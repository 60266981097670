

/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Skeleton ProductCard
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       22nd July 2022

*******************************************************************************************/

import React                            from 'react';
import { styled, Box }                  from '@mui/material';
import { Skeleton }                     from '@mui/material';
import { 
    ContainerHorizontal, 
    ContainerVertical 
}                                       from './components';

const Root = styled(Box)(({theme}) => ({
    width           : "100%",
    aspectRatio     : '1',
    border          : `0.5px solid ${theme.palette.divider}`,
    overflow        : 'hidden',
    display         : "flex",
    flexDirection   : "column"
}));

const ContainerHeader = styled(Box)(({
    minHeight       : 150, 
    aspectRatio     : '5'
}));

const ContainerBody = styled(Box)(({theme}) => ({
    marginTop       : 0,
    borderTop       : `0.5px solid ${theme.palette.divider}`,
    borderBottom    : `0.5px solid ${theme.palette.divider}`,
}));

const ContainerButton = styled(Box)(({theme}) => ({
    width           : 150,
    height          : 40,
    borderRadius    : theme.spacing(0.5),
    overflow        : 'hidden'
}));

export const SkeletonProductCard = ({height = '100%',footerHeight = 125, ...props}) => (
    <Root height={height}>
        <ContainerHeader>
            <Skeleton variant="rectangular" height="100%"/>
        </ContainerHeader>
        <ContainerBody flexGrow={1}>
            <Skeleton variant="rectangular" height="100%"/>
        </ContainerBody>
        <ContainerVertical sx={{minHeight:footerHeight, p:1, bgcolor : theme => theme.palette.divider}}>
            <Box flexGrow={1}/>
            <ContainerHorizontal>
                <Box flexGrow={1}/>
                <Box width="25%">
                    <Skeleton variant="rectangular"/>
                </Box>
            </ContainerHorizontal>
            <ContainerHorizontal>
                <Box flexGrow={1}/>
                <Box width="45%">
                    <Skeleton variant="rectangular" height="30px"/>
                </Box>
            </ContainerHorizontal>
            <ContainerHorizontal position = 'relative'>
                <Box flexGrow={1}/>
                <ContainerButton>
                    <Skeleton variant="rectangular" height="100%"/>
                </ContainerButton>
            </ContainerHorizontal>
        </ContainerVertical>
    </Root>
);

export default SkeletonProductCard;