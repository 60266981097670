
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
CommentSubscription Context
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       6th May 2022

*******************************************************************************************/
import React            from 'react';
import omit             from 'lodash/omit'
import { useNetwork }   from './NetworkContext';

const CommentSubscriptionContext = React.createContext(undefined);

// https://kentcdodds.com/blog/how-to-use-react-context-effectively

const CommentSubscriptionProvider = ({children, rootId}) => {

    const { socketUsers : socket }  = useNetwork();
    const counts                    = React.useRef({});

    // Join and Increment
    const join  = React.useCallback( (channel) => {
        if(socket && channel){
            socket.emit('join', channel);
            counts.current = ({...counts.current, [channel] : (counts.current[channel] || 0) + 1})
        }
    }, [socket]);

    // Leave Channel
    const leave = React.useCallback( (channel) => {
        if(channel){
            let cnt = parseInt(counts.current[channel]);

            // Not Counted
            if(isNaN(cnt)) 
                return;

            // Leave if last
            if(cnt <= 1){
                socket.emit('leave', channel)
                counts.current = omit(counts.current, [channel]);

            // Else count down
            }else{
                counts.current = ({...counts.current, [channel] : (counts.current[channel] || 2) - 1})
            }
        }
    }, [counts, socket]);

    // Leave all channels if rootId changes
    React.useEffect(() => {
        if(socket){
            return () => {
                Object.keys(counts.current).forEach(channel => socket.emit('leave', channel))
                counts.current = {};
            }
        }
    },[rootId, socket]);

    const value = {
        counts : counts.current,
        rootId,
        join,
        leave
    }

    return (
        <CommentSubscriptionContext.Provider value={value}>
            {children}
        </CommentSubscriptionContext.Provider>
    )
}

const CommentSubscriptionConsumer =  ({children}) => {
    return (
        <CommentSubscriptionContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('CommentSubscriptionConsumer must be used withinCommentSubscriptionProvider');
                }
                return children(context)
            }}
        </CommentSubscriptionContext.Consumer>
    )
}

const useCommentSubscription = () => {
    const context = React.useContext(CommentSubscriptionContext)
    if (context === undefined) {
        throw new Error('useCommentSubscription must be used within a CommentSubscriptionProvider')
    }
    return context
}

export {
    CommentSubscriptionProvider,
    CommentSubscriptionConsumer,
    useCommentSubscription
}
