/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Header
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       22nd December 2020

*******************************************************************************************/
import React                            from 'react';
import { Box }                          from '@mui/material';
import MenuIcon                         from '@mui/icons-material/Menu';

import { useUser }                      from 'contexts';
import Menu                             from './Menu';
import usePages                         from './usePages';
import FatMenu                          from './FatMenu';
import { 
    AdministrationTitle, 
    SystemPagesTitle, 
    OtherPagesTitle, 
    UserNavigationTitle  
}                                       from './Titles';

export const HamburgerMenu = (props) => {
    
    const {
        isAuthenticated, 
        isAdmin, 
        isSuspended
    }                                       = useUser();
    const {
        mainPages,
        otherPages, 
        userPages,
        adminPages
    }                                       = usePages();
    const userValid                         = React.useMemo(() => isAuthenticated && !isSuspended, [isAuthenticated, isSuspended]);
    const showUser                          = React.useMemo(() => userValid, [userValid]);
    const showAdmin                         = React.useMemo(() => userValid && isAdmin, [isAdmin, userValid])
    return (
        <Menu 
            showArrow   = {true} 
            id          = "hamburger" 
            label       = {<MenuIcon sx={{fontSize:40,color:'text.primary'}}/>} 
            ButtonProps = {{ sx : { ml : -2 } }}
        >
            <Box sx={{p:0,maxHeight:400}}>
                
                <React.Fragment>
                    <SystemPagesTitle />
                    <FatMenu multicolumn={false} pages={mainPages}/>
                </React.Fragment>

                {
                    showUser && 
                    <React.Fragment>
                        <UserNavigationTitle />
                        <FatMenu multicolumn={false} pages={userPages}/>
                    </React.Fragment>
                }

                <React.Fragment>
                    <OtherPagesTitle />
                    <FatMenu multicolumn={false} pages={otherPages}/>
                </React.Fragment>

                {
                    showAdmin && 
                    <React.Fragment>
                        <AdministrationTitle />
                        <FatMenu multicolumn={false} pages={adminPages}/>
                    </React.Fragment>
                }
                
            </Box>
        </Menu>
    )
}

export default HamburgerMenu;