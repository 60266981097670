
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Feature Box with Demo Cards
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       16th May 2021

*******************************************************************************************/
import React                            from 'react';
import {
    styled,
    Link,
    Table,
    TableBody,
    TableCell       as TableCellMUI,
    TableContainer,
    TableHead,
    TableRow        as TableRowMUI,
    Typography,
}                                       from '@mui/material';
import {FeatureBox}                     from 'components';
import config                           from '../config';

const {
    development : DEVELOPMENT_MODE
} = config;

const TableCell = styled(TableCellMUI,{
    shouldForwardProp : prop => prop !== 'lastRow'
})(({lastRow = false}) => ({
    paddingTop      : 0,
    paddingBottom   : 0,
    fontSize        : '0.6rem',
    ...(lastRow && {
        borderBottom : 'none'
    })
}))


const TableRow = styled(TableRowMUI)(({theme}) => ({
    height          : '1.5rem'
}))

const DEMO_CARD_DATA = [
    {label: 'Visa',         number: "4242 4242 4242 4242", desc: "No Auth"},
    {label: 'Mastercard',   number: "5555 5555 5555 4444", desc: "No Auth"},
    {label: 'Visa',         number: "4000 0027 6000 3184", desc: "Auth"},
    {label: 'Visa',         number: "4000 0082 6000 3178", desc: "Auth, No Funds"}
]

const Content = () => (
    <React.Fragment>
        <Typography align="justify" variant="body2" paragraph>
            Demo cards can be used during development/testing, this will be omitted in production mode.
            For more cards, visit the <Link href="https://stripe.com/docs/testing" target="blank" style={{cursor:'pointer'}}>Stripe Website</Link>
        </Typography>
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        {
                            ['Label','Number','Description'].map((header, ix) => (
                                <TableCell key={ix}>{header}</TableCell>
                            ))
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        DEMO_CARD_DATA.map(({label,number,desc},ix) => (
                            <TableRow key={ix} hover>
                                {
                                    [label,number,desc].map((cell,ixCell) => (
                                        <TableCell key={ixCell} lastRow={ix >= DEMO_CARD_DATA.length - 1}>
                                            {cell} 
                                        </TableCell>
                                    ))
                                }
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    </React.Fragment>
)

export const StripeDemoCards = ({ frameless = false }) => {
    if(!DEVELOPMENT_MODE) 
        return null;
    if(frameless)
        return <Content/>
    return (
        <FeatureBox title={"Demo Cards - DEVELOPMENT MODE"} >
            <Content/>
        </FeatureBox>
    )
};

export default StripeDemoCards;

