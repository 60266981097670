/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Comments Component
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       1st May 2022

*******************************************************************************************/
import React                            from 'react';
import { styled, Box, Typography }      from '@mui/material';
import {
    SpaceBox,
    FeatureBox
}                                       from 'components';

const Root = styled(Box)(({theme}) => ({
    width : '100%',
    '& > * + *' : {
        marginTop : theme.spacing(1)
    }
}))

export const NoticeBlock = ({title=undefined, titleProps={}, children, ...props}) => (
    <Root {...props}>
        {
            title &&
            <Typography variant="h5" component="h3" gutterBottom {...titleProps}>
                {title}
            </Typography>
        }
        <FeatureBox>
            <SpaceBox align="center">
                {children}
            </SpaceBox>
        </FeatureBox>
    </Root>
)

export default NoticeBlock;