/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Sticky Component
********************************************************************************************
based on: https://mtm.dev/sticky-stuck-styles

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       31st December 2020

*******************************************************************************************/
import React from 'react';
import {Box} from '@mui/material';

const noop                  = () => {}
const defaultStuckStyles    = {zIndex:10}
const defaultUnstuckStyles  = {};
  
export const Sticky = ({
    enabled             = true,
    position            = "top",
    stuckClasses        = undefined,
    unstuckClasses      = undefined,
    stuckComponent      = undefined,
    unstuckComponent    = undefined,
    stuckStyles         = defaultStuckStyles,
    unstuckStyles       = defaultUnstuckStyles,
    onStick             = noop,
    children,
    ...props
  }) => {
    const [stuck, setStuck] = React.useState(false)
    const ref               = React.createRef()
  
    const className   = React.useMemo(() => (
        (stuck && enabled) 
            ? stuckClasses  
            : unstuckClasses
    ), [enabled, stuck, stuckClasses, unstuckClasses]);

    const styles    = React.useMemo(() => (
        (stuck && enabled) 
            ? { ...defaultStuckStyles,   ...stuckStyles   } 
            : { ...defaultUnstuckStyles, ...unstuckStyles }
    ), [enabled, stuck, stuckStyles, unstuckStyles]);
  
    const inlineStyles = enabled ? {
        position    : "sticky",
        [position]  : -1,
        zIndex      : 2,
        ...styles
    } : styles

    /*
    const handleStuck = React.useCallback( (e,stuck) => {
        if(position === 'top'       && e.boundingClientRect.bottom >= window.innerHeight)
            return setStuck(false)
        if(position === 'bottom'    && e.boundingClientRect.top <= 0) 
            return setStuck(false)
        if(position === 'left'      && e.boundingClientRect.right >= window.innerWidth) 
            return setStuck(false)
        if(position === 'right'     && e.boundingClientRect.left <= 0) 
            return setStuck(false)
        if(enabled){
            setStuck(stuck);
        }
    },[enabled, position])
    */

    /*
    React.useEffect(() => {
        if(enabled){
            const cachedRef = ref.current
            const observer = new IntersectionObserver(
                ([e]) => {
                    handleStuck(e, e.intersectionRatio < 1)
                },
                { threshold: [1,1] }
            )
            observer.observe(cachedRef)
            return () => {
                observer.unobserve(cachedRef)
            }
        }
    }, [enabled, handleStuck, ref])
    */

    React.useEffect(() => {
        const cachedRef = ref.current
        const observer = new IntersectionObserver(
            ([e]) => setStuck(e.intersectionRatio < 1),
            { threshold: [1] }
        )
        observer.observe(cachedRef)
        return () => {
            observer.unobserve(cachedRef)
        }
    }, [ref])

    React.useEffect(() => {
        if(!enabled) 
            setStuck(false);
    },[enabled])

    React.useEffect(() => onStick(stuck), [onStick, stuck])
  
    return (
        <Box 
            id          = "sticky" 
            className   = {className} 
            ref         = {ref} 
            component   = {(stuck ? stuckComponent : unstuckComponent) || unstuckComponent || "div"} 
            {...props}
            style       = {{
                ...inlineStyles,
                ...props?.style, 
            }}
        >
            {children}
        </Box>
    )
}

export default Sticky;
  