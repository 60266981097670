/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
SpaceBox
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       16th April 2022

*******************************************************************************************/
import { styled, Box } from '@mui/material';

export const SpaceBox = styled(Box,{
    shouldForwardProp : prop => prop !== 'spacing'
})(({theme, spacing = 4}) => ({
    width : '100%',
    padding : theme.spacing(spacing),
    '& > * + *' : {
        marginTop : theme.spacing(2)
    }
}))

export default SpaceBox;
