/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Widget Hero
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       30th May 2023

*******************************************************************************************/

import React                from 'react';
import { 
  styled, 
  alpha, 
  Box 
}                           from '@mui/material';
import { Videos }           from '../components';

const Hero                  = React.lazy(() => import('../components/Hero'));

const HeroContainer = styled(Box)(({theme}) => ({
  position      : 'absolute',
  top           : '50%',
  transform     : 'translatey(-50%)',
  left          : '20%',
  right         : '20%',
  [theme.breakpoints.down('md')] : {
    left        : '10%',
    right       : '10%',
  },
  [theme.breakpoints.down('sm')] : {
    left        : '0%',
    right       : '0%',
  },
}));

const MainContainer = styled(Box,{
  shouldForwardProp : prop => prop !== 'height' && prop !== 'minHeight'
})(({theme, height = 'calc(100vh - 90px)', minHeight = 650}) => ({
  minHeight       : minHeight,
  height          : height,
  position        : 'relative',
}));

const Jumbotron = styled(Box)(({theme}) => ({
  borderRadius  : theme.spacing(2),
  background    : theme.palette.mode === 'dark' ? 'none' : alpha(theme.palette.primary.main, 0.25),
  overflow      : 'hidden',
  margin        : theme.spacing(4),
  padding       : theme.spacing(4),
}));

export const WidgetHero = ({children, data = undefined, width = 1000}) => {
    if(!data)
        return null
    return (
        <MainContainer minHeight={400}>
            <Videos 
                src     = {data?.fields?.video?.fields?.file?.url} 
                preview = {
                  data?.fields?.image?.fields?.file?.url && width
                      ? `${data?.fields?.image?.fields?.file?.url}?w=${width}`
                      : data?.fields?.image?.fields?.file?.url
                }
            />
            <HeroContainer sx={{zIndex:5}}>
                <Jumbotron>
                    <Hero data={data}>
                        {children}
                    </Hero>
                </Jumbotron>
            </HeroContainer>
        </MainContainer>
    )
}

export default WidgetHero;