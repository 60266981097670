/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Zodiac Field
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       6th March 2023

*******************************************************************************************/
import React                            from 'react';
import { MenuItem }                     from '@mui/material';
import { Select }                       from 'mui-rff';
import { useTranslation }               from 'contexts';

export const ZODIAC_DATA = [
    { value : "sidereal",  translationKey : "common.sidereal" },
    { value : "tropical",  translationKey : "common.tropical" },
];

export const ZodiacField = ({name = "zodiac", label=undefined, disabled = false, ...props}) => {
    const {t} = useTranslation();
    const zodiacData = React.useMemo(() => (
        ZODIAC_DATA
            .map(({value,translationKey}) => ({
                value,
                label : t(translationKey)
            }))
    ),[t]);
    return (
        <Select
            name        = {name}
            label       = {label || t('common.zodiac')}
            disabled    = {disabled}
            {...props}
        >
            {
                zodiacData.map(({value,label},ix) => (
                    <MenuItem value={value} key={ix}>
                        {label}
                    </MenuItem>
                ))
            }
        </Select>
    )
}

export default ZodiacField;