/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Form Alert
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       11th June 2021

*******************************************************************************************/
import React            from 'react';
import {
    styled,
    Box,
    Alert as AlertMUI,
    AlertTitle
}                       from '@mui/material';

function important(value){
    return (value + ' !important');
}

const Alert = styled(AlertMUI)(({theme}) => ({
    '&.MuiAlert-root' : {
        width           : important('100%'),
        paddingTop      : important(2 + 'px'),
        paddingBottom   : important(2 + 'px'),
        paddingRight    : important(theme.spacing(1.5)),
        padding         : important(theme.spacing(1)),
    },
    '& > .MuiAlert-icon' : {
        fontSize        : '1.25rem',
        marginRight     : theme.spacing(0.5)
    },
    '& > .MuiAlert-message' : {
        fontSize        : important('0.75rem'),
        fontWeight      : important(300)
    },
    '& > .MuiAlert-action' : {
        marginRight     : theme.spacing(0.25)
    }
}));

const MessageContainer = styled(Box)({
    fontSize            : '0.75rem',
    fontWeight          : 300
})

export const FormAlert = ({children, title = undefined, ...props}) => (
    <Alert {...props}>
        {
            title && 
            <AlertTitle>
                {title}
            </AlertTitle>
        }
        <MessageContainer>
            {children}
        </MessageContainer>
    </Alert>
)

export default FormAlert;