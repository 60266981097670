/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
New Record
********************************************************************************************
Displays the particular component (Default NewChip) if the 'createdAt' moment is within a 
threshold duration (and unit) in the past, from the current moment.

If createdAt is missing or undefined, or the threshold is less than zero nor not a number, 
or the resolved createdAt time has occurred before now minus the threshold, then 
a null object is rendered, else the provided component is rendered.

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       28th January 2023

*******************************************************************************************/
import React                            from 'react';
import moment                           from 'moment';
import { NewChip }                      from 'components';
import { useClock }                     from 'hooks';

const obj = {};

export const NewRecord = ({
    createdAt               = undefined, 
    threshold               = 5, 
    unit                    = 'minutes', 
    component : Component   = NewChip, 
    componentProps          = obj
}) => {
    const {now} = useClock();
    const base  = React.useMemo(() => moment(createdAt), [createdAt]);
    const isNew = React.useMemo(() => {
        try {
            return createdAt && threshold > 0 
                ? base.isAfter( now.clone().subtract( threshold, unit ) ) && base.isSameOrBefore(now)
                : false
        } catch (err) {
            // catch error, perhaps unit is invalid etc ... 
        }
        return false;
    }, [base, createdAt, now, threshold, unit]);

    if(!isNew || !createdAt || isNaN(threshold) || threshold <= 0)
        return null

    return (
        <Component {...componentProps} />
    )
}

export default NewRecord;