/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Network Offline
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       28th October 2021

*******************************************************************************************/
import React                            from 'react';
import { 
    styled, 
    useTheme, useMediaQuery, Box, 
    CardMedia, Grid,
    Typography as TypographyMUI 
}                                       from '@mui/material';
import { Title, SpaceBox }              from 'components';
import { useNetwork }                   from 'contexts';
import FullMoonBlue                     from 'resources/AstrologyToolkit/FullMoonWatercolor_Blue.gif';
import FullMoonBlood                    from 'resources/AstrologyToolkit/FullMoonWatercolor_Blood.gif';
import { withTranslation }              from './hoc';

const Typography = styled(TypographyMUI)({
    fontSize : '1.25rem'
});

// create an utility function somewhere
const checkImage = path => new Promise((resolve,reject) => {
    const img   = new Image()
    img.onload  = () => resolve(path)
    img.onerror = () => reject()
    img.src     = path
});

export const NetworkOffline = withTranslation( ({t, ...props}) => {

    const theme                                 = useTheme();
    const {isAPIHealthy,    isNetworkReady}     = useNetwork();
    const [imagesLoaded,    setImagesLoaded]    = React.useState(false);

    React.useEffect(()=>{
        if(!isAPIHealthy) 
            return;
        Promise
            .all([FullMoonBlood,FullMoonBlue].map(checkImage))
            .then(() => {
                setImagesLoaded(true);
            })
            .catch(()=>{
                // console.error('could not cache images')
            })
    },[isAPIHealthy])

    const minSize = useMediaQuery(theme => theme.breakpoints.up('md'))

    // Network is off
    return (
        <Box display={isNetworkReady ? 'none' : 'inherit'} sx={{width:'100%'}}>
            <Grid container>
                {minSize &&
                    <Grid item xs={12} md={4} align="center">
                        <CardMedia
                            component   = "img"
                            id          = {imagesLoaded ? 'imageCached' : 'image'}
                            image       = {theme.palette.mode === 'light' ? FullMoonBlue : FullMoonBlood}
                            title       = {t('components.networkOffline.networkOffline')}
                            sx          = {{maxWidth:400}}
                        />
                    </Grid>
                }
                <Grid item xs={12} md={8}>
                    {
                        isAPIHealthy
                        ? (
                            <SpaceBox>
                                <Title variant="h3" paragraph>
                                    { t('components.networkOffline.communicationProblems') }
                                </Title>
                                <Typography paragraph>
                                    { t('components.networkOffline.communicationProblemsSummary') }
                                </Typography>
                            </SpaceBox>
                        ) : (
                            <SpaceBox>
                                <Title variant="h3" paragraph>
                                    { t('components.networkOffline.networkOffline') }
                                </Title>
                                <Typography paragraph>
                                    { t('components.networkOffline.networkOfflineSummary') }
                                </Typography>
                            </SpaceBox>
                        )
                    }
                </Grid>
            </Grid>
        </Box>
    );
});

export default NetworkOffline;