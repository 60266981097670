/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Order Invoice Button
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       20th November 2021

*******************************************************************************************/
import React                            from 'react';
import { 
    Box,
    DialogActions,
    Tooltip,
    useMediaQuery
}                                       from '@mui/material';
import Pagination                       from '@mui/material/Pagination';
import {
    Receipt as ReceiptIcon,
    Email   as EmailIcon
}                                       from '@mui/icons-material';
import {useHistory, useLocation}        from 'react-router-dom';
import {
    RefreshIcon,
    DraggableDialog,
    Button,
    IconButton
}                                       from 'components';
import { withTranslation }              from 'components/hoc';
import { 
    useUser,
    useOrders,
    useAlert
}                                       from 'contexts';
import { useCancelToken }               from 'hooks';

export const OrderTaxInvoiceButton = withTranslation( ({
    t, 
    children,
    invoiceId = undefined, 
    showEmail = true, 
    showView  = true, 
    color     = "secondary", 
    ...props
}) => {
    
    const history                       = useHistory();
    const location                      = useLocation();
    const {alert}                       = useAlert();
    const {isCancel}                    = useCancelToken();
    const { isAuthenticated }           = useUser();
    const {
        queried,
        getInvoiceByDownload, 
        getInvoiceByEmail,
        emailingInvoice,
        emailingInvoiceAllow
    }                                   = useOrders();  
    
    const [url,         setUrl]         = React.useState(undefined);  
    const [fileName,    setFileName]    = React.useState(undefined);
    const [numPages,    setNumPages]    = React.useState(null);
    const [pageNumber,  setPageNumber]  = React.useState(1);
    const [viewing,     setViewing]     = React.useState(false);

    // Query Invoice by ID
    const handleQuery = React.useCallback( (id) => new Promise((resolve,reject) => {
        setViewing(true)
        getInvoiceByDownload(id)
            .then(({objectUrl,fileName}) => {
                setUrl(objectUrl);
                setFileName(fileName);
                resolve(true);
            })
            .finally(() => {
                setViewing(false);
            })
            .catch(reject)
    }), [getInvoiceByDownload]);

    // Download Invoice
    const handleDownload = React.useCallback( () => {

        if(!invoiceId) 
            return;

        handleQuery(invoiceId)
            .catch(err => {
                if(!isCancel(err)) 
                    alert( t('components.order.orderTaxInvoiceButton.problemDownloadingInvoice') ,'error');
            })

    }, [t, alert, handleQuery, invoiceId, isCancel]);

    const handleEmailInvoice        = React.useCallback( () => getInvoiceByEmail(invoiceId), [getInvoiceByEmail, invoiceId]);
    const handleClose               = React.useCallback( () => setUrl(undefined), []);
    const handleChange              = React.useCallback( (event, value) => setPageNumber(value), []);
    const reset                     = React.useCallback(() => {
        setPageNumber(1);
        setNumPages(null);
    }, [])

    // Reset on url change, and unmount
    React.useEffect(() => {
        reset();
        return () => {
            reset();
        }
    },[reset, url])

    // Handle id parameter, and strip
    React.useEffect(() => {
        if(isAuthenticated){
            const idParmName = 'invoiceId';
            const queryParams = new URLSearchParams(location.search)
            const id = queryParams.get(idParmName);
            if(id && id !== invoiceId){
                handleQuery(id)
                    .catch(err => {
                        //
                    })
                    .finally(() => {
                        queryParams.delete(idParmName);
                        history.replace({
                            search: queryParams.toString(),
                        })
                    })
            }
        }
    },[handleQuery, history, invoiceId, isAuthenticated, location.search])

    const hidden = useMediaQuery(theme => theme.breakpoints.up('lg'))

    // Not authenticated, or no invoiceId
    if(!isAuthenticated || !invoiceId)
        return null;

    if(!showEmail && !showView)
        return null;

    // Render
    return <>
        {
            showEmail && 
            <Tooltip title={ t('components.order.orderTaxInvoiceButton.emailInvoice') }>
                <span>
                    {
                        hidden &&
                        <Button 
                            disabled    = {!queried || emailingInvoice || !emailingInvoiceAllow} 
                            onClick     = {handleEmailInvoice} 
                            variant     = "contained" 
                            color       = {color}
                            startIcon   = {
                                emailingInvoice 
                                    ? <RefreshIcon loading={true} /> 
                                    : <EmailIcon />
                            } 
                            {...props}
                        > 
                                {children || t('common.email')}
                        </Button>
                    }
                    {
                        !hidden &&
                        <IconButton onClick={handleEmailInvoice} size="small" color = {color} {...props}>
                            {
                                emailingInvoice 
                                    ? <RefreshIcon loading={true} /> 
                                    : <EmailIcon />}
                        </IconButton>
                    }
                </span>
            </Tooltip>
        }
        {
            showView && 
            <Tooltip title={ t('components.order.orderTaxInvoiceButton.downloadInvoicePDF')  }>
                <span>
                    {
                        hidden &&
                        <Button 
                            disabled    = {!queried || viewing} 
                            onClick     = {handleDownload} 
                            variant     = "contained" 
                            color       = {color}
                            startIcon   = {
                                viewing 
                                    ? <RefreshIcon loading={true} /> 
                                    : <ReceiptIcon />
                            } 
                            {...props}
                        > 
                            {children || t('common.view')}
                        </Button>
                    }
                    {
                        !hidden &&
                        <IconButton onClick={handleDownload} size="small" color = {color} {...props}>
                            {
                                viewing 
                                    ? <RefreshIcon loading={true} /> 
                                    : <ReceiptIcon />
                            }
                        </IconButton>
                    }
                </span>
            </Tooltip>
        }
        {
            url && 
            <DraggableDialog
                open                = {Boolean(url)}
                title               = {
                    <React.Fragment>
                        <Box display="flex">
                            <Box flexShrink={1}>
                                {t('components.order.orderTaxInvoiceButton.taxInvoiceViewer') }
                            </Box>
                        </Box>
                    </React.Fragment>
                }
                fullScreen          = {false}
                onClose             = {handleClose}
                onCancel            = {handleClose}
                onOk                = {handleClose}
                showButtonsCancel   = {false}
                showButtonsOk       = {false}
                disableBackdropClick= {true}
            >
                <Box>
                    <object id={fileName} data={url} type="application/pdf" width="500px" height="500px"  download={`Invoice`}>
                        <p>
                            {t('components.order.orderTaxInvoiceButton.noPlugin') } <a href={url}>{t('components.order.orderTaxInvoiceButton.downloadInvoice') }</a>
                        </p>
                    </object>
                </Box>
                
                {
                    false && numPages > 1 &&
                    <DialogActions>
                        <Pagination count={numPages} page={pageNumber} onChange={handleChange} />
                    </DialogActions>
                }
            </DraggableDialog>
        }
    </>;
});

export default OrderTaxInvoiceButton;