/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Current Location
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       13th June 2021

*******************************************************************************************/
import React                            from 'react';
import moment                           from 'moment';
import { FormattedMoment }              from 'components';
import { useCurrentLocation }           from 'contexts';
import { withTranslation }              from './hoc';

export const CurrentLocation = withTranslation( ({t}) => {
    
    const {
        valid, 
        queried, 
        coordsFormatted
    } = useCurrentLocation();
    
    if(!valid || !moment.isMoment(queried)) 
        return null;

    return (
        <React.Fragment>
            {t('common.yourLocation')}: {coordsFormatted}, {t('common.checked')}: <FormattedMoment time={queried}/>
        </React.Fragment>
    )
});

export default CurrentLocation;