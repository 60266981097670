
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Header Context
********************************************************************************************
Header context, consumer, provider and hook

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       3rd June 2022

*******************************************************************************************/
import React from 'react';

// The Product Context 
const HeaderContext = React.createContext(undefined);

// Header Provider
const HeaderProvider = ({children}) => {

    const [show, setShow]   = React.useState(true);
    const [dims, setDims]   = React.useState({width:0,height:0});

    // Context values
    const value             = React.useMemo(() => ({
        show,
        setShow,
        dims,
        setDims
    }), [dims, show]);

    return (
        <HeaderContext.Provider value={value}>
            {children}
        </HeaderContext.Provider>
    )
}

// Header Consumer
const HeaderConsumer = ({children}) => {
    return (
        <HeaderContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('HeaderConsumer must be used within HeaderProvider');
                }
                return children(context)
            }}
        </HeaderContext.Consumer>
    )
}

// useHeader Hook
const useHeader = () => {
    const context = React.useContext(HeaderContext);
    if(context === undefined)
        throw new Error('useHeader must be used within HeaderProvider');
    return context;
}

export {
    HeaderProvider,
    HeaderConsumer,
    useHeader
}