/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Home
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       20th November 2020

*******************************************************************************************/

import React                from 'react';
import { 
  styled,
  Box 
}                           from '@mui/material';
import {
  PageContainer,
  Loader,
  JSONViewer,
  ContentfulSEO
}                           from 'components';
import {  SkeletonLoading } from 'components/skeletons';
import { 
  useContentful,
}                           from 'contexts';
import constants            from './constants';
import {
  WidgetStepper,
  WidgetImage,
  WidgetExamples,
  WidgetArchives,
  WidgetTestimonials,
  WidgetProducts,
  WidgetHero,
  WidgetRichText,
  WidgetVideo,
  WidgetWorkRequired
}                           from './widgets';
import { 
  Archives,
  HeroButtons
}                           from './components';

const HomeRoot = styled(Box)(({theme}) => ({
  background  : theme.palette.background.paper,
  position    : 'relative'
}))

const Sections = styled(Box)(({theme}) => ({
  '& > * + *' : {
    marginTop : [theme.spacing(12), '!important' ],
  },
}));

export const Home = () => {

  const {getLanding}              = useContentful();
  const [data, setData]           = React.useState(undefined);

  React.useEffect(() => {
    getLanding('home')
      .then(items => setData(items[0]))
      .catch(console.error)
  },[getLanding])

  return (
    <HomeRoot>

      {
        !data && 
        <Box position="relative" sx={{width:'100%',height:'calc(100vh - 100px)'}}>
          <SkeletonLoading loading={true} loadingComponent={Loader}/>
        </Box>
      }
      
      {
        data?.fields?.seoFields &&
        <ContentfulSEO data={data?.fields?.seoFields} />
      }
      
      <WidgetHero data={data?.fields?.hero}>
        <HeroButtons />
      </WidgetHero>

      <PageContainer breadcrumbs={false} allowOffline={true} paperProps={{sx:{position:'unset'}}} sx={{position:'unset'}}>

        { 
          false && 
          <JSONViewer src={data?.fields?.seoFields} /> 
        } 

        <WidgetWorkRequired />

        <Sections id={constants.ids.about}>
          {
            (data?.fields?.widgets || []).map((data,ix) => {
              switch(data?.sys?.contentType?.sys?.id){
                case 'widgetStepper':
                  return (
                    <WidgetStepper key={ix} data={data} />
                  );
                case 'widgetImage':
                  return (
                    <WidgetImage key={ix} data={data} />
                  );
                case 'widgetExamples':
                  return (
                    <WidgetExamples key={ix} data={data} />
                  );
                case 'widgetArchives':
                  return (
                    <WidgetArchives key={ix} data={data} />
                  );
                case 'widgetTestimonials':
                  return (
                    <WidgetTestimonials key={ix} data={data} />
                  );
                case 'widgetProducts':
                  return (
                    <WidgetProducts key={ix} data={data} />
                  );
                case 'widgetRichText':
                  return (
                    <WidgetRichText key={ix} data={data} />
                  );
                case 'widgetVideo':
                  return (
                    <WidgetVideo key={ix} data={data} />
                  );
                default:
                  return null;
              }
            })
          }

          {
            !true && 
            <JSONViewer src={data?.fields?.widgets} />
          }
          
          <Box sx={{pt:14,mb:-2}}>
            <Archives/>
          </Box>
        </Sections>
      </PageContainer>   
    </HomeRoot>
  )
};

export default Home;