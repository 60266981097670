/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Order Progress
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       5th August 2021

*******************************************************************************************/
import React                            from 'react';
import { 
    styled, 
    alpha, 
    Box, Typography, useMediaQuery 
}                                       from '@mui/material';
import {
    Title,
    JSONViewer,
    AdminBox,
    NoDataPrompt,
    DeliveryProgress,
    Button,
    RootContainer,
    ItemPaper
}                                       from 'components';
import { withTranslation }              from 'components/hoc';
import { useHistory }                   from 'react-router-dom';
import {
    useOrderViewer, 
    useUser,
    useTrackId
}                                       from 'contexts';
import { 
    useLocation
}                                       from "react-router-dom";
import { 
    useQueryParam, 
    StringParam,
}                                       from 'use-query-params';

const DEBUG                     = false;
const DELIVERY_QUERY_PARAM_NAME = 'deliveryId'
const ORDER_TABLE_ID            = 'orderTable';
const SUPPORT_ID                = 'support';
const arr                       = [];

const ContentContainer = styled(Box,{
    shouldForwardProp : (prop) => prop !== 'cancelled'
})(({theme, cancelled = false}) => ({
    background : cancelled 
        ? alpha(theme.palette.error.light,0.5) 
        : 'inheri',
    '& > * + *' : {
        marginTop : theme.spacing(2)
    }
}));

export const OrderProgress = withTranslation( ({t, quickLinks = false, ...props}) => {
    const [deliveryId, setDeliveryId]                   = useQueryParam(DELIVERY_QUERY_PARAM_NAME, StringParam);
    const location                                      = useLocation();
    const history                                       = useHistory();
    const {
        data : order, 
        orderId, 
        isOwner
    }                                                   = useOrderViewer();
    const {trackId, setTrackId}                         = useTrackId();
    const {isAuthenticated}                             = useUser();
    
    // Extract the delivery data from the orders context
    const deliveries = React.useMemo(() => order ? order.deliveries : [], [order]);

    const scrollToID = React.useCallback((id) => {
        if(id){
            const el = document.getElementById(id);
            if(el) el.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'start'});
            return true;
        }
        return false;
    },[])

    const scrollToDelivery = React.useCallback((deliveryId) => {
        if(scrollToID(deliveryId)){
            setTrackId(deliveryId)
            setDeliveryId(undefined);
        }
    },[scrollToID, setDeliveryId, setTrackId])

    // clear last scroll after a reasonable time
    React.useEffect(()=>{
        if(trackId) 
            scrollToDelivery(trackId);
    },[trackId, scrollToDelivery, setTrackId])

    // Scroll to library hash
    React.useEffect(()=>{
        if(isAuthenticated && (deliveries || []).length > 0 && deliveryId){
            const timeout = setTimeout(()=>{
                scrollToDelivery(deliveryId);
            },500)
            return () => {
                clearTimeout(timeout);
            }
        }
    },[isAuthenticated, deliveries, location.search, history, scrollToDelivery, deliveryId])

    const statusMap = React.useMemo(() => ([
        {
            id              : 'inputPending',
            status          : 'USER_INPUT_PENDING',
            statusExclude   : arr,
            inputRequired   : false,
            inputAvailable  : true,
            inputConfirm    : true
        },
        {
            id              : 'input',
            status          : 'USER_INPUT', 
            statusExclude   : arr, 
            inputRequired   : true,
            inputAvailable  : true,
            inputConfirm    : false
        },
        {
            id              : 'processing',
            status          : undefined, 
            statusExclude   : ['USER_INPUT', 'USER_INPUT_PENDING', 'COMPLETE', 'DISPATCHED','CANCELLED'], //PROCESSING & COMPLETE_PENDING OK
            inputRequired   : false,
            inputAvailable  : false,
            inputConfirm    : false
        },
        {
            id              : 'cancelled',
            status          : 'CANCELLED', 
            statusExclude   : arr, //PROCESSING & COMPLETE_PENDING OK
            inputRequired   : false,
            inputAvailable  : false,
            inputConfirm    : false,
            hideProgress    : true
        },
        {
            id              : 'dispatched',
            status          : 'DISPATCHED', 
            statusExclude   : arr,
            inputRequired   : false,
            inputAvailable  : false,
            inputConfirm    : false
        },
        {
            id              : 'complete',
            status          : 'COMPLETE', 
            statusExclude   : arr,
            inputRequired   : false,
            inputAvailable  : false,
            inputConfirm    : false
        },
    ].reduce((acc,cur) => {
        const include = (Array.isArray(cur.status         ) ? cur.status          : [cur.status       ]).filter(Boolean);
        const exclude = (Array.isArray(cur.statusExclude  ) ? cur.statusExclude   : [cur.statusExclude]).filter(Boolean);
        const deliveriesSubset = deliveries
            .filter(d => !include.length ||  include.includes(d.status))
            .filter(d => !exclude.length || !exclude.includes(d.status));
        const quantity = deliveriesSubset.length;
        return [ ...acc, { ...cur, deliveriesSubset, empty: quantity <= 0, quantity}]

    },[])), [deliveries]);

    // Are there any subsets
    const smDown        = useMediaQuery(theme => theme.breakpoints.down('md'));
    const subsets       = React.useMemo(() => statusMap.filter(x => !x.empty), [statusMap]);
    const hasSubsets    = React.useMemo(() => subsets.length > 0, [subsets.length]);

    const hasOrderTable = Boolean(document.getElementById(ORDER_TABLE_ID));
    const hasSupport    = Boolean(document.getElementById(SUPPORT_ID));

    if(!order || !order?.shouldProcess || !orderId)
        return null
    
    return (
        <RootContainer component={ItemPaper} id={"orderprogress"}>
            {
                quickLinks &&
                <ContentContainer>
                    <Box>
                        <Title variant="h5">
                            {t('components.order.orderProgress.quickLinks')}
                        </Title>
                    </Box>
                    {
                        (hasSubsets || hasOrderTable) && 
                        <Box mt={0}>
                            <Typography gutterBottom>
                                {t('components.order.orderProgress.navigateToTheFollowing')}
                            </Typography>
                            <Box display="flex" flexDirection={smDown ? 'column' : 'row'} sx={{alignItems: 'left'}}>   
                                {   
                                    hasSubsets && 
                                    subsets.map(({id,quantity},ix) => (
                                        <Button key={id} variant="contained" size="small" color="secondary" onClick={() => scrollToID(id)} sx={{whiteSpace:'nowrap',mr:1,mt:1}}>
                                            {t(`components.order.orderProgress.${id}.title`)} ({quantity}x)
                                        </Button>
                                    ))
                                }
                                {
                                    hasOrderTable && 
                                    <Button variant="contained" size="small" color="secondary" onClick={() => scrollToID(ORDER_TABLE_ID)} sx={{whiteSpace:'nowrap',mr:1,mt:1}}>
                                        {t('components.order.orderProgress.orderTable')}
                                    </Button>
                                }
                                {   
                                    hasSupport && 
                                    <Button variant="contained"  size="small" color="secondary" onClick={() => scrollToID(SUPPORT_ID)} sx={{mr:1,mt:1}}>
                                        {t('components.order.orderProgress.support')}
                                    </Button>
                                }
                            </Box>
                        </Box>
                    }

                    {
                        (!hasOrderTable && !hasSubsets) && 
                        <Box p={2} align="center">
                            <NoDataPrompt>
                                {t('components.order.orderProgress.noQuickLinks')}
                            </NoDataPrompt>
                        </Box>
                    }
                </ContentContainer>
            }
            
            {   
                !quickLinks && 
                <ContentContainer id={'deliveries'} >
                    {
                        statusMap
                            .filter(x => !x?.empty) // Exlude empty
                            .map(({id, inputRequired, inputAvailable, inputConfirm, deliveriesSubset, hideProgress = false}, ix) => {
                            return(
                                <ContentContainer p={2} id={id} key={ix} cancelled = {hideProgress} sx={{m:-2}}>
                                    <Box>
                                        <Title variant="h5">
                                            {t(`components.order.orderProgress.${id}.title`)}
                                        </Title>
                                        <Typography gutterBottom>
                                            {t(`components.order.orderProgress.${id}.subtitle`)}
                                        </Typography>
                                    </Box>
                                    <ContentContainer> 
                                        {
                                            deliveriesSubset.map(delivery => {
                                                const {id} = delivery;
                                                return (
                                                    <Box key={id} id={id}>
                                                        <DeliveryProgress 
                                                            id                  = {id}
                                                            userInputRequired   = {inputRequired}
                                                            userInputAvailable  = {inputAvailable} 
                                                            userInputConfirm    = {inputConfirm}
                                                            order               = {order} 
                                                            delivery            = {delivery} 
                                                            isOwner             = {isOwner}
                                                            hideProgress        = {hideProgress}
                                                        />
                                                    </Box>
                                                )
                                            })
                                        }
                                        {
                                            DEBUG && deliveriesSubset.length > 0 &&
                                            <AdminBox>
                                                <JSONViewer src={deliveriesSubset}/>
                                            </AdminBox>
                                        }
                                    </ContentContainer>
                                </ContentContainer>
                            )
                        })
                    }
                </ContentContainer>
            }
        </RootContainer>
    )
})

export default OrderProgress;