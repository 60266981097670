/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Credit Card Details Form
********************************************************************************************
Based loosely off: 
  https://github.com/mui-org/material-ui/issues/16037
  https://codesandbox.io/s/stripe-0xez4?file=/src/App.tsx:252-330

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       28th December 2020

*******************************************************************************************/

import React    from "react";
import Grid     from "@mui/material/Grid";
import {
  StripeTextFieldNumber,
  StripeTextFieldExpiry,
  StripeTextFieldCVC
}               from "./StripeTextFields";
import {
    FormAlert,
    FormFailBox
 }              from "components";

const capFirst = str => (str.charAt(0).toUpperCase() + str.slice(1));
const sanitize = err => capFirst((err || '').replace("Your card's","").replace("Your","").trim());


export class StripeCreditCardDetails extends React.Component {

    state = {
        disabled                    : this.props.disabled,
        creditCardNumberComplete    : false,
        expirationDateComplete      : false,
        cvcComplete                 : false,
        
        cardNumberError             : false,
        expiredError                : false,
        cvcError                    : false,
        messageSuccess              : this.props.messageSuccess,
        messageError                : this.props.messageError,
    };

    onElementChange = (field, errorField) => ({complete, error = { message: null }}) => {
        this.setState({ 
            [field]         : complete, 
            [errorField]    : sanitize(error.message)
        });
        const expectedState     = { ...this.state };
        expectedState[field]    = complete;
    };

    componentWillReceiveProps(nextProps) {
        // Any time props.email changes, update state.
        if (nextProps.disabled !== this.props.disabled || nextProps.messageSuccess !== this.props.messageSuccess || nextProps.messageError !== this.props.messageError) {
            this.setState({
                disabled        : nextProps.disabled,
                messageSuccess  : nextProps.messageSuccess,
                messageError    : nextProps.messageError
            });
        }
    }

    render() {
        const { cardNumberError, expiredError, cvcError, messageSuccess, messageError} = this.state;
        const failure = cardNumberError || expiredError || cvcError;
        const errorsQuantity = [cardNumberError, expiredError, cvcError].filter(Boolean).length;
        return (
            <div style={{width:'100%'}}>
                <FormFailBox failure={failure}>
                    <div style={{padding:2}}>
                        <Grid container spacing={1}>
                            {
                                [messageSuccess, messageError, failure].some(Boolean) && 
                                <Grid item xs={12}>
                                    { messageSuccess && 
                                        <FormAlert severity="info"> {messageSuccess} </FormAlert>
                                    }
                                    { messageError && 
                                        <FormAlert severity="error"> {messageError} </FormAlert>
                                    }
                                    { failure &&
                                        <FormAlert severity="error">
                                            Please fix the following {errorsQuantity > 1 ? `${errorsQuantity}x errors` : 'error'}
                                        </FormAlert>
                                    }
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <StripeTextFieldNumber
                                    disabled            = {this.state.disabled}
                                    label               = "Credit Card Number"
                                    error               = {Boolean(cardNumberError)}
                                    helperText          = {Boolean(cardNumberError) && cardNumberError}
                                    labelErrorMessage   = {cardNumberError}
                                    onChange            = {
                                        this.onElementChange(
                                            "creditCardNumberComplete",
                                            "cardNumberError"
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <StripeTextFieldExpiry
                                    disabled            = {this.state.disabled}
                                    label               = "Expiry Date"
                                    error               = {Boolean(expiredError)}
                                    helperText          = {Boolean(expiredError) && expiredError}
                                    labelErrorMessage   = {expiredError}
                                    onChange            = {
                                        this.onElementChange(
                                            "expirationDateComplete",
                                            "expiredError"
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <StripeTextFieldCVC
                                    disabled            = {this.state.disabled}
                                    label               = "CVC Code"
                                    error               = {Boolean(cvcError)}
                                    helperText          = {Boolean(cvcError) && cvcError}
                                    labelErrorMessage   = {cvcError}
                                    onChange            = {
                                        this.onElementChange(
                                            "cvcComplete",
                                            "cvcError"
                                        )
                                    }
                                />
                            </Grid>
                        </Grid>
                        {this.props.children}
                    </div>
                </FormFailBox>
            </div>
        );
    }
}

/*
export const StripeCreditCardDetails = React.forwardRef((props,ref) => {
    const [state, setState] = React.useState({
      cardNumberComplete: false,
      expiredComplete: false,
      cvcComplete: false,
      cardNumberError: null,
      expiredError: null,
      cvcError: null
    });
  
    const onElementChange = (field, errorField) => ({
      complete,
      error = { message: null }
    }) => {
      setState({ ...state, [field]: complete, [errorField]: error.message });
    };
  
    const { cardNumberError, expiredError, cvcError } = state;
  
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <StripeTextFieldNumber
            error={Boolean(cardNumberError)}
            labelErrorMessage={cardNumberError}
            onChange={onElementChange("cardNumberComplete", "cardNumberError")}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <StripeTextFieldExpiry
            error={Boolean(expiredError)}
            labelErrorMessage={expiredError}
            onChange={onElementChange("expiredComplete", "expiredError")}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <StripeTextFieldCVC
            error={Boolean(cvcError)}
            labelErrorMessage={cvcError}
            onChange={onElementChange("cvcComplete", "cvcError")}
          />
        </Grid>
        <Grid item xs={12}>
            {props.children}
        </Grid>
        <Grid item xs={12}>
            <pre>{JSON.stringify(state, null, 2)}</pre>
        </Grid>
      </Grid>
    );
})
*/


export default StripeCreditCardDetails;
