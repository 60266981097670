
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
TrackIdContext
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       6th May 2022

*******************************************************************************************/
import React                    from 'react';
import { useStateEphemeral }    from 'hooks';

const TrackIdContext = React.createContext(undefined);

export const TrackIdProvider = ({children}) => {
    const [trackId, setTrackId] = useStateEphemeral(undefined,1000);
    const reset                 = React.useCallback(() => setTrackId(undefined), [setTrackId]);

    React.useEffect(() => {
        reset();
        return () => {
            reset();
        }
    },[reset])

    const value = {
        trackId,
        setTrackId,
        reset
    }
    return (
        <TrackIdContext.Provider value={value}>
            {children}
        </TrackIdContext.Provider>
    )
}

export const useTrackId = () => {
    const context = React.useContext(TrackIdContext)
    if (context === undefined) {
        throw new Error('useTrackId must be used within a TrackIdProvider')
    }
    return context
}
