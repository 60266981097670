/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Coupon Apply Form
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       16th November 2021

*******************************************************************************************/
import React                            from 'react';
import { Grid }                         from '@mui/material';
import { Form }                         from 'components';
import { useTranslation }               from 'contexts';
import { 
    TextField,
    // showErrorOnBlur as showError,
    showErrorOnChange   as showError,
}                                       from 'mui-rff';
import {
    ConfirmationNumber  as CouponIcon,
    Restore             as RestoreIcon
}                                       from '@mui/icons-material';

// const capFirst = (x) => x[0].toUpperCase() + x.slice(1,x.length);

const noop = () => {};
const obj = {};

const MIN_CODE_LENGTH = 4;

const defaultFormData = {
    code : undefined
}

export const CouponApplyForm = ({
    formData                = defaultFormData,
    isEditing               = false,
    disabled                = false,
    onSubmit : handleSubmit = noop,
    onCancel : handleCancel = noop,
    FormProps               = obj,
    ...rest
}) => {
    const {t} = useTranslation();
    
    const fields = React.useMemo(() => ([{ 
        name        : 'code', 
        label       : t('components.forms.couponApplyForm.couponCode'), 
        helperText  : t('components.forms.couponApplyForm.helperText'), 
        required    : true, 
        xs          : 12 
    }]),[t]);

    const validate = (values) => {
        let errors = {};

        // Required Fields
        let requiredFields  = fields.filter(f => f.required).map(f => f.name)
        requiredFields.forEach(item=>{
            if(values[item] === undefined || values[item] === '')
                errors[item] = errors[item] || t('components.forms.couponApplyForm.required');
        })

        // Regex check for code
        if(values.code && /[^0-9a-zA-Z]/.test(values.code))
            errors.code = errors.code || t('components.forms.couponApplyForm.mustBeAlpha');

        // Check Length
        if(values.code && values.code.length < MIN_CODE_LENGTH)
            errors.code = errors.code || t('components.forms.couponApplyForm.mustBeMinLength',{minLength:MIN_CODE_LENGTH})

        // Errors
        return errors;
    }

    const initialValues = {
        ...formData
    }

    return (
        <Form
            debug               = {false}
            disabled            = {disabled}
            showCancelButton    = {true}
            showResetButton     = {false}
            
            submitText          = {t('components.forms.couponApplyForm.applyCoupon')}
            onSubmit            = {handleSubmit}
            onCancel            = {handleCancel}
            initialValues       = {initialValues}
            validate            = {validate}
            {...FormProps}
            SubmitButtonProps   = {{startIcon : <CouponIcon/> , style:{minWidth:150}}}
            ResetButtonProps    = {{startIcon : <RestoreIcon/>, style:{minWidth:150}, color : 'primary'}}
            render              = {({disabled, form, error, dirtySinceLastSubmit, submitFailed, submitSucceeded, errors, handleSubmit, values, ...rest}) => {
                return (
                    <form onSubmit={handleSubmit} noValidate>
                        <Grid container>
                        {
                            fields.map(({name, helperText, label, xs = 12},ix) => {
                                return (
                                    <Grid key={`text${ix}`} item xs={xs}>
                                        <TextField 
                                            disabled    = {isEditing || disabled} 
                                            name        = {name} 
                                            label       = {label}
                                            showError   = {showError}
                                            variant     = "filled"
                                            helperText  = {helperText}
                                        />
                                    </Grid>
                                )
                            })
                        }
                        </Grid>
                    </form>
                )
            }}
        />
    )
}

export default CouponApplyForm;