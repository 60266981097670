/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Skeleton Tabs
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       21st July 2022

*******************************************************************************************/

import React                            from 'react';
import { styled, Box }                  from '@mui/material';
import { Skeleton }                     from '@mui/material';

const Root = styled(Box)(({theme}) => ({
    width       : "100%",
    display     : "flex",
    overflow    : 'scroll',
    '& > * + *' : {
        marginLeft : theme.spacing(0.5)
    }
}));

export const SkeletonTabs = ({quantity = 3, height = 35, width = 100}) => (
    <Root height={height}>
        {
            Array(quantity).fill(undefined).map((_,ix) => (
                <Skeleton 
                    key     = {ix} 
                    variant = "rectangular" 
                    width   = {width} 
                    height  = "100%"
                />
            ))
        }
        <Box flexGrow={1}/>
    </Root>
);

export default SkeletonTabs;