/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Alerts
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       24th July 2022

*******************************************************************************************/
import React            from 'react';
import { styled, Box }  from '@mui/material';
import moment           from 'moment';

const StickyContainer = styled(Box)({
    width           : '100%',
    position        : 'sticky',
    top             : 0,
    zIndex          : 1000
})

const AlertEmailUnverified      = React.lazy(() => import('components/AlertEmailUnverified'));
const AlertCartAdd              = React.lazy(() => import('components/AlertCartAdd'));
const AlertCartRemove           = React.lazy(() => import('components/AlertCartRemove'));
const AlertNoInternet           = React.lazy(() => import('components/AlertNoInternet'));
const AlertWaitingForNetwork    = React.lazy(() => import('components/AlertWaitingForNetwork'));
const AlertVersionUpdate        = React.lazy(() => import('components/AlertVersionUpdate'));
const AlertPublicCoupon         = React.lazy(() => import('components/AlertPublicCoupon'));
const AlertLaunch               = React.lazy(() => import('components/AlertLaunch'));
const AlertDisclaimer           = React.lazy(() => import('components/AlertDisclaimer'));

export const Alerts = () => (
    <>
        <AlertLaunch launchDate={moment("02/07/2023 12:17pm", "DD/MM/YYYY hh:mma")} />
        <StickyContainer>
            <AlertVersionUpdate/>
            <AlertDisclaimer />
            <AlertNoInternet />
            <AlertWaitingForNetwork />
            <AlertEmailUnverified />
            <AlertCartAdd />
            <AlertCartRemove />
        </StickyContainer>
        <AlertPublicCoupon />
    </>
)

export default Alerts;