
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
AuthProviderWithHistory
********************************************************************************************
Base on: https://auth0.com/blog/complete-guide-to-react-user-authentication/

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       23rd December 2020

*******************************************************************************************/

import React                from "react";
import { useHistory }       from "react-router-dom";
import { Auth0Provider }    from "@auth0/auth0-react";
import config               from "../config";

// AUTH0
const {
  auth0 : {
    domain,
    clientId
  }
}                         = config;
const scope               = "openid email profile roles";     // <<< TO ENSURE A JWT
const audience            = `https://${domain}/api/v2/`       // <<< TO ENSURE A JWT
const useRefreshTokens    = true;
const cacheLocation       = "localstorage"

export const Auth0ProviderWithHistory = ({ children }) => {
  
  const history = useHistory();

  // https://community.auth0.com/t/redirect-after-login-page/63011/3
  const handleRedirectCallback  = React.useCallback( (appState) => (
    history.push(appState?.returnTo || window.location.pathname)
  ),[history]);

  return (
    <Auth0Provider
      domain                = { domain }
      clientId              = { clientId }
      redirectUri           = { window.location.origin }
      scope                 = { scope }
      useRefreshTokens      = { useRefreshTokens }
      audience              = { audience } 
      onRedirectCallback    = { handleRedirectCallback }
      cacheLocation         = { cacheLocation }
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;