/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
AccountCredits Context
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       2nd June 2023

*******************************************************************************************/
import React                    from 'react';
import moment                   from 'moment';
import {useNetwork}             from './NetworkContext';
import {
    useCancelToken,
    useStateEphemeral,
    useCanQuery
}                               from 'hooks';
import { useUser }              from './UserContext';
import { useAlert }             from './AlertContext';

const CREDITS_NAME = "Cosmic Credits";

// The Context Object
export const AccountCreditsContext = React.createContext(undefined);

// Voltage Provider
export const AccountCreditsProvider = ({children}) => {
    
    const {allowQueryForUser}                   = useCanQuery();
    const {axios, socketUsers:socket}           = useNetwork();
    const {cancelToken, isCancel}               = useCancelToken();
    const {isAuthenticated,isAdmin}             = useUser();
    const {alert}                               = useAlert()

    const [balance,         setBalance]         = React.useState(-1);
    const [working,         setWorking]         = React.useState(false);
    const [queried,         setQueried]         = React.useState(undefined);
    const [isRetrieved,     setRetrieved]       = React.useState(false);
    
    // Messages
    const [messageSuccess,  setMessageSuccess]  = useStateEphemeral(undefined);
    const [messageError,    setMessageError]    = useStateEphemeral(undefined,5000);

    const clear = React.useCallback( () => {
        setBalance(0)
        setQueried(undefined);
        setRetrieved(false);
    }, []);

    const queryAccountBalance = React.useCallback( () => new Promise( (resolve,reject) => {
        setWorking(true);
        axios.get(`/api/user/account/credits/balance`, {cancelToken})
            .then(response => {
                // console.log(response);
                return response;
            })
            .then(({data}) => data)
            .then(data => {
                setMessageSuccess("Queried account balance");
                return data;
            })
            .then(resolve)
            .catch(err => {
                if(isCancel(err)) return reject(err);
                setMessageError(err?.message  || "Error retrieving account balance");
                reject(err);
            })
            .finally(() => {
                setRetrieved(true);
                setWorking(false);
                setQueried(moment());
            })
    }),[axios, cancelToken, isCancel, setMessageError, setMessageSuccess]);

    const refresh = React.useCallback((reset) => {
        if(allowQueryForUser){
            if(reset) clear();
            queryAccountBalance()
                .then(({balance:{balance,notes}}) => balance)
                .then(setBalance)
                .catch(clear)
        }else{
            clear();
        }
    },[allowQueryForUser, clear, queryAccountBalance])
    
    React.useEffect(refresh,[refresh]);

    // Admin Can Issue Credits
    const issueCredits = React.useCallback((userId,amount) => new Promise((resolve,reject) => {

        if(!isAuthenticated || !isAdmin){
            alert('Unauthorized','error');
            return reject('error');
        }
        if(!userId){
            alert('userId is required','error')
            return reject('error');
        }
        if(!amount || isNaN(amount) || amount < 0){
            alert('amount must be positive numeric','error');
            return reject('error');
        }

        axios
            .post(`/api/admin/accounting/credits/${userId}`,{amount},{cancelToken})
            .then(({data}) => data)
            .then((data) => {
                console.log(data);
                alert('Credits Issued','success')
            })
            .then(resolve)
            .catch(err => {
                if(isCancel(err)) return reject('cancelled');
                alert(err.message || 'Error Issuing Credits','error')
                reject(err)
            })
    }),[alert, axios, cancelToken, isAdmin, isAuthenticated, isCancel])

    const purchaseArchive = React.useCallback((archiveId) => new Promise((resolve,reject) => {
        if(!isAuthenticated){
            alert('Unauthorized','error');
            return reject('error');
        }
        if(!archiveId){
            alert('archiveId is required','error')
            return reject('error');
        }
        setWorking(true);
        axios
            .post(`/api/examples/${archiveId}/purchase`, {}, {cancelToken})
            .then(({data}) => data)
            .then((data) => {
                console.log(data);
                alert('Purchased','success')
            })
            .then(resolve)
            .catch(err => {
                if(isCancel(err)) return reject('cancelled');
                alert(err.message || 'Error Purchasing Archive','error')
                reject(err)
            })
            .finally(() => {
                setWorking(false);
            })

    }),[alert, axios, cancelToken, isAuthenticated, isCancel]);

    // Refresh on SocketIO Instruction
    React.useEffect(() => {
        if(allowQueryForUser && socket){
            socket.on('refresh_credits_balance', refresh)
            return () => {
                socket.off('refresh_credits_balance', refresh);
            }
        }
    },[allowQueryForUser, socket, refresh]) 

    // Context values
    const value = {
        balance,
        working,
        queried,
        refresh,
        isRetrieved,
        messageSuccess,
        messageError,
        issueCredits,
        purchaseArchive,
        CREDITS_NAME
    };

    return (
        <AccountCreditsContext.Provider value={value}>
            {children}
        </AccountCreditsContext.Provider>
    )
}

export const AccountCreditsConsumer =  ({children}) => {
    return (
        <AccountCreditsContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('AccountCreditsConsumer must be used within AccountCreditsProvider');
                }
                return children(context)
            }}
        </AccountCreditsContext.Consumer>
    )
}

// useVoltage Hook
export const useAccountCredits = () => {
    const context = React.useContext(AccountCreditsContext);
    if(context === undefined)
        throw new Error('useAccountCredits must be used within AccountCreditsProvider');
    return context;
}