/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Completion Bar
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       8th August 2021

*******************************************************************************************/
import React                            from 'react';
import { 
    styled, 
    lighten,
    Box, 
    Typography as TypographyMUI
}                                       from '@mui/material';
import { withTranslation }              from './hoc';

const Root = styled(Box,{
    shouldForwardProp : prop => prop !== 'cancelled' && prop !== 'color'
})(({theme, cancelled = false, color = 'black'}) => ({
    position        :'relative',
    borderRadius    : 5,
    overflow        : 'hidden',
    marginTop       : theme.spacing(1),
    marginBottom    : theme.spacing(1),
    border          : color ? `1px solid ${cancelled ? theme.palette.error.main : color}` : null,
    background      : lighten(cancelled ? theme.palette.error.main : theme.palette.secondary.main,0.85)
}));

const Typography = styled(TypographyMUI,{
    shouldForwardProp : prop => prop !== 'cancelled'
})(({theme, cancelled=false}) => ({
    color           : 'black',
    position        : 'relative',
    textAlign       : 'center',
    fontSize        : 'inherit',
    ...(cancelled && {
        color : theme.palette.error.main
    })
}));

const ProgressBar = styled(Box,{
    shouldForwardProp : prop => prop !== 'pcntComplete' && prop !== 'color'
})(({theme, pcntComplete = 0, color = 'black'}) => ({
    position        : 'absolute',
    left            : 0,
    top             : 0,
    height          : '100%',
    width           : `${pcntComplete}%`,
    borderRight     : pcntComplete > 5 && pcntComplete < 100 && color ? `1px solid ${color}` : 'none',
    background      : theme.palette.secondary.main
}))

const obj = {}

export const CompletionBar = withTranslation( ({
    t,
    children, 
    icon                = undefined, 
    suffix              = undefined, 
    pcntComplete        = 0, 
    cancelled           = false, 
    cancelledText       = undefined, 
    color               = undefined, 
    background          = undefined, 
    borderColor         = undefined, 
    cancelledColor      = undefined,
    typographyProps     = obj, 
    ...props
}) => (
    <Root cancelled = {cancelled} sx = {{bgcolor : background, ...props.sx}} color={borderColor}>
        {
            !cancelled && 
            <ProgressBar pcntComplete = {cancelled ? 0 : pcntComplete} sx = {{bgcolor: color}} color={borderColor}/>
        }
        <Box display="flex">
            {
                icon &&
                <Box sx={{my:'auto',zIndex:1}} display="flex">
                    {icon}
                </Box>
            }
            <Box flexGrow={1}>
                <Typography component = "div" {...props} sx={{color:borderColor,...props.sx}} {...typographyProps} cancelled = {cancelled}>
                    {
                        cancelled
                            ? <> {cancelledText || t('common.cancelled')} </>
                            : <> {children || `${pcntComplete.toFixed(1)}%`} {suffix} </>
                    }
                </Typography>
            </Box>
        </Box>
    </Root>
));

export default CompletionBar;
