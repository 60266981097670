/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Item Paper
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       2nd July 2021

*******************************************************************************************/

import React    from 'react';
import { 
    styled, 
    Paper as PaperMUI
}               from '@mui/material';

const Paper = styled(PaperMUI)(({theme}) => ({
    width       :  '100%',
    padding     : theme.spacing(2),
    background  : theme.palette.background.paper,
}))

export const ItemPaper = React.forwardRef(({children, ...props}, ref) => {
    const {id, ...rest}  = props;
    return (
        <Paper 
            id          = {id || 'itemPaper'} 
            ref         = {ref}
            {...rest}
        >
            {children}
        </Paper>
    )
});

export default ItemPaper;