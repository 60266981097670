/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Logout Button
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       22nd December 2020

*******************************************************************************************/

import React                            from 'react'
import {useMediaQuery}                  from '@mui/material';
import ExitToAppIcon                    from '@mui/icons-material/ExitToApp';
import { useUser, useTranslation }      from 'contexts';  
import { 
    IconButton, 
    Button, 
    RefreshIcon 
}                                       from 'components';

export const Logout = ({children, component : Component = Button, icon : Icon = ExitToAppIcon, force = false, ...props}) => {
    const [t]                           = useTranslation();
    const {
        isLoading, 
        isAuthenticated, 
        logout
    }                                   = useUser();
    const [loading, setLoading]         = React.useState(false);

    // Reset Loading
    const resetLoading                  = React.useCallback( () => (
        setLoading(false)
    ), []);

    // Button Click Handler
    const handleClick                   = React.useCallback( (e) => {
        setLoading(true);
        logout(e);
    }, [logout]);

    // Reset loading when page loads
    React.useEffect(() => {
        window.addEventListener('load', resetLoading);
        return () => {
            window.removeEventListener('load', resetLoading)  
        }
    },[resetLoading])

    // Breakpoints
    const smUp      = useMediaQuery(theme => theme.breakpoints.up('sm'));

    // Dont Show
    const show      = React.useMemo(() => force || isAuthenticated, [force, isAuthenticated]);

    // Disabled
    const disabled  = React.useMemo(() => isLoading || loading, [isLoading, loading]);
    
    if(!show) 
        return null;

    return (
        <React.Fragment>
            
            {
                !smUp &&
                <IconButton disabled = {disabled} onClick={handleClick} size="large">
                    <Icon/>
                </IconButton>
            }

            {
                smUp &&
                <Component 
                    disabled    = {disabled}
                    startIcon   = {loading ? <RefreshIcon loading={loading}/> : <Icon/>} 
                    {...props} 
                    onClick     = {handleClick}
                >
                    {children || t('common.logout')}
                </Component> 
            }

        </React.Fragment>
    )
}
export default Logout;