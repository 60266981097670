/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Google Maps Static
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       2nd July 2021

*******************************************************************************************/

import React                        from 'react';
import formatcoords                 from 'formatcoords';
import { v4 as uuidv4 }             from 'uuid';
import {
    styled,
    useTheme,
    alpha,
    Box,
    Paper 
}                                   from '@mui/material';
import { StaticGoogleMap, Marker }  from 'react-static-google-map';
import { withTranslation }          from './hoc';
import config                       from '../config';

const {
    googleMaps : {
        apiKey : GOOGLE_MAPS_API_KEY
    }
} = config;

const DEFAULT_BORDER_RADIUS = 5;

const GeolocationContainer = styled(Paper,{
    shouldForwardProp: prop => prop !== 'borderRadius'
})(({theme, borderRadius = DEFAULT_BORDER_RADIUS}) => ({
    position                    : 'absolute',
    padding                     : theme.spacing(0.5),
    top                         : 0,
    left                        : 0,
    fontSize                    : '0.5rem',
    borderTopLeftRadius         : borderRadius,
    borderTopRightRadius        : 0,
    borderBottomLeftRadius      : 0,
    borderBottomRightRadius     : borderRadius,
    color                       : theme.palette.primary.contrastText,
    background                  : alpha(theme.palette.primary.main,0.75)
}));

const MapsContainer = styled(Box)({
    position    : 'relative',
    width       : '100%',
    height      : '100%'
});

const parseSize = (width,height,multiple) => `${parseInt(multiple*width)}x${parseInt(multiple*height)}`

const DEFAULT_STYLE = {}

export const GoogleMapsStatic = withTranslation( ({
    t,
    children,
    lat,
    lng,
    width           = 500,
    height          = 500,
    zoom            = 15,
    multiple        = 2,
    style           = DEFAULT_STYLE,
    borderRadius    = DEFAULT_BORDER_RADIUS,
    ...props 
}) => {
    
    const uuid      = React.useMemo(() => uuidv4(), []);
    const className = React.useMemo(() => `staticMap-${uuid}`, [uuid]);
    const theme     = useTheme();
    const pointer   = React.useMemo(() => Boolean(props.onClick), [props.onClick]);

    React.useEffect(() => {

        const style = document.createElement('style')
        style.innerHTML = `
            .${className} {
                width           : 100%;
                height          : 100%;
                max-width       : 100%;
                position        : relative;
                object-fit      : cover;
                overflow        : hidden;
                cursor          : ${pointer ? 'pointer' : 'cursor'};
                border-radius   : ${borderRadius}px;
                border          : 1px solid ${theme.palette.divider};
                padding         : 0px;
            }
        `
        document.head.appendChild(style);
        return () => {
            document.head.removeChild(style);
        }
    },[borderRadius, className, pointer, theme.palette.divider]);

    return (
        <StaticGoogleMap 
            className   = {className}
            size        = {parseSize(width,height,multiple)} 
            zoom        = {zoom} 
            {...props} 
            apiKey      = {GOOGLE_MAPS_API_KEY}
            alt         = { t('components.googleMapsStatic.locationLatLng', {lat : String(lat || 'NA'), lng : String(lng || 'NA') }) }
            style       = {{
                width           : '100%',
                height          : '100%',
                position        : 'relative',
            }}
        >
            <Marker location={{lat,lng}} color="red" />
            {children}
        </StaticGoogleMap>
    )
});

const GeoLocationOverlay = ({geolocation, borderRadius=DEFAULT_BORDER_RADIUS, ...props}) => {
    if(!geolocation || !geolocation?.success) 
        return null;
    return (
        <GeolocationContainer borderRadius={borderRadius} elevation={0}>
            {formatcoords(geolocation?.lat, geolocation?.lng).format()}
        </GeolocationContainer>
    )
}

export const GoogleMapsStaticWithGeolocation = ({lat,lng, borderRadius=DEFAULT_BORDER_RADIUS, containerprops={}, ...props}) => (
    <MapsContainer {...containerprops}>
        <GoogleMapsStatic lat={lat} lng={lng} {...props}/>
        <GeoLocationOverlay geolocation={{lat,lng,success:true}} borderRadius={borderRadius}/>
    </MapsContainer>
)

export default GoogleMapsStatic;

