/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Pages -- About
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       20th November 2020

*******************************************************************************************/
import React                from 'react';
import { Box }              from '@mui/material';
import { 
    PageContainer,
    Skeleton,
    ContentfulPage
}                           from 'components';

const SkeletonComponent = () => (
    <Box width="100%" sx={{"& > * + *" : {mt:2}}}>
        <Box>
            <Skeleton width="40%" height="30px"/>
        </Box>
        <Box width="100%" sx={{aspectRatio:'1.41',maxHeight:'50vh'}}>
            <Skeleton width="100%" height="100%"/>
        </Box>
        <Box>
            <Skeleton width="60%" height="30px"/>
        </Box>
        <Box display="flex" width={"100%"} sx={{aspectRatio:'5',maxHeight:'50vh'}}>
            <Box flexGrow={1} mr={1}>
                <Skeleton width="100%" height="100%"/>
            </Box>
            <Box flexGrow={1} ml={1}>
                <Skeleton width="100%" height="100%"/>
            </Box>
        </Box>
    </Box>
)

export const About = () => (
    <ContentfulPage 
        slug                        = "about" 
        container                   = {PageContainer}
        includeCoverImageAsBanner   = {false}
        includeImageDescription     = {true}
        SkeletonComponent           = {SkeletonComponent}
    />
)

export default About;