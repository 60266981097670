/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Skeleton Blog Post
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       7th January 2024

*******************************************************************************************/
import React                    from 'react';
import { Box }                  from '@mui/material';
import { Skeleton }             from 'components';

export const SkeletonPage = () => (
    <Box sx={{"& > * + *" : {mt:2}}}>
        <Box sx={{aspectRatio:"2"}}>
            <Skeleton width="100%" height="100%" />
        </Box>
        <Skeleton height="30px" width="80%" sx={{ml:'auto'}} />
        <Box>
            <Skeleton height="100vh" />
        </Box>
    </Box>
)

export default SkeletonPage;