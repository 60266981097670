/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Price With Sale
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       26th July 2021

*******************************************************************************************/
import React                from 'react';
import { 
    styled,
    Typography 
}                           from '@mui/material';
import { Price }            from 'components';

const Span = styled('span')(({theme}) => ({
    fontSize        : '0.4em',
    paddingLeft     : theme.spacing(0.5),
    paddingRight    : theme.spacing(0.5),
}))

const PriceOriginal = ({
    price           = 0, 
    priceOriginal   = undefined,
    shrinkOnSale    = true
}) => {
    const onSale = React.useMemo(() => !isNaN(priceOriginal) && price < priceOriginal, [price, priceOriginal]);
    if(!onSale) return null;
    return (
        <>
            <Span>was</Span>
            <Price price={priceOriginal} sale={true} unit={null} shrinkOnSale={shrinkOnSale} />
            <Span>now</Span>
        </>
    )
};

export const PriceWithSale = ({
    price           = 0,
    priceOriginal   = undefined,
    unit            = "ea",
    shrinkOnSale    = true,
    ...props
}) => {
    return (
        <Typography component='div' {...props}>
            <PriceOriginal price={price} priceOriginal={priceOriginal} shrinkOnSale={shrinkOnSale}/>
            <Price price={price} unit={unit}/>
        </Typography>
    )
};

export default PriceWithSale;