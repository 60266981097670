/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Quote
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       4th December 2021

*******************************************************************************************/
import { styled, alpha, Box } from '@mui/material';

export const Quote = styled(Box)(({theme}) => ({
    background  : alpha(theme.palette.primary.light,theme.palette.mode === 'light' ? 0.10 : 0.50),
    borderLeft  : `${theme.spacing(0.75)} solid ${theme.palette.divider}`,
    padding     : theme.spacing(2),
    '& > * + *' : {
        marginTop: theme.spacing(2)
    }
}))

export default Quote;