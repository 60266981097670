/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
DateChip Component
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       25th June 2021

*******************************************************************************************/

import React                            from 'react';
import { Chip }                         from '@mui/material';
import { useLocale }                    from 'contexts';
import { withTranslation }              from './hoc';

export const DateChip = withTranslation( ({
    t,
    date,
    prefix  = undefined,
    live    = false,
    ...props
}) => {

    const {formatDateTime} = useLocale();

    // Memoised Formatting Function
    const f = React.useCallback( momentObject => {
        
        // Build the Parts
        let parts = [prefix, formatDateTime(momentObject)];
        
        // Join
        return parts.filter(Boolean).join(' '); 

    }, [formatDateTime, prefix]);
        
    // State of Bounds
    const [label, setLabel] = React.useState(f(date))

    // Update Timestamp
    React.useEffect(() => {
        if(!date && live){
            let interval = setInterval( () => {
                setLabel( f(date) );
            }, 1000);
            return () => {
                clearInterval(interval);
            }
        }
        if(!date && !live)
            setLabel(t('common.now'));
        if(date)
            setLabel( f(date) );
    }, [date, f, live, t])

    // Done, Render
    if(!label) return null;

    return (
        <Chip label={label} {...props} style={{minWidth:150}}/>
    )
});

export default DateChip;