/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
use on screen
********************************************************************************************
A hook, which takes a ref and 

Taken From:
  https://stackoverflow.com/a/65008608/1834057

Usage:
  const DummyComponent = () => {
    const ref = useRef()
    const isVisible = useOnScreen(ref)
    return <div ref={ref}>{isVisible && `Yep, I'm on screen`}</div>
  }

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       28th November 2021

********************************************************************************************/
import React                from 'react';

export const useOnScreen = (ref) => {

  // Intersecting 
  const [onScreen, setOnScreen] = React.useState(false)

  // OnScreen Handler
  const handleOnScreen = React.useCallback(([entry]) => {
    let isOnScreen = entry.isIntersecting;
    if(isOnScreen !== onScreen)
      setOnScreen(isOnScreen);
  },[onScreen]);
  
  // Side Effect for ref, to see when it goes on screen
  React.useEffect(() => {

    // Define the observer
    const observer = new IntersectionObserver(handleOnScreen)

    // Observe intersection
    observer.observe(ref.current)

    // Remove the observer as soon as the component is unmounted
    return () => { 
      observer.disconnect() 
    }

  }, [ref, handleOnScreen])

  // Done
  return onScreen;
};


// Hook
// https://usehooks.com/useOnScreen/
/*
export const useOnScreen = (ref, rootMargin = "0px") => {

  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = React.useState(false);

  React.useEffect(() => {
    
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin,
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      observer.unobserve(ref.current);
    };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return isIntersecting;
}
*/

export default useOnScreen;