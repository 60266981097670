/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Thumbnail
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       24th March 2023

*******************************************************************************************/
import React                        from 'react';
import {
    useTheme,
    Box,
    Grid
}                                   from '@mui/material';
import { 
    Logo,
    DraggableDialog,
    CardMediaWithLoading,
    CommentsThread,
    ItemPaper,
    ImageDownloader
}                                   from 'components';
import { 
    CommentsThreadProvider,
}                                   from 'contexts';

const obj = {};

export const Thumbnail = ({
    children, 
    thumbnail                   = undefined, 
    download : downloadUrl      = undefined, 
    title                       = "Image Viewer", 
    alt                         = "", 
    component : Component       = CardMediaWithLoading, 
    componentProps              = obj, 
    thumbnailSize               = 50, 
    size                        = 400, 
    src                         = undefined, 
    render                      = undefined, 
    dialogProps                 = obj, 
    commentsThreadReference     = undefined
}) => {
    const theme             = useTheme();
    const [open, setOpen]   = React.useState(false);
    const handleOpen        = React.useCallback( () => setOpen(true),[]);
    const handleClose       = React.useCallback( () => setOpen(false),[]);
    return (
        <React.Fragment>
            {
                render && 
                render({open, handleOpen, thumbnail, src, alt})
            }
            {
                !render &&
                <>
                
                    {
                        src 
                            ? (
                                <CardMediaWithLoading
                                    skeletonAspectRatio     = "1"
                                    skeletonShowLoading     = {false} 
                                    skeletonMinHeight       = {thumbnailSize}
                                    component               = "img" 
                                    src                     = {thumbnail || src} 
                                    alt                     = {alt}
                                    sx                      = {{width:thumbnailSize,height:thumbnailSize,cursor:'pointer'}}
                                    onClick                 = {handleOpen}
                                />
                            )
                            : (
                                <Box display="flex" sx={{aspectRatio:'1', height:thumbnailSize, borderRadius:theme => theme.spacing(1), border:theme => `1px solid ${theme.palette.divider}`}}>
                                    <Box sx={{m:'auto'}}>
                                        <Logo p={0} to={null} maxHeight={0.5 * thumbnailSize} minWidth={0} dark={theme.palette.mode !== 'dark'} sx={{maxWidth:0.75*thumbnailSize}}/>
                                    </Box>
                                </Box>
                            )
                    }
                
                </>
            }

            <DraggableDialog
                title       = {title}
                open        = {open}
                onClose     = {handleClose}
                showButtons = {false}
                maxWidth    = "sm"
                {...dialogProps}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} md={commentsThreadReference ? 5 : 12}>
                        <ItemPaper 
                            sx={{
                                width   : '100%', 
                                // maxWidth:size, 
                                mx      : 'auto', 
                                '& > * + *' : {mt:1},
                                ...(commentsThreadReference && {
                                    position    : 'sticky',
                                    top         : 0
                                })
                            }}
                        >
                            <Box>
                                {children}
                            </Box>
                            <Box sx={{maxWidth:size,mx:'auto',position:'relative'}}>
                                <Component
                                    skeletonAspectRatio = "1"
                                    skeletonShowLoading = {true}
                                    component           = "img" 
                                    src                 = {src} 
                                    alt                 = {alt}
                                    {...componentProps}
                                />
                                {
                                    downloadUrl &&
                                    <Box sx={{position:"absolute", bottom:0, right:0, p:1}}>
                                        <ImageDownloader 
                                            imageUrl = {downloadUrl} 
                                        />
                                    </Box>
                                }
                            </Box>
                            
                        </ItemPaper>
                    </Grid>
                    {
                        commentsThreadReference && 
                        <Grid item xs={12} md={7}>
                            <ItemPaper sx={{minHeight:'100%'}}>
                                <CommentsThreadProvider>
                                    <CommentsThread reference={commentsThreadReference} privateThread = {false} showDetachedButtons={false}/>
                                </CommentsThreadProvider>
                            </ItemPaper>
                        </Grid>
                    }
                </Grid>

                
            </DraggableDialog>
        </React.Fragment>
    )
}

export default Thumbnail;