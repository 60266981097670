
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
ServerAlert Context
********************************************************************************************
Boilerplate context, consumer, provider and hook

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       2nd April 2022

*******************************************************************************************/
import React                    from 'react';
import {useNetwork }            from 'contexts';
import { useAlert }             from './AlertContext';

// The Product Context 
const ServerAlertContext = React.createContext(undefined);

// ServerAlert Provider
const ServerAlertProvider = ({children}) => {

    const {socketUsers: socket}         = useNetwork();
    const {alert}                       = useAlert();

    const handleAlert = React.useCallback( (payload) => {
        try {
            const data = typeof payload === 'string' ? {message : payload} : payload ;
            const { message  = undefined, severity = 'info' } = data  || {};
            alert(message,severity);
        } catch(err) {
            //
        }
    }, [alert]);

    // Handle Alert
    React.useEffect(()=>{
        if(socket){
            socket.on('alert', handleAlert);
            return () => {
                socket.off('alert', handleAlert);
            }
        }
    },[handleAlert, socket])

    // Context values
    const value = {
    };

    return (
        <ServerAlertContext.Provider value={value}>
            {children}
        </ServerAlertContext.Provider>
    )
}

// ServerAlert Consumer
const ServerAlertConsumer = ({children}) => {
    return (
        <ServerAlertContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('ServerAlertConsumer must be used within ServerAlertProvider');
                }
                return children(context)
            }}
        </ServerAlertContext.Consumer>
    )
}

// useServerAlert Hook
const useServerAlert = () => {
    const context = React.useContext(ServerAlertContext);
    if(context === undefined)
        throw new Error('useServerAlert must be used within ServerAlertProvider');
    return context;
}

export {
    ServerAlertProvider,
    ServerAlertConsumer,
    useServerAlert
}