/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Menu Title
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       1st August 2022

*******************************************************************************************/
import React                        from 'react';
import { 
    styled,
    Typography as TypographyMUI
}                                   from '@mui/material';

const Typography = styled(TypographyMUI, {
    shouldForwardProp : prop => !['underlined','thickness','fontSize'].includes(prop)
})(({theme, underlined = false, thickness = 3, fontSize = '1.1rem'}) => {
    const baseColor     = theme.palette.mode === 'light' 
        ? theme.palette.primary.dark 
        : theme.palette.text.primary;
    const borderColor   = theme.palette.mode === 'light' 
        ? theme.palette.primary.light 
        : theme.palette.divider;
    return {
        width       : '100%',
        fontSize    : fontSize,
        fontWeight  : 400,
        color       : baseColor,
        ...(underlined && {
            paddingLeft         : theme.spacing(1),
            borderBottomWidth   : thickness,
            borderBottom        : `solid ${borderColor}`,
        })
    }
});

export const MenuTitle = ({fontSize = '1.1rem', thickness = 3, underlined = true, ...props}) => (
    <Typography 
        component   = "div" 
        variant     = "h6"
        underlined  = { underlined }
        thickness   = { thickness }
        fontSize    = { fontSize }
        {...props}
    /> 
);

export default MenuTitle;