
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Ticket Context
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       8th December 2021

*******************************************************************************************/
import React                    from 'react';
import {omitBy,isNil }          from 'lodash';
import moment                   from 'moment';
import {useNetwork}             from './NetworkContext';
import {useUser}                from './UserContext';
import { 
    useCancelToken,
    useCanQuery
}                               from 'hooks';

// The Product Context 
const TicketContext = React.createContext(undefined);

const BASE_API_URL = '/api/user/ticket';

// Ticket Provider
const TicketProvider = ({children}) => {


    const {allowQueryForUser,allowQueryForAdmin}                                    = useCanQuery();
    const {userId}                                                      = useUser();
    const {axios,socketUsers : socket}                                  = useNetwork();
    const {cancelToken,isCancel}                                        = useCancelToken();
    const [enabled,                     setEnabled]                     = React.useState(false);
    const [tickets,                     setTickets]                     = React.useState([]);
    const [queried,                     setQueried]                     = React.useState(undefined);
    const [working,                     setWorking]                     = React.useState(false);
    const [categories,                  setCategories]                  = React.useState([]);
    const [categoriesRequireOrder,      setCategoriesRequireOrder]      = React.useState([]);
    const [categoriesRequireProduct,    setCategoriesRequireProduct]    = React.useState([]);
    const [categoriesRequireDelivery,   setCategoriesRequireDelivery]   = React.useState([]);
    const [categoriesRequireLibrary,    setCategoriesRequireLibrary]    = React.useState([]);

    const [ticketId,    setTicketId]                                    = React.useState(undefined);

    const [admin,                       setAdmin]                       = React.useState(false);
    const [includeRead,                 setIncludeRead]                 = React.useState(true);
    const [includeUnread,               setIncludeUnread]               = React.useState(true);
    const [includeUnassigned,           setIncludeUnassigned]           = React.useState(true);
    const [includeAssigned,             setIncludeAssigned]             = React.useState(true);
    const [includeOpen,                 setIncludeOpen]                 = React.useState(true);
    const [includeClosed,               setIncludeClosed]               = React.useState(false);

    // Filter
    const [productId,                   setProductId]                   = React.useState(undefined);
    const [orderId,                     setOrderId]                     = React.useState(undefined);
    const [libraryId,                   setLibraryId]                   = React.useState(undefined);
    const [deliveryId,                  setDeliveryId]                  = React.useState(undefined);

    // Pagination
    const [page,                        setPage]                        = React.useState(0);
    const [limit,                       setLimit]                       = React.useState(10);
    const [totalItems,                  setTotalItems]                  = React.useState(0);

    const [newTicketCount,              setNewTicketCount]              = React.useState(0);
    const [newTicketMessageCount,       setNewTicketMessageCount]       = React.useState(0);
    const [newTicketClosedCount,        setNewTicketClosedCount]        = React.useState(0);

    const resetNewTicketCount           = React.useCallback( () => setNewTicketCount(0),        []);
    const resetNewTicketMessageCount    = React.useCallback( () => setNewTicketMessageCount(0), []);
    const resetNewTicketClosedCount     = React.useCallback( () => setNewTicketClosedCount(0),  []);

    // Reset Tickets
    const resetTickets      = React.useCallback( () => {
        setTickets([]);
        setTotalItems(0);
        setQueried(undefined);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [libraryId, orderId, productId, deliveryId]);

    // Reset all categories
    const resetCategories   = React.useCallback( () => {
        setCategories([])
        setCategoriesRequireOrder([]);
        setCategoriesRequireProduct([]);
        setCategoriesRequireDelivery([]);
        setCategoriesRequireLibrary([]);
    }, []);

    const clear = React.useCallback(resetTickets, [resetTickets]);

    React.useEffect(() => {
        if(!enabled)
            resetTickets();
    },[enabled, resetTickets])

    // Reset tickets on admin status change
    React.useEffect(resetTickets,[resetTickets]);

    const queryServer = React.useCallback(() => new Promise((resolve,reject) => {
        if(allowQueryForUser){
            setWorking(true);
            const parms = omitBy({
                iUnassigned   : includeUnassigned,
                iAssigned     : includeAssigned,
                iOpen         : includeOpen,
                iClosed       : includeClosed,
                iRead         : includeRead,
                iUnread       : includeUnread,

                productId,
                orderId,
                libraryId,
                deliveryId,

                admin 
            },isNil);
            const queryString = Object.entries(parms).map(([k,v]) => `${k}=${v}`).join('&')
            axios.get(`${BASE_API_URL}?${queryString}&page=${page}&limit=${limit}`,{cancelToken})
                .then(({data:{
                    tickets     = [],
                    totalItems  = 0
                } = {}}) => [tickets,totalItems])
                .then(resolve)
                .catch(err => {
                    if (isCancel(err)) return reject(err);
                    reject(err);
                })
                .finally(()=>{
                    setWorking(false)
                })
        }else{
            reject(new Error('No internet or not authenticated'))
        }
    }),[admin, axios, cancelToken, deliveryId, includeAssigned, includeClosed, includeOpen, includeRead, includeUnassigned, includeUnread, isCancel, libraryId, limit, orderId, page, productId, allowQueryForUser])


    // Refresh 
    const refresh = React.useCallback(() => new Promise(resolve => {
        if(enabled){
            queryServer()
                .then(([tickets,totalItems]) => {
                    setTickets(tickets);
                    setTotalItems(totalItems);
                    resolve(tickets)
                })
                .catch(() => {
                    resetTickets();
                    resolve([]);
                })
                .finally(() => {
                    setQueried(moment());
                    resetNewTicketCount();
                    resetNewTicketMessageCount();
                    resetNewTicketClosedCount();
                })
        }else{
            clear();
        }
    }),[clear, enabled, queryServer, resetNewTicketClosedCount, resetNewTicketCount, resetNewTicketMessageCount, resetTickets]);

    const queryTicket = React.useCallback( (ticketId) => new Promise((resolve,reject) => {
        if(allowQueryForUser){
            if(ticketId){
                axios.get(`${BASE_API_URL}/${ticketId}?admin=${Boolean(admin).toString()}`, {cancelToken})
                    .then(({data}) => data)
                    .then(resolve)
                    .catch(err => {
                        if (isCancel(err)) return reject(err);
                        reject(err);
                    })
            }else{
                resolve({})
            }
        }else{
            reject(new Error('No internet or not authenticated'))
        }
    }), [admin, axios, cancelToken, isCancel, allowQueryForUser]);

    const refreshTicket = React.useCallback((tid) => new Promise(resolve => {

        // Determine ID
        const ix = tid ? tickets.findIndex(t => t.id === tid) : -1;

        // No match
        if(ix < 0) return resolve({});

        //Perform Query
        setWorking(true);
        queryTicket(tid)
            .then(data => {
                setTickets(prev => {
                    return prev.map(ex => {
                        if(ex.id && ex.id === data.id) return data;
                        return ex;
                    })
                })
                resolve(data);
            })
            .catch(err => {
                resolve({});
            })
            .finally(() => {
                setWorking(false);
                setQueried(moment());
            })

    }),[queryTicket, tickets])

    // Submit a new ticket
    const submitTicket = React.useCallback( (formData) => new Promise((resolve,reject) => {
        if(allowQueryForUser){
            setWorking(true);
            axios.put(`${BASE_API_URL}`, formData, {cancelToken})
                .then(({data}) => data)
                .then(resolve)
                .catch((err) => {
                    if (isCancel(err)) return reject(err);
                    reject(err);
                })
                .finally(()=>{
                    setWorking(false);
                })
        }
    }),[axios, cancelToken, isCancel, allowQueryForUser]);

    // Submit a message to a ticket
    const submitTicketMessage = React.useCallback((formData) => new Promise((resolve,reject) => {
        if(allowQueryForUser){
            setWorking(true);
            axios.put(`${BASE_API_URL}/${formData.ticket}/message`,formData, {cancelToken})
                .then(({data}) => data)
                .then(resolve)
                .catch((err) => {
                    if (isCancel(err)) return reject(err);
                    reject(err);
                })
                .finally(()=>{
                    setWorking(false);
                })
        }
    }),[axios, cancelToken, isCancel, allowQueryForUser])

    // Get the possible ticket categories
    React.useEffect(()=>{
        if(allowQueryForUser){
            axios.get(`${BASE_API_URL}/categories`,{cancelToken})
                .then(({data}) => data)
                .then((data) => {
                    const {
                        TICKET_CATEGORIES,
                        TICKET_CATEGORIES_REQUIRE_ORDER,
                        TICKET_CATEGORIES_REQUIRE_PRODUCT,
                        TICKET_CATEGORIES_REQUIRE_DELIVERY,    
                        TICKET_CATEGORIES_REQUIRE_LIBRARY
                    } = data;
                    setCategories(TICKET_CATEGORIES);
                    setCategoriesRequireOrder(TICKET_CATEGORIES_REQUIRE_ORDER);
                    setCategoriesRequireProduct(TICKET_CATEGORIES_REQUIRE_PRODUCT);
                    setCategoriesRequireDelivery(TICKET_CATEGORIES_REQUIRE_DELIVERY);
                    setCategoriesRequireLibrary(TICKET_CATEGORIES_REQUIRE_LIBRARY);
                })
                .catch((err)=>{
                    if(isCancel(err)) return resetCategories();
                    resetCategories();
                })
        }else{
            resetCategories();
        }

    },[axios, cancelToken, isCancel, allowQueryForUser, resetCategories])

    // Execute refresh when it changes
    React.useEffect(() => {
        if(allowQueryForUser)
            refresh()
        else
            clear();
    },[clear, allowQueryForUser, refresh])

    // New Tickets for Admins
    const recordNewTicket   = React.useCallback( (tid, uid) => {
        if(tid !== ticketId && uid !== userId)
            setNewTicketCount(prev => prev + 1);
    },[ticketId, userId])

    // New Messages for Admins
    /*
    const recordNewTicketMessage = React.useCallback( (tid, uid) => {
        if(tid !== ticketId && uid !== userId)
            setNewTicketMessageCount(prev => prev + 1);
        // if(tid && tid === ticketId)
        //     refreshTicket(tid);
    },[ticketId, userId])
    */

    // New Messages for Admins
    /*
    const recordNewTicketClosed = React.useCallback( (tid, uid) => {
        if(tid !== ticketId && uid !== userId)
            setNewTicketClosedCount(prev => prev + 1);
        // if(tid && tid === ticketId)
        //    refreshTicket(tid);
    },[ticketId, userId])
    */

    // Refresh on SocketIO Instruction, User ONLY
    React.useEffect(() => {
        if(enabled && allowQueryForUser && socket && !admin){
            socket.on('refresh_tickets', refresh)
            return () => {
                socket.off('refresh_tickets', refresh);
            }
        }
    },[refresh, socket, admin, enabled, allowQueryForUser])

    // Refresh on SocketIO Instruction
    React.useEffect(() => {
        if(enabled && allowQueryForUser && socket){
            socket.on('refresh_ticket', refreshTicket)
            return () => {
                socket.off('refresh_ticket', refreshTicket);
            }
        }
    },[socket, refreshTicket, enabled, allowQueryForUser])

    // ------------------------------------------------------
    // IF IS ADMIN and Admin Mode: 
    // New Ticket Message Created
    React.useEffect(() => {
        if(enabled && allowQueryForAdmin && socket && admin){
            socket.on('ticket_posted', recordNewTicket);
            return () => {
                socket.off('ticket_posted', recordNewTicket);
            }
        }
    },[admin, enabled, allowQueryForAdmin, recordNewTicket, socket])

    // New Ticket Message Posted
    /*
    React.useEffect(() => {
        if(false){
        if(false && socket && isAdmin && admin){
            socket.on('ticket_message_posted', recordNewTicketMessage);
            return () => {
                socket.off('ticket_message_posted', recordNewTicketMessage);
            }
        }
    },[socket, admin, isAdmin, recordNewTicketMessage])
    */

    // New Ticket Closed
    /*
    React.useEffect(() => {
        if(false && socket && isAdmin && admin){
            socket.on('ticket_closed', recordNewTicketClosed);
            return () => {
                // resetNewTicketClosedCount();
                socket.off('ticket_closed', recordNewTicketClosed);
            }
        }
    },[socket, admin, isAdmin, recordNewTicketClosed])
    */

    // Context values
    const value = {
        setEnabled,
        working,
        queried,
        setWorking,
        tickets,
        refresh,
        refreshTicket,
        submitTicket,
        submitTicketMessage,

        // Categories
        categories,
        categoriesRequireOrder,
        categoriesRequireProduct,
        categoriesRequireDelivery,
        categoriesRequireLibrary,
        
        // Includes
        includeClosed,
        setIncludeClosed,
        includeOpen,
        setIncludeOpen,
        includeAssigned,
        setIncludeAssigned,
        includeUnassigned,
        setIncludeUnassigned,
        includeRead,
        setIncludeRead,
        includeUnread,
        setIncludeUnread,

        // Current Ticket
        ticketId,    
        setTicketId,

        // Filter
        productId,
        setProductId,
        orderId,
        setOrderId,
        libraryId,
        setLibraryId,
        deliveryId,
        setDeliveryId,

        // Pagination
        page,
        setPage,
        limit,
        setLimit,
        totalItems,

        // Admin or User Mode
        admin,
        setAdmin,

        newTicketCount,
        newTicketMessageCount,
        newTicketClosedCount
    };

    return (
        <TicketContext.Provider value={value}>
            {children}
        </TicketContext.Provider>
    )
}

// Ticket Consumer
const TicketConsumer = ({children}) => {
    return (
        <TicketContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('TicketConsumer must be used within TicketProvider');
                }
                return children(context)
            }}
        </TicketContext.Consumer>
    )
}

// useTicket Hook
const useTicket = () => {
    const context = React.useContext(TicketContext);
    if(context === undefined)
        throw new Error('useTicket must be used within TicketProvider');
    return context;
}

export {
    TicketProvider,
    TicketConsumer,
    useTicket
}