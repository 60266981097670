/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Widget Work Required
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       13th July 2023

*******************************************************************************************/

import React                from 'react';
import moment               from 'moment';
import {
    styled,
    Box,
    Paper,
    Typography,
    lighten,
    darken
}                           from '@mui/material';
import {
  AdminBox,
  CountDownOrTimestamp,
  JSONViewer,
  ViewUserButton
}                           from 'components';
import { 
  useUser
}                           from 'contexts';
import {
    useClock
}                           from 'hooks';
import { WorkRequired }     from 'views/admin/deliveries/WorkRequired';
import SectionContainer     from '../components/SectionContainer';

const FloatingContainer = styled(Paper,{
    shouldForwardProp : prop => prop !== 'visible'
})(({ theme, visible }) => ({
    padding         : theme.spacing(2),
    backgroundColor : theme.palette.mode === 'light' 
        ? lighten(theme.palette.warning.light,0.75)
        : darken(theme.palette.warning.dark,0.25),
    opacity         : visible ? 1 : 0,
    transition      : theme.transitions.create('opacity', {
        duration    : theme.transitions.duration.standard,
        easing      : theme.transitions.easing.easeInOut,
    }),
    cursor      : 'pointer', 
    position    : 'fixed',
    width       : 200,
    bottom      : 16,
    right       : 16,
    zIndex      : visible ? 10000 : -10000,
    zoom        : 0.75
}));

const useIsVisible = (ref) => {
    const [isVisible, setIsVisible] = React.useState(false);
    React.useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            setIsVisible(entry.isIntersecting);
        });
        if (ref) {
            observer.observe(ref);
        }
        return () => {
            if (ref) {
                observer.unobserve(ref);
            }
        };
    }, [ref]);
    return isVisible;
};

const noop = () => {};


const FloatingComponent = ({data = [], visible = false, onClick : handleClick = noop, floatingQuantity = 3, filesAlmostDoneQuantity = 10}) => {
    
    const { now }                       = useClock(250);
    const {isAuthenticated, isAdmin}    = useUser();
    const quantity                      = React.useMemo(() => (data ? data?.length : undefined) || 0, [data]);
    const hasData                       = quantity > 0;
    const plural                        = quantity > 1 || quantity === 0;

    if(!isAuthenticated || !isAdmin || !hasData) 
        return null;

    return (
        <FloatingContainer 
            onClick     = {handleClick} 
            visible     = {visible}
            elevation   = {4} 
            square      = {false}
        >
            <Typography variant="h6" sx={{fontWeight:400}}>
                Attention:
            </Typography>
            <Box sx={{'& > * + *' : { mt : 1}}}>

                <Typography variant="body2" gutterBottom>
                    {quantity}x Order{plural ? 's' : ''} Need{!plural ? 's' : ''} Work
                </Typography>

                { 
                    (data || [])
                        .slice(0, Math.max(floatingQuantity,1))
                        .map(({id, library : { files = [] } = {}, order : { user } = {}, releaseAfter}) => {
                            const outstanding           = files.filter(f => Boolean(!f?.file));
                            const outstandingQuantity   = outstanding.length;
                            const daysLeft              = releaseAfter ? moment(releaseAfter).diff(now, 'days', true) : 0;
                            return (
                                <Box key={id} sx={{borderTop : theme => `1px solid ${theme.palette.divider}`, pt:1}}>
                                    {
                                        user && 
                                        <Typography variant="body2" component="div">
                                            <ViewUserButton id={user?.id} emailMD5={user?.emailMD5} name={user?.name} scrollable={false}/>
                                        </Typography>
                                    }

                                    {
                                        releaseAfter && 
                                        <Typography variant="body2" component="div">
                                            <CountDownOrTimestamp now={now} to={releaseAfter} countDownProps={{prefix:'Due'}}/>
                                        </Typography>
                                    }

                                    <Typography variant="body2">
                                        {outstandingQuantity}x Missing Audios
                                    </Typography>

                                    {
                                        daysLeft > 0 &&
                                        <Typography variant="body2" sx={{fontWeight:400}}>
                                            RR: {(outstandingQuantity/daysLeft).toFixed(3)} / day 
                                        </Typography>
                                    }

                                    {
                                        outstandingQuantity && outstandingQuantity <= filesAlmostDoneQuantity &&
                                        <Typography variant="body2" sx={{textAlign:'right', fontWeight:400, color:theme => theme.palette.success.dark}}>
                                            ... Almost Done!
                                        </Typography>
                                    }
                                </Box>
                            )
                        })
                }

                {
                    hasData && quantity > floatingQuantity && 
                    <Box pt={1}>
                        <Typography variant='body2' align="right" sx = {{fontWeight:400}}>
                            ... Plus {quantity - floatingQuantity}x More
                        </Typography>
                    </Box>
                }

                {
                    false && 
                    <JSONViewer src={data.slice(0,3)} />
                }
            </Box>
        </FloatingContainer>
    )
}

export const WidgetWorkRequired = ({floatingQuantity = 3}) => {

    const [ref, setRef]                 = React.useState(null);
    const isVisible                     = useIsVisible(ref);
    const {isAuthenticated, isAdmin}    = useUser();

    const [libraryId,setLibraryId]      = React.useState(undefined)
    const [data,setData]                = React.useState(undefined)
    const quantity                      = React.useMemo(() => (data ? data?.length : undefined) || 0, [data]);
    const hasData                       = React.useMemo(() => quantity > 0, [quantity]);
    
    const handleScrollToIncomplete      = React.useCallback(() => {
        if (ref) {
            ref.scrollIntoView({ behavior: 'smooth' });
        }
    }, [ref])

    if(!isAuthenticated || !isAdmin) 
        return null;

    return (
        <SectionContainer ref={setRef} sx={{position:'unset'}}>
            <AdminBox 
                disabled  = {!hasData} 
                sx        = {{
                    p             : hasData ? 2 : 0, 
                    pt            : hasData ? 3 : 0 , 
                    border        : hasData ? '1px dashed red' : 'none', 
                    borderRadius  : theme => theme.spacing(2)
                }}
            >
                <WorkRequired onChange = {setData} onLibraryIdChange={setLibraryId} />
            </AdminBox>
            <FloatingComponent 
                data                = {data} 
                onClick             = {handleScrollToIncomplete} 
                visible             = {!isVisible && !libraryId }
                floatingQuantity    = {floatingQuantity}
            />
        </SectionContainer>
    )
}

export default WidgetWorkRequired;