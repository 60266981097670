/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Product Cross Sell
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       6th August 2021

*******************************************************************************************/

import React                    from 'react';
import { Box, Grid }            from '@mui/material';
import {
    ProductCard,
    Title
}                               from 'components'
import SkeletonProductCard      from 'components/skeletons/SkeletonProductCard';
import { 
    useProduct,
    useCart
}                               from 'contexts';

function shuffle(arr) {
    var len = arr.length;
    var d = len;
    var array = [];
    var k, i;
    for (i = 0; i < d; i++) {
        k = Math.floor(Math.random() * len);
        array.push(arr[k]);
        arr.splice(k, 1);
        len = arr.length;
    }
    for (i = 0; i < d; i++) {
        arr[i] = array[i];
    }
    return arr;
}


export const ProductCrossSell = ({
    productId, 
    component   = 'div', 
    title       = 'Other products you might like', 
    saleOnly    = false, 
    omitInCart  = false, 
    showImage   = true, 
    imageHeight = undefined, 
    quantity    = 2, 
    GridProps   = {}, 
    TitleProps  = {},
    ...props
}) => {

    // Products
    const [productsShuffled,    setProductsShuffled]    = React.useState([]);
    const [products,            setProducts]            = React.useState([]);

    // Hook into the products
    const { data : rawData }                            = useProduct();
    const { qtyProductInCart }                          = useCart();

    React.useEffect(() => setProductsShuffled(shuffle([...rawData])), [rawData]); // Dupe

    // Concat sale with not on sale items, and slice
    React.useEffect(() => {
        
        // Products NOT this productId
        const productsAll = (productsShuffled || [])
            .filter(p => p.id !== productId && p.listed)
            .filter(p => qtyProductInCart(p.id) <= 0 || !omitInCart);

        // Products on Sale or Not
        const productsArray = saleOnly 
            ? productsAll.filter(p => p.onSale)   // Products on sale
            : productsAll;

        setProducts(
            productsArray
                .sort((a,b) => b.price - a.price)
                .slice(0,quantity)
        );
        
    },[omitInCart, productId, productsShuffled, qtyProductInCart, saleOnly, quantity]);

    const remainder = React.useMemo(() => {
        if(!Array.isArray(products)) 
            return 0;
        return products.length > quantity ? (products.length % quantity) : (quantity - products.length);
    }, [products, quantity])

    // No Data
    if(!Array.isArray(rawData) || rawData.length <= 0)
        return null;

    // If no products
    if(!Array.isArray(products) || products.length <= 0)
        return null;

    return (
        <Box component={component} id="productCrossSell">
            {
                title && 
                <Title component={Box} gutterBottom variant="h5" align={'left'} {...TitleProps}>
                    {title}
                </Title>
            }
            <Grid id="productXS" container>
                {
                    products.map((product,ix) => (
                        <Grid id={`productXS-${ix}-${product.id}`} key={ix} item xs={12} sm={12} md={6} lg={6} {...GridProps}>
                            <ProductCard 
                                productId       = {product.id} 
                                showImage       = {showImage} 
                                imageHeight     = {imageHeight} 
                                showDescription = {false}
                                actionsHeight   = {125}
                            />
                        </Grid>
                    ))
                }
                
                {   
                    Boolean(remainder) && 
                    new Array(remainder).fill(1).map((_,ix)=>(
                        <Grid id={`productXS-Skeleton-${ix}`} key={ix} item xs={12} sm={12} md={6} lg={6} {...GridProps} >
                            <SkeletonProductCard height={'100%'} variant="rect" footerHeight = {125} style={{minHeight:300}}/>
                        </Grid>
                    ))
                }
            </Grid>
        </Box>
    )
}

export default ProductCrossSell