/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Skeleton Address
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       2nd August 2022

*******************************************************************************************/
import { styled, Box } from '@mui/material';

export const ContainerHorizontal = styled(Box)(({theme}) => ({
    display : "flex",
    width   : "100%",
    '& > * + *' : {
        marginLeft : theme.spacing(1)
    }
}))

export const ContainerVertical = styled(Box)(({theme}) => ({
    display         : 'flex',
    flexDirection   : 'column',
    '& > * + *' : {
        marginTop : theme.spacing(1)
    }
}))

export default ContainerVertical;