/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Check Cross Icon
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       14th August 2022

*******************************************************************************************/
import React                            from 'react';
import { styled, Tooltip }              from '@mui/material';
import TickIcon                         from '@mui/icons-material/CheckCircle';
import CrossIcon                        from '@mui/icons-material/Cancel';

const withChecked = checked => styled( checked ? TickIcon : CrossIcon, {
    shouldForwardProp : prop => prop !== 'size'
})(({theme, size = 12}) => ({
    fontSize    : size,
    transform   : `translatey(${size/3 - 2}px)`,
    color       : checked 
        ? theme.palette.success.main 
        : theme.palette.error.main
}))

export const CheckCrossIcon = ({checked = true, tooltip=undefined, size = 12, ...props}) => {
    const Icon = React.useMemo(() => withChecked(checked), [checked]);
    if(tooltip) 
        return (
            <Tooltip title={`${checked ? '' : 'Not '}${tooltip}`}>
                <span>
                    <Icon size={size} {...props}/>
                </span>
            </Tooltip>
        )
    return (
        <Icon size={size} {...props}/>
    )
}

export default CheckCrossIcon;