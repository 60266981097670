
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Verify Email Resend Button
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       3rd May 2021

*******************************************************************************************/
import React                    from 'react';
import { Box }                  from '@mui/material';
import { MainAlert, Button }    from 'components';
import { withTranslation }      from './hoc';
import { useUser,useNetwork }   from 'contexts';

const MIN_SEND_INTERVAL = 1 * 60 * 1000; // 1 minute


const ResendConfirmation = withTranslation( ({t, disabled = true}) => {
    if(disabled) 
        return null;
    return (
        <React.Fragment>
            { t('components.alertEmailUnverified.resendConfirmation') }
        </React.Fragment>
    )
});

const ResendAgain = withTranslation( ({t, disabled = false, readyIn = 0}) => {
    if(!disabled) 
        return null;
    return (
        <React.Fragment>
            { t('components.alertEmailUnverified.resendIn', {duration : (readyIn/1000).toFixed(0)}) }
        </React.Fragment>
    )
});

const LastSent = withTranslation( ({t, lastSent = undefined, success = true}) => {
    if(!lastSent || isNaN(lastSent)) 
        return null;
    return (
        <React.Fragment>
            {
                success 
                    ? t("components.alertEmailUnverified.lastSent", { timestamp : new Date(lastSent).toISOString() }) 
                    : t("components.alertEmailUnverified.lastSentWithError",{ timestamp : new Date(lastSent).toISOString() }) 
            }
        </React.Fragment>
    )
});

// Full-Width MainAlert box with Resend Button
export const AlertEmailUnverified = withTranslation( ({t, ...props}) => {

    const {isNetworkReady}              = useNetwork();
    const [disabled, setDisabled]       = React.useState(false);
    const [readyIn,  setReadyIn]        = React.useState(0);
    const [success,   setSuccess]       = React.useState(undefined);
    const {
        isAuthenticated, 
        verified, 
        resendEmailConfirmation,
        emailConfirmationLastSent,
    }                                   = useUser();

    const handleClick = React.useCallback( () => {
        setSuccess(undefined);
        resendEmailConfirmation()
            .then(result => {
                setSuccess(true);
            })
            .catch(err => {
                setSuccess(false);
            });
    }, [resendEmailConfirmation]);

    React.useEffect(()=>{
        if(emailConfirmationLastSent){

            let interval;
            const handleCheck = () => {
                let elapsed = Date.now() - emailConfirmationLastSent; // Milliseconds elapsed
                let disable = elapsed <= MIN_SEND_INTERVAL;
                setDisabled(disable);
                if(!disable)
                    clearInterval(interval); // Stop Interval

                // Take not of time left before
                setReadyIn(Math.max(MIN_SEND_INTERVAL - elapsed, 0))
            }
            
            // Run Immediately
            handleCheck(); 
            
            // Run Check every second
            interval = setInterval(handleCheck, 500); 

            // Cleanup
            return () => {
                clearInterval(interval);
            };
        }else {
            setDisabled(false);
        }
    },[emailConfirmationLastSent])

    // Not Online
    if(!isNetworkReady)
        return null;

    // Already Verified or Not Authenticated, Abort
    if(!isAuthenticated || verified) 
        return null;

    // Return the alert with buttons allowing the user to request a new confirmation email
    return (
        <MainAlert 
            severity    = "warning"
            action      = {
                <Button onClick={handleClick} disabled={disabled} variant="outlined" color="error" size="small" {...props}>
                    <ResendConfirmation disabled={disabled} />
                    <ResendAgain disabled={disabled} readyIn={readyIn}/>
                </Button>
            }
        >
            <Box p={0}>
                {t("components.alertEmailUnverified.emailNotVerified")}
                <LastSent lastSent={emailConfirmationLastSent} succes={success} />
            </Box>
        </MainAlert>
    )
})

export default AlertEmailUnverified;