/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
User Profile Form
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       3rd June 2024

*******************************************************************************************/
import React                            from 'react';
import { Grid, InputAdornment}          from '@mui/material';
import { Form }                         from 'components';
import { useTranslation }               from 'contexts';
import { 
    TextField,
    Switches,
    showErrorOnChange   as showError,
}                                       from 'mui-rff';

const defaultFormData = {
    _id         : undefined,
    public      : false,
    handle      : "",
    displayName : "",
    firstName   : "",
    lastMame    : "",
    bio         : ""
}

const noop = () => {};

const BIO_MAX_LENGTH = 2048;

const EMPTY_STRING_FIELD_PROPS = {
    format : (value) => (value === undefined ? '' : value),
    parse  : (value) => (value === '' ? '' : value)
}

export const UserProfileForm = ({
    formData                = defaultFormData,
    disabled                = false,
    onSubmit : handleSubmit = noop,
    onCancel : handleCancel = noop,
    variant                 = undefined,
    showCancelButton        = true,
    submitText              = undefined,
    FormProps = {
        showCancelButton    : true, // dupe
        disabledAfterSubmit : false,
        showResetButton     : false,
        resetOnSubmit       : false,
    }
}) => {
    const {t}       = useTranslation();
    const validate  = React.useCallback( (values) => {
        let errors = {};

        // Required
        ['handle','displayName'].forEach((field) => {
            if(!values[field])
                errors[field] = errors[field] || "Required";
        });

        // Alphanumerc Only for Handle
        ["handle", "firstName", "lastName"].forEach((field) => {
            const val  = values[field];
            if(val && !/^[a-zA-Z0-9]+$/.test(val))
                errors[field] = errors[field] || "Alphanumeric Only";
        });

        // Bio Length
        let bioLength = (values?.bio || '').length;
        if(bioLength > BIO_MAX_LENGTH)
            errors['bio'] = errors['bio'] || `Max Length ${BIO_MAX_LENGTH} (Currently ${bioLength})`;
            
        return errors;
    }, []);

    // Initial Values
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const initialValues = React.useMemo(() => ({ ...formData }), [JSON.stringify(formData)]); 

    return (
        <Form
            debug           = {false}
            disabled        = {disabled}
            onSubmit        = {handleSubmit}
            onCancel        = {handleCancel}
            initialValues   = {initialValues}
            validate        = {validate}    
            submitText      = {submitText || t('common.submit')}
            showObjectId    = {false}
            {...FormProps}
            showCancelButton= {showCancelButton}
            render          = {({disabled, form, error, dirtySinceLastSubmit, submitFailed, submitSucceeded, errors, handleSubmit, values, ...rest}) => {
                return (
                    <form onSubmit = {handleSubmit} noValidate>
                        <Grid container>
                            <Grid item xs={12} sm={9}>
                                <TextField 
                                    disabled    = {disabled}
                                    name        = {'handle'} 
                                    label       = {"Handle"}
                                    showError   = {showError}
                                    variant     = {variant}
                                    InputProps  = {{
                                        startAdornment: <InputAdornment position="start">@</InputAdornment>
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3} sx={{m:'auto'}}>
                                <Switches 
                                    name        = "public" 
                                    disabled    = {disabled} 
                                    data        = {{label : values?.public ? "Public" : "Private", value:true}} 
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField 
                                    disabled    = {disabled}
                                    name        = {'displayName'} 
                                    label       = {"Display Name"}
                                    showError   = {showError}
                                    variant     = {variant}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField 
                                    disabled    = {disabled}
                                    name        = {'firstName'} 
                                    label       = {"First Name"}
                                    showError   = {showError}
                                    variant     = {variant}
                                    fieldProps  = {EMPTY_STRING_FIELD_PROPS}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField 
                                    disabled    = {disabled}
                                    name        = {'lastName'} 
                                    label       = {"Last Name"}
                                    showError   = {showError}
                                    variant     = {variant}
                                    fieldProps  = {EMPTY_STRING_FIELD_PROPS}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField 
                                    disabled    = {disabled}
                                    name        = {'bio'} 
                                    label       = {"About / Bio"}
                                    showError   = {showError}
                                    multiline   = {true}
                                    variant     = {variant}
                                    minRows     = {3}
                                    maxRows     = {50}
                                    helperText  = { `${(values?.bio || '').length}/${BIO_MAX_LENGTH}`}
                                    fieldProps  = {EMPTY_STRING_FIELD_PROPS}
                                />
                            </Grid>
                        </Grid>
                    </form>
                );
            }}
        />
    )
}

export default UserProfileForm;