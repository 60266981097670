/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Use Infinite Loading Hook
********************************************************************************************
Based Loosely on the following:
    https://blog.logrocket.com/react-hooks-infinite-scroll-advanced-tutorial/

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       26th November 2021

*******************************************************************************************/

import React                            from 'react';
import {isNil}                          from 'lodash';
import { 
    useQueryParam, 
    NumberParam,
    withDefault
}                                       from 'use-query-params';

const noop          = () => {};
const isIterable    = x => !!x?.[Symbol.iterator];

// Named Export
export const useInfiniteLoading = ({getItems, onNext = noop, onPrev = noop}) => {

    // History
    const [page,setPage]                    = useQueryParam('page', withDefault(NumberParam,1))

    const initialPage                       = React.useRef(page || 1);
    const lowestPageLoaded                  = React.useRef(initialPage.current); /* 7 */
    const highestPageLoaded                 = React.useRef(initialPage.current); /* 7 */
    const [
        initialPageLoaded, 
        setInitialPageLoaded
    ]                                       = React.useState(false);

    // The items
    const [items,       setItems]           = React.useState([]);
    const [totalItems,  setTotalItems]      = React.useState(undefined);
    const [totalPages,  setTotalPages]      = React.useState(undefined);
    const [loading,     setLoading]         = React.useState(false);    
    const [hasNext,     setHasNext]         = React.useState(false);
    const [hasPrev,     setHasPrev]         = React.useState(() => lowestPageLoaded.current > 1);

    // Functio to Load Items
    const loadItems = React.useCallback(async (page, forward=true, clear = false) => {

        // Try to Load
        try{

            // Mark as Loading
            setLoading(true);

            // Load Data
            const {items, totalItems, totalPages} = await getItems({ page : page - 1 }); // {items, totalPages}, zero based

            if(!isIterable(items))
                throw new Error('array must be iterable')

            // Has More Data
            setHasNext(totalPages > highestPageLoaded.current);

            // Has Prev Data
            setHasPrev(lowestPageLoaded.current > 1); 

            // TotalItemsCount
            setTotalItems(totalItems);

            // TotalPages
            setTotalPages(totalPages);
            
            // Combine the items
            if(clear){
                setItems(items);
            }else{
                const isForward = Boolean(isNil(forward) || forward);
                setItems(prevItems => (
                    isForward 
                        ? [...prevItems, ...items] 
                        : [...items, ...prevItems] 
                ))
            }
            
            // determine the callback function
            forward 
                ? onNext() 
                : onPrev();

            // Initial Page Loaded
            setInitialPageLoaded(true);

        // Report Error
        }catch(err){
            
            console.error(err);

        // Mark as Not Loading
        }finally{
            setTimeout(() => setLoading(false), 1000);
        }

    },[getItems, onNext, onPrev]);

    // Function to Load Next Data
    const loadNext = React.useCallback( () => {
        if(hasNext && !loading){
            highestPageLoaded.current = Number(highestPageLoaded.current) + 1;
            setPage(highestPageLoaded.current);
            loadItems(highestPageLoaded.current, true); // Forward Direction
        }
    },[hasNext, loadItems, loading, setPage])

    // Function to Load Prev Data
    const loadPrev = React.useCallback( () => {
        if(hasPrev && !loading){
            lowestPageLoaded.current = Number(lowestPageLoaded.current) - 1;
            setPage(lowestPageLoaded.current);
            loadItems(lowestPageLoaded.current, false); // Reverse Direction
        }
    },[hasPrev, loadItems, loading, setPage])

    // When loadItems Changes
    React.useEffect(() => {
        
        initialPage.current         = 1;
        lowestPageLoaded.current    = 1;
        highestPageLoaded.current   = 1;

        setInitialPageLoaded(false);
        setPage(1);

        // if (initialPageLoaded) return;
        loadItems(initialPage.current, true, true);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadItems])


    // Return State
    return {
        items,
        totalItems,
        totalPages,
        loading,
        hasNext,
        loadNext,
        hasPrev,
        loadPrev,
        initialPageLoaded
    };
}


// Default Export
export default useInfiniteLoading;