/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Edit Product Button
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       14th July 2021

*******************************************************************************************/
import React                            from 'react';
import { useProduct, useUser}           from 'contexts';
import { 
    ObjectId,
    DraggableDialog 
}                                       from 'components';
import { EditButton }                   from 'components/buttons';
import { EditIconButton }               from 'components/iconButtons';
import { ProductForm }                  from 'components/forms';
import { 
    useQueryParam, 
    BooleanParam,
}                                       from 'use-query-params';
import { withTranslation }              from './hoc';

const noop  = () => {};
const obj   = {};

export const EditProductButton = withTranslation( ({
    t,
    children,
    productId,
    visible             = true,
    disabled            = false, 
    useIconButton       = true,
    ButtonProps         = obj,
    IconButtonProps     = obj,
    onOpenChange        = noop,
    ...props
}) => {
    const { data : productData, updateProduct } = useProduct();
    const { isAuthenticated, isAdmin }          = useUser();
    const [product, setProduct]                 = React.useState({});
    const [open,    setOpen]                    = React.useState(false);
    const [edit,    setEdit]                    = useQueryParam('edit', BooleanParam);
    const handleOpen                            = React.useCallback( () => setEdit(true), [setEdit]);
    const handleClose                           = React.useCallback( () => setEdit(undefined), [setEdit]);
    const handleEditSubmit                      = React.useCallback( ({
        _id:id, 
        type, processor, product, name, abstract, description, listPrice, 
        discountValue, discountType, available, 
        /*taxExempt,*/ tags, categories, cover, images, deleted = false, productInput = {}, leadTimeSecondsOverride, ...rest
    }) => {
        return new Promise( resolve => {
            updateProduct({
                id, 
                type, processor, product, name, abstract, description, listPrice, 
                discountValue, discountType, available, 
                /*taxExempt,*/ tags, categories, cover, images, deleted, productInput, leadTimeSecondsOverride, ...rest
            })
                .then(handleClose)
                .then(() => {
                    resolve({})
                })
                .catch(({errors = {}}) => {
                    resolve(errors);
                });
        })
    }, [handleClose, updateProduct]);

    React.useEffect(() => {
        setOpen(Boolean(edit))
    },[edit])

    React.useEffect(() => (
        onOpenChange(open)
    ), [onOpenChange, open])

    React.useEffect(() => (
        setProduct(productData.find(p => p._id === productId))
    ), [productData, productId])

    // No Product
    if(!product || !isAdmin || !isAuthenticated) 
        return null;

    return (
        <React.Fragment>
            {visible && !useIconButton && 
                <EditButton disabled = {disabled} onClick={handleOpen} {...ButtonProps}/>
            }
            {visible && useIconButton &&
                <EditIconButton disabled = {disabled} onClick={handleOpen} {...IconButtonProps}/>
            }
            <DraggableDialog 
                showButtons     = {false} 
                disableBackdropClick
                title           = {
                    <ObjectId 
                        value   = { productId } 
                        render  = {({id}) => (
                            <span>
                                {t('components.editProductButton.editProductId', { name : product.name || 'No Name', id })}
                            </span>
                        )}
                    /> 
                }
                open            = {open} 
                onClose         = {handleClose} 
                onCancel        = {handleClose} 
                fullScreen      = {true}
            >
                <ProductForm  
                    onSubmit    = {handleEditSubmit} 
                    onCancel    = {handleClose} 
                    formData    = {product} 
                    isEditing   = {true} 
                    submitText  = {t('common.update')}
                    FormProps   = {{sticky:true}}
                />
            </DraggableDialog>
        </React.Fragment>
    )
});

export default EditProductButton;