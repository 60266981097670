
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
CommentsThread Context
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       3rd November 2022

*******************************************************************************************/
import React                    from 'react';
import {
    useNetwork
}                               from 'contexts';
import {
    useCancelToken,
}                               from 'hooks'; 

// The Product Context 
const CommentsThreadContext = React.createContext(undefined);

// CommentsThread Provider
const CommentsThreadProvider = ({children}) => {

    const {cancelToken}                 = useCancelToken();
    const {
        axios, 
        isNetworkReady
    }                                   = useNetwork();
    const { socketRoot : socket }       = useNetwork();

    const [reference,   setReference]   = React.useState(undefined);
    const [childCounts, setChildCounts] = React.useState([]);

    // Get the Child Counts for Comments Thread belonging to Group
    const getChildCount      = React.useCallback( (reference) => new Promise((resolve,reject) => {
        axios.get(`/api/user/commentGroup/${reference}/childCount`, {cancelToken})
            .then(({data}) => data)
            .then(resolve)
            .catch(reject)
    }), [axios, cancelToken]);

    const refreshChildCounts = React.useCallback(() => {
        if(isNetworkReady && reference){
            getChildCount(reference)
                .then(setChildCounts)
                .catch(err => {
                    setChildCounts([]);
                })
        }else{
            setChildCounts([]);
        }
    },[isNetworkReady, reference, getChildCount])

    React.useEffect(refreshChildCounts,[refreshChildCounts]);

    // Listen for instructions
    React.useEffect(() => {
        if(reference && socket){
            // console.log("Joining Room: ", group);
            socket.emit('join', reference);
            return () => {
                // console.log("Leaving Room: ", group);
                socket.emit('leave', reference)
            }
        }
    },[reference, isNetworkReady, socket])

    // Context values
    const value = {
        childCounts,
        refreshChildCounts,
        setReference
    };

    return (
        <CommentsThreadContext.Provider value={value}>
            {children}
        </CommentsThreadContext.Provider>
    )
}

// CommentsThread Consumer
const CommentsThreadConsumer = ({children}) => {
    return (
        <CommentsThreadContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('CommentsThreadConsumer must be used within CommentsThreadProvider');
                }
                return children(context)
            }}
        </CommentsThreadContext.Consumer>
    )
}

// useCommentsThread Hook
const useCommentsThread = () => {
    const context = React.useContext(CommentsThreadContext);
    if(context === undefined)
        throw new Error('useCommentsThread must be used within CommentsThreadProvider');
    return context;
}

export {
    CommentsThreadProvider,
    CommentsThreadConsumer,
    useCommentsThread
}