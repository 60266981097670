/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
New Messages Chip
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       28th February 2022

*******************************************************************************************/
import React                            from 'react';
import { Chip }                         from '@mui/material';
import SupervisorAccountIcon            from '@mui/icons-material/SupervisorAccount';
import {withTranslation}                from './hoc';

export const AdministratorModeChip = withTranslation( ({t, ...props}) => (
    <Chip 
        icon    = { <SupervisorAccountIcon /> } 
        size    = "small" 
        color   = "secondary" 
        label   = { t('common.adminMode') }
        {...props}
    />
));

export default AdministratorModeChip