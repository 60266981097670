/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
No Default Payment Method
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       7th May 2021

*******************************************************************************************/
import React                            from 'react';
import {useHistory}                     from 'react-router-dom';
import { Box, Button, Typography }      from '@mui/material';
import {
    UserSettingsLocation_TabPaymentMethods as PaymentMethods
}                                       from 'router/locations';
import { usePaymentMethod }             from 'contexts';
import { withTranslation }              from './hoc';

const noop = () => {};

export const NoDefaultPaymentMethod = withTranslation( ({
    t,
    onClick = noop, 
    ...props
}) => {
    
    const {quantity,hasDefault} = usePaymentMethod();           
    const history               = useHistory();
    
    const handleNavigate        = React.useCallback( () => (
        Promise.resolve(history.push(PaymentMethods.path))
    ), [history]);

    const handleClick           = React.useCallback( () => (
        handleNavigate()
            .then(onClick)
            .catch(console.error)
    ), [handleNavigate, onClick]);

    return (
        <Box width="100%">
            {   quantity === 0 && 
                <Typography paragraph>
                    {t('components.noDefaultPaymentMethod.noMethods')}
                </Typography>
            }
            {   quantity > 0 && !hasDefault &&
                <Typography paragraph>
                    {t('components.noDefaultPaymentMethod.noDefault')}
                </Typography>
            }
            {
                hasDefault && 
                <Typography paragraph>
                    {t('components.noDefaultPaymentMethod.hasMethodsWithDefault',{quantity})}
                </Typography>
            }
            <Box align="center" p={1}>
                <Button variant="contained" onClick={handleClick} color={'primary'} {...props}>
                    { 
                        quantity <= 0
                            ? t('components.noDefaultPaymentMethod.addPaymentMethod')
                            : t('components.noDefaultPaymentMethod.addEditPaymentMethod')
                    }
                </Button>
            </Box>
        </Box>
    )
});

export default NoDefaultPaymentMethod;