/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Order Tickets
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com 
Date:       12th February 2023

*******************************************************************************************/
import React                            from 'react';
import {
    Tickets,
    ItemPaper,
}                                       from 'components';
import { 
    useUser,
    useOrderViewer
}                                       from 'contexts';

export const OrderTickets = ({orderId : orderIdIn}) => {
    const {isAdmin}             = useUser();
    const {
        orderId, 
        isOwner, 
        data : order
    }                           = useOrderViewer();
    const disabled              = React.useMemo(() => Boolean(!order || order?.isCancelled), [order]);
    if(!orderId) 
        return null;
    if(orderId !== orderIdIn)
        return null
    return (
        <ItemPaper>
            <Tickets id="support" adminMode={!isOwner && isAdmin} orderId={orderId} showSkeleton={false} disabled={disabled}/>
        </ItemPaper>
    )
}

export default OrderTickets;