/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
File Uploader Modal
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       20th June 2021

*******************************************************************************************/
import React                            from 'react';
import { Box, Typography }              from '@mui/material';
import {
    DropzoneUploader,
    DraggableDialog
}                                       from 'components';
import { withTranslation }              from 'components/hoc';

const noop          = () => {};
const arr           = [];
const obj           = {
    maxSizeBytes : 1024 * 1024
};
const noopsubmit    = (files, allFiles) => { 
    console.log("FileUploader onSubmit(...) Not implemented")
}

const SIZE_BYTES_1MB = 1024 * 1024;

export const FileUploader = withTranslation( ({
    t,
    children,
    disabled                    = false, 
    title                       = undefined,
    open                        = false,
    multiple                    = true,
    acceptImage                 = true,
    acceptAudio                 = false,
    acceptVideo                 = false,
    acceptCustom                = arr,
    onClose     : handleClose   = noop,
    onSubmit    : handleSubmit  = noopsubmit,
    DropzoneUploaderProps       = obj,
    maxSizeBytes                = 5 * SIZE_BYTES_1MB,
    ...props
}) => {
    
    const args = React.useMemo(() => ({
        acceptImage,
        acceptAudio,
        acceptVideo,
        acceptCustom,
        onSubmit : handleSubmit,
        disabled
    }), [acceptAudio, acceptCustom, acceptImage, acceptVideo, disabled, handleSubmit]);

    return (
        <DraggableDialog 
            title       = {title || t('components.modals.fileUploader.fileUpload')} 
            open        = {open} 
            showButtons = {false} 
            onClose     = {handleClose} 
            {...props} 
            fullWidth 
            maxWidth    = "xs"
        >
            <Box>
                {children}
            </Box>

            <Box id="dropzoneUploaderContainer">
                <DropzoneUploader 
                    multiple        = {multiple} 
                    {...args} 
                    {...DropzoneUploaderProps} 
                    maxSizeBytes    = {maxSizeBytes}
                />
            </Box>

            {
                Boolean(maxSizeBytes) &&
                <Box align="right" p={0.5}>
                    <Typography slign="right" style={{fontStyle:'italic', fontSize:'0.5rem'}}>
                        { t('components.modals.fileUploader.maxSizeMb', { size : maxSizeBytes / SIZE_BYTES_1MB }) } 
                    </Typography>
                </Box>
            }
        </DraggableDialog>
    )
})

export default FileUploader;