/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Copy Field
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       29th September 2022

*******************************************************************************************/
import React                        from 'react';
import { TextField }                from '@mui/material';
import { useCopyToClipboard }       from 'hooks';
import { CopyIconButton }           from 'components/iconButtons';

export const CopyField = ({value = undefined, label = "Copy and Share", ...props}) => {

    // Alert
    const copy = useCopyToClipboard();

    // Copy Function
    const handleCopy = React.useCallback( () =>  copy(value), [copy, value])

    // No Value
    if(!value) 
        return null;

    // Return the field
    return (
        <TextField 
            variant         = "filled"
            label           = {label} 
            value           = {value} 
            InputLabelProps = {{ shrink: true }}
            InputProps      = {{
                endAdornment : (
                    <CopyIconButton size="small" onClick={handleCopy}/>
                )
            }}
            onFocus         = {event => event.target.select()}
            onSelectCapture = {event => event.target.select()}
            readOnly        = {true}
            fullWidth 
            {...props}
        />
    )
}

export default CopyField