/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Products
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       1st June 2022

*******************************************************************************************/
import React                from 'react';
import { 
  useTheme, Box, 
  Button as ButtonMUI, 
  Typography 
}                           from '@mui/material';
import {
  Search as SearchIcon,
}                           from '@mui/icons-material';
import { useHistory }       from 'react-router-dom';
import { 
  Title,
  ProductCrossSell,
}                           from 'components';
import {
  withDisabledOffline
}                           from 'components/hoc';
import { 
  useTranslation,
  useProduct,
}                           from 'contexts';
import { ShopLocation }     from 'router/locations';
import constants            from '../constants';
import SectionContainer     from '../components/SectionContainer';

const Button                = withDisabledOffline(ButtonMUI);

export const WidgetProducts = ({data}) => {
    const theme                     = useTheme();
    const history                   = useHistory();
    const {hasProducts}             = useProduct();
    const [t]                       = useTranslation();

    // Jump to the online store
    const jumpToShop              = React.useCallback( () => history.push(ShopLocation.path), [history]);

    if(!hasProducts)
        return null;

    return (
        <SectionContainer id={constants.ids.crossSell}>
            <Title component="h2" variant="h2" gutterBottom>
              {data?.fields?.title}
            </Title>
            <Box display="flex">
              <Box flexGrow={1}>
                <Typography paragraph variant="h5">
                  {data?.fields?.subtitle}
                </Typography>
              </Box>
              <Box flexShrink={1} sx={{transform:theme => `translatey(-${theme.spacing(1)})`}}>
                  <Button variant="contained" size="large" startIcon={<SearchIcon/>} onClick={jumpToShop} color={theme.palette.mode === 'light' ? 'primary' : 'secondary'} > 
                      {t('common.seeMore')}
                  </Button>
              </Box>
            </Box>
            <ProductCrossSell 
              title       = {false} 
              quantity    = {3} 
              GridProps   = {{xs:12, sm:4, md:4, lg:4}} 
              omitInCart  = {false}
            />
        </SectionContainer>
    )
}

export default WidgetProducts;