/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Page Container
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       14th July 2021

*******************************************************************************************/
import { Box } from '@mui/material';

export const ContainerWithFooter = ({children, render, renderFooter, renderHeader}) => {
    return (
        <Box id="containerWithFooter" flexGrow={1} display="flex" flexDirection="column" style={{height:'100%'}} >
            {renderHeader && 
                <Box>
                    {renderHeader()}
                </Box>
            }
            <Box flexGrow={1}>
                {children}
                {render && 
                    render()
                }
            </Box>
            {renderFooter &&
                <Box>
                    {renderFooter()}
                </Box>
            }
        </Box>
    )
}

export default ContainerWithFooter;