/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Quantity Controller
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       2nd July 2021

*******************************************************************************************/
import React                            from 'react';
import { Box, Typography }              from '@mui/material';
import {
    RemoveCircle  as RemoveIcon,
    AddCircle     as AddIcon,
}                                       from '@mui/icons-material';
import { 
    Counter,
    IconButton,
}                                       from 'components';

const Decrease = ({readOnly,...props}) => readOnly ? null : (
    <IconButton size="small" {...props}>
        <RemoveIcon/>
    </IconButton>
)

const Increase = ({readOnly,...props}) => readOnly ? null : (
    <IconButton size="small" {...props}>
        <AddIcon/>
    </IconButton>
)

export const QuantityController = ({
    quantity    = 1,
    minimum     = 1,
    maximum     = Infinity,
    disabled    = false,
    readOnly    = false,
    title       = "Quantity",
    onClick     = (event, value) => {},
    ...props
}) => {
    
    const handleDecrement   = React.useCallback(e => onClick(e, -1), [onClick]);
    const handleIncrement   = React.useCallback(e => onClick(e, +1), [onClick]);
    
    return (
        <Box>
            <Typography color="textPrimary">
                {title}
            </Typography>
            <Typography color="textPrimary">
                <Decrease readOnly={readOnly} disabled={quantity <= minimum || disabled} onClick = {handleDecrement} />
                <Counter value={quantity} />
                <Increase readOnly={readOnly} disabled={quantity >= maximum || disabled} onClick = {handleIncrement} />
            </Typography>
        </Box>
    )
}

export default QuantityController;