/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Date Range Form
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       13th February 2021

*******************************************************************************************/
import React                            from 'react';
import { 
    styled, useTheme, Box, 
    Chip as ChipMUI, 
    Grid, InputLabel, Typography 
}                                       from '@mui/material';
import DoneIcon                         from '@mui/icons-material/Done';
import { DateRangePicker }              from 'react-date-range';
import {
    Form,
    DraggableDialog
}                                       from 'components';
import { FORM_ERROR }                   from 'hooks';
import { 
    showErrorOnChange   as showError,
}                                       from 'mui-rff';
import { DateTimeField }                from './fields';
import moment                           from 'moment';
import { useLocale, useTranslation }    from 'contexts';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const Chip = styled(ChipMUI)({
    fontSize : '0.5rem',
});

const InterField = ({children}) => (
    <Box pt={3}>
        <Typography >
            {children}
        </Typography>
    </Box>
)

const checkSame = (d1, d2) => moment(d1).isSame(d2);
const parseDate = x => x;
const now = () => moment();
const obj = {};
const noop = () => {};
const noopsubmit = (values) =>  new Promise(resolve => resolve({[FORM_ERROR] : 'Not Implemented'}));
const defaultFormData = {
    start   : undefined,
    finish  : undefined
}

export const DateRangeForm = ({
    disabled                = false,
    formData                = defaultFormData,
    onSubmit : handleSubmit = noopsubmit,
    onCancel : handleCancel = noop,
    FormProps               = obj,
    ...props
}) => {
    const {t}               = useTranslation();
    const {formatDateTime}  = useLocale()
    const theme             = useTheme();

    const [open, setOpen] = React.useState(false);

    const validate  = React.useCallback( (values) => new Promise((resolve) => {
        let errors = {};
        
        // Required
        ['start'].forEach(key => {
            if(!values[key])
                errors[key] = errors[key] || t('components.forms.dateRangeForm.required')
        });
        
        // Must be Moment
        ['start','finish'].forEach(key => {
            if(values[key] && !moment.isMoment(values[key]))
                errors[key] = errors[key] || t('components.forms.dateRangeForm.invalid')
        });

        // Finish after Start
        if(!Boolean(Object.keys(errors).length)){ // No existing errors
            if(values['finish'] <= values['start']){
                errors['start']     = errors['start']   || t('components.forms.dateRangeForm.startBeforeFinish');
                errors['finish']    = errors['finish']  || t('components.forms.dateRangeForm.finishAfterStart')
            } 
            let now = moment();
            if(moment.isMoment(values['start']) && values['start'].isAfter(now))
                errors['start'] = errors['start'] || t('components.forms.dateRangeForm.startBeforeNow')
        }

        // Done
        resolve(errors);
    }), [t]);

    const initialValues = {
        ...formData,
        start   : parseDate(formData.start),
        finish  : parseDate(formData.finish)
    };

    const presetsNew = React.useMemo(() => {
        const n = now();
        return [
            {key : 'thisHour',      unit : 'hour',      prev : false,   translationKey : 'components.forms.dateRangeForm.thisHour'},
            {key : 'lastHour',      unit : 'hour',      prev : true,    translationKey : 'components.forms.dateRangeForm.lastHour'},
            {key : 'today',         unit : 'day',       prev : false,   translationKey : 'components.forms.dateRangeForm.today'},
            {key : 'yesterday',     unit : 'day',       prev : true,    translationKey : 'components.forms.dateRangeForm.yesterday'},
            {key : 'thisWeek',      unit : 'week',      prev : false,   translationKey : 'components.forms.dateRangeForm.thisWeek'},
            {key : 'lastWeek',      unit : 'week',      prev : true,    translationKey : 'components.forms.dateRangeForm.lastWeek'},
            {key : 'thisFortnight', unit : 'fortnight', prev : false,   translationKey : 'components.forms.dateRangeForm.thisFortnight'},
            {key : 'lastFortnight', unit : 'fortnight', prev : true,    translationKey : 'components.forms.dateRangeForm.lastFortnight'},
            {key : 'thisMonth',     unit : 'month',     prev : false,   translationKey : 'components.forms.dateRangeForm.thisMonth'},
            {key : 'lastMonth',     unit : 'month',     prev : true,    translationKey : 'components.forms.dateRangeForm.lastMonth'},
            {key : 'thisQuarter',   unit : 'quarter',   prev : false,   translationKey : 'components.forms.dateRangeForm.thisQuarter'},
            {key : 'lastQuarter',   unit : 'quarter',   prev : true,    translationKey : 'components.forms.dateRangeForm.lastQuarter'},
            {key : 'thisYear',      unit : 'year',      prev : false,   translationKey : 'components.forms.dateRangeForm.thisYear'},
            {key : 'lastYear',      unit : 'year',      prev : true,    translationKey : 'components.forms.dateRangeForm.lastYear'},
        ].reduce((acc,{key,unit,prev,translationKey}) => {
            const fn = unit === 'fortnight';
            let start   = (fn ? n.clone().subtract(1,'weeks').startOf('week')   : n.clone().startOf(unit)),
                finish  = (fn ? n.clone().endOf('week')                         : n.clone().endOf(unit)).subtract(1,'millisecond')
            if(prev){
                start   = (fn  ? start.subtract( 2,'weeks')                     : start.subtract( 1, unit));
                finish  = (fn  ? finish.subtract(2,'weeks')                     : finish.subtract(1, unit));
            }
            return [
                ...acc,
                {
                    key, 
                    unit, 
                    prev, 
                    translationKey,
                    start, 
                    finish 
                }
            ]
        },[]).filter(Boolean)
    },[]);

    return (
        
        <Form 
            debug                       = {false}
            disabled                    = {disabled}
            onSubmit                    = {handleSubmit}
            onCancel                    = {handleCancel}
            initialValues               = {initialValues}
            validate                    = {validate}
            description                 = {t('components.forms.dateRangeForm.description')}
            {...FormProps}
            render                      = { ({disabled, form, error, dirtySinceLastSubmit, submitFailed, submitSucceeded, errors, handleSubmit, values, ...rest}) => {
                const isCustom = !Boolean(presetsNew.map(({start,finish}) => checkSame(start,values.start) && checkSame(finish,values.finish)).some(Boolean))
                const handleCustomClick = () => {
                    form.change('start',    now().subtract(1,'days').startOf('day'));
                    form.change('finish',   now().endOf('day'));
                }
                const selectionRange = {
                    startDate   : (values.start     || moment(0)).toDate(),
                    endDate     : (values.finish    || now()).toDate(),
                    key         : 'selection',
                }
                return (
                    <form onSubmit={handleSubmit} noValidate>
                        <Grid container sx={{pt:1}}>
                            {false &&
                                <Grid item xs={12}>
                                    <Typography gutterBottom>
                                        {t('components.forms.dateRangeForm.dateRangeBetween', {start : formatDateTime(values.start || moment(0)), finish : formatDateTime(values.finish || moment()) })}
                                    </Typography>
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <InputLabel shrink={true}> 
                                    {t('components.forms.dateRangeForm.dateRangePresets')}
                                </InputLabel>
                                <div style={{width:'100%', paddingTop:theme.spacing(1)}}>
                                    {
                                        presetsNew.map(({key, start, finish, prev, translationKey }, ix) => {
                                            const selected  = !isCustom && checkSame(start,values.start) && checkSame(finish,values.finish);
                                            const handleClick = () => {
                                                form.change('start',    start);
                                                form.change('finish',   finish);
                                            }
                                            return (
                                                <Box 
                                                    key = {ix} 
                                                    sx  = {{
                                                        pr      : 0.5,
                                                        pb      : 0.5, 
                                                        display : 'inline-block'
                                                    }}
                                                >
                                                    <Chip
                                                        disabled    = {disabled}
                                                        icon        = {selected ? <DoneIcon /> : null}
                                                        size        = "small"
                                                        label       = {t(translationKey)}
                                                        color       = {selected ? (prev ? 'secondary': 'primary') : 'default'}
                                                        onClick     = {handleClick}
                                                    />  
                                                </Box>
                                            )
                                        })
                                    }
                                    <Box component="span" pl={1} pr={1}> or </Box>
                                    <Chip
                                        disabled    = {disabled}
                                        icon        = {isCustom ? <DoneIcon /> : null}
                                        size        = "small"
                                        label       = "Custom"
                                        color       = {isCustom ? 'primary' : 'default'}
                                        variant     = {isCustom ? "outlined" : 'default'}
                                        onClick     = {handleCustomClick}
                                    />  
                                </div>
                            </Grid>
                            <Grid item xs={5}>
                                <DateTimeField
                                    label           = {t('components.forms.dateRangeForm.startDate')}
                                    name            = "start" 
                                    value           = {values.start}
                                    disabled        = {disabled}
                                    viewTime        = {!false}
                                    disableFuture   = {true}
                                    showError       = {showError}
                                    onChange        = {(value) => form.change('start',   value)}
                                />
                            </Grid>
                            <Grid item xs={2} align="center">
                                <InterField>to</InterField>
                            </Grid>
                            <Grid item xs={5}>
                                <DateTimeField
                                    label           = {t('components.forms.dateRangeForm.finishDate')}
                                    name            = "finish" 
                                    value           = {values.finish}
                                    disabled        = {disabled}
                                    viewTime        = {!false}
                                    disableFuture   = {false}
                                    showError       = {showError}
                                    onChange        = {(value) => form.change('finish',  value)}
                                />
                            </Grid>
                        </Grid>
                        <DraggableDialog open={open} onClose={()=> setOpen(false)} showButtons={true} showButtonsCancel={false}>
                            <DateRangePicker 
                                ranges   = {[selectionRange]} 
                                editableDateInputs = {false}
                                showDateDisplay = {false}
                                onChange = {(ranges)=>{
                                    let {
                                        selection : {
                                            startDate   : start, 
                                            endDate     : finish
                                        }
                                    } = ranges;
                                    form.change('start',    moment(start ))
                                    form.change('finish',   moment(finish))
                                }}
                            />
                        </DraggableDialog>
                    </form>
                )
            }}
        />
    )
}

export default DateRangeForm;