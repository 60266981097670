/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Querying Server
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       9th July 2021

*******************************************************************************************/
import React                            from 'react';
import { Typography }                   from '@mui/material';
import { useNetwork }                   from 'contexts';

export const QueryingServer = ({
    querying = false,
    ...props
}) => {
    const {isNetworkReady} = useNetwork();
    if(!querying) 
        return null;
    return (
        <React.Fragment>
            {
                Boolean(isNetworkReady) &&
                <Typography color="textPrimary" align="center" {...props}>
                    Querying server
                </Typography>
            }
        </React.Fragment>
    )
}

export default QueryingServer;