/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
withRotation HOC
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       9th May 2021

*******************************************************************************************/
import React                            from 'react';
import {styled}                         from '@mui/material';

const DEFAULT_DURATION = 500;

const parseDuration = duration => isNaN(duration) ? duration : `${parseInt(duration)}ms`;

const withStyledComponent = Component => styled(Component, {
    shouldForwardProp : (prop) => prop !== 'duration' && prop !== 'rotate'
})(({rotate = true, duration = DEFAULT_DURATION}) => ({
    ...(rotate && {
        animation : `spin ${parseDuration(duration)} infinite`,
        "@keyframes spin" : [0.0,1.0].reduce((acc,cur) => {
            return {
                ...acc,
                [`${cur}%`] : {
                    transform : `rotate(${parseInt(cur * 360)}deg)`
                }
            }
        },{})
    })
}));

export const withRotation = Component => {
    const StyledComponent = withStyledComponent(Component);
    const WrappedComponent = ({ rotate = true, duration = DEFAULT_DURATION, ...props }) => {        
        return (
            <StyledComponent 
                rotate      = { rotate }
                duration    = { duration }
                {...props}
            />
        )
    };
    return WrappedComponent;
}

export default withRotation;