/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Hook to handle single and double clicks
********************************************************************************************
https://developpaper.com/question/react-js-how-to-solve-the-conflict-between-ondoubleclick-and-onclick-in/

Usage: 
    const click = useSingleAndDoubleClick(callbackClick, callbackDoubleClick);

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       25th January 2022

*******************************************************************************************/
import React                    from 'react';
import {debounce,map}           from 'lodash';

const noop = () => {};

export const useClickHandler = ({
    onClick         = noop, 
    onDoubleClick   = noop, 
    delay           = 250
} = {}) => {

    // Checks
    if(typeof onClick !== 'function') 
        throw new Error('onClick must be a function');
    if(typeof onDoubleClick !== 'function') 
        throw new Error('onDoubleClick must be a function');
    if(isNaN(delay) || delay <= 0)
        throw new Error('delay must be positive numeric');
    
    // Keep track of click tasks
    const [clickTasks, setClickTasks] = React.useState([]);

    // Reset the list of tasks
    const resetClickTasks = React.useCallback(() => setClickTasks([]), []);

    // Single Click Handler
    const handleClick = React.useCallback(args => {
        const clickTask = debounce(() => {
            onClick(args);
            resetClickTasks();
        }, delay);
        setClickTasks(prev => [...prev,clickTask]);
        clickTask();
    },[delay, onClick, resetClickTasks])

    // Double Click Handler
    const handleDoubleClick = React.useCallback(args => {
        if (clickTasks.length > 0) {
            map(clickTasks, task => task.cancel());
        }
        onDoubleClick(args)
    },[onDoubleClick, clickTasks])

    // Modified Handlers
    return {
        handleClick,
        handleDoubleClick
    }
};

// HOC
export const withClickHandler = (Component, delay=250) => React.forwardRef(({onClick = noop, onDoubleClick = undefined, ...props}, ref) => {
    
    const { handleClick, handleDoubleClick} = useClickHandler({
        onClick, 
        onDoubleClick : onDoubleClick || onClick, 
        delay
    });

    const showPointer = React.useMemo(() => (
        Boolean((onClick && onClick !== noop )|| onDoubleClick)
    ), [onClick, onDoubleClick])

    return (
        <Component 
            ref             = {ref}
            onClick         = {handleClick} 
            onDoubleClick   = {handleDoubleClick} 
            {...props}
            style           = {{
                cursor : showPointer ? 'pointer' : 'default',
                ...props?.style
            }}
        />
    )
});

export default useClickHandler;