
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Launch Context
********************************************************************************************
Boilerplate context, consumer, provider and hook

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       1st July 2023

*******************************************************************************************/
import React            from 'react';
import moment           from 'moment';
import {useClock}       from 'hooks';
import { useUser }      from './UserContext';

// The Product Context 
const LaunchContext = React.createContext({});

// The Launch Date
const LAUNCH_DATE_USER      = moment("02/07/2023 12:17pm", "DD/MM/YYYY hh:mma");
const LAUNCH_DATE_ADMIN     = LAUNCH_DATE_USER.clone().subtract(30,'minutes');

// Launch Provider
const LaunchProvider = ({children}) => {

    const {
        isAuthenticated,
        isAdmin
    }                   = useUser();
    const {now}         = useClock();
    const valid         = React.useMemo(() => Boolean(LAUNCH_DATE_USER && moment.isMoment(LAUNCH_DATE_USER)), []);
    const userIsPast    = React.useMemo(() => valid && now.isSameOrAfter(LAUNCH_DATE_USER), [now, valid])
    const adminIsPast   = React.useMemo(() => valid && now.isSameOrAfter(LAUNCH_DATE_ADMIN),[now, valid])
    
    const clock         = React.useMemo(() => (
        valid && !userIsPast
            ? moment.duration(LAUNCH_DATE_USER.diff(now)).format("dd:hh:mm:ss", { trim: false }) 
            : undefined
    ),[now, userIsPast, valid]);

    // Context values
    const value = {
        launchDate      : LAUNCH_DATE_USER, 
        launchedUser    : userIsPast,
        launchedAdmin   : adminIsPast,
        launched        : isAuthenticated && isAdmin 
            ? adminIsPast 
            : userIsPast,
        clock
    };

    return (
        <LaunchContext.Provider value={value}>
            {children}
        </LaunchContext.Provider>
    )
}

// Launch Consumer
const LaunchConsumer = ({children}) => {
    return (
        <LaunchContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('LaunchConsumer must be used within LaunchProvider');
                }
                return children(context)
            }}
        </LaunchContext.Consumer>
    )
}

// useLaunch Hook
const useLaunch = () => {
    const context = React.useContext(LaunchContext);
    if(context === undefined)
        throw new Error('useLaunch must be used within LaunchProvider');
    return context;
}

export {
    LaunchProvider,
    LaunchConsumer,
    useLaunch
}