/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Archive Cross Sell
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       28th December 2022

*******************************************************************************************/
import React                    from 'react';
import moment                   from 'moment';
import {
    useTheme,
    Box,
}                               from '@mui/material';
import AudiotrackIcon           from '@mui/icons-material/Audiotrack';
import { 
    Title,
    RefreshIcon,
    ImageLinkWithOverlay,
    DebouncedButton,
    Skeleton,
    LikeDisplay
}                               from 'components';
import { withTranslation }      from './hoc';
import { 
    ExamplesLocation
}                               from 'router/locations/Locations';
import {
    useNetwork,
}                               from 'contexts';
import {
    useCancelToken,
    useImageCDN
}                               from 'hooks';  
import config                   from 'config';

const GRAYSCALE             = Boolean(config?.archive?.grayscale);
const FEATURE_COLOR         = "#FFD700"

export const ArchiveCrossSell = withTranslation( ({t, title = undefined, showTitle=true, auto = true, imageWidth = 250, quantity = 5, id = undefined, ...props}) => {

    const {cancelToken, isCancel}                       = useCancelToken();
    const {axios, isNetworkReady}                       = useNetwork();
    const theme                                         = useTheme();
    const convert                                       = useImageCDN();

    const [working, setWorking]                         = React.useState(false);
    const [data,    setData]                            = React.useState([]);
    const [queried, setQueried]                         = React.useState(undefined);

    const refresh = React.useCallback( () => {
        if(isNetworkReady){
            setWorking(true);
            axios.get(
                id 
                    ? `/api/examples/${id}/similar?limit=${quantity}`
                    : `/api/examples/random?limit=${quantity}`, 
                    {cancelToken}
            )
                .then(({data}) => {
                    setData(data);
                })
                .catch(err => {
                    if(isCancel(err)) return;
                })
                .finally(() => {
                    setWorking(false);
                    setQueried(moment());
                })
        }else{
            setData([]);
        }
    },[axios, cancelToken, id, isCancel, isNetworkReady, quantity]);

    // Query and requery on interval
    React.useEffect(() => {
        refresh();
        if(auto){
            const period    = 60000; // Every Minute
            const interval  = setInterval(refresh,period);
            return () => {
                clearInterval(interval);
            }
        }
    },[refresh, auto])

    if(quantity <= 0)
        return null;

    return (
        <Box>
            {
                showTitle &&
                <Box display="flex">
                    <Box flexGrow={1}>
                        <Title variant="h5" component="h3">
                            {title || t('components.archiveCrossSell.relatedExamples')}
                        </Title>
                    </Box>
                    <Box>
                        <DebouncedButton color={theme.palette.mode === 'dark' ? 'secondary' : 'primary'} disabled={working} onClick={refresh} startIcon={working ? <RefreshIcon loading={true}/> : null}>
                            { t('common.refresh') }
                        </DebouncedButton>
                    </Box>
                </Box>
            }
            <Box display="flex">
                {
                    Boolean(!working && queried && data?.length) &&
                    data.map(({photo,slug,name,likeGroup,id,enabled,isPurchased}) => {
                        return (
                            <Box key={id} flexBasis={`${(100/data.length).toFixed(1)}%`} sx={{position:'relative'}}>
                                <ImageLinkWithOverlay
                                    name    = { name} 
                                    to      = { ExamplesLocation.toUrl({slug : slug || id}) }
                                    photo   = { convert(photo, { operation:'width', width:imageWidth, options:`grayscale:${Boolean(GRAYSCALE && !isPurchased)}`} ) }
                                    alt     = { name }
                                />
                                {
                                    enabled &&
                                    <Box sx={{position:'absolute', color:FEATURE_COLOR, top:theme => theme.spacing(1),right:theme => theme.spacing(1)}}>
                                        <AudiotrackIcon color="inherit" size="large"/>
                                    </Box>
                                }
                                {
                                    likeGroup && 
                                    <LikeDisplay likes={likeGroup?.likeCount} dislikes={likeGroup?.dislikeCount} />
                                }
                            </Box>
                        )
                    })
                }
                { 
                    Boolean(working || !queried || !data?.length) &&
                    Array(quantity).fill(undefined).map((_,ix) => (
                        <Box key={ix} flexBasis={`${(100/quantity).toFixed(1)}%`} sx={{position:'relative', aspectRatio:'0.71', mx:0.1}}>
                            <Skeleton width="100%"/>
                        </Box>
                    ))
                }
            </Box>
        </Box>
    )
});

export default ArchiveCrossSell;