/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
LanguagesMenu
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       15th August 2023

*******************************************************************************************/
import React                            from 'react';
import Menu                             from './Menu';
import {
    MenuItem,
    ListItem,
    ListItemIcon,
    ListItemText
}                                       from '@mui/material';
import {
    CheckCircle         as SelectedIcon,
    CheckCircleOutline  as UnselectedIcon
}                                       from '@mui/icons-material';
import {
    LanguageSelector
}                                       from 'components';
import {LanguageTitle}                  from './Titles';

export const LanguagesMenu = ({...props}) => {
    return (
        <LanguageSelector render = {({language, languageName, languages, changeLanguage}) => (
            <Menu showArrow = {true} label = {language}>
                <LanguageTitle />
                {
                    (languages || []).map(({language:lang,languageName:langName}) => (
                        <MenuItem key={lang} componnet="div" onClick={() => changeLanguage(lang)}>
                            <ListItem>
                                <ListItemIcon>
                                    { 
                                        lang === language
                                            ? <SelectedIcon color={"success"} />
                                            : <UnselectedIcon color={"default"} />
                                    }
                                </ListItemIcon>
                                <ListItemText>
                                    {[...new Set([lang,langName])].join(' - ')}
                                </ListItemText>
                            </ListItem>
                        </MenuItem>
                    ))
                }
            </Menu>
        )}/>
    )
};

export default LanguagesMenu;