import withWaitingForNetwork    from 'components/hoc/withWaitingForNetwork';
import withNoLibrariesSelected  from 'components/hoc/withFeatureNoLibariesSelected';
import withFeatureTypography    from 'components/hoc/withFeatureTypography';
import withFeatureLoading       from 'components/hoc/withFeatureLoading';

// Import
import Skeleton                 from './Skeleton';
import SkeletonRadix            from './SkeletonRadix';
import SkeletonProductCard      from './SkeletonProductCard';
import SkeletonTable            from './SkeletonTable'
import SkeletonFancy            from './SkeletonFancy';
import SkeletonEntries          from './SkeletonEntries';

// Standard Exports
export * from './SkeletonComment';
export * from './SkeletonPlaylist';
export * from './SkeletonPerson';
export * from './SkeletonTabs'; 
export * from './SkeletonRadix';
export * from './SkeletonProductCard';
export * from './SkeletonTable';
export * from './SkeletonFancy';
export * from './SkeletonEntries';
export * from './Skeleton';
export * from './SkeletonAddress';
export * from './SkeletonBlogPost';
export * from './SkeletonPage';

// Waiting For Network
export const SkeletonLoading                        = withFeatureLoading(Skeleton);
export const SkeletonWaitingForNetwork              = withWaitingForNetwork(Skeleton);
export const SkeletonFancyWaitingForNetwork         = withWaitingForNetwork(SkeletonFancy);
export const SkeletonTableWaitingForNetwork         = withWaitingForNetwork(SkeletonTable);
export const SkeletonProductCardWaitingForNetwork   = withWaitingForNetwork(SkeletonProductCard);
export const SkeletonEntriesWaitingForNetwork       = withWaitingForNetwork(SkeletonEntries);

// No Libraries Selected
export const SkeletonFancyNoLibrariesSelected       = withNoLibrariesSelected(SkeletonFancy);
export const SkeletonRadixNoLibrariesSelected       = withNoLibrariesSelected(SkeletonRadix);
export const SkeletonEntriesNoLibrariesSelected     = withNoLibrariesSelected(SkeletonEntries);

// With Tpography
export const SkeletonEntriesFeatureTypography       = withFeatureTypography(SkeletonEntries);

// Default Skeleton
export default Skeleton;


