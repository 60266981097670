/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Cart Coupon
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       16th November 2021

*******************************************************************************************/
import React                            from 'react';
import { Box, Typography }              from '@mui/material';
import { 
    Title, 
    ItemPaper,
    LoadingData,
    JSONViewer,
    RemoveWithConfirmationButton as RemoveButton,
    AlertMessageGroup
}                                       from 'components';
import { withTranslation }              from './hoc';
import {
    useCart,
    useLocale
}                                       from 'contexts';
import {
    CouponApplyForm
}                                       from 'components/forms';

// Shopping Cart
export const CartCoupon = withTranslation( ({t, disabled = false, ...props}) => {
    
    const {
        cart,
        working,
        workingCoupon,
        coupon,
        applyCoupon,
        removeCoupon,
        messageCouponError,
        messageCouponSuccess
    }                           = useCart();
    const {
        formatCurrency,
        currencyFactor,
        currency
    }                           = useLocale();
    const handleRemoveCoupon    = React.useCallback( () => removeCoupon(), [removeCoupon]);
    const handleApplyCoupon     = React.useCallback( (formData) => applyCoupon(formData), [applyCoupon]);

    const totalAdjustment       = React.useMemo(() => (cart || []).filter(x => x.__t === 'COUPON').reduce((a,c) => (a + (c?.total || 0)),0), [cart]);
    const hasAdjustment         = React.useMemo(() => Boolean(Math.abs(totalAdjustment) > 0), [totalAdjustment]);

    // No coupon or disabled, Return Empty
    if(!coupon && disabled)
        return null;
    
    return (
        <ItemPaper>

            <Title gutterBottom>
                {t('common.coupon')}
            </Title>

            {
                !coupon &&
                <React.Fragment>
                    <Typography gutterBottom>
                        {t('components.cartCoupon.noCouponPrompt')}
                    </Typography>
                    <Box>
                        <CouponApplyForm onSubmit={handleApplyCoupon} FormProps={{showResetButton:true, showCancelButton:false, changeRequiredToSubmit:true}} />
                    </Box>
                </React.Fragment>
            }

            {   coupon && 
                <React.Fragment>
                    {   
                        // Show when coupon is set, ie, fall back to the form submission error handling for when coupon not set.
                        (messageCouponSuccess || messageCouponError) && 
                        <Box flexGrow={1}>
                            <AlertMessageGroup success={messageCouponSuccess} error={messageCouponError}/>
                        </Box>
                    }

                    <Box display="flex">
                        <Box flexGrow={1}>
                            <Typography gutterBottom>
                                {t('components.cartCoupon.couponSetTo', {coupon : coupon?.code || coupon?._id || coupon, description : coupon.description ? `, ${coupon.description}` : ''})}
                            </Typography>
                            {
                                !working && !workingCoupon && !hasAdjustment &&
                                <Typography color="error">
                                    {t('components.cartCoupon.couponNoEffect')}
                                </Typography>
                            }
                            {
                                !working && !workingCoupon && hasAdjustment && totalAdjustment < 0 &&
                                <Typography color="secondary.dark">
                                    {t('components.cartCoupon.couponReducedPrice', { amount : formatCurrency(-totalAdjustment/currencyFactor, currency)})}
                                </Typography>
                            }
                        </Box>
                        {
                            (working || workingCoupon) &&
                            <Box flexShrink={1} pr={1}>
                                <LoadingData/>
                            </Box>
                        }
                        {
                            !disabled &&
                            <Box flexShrink={1}>
                                <RemoveButton disabled={working} variant="text" color="secondary" size="small" onClick={handleRemoveCoupon} />
                            </Box>
                        }
                    </Box>

                    {
                        false && typeof(coupon) === 'object' &&
                        <JSONViewer src={coupon}/>
                    }

                </React.Fragment>
            }
        </ItemPaper>
    )
});

export default CartCoupon;