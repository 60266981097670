/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Color Picker
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       8th June 2021

*******************************************************************************************/
import React                            from 'react';
import { Box, Typography }              from '@mui/material';
import { 
    CirclePicker as Picker,
    SliderPicker as Slider
}                                       from 'react-color';
import { withTranslation }              from './hoc';

const noopcolor = (color) => {};
const obj = {};

export const ColorPicker = withTranslation( ({
    t,
    color                               = '#000000',
    onChange        : handleChange      = noopcolor,
    onSwatchHover   : handleHover       = noopcolor,
    SliderProps     = obj,
    PickerProps     = obj,
    ...props
}) => {
    
    const handleChangeComplete  = React.useCallback( (color) => handleChange(color.hex),    [handleChange]);
    const handleSwatchHover     = React.useCallback( (color) => handleHover(color.hex),     [handleHover]);

    return (
        <Box width={250}>
            <Typography>
                {t('components.colorPicker.selectFrom')}
            </Typography>
            <Box p={0.5} mt={1}>
                <Box pb={3}>
                    <Slider
                        color               = {color} 
                        onChangeComplete    = {handleChangeComplete}
                        onSwatchHover       = {handleSwatchHover}
                        {...props}
                        {...SliderProps}
                    />
                </Box>
                <Box>
                    <Picker 
                        color               = {color} 
                        onChangeComplete    = {handleChangeComplete}
                        onSwatchHover       = {handleSwatchHover}
                        {...props}
                        {...PickerProps}
                    />
                </Box>
            </Box>
        </Box>
    )
});

export default ColorPicker;