/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Order Payment History
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       27th July 2021

*******************************************************************************************/
import React                            from 'react';
import { titleCase }                    from "title-case";
import moment                           from 'moment';
import {
    styled,
    Box,
    Link,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell as TableCellMUI,
    useTheme,
    Typography,
}                                       from '@mui/material';
import CreatedIcon                      from '@mui/icons-material/StarTwoTone';
import SuccessIcon                      from '@mui/icons-material/CheckCircleOutline';
import ProgressIcon                     from '@mui/icons-material/PlayCircleFilled';
import FailedIcon                       from '@mui/icons-material/Block';
import WarningIcon                      from '@mui/icons-material/Warning';
import ExpiredIcon                      from '@mui/icons-material/AvTimer';
import {
    FormAlert,
    Currency,
    LoadingData,
    Title,
    JSONViewer,
    CountDown,
    ObjectId,
    RootContainer,
    ItemPaper
}                                       from 'components';
import { withTranslation }              from 'components/hoc';
import { 
    useOrders, 
    useOrderViewer,
    useLocale,
}                                       from 'contexts';

const DEBUG = false;

const TableCell = styled(TableCellMUI)(({theme}) => ({
    verticalAlign   : 'top',
    textAlign       : 'left',
    fontSize        : '0.65rem',
    padding         : theme.spacing(0.25),
    margin          : 'auto'
}))

// ['PENDING', 'FAILED', 'PAID', 'FULL_REFUND', 'PARTIAL_REFUND'],
const paymentStatusMap = {
    'PENDING'           : {title: "Payment Required", isPending:true,   transactionKey: "components.order.orderPaymentHistory.paymentStatus.PENDING"},
    'EXPIRED'           : {title: "Expired",                            transactionKey: "components.order.orderPaymentHistory.paymentStatus.EXPIRED"},
    'CANCELLED_USER'    : {title: "Cancelled by User",                  transactionKey: "components.order.orderPaymentHistory.paymentStatus.CANCELLED_USER"},
    'CANCELLED_SYSTEM'  : {title: "Cancelled by Webmaster",             transactionKey: "components.order.orderPaymentHistory.paymentStatus.CANCELLED_SYSTEM"},
    'FAILED'            : {title: "Payment Failed",                     transactionKey: "components.order.orderPaymentHistory.paymentStatus.FAILED"},
    'PAID_FREE'         : {title: "Paid (Free)",                        transactionKey: "components.order.orderPaymentHistory.paymentStatus.PAID_FREE"},
    'PAID_FULL'         : {title: "Paid",                               transactionKey: "components.order.orderPaymentHistory.paymentStatus.PAID_FULL"},
    'PAID_PARTIAL'      : {title: "Partially Paid", isPartial:true,     transactionKey: "components.order.orderPaymentHistory.paymentStatus.PAID_PARTIAL"},
    'REFUND_FULL'       : {title: "Full Refund",                        transactionKey: "components.order.orderPaymentHistory.paymentStatus.REFUND_FULL"},
    'REFUND_PARTIAL'    : {title: "Part Refund",                        transactionKey: "components.order.orderPaymentHistory.paymentStatus.REFUND_PARTIAL"},
}

// Create Icon Component HOC
const createIconComponent = (Icon, color='inherit') => {
    const Component = ({children,color:colorIn}) => {
        const theme = useTheme();
        return (
            <Box display="flex" style={{color: theme.palette[colorIn||color]?.main || colorIn || color}}>
                <Box flexShrink={1}>
                    <Icon color='inherit' style={{fontSize:'0.85rem',lineHeight:1,transform:'translatey(1px)'}}/>
                </Box>
                {
                    children &&
                    <Box sx={{my:'auto', pl : 0.5}}>
                        {children}
                    </Box>
                }
            </Box>
        )
    }
    return Component;
};

const Created       = createIconComponent(CreatedIcon,  'inherit');
const Success       = createIconComponent(SuccessIcon,  'success');
const Expired       = createIconComponent(ExpiredIcon,  'error');
const Warning       = createIconComponent(WarningIcon,  'warning');
const Progress      = createIconComponent(ProgressIcon, 'info');
const Failed        = createIconComponent(FailedIcon,   'error');

const TransactionStatus = withTranslation( ({t, status, children}) => {
    switch(true){
        case /pending/i.test(status):
            return (
                <Warning>
                    {children || t('components.order.orderPaymentHistory.transactionStatus.pending')}
                </Warning>       
            )
        case /succeeded/i.test(status):
            return (
                <Success>
                    {children || t('components.order.orderPaymentHistory.transactionStatus.success')}
                </Success>
            )
        case /failed/i.test(status):
            return (
                <Failed>
                    {children || t('components.order.orderPaymentHistory.transactionStatus.failed')}
                </Failed>
            )
        default:
            return titleCase((status || '').replace(/_/g, ' '));
    }
});

const OrderPaymentStatus = withTranslation( ({t, status, children}) => {
    const theme = useTheme();
    const {title, translationKey} = paymentStatusMap[status] || {};
    switch(status){
        case 'EXPIRED':
            return (
                <Expired>    
                    {translationKey ? t(translationKey) : title}{children}
                </Expired>
            )
        case 'PENDING':
        case 'PAID_PARTIAL':
        case 'REFUND_PARTIAL':
            return (
                <Warning>
                    {translationKey ? t(translationKey) : title}{children}
                </Warning>  
            )
        case 'PAID_FREE':
        case 'PAID_FULL':
            return (
                <Success>    
                    {translationKey ? t(translationKey) : title}{children}
                </Success>
            )
        case 'REFUND_FULL':
            return (
                <Warning color={theme.palette.error.main}> 
                    {translationKey ? t(translationKey) : title}{children}
                </Warning>    
            )
        case 'CANCELLED_USER':
        case 'CANCELLED_SYSTEM':
            return (
                <Failed>     
                    {translationKey ? t(translationKey) : title}{children}
                </Failed>
            )
        default:
            return titleCase((title || '').replace(/_/g, ' '));
    }
});

const CountDownOrder = withTranslation( ({t, expires}) => {
    const {refresh}     = useOrders();
    const to            = moment(expires);
    return (
        <CountDown 
            to          = {to} 
            onExpire    = {refresh} 
            render      = {({countDown, isFinishing})=>(
                <Typography variant = "body1">
                    { t('components.order.orderPaymentHistory.countDownOrder.requiresPayment', {expires : countDown}) }
                </Typography>
            )}
        />
    )
})

const TimeLineRow = ({timeStamp:t, description, status, ...props}) => {
    const timeStamp                         = moment.isMoment(t) ? t : moment(t);
    const { formatDateTime, formatTime}     = useLocale();
    return (
        <TableRow>
            <TableCell style={{width:'fit-content', whiteSpace:'pre-line'}}>
                { 
                    moment().isSame(timeStamp, 'day') 
                        ? formatTime(timeStamp) 
                        : formatDateTime(timeStamp)
                }
            </TableCell>
            <TableCell>
                {description}
            </TableCell>
            <TableCell style={{verticalAlign:'center', height:0}}>
                {status}
            </TableCell>
        </TableRow>
    )
}

export const OrderPaymentHistory = withTranslation( ({t, ...props}) => {
    const theme                                         = useTheme();
    const {
        data:order,
        orderId,
        timeLine
    }                                                   = useOrderViewer();

    const {
        isPending = false, 
        isPartial = false
    } = (order ? paymentStatusMap[order.paymentStatus] : undefined) || {};

    if(!orderId)
        return null;

    return (
        <RootContainer component={ItemPaper} id="orderPaymentHistory">
            <Title variant="h5" gutterBottom>
                { t('components.order.orderPaymentHistory.paymentHistory' ) }
            </Title>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{minWidth:100}}> 
                                { t('components.order.orderPaymentHistory.table.dateTime' ) }
                            </TableCell>
                            <TableCell sx={{minWidth:100}}>
                                { t('components.order.orderPaymentHistory.table.description' ) }
                            </TableCell>
                            <TableCell sx={{minWidth:100}}>
                                { t('components.order.orderPaymentHistory.table.status' ) }
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            order && !timeLine && 
                            <TimeLineRow 
                                timeStamp   = { order.updatedAt } 
                                description = { 
                                    <LoadingData color={theme.palette.text.primary} /> 
                                }
                            />
                        }
                        {
                            order && timeLine && 
                            <React.Fragment>
                                <TimeLineRow 
                                    timeStamp   = { order.createdAt }
                                    description = { <> {t('components.order.orderPaymentHistory.orderCreated')} (<ObjectId value={orderId}/>) </>}
                                    status      = { 
                                        <Created>
                                            { t('components.order.orderPaymentHistory.initiated' ) }
                                        </Created>
                                    }
                                />
                                {(timeLine.result || []).map((d,ix) => (
                                    <TimeLineRow 
                                        key         = { ix }
                                        timeStamp   = { d.timeStamp }
                                        description = {
                                            <React.Fragment>
                                                {d.type === 'intent' && t('components.order.orderPaymentHistory.paymentDetailsSubmitted' ) }
                                                {d.type === 'error'  && t('components.order.orderPaymentHistory.paymentFailed' ) }
                                                {d.type === 'charge' && 
                                                    <>
                                                        { t('components.order.orderPaymentHistory.payment' ) } <Currency value={d.amountCaptured}/> / <Currency value={d.amount}/>
                                                        <div>
                                                            {(d?.method || 'Unknown').toUpperCase()}
                                                            {d.receipt_url &&
                                                                <Link href={d.receipt_url} target="_blank" rel="noreferrer" style={{marginLeft:theme.spacing(1),color:theme.palette.secondary.main}}>
                                                                    { t('components.order.orderPaymentHistory.receipt' ) }
                                                                </Link>
                                                            }
                                                        </div>
                                                        
                                                    </> 
                                                }
                                                {d.type === 'refund' && <>{ t('components.order.orderPaymentHistory.refund' ) } <Currency value={d.amount}/> </> }
                                            </React.Fragment>
                                        }
                                        status = {
                                            <React.Fragment>
                                                {d.type === 'intent' && <TransactionStatus status={'succeeded'}/> }
                                                {d.type === 'error'  && <TransactionStatus status={d.status}>{titleCase((d.code).replace('payment_intent_','').replace(/_/g, ' '))}</TransactionStatus> }
                                                {d.type === 'charge' && <TransactionStatus status={d.status}>{d.failureMessage}</TransactionStatus> }
                                                {d.type === 'refund' && <TransactionStatus status={d.status}>{titleCase((d.reason || 'Sys. Gen.').replace(/_/g, ' '))}</TransactionStatus> }
                                            </React.Fragment>
                                        }
                                    />
                                ))}
                                <TimeLineRow 
                                    timeStamp   = {order.updatedAt}
                                    description = { t('components.order.orderPaymentHistory.orderStatus' ) }
                                    status      = {<OrderPaymentStatus status={order.paymentStatus}/>}
                                />
                                {
                                    false && order.shouldProcess &&
                                    <TimeLineRow 
                                        timeStamp   = {order.updatedAt}
                                        description = { t('components.order.orderPaymentHistory.delivery' ) }
                                        status      = {
                                            <Progress>
                                                { t('components.order.orderPaymentHistory.working' ) }
                                            </Progress>
                                        }
                                    />
                                }
                            </React.Fragment>
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            {
                order && orderId && timeLine && (isPending || isPartial) && 
                <Box pt={1}>
                    <FormAlert severity="error">
                        <Box pl={1}>
                            <CountDownOrder expires={order.expiresAt}/>
                        </Box>
                    </FormAlert>
                </Box>
            }

            {
                true && order && timeLine && DEBUG && 
                <>
                    <Title variant="h5"> 
                        { t('components.order.orderPaymentHistory.orderData' ) }
                    </Title>
                    <JSONViewer src={order} />
                    <Title variant="h5"> 
                        { t('components.order.orderPaymentHistory.transactionData' ) }
                    </Title>
                    <JSONViewer src={timeLine} />
                </>
            }
        </RootContainer>
    );
})

export default OrderPaymentHistory;