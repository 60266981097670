/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Deposit Button
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       8th May 2021

*******************************************************************************************/

import React                            from 'react';
import { 
    styled,
    Button as ButtonMUI 
}                                       from '@mui/material';
import PaymentIcon                      from '@mui/icons-material/Payment';
import {
    useUser,
    useNetwork
}                                       from 'contexts';
import { withTranslation }              from './hoc';

const Button = styled(ButtonMUI,{
        shouldForwardProp : prop => prop !== 'pulse'
})(({pulse = false}) => ({
    width : '100%',
    ...(pulse && {
        animation: `pulse 2s infinite`,
        "@keyframes pulse": {
            "0%" : {
                transform: 'scale(0.95)',
                boxShadow: '0 0 0 0 rgba(0, 0, 0, 0.7)'
            },
            "70%" : {
                transform: 'scale(1)',
                boxShadow: '0 0 0 10px rgba(0, 0, 0, 0)'
            },
            "100%" : {
                transform: 'scale(0.95)',
                boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)'
            }
        }
    })
}));

export const MakePaymentButton = withTranslation( ({t, children, pulse = true, ...props}) => {

    const {isNetworkReady}      = useNetwork();
    const {isAuthenticated}     = useUser();

    if(!isAuthenticated) 
        return null;

    return (
        <Button 
            pulse       = { pulse && isNetworkReady }
            variant     = "contained"
            color       = "primary"
            startIcon   = { <PaymentIcon/> }
            {...props}
        >
            { children || t('components.makePaymentButton.addFunds') }
        </Button>
    )
});

export default MakePaymentButton;