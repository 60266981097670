/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Counter
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       30th June 2021

*******************************************************************************************/
import { useTheme }     from '@mui/material';
import React            from 'react';
import CountUp          from 'react-countup';

export const Counter = ({
    value,
    duration        = 2.5,
    formattingFn    = (value) => value,
    style,
    colorless       = false,
    ...props
}) => {
    const theme             = useTheme();

    const [state, setState] = React.useState({
        complete    : false, 
        start       : 0, 
        end         : value, 
        color       : undefined
    });

    // Change state when value changes
    React.useEffect(() => {
        setState(prev => ({
            start       : prev.end, // Previous value becomes start
            end         : value,    // New value becomes end
            complete    : false     // Mark as incomplete
        }));
    }, [value]);

    // Change Color
    React.useEffect(()=>{
        if(colorless) return;
        setState(
            prev => ({
                ...prev,
                color   : value > state.end 
                            ? theme.palette.success.dark 
                            : (value < state.end 
                                ? theme.palette.error.dark
                                : undefined
                            )
            })
        );
    },[colorless, state.end, theme.palette.error.dark, theme.palette.success.dark, value])

    // On End
    const handleEnd = React.useCallback( () => {
        setState(prev => ({...prev, complete:true}));
    }, []);

    // Vanilla
    if(state.complete)
        return <>{formattingFn(value)}</>

    // CountUp Object
    return (
        <CountUp 
            start           = {state.start} 
            end             = {state.end} 
            duration        = {duration} 
            formattingFn    = {formattingFn} 
            onEnd           = {handleEnd}
            style           = {{color:state.color,...style}}
            {...props}
        />
    )
}

export default Counter;