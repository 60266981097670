/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
ObjectId Summary
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       31st March 2022

*******************************************************************************************/
import React                    from 'react';
import { 
    Tooltip, 
    Typography
}                               from '@mui/material';
import { useCopyToClipboard }   from 'hooks';
import { withTranslation }      from './hoc';


export const ObjectId = withTranslation( ({t, value, length=6, prefix = 'Copy', suffix = undefined, allowCopy = true, ...props}) => {
    
    const copy                          = useCopyToClipboard();
    const [title,       setTitle]       = React.useState(undefined);
    const [valueFmt,    setValueFmt]    = React.useState(undefined);

    // Update Tooltip Title
    React.useEffect( () => {
        try{
            setTitle([prefix, value, suffix].filter(Boolean).join(" "))        
        }catch(err){
            //
        }
    },[prefix, suffix, value])

    // Update Formatted Value
    React.useEffect(() => {
        if(value && typeof value === 'string')
            setValueFmt((value || '').slice(-Math.abs(length)).toUpperCase());
        else 
            setValueFmt(undefined);
    }, [length, value])

    // Copy Handler
    const handleCopy    = React.useCallback( () => {
        if(value && typeof value === 'string'){
            copy( value.toUpperCase(), t('components.objectId.copied'));
        }
    }, [t, copy, value]);

    // No Value, or Value isn't string
    if(!value || typeof value !== 'string')
        return null;

    if(props.render)
        return (props.render({id:valueFmt, handleCopy, allowCopy}))

    if(!allowCopy) 
        return (
            <Typography component="span" sx={{fontSize:'inherit',...props?.sx}}>
                {valueFmt}
            </Typography>
        )

    if(title)
        return (
            <Tooltip title={title}>
                <Typography component="span" onClick={handleCopy} style={{cursor:'pointer'}} sx={{fontSize:'inherit',...props?.sx}}>
                    {valueFmt}
                </Typography>
            </Tooltip>
        )
        
    return (
        <Typography component="span" onClick={handleCopy} style={{cursor:'pointer'}} sx={{fontSize:'inherit',...props?.sx}}>
            {valueFmt}
        </Typography>
    )
});

export default ObjectId;