/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Contentful SEO Component
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       31st October 2023

*******************************************************************************************/
import React                from 'react';
import { Seo }              from 'components';

// Force Array
const enforceArray = (arg, dflt = []) => Array.isArray(arg) ? arg : dflt;

export const ContentfulSEO = ({data = undefined, ...props}) => {

    // Deconstruct
    const {title = undefined, description = undefined, image = undefined, keywords = [], ...rest} = props;

    if(!data && !props)
        return null;

    return (
        <Seo 
            title       = { data?.fields?.pageTitle || title} 
            description = { data?.fields?.pageDescription || description}
            keywords    = { 
                [
                    ...new Set([
                        ...(enforceArray(data?.fields?.keywords)), 
                        ...(enforceArray(keywords))
                    ])
                ].join(', ') 
            }
            follow      = { !Boolean(data?.fields?.nofollow) }
            index       = { !Boolean(data?.fields?.noindex) }
            image       = { (data?.fields?.shareImages || [])[0]?.fields?.file?.url || image }
            {...rest}
        />
    )
};

export default ContentfulSEO;