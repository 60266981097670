/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Product Component
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       24th July 2021

*******************************************************************************************/

import React                    from 'react';
import pickBy                   from 'lodash/pickBy';
import isNumber                 from 'lodash/isNumber';
import isEmpty                  from 'lodash/isEmpty';
import moment                   from 'moment';
import pluralize                from 'pluralize';
import seedrandom               from 'seedrandom';
import { boolean }              from 'boolean';
import { titleCase }            from 'title-case';
import {
    alpha,
    styled,
    lighten,
    darken,
    useTheme,
    useMediaQuery, 
    Icon,
    Box,
    Grid,
    Typography,
    DialogActions,
    Table,
    TableBody,
    TableRow,
    TableCell as TableCellMUI,
    TableHead,
    TableContainer
}                               from '@mui/material';
import MoneyIcon                from '@mui/icons-material/MonetizationOn';
import FlightTakeoffIcon        from '@mui/icons-material/FlightTakeoff';
import TickIcon                 from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CrossIcon                from '@mui/icons-material/Cancel';
import SearchIcon               from '@mui/icons-material/Search';
import WatchIcon                from '@mui/icons-material/Watch';
import WatchOffIcon             from '@mui/icons-material/WatchOff';
import {
    RootContainer,
    Title,
    EditProductButton,
    CartIconWithQuantity,
    ImageGallery,
    PriceWithSale,
    TagChip,
    ItemPaper,
    Sticky,
    AddToCartButton,
    JSONViewer,
    FormAlert,
    Price,
    PriceTag,
    ProductCrossSell,
    AdminBox,
    Examples,
    SkeletonWaitingForNetwork,
    ProductReviews,
    RefreshIcon,
    Currency,
    Button,
    SocialSharePage,
    Linkify,
    FileIcon,
    Like,
    Thumbnail,
    DraggableDialog,
    ContentfulRichText,
    ProductUpgradeDowngrade,
    ViewCartCheckoutButton,
    Tabs,
    Tab,
    TabPanel,
}                               from 'components'
import { withTranslation }      from './hoc';
import {
    CommentsThread
}                               from 'components/comments';
import {
    DeleteIconButton
}                               from 'components/iconButtons';
import { 
    useTranslation,
    useProduct,
    useUser,
    useCart,
    useLocale,
    useNetwork,
    CommentsThreadProvider,
    useContentful
}                               from 'contexts';
import useSize                  from 'hooks/useSize';
import { 
    // useCancelToken, 
    useImageCDN,
    useUserFieldOptions
}                               from 'hooks';

const StuckContainer = styled(Box)(({theme}) => ({
    backdropFilter              : 'blur(3px)',
    marginBottom                : theme.spacing(2),
    color                       : theme.palette.primary.dark, 
    background                  : [lighten(theme.palette.primary.light,0.7),'!important'],
    borderTopLeftRadius         : 0,
    borderTopRightRadius        : 0,
    borderRadius                : theme.spacing(1),
    transitionTimingFunction    : 'ease',
    transition                  : '1s'
}))

const UnstuckContainer = styled(Box)(({theme}) => ({
    marginBottom                : theme.spacing(2),
    color                       : theme.palette.primary.dark,
    background                  : [lighten(theme.palette.primary.light,0.9),'!important'],
    transitionTimingFunction    : 'ease',
    transition                  : '1s'
}))

const tableCellFontSizeBreakpoints = (theme,base=0.8) => ({
    [theme.breakpoints.down('lg')] : {
        fontSize : `${base + 0.10}rem`
    },
    [theme.breakpoints.down('md')] : {
        fontSize : `${base + 0.05}rem`
    },
    [theme.breakpoints.down('sm')] : {
        fontSize : `${base}rem`
    },
})

const TableCell = styled(TableCellMUI,{
    shouldForwardProp : prop => prop !== 'lastRow'
})(({theme, lastRow = false}) => ({
    padding : theme.spacing(0.5),
    ...tableCellFontSizeBreakpoints(theme),
    ...(lastRow && {
        borderBottom : 'none'
    })
}))

const TableCellBox = styled(Box)(({theme}) => ({
    '& > *' : {
        fontSize : 'inherit',
        ...tableCellFontSizeBreakpoints(theme),
    }
}))

const TableCellLegend = styled(TableCellMUI,{
    shouldForwardProp : prop => prop !== 'text'
})(({theme, text = false}) => ({
    border          : 'none',
    padding         : 0,
    paddingLeft     : text ? 2 : 0,
    textAlign       : text ? 'left' : 'right',
    fontSize        : text ? 12 : '0.675rem',
    ...tableCellFontSizeBreakpoints(theme,0.6),
}))

const PriceContainer = styled(Box,{
    shouldForwardProp : prop => prop !== 'stuck'
})(({theme, stuck = false}) => ({
    ...(!stuck && {
        fontSize : '3rem',
        [theme.breakpoints.down('xl')] : { fontSize : '3.0rem' },
        [theme.breakpoints.down('lg')] : { fontSize : '2.5rem' },
        [theme.breakpoints.down('md')] : { fontSize : '2.0rem' },
        [theme.breakpoints.down('sm')] : { fontSize : '1.5rem' },
        paddingBottom : theme.spacing(1)
    }),
    ...(stuck && {
        fontSize : '2.0rem',
        [theme.breakpoints.down('xl')] : { fontSize : '2.00rem' },
        [theme.breakpoints.down('lg')] : { fontSize : '1.75rem' },
        [theme.breakpoints.down('md')] : { fontSize : '1.50rem' },
    })
}))


const FooterTypography = styled(Typography)(({theme}) => ({
    fontSize    : '0.6rem',
    right       : theme.spacing(2)
}))

const CenteredVerticalContainer = styled(Box)({
    marginTop       : 'auto',
    marginBottom    : 'auto'
})

const FeatureBox = styled(Box)(({theme}) => ({
    borderRadius    : theme.spacing(1),
    padding         : theme.spacing(2),
    border          : `1px solid ${theme.palette.text.primary}`,
    background      : theme.palette.mode === "light" 
        ? lighten(theme.palette.info.light,0.75)
        : darken(theme.palette.info.dark,0.75),
}))

const  humanizeWeeks = (durationInSeconds) => {
    const weekInSeconds         = (7 * 24 * 60 * 60);
    const weeks                 = Math.floor(durationInSeconds / weekInSeconds);
    const remainingSeconds      = durationInSeconds % weekInSeconds;
    let result = '';
    if(durationInSeconds === 0)
        return 'immediately' 
    if (weeks > 0) {
        result += `${weeks} week${weeks > 1 ? 's' : ''}`;
        if (remainingSeconds > 0) {
            result += ' and ';
        }
    }
    if(weeks === 0 || remainingSeconds > 0)
        result += moment.duration(remainingSeconds, 'seconds').humanize();
    return result
}

const arr = [];

const Images = withTranslation( ({t, title, images=arr, isAvailable = true, showImages=true, showTitle = false, sticky=false, ...props}) => {
    
    const convert                       = useImageCDN();
    const {
        DEFAULT_PRODUCT_IMAGE,
        GRAYSCALE_WHEN_UNAVAILABLE
    }                                   = useProduct();
    const [fullScreen, setFullScreen]   = React.useState(false);
    const sources                       = React.useMemo(() => (
        Array.isArray(images) && images.length > 0 
            ? images 
            : [{
                src     : DEFAULT_PRODUCT_IMAGE,
                // label   : "Product Cover"
            }]
    ),[images,DEFAULT_PRODUCT_IMAGE]);
    
    const convertImageFunction          = React.useCallback( (img,width) => convert(img, {
        operation   : 'width',
        width       : width,
        options     : `grayscale:${isAvailable || !GRAYSCALE_WHEN_UNAVAILABLE ? false : true}`
    }),[convert, isAvailable, GRAYSCALE_WHEN_UNAVAILABLE]);
    
    const galleryImages                 = React.useMemo(() => (
        (sources || [])
            .map(({src,label}) => ({
                thumbnail       : convertImageFunction(src, 100),
                thumbnailLabel  : label,
                original        : convertImageFunction(src, fullScreen ? 2000 : 500),
            }))
    ), [sources, convertImageFunction, fullScreen]);

    const showThumbnails = React.useMemo(() => (
        galleryImages?.length > 1
    ), [galleryImages?.length])

    // No Images
    if(!showImages || !sources.length) 
        return null;

    // Build Image gallery
    return (
        <Sticky enabled={sticky}>
            <Box id="imageGallery" component={ItemPaper}>
                {   
                    showTitle && 
                    <Title gutterBottom variant="h5">
                        {title || t('common.showcase')}
                    </Title>
                }
                <ImageGallery 
                    items           = { galleryImages } 
                    showThumbnails  = { showThumbnails }
                    onScreenChange  = { setFullScreen } 
                />
            </Box>
        </Sticky>
    )
});


const SkeletonProduct = (props) => (
    <Box width="100%"> 
        <Grid container>
            <Grid item xs={5} sm={4}>
                <Box width="100%" style={{aspectRatio:'1'}}>
                    <SkeletonWaitingForNetwork/>
                </Box>
            </Grid>
            <Grid component={RootContainer} item xs={7} sm={8}>
                <Box style={{height:200}}>
                    <SkeletonWaitingForNetwork/>
                </Box>
                <Box style={{aspectRatio:'1'}}>
                    <SkeletonWaitingForNetwork/>
                </Box>
            </Grid>
        </Grid>
    </Box>
)

const DigitalFile = ({type, name, mime}) => {
    return (
        <Box sx={{borderRadius : theme => theme.spacing(1), border:theme => `1px solid ${theme.palette.divider}`}}>
            <Box sx={{maxWidth:75,mx:'auto',p:1}}>
                <FileIcon extension = {type} />
            </Box>
            <Box>
                <Typography align="center" sx={{fontWeight:400}}>
                    {name}
                </Typography>
                <Typography variant="body2" align="center">
                    {mime}
                </Typography>
            </Box>
        </Box>
    )
}

const UserInputOptions = withTranslation( ({t, available = true, item = undefined, label = undefined}) => {
    const convert                       = useImageCDN();
    const {GRAYSCALE_WHEN_UNAVAILABLE}  = useProduct();
    const [open, setOpen]               = React.useState(false);
    const handleOpen                    = React.useCallback(() => setOpen(true), []);
    const handleClose                   = React.useCallback(() => setOpen(false), []);
    const options                       = React.useMemo(() => (item?.options || []).filter(x => Boolean(x?.image)), [item]);
    if(!item || item?.type !== 'enum') 
        return null
    if(!options.length)
        return null;
    return (
        <Box display="flex" align="right" sx={{ml:'auto'}}>
            <Box flexGrow={1}/>
            <Box>
                <Button variant="contained" startIcon = {<SearchIcon/>} size="small" onClick={handleOpen} sx={{minWidth:150}}> 
                    {t('components.product.viewLabel', {label : label && typeof label === 'string' ? pluralize.plural(label) : label })}
                </Button>
            </Box>
            <DraggableDialog
                title       = { t('components.product.userInputOptions') }
                open        = {open}
                onClose     = {handleClose}
                onCancel    = {handleClose}
                showButtons = {false}
                maxWidth    = {'sm'}
                fullWidth   = {true}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <Typography>
                            { t('components.product.clickImageToEnlarge') }
                        </Typography>
                    </Grid>
                {
                    options.map(({image,label},ix) => {
                        const title     = label ? `Preview, ${label}` : "Preview"
                        const thumbnail = convert(image,{
                            operation   : 'width',
                            width       : 100,
                            options     : `grayscale:${available || !GRAYSCALE_WHEN_UNAVAILABLE ? false : true}`
                        });
                        const highres   = convert(image,{
                            operation   : 'width',
                            width       : 500,
                            options     : `grayscale:${available || !GRAYSCALE_WHEN_UNAVAILABLE ? false : true}`
                        });
                        if(!image)
                            return null;
                        return (
                            <Grid item xs={6} key={ix}>
                                <Box display="flex" width="100%">
                                    <Box>
                                        <Thumbnail title={title} thumbnail={thumbnail} src={highres} thumbnailSize={50}/>
                                    </Box>
                                    <Box ml={2} sx={{my:'auto'}}>
                                        <Typography>
                                            {label}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        )
                    })
                }
                </Grid>
            </DraggableDialog>
        </Box>
    )
});

export const Product = ({
    id, 
    disabled        = false, 
    showPricing     = true, 
    showImages      = true, 
    showRawData     = false,
    showCrossSell   = true,
    showComments    = false,
    buttonText      = undefined,
    sticky          = false,
}) => {
    
    const theme                                         = useTheme();
    const [t]                                           = useTranslation();
    const { formatDateTime }                            = useLocale();
    const {isAuthenticated,isAdmin}                     = useUser();
    const {axios}                                       = useNetwork();
    // const {cancelToken, isCancel}                        = useCancelToken();
    const {
        hasItems,
        cart,
        added,
        removed,
        removeProductFromCart,
        currentQuantityForProduct
    }                                                   = useCart();
    const {
        data : products,
        processors,
        toUrl,
        contentful : contentfulCollection
    }                                                   = useProduct();
    const {client}                                      = useContentful();
    const [productFields,       setProductFields]       = React.useState({});
    const [productUserFields,   setProductUserFields]   = React.useState({});
    const product                                       = React.useMemo(() => products.find(item => item._id === id), [id, products]);

    const [currentQuantity,     setCurrentQuantity]     = React.useState(0);
    const [isStuck,             setStuck]               = React.useState(false);
    const [processor,           setProcessor]           = React.useState({});
    const [showEditButton,      setShowEditButton]      = React.useState(false);
    const [data,                setData]                = React.useState(undefined);

    React.useEffect(() => {
        if(product?.processor){
            const promises = ['all',product?.processor]
                .filter(Boolean)
                .map(processor => (
                    client.getEntries({
                        content_type        : 'fieldProcessorProductField',
                        'fields.processor'  : processor,
                    })
                ));
            Promise
                .all(promises)
                .then(responses => responses.map(response => response.items))
                .then(fieldsArr => {
                    const fields = fieldsArr.reduce((acc,cur) => [...acc,...cur], [])
                    setProductFields(
                        fields.reduce((acc,cur) => {
                            return cur?.fields?.fieldKey 
                                ? {...acc, [cur.fields.fieldKey] : cur } 
                                : {...acc}
                            },
                            {}
                        )
                    )  
                })
                .catch(err => {
                    console.error(err);
                    setProductFields({})        
                })
        }else{
            setProductFields({})
        }
    },[client, product?.processor])

    React.useEffect(() => {
        if(product?.processor){
            const promises = ['all',product?.processor]
                .filter(Boolean)
                .map(processor => (
                    client.getEntries({
                        content_type        : 'fieldProcessorUserField',
                        'fields.processor'  : processor,
                    })
                ));
            Promise
                .all(promises)
                .then(responses => responses.map(response => response.items))
                .then(fieldsArr => {
                    const fields = fieldsArr.reduce((acc,cur) => [...acc,...cur], [])
                    const newUserFields = fields.reduce((acc,cur) => {
                        return cur?.fields?.fieldKey 
                            ? {...acc, [cur.fields.fieldKey] : cur } 
                            : {...acc}
                        },
                        {}
                    )
                    setProductUserFields(newUserFields)  
                })
                .catch(err => {
                    console.error(err);
                    setProductUserFields({})        
                })
        }else{
            setProductUserFields({})
        }
    },[client, product?.processor])

    React.useEffect(() => {
        setData(
            product?.slug 
                ? contentfulCollection.find(x => x?.fields?.slug === product?.slug) 
                : undefined
        )
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[JSON.stringify(contentfulCollection),product?.slug])

    const userFieldsBase                            = React.useMemo(() => (
        Object
            .entries(processor?.userFields || [])
            .map(([name,field]) => ({...field,name}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [ JSON.stringify(processor?.userFields) ]);
    const userFieldsProcessedOptions                = useUserFieldOptions(userFieldsBase, id)
    const userFields                                = React.useMemo(() => {
        return userFieldsProcessedOptions.reduce((acc,cur) => ({
            ...acc,
            [cur.name] : cur
        }),{})
    },[userFieldsProcessedOptions])

    const ref                                       = React.useRef(null);
    const {width}                                   = useSize(ref);
    const isAvailable                               = React.useMemo(() => (
        product?.available && product?.listed && !product?.deleted
    ),[product?.available, product?.deleted, product?.listed])

    // Handle Show/Hide Edit Button
    const handleShowEditButton                      = React.useCallback( () => setShowEditButton(true), []);
    const handleHideEditButton                      = React.useCallback( () => setShowEditButton(false), []);

    // Remove Product from Cart
    const handleRemClick                            = React.useCallback( () => removeProductFromCart({productId:id}), [id, removeProductFromCart]);

    React.useEffect(()=>{
        if(product && product.type){
            let type = product.type;
            let proc = (processors[type] || []).find(p => p.className === product.processor) || {}
            setProcessor(proc)
        }else{
            setProcessor({});
        }
    },[product,processors])

    React.useEffect(()=>{
        setCurrentQuantity(currentQuantityForProduct(id))
    },[cart, currentQuantityForProduct, id])

    // Concat Product Images
    const images                = React.useMemo(() => {
        // console.log(userFields)
        return ([
            product 
                ? (
                    product?.cover 
                        ? {
                            src     : product?.cover,
                            label   : 'Product Cover'
                        } 
                        : undefined
                )
                : undefined,
            ...(product?.images || []).map((src,ix) => ({
                    src     : src, 
                    label   : `Product Image ${ix + 1}`
                })
            ),
            ...(
                (Object.values(userFields) || [])
                    .filter(x => (  // {label,description,name,options,type,typeLabel}
                        x?.type === 'enum'
                    )) 
                    .reduce((acc,cur) => ([
                        ...acc,
                        ...(
                            (cur?.options || [])
                                .map(({image,label},ix) => (
                                    image 
                                        ? {
                                            src     : image, 
                                            label   : label || [cur?.label,`Image ${ix + 1}`].filter(Boolean).join(' - ') 
                                        } 
                                        : undefined 
                                )) // 
                                .filter(Boolean)
                        )
                    ]),[])
            )
        ].filter(Boolean))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product?.cover, product?.images, JSON.stringify(userFields)]);

    const descriptionParagraphs = React.useMemo(() => (product?.description || "").split(/\n/).filter(Boolean), [product?.description]);
    const abstractParagraphs    = React.useMemo(() => (product?.abstract || "").split(/\n/).filter(Boolean), [product?.abstract]);
    const requiresUserInput     = React.useMemo(() => Boolean(processor) && Boolean(processor.requiresUserInput), [processor]);
    const requiresProductInput  = React.useMemo(() => Boolean(processor) && Boolean(processor.requiresProductInput), [processor]);
    const views                 = React.useMemo(() => (product?.views || 0) + 1, [product?.views]);

    const attachmentFields      = React.useMemo(() => pickBy(processor?.productFields || {}, x => ['attachment'].includes(x.type)),[processor?.productFields]);       // Object
    const enumFields            = React.useMemo(() => pickBy(processor?.productFields || {}, x => ['enum','boolean','integer'].includes(x.type) && x?.inclusion), [processor?.productFields]);   // Object
    const requiresLegend        = React.useMemo(() =>!isEmpty(pickBy(processor?.productFields || {}, x => ['enum','boolean'].includes(x.type) && x?.inclusion)), [processor?.productFields]);   // Object
    const hasAttachments        = React.useMemo(() => (
        Boolean(
        Object
            .keys(attachmentFields)
            .reduce((acc,cur) => {
                const arr = ((product?.productInput || {})[cur] || []);
                return acc + (Array.isArray(arr) ? arr.length : 0)
            }, 0) > 0
        )
    ),[attachmentFields, product?.productInput]);

    const hasComputed           = React.useMemo(() => (
        (processor?.attachmentsComputed || []).length > 0
    ), [processor?.attachmentsComputed]);

    const hasEnumFields        = React.useMemo(() => (
        Boolean(
            Object
                .keys(enumFields)
                .reduce((acc,cur) => {
                    return acc + (Object((product?.productInput || {})).hasOwnProperty(cur) ? 1 : 0)
                }, 0) > 0
        )
    ),[enumFields, product?.productInput]);

    // FUnction to slect Random
    const selectRandom          = React.useCallback( arr => {
        var rng = seedrandom(id);   
        return arr[Math.floor(rng() * arr.length)];
    },[id]);

    // Discount is stored as negative value in db, turn it into an amount from clients perspective
    const discountAmount        = React.useMemo(() => -(product?.discount || 0),[product?.discount]);
    const otherProductsExists   = React.useMemo(() => Boolean(products.filter(p => p?._id && p?._id !== product?._id && p?.listed).length), [product?._id, products]);
    const imagesId              = React.useMemo(() => "imagesGalleryGrid", []);

    React.useEffect(() => {
        if(axios && product?._id){
            axios
                .post(`/api/product/${product?._id}/view`)
                .catch(console.error);
        }
    },[axios, product?._id])

    const leadTime              = React.useMemo(() => (
        isNumber(product?.leadTimeSecondsOverride) ?  product?.leadTimeSecondsOverride : processor?.leadTimeSeconds
    ),[processor?.leadTimeSeconds, product?.leadTimeSecondsOverride])
    
    const smUp                  = useMediaQuery(theme => theme.breakpoints.up('sm'));
    const lgUp                  = useMediaQuery(theme => theme.breakpoints.up('lg'));
    const name                  = React.useMemo(() => data?.fields?.name || product?.name || t('common.product'), [data?.fields?.name, product?.name, t])

    const [value, setValue]                     = React.useState(0);
    const handleChange                          = React.useCallback( (event, newValue) => setValue(newValue), []);
    React.useEffect(() => setValue(0), [id]); // reset when product changes

    // console.log(userFields);

    // Empty
    if(!product){
        return (
            <SkeletonProduct />
        )
    }

    return (
        <Box width="100%" id="product">
            <Box component={Grid} container spacing={2} >
                
                {
                    !product?.available && 
                    <Box component={Grid} item xs={12}>
                        <FormAlert severity="warning">
                            { t('components.product.productUnavailable') }
                        </FormAlert>
                    </Box>
                }

                { 
                    smUp && 
                    <Box component={Grid} id={imagesId} item xs={12} sm={5} md={4}>
                        <Images 
                            sticky      = {true} 
                            images      = {images} 
                            showImages  = {showImages} 
                            isAvailable = {isAvailable}
                        />
                    </Box>
                }
                
                <Grid component={RootContainer} item xs={12} sm={showImages ? 7 : 12} md={showImages ? 8 : 12} >
                    
                    {
                        showPricing &&
                        <Sticky
                            enabled             = { sticky }
                            onStick             = { setStuck }
                            stuckComponent      = { sticky ? StuckContainer : undefined}
                            unstuckComponent    = { UnstuckContainer }
                        >
                            <Box 
                                position        = "relative"
                                component       = "div"
                                p               = {2} 
                                mt              = {2}
                                style           = {{textAlign:'right', marginTop:0, borderRadius: theme.spacing(0)}}
                                onMouseOver     = {handleShowEditButton} 
                                onMouseLeave    = {handleHideEditButton}
                            >
                                {
                                    (added || removed) && isStuck && 
                                    <div style={{height:theme.spacing(5)}}/>
                                }
                                <Box display="flex" position="relative" width="100%">
                                    <Box flexShrink={1} position="relative" style={{whiteSpace:'nowrap', maxWidth:`calc(100% - ${width}px - ${showEditButton ? 40 : 0}px)`}}>
                                        <Box align="left" sx={{overflow:'hidden'}}>
                                            <Title component='span' variant="h3" color="inherit">
                                                {name}
                                            </Title>
                                        </Box>
                                        {
                                            product.onSale && 
                                            <>
                                                {
                                                    lgUp && 
                                                    <Box style = {{
                                                        position        : 'absolute',
                                                        right           : theme.spacing(-1),
                                                        top             : '50%', 
                                                        transform       : `translatey(-50%) translatex(100%) rotate(-15deg)`,
                                                        transformOrigin : 'center left'
                                                    }}>
                                                        <PriceTag color={theme.palette.secondary.textContrast} background={theme.palette.error.main}>
                                                            {
                                                                selectRandom([
                                                                    t('common.onSale'), 
                                                                    t('common.priceReduced'),
                                                                    <>Save <Price price={discountAmount} colorless={true} unit={null}/></>,
                                                                    <><Price price={discountAmount} colorless={true} unit={null}/> off</>,
                                                                    t('common.discounted'),
                                                                    t('common.promo')
                                                                ])
                                                            }
                                                        </PriceTag>
                                                    </Box>
                                                }
                                            </>
                                        }
                                    </Box>
                                    <Box flexGrow={1}/>
                                    {
                                        isAdmin && isAuthenticated && !disabled && 
                                        <CenteredVerticalContainer flexShrink={1}>
                                            <EditProductButton 
                                                visible         = {showEditButton}
                                                disabled        = {disabled} 
                                                productId       = {id} 
                                                useIconButton   = {true}
                                                onOpenChange    = {(open) => {
                                                    if(open)
                                                        handleHideEditButton();
                                                }}
                                                IconButtonProps = {{
                                                    color       : "secondary",
                                                    size        : "small",
                                                }}
                                            />
                                        </CenteredVerticalContainer>
                                    }
                                    <Box ref={ref} display={"flex"} flexShrink={1}>
                                        {
                                            currentQuantity > 0 && !disabled &&
                                            <CenteredVerticalContainer flexShrink={1}>
                                                <DeleteIconButton 
                                                    size        = "small" 
                                                    tooltip     = {t('common.remove')} 
                                                    onClick     = {handleRemClick}
                                                    color       = "error"
                                                />
                                            </CenteredVerticalContainer>
                                        }
                                        {
                                            currentQuantity > 0 &&
                                            <CenteredVerticalContainer flexShrink={1}>
                                                <CartIconWithQuantity quantity={currentQuantity}/>
                                            </CenteredVerticalContainer>
                                        }
                                        {
                                            lgUp &&
                                            <CenteredVerticalContainer ml={1} flexShrink={1}>
                                                <Title variant="h5" color="inherit"sx={{whiteSpace:'nowrap'}}>
                                                    {t('common.pricing')}
                                                </Title>
                                            </CenteredVerticalContainer>
                                        }
                                    </Box>
                                </Box>
                                
                                <Box display="flex" justifyContent={"flex-end"}>
                                    <Box>
                                        <PriceContainer stuck={sticky && isStuck}>
                                            <PriceWithSale 
                                                price           = {product?.price}
                                                priceOriginal   = {product?.listPrice}
                                                unit            = "* ea"
                                                sx              = {{
                                                    color       : 'inherit',
                                                    fontSize    : 'inherit'
                                                }}
                                            />
                                        </PriceContainer>
                                        {
                                            product.onSale &&
                                            <Currency 
                                                value   = {discountAmount} 
                                                render  = {({valueFormatted}) => (
                                                    <Typography gutterBottom sx={{color:'inherit'}}>
                                                        { t(`components.product.saving${lgUp ? "Long" : "Short"}`, {valueFormatted}) }
                                                    </Typography>
                                                )}
                                            />
                                        }
                                    </Box>
                                </Box>

                                <DialogActions sx={{paddingRight:0, paddingBottom:0 }}>
                                    {
                                        hasItems &&
                                        <ViewCartCheckoutButton disabled={disabled} size="large" variant="contained">
                                            {t('common.viewCart')}
                                        </ViewCartCheckoutButton>
                                    }
                                    <AddToCartButton productId={id} disabled={disabled} sx={{ml:2}}>
                                        {buttonText}
                                    </AddToCartButton>
                                </DialogActions>
                                
                            </Box>
                        </Sticky>
                    }

                    {
                        !smUp && 
                        <Box component={Grid} id={imagesId} item xs={12} sm={5} md={4}>
                            <Images images={images} showImages={showImages} isAvailable={isAvailable}/>
                        </Box>
                    }

                    <Tabs value={value} onChange={handleChange} unmountOnExit={false}>
                        <Tab 
                            value={0}       
                            label={t('common.about')}
                        />

                        <Tab 
                            value={1}       
                            label={t('common.details')}
                        />

                        {
                            requiresUserInput &&
                            <Tab 
                                value={2}       
                                label={t('common.inputs')}
                            />
                        }

                        {
                            (hasComputed || hasAttachments) &&
                            <Tab 
                                value={3}       
                                label={t('common.includes')}
                            />
                        }

                        {
                            product && (product?.downgradeTo || product?.upgradeTo) &&
                            <Tab 
                                value={6}       
                                label={t('common.related')}
                            />
                        }

                        {
                            product?.isVirtual && product?.canExample &&
                            <Tab 
                                value={4}       
                                label={t('common.examples')}
                            />
                        }

                        {
                            product?.id && product?.canReview &&
                            <Tab 
                                value={5}       
                                label={t('common.reviews')}
                            />
                        }

                    </Tabs>

                    <TabPanel value={value} index={0} sx={{height:'unset'}}>
                        <ItemPaper style={{position:'relative'}}>
                            <RootContainer>
                                {/* Headers */}
                                <Box display={"flex"} width={'100%'}>
                                    <Box flexGrow={1}>
                                        <Title variant="h4">
                                            {t('components.product.aboutName', { name }) }
                                        </Title>
                                    </Box>
                                    <Box ml={2}>
                                        <Like reference={product.id} size="small"/>
                                    </Box>
                                    {
                                        lgUp &&
                                        <Box ml={2}>
                                            <SocialSharePage url={toUrl({id : product.slug || product.id, absolute:true})} width="fit-content"/>
                                        </Box>
                                    }
                                </Box>

                                {
                                    !lgUp &&
                                    <Box align="right">
                                        <SocialSharePage width="fit-content"/>
                                    </Box>
                                }

                                {/* Chips */}
                                <Box display="flex">
                                    {
                                        !product?.available && 
                                        <TagChip color="default" label={t('common.unavailable')} />
                                    }
                                    {
                                        product?.onSale && 
                                        <TagChip icon={<MoneyIcon/>} color="default" label={t('common.onSale')} />
                                    }
                                    {
                                        product?.requiresShipping && 
                                        <TagChip icon={<FlightTakeoffIcon/>} color="default" label={t('common.isShipped')}/>
                                    }
                                    {
                                        [product?.tags || [], product?.categories || []]
                                        .map((arr,ixA) => (
                                            arr.filter(Boolean).map((value,ixB) => (
                                                <TagChip
                                                    key     = {`${ixA}-${ixB}`} 
                                                    label   = {titleCase(value)} 
                                                    color   = {['primary','secondary'][ixA]}
                                                />
                                            ))
                                        ))
                                    }
                                </Box>

                                {/* Product Views */}
                                {
                                    <Box>
                                        <Typography component={Box} style={{fontStyle:'italic'}} variant="body2">
                                            {
                                                [
                                                    `This product has been viewed ${views}x ${views === 1 ? 'time' : 'times'}`,
                                                    isAuthenticated && isAdmin 
                                                        ? `puchased ${product?.timesUsed || 0} ${product?.timesUsed === 1 ? 'time' : 'times'}` 
                                                        : undefined 
                                                ]
                                                    .filter(Boolean)
                                                    .join(', and ')
                                            }.
                                        </Typography>
                                    </Box>
                                }
                                
                                {/* Descriptions */}
                                <Box sx={{"& > * + *" : { mt : 2}}}>


                                    {
                                        data?.fields?.abstract &&
                                        <Box>
                                            <Title variant="h5">
                                                {t('common.summary')}
                                            </Title>
                                            <FeatureBox sx={{mt:2,mb:4}}>
                                                <Linkify>
                                                    {
                                                        <Typography style={{fontWeight:400}}>
                                                            {data?.fields?.abstract}
                                                        </Typography>
                                                    }

                                                    {
                                                        false &&
                                                        abstractParagraphs.map((paragraph,ix) => (
                                                            <Typography key={ix} style={{fontWeight:400}} paragraph>
                                                                {paragraph}
                                                            </Typography>
                                                        ))
                                                    }
                                                </Linkify>
                                            </FeatureBox>
                                        </Box>
                                    }

                                    <Box>
                                        <Linkify>
                                            <Title variant="h5">
                                                {t('common.description')}
                                            </Title>

                                            {
                                                false &&
                                                descriptionParagraphs.map((paragraph, ix) => (
                                                    <Typography key={ix} align="justify" paragraph>
                                                        {paragraph}
                                                    </Typography>
                                                ))
                                            }

                                            {
                                                <ContentfulRichText 
                                                    content={data?.fields?.description} 
                                                />
                                            }

                                        </Linkify>
                                    </Box>
                                
                                    <Box sx={{"& > * + *" : {mt : 1}}}>
                                        {
                                            data?.fields?.coverReference &&
                                            <Box>
                                                <FooterTypography color="textSecondary" align="right">
                                                    {t('components.product.coverPhotoReference', {reference : data?.fields?.coverReference }) }
                                                </FooterTypography>
                                            </Box>
                                        }
                                        <Box>
                                            <FooterTypography color="textSecondary" align="right">
                                                * {t('components.product.listedPricesExclusive') }
                                            </FooterTypography>
                                            {
                                                Array.isArray(data?.fields?.footnotes) && data?.fields?.footnotes?.length > 0 &&
                                                data?.fields?.footnotes.map((footnote,ix) => (
                                                    <FooterTypography key={ix} color="textSecondary" align="right">
                                                        {footnote}
                                                    </FooterTypography>
                                                ))
                                            }
                                        </Box>
                                        <Box>
                                            <FooterTypography color="textSecondary" align="right">
                                                {t('components.product.lastUpdated',{timeStamp : formatDateTime(moment(product?.updatedAt))})}
                                            </FooterTypography>
                                        </Box>
                                    </Box>
                                </Box>
                            </RootContainer>
                        </ItemPaper>
                    </TabPanel>

                    <TabPanel value={value} index={1} sx={{height:'unset'}}>
                        <ItemPaper style={{position:'relative'}}>
                            <RootContainer>
                                <Title gutterBottom variant="h4">
                                    {t('components.product.productDetails') }
                                </Title>
                                    
                                {
                                    product && 
                                    <Box>
                                        <Title variant="h5" gutterBottom>
                                            {t('common.delivery')}
                                        </Title>
                                        {
                                            !product?.requiresShipping && 
                                            <Typography gutterBottom>
                                                {t('components.product.virtualProductElectronic') }
                                            </Typography>
                                        }
                                        {
                                            product?.requiresShipping && 
                                            <Typography gutterBottom>
                                                {t('components.product.physicalProductShipped') }
                                            </Typography>
                                        }
                                    </Box>
                                }

                                {
                                    product.isVirtual &&
                                    <Box>
                                        <Title variant="h5" gutterBottom>
                                            {t('components.product.productLeadTime') }
                                        </Title>
                                        {
                                            leadTime > 0 && 
                                            <>
                                                {
                                                    requiresUserInput && 
                                                    <Typography gutterBottom>
                                                        Once the necessary user-input has been provided, the order will be delivered 
                                                        within <strong>approximately {humanizeWeeks(leadTime)}</strong>, or earlier 
                                                        (if possible), depending on current workload.
                                                    </Typography>
                                                }
                                                {
                                                    !requiresUserInput && 
                                                    <Typography gutterBottom>
                                                        Order will be delivered 
                                                        within <strong>approximately {humanizeWeeks(leadTime)}</strong> after 
                                                        order commencement, or earlier (if possible), depending on current workload.
                                                    </Typography>
                                                }
                                            </>
                                        }
                                        {
                                            leadTime <= 0 && 
                                            <>
                                                {
                                                    requiresUserInput && 
                                                    <Typography gutterBottom>
                                                        {t('components.product.processedImmediatelyUserInput') }
                                                    </Typography>
                                                }
                                                {
                                                    !requiresUserInput && 
                                                    <Typography gutterBottom>
                                                        {t('components.product.processedImmediately') }
                                                    </Typography>
                                                }
                                            </>
                                        }
                                    </Box>
                                }

                                {
                                    hasEnumFields && requiresProductInput &&
                                    <Box sx={{height:'fit-content'}}>
                                        <Title variant="h5" gutterBottom>
                                            { t('common.inclusions') }
                                        </Title>

                                        {
                                            false && 
                                            <JSONViewer src={productFields} /> 
                                        }

                                        <Typography gutterBottom>
                                            { t('components.product.inclusionsSummary') }
                                        </Typography>

                                        <TableContainer>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell style={{width:25}}/>
                                                        <TableCell sx={{fontWeight:600}}>
                                                            {t('common.description')}
                                                        </TableCell>
                                                        <TableCell style={{width:50,fontWeight:600}} align="right">
                                                            {t('common.included')}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        Object.keys(enumFields).map((key,ix) => {

                                                            let item        = enumFields[key];
                                                            let value       = product.productInput[key];


                                                            let condKnown   = item.type === 'enum' && value === 'knownBirthTime';
                                                            let condUnknown = item.type === 'enum' && value === 'unknownBirthTime';
                                                            let explicitYes = ((item.type === 'boolean' && boolean(value))  || (item.type === 'enum'    && [true,   'yes'   ].includes(value)));
                                                            let explicitNo  = ((item.type === 'boolean' && !boolean(value)) || (item.type === 'enum'    && [false,  'no'    ].includes(value)));
                                                            // let explicitUnk = !explicitYes && !explicitNo;
                                                            let lastRow     = ix >= Object.keys(enumFields).length - 1;

                                                            return (
                                                                <TableRow key={ix}>
                                                                    <TableCell lastRow={lastRow} sx={{verticalAlign:'top',fontWeight:400}} align="right">
                                                                        {ix+1}
                                                                    </TableCell>
                                                                    <TableCell lastRow={lastRow} sx={{verticalAlign:'top'}}>
                                                                        <Typography component="div" sx={{fontWeight:400,fontSize:'inherit'}}>
                                                                            {productFields[key]?.fields?.title || item.label || item.type}
                                                                        </Typography>
                                                                        {
                                                                            isEmpty(productFields[key]?.fields?.description) &&
                                                                            <Typography component="div" color="text.secondary" sx={{fontSize:'inherit'}}>
                                                                                {item.description}    
                                                                            </Typography>
                                                                        }
                                                                        
                                                                        {
                                                                            !isEmpty(productFields[key]?.fields?.description) &&
                                                                            <ContentfulRichText 
                                                                                component   = {TableCellBox}
                                                                                content     = {productFields[key]?.fields?.description} 
                                                                                sx          = {{'& > *' : {color : theme => theme.palette.text.secondary}}}
                                                                            />
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell lastRow={lastRow} align="right" sx={{whiteSpace:'nowrap',verticalAlign:'top',fontSize:'30px!important'}}>
                                                                            {
                                                                                item.type === 'integer' &&
                                                                                <Typography component="span" sx={{ verticalAlign: 'middle'}}>
                                                                                    {value} {item?.unit}
                                                                                </Typography>
                                                                            }
                                                                            {   explicitYes &&  
                                                                                <Icon style={{ verticalAlign: 'middle' }} sx={{fontSize:'inherit'}}>
                                                                                    <TickIcon color="success" sx={{fontSize:'inherit'}}/>
                                                                                </Icon>
                                                                            }
                                                                            {   !explicitYes && explicitNo &&   
                                                                                <Icon style={{ verticalAlign: 'middle' }} sx={{fontSize:'inherit'}}>
                                                                                    <CrossIcon color="error" sx={{fontSize:'inherit'}}/>
                                                                                </Icon>
                                                                            }
                                                                            {   
                                                                                !explicitYes && !explicitNo && (condKnown || condUnknown) &&
                                                                                <>
                                                                                    {condKnown && 
                                                                                        <Icon style={{ verticalAlign: 'middle' }} sx={{fontSize:'inherit'}}>
                                                                                            <WatchIcon color="success" sx={{fontSize:'inherit'}}/> 
                                                                                        </Icon>
                                                                                    }
                                                                                    {condUnknown && 
                                                                                        <Icon style={{ verticalAlign: 'middle' }} sx={{fontSize:'inherit'}}>
                                                                                            <WatchOffIcon color="error" sx={{fontSize:'inherit'}}/> 
                                                                                        </Icon>
                                                                                    }
                                                                                    <Icon style={{ verticalAlign: 'middle' }} sx={{fontSize:'inherit'}}>
                                                                                        <TickIcon color="warning" sx={{fontSize:'inherit'}}/>
                                                                                    </Icon>
                                                                                </>
                                                                            }
                                                                            {
                                                                                !explicitYes && !explicitNo && !condKnown && !condUnknown && item.type !== 'integer' &&
                                                                                <Icon style={{ verticalAlign: 'middle' }} sx={{fontSize:'inherit'}}>
                                                                                    <RadioButtonUncheckedIcon color="disabled" sx={{fontSize:'inherit'}}/>
                                                                                </Icon>
                                                                            }
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        })
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                        {
                                            requiresLegend &&
                                            <Box display="flex" mt={2}>
                                                <Box flexGrow={1}/>
                                                <Box width="fit-content" sx={{pl:2,pr:1, borderTop:theme => `5px solid ${theme.palette.divider}`}}>
                                                    <Typography sx={{fontWeight:400}}>
                                                        { t('components.product.inclusionsLegend') }
                                                    </Typography>
                                                    <TableContainer>
                                                        <Table>
                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCellLegend>
                                                                        <TickIcon color="success" sx={{fontSize:'inherit'}}/>
                                                                    </TableCellLegend>
                                                                    <TableCellLegend text={true}>
                                                                        { t('components.product.alwaysIncluded') }
                                                                    </TableCellLegend>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCellLegend><WatchIcon color="success" sx={{fontSize:'inherit'}}/>
                                                                        <TickIcon color="warning" sx={{fontSize:'inherit'}}/>
                                                                    </TableCellLegend>
                                                                    <TableCellLegend text={true}>
                                                                        { t('components.product.includedBirthTimeKnown') }
                                                                    </TableCellLegend>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCellLegend><WatchOffIcon color="error" sx={{fontSize:'inherit'}}/>
                                                                        <TickIcon color="warning" sx={{fontSize:'inherit'}}/>
                                                                    </TableCellLegend>
                                                                    <TableCellLegend text={true}>
                                                                        { t('components.product.includedBirthTimeNotKnown') }
                                                                    </TableCellLegend>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCellLegend>
                                                                        <CrossIcon color="error" sx={{fontSize:'inherit'}}/>
                                                                    </TableCellLegend>
                                                                    <TableCellLegend text={true}>
                                                                        { t('components.product.alwaysExcluded') }
                                                                    </TableCellLegend>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Box>
                                            </Box>
                                        }  
                                    </Box>
                                }
                            </RootContainer>
                        </ItemPaper>
                    </TabPanel>

                    {
                        requiresUserInput &&
                        <TabPanel value={value} index={2} sx={{height:'unset'}}>
                            <ItemPaper sx={{bgcolor: theme => alpha(theme.palette.warning.main,0.10)}}>
                                <Title gutterBottom variant="h4">
                                    User Inputs
                                </Title>
                                <Typography gutterBottom>
                                    This product requires some user-provided data.
                                </Typography>
                                <Typography gutterBottom>
                                    After checkout, you will be required to supply the necessary information in order for the order to be delivered.
                                </Typography>

                                <Box sx={{my:2}}>
                                    <FormAlert severity="success" >
                                        <strong>NOTE:</strong> Once payment is complete, a dedicated order-page will be created where you can input the 
                                        necessary data and monitor the progress of your purchase through to completion.
                                    </FormAlert>
                                </Box>
                                
                                {
                                    false &&
                                    <Box>
                                        <JSONViewer src={productUserFields} />
                                    </Box>
                                }
                                {
                                    false &&
                                    <Box>
                                        <JSONViewer src={userFields} />
                                    </Box>
                                }
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{minWidth: 25}}/>
                                                <TableCell sx={{width:'100%', fontWeight:600}} colSpan={2} >
                                                    Description of Required Inputs
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                Object.entries(userFields || {}).map(([key,item],ix) => {
                                                    const lastRow   = ix >= Object.keys(userFields || {}).length - 1;
                                                    const label     = productUserFields[key]?.fields?.title || item.label || item.type;
                                                    return (
                                                        <TableRow key={ix}>
                                                            <TableCell lastRow={lastRow} sx={{verticalAlign:'top',fontWeight:400}} align="right">
                                                                {ix+1}
                                                            </TableCell>
                                                            <TableCell lastRow={lastRow}>
                                                                <Typography component="div" sx={{fontWeight:400,fontSize:'inherit'}}>
                                                                    {label}
                                                                </Typography>
                                                                <Box mr={2}>
                                                                    {
                                                                        isEmpty(productUserFields[key]?.fields?.description)
                                                                            ?   <Typography component="div" color="text.secondary" sx={{fontSize:'inherit'}}>
                                                                                    { (item.description || '').trim().replace(/\.$/, "") + ". " }
                                                                                    { item.default && <>Default is: <strong>'{titleCase(item.default)}'</strong></>}
                                                                                </Typography>
                                                                            :   <ContentfulRichText 
                                                                                    component   = {TableCellBox}
                                                                                    content     = {productUserFields[key]?.fields?.description} 
                                                                                    sx          = {{'& > *' : {color : theme => theme.palette.text.secondary}}}
                                                                                />
                                                                    }
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <UserInputOptions item={item} available={isAvailable} label={label}/>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </ItemPaper>
                        </TabPanel>
                    }

                    {  
                        (hasComputed || hasAttachments) && 
                        <TabPanel value={value} index={3} sx={{height:'unset'}}>
                            <ItemPaper>
                                <Title variant="h4" gutterBottom>
                                    Digital Content
                                </Title>
                                <Typography gutterBottom>
                                    This product includes the following digital content, which will be available upon delivery completion:
                                </Typography>

                                {
                                    hasComputed &&
                                    <Box>
                                        <Typography sx={{fontWeight:400}}>
                                            Primary Content:
                                        </Typography>
                                        <Typography>
                                            Content provided as part of each delivery
                                        </Typography>
                                        <Box sx={{m:2}}>
                                            <Grid container spacing={1}>
                                                {
                                                    (processor?.attachmentsComputed || []).map(({type, name, mime}, ixB) => (
                                                    <Grid item xs={6} sm={6} md={4} lg={3} key={ixB}>
                                                        <DigitalFile type={type} name={name} mime={mime} />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Box>
                                    </Box>
                                }

                                {
                                    hasAttachments &&
                                    Object.entries(attachmentFields).map(([key,value]) => {
                                        const productInput = product.productInput[key];
                                        return (
                                            <Box key={key}>
                                                <Typography sx={{fontWeight:400}}>
                                                    {hasComputed ? "Secondary Content, " : undefined}{value.label}:
                                                </Typography>
                                                <Typography>
                                                    {value.description}
                                                </Typography>
                                                <Box sx={{m:2}}>
                                                    <Grid container spacing={1}>
                                                        {
                                                            (productInput || []).map(({type, name, mime}, ix) => (
                                                            <Grid item xs={6} sm={6} md={4} lg={3} key={ix}>
                                                                <DigitalFile type={type} name={name} mime={mime} />
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                </Box>
                                            </Box>
                                        )
                                    })
                                }
                            </ItemPaper>
                        </TabPanel>
                    }

                    {
                        product.isVirtual && product?.canExample &&
                        <TabPanel value={value} index={4} sx={{height:'unset'}}>
                            <Examples 
                                component   = {ItemPaper}
                                hideIfNone  = {!true}
                                quantity    = {3}
                                productId   = {product?._id}
                                renderTitle = {({refresh, working}) => {
                                    return (
                                        <Box display="flex">
                                            <Box flexGrow={1}>
                                                <Title gutterBottom variant="h4">
                                                    {t('common.examples')}
                                                </Title>
                                            </Box>
                                            <Box>
                                                <Button 
                                                    startIcon   = {working ? <RefreshIcon loading={true}/> : null}
                                                    color       = {theme.palette.mode === "light" ? "primary" : "secondary"} 
                                                    disabled    = {working} 
                                                    onClick     = {refresh}
                                                >
                                                    {t('common.refresh')}
                                                </Button>
                                            </Box>
                                        </Box>
                                    )
                                }}
                            />
                        </TabPanel>
                    }

                    {
                        product?.id && product?.canReview &&
                        <TabPanel value={value} index={5} sx={{height:'unset'}}>
                            <ProductReviews 
                                component   = {ItemPaper}   
                                hideIfNone  = {!true}
                                quantity    = {25}
                                productId   = {product?.id} 
                                renderTitle = {({refresh, working}) => {
                                    return (
                                        <Box display="flex">
                                            <Box flexGrow={1}>
                                                <Title gutterBottom variant="h4">
                                                    {t('common.reviews')}
                                                </Title>
                                            </Box>
                                            <Box>
                                                <Button 
                                                    startIcon   = {working ? <RefreshIcon loading={true}/> : null}
                                                    color       = {theme.palette.mode === "light" ? "primary" : "secondary"} 
                                                    disabled    = {working} 
                                                    onClick     = {refresh}
                                                >
                                                    {t('common.refresh')}
                                                </Button>
                                            </Box>
                                        </Box>
                                    )
                                }}
                            />
                        </TabPanel>
                    }

                    {
                        product && (product?.downgradeTo || product?.upgradeTo) &&
                        <TabPanel value={value} index={6} sx={{height:'unset'}} unmountOnExit={false}>
                            <ProductUpgradeDowngrade productId={product?._id} />
                        </TabPanel>
                    }

                    <ItemPaper>
                        <Title variant="h5" gutterBottom>
                            Purchase {name}
                        </Title>
                        <Box sx={{textAlign:'center'}}>
                            {
                                hasItems &&
                                <ViewCartCheckoutButton disabled={disabled} size="large" variant="contained">
                                    {t('common.viewCart')}
                                </ViewCartCheckoutButton>
                            }
                            <AddToCartButton productId={id} disabled={disabled} sx={{ml:2}}>
                                {buttonText}
                            </AddToCartButton>
                        </Box>
                    </ItemPaper>

                    {
                        showCrossSell && otherProductsExists && 
                        <ItemPaper>
                            <ProductCrossSell 
                                title       = {"Other Products You Might Like"} 
                                component   = {Box} 
                                showImage   = {true} 
                                productId   = {product._id} 
                                omitInCart  = {false}
                                TitleProps  = {{variant:"h4"}}
                            />
                        </ItemPaper>
                    }

                    {
                        showComments && 
                        <ItemPaper>
                            <CommentsThreadProvider>
                                <CommentsThread reference = {product?._id} privateThread = {false} />
                            </CommentsThreadProvider>
                        </ItemPaper>
                    }

                    {
                        (!true || showRawData) && 
                        <AdminBox>
                            <Title variant="h5" gutterBottom>
                                {t('common.rawData')}
                            </Title>
                            <JSONViewer src={product} collapsed={true}/> 
                        </AdminBox>
                    }

                    {
                        (!true || showRawData) && isAdmin && processor &&
                        <AdminBox>
                            <Title variant="h5" gutterBottom>
                                {t('common.processor')}
                            </Title>
                            <Typography color="textSecondary" variant="body2" gutterBottom>
                                Processor Class: {processor.className} -- "{processor.description}"
                            </Typography>
                            <JSONViewer src={processor} collapsed={true}/>
                        </AdminBox>
                    }

                    {
                        showRawData && isAdmin &&
                        <AdminBox>
                            <Title variant="h6" gutterBottom>
                                Available Processors
                            </Title>
                            <JSONViewer src={processors} collapsed={true}/>
                        </AdminBox>
                    }  
                </Grid>
            </Box>
        </Box>
    );
};

export default Product;
