
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Address Context
********************************************************************************************
Boilerplate context, consumer, provider and hook

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       18th July 2021

*******************************************************************************************/
import React                    from 'react';
import {isEqual,pick}           from 'lodash';
import moment                   from 'moment';
import {useNetwork}             from './NetworkContext';
import {
    useStateEphemeral,
    useCancelToken,
    useCanQuery
}                               from 'hooks';

// The Product Context 
const AddressContext = React.createContext(undefined);

const BASE_API_URL = '/api/user/address';

// Address Provider
const AddressProvider = ({children}) => {

    const {allowQueryForUser}                         = useCanQuery();
    const {
        axios, 
        socketUsers : socket
    }                                           = useNetwork();
    const {cancelToken,isCancel}                = useCancelToken();
    const [messageSuccess,  setMessageSuccess]  = useStateEphemeral(undefined);
    const [messageError,    setMessageError]    = useStateEphemeral(undefined,5000);
    const [queried,         setQueried]         = React.useState(undefined);
    const [addresses,       setAddresses]       = React.useState([]);
    const [billingAddress,  setBillingAddress]  = React.useState(undefined);
    const [shippingAddress, setShippingAddress] = React.useState(undefined);
    const [loading,         setLoading]         = React.useState(false);

    const clear = () => setAddresses([]);

    const getAddress = React.useCallback( () => new Promise((resolve,reject) => {
        axios.get(BASE_API_URL,{cancelToken})
            .then(({data}) => data)
            .then(resolve)
            .catch(err => {
                if(isCancel(err)) return reject(err);
                reject(err)
            })
    }),[axios, cancelToken, isCancel]);

    const refresh = React.useCallback( (reset) => {
        if(allowQueryForUser){
            setLoading(true);
            if(reset) clear();
            getAddress()
                .then(data => {
                    setMessageSuccess("Addresses retrieved");
                    return data;
                })
                .then(setAddresses)
                .catch(err => {
                    setMessageError("Problem retrieving addresses");
                    clear();
                })
                .finally(() => {
                    setLoading(false);
                    setQueried(moment());
                })
        }else{
            clear();
        }
    },[getAddress, allowQueryForUser, setMessageError, setMessageSuccess])

    const updateAddress = React.useCallback(({_id, ...fields}) => new Promise((resolve,reject) => {
        axios.post(`${BASE_API_URL}/${_id}`, fields, {cancelToken})
            .then(resolve)
            .catch((err)=>{
                if(isCancel(err)) return reject(err);
                reject(err)
            })
    }),[axios, cancelToken, isCancel])

    const deleteAddress = React.useCallback(_id => new Promise((resolve,reject) => {
        axios.delete(`${BASE_API_URL}/${_id}`, {cancelToken})
            .then(resolve)
            .catch( err => {
                if(isCancel(err)) return reject(err);
                reject(err);
            })
    }),[axios, cancelToken, isCancel])

    const createAddress = React.useCallback(({...fields}) => new Promise((resolve,reject) => {
        axios.post(BASE_API_URL, fields, {cancelToken})
            .then(resolve)
            .catch( err => {
                if(isCancel(err)) return reject(err);
                reject(err);
            })
    }),[axios, cancelToken, isCancel])

    // Update the shipping and billing addresses when addresses array changes
    React.useEffect(()=>{
        setBillingAddress(  addresses.find(a => a?.kind === 'BILLING'   ));
        setShippingAddress( addresses.find(a => a?.kind === 'SHIPPING'  ));
    },[addresses])

    // Refresh
    React.useEffect(() => {
        if(allowQueryForUser)
            refresh();
    }, [allowQueryForUser, refresh])

    // Refresh on SocketIO Instruction
    React.useEffect(() => {
        if(allowQueryForUser && socket){
            socket.on('refresh_addresses', refresh)
            return () => {
                socket.off('refresh_addresses', refresh);
            }
        }
    },[allowQueryForUser, refresh, socket])

    // Comparator
    const isAddressEqual = React.useCallback( (a1, a2, includeKind=true) => {
        let fields = [
            includeKind ? 'kind' : undefined,
            'firstName', 'middleName', 'lastName', 
            'address1','address2',
            'city',
            'country','countryCode',
            'state', 'stateCode', 
            'postCode', 
            'geolocation'
        ].filter(Boolean)
        return isEqual(
            pick(a1, fields),
            pick(a2, fields)
        );
    },[])

    // Context values
    const value = React.useMemo(() => ({
        messageSuccess,
        messageError,
        shippingAddress,
        billingAddress,
        refresh,
        updateAddress,
        createAddress,
        deleteAddress,
        isEqual : isAddressEqual,
        queried,
        loading
    }),[billingAddress, createAddress, deleteAddress, isAddressEqual, loading, messageError, messageSuccess, queried, refresh, shippingAddress, updateAddress])

    return (
        <AddressContext.Provider value={value}>
            {children}
        </AddressContext.Provider>
    )
}

// Address Consumer
const AddressConsumer = ({children}) => {
    return (
        <AddressContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('AddressConsumer must be used within AddressProvider');
                }
                return children(context)
            }}
        </AddressContext.Consumer>
    )
}

// useAddress Hook
const useAddress = () => {
    const context = React.useContext(AddressContext);
    if(context === undefined)
        throw new Error('useAddress must be used within AddressProvider');
    return context;
}

export {
    AddressProvider,
    AddressConsumer,
    useAddress
}