/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Select Yes/No
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       22nd August 2023

*******************************************************************************************/
import React                            from 'react';
import { MenuItem }                     from '@mui/material';
import { 
    Select,
    showErrorOnChange   as showError,
}                                       from 'mui-rff';
import { useTranslation }               from 'contexts';

export const SelectYesNoField = (props) => {
    const {t} = useTranslation();
    return (
        <Select showError={showError} {...props} inputLabelProps = {{ shrink : true, ...props?.inputLabelProps }}>
            <MenuItem value = {true}>
                { t('common.yes') }
            </MenuItem>
            <MenuItem value = {false}>
                { t('common.no') }
            </MenuItem>
        </Select>
    )
}

export default SelectYesNoField;
