
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
OrderViewer Context
********************************************************************************************
Boilerplate context, consumer, provider and hook

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       20th February 2022

*******************************************************************************************/
import React            from 'react';
import moment           from 'moment';
import { useNetwork }   from './NetworkContext';
import { useOrders }    from './OrdersContext';
import { useUser }      from './UserContext';
import { useCanQuery }  from 'hooks';

// The Product Context 
const OrderViewerContext = React.createContext(undefined);

// OrderViewer Provider
const OrderViewerProvider = ({children}) => {

    const {userId,isAuthenticated}      = useUser();
    const {allowQueryForUser}           = useCanQuery();
    const {
        orders,
        queried                     : queriedOrders, 
        getOrderFromMemoryOrById    : getOrderById,
        getTimeLineById,
    }                                   = useOrders();
    const {
        socketUsers : socket
    }                                   = useNetwork();
    const [data,        setData]        = React.useState(undefined);
    const [orderId,     setOrderId]     = React.useState(undefined);
    const [loading,     setLoading]     = React.useState(true);
    const [queried,     setQueried]     = React.useState(undefined);
    const [timeLine,    setTimeLine]    = React.useState(undefined);

    const reset                         = React.useCallback( () => {
        setQueried(undefined);
        setData(undefined);
    },[]);

    const resetTimeLine                 = React.useCallback( () => {
        setTimeLine(undefined)
    }, []);

    React.useEffect(() => {
        if(queriedOrders && orderId){
            setLoading(true);
            getOrderById(orderId)
                .then(setData)
                .catch(reset)
                .finally(() => {
                    setQueried(moment());
                    setLoading(false);
                })
        }else{
            setLoading(false);
            reset();
        }
    },[orderId, orders, queriedOrders, getOrderById, reset])

    // Reset when orderid changes
    React.useEffect(resetTimeLine, [orderId,resetTimeLine])

    const refreshTimeline = React.useCallback( () => {
        if(!orderId || !allowQueryForUser){
            resetTimeLine();
            return;
        }
        getTimeLineById(orderId)
            .then(setTimeLine)
            .catch(resetTimeLine)
    },[allowQueryForUser, getTimeLineById, orderId, resetTimeLine]);

    //Update Timeline
    React.useEffect(refreshTimeline, [refreshTimeline]);

    // Is Owner or Not
    const isOwner = React.useMemo(() => Boolean(userId && isAuthenticated && data?.user === userId), [data?.user, isAuthenticated, userId]);

    // Refresh order on socketIo instruction
    React.useEffect(()=>{
        const handleRefreshOrder = (id) => {
            if(id === orderId) 
                refreshTimeline();
        }
        if(socket){
            socket.on('refresh_order', handleRefreshOrder);
            return () => {
                socket.off('refresh_order', handleRefreshOrder);
            }
        }
    },[orderId, refreshTimeline, socket])

    // Context values
    const value = {
        loading,
        queried,
        data,
        orderId,
        setOrderId,
        reset,
        isOwner,
        timeLine
    };

    return (
        <OrderViewerContext.Provider value={value}>
            {children}
        </OrderViewerContext.Provider>
    )
}

// OrderViewer Consumer
const OrderViewerConsumer = ({children}) => {
    return (
        <OrderViewerContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('OrderViewerConsumer must be used within OrderViewerProvider');
                }
                return children(context)
            }}
        </OrderViewerContext.Consumer>
    )
}

// useOrderViewer Hook
const useOrderViewer = () => {
    const context = React.useContext(OrderViewerContext);
    if(context === undefined)
        throw new Error('useOrderViewer must be used within OrderViewerProvider');
    return context;
}

export {
    OrderViewerProvider,
    OrderViewerConsumer,
    useOrderViewer
}