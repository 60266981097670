/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Section
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       1st June 2022

*******************************************************************************************/
import {Grid} from '@mui/material';

export const SectionSimple = ({children, feature, args={xs:12}, ...props}) => (
    <Grid container {...props} spacing={4}>
        <Grid item {...args}>
            {children}
        </Grid>
    </Grid>
)

export const Section = ({children, feature, LeftArgs={md:5, sm:12, xs:12}, RightArgs={md:7,sm:12, xs:12}, ...props}) => (
    <Grid container {...props} spacing={4}>
        <Grid item {...LeftArgs}>
            {children}
        </Grid>
        <Grid item {...RightArgs}>
            {feature}
        </Grid>
    </Grid>
)

export default Section;