
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Shopping Cart Condensed Table
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       1st July 2021

*******************************************************************************************/
import React                                from 'react';
import { 
    styled,
    Tooltip, 
    Table, 
    TableBody, 
    TableCell as TableCellMUI, 
    TableContainer, 
    TableHead, 
    TableRow 
}                                           from '@mui/material';
import {
    useCart,
    useNetwork
}                                           from 'contexts';
import RemoveShoppingCartIcon               from '@mui/icons-material/RemoveShoppingCart';
import {
    Currency,
    Counter,
    Price,
    IconButton
}                                           from 'components';
import { withTranslation }                  from './hoc';

const TableCell = styled(TableCellMUI,{
    shouldForwardProp : prop => !['bold','nowrap','productName','lastRow'].includes(prop)
})(({theme,bold=false,nowrap=false,productName=false,lastRow = false}) => ({
    fontSize : '0.75rem',
    padding : theme.spacing(0.5),
    '&:last-child' : {
        padding : theme.spacing(0.5)
    },
    borderColor : theme.palette.mode === "dark" 
        ? theme.palette.text.disabled 
        : theme.palette.divider,
    ...(bold && {
        fontWeight : 500
    }),
    ...(nowrap && {
        whiteSpace : 'nowrap',
        overflow : 'hidden',
        textOverflow : 'ellipsis'
    }),
    ...(productName && {
        maxWidth : 25
    }),
    ...(lastRow && {
        borderBottom : 'none'
    })
}));

export const CartCondensedTable =  withTranslation( ({t, readOnly = false, showTax = false, ...props }) => {
    
    const {isNetworkReady}                  = useNetwork();
    const {
        cart : items,
        working,
        quantity,
        clearCart,
        cartValue,
        cartTaxes,
        cartTotal,
        removeCoupon,
        removeProductFromCart,
        disableWidgets,
        adjustments,
        hasAdjustments
    }                                       = useCart();
    const [apr,,]                           = React.useMemo(() => (
        ['price','taxes','total']
            .map(x => (adjustments || [])
            .reduce((a,c) => a + (c[x] || 0),0))
    ), [adjustments]);
    const formattingFn                      = React.useCallback(value => `${value}x`, []);
    
    return (
        <TableContainer component='div'>
            <Table size="small">
                <TableHead>
                <TableRow>
                    <TableCell bold={true}>
                        {t('common.name')}
                    </TableCell>
                    <TableCell bold={true} align="right">
                        {t('common.qty')}
                    </TableCell>
                    <TableCell bold={true} align="right" />
                    <TableCell bold={true} align="right">
                        {t('common.price')}
                    </TableCell>
                    <TableCell bold={true} align="right">    
                        {t('common.total')}
                    </TableCell>
                    {
                        !readOnly && !disableWidgets &&
                        <TableCell bold={true} style={{maxWidth:5}}>    </TableCell>
                    }
                </TableRow>
                </TableHead>
                <TableBody>

                {
                    items
                        .filter(x => x.total !== 0)
                        .map((item,ix) => {

                            const handleRemoveProduct   = (e) => {
                                e.stopPropagation();
                                removeProductFromCart({productId : item?.product?._id});
                            }
                            const handleRemoveCoupon    = (e) => {
                                e.stopPropagation();
                                removeCoupon();
                            }
                            return (
                                <TableRow key={ix}>

                                    <TableCell productName={true} nowrap={true} component="th" scope="row" style={{minWidth:100,width:'100%'}}> 
                                        {item?.product?.name || item?.description} 
                                    </TableCell>

                                    <TableCell align="right">
                                        <Counter value={item?.quantity || 0} formattingFn={formattingFn} decimals={0} />
                                    </TableCell>

                                    <TableCell align="right">
                                        {
                                            item?.product?.listPrice !== item?.product?.price && 
                                            <Price sale={true} price={item?.product?.listPrice} unit={null} />
                                        }
                                    </TableCell>
                                    
                                    <TableCell align="right">
                                        <Currency value={item?.price || 0}/>
                                    </TableCell>

                                    <TableCell align="right">
                                        <Currency value={item?.subtotal || 0} />
                                    </TableCell>
                                    
                                    {
                                        !readOnly && !disableWidgets &&
                                        <TableCell align="right">
                                            {
                                                item.__t === 'PRODUCT' && 
                                                <Tooltip title={ t('components.cartCondensedTable.removeProduct') }>
                                                    <span>
                                                        <IconButton disabled={working || !isNetworkReady} onClick={handleRemoveProduct} size="small">
                                                            <RemoveShoppingCartIcon color={isNetworkReady ? "secondary" : "default"} style={{fontSize:'0.75rem'}}/>
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                            }
                                            {
                                                item.__t === 'COUPON' && 
                                                <Tooltip title={ t('components.cartCondensedTable.removeCoupon') }>
                                                    <span>
                                                        <IconButton disabled={working || !isNetworkReady} onClick={handleRemoveCoupon} size="small">
                                                            <RemoveShoppingCartIcon color={isNetworkReady ? "secondary" : "default"} style={{fontSize:'0.75rem'}}/>
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                            }
                                        </TableCell>
                                    }
                                </TableRow>
                            )
                        })
                }

                {
                    hasAdjustments && Boolean(apr) &&
                    <TableRow>
                        <TableCell productName={true} nowrap={true} component="th" scope="row" style={{minWidth:100}}> 
                            { t('components.cartCondensedTable.adjustmentsCoupon') }
                        </TableCell>
                        <TableCell align="right"/>
                        <TableCell align="right"/>
                        <TableCell align="right"/>
                        <TableCell align="right">
                            <Currency value={apr} />
                        </TableCell>
                        <TableCell align="right"/>
                    </TableRow>
                }

                <TableRow key={'subtotal'}>
                    <TableCell bold={true} lastRow={!showTax} component="th" scope="row"> 
                        {t('common.subtotal')}
                    </TableCell>
                    <TableCell bold={true} lastRow={!showTax} align="right"> 
                        {
                            quantity > 1 && 
                            <Counter value={quantity} formattingFn={formattingFn} decimals={0} />
                        }
                    </TableCell>
                    <TableCell bold={true} align="right"/>
                    <TableCell bold={true} lastRow={!showTax} align="right" style={{whiteSpace:'nowrap'}}> 
                        {
                            quantity > 1 && 
                            <Currency 
                                prefix      = { <span style={{fontSize:'0.75em'}}>{t('common.avg')}</span> } 
                                value       = {cartValue/quantity}
                            />
                        }
                    </TableCell>
                    <TableCell bold={true} lastRow={!showTax} align="right"> 
                        <Currency value={cartValue} />
                    </TableCell>
                    {
                        !readOnly && !disableWidgets &&
                        <TableCell bold={true} align="right" lastRow={!showTax}>
                            <Tooltip title={`Empty cart`}>    
                                <span>
                                    <IconButton disabled={working || !isNetworkReady} onClick={clearCart} size="small">
                                        <RemoveShoppingCartIcon color={isNetworkReady ? "secondary" : "default"} style={{fontSize:'0.75rem'}}/>
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </TableCell>
                    }
                </TableRow>

                {
                    showTax && 
                    <React.Fragment>
                        <TableRow key={'tax'}>
                            <TableCell component="th" scope="row"> Taxes </TableCell>
                            <TableCell align="right"/>
                            <TableCell align="right"/>
                            <TableCell align="right"/>
                            <TableCell align="right">
                                <Currency value={cartTaxes} />
                            </TableCell>
                            {
                                !readOnly && !disableWidgets &&
                                <TableCell/>
                            }
                        </TableRow>

                        <TableRow key={'total'}>
                            <TableCell bold={true} lastRow={showTax} component="th" scope="row"> 
                                {t('common.total')}
                            </TableCell>
                            <TableCell bold={true} lastRow={showTax}/>
                            <TableCell bold={true} lastRow={showTax}/>
                            <TableCell bold={true} lastRow={showTax}/>
                            <TableCell bold={true} lastRow={showTax} align="right">
                                <Currency value={cartTotal} />
                            </TableCell>
                            {
                                !readOnly && !disableWidgets &&
                                <TableCell bold={true} lastRow={showTax} style={{maxWidth:10}}/>
                            }
                        </TableRow>
                    </React.Fragment>
                }
                </TableBody>
            </Table>
        </TableContainer>
    )
});

export default CartCondensedTable;