/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Scroll to Top
********************************************************************************************

Scrolls page to top when page path changes

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       6th November 2023

*******************************************************************************************/
import React                            from 'react';
import { useLocation }                  from 'react-router-dom';

export const ScrollToTop = ({top = 0, behavior = "smooth"} = {}) => {

    // Location Hook
    const {pathname} = useLocation();

    // Handle Scroll
    const handleScrollToTop = React.useCallback(() => {
        window.scrollTo({
            top       : top, 
            behavior  : behavior, 
            prepend   : false
          });
    },[top, behavior])
  
    // Scroll to top on pathname change
    React.useEffect(handleScrollToTop,[handleScrollToTop, pathname])
  
    // Nothing
    return null;
}

export default ScrollToTop;
  