/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Tag Chip
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       26th July 2021

*******************************************************************************************/
import React    from 'react';
import {Chip}   from '@mui/material';

export const TagChip = ({
    label,
    ...props
}) => {
    return (
        <Chip 
            // variant     = "outlined" 
            size        = "small" 
            label       = {label} 
            {...props} 
            style       = {{
                marginRight     : 5, 
                marginBottom    : 5,
                ...props.style
            }}
        />
    )
}

export default TagChip;