/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Linkify Component
********************************************************************************************
Composed version of Linkify Component, Allowing for disabled / sanitized links, and 
control over link target

Link Target Issue Solution:
https://github.com/tasti/react-linkify/issues/96#issuecomment-637768168

Prevent 'window.opener' exploit
https://www.npmjs.com/package/react-secure-link
https://github.com/tasti/react-linkify/issues/96#issuecomment-751308395

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       4th November 2022

*******************************************************************************************/
import React                            from 'react';
import { SecureLink }                   from "react-secure-link"
import LinkifyOrig                      from 'react-linkify';

export const Linkify   = ({
    disabled        = false, 
    sanitize        = false, 
    sanitizeText    = "<LINK REMOVED>", 
    target          = "_blank", 
    ...props
}) => {
    return (
        <LinkifyOrig 
            componentDecorator = {(decoratedHref, decoratedText, key) => {
                if(sanitize) return sanitizeText;
                return (
                    <React.Fragment key={key}>
                        {
                            !Boolean(disabled) &&
                            <SecureLink target={target} href={decoratedHref} key={`secure-${key}`}>
                                {decoratedText}
                            </SecureLink>
                        }
                        {
                            Boolean(disabled) &&
                            <React.Fragment key={`decorated-${key}`}>
                                {decoratedText}
                            </React.Fragment>
                        }
                    </React.Fragment>
                )
            }}
            {...props}
        />
    )
}

export default Linkify