/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Login Button
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       22nd December 2020

*******************************************************************************************/

import React                            from 'react'
import { useUser, useTranslation}       from 'contexts'; 
import { RefreshIcon, Button}           from 'components';

export const Login = ({children, component : Component = Button, popup = false, force = false, ...props}) => {   
    const [t]                   = useTranslation();
    const {
        updatingServer,
        isAuthenticated, 
        isSuspended, 
        isLoading, 
        loginWithPopup,
        loginWithRedirect
    }                           = useUser();
    const [loading, setLoading] = React.useState(false);
    
    // Reset the Loading State
    const resetLoading          = React.useCallback( () => (
        setLoading(false)
    ), []);

    // Button Click Handler
    const handleClick           = React.useCallback( (e) => {
        if(!popup) setLoading(true);
        popup 
            ? loginWithPopup(e) 
            : loginWithRedirect(e)
    }, [loginWithPopup, loginWithRedirect, popup]);

    // Reset loading when page loads
    React.useEffect(() => {
        window.addEventListener('load', resetLoading);
        return () => {
            window.removeEventListener('load', resetLoading)  
        }
    },[resetLoading])

    // Should Show or Not
    const show          = force || (!isAuthenticated);
    const disabled      = props?.disabled || !!isSuspended || isLoading || loading || updatingServer;
    const refreshing    = loading || updatingServer;

    // if dont show, return nothing.
    if(!show) 
        return null;

    // Render the button
    return (
        <Component 
            {...props} 
            disabled    = {disabled} 
            startIcon   = {
                refreshing 
                    ? <RefreshIcon loading={true} /> 
                    : props?.startIcon
                } 
            onClick     = {handleClick}
        >
            {
                children || t('common.login')
            }
        </Component>
    )
}

export default Login
