/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Public User Name
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       4th June 2024

*******************************************************************************************/
import React from 'react';

export const PublicUserName = ({user = undefined, ...props}) => {
    
    const {
        namePublic  = undefined,
        firstName   = undefined, 
        lastInitial = undefined,
        userProfile : {
            displayName = undefined,
            handle      = undefined
        } = {},
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }                                   = React.useMemo(() => user || {}, [JSON.stringify(user)]);
    
    const visibleName                   = React.useMemo(() => (
        [
            displayName, 
            handle ? `@${handle}` : undefined,
            namePublic,
            firstName && lastInitial ? `${firstName} ${lastInitial}` : undefined,
            "Someone"
        ].filter(Boolean)[0]
    ),[firstName, lastInitial, namePublic, displayName, handle])

    // No User or Visible Name
    if(!user || !visibleName)
        return null;

    // Visible Name
    return (
        <span {...props} >
            { visibleName } 
        </span>
    )
}

export default PublicUserName;