/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Date Range Dialog
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       25th January 2023

*******************************************************************************************/
import React                            from 'react';
import { DraggableDialog }              from 'components';
import { DateRangeForm }                from 'components/forms';
import { withTranslation }              from 'components/hoc';

const noop = () => {};
const obj = {};

export const DateRangeDialog = withTranslation( ({
    t,
    title                       = undefined,
    start                       = undefined,
    finish                      = undefined,
    open                        = false,
    onClose     : handleClose   = noop,
    onCancel    : handleCancel  = noop,
    onSubmit    : handleSubmit  = noop,
    formProps                   = obj,
    ...props
}) => {

    const formData = React.useMemo(() => ({
        start,
        finish
    }), [start,finish]);

    return (   
        <DraggableDialog 
            title       = {title || t('components.modals.dateRangeDialog.editDateRange')}
            showButtons = {false}
            {...props}
            open        = {Boolean(open)} 
            onClose     = {handleClose} 
            draggable   = {false}
        >
            <DateRangeForm 
                {...formProps}
                onCancel = {handleCancel}
                onSubmit = {handleSubmit}
                formData = {formData}
            />
        </DraggableDialog>
    )
})

export default DateRangeDialog;