/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Filter Ticket Form
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       4th March 2022

*******************************************************************************************/
import React                            from 'react';
import { Chip, Grid }                   from '@mui/material';
import {
    Form,
}                                       from 'components';
import { useTranslation }               from 'contexts';
import { 
    Autocomplete,
    showErrorOnChange   as showError,
}                                       from 'mui-rff';

const noop = () => {}

const DATA = {
    assigned : {
        label      : "components.forms.filterTicketForm.assignedLabel",
        helperText : "components.forms.filterTicketForm.assignedHelperText",
        options : [
            {value : true,  translationKey : "components.forms.filterTicketForm.assigned"},
            {value : false, translationKey : "components.forms.filterTicketForm.notAssigned"}
        ]
    },
    read : {
        label       : "components.forms.filterTicketForm.readLabel",
        helperText  : "components.forms.filterTicketForm.readHelperText",
        options : [
            {value : true,  translationKey : "components.forms.filterTicketForm.read"},
            {value : false, translationKey : "components.forms.filterTicketForm.notRead"}
        ]
    },
    open : {
        label       : "components.forms.filterTicketForm.openLabel",
        helperText  : 'components.forms.filterTicketForm.openHelperText',
        options : [
            {value : true,  translationKey : "components.forms.filterTicketForm.open"},
            {value : false, translationKey : "components.forms.filterTicketForm.notOpen"}
        ]
    }
}

const defaultFormData = {
    assigned    : [],
    read        : [],
    open        : [],
};
const obj = {};

export const FilterTicketForm = ({
    formData                = defaultFormData,
    isEditing               = false,
    disabled                = false,
    onSubmit : handleSubmit = noop,
    onCancel : handleCancel = noop,
    FormProps               = obj,
    ...rest
}) => {
    const {t} = useTranslation();

    const validate = React.useCallback( (values) => {
        let errors          = {};

        Object.keys(formData).forEach(key => {
            if(!values[key] || values[key].length === 0)
                errors[key] = errors[key] || t('components.forms.filterTicketForm.required')
        })

        return errors;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(formData), t]);

    const initialValues = React.useMemo(() => ({
        ...formData
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [JSON.stringify(formData)]);

    return (
        <Form
            debug           = {false}
            disabled        = {disabled}
            onSubmit        = {handleSubmit}
            onCancel        = {handleCancel}
            initialValues   = {initialValues}
            validate        = {validate}
            {...FormProps}
            render          = {({disabled, form, error, dirtySinceLastSubmit, submitFailed, submitSucceeded, errors, handleSubmit, values, ...rest}) => {
                return (
                    <form onSubmit={handleSubmit} noValidate>
                        <Grid container>
                            {Object.keys(initialValues).map((key,ix) => {
                                let data = DATA[key];
                                return (
                                    <Grid key={ix} item xs={12}>
                                        <Autocomplete
                                            disabled                = {disabled} 
                                            name                    = {key}
                                            label                   = {t(data.label)}
                                            options                 = {data.options.map(x => x.value)}
                                            helperText              = {t(data.helperText)}
                                            value                   = {values[key]}
                                            getOptionValue          = {option => option }
                                            getOptionLabel          = {option => {
                                                const opt = data.options.find(x => x.value === option);
                                                return opt?.translationKey ? t(opt.translationKey) : opt.name;
                                            }}
                                            isOptionEqualToValue    = {(option,value) => option === value }
                                            disableCloseOnSelect    = {false}
                                            textFieldProps          = {{
                                                InputLabelProps : {shrink :true}
                                            }}
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => {
                                                    const opt   = data.options.find(x => x.value === option);
                                                    const label = opt.translationKey ? t(opt.translationKey) : opt.name;
                                                    return (
                                                        <Chip
                                                            {...getTagProps({ index })}
                                                            color   = "primary"
                                                            size    = "small"
                                                            label   = { label } 
                                                        />
                                                    )
                                                })
                                            }
                                            showError               = {showError}
                                            autoHighlight
                                            autoSelect = {false}
                                            autoComplete
                                            multiple
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </form>
                );
            }}
        />
    );
}

export default FilterTicketForm;