/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Hero
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       1st June 2022

*******************************************************************************************/
import React                    from 'react';
import { 
    DialogActions, 
    Hidden
}                               from '@mui/material';
import ShopIcon                 from '@mui/icons-material/Shop';
import InfoIcon                 from '@mui/icons-material/Info';
import { 
    useProduct, useAlert 
}                               from 'contexts';
import { useHistory }           from 'react-router-dom';
import {  
    Button
}                               from 'components';
import { ShopLocation }         from 'router/locations';
import constants                from '../constants';

export const HeroButtons = () => {
    const history                   = useHistory();
    const {hasProducts}             = useProduct();
    const {alert}                   = useAlert();

    // Jump to Anchor
    const handleNavigateToAnchor = React.useCallback( (id) => {
        const el = document.getElementById(id);
        if(el) el.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'start'});
        else(alert(`Element '${id || 'id'}' not found`,'error'));
    },[alert])

    // Jump to Order
    const handleNavigateToOrder = React.useCallback( () => {
        hasProducts ? handleNavigateToAnchor(constants.ids.crossSell) : history.push(ShopLocation.path)
    }, [handleNavigateToAnchor, hasProducts, history]);

    // Jump to Info
    const handleNavigateToInfo = React.useCallback(() => {
        handleNavigateToAnchor(constants.ids.about)
    }, [handleNavigateToAnchor])

    const args = React.useMemo(() => ({
        variant : "contained", color:"primary", size:"large" 
    }), []);

    return (
        <DialogActions>
            <Button startIcon={<InfoIcon/>} {...args} onClick={handleNavigateToInfo}>
                <Hidden mdDown> Find Out More </Hidden>
                <Hidden mdUp>   Info </Hidden>
            </Button>
            {
                hasProducts &&
                <Button startIcon={<ShopIcon/>} {...args} onClick={handleNavigateToOrder}>
                    <Hidden mdDown> Order Now </Hidden>
                    <Hidden mdUp>   Order </Hidden>
                </Button>
            }
        </DialogActions>
    );
};

export default HeroButtons;