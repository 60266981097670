/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Product Component
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       24th July 2021

*******************************************************************************************/

import React                    from 'react';
import TextTruncate             from 'react-text-truncate';
import {
    lighten,
    useTheme,
    Box,
    Grid,
    Typography,
    CardMedia
}                               from '@mui/material';
import ArrowForwardIcon         from '@mui/icons-material/ArrowForward';
import ArrowBackIcon            from '@mui/icons-material/ArrowBack';
import {
    Title,
    ItemPaper,
    FeatureBox
}                               from 'components'
import { useProduct }           from 'contexts';
import { useImageCDN }          from 'hooks';
import { useHistory }           from 'react-router-dom';

const ProductButton = ({productId, upgrade = true, lines=3}) => {
    const history               = useHistory();
    const theme                 = useTheme();
    const { 
        data        : products,
        contentful  : contentfulCollection,
        contentfulLoading,
        DEFAULT_PRODUCT_IMAGE,
        GRAYSCALE_WHEN_UNAVAILABLE
    }                           = useProduct();
    const convert               = useImageCDN();
    
    const product               = React.useMemo(() => (
        products.find(item => item._id === productId)
    ), [productId, products]);

    const isAvailable           = React.useMemo(() => (
        product?.available && product?.listed && !product?.deleted
    ),[product?.available, product?.deleted, product?.listed]);

    const data = React.useMemo(() => (
        product?.slug 
            ? contentfulCollection.find(x => x?.fields?.slug === product?.slug) 
            : undefined
    ),[contentfulCollection, product?.slug])

    const bgColor = React.useMemo(() => (
        upgrade ? theme.palette.success.light : theme.palette.error.light
    ),[theme.palette.error.light, theme.palette.success.light, upgrade])

    const fgColor = React.useMemo(() => "#000000", []) ; //theme.palette.getContrastText(bgColor)

    return (
        <Box sx = {{ height : '100%', pt:1 }}>
            <FeatureBox 
                onClick = {() => history.push(product?.slug)} 
                sx      = {{
                    height          : '100%',
                    mr              : upgrade ? 0 : 0.5,
                    ml              : upgrade ? 0.5 : 0,
                    width           : 'fit-content',
                    maxWidth        : 350,
                    minHeight       : 50,
                    borderRadius    : 2,
                    padding         : 0,
                    bgcolor         : theme => lighten(bgColor, 0.75),
                    ':hover'        : {
                        cursor  : 'pointer',
                        bgcolor         : theme => lighten(bgColor, 0.50),
                    }
                }}
            >
                <Grid container spacing={0} align={upgrade ? 'right' : 'left'} flexDirection={upgrade ? 'row-reverse' : 'row'}>
                    <Grid item xs={3} sx={{p:1,display:'flex'}}>
                        <Box sx = {{ my:'auto'}}>
                            <CardMedia 
                                component   = "img"
                                src         = { 
                                    convert(
                                        product?.cover || DEFAULT_PRODUCT_IMAGE, 
                                        { 
                                            operation   : 'width', 
                                            width       : 100,
                                            options     : `grayscale:${isAvailable || !GRAYSCALE_WHEN_UNAVAILABLE ? false : true}`
                                        })
                                }
                                sx          = {{ width : '100%'}}
                            />
                        </Box>
                    </Grid >
                    <Grid item xs={9} display="flex" flexDirection={upgrade ? 'row-reverse' : 'row'} sx={{my:'auto'}}>
                        {
                            false &&
                            <Box sx={{m : 0, transform: theme => `translate(${theme.spacing(upgrade ? 0.5 : -0.5)},${theme.spacing(-0.5)})`, color : lighten(fgColor,0.25)}}>
                                {
                                    !upgrade 
                                        ? <ArrowBackIcon    sx={{color:'inherit'}} /> 
                                        : <ArrowForwardIcon sx={{color:'inherit'}} /> 
                                } 
                            </Box>
                        }
                        <Box sx={{textAlign : upgrade ? 'right' : 'left', py:1, pr : upgrade ? 0 : 1, pl : upgrade ? 1 : 0, }}>
                            <Typography color={lighten(fgColor,0.25)} sx={{fontSize : '0.7rem', mb : -0.5}}>
                                {upgrade ? "Upgrade" : "Downgrade"} to:
                            </Typography>
                            <Typography color={fgColor} sx={{fontSize:'1rem',fontWeight:400}}>
                                {product?.name}
                            </Typography>
                            <Typography component="div" color={lighten(fgColor,0.25)} sx={{fontSize:'0.6rem'}}>
                                {
                                    (contentfulLoading) && 
                                    "Loading ..."
                                }
                                {
                                    !contentfulLoading &&
                                    <TextTruncate
                                        text    = {data?.fields?.abstract || product?.abstract}
                                        line    = {lines}
                                    />
                                }
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </FeatureBox>
        </Box>
    )
}

export const ProductUpgradeDowngrade = ({productId = undefined}) => {
    const { data:products }     = useProduct();
    const product               = React.useMemo(() => products.find(item => item._id === productId), [productId, products]);
    if(!product) 
        return null
    if((!product?.downgradeTo && !product?.upgradeTo)) 
        return null
    return (
        <ItemPaper>
            <Box flexGrow={1}>
                <Title variant="h4">
                    Related Products
                </Title>
                <Typography gutterBottom>
                    Upgrade or downgrade to the following alternatives
                </Typography>
            </Box>
            <Grid container spacing={0} sx={{flexDirection : {xs:'column', md:'row-reverse'}}}>
                {
                    [product?.upgradeTo, product?.downgradeTo].map((value,ix) => (
                        <Grid key={ix} item xs={12} md={6} align={ix === 0 ? "right" : "left"}>
                            {
                                value &&
                                <ProductButton
                                    productId       = {value} 
                                    upgrade         = {ix === 0}
                                    lines           = {2}
                                />
                            }
                        </Grid>
                    ))   
                }
            </Grid>
        </ItemPaper>
    )
}

export default ProductUpgradeDowngrade;
