/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Sabian Symbols Sampler
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       17th December 2023

*******************************************************************************************/
import React                    from 'react';
import startCase                from 'lodash/startCase';
import {
    alpha,
    useTheme,
    Box,
    Grid,
    CardMedia,
    Typography,
}                               from '@mui/material';
import { 
    Title,
    DebouncedButton,
    RefreshIcon,
    Thumbnail,
    ImageLinkWithOverlay,
}                               from 'components';
import glyphs                   from 'components/glyphs'
import {
    useNetwork,
    useUser,
}                               from 'contexts';
import { withTranslation }      from './hoc';
import { 
    watermarkOption,
    useImageCDN
}                               from 'hooks';

const SYMBOLS_TYPE      = 'sabian'

const Glyph = ({src,alt,...rest}) => (
    <CardMedia 
        component   = {'img'} 
        src         = {src} 
        alt         = {alt} 
        {...rest}
        sx          = {{
            mx      : 0.5, 
            height  : 10,
            width   : 'initial', 
            display : 'inline',
            ...rest.sx            
        }} 
    />
)

export const SingleSymbolPhrase = ({
    data : {
        phrase, 
        degrees, 
        degreesFormatted30, 
        key, 
        keyLabel, 
        sign, 
        signLabel
    }
}) => {
    const theme         = useTheme();
    const oppositeMode  = React.useMemo(() => theme.palette.mode === 'dark' ? 'light' : 'dark', [theme.palette.mode]);
    const keyGlyph      = React.useMemo(() => glyphs[oppositeMode][key], [key, oppositeMode]);
    const sgnGlyph      = React.useMemo(() => glyphs[oppositeMode][sign], [sign, oppositeMode]);
    return (
        <Typography variant="body2">
            <strong>{startCase(SYMBOLS_TYPE)} Symbol #{degrees + 1}</strong> - {degreesFormatted30} {" "}
            {keyLabel}  <span><Glyph src = {keyGlyph} alt = {keyLabel} /></span> in {" "} 
            {signLabel} <span><Glyph src = {sgnGlyph} alt = {signLabel} /></span>
            - <span style={{fontStyle:'italic'}}>{phrase}</span>
        </Typography>
    )
}

export const SingleSymbol =  withTranslation( ({t, 
    symbolStyle = undefined, 
    data : {
        image : {
            _id, 
            file
        } = {}, 
        phrase, 
        degrees, 
        degreesFormatted30, 
        key, 
        keyLabel, 
        sign, 
        signLabel
    } = {},
    watermarked = true
} = {}) => {
    
    const theme         = useTheme();
    const {isAdmin}     = useUser();
    const convert       = useImageCDN();

    const mode          = React.useMemo(() => theme.palette.mode, [theme.palette.mode]);
    const oppositeMode  = React.useMemo(() => mode === 'dark' ? 'light' : 'dark', [mode]);
    const keyGlyph      = React.useMemo(() => glyphs[oppositeMode][key], [key, oppositeMode]);
    const sgnGlyph      = React.useMemo(() => glyphs[oppositeMode][sign], [sign, oppositeMode]);

    const convertImage  = React.useCallback( (width) => (
        convert( file, {  operation : 'width', width, options : watermarked ? watermarkOption(width/2, mode === 'dark') : ''})
    ), [convert, file, mode, watermarked]);

    return (

        <Box sx={{position:'relative'}}>
            <Thumbnail 
                title                   = {t('components.example.symbolViewer')}
                thumbnail               = {convertImage(250)}
                src                     = {convertImage(500)}
                download                = { isAdmin ? convert(file, { operation:'cdn' } ) : null }
                dialogProps             = {{ fullScreen : true, maxWidth : "sm" }}
                alt                     = {`${degrees}°, ${keyLabel} in ${signLabel}, ${[symbolStyle,'artwork'].filter(Boolean).join(" ")}`}
                size                    = {500}
                commentsThreadReference = {_id}
                render                  = {({handleOpen, thumbnail, alt}) => (
                    <ImageLinkWithOverlay
                        aspectRatio         = "1"
                        photo               = {thumbnail}
                        alt                 = {alt}
                        loading             = "lazy"
                        onClick             = {handleOpen}
                        name                = {`${degrees}°, ${keyLabel} in ${signLabel}`}
                        // sx                  = {{cursor:'pointer'}}
                    />
                )}
            >
                <SingleSymbolPhrase data={{phrase, degrees, degreesFormatted30, key, keyLabel, sign, signLabel}} />
            </Thumbnail>
            <Box position="absolute" style={{borderBottomRightRadius:8}} sx={{top:0, left:0, bgcolor:theme => alpha(theme.palette.background.paper,0.5)}}>
                <Glyph src = {keyGlyph} alt = {keyLabel} />
                <Glyph src = {sgnGlyph} alt = {signLabel} />
            </Box>
        </Box>
    )
})

export const SabianSymbolsSampler =  withTranslation(({
    t,
    data : {
        lat,
        lng,
        birthDateTime,
        unknownTime,
        localTime,
        houseSystem,
        zodiac
    } = {},
    watermarked = true
}) => {

    const theme                                 = useTheme();
    const {axios,   isNetworkReady}             = useNetwork();
    
    // Query Sabian Symbols
    const [symbolsWorking,  setSymbolsWorking]  = React.useState(false);
    const [symbolsResult,   setSymbolsResult]   = React.useState(undefined);
    const [symbolsSet,      setSymbolsSet]      = React.useState(undefined);
    const currentSymbolsSet                     = React.useRef(undefined);

    const symbolsArgs  = React.useMemo(() => ({
        lat,
        lng,
        birthDateTime,
        unknownTime,
        localTime,
        houseSystem,
        zodiac
    }), [birthDateTime, houseSystem, lat, lng, localTime, unknownTime, zodiac])

    // Query Server
    const queryStmbols = React.useCallback(() => {
        setSymbolsResult(undefined);
        setSymbolsSet(undefined);
        if(isNetworkReady && axios && symbolsArgs){
            try {
                //METADATA
                const queryString = Object.entries(symbolsArgs).map(([key,value]) => `${key}=${value}`).join('&');
                setSymbolsWorking(true);
                axios.get([
                        `/api/public/symbols?${queryString}`,
                        `symbolsType=${SYMBOLS_TYPE}`,
                        currentSymbolsSet.current 
                            ? `excludeSymbolsSet=${currentSymbolsSet.current}` 
                            : undefined
                    ]
                        .filter(Boolean)
                        .join('&')
                )
                    .then(({data:{symbols,result}}) => {
                        setSymbolsResult(result);
                        setSymbolsSet(symbols);
                        currentSymbolsSet.current = symbols._id;
                    })
                    .catch(err => {
                        setSymbolsResult(undefined);
                    })
                    .finally(() => {
                        setSymbolsWorking(false);
                    })
            } catch (err) {
                setSymbolsResult(undefined);
                setSymbolsSet(undefined);
                currentSymbolsSet.current = undefined;
            }
        }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isNetworkReady, axios, JSON.stringify(symbolsArgs)])

    // When Component Loads
    React.useEffect(queryStmbols,[queryStmbols]);

    return (
        <React.Fragment>
            {
                (symbolsResult || symbolsWorking) &&
                <Box mt={2}>
                    <Box display="flex">
                        <Box flexGrow={1}>
                            <Title variant="h5" component="h3">
                                {t('components.example.symbolsType', {symbolsType : startCase(SYMBOLS_TYPE) })}
                            </Title>
                            {
                                !symbolsWorking && symbolsSet?.style && 
                                <Typography>
                                    {t('components.example.influencedByStyle', {style : symbolsSet?.style})}
                                </Typography>
                            }
                            {
                                symbolsWorking &&
                                <Typography>
                                    {t('common.loading')}
                                </Typography>
                            }
                        </Box>
                        <Box sx={{my:'auto'}}>
                            <DebouncedButton color={theme.palette.mode === 'dark' ? 'secondary' : 'primary'} disabled={symbolsWorking} onClick={queryStmbols} startIcon={symbolsWorking ? <RefreshIcon loading={true}/> : null}>
                                {t('components.example.changeStyle')}
                            </DebouncedButton>
                        </Box>
                    </Box>
                    <Box>
                        <Grid container spacing={0}>
                            {
                                symbolsWorking &&
                                Array(16).fill(undefined).map((_,ix) => (
                                    <Grid key={ix} item xs={6} lg={3}>
                                        <ImageLinkWithOverlay 
                                            overlayEnabled  = {false} 
                                            aspectRatio     = "1" 
                                        />   
                                    </Grid>
                                ))
                            }
                            {
                                !symbolsWorking && 
                                symbolsResult.map((data, ix) => (
                                    <Grid key={ix} item xs={6} lg={3}>
                                        <SingleSymbol 
                                            data        = { data }
                                            watermarked = { watermarked } 
                                            symbolStyle = { symbolsSet?.style } 
                                        />
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Box>
                </Box>
            }
        </React.Fragment>
    )
})

export default SabianSymbolsSampler;