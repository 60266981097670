/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Language Selector
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       3rd July 2022

*******************************************************************************************/

import React                from 'react';
import isFunction           from 'lodash/isFunction';
import { 
    FormControl, 
    InputLabel, 
    Select, 
    MenuItem 
}                           from '@mui/material';
import { useTranslation }   from 'contexts/TranslationContext';
import IconMenu             from './IconMenu';

const noop = () => {};

const convertCodeToName = (base,code) => {
    try {
        return (new Intl.DisplayNames([base], { type: 'language' })).of(code);    
    } catch (err) {
        return code;
    }
}

export const LanguageSelector = ({component : Component = IconMenu, render = noop, ...props}) => {
    
    const {t,i18n}                  = useTranslation();  

    // Detect list of available translations
    // const languages = React.useMemo(() => (i18n?.options?.supportedLngs || []).filter(x => x !== 'cimode'), [i18n?.options?.supportedLngs]);
    const languages     = React.useMemo(() => {
        return (
            Object
                .keys(i18n?.options?.resources)
                .map((lang) => ({
                    language        : lang,
                    languageName    : convertCodeToName(i18n.language,lang)
                }))
        )
    },[i18n.language, i18n?.options?.resources])

    // Is there more than one choice
    const hasChoice = React.useMemo(() => languages.length > 1, [languages.length]);

    const changeLanguage = React.useCallback(lang => {
        i18n.changeLanguage(lang);
    },[i18n])

    // Handle Change Language
    const handleChangeLanguage  = React.useCallback( (event) =>{ 
        changeLanguage(event.target.value);
    }, [changeLanguage]);

    const languageName = React.useMemo(() => convertCodeToName(i18n.language,i18n.language), [i18n.language]);

    // No translations
    if(!hasChoice)
       return null;

    if(isFunction(render) && render !== noop)
        return render({language : i18n.language, languageName, languages, changeLanguage})
    
    // Build the dropdown
    return (
        <FormControl>
            <InputLabel>
                {t('common.language')}
            </InputLabel>
            <Select
                labelId     = "language-select-label"
                id          = "language-select"
                value       = {i18n.language}
                onChange    = {handleChangeLanguage}
            >
            {
                languages.map((lang) => (
                    <MenuItem key = {lang} value = {lang} >
                        {lang}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
};

export default LanguageSelector;