/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Skeleton Comment
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       18th July 2022

*******************************************************************************************/
import React                            from 'react';
import { Box, Paper }                   from '@mui/material';
import { Skeleton }                     from '@mui/material';
import { 
    ContainerVertical,
    ContainerHorizontal
}                                       from './components';

export const SkeletonComment = ({showCircle = true, circleSize = 50, ...props}) => {
    return (
        <Box width="100%">
            <ContainerHorizontal>
                {
                    showCircle && 
                    <Box component={Paper} flexShrink={1} sx={{borderRadius:circleSize/2,mt:0.5,height:'fit-content'}}>
                        <Skeleton variant="circular" width={circleSize} height={circleSize}/>
                    </Box>
                }
                <Box flexGrow={1}>
                    <ContainerHorizontal>
                        <Box component = {Skeleton} width="35%" variant="text"/>
                        <Box component = {Skeleton} width="15%" variant="text"/>
                        <Box flexGrow={1}/>
                        <Box component = {Skeleton} width="15%" variant="text"/>
                        <Box component = {Skeleton} width="10%" variant="text"/>
                    </ContainerHorizontal>
                    <ContainerVertical>
                        <Skeleton variant="text" width="100%"/>
                        <Skeleton variant="text" width="100%"/>
                        <Skeleton variant="text" width="80%"/>
                        <Skeleton variant="text" width="60%"/>
                    </ContainerVertical>
                    <ContainerHorizontal>
                        <Box component = {Skeleton} width="15%" variant="text"/>
                        <Box flexGrow={1}/>
                        <Box component = {Skeleton} width="15%" variant="text"/>
                    </ContainerHorizontal>
                    <ContainerHorizontal>
                        <Box component = {Skeleton} width="10%" variant="text"/>
                        <Box flexGrow={1}/>
                    </ContainerHorizontal>
                </Box>
            </ContainerHorizontal>
        </Box>
    );
}

export default SkeletonComment;