/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Product Form
********************************************************************************************
Edit Existing, or, Create a new Product

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       25th May 2021

*******************************************************************************************/
import React                            from 'react';
import flat                             from 'flat';
import isNil                            from 'lodash/isNil';
import pick                             from 'lodash/pick';
import clone                            from 'lodash/clone';
import isEqual                          from 'lodash/isEqual';
import get                              from 'lodash/get';
import intersection                     from 'lodash/intersection';
import { FileIcon }                     from 'react-file-icon';
import {
    styled,
    useTheme,
    Box,
    Button,
    IconButton,
    Chip,
    Grid,
    InputAdornment,
    MenuItem,
    alpha,
    darken,
    Tooltip,
    Typography,
    LinearProgress,
    TableContainer,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell as TableCellMUI,
    FormGroup,
    InputLabel,
    FormControl,
    FormHelperText,
    TextField as TextFieldMUI
}                                       from '@mui/material';
import BackupIcon                       from '@mui/icons-material/Backup';
import SwapHorizIcon                    from '@mui/icons-material/SwapHoriz';
import DeleteIcon                       from '@mui/icons-material/Delete';
import PersonIcon                       from '@mui/icons-material/Person';
import BuildIcon                        from '@mui/icons-material/Build';
import SystemIcon                       from '@mui/icons-material/SettingsSystemDaydream';
import AddIcon                          from '@mui/icons-material/Add';
import EditIcon                         from '@mui/icons-material/Edit';

import {
    AdminBox,
    Form,
    Title as TitleComponentOriginal,
    FormAlert,
    RemoveWithConfirmationButton,
    NoDataPrompt,
    FeatureBox,
    DragAndDrop,
    JSONViewer,
    RefreshIcon,
    ConfirmationButton,
    CopyField,
    FormFailBox,
}                                       from 'components';
import {
    useLocale,
    useProduct,
    useUser,
    useNetwork,
    useTranslation
}                                       from 'contexts';
import {
    FileUploader
}                                       from 'components/modals';
import { FORM_ERROR }                   from 'hooks';
import { FormSpy }                      from 'react-final-form';
import { 
    NoopFields, 
    SelectYesNoField
}                                       from './fields';
import { 
    TextField,
    Select,
    showErrorOnBlur as showError,
    Autocomplete
}                                       from 'mui-rff';
import { startCase }                    from 'lodash';

const Image = styled('img')({
    minWidth        : '100%',
    minHeight       : '100%',
    transition: 'all .2s ease-in-out',
    transform: 'scale(1.00)',
    "&:hover" : {
        transform   : 'scale(0.99)',
    }
});

const TableCell = styled(TableCellMUI,{
    shouldForwardProp : prop => prop !== 'noborder'
})( ({theme,noborder = false}) => ({
    padding : theme.spacing(0.5),
    ...(noborder && {
        borderBottom : 'none'
    })
}));

const IconButtonsPanel = styled(Box)(({theme}) => ({
    position    : 'absolute', 
    top         : 0, 
    right       : 0, 
    left        : 0,
    background  : alpha(darken(theme.palette.background.paper,0.5),0.75),
    textAlign   : 'right'
}));

const ImageContainer = styled(Box,{
    shouldForwardProp : prop => prop !== 'drag'
})(({theme, drag = false}) => ({
    display         : 'flex',
    justifyContent  : 'center',
    alignItems      : 'center',
    overflow        : 'hidden',
    position        : 'relative',
    width           : '100%',
    height          : '100%',
    ...(drag && {
        border          : `1px dashed ${theme.palette.primary.dark}`,
        borderRadius    : 5,
        overflow        : 'hidden',
        background      : alpha(theme.palette.primary.light,0.5)
    })
}))

const Title = (props) => <TitleComponentOriginal variant="h6" {...props}/>

const obj = {};
const arr = [];

const DATA_TEMPLATE =  {
    _id                     : undefined,
    slug                    : undefined,
    type                    : 'virtual',
    name                    : null,
    abstract                : null,
    description             : null,
    listPrice               : 0,
    discountType            : 'none',
    discountValue           : 0,
    listed                  : false,
    available               : true,
    tags                    : arr,
    categories              : arr,
    cover                   : undefined,
    images                  : arr,
    processor               : undefined,
    productInput            : obj,
    leadTimeSecondsOverride : null,
    upgradeTo               : null, 
    downgradeTo             : null,
    priority                : 0,
    canExample              : false,
    canReview               : false,
}

const noop = () => {};
const nosub = (values) => new Promise(resolve => resolve({[FORM_ERROR]:'Not Implemented'}));

const LinearProgressWithLabel = ({count, countTarget, value, ...props}) => (
    <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
            <LinearProgress variant="determinate" value={value} {...props} />
        </Box>
        <Box minWidth={100} align="right">
            <Typography variant="body2" color="textSecondary">
                {count}/{countTarget} ({`${Math.round(value)}%`})
            </Typography>
        </Box>
    </Box>
);

const FileUploadField = ({disabled=false, value = undefined, multiple = false, onChange : handleChange = noop, onCancel : handleCancel = noop, render}) => {
    
    const {t}                       = useTranslation();
    const [open, setOpen]           = React.useState(!Boolean(value));
    const handleOpen                = React.useCallback( () => setOpen(true), []);
    const handleClose               = React.useCallback( () => setOpen(false), []);
    const handleSubmit              = React.useCallback( (files, allFiles) => {
        handleClose()
        const fileUrls = files.map(f => f?.meta?.fileUrl).filter(Boolean);
        handleChange( 
            multiple 
                ? fileUrls 
                : fileUrls[0] 
        )
    }, [handleChange, handleClose, multiple]);

    const handleCloseAndCancel      = React.useCallback( () => {
        handleClose();
        handleCancel();
    }, [handleCancel, handleClose]);

    return (
        <Box display="flex">
            <Box flexGrow={1}>
                <CopyField value={value} variant="standard" label={null}/>
            </Box>
            <Box sx={{ml:1,my:'auto'}}>
                <IconButton disabled={disabled} size="small" color="primary" onClick={handleOpen}>
                    <EditIcon />
                </IconButton>
            </Box>
            <FileUploader 
                disabled        = {disabled} 
                title           = {t('common.uploadFile')}
                open            = {open} 
                multiple        = {multiple}
                onClose         = {value ? handleClose : handleCloseAndCancel} 
                onSubmit        = {handleSubmit}
                acceptAudio     = {true}
                acceptVideo     = {true}
                acceptImage     = {true}
                acceptCustom    = {['application/pdf']}
            />
        </Box>
    )
}

const ProductAttachmentsField = ({
    disabled        = false, 
    value           = arr, 
    errors          = undefined,
    submitErrors    = undefined,
    onChange        = noop, 
    name            = "attachments", 
    label, 
    description, 
    multi           = false
}) => {

    const {t} = useTranslation();

    const insert = React.useCallback( (arr, index, newItem) => ([
        ...arr.slice(0, index),
        newItem,
        ...arr.slice(index)
    ]),[]);

    const handleInsertRowAfter = React.useCallback((ix) => {
        const arr = insert(value, ix + 1, { name : `File ${value.length + 1}`, url : '' });
        return onChange( arr )
    }, [insert, onChange, value]);

    const handleAddRow = React.useCallback(() => (
        handleInsertRowAfter((value || []).length + 1)
    ), [handleInsertRowAfter, value]);

    const handleRemoveRow = React.useCallback( (ix) => (
        onChange(value.filter((_,i) => i !== ix))
    ),[onChange, value]);

    const error = React.useMemo(() => get(errors,name) || get(submitErrors,name), [errors, name, submitErrors])

    return (
        <Box sx={{pb:2}}>
            <FormFailBox failure = {error} sx={{'& > * + *' : {mt:1}}}>
                <FormGroup>
                    <FormControl margin="normal" fullWidth>
                        <InputLabel htmlFor={name} variant="standard" shrink={true} sx={{bgcolor:'inherit'}}>
                            {label}
                        </InputLabel>
                        <Box
                            id  = {name} 
                            sx  = {{
                                display         : 'flex', 
                                flexDirection   : 'column', 
                                minHeight       : 150, 
                                p               : 1,
                                mt              : 1,
                            }}
                        >
                            {
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell/>
                                                <TableCell/>
                                                <TableCell sx={{minWidth:50}}>
                                                    {t('components.productAttachmentsField.table.name')}
                                                </TableCell>
                                                <TableCell width="100%">
                                                    {t('components.productAttachmentsField.table.source')}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {t('components.productAttachmentsField.table.actions')}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                value.map(({name : fileName, url, type}, ix) => {
                                                    const handleChangeName = (e) => {
                                                        const newValue = e.target.value;
                                                        onChange(value.map((v,i) => i === ix ? ({...v,name : newValue}) : v))
                                                    }
                                                    const handleChangeSource = (newValue) => {
                                                        onChange(value.map((v,i) => i === ix ? ({...v,url : newValue}) : v))
                                                    }
                                                    const getError      = (n) => get(flat(errors || {}),n) || get(flat(submitErrors || {}),n);
                                                    const errorName     = getError(`${name}.${ix}.name`);
                                                    const errorSource   = getError(`${name}.${ix}.url`);
                                                    const hasErrors     = errorName || errorSource;
                                                    return (
                                                        <>
                                                            <TableRow key={ix}>
                                                                <TableCell noborder={hasErrors}>             
                                                                    {ix + 1}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {
                                                                        type && 
                                                                        <div style={{ width: 24}}>
                                                                            <FileIcon extension={type} />
                                                                        </div>
                                                                    }
                                                                </TableCell>
                                                                <TableCell noborder={hasErrors}>
                                                                    <TextFieldMUI disabled={disabled} value={fileName} onChange = {handleChangeName}/>
                                                                </TableCell>
                                                                <TableCell width="100%" noborder={hasErrors} sx={{pr:0}}>
                                                                    <FileUploadField disabled={disabled} value={url} onChange={handleChangeSource} onCancel={() => handleRemoveRow(ix)}/>
                                                                </TableCell>
                                                                <TableCell noborder={hasErrors} sx={{pl:0}}>
                                                                    <Box display="flex">
                                                                        <IconButton disabled={disabled} size="small" color="primary" onClick={() => handleInsertRowAfter(ix)}>
                                                                            <AddIcon/>
                                                                        </IconButton>
                                                                        <IconButton disabled={disabled} size="small" color="error" onClick={() => handleRemoveRow(ix)}>
                                                                            <DeleteIcon/>
                                                                        </IconButton>
                                                                    </Box>
                                                                </TableCell>
                                                            </TableRow>
                                                            {
                                                                hasErrors &&
                                                                <TableRow key={ix + 'errors'}>
                                                                    <TableCell />
                                                                    <TableCell />
                                                                    <TableCell>
                                                                        {   
                                                                            errorName && 
                                                                            <FormHelperText error={errorName}>
                                                                                {errorName}
                                                                            </FormHelperText>
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {   
                                                                            errorSource && 
                                                                            <FormHelperText error={errorSource}>
                                                                                {errorSource}
                                                                            </FormHelperText>
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell />
                                                                </TableRow>
                                                            }
                                                        </>
                                                    )
                                                })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            }

                            <FormHelperText error={Boolean(error)}>
                                {error || description}
                            </FormHelperText>
                            
                            {
                                <Box sx={{display:'flex',flexGrow:1}}>
                                    {
                                        Boolean((!value || !value.length)) && 
                                        <Box sx = {{my:'auto', width:'100%'}} >
                                            <NoDataPrompt disabled={disabled} onClick = {handleAddRow} buttonText="Upload">
                                                {t('components.productAttachmentsField.noFiles')}
                                            </NoDataPrompt>
                                        </Box>
                                    }
                                    {
                                        Boolean(value && value.length) && 
                                        <Box sx = {{my:2, width:'100%',textAlign:'center'}} >
                                            <Button disabled={disabled} size="small" color="secondary" variant="contained" onClick={handleAddRow}>
                                                {t('components.productAttachmentsField.addAnotherFile')}
                                            </Button>
                                        </Box>
                                    }
                                </Box>
                            }
                        </Box>
                    </FormControl>
                </FormGroup>
            </FormFailBox>
        </Box>
    )
};

const INSTANT       = 0;
const MINUTE        = 60;
const HOUR          = MINUTE * 60;
const DAY           = HOUR * 24;
const WEEK          = DAY  * 7;


export const ProductForm = ({
    disabled                    = false,
    formData                    = DATA_TEMPLATE,
    onSubmit                    = nosub,
    onCancel : handleCancel     = noop,
    FormProps                   = obj
}) => {
    const {t}                                           = useTranslation();
    const {socketUsers : socket}                        = useNetwork();
    const theme                                         = useTheme();
    const {
        getProductById, 
        compileProduct, 
        processors, 
        compiling   : compilingIn,
        data        : products
    }                                                   = useProduct();
    const {currency}                                    = useLocale();
    const {isAdmin, isAuthenticated}                    = useUser();

    const [product,             setProduct]             = React.useState({});
    const [compileProgress,     setCompileProgress]     = React.useState(undefined); // Percentage [0,100]
    const [compileDetails,      setCompileDetails]      = React.useState({count:0, countTotal:0});
    const [compileDescription,  setCompileDescription]  = React.useState(undefined);

    const initialValues                                 = React.useMemo(() => ({
        ...pick(formData,Object.keys(DATA_TEMPLATE)),
        listPrice       : formData.listPrice / 100.0,
        discountValue   : formData.discountType === 'fixed' 
            ? formData.discountValue / 100.0 
            : formData.discountValue
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [JSON.stringify(formData)]);

    // Get the product data
    React.useEffect(()=>{
        setProduct(getProductById(formData._id))
    },[formData._id, getProductById, setProduct])

    const handleSubmit = React.useCallback( (values) => {

        const formData              = clone(values); // Prevent Mutating by Reference
        formData.cover              = formData.cover || ''; // stop null
        formData.listPrice          = Math.round((formData.listPrice || 0.0) * 100);
        formData.discountValue      = formData.discountType === 'fixed' 
            ? Math.round((formData.discountValue || 0.0) * 100)
            : formData.discountType === 'percentage' 
                ? formData.discountValue
                : 0
        if(formData.discountValue < 1) // Less than one cent
            formData.discountType = 'none'
        formData.upgradeTo      = formData.upgradeTo    === 'none' ? null : formData.upgradeTo;
        formData.downgradeTo    = formData.downgradeTo  === 'none' ? null : formData.downgradeTo;
        return onSubmit(formData);

    }, [onSubmit]);

    const [openImagesUploader,  setOpenImagesUploader]  = React.useState(false);
    const handleOpenImagesUploader                      = React.useCallback(() => setOpenImagesUploader(true), []);
    const handleCloseImagesUploader                     = React.useCallback(() => setOpenImagesUploader(false), []);
    
    const [openCoverUploader,   setOpenCoverUploader]   = React.useState(false);
    const handleOpenCoverUploader                       = React.useCallback(() => setOpenCoverUploader(true), []);
    const handleCloseCoverUploader                      = React.useCallback(() => setOpenCoverUploader(false), []);



    const [coverHover,          setCoverHover]          = React.useState(false);
    const [imageHover,          setImageHover]          = React.useState(undefined);

    // Helper function to clear compile progress
    const clearCompileProgress = () => setCompileProgress(undefined);

    // Handle Product Compilation
    const handleCompileProduct = React.useCallback( () => {
        if(product){
            let id                  = product._id,
                requiresCompilation = product.requiresCompilation;
            if(id && requiresCompilation) 
                compileProduct(id);
        }
    },[compileProduct, product]);

    // Turn off progress bar after period
    React.useEffect(() => {
        if(!isNil(compileProgress)){
            let timeout = setTimeout(clearCompileProgress, compileProgress >= 100 ? 100 : 5000);
            return () => {
                clearTimeout(timeout);
            }
        }
    },[compileProgress])
    
    // Validate Function
    const validate  = React.useCallback( (values = {}) => {

        let errors      = {};
        if(!values)
            return errors;

        // Required
        ['slug','type' ,'name' ,/*'abstract','description',*/ 'listPrice','discountType','listed','available','processor','canReview','canExample'].forEach( key => {
            if(isNil(values[key]))
                errors[key] = errors[key] || t('components.forms.productForm.required');
        });
        let keyDT = "discountType", keyDA = "discountValue";
        let da = values[keyDA];
        if(values[keyDT] && values[keyDT] !== 'none'){
            if(isNil(da)) errors[keyDA] = errors[keyDA] || t('components.forms.productForm.required');
        }

        let abstractMaxLength = 100;
        if(!errors.abstract && (values?.abstract?.length || 0) > abstractMaxLength)
            errors.abstract = `Max length ${abstractMaxLength}`;

        // Price
        if(!isNil(values.listPrice)){
            let listPrice = parseFloat(values.listPrice);
            if(isNaN(listPrice))
                errors.listPrice = errors.listPrice || t('components.forms.productForm.invalid');
            if(!isNaN(listPrice) && listPrice < 0)
                errors.listPrice = errors.listPrice || t('components.forms.productForm.mustBePositiveOrZero');
        }

        // Discount
        if(!errors[keyDA] && values[keyDT] !== 'none'){
            da = parseFloat(da);
            if(isNaN(da)){
                errors[keyDA] = errors[keyDA] || t('components.forms.productForm.invalid');
            }else{
                if(values[keyDT] === 'percentage' && (da < 0 || da >= 100)){
                    errors[keyDA] = errors[keyDA] || t('components.forms.productForm.mustBeInRange', {minimum:0,maximum:100});
                } else
                if(!errors.listPrice && values[keyDT] === 'fixed' && (da < 0 || da >= values.listPrice)){
                    errors[keyDA] = errors[keyDA] || t('components.forms.productForm.mustBeInRange', {minimum:0,maximum:values.listPrice});
                }
            }
        }

        // Check quantity and length for tags and categories
        [ 
            {key:'tags',        maxQuantity:3,  minLength:3}, 
            {key:'categories',  maxQuantity:5,  minLength:3} 

        ].forEach(({key, maxQuantity, minLength}) => {
            const vals      = values[key];
            const hasErrors = Boolean(errors[key]);
            if(!hasErrors && !isNil(vals) && Array.isArray(vals)){
                if(vals.length > maxQuantity)
                    errors[key] = t('components.forms.productForm.maximumItemsQuantity', { quantity:maxQuantity });
                if(vals.map(x => x.length < minLength).some(Boolean))
                    errors[key] = t('components.forms.productForm.itemsMinimumLength', { value : minLength });
            }
        })
        
        return errors;

    }, [t]);

    // Handle Compile Product Notice
    React.useEffect(() => {
        if(socket && product){

            const handleCompileProductNotice = ({productId, count, countTarget, description}) => {
                if(!productId || productId !== product._id)
                    return;

                setCompileProgress(100 * count / countTarget);
                setCompileDescription(description);
                setCompileDetails({count, countTarget});
                if(count >= countTarget){
                    setTimeout(() => {
                        setCompileProgress(undefined);
                        setCompileDescription(undefined);
                        setCompileDetails({count:0, countTarget:0})
                    },1000)
                }
            }

            socket.on('compile_product', handleCompileProductNotice)
            return () => {
                socket.off('compile_product', handleCompileProductNotice);
            }
        }
    },[socket, product])

    const compilingProduct  = React.useMemo(() => (
        Boolean(product?.status === "COMPILING")
    ), [product?.status]);

    const compiling         = React.useMemo(() => (
        compilingIn || !isNaN(compileProgress)
    ), [compileProgress, compilingIn]);

    return (
        
        <Form 
            submitText                  = {t('components.forms.productForm.submitText') }
            debug                       = {false}
            disabled                    = {compiling || disabled}
            onSubmit                    = {handleSubmit}
            onCancel                    = {handleCancel}
            initialValues               = {initialValues}
            validate                    = {validate}
            SubmitButtonProps           = {{size:'large',style:{width:200}}}
            CancelButtonProps           = {{size:'large',style:{width:200}}}
            changeRequiredToSubmit      = {true}
            showObjectId                = {false}
            {...FormProps}
            render                      = { ({disabled, submitting, form, error, dirtySinceLastSubmit, submitFailed, submitSucceeded, errors, submitErrors, handleSubmit, values, ...rest}) => {

                const images    = (Array.isArray(values.images) ? values.images : []);
                const type      = values.type;

                const handleCoverUploaderSubmit = (files, allFiles) => {
                    handleOpenCoverUploader();
                    let uploads = files.map(f => f?.meta?.fileUrl).filter(Boolean);
                    form.change('cover', uploads[0]);
                };

                const handleFileUploaderSubmit = (files, allFiles) => {
                    handleOpenImagesUploader();
                    let uploads = files.map(f => f?.meta?.fileUrl).filter(Boolean);
                    form.change('images', images.concat(uploads));
                };

                // Extract the Product Fields
                let productFields = (processors[values.type] || []).find(p => p.className === values.processor)?.productFields || {};
                let hasProductFields = Object.keys(productFields).length > 0
                

                return (
                    <form onSubmit={handleSubmit} noValidate>
                        {
                            !isEqual(values,initialValues) && !submitting && !submitSucceeded && 
                                <FormAlert severity="warning">
                                    {t('components.forms.productForm.unsavedChanges') }
                                </FormAlert>
                        }
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                                <div style={{position:'relative'}}>
                                    <Title>
                                        <span style={{paddingRight:theme.spacing(2)}}>
                                            { t('common.cover') }
                                        </span>
                                        {!disabled && 
                                            <Button startIcon={<BackupIcon/>} size="small" color="secondary" onClick={() => setOpenCoverUploader(true)}> 
                                                { t('common.upload') }
                                            </Button>
                                        }
                                        { !disabled && values.cover &&
                                            <RemoveWithConfirmationButton size="small" color="secondary" onClick={() => form.change('cover',undefined)}> 
                                                { t('common.clear') }
                                            </RemoveWithConfirmationButton>
                                        }
                                    </Title>
                                    <FeatureBox>
                                        {
                                            values.cover &&
                                            <ImageContainer onMouseEnter= {()=> setCoverHover(true)} onMouseLeave={()=>setCoverHover(undefined)}>
                                                <Image
                                                    src         = {values.cover}
                                                    alt         = {t('components.forms.productForm.productCover') }
                                                    loading     = "lazy"
                                                />
                                                {
                                                    coverHover && !disabled && 
                                                    <IconButtonsPanel>
                                                        <IconButton 
                                                            size    = "small" 
                                                            color   = "secondary" 
                                                            onClick = {() => {
                                                                form.change('cover', undefined);
                                                            }}
                                                        >
                                                            <Tooltip title={t('common.clear') }>
                                                                <span><DeleteIcon/></span>
                                                            </Tooltip>
                                                        </IconButton>
                                                    </IconButtonsPanel>
                                                }
                                            </ImageContainer>
                                        }
                                        {
                                            !values.cover &&
                                            <div style={{paddingTop:10}}>
                                                <NoDataPrompt disabled={disabled} onClick={handleOpenCoverUploader} buttonText={t('common.upload') }>
                                                    {t('components.forms.productForm.noCoverUploaded') }
                                                </NoDataPrompt>
                                            </div>
                                        }
                                    </FeatureBox>
                                    <div style={{position:'relative'}}>
                                        <Title>
                                            <span style={{paddingRight:theme.spacing(2)}}>
                                                { t('common.images') }
                                            </span>
                                            {
                                                !disabled && 
                                                <Button startIcon={<BackupIcon/>} size="small" color="secondary" onClick={handleOpenImagesUploader} style={{marginLeft:4}}> 
                                                    { t('common.upload') }
                                                </Button>
                                            }
                                            {
                                                !disabled && images && images.length > 0 &&
                                                <RemoveWithConfirmationButton size="small" color="secondary" onClick={() => form.change('images',[])} style={{marginLeft:4}}> 
                                                    { t('common.clear') }
                                                </RemoveWithConfirmationButton>   
                                            }                   
                                        </Title>
                                        <FeatureBox>
                                            {
                                                images.length > 0 &&
                                                <DragAndDrop 
                                                    cols        = {2}
                                                    cellheight  = {100}
                                                    data        = {images} 
                                                    onChange    = {(newData)=>{
                                                        form.change('images',newData);
                                                    }}
                                                    render = {({ref, object, index, isDragging})=>{

                                                        const handleCoverClick = () => {
                                                            if(values.cover && !images.includes(values.cover))
                                                                form.change('images',[...images,values.cover])
                                                            form.change('cover',object.value);
                                                        }
                                                        const handleRemoveClick = () => {
                                                            let newImages = images.filter((key,ix) => ix !== index);
                                                            form.change('images',newImages);
                                                        }

                                                        return (
                                                            <ImageContainer 
                                                                ref             = {ref} 
                                                                style           = {{overflow:'hidden', position:'relative'}}
                                                                onMouseEnter    = {() => setImageHover(index)} 
                                                                onMouseLeave    = {() => setImageHover(undefined)}
                                                                drag            = {isDragging}
                                                            >
                                                                <Image
                                                                    src         = {object.value}
                                                                    alt         = {""}
                                                                    loading     = "lazy"
                                                                    style       = {{opacity: isDragging ? 0.5 : 1.0,width:'100%'}}
                                                                />
                                                                {
                                                                    imageHover === index && !isDragging && !disabled && 
                                                                    <IconButtonsPanel>
                                                                        {values.cover !== object.value &&
                                                                            <IconButton onClick={handleCoverClick} size="small" color="secondary">
                                                                                <Tooltip title={t('components.forms.productForm.swapWithCover') }>
                                                                                    <span><SwapHorizIcon/></span>
                                                                                </Tooltip>
                                                                            </IconButton>
                                                                        }
                                                                        <IconButton onClick={handleRemoveClick} size="small" color="secondary">
                                                                            <Tooltip title={t('common.clear') }>
                                                                                <span><DeleteIcon/></span>
                                                                            </Tooltip>
                                                                        </IconButton>
                                                                    </IconButtonsPanel>
                                                                }
                                                            </ImageContainer>
                                                        )
                                                    }}
                                                />
                                            }
                                            {
                                                !images?.length &&
                                                <div style={{paddingTop:10}}>
                                                    <NoDataPrompt disabled={disabled} onClick={handleOpenImagesUploader} buttonText={t('common.upload') }>
                                                        {t('components.forms.productForm.noImagesUploaded') }
                                                    </NoDataPrompt>
                                                </div>
                                            }
                                        </FeatureBox>
                                    </div>
                                </div>
                            </Grid>

                            <Grid container item xs={12} md={9}>

                                <Grid item xs={12} md={8}>

                                    <Grid container item>
                                        <Grid item xs={12}>

                                            <Box display="flex">
                                                <Box flexGrow={1}>
                                                    <Title>
                                                        {t('components.forms.productForm.productInfo') }
                                                    </Title>
                                                </Box>
                                                {
                                                    compiling && 
                                                    <Box pl={1} style={{transform:'translatey(10px)'}}>
                                                        <Chip 
                                                            color   = "primary" 
                                                            size    = "small"
                                                            label   = {
                                                                t('components.forms.productForm.compilingPercentage', { percentage : (compileProgress || 0).toFixed(1) }) 
                                                            }
                                                        />
                                                    </Box>
                                                }
                                            </Box>
                                            <TextField 
                                                label       = {t('components.forms.productForm.name') }
                                                helperText  = {t('components.forms.productForm.nameHelperText') }
                                                name        = "name" 
                                                disabled    = {disabled}
                                                showError   = {showError}
                                            />
                                            <TextField 
                                                label       = {t('components.forms.productForm.slug') }
                                                helperText  = {t('components.forms.productForm.slugHelperText') }
                                                name        = "slug" 
                                                disabled    = {disabled}
                                                showError   = {showError}
                                            />
                                            <TextField 
                                                label       = {t('components.forms.productForm.abstract') }
                                                helperText  = {t('components.forms.productForm.abstractHelperText') }
                                                name        = "abstract" 
                                                disabled    = {disabled}
                                                showError   = {showError}
                                            />
                                            <TextField 
                                                label       = {t('components.forms.productForm.description') }
                                                helperText  = {t('components.forms.productForm.descriptionHelperText') }
                                                name        = "description" 
                                                disabled    = {disabled}
                                                showError   = {showError}
                                                minRows     = {5}
                                                maxRows     = {Infinity}
                                                multiline
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container item sx={{mt:2}}>
                                        <Grid item xs={12} sx={{mb:-2}}>
                                            <Title>
                                                {t('components.forms.productForm.upgradeDowngrade') }
                                            </Title>
                                            <Typography>
                                                {t('components.forms.productForm.upgradeDowngradeSummary') }
                                            </Typography>
                                        </Grid>
                                        {
                                            [

                                                'downgradeTo',
                                                'upgradeTo'

                                            ].map((name,ix) => (
                                                <Grid key={ix} item xs={6}>
                                                    <Select 
                                                        label               = {startCase(name)}
                                                        name                = {name} 
                                                        disabled            = {disabled}
                                                        showError           = {showError}
                                                        inputLabelProps     = {{shrink : true}}
                                                        value               = {values[name] || 'none'}
                                                    >
                                                        <MenuItem value={"none"}>
                                                            {t('components.forms.productForm.none') }
                                                        </MenuItem>
                                                        {
                                                            products
                                                                .filter(p => p._id !== values._id && !p?.deleted)
                                                                .map((p,ixItem) => (
                                                                    <MenuItem key={ixItem} value={p._id}>
                                                                        {p.name}
                                                                    </MenuItem>
                                                                )
                                                            )
                                                        }
                                                    </Select>
                                                </Grid>
                                            ))
                                        }
                                    </Grid>

                                    <Grid container item sx={{mt:2}}>

                                        <Grid item xs={12} sx={{mb:-2}}>
                                            <Title>
                                                { t('components.forms.productForm.deliveryPipeline') }
                                            </Title>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Select 
                                                label           = { t('components.forms.productForm.type') }
                                                name            = "type" 
                                                disabled        = {disabled}
                                                showError       = {showError}
                                                inputLabelProps = {{shrink : true}}
                                                helperText      = { t('components.forms.productForm.typeHelperText') }
                                            >
                                                <MenuItem value="virtual">  
                                                    { t('components.forms.productForm.virtual') }   
                                                </MenuItem>
                                                <MenuItem value="physical"> 
                                                    { t('components.forms.productForm.physical') }   
                                                </MenuItem>
                                            </Select>

                                            <FormSpy subscription = {{values: true}} 
                                                onChange = {({values})=>{
                                                    if(values.type !== type || !values.type)
                                                        form.change('processor', undefined);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Select
                                                label       = { t('components.forms.productForm.processor') }   
                                                name        = 'processor'
                                                disabled    = {disabled}
                                                showError   = {showError}
                                                inputLabelProps = {{shrink : true}}
                                                helperText  = { t('components.forms.productForm.processorHelperText') }   
                                            >
                                                {
                                                    (
                                                        processors[values.type] || []).map((p,ix)=>(
                                                        <MenuItem key={ix} value={p.className}>
                                                            <Grid style={{ display: "flex" }}>
                                                                <Typography>
                                                                    {p.classLabel}
                                                                </Typography>
                                                                { p.requiresUserInput    ? <PersonIcon/> : undefined } 
                                                                { p.requiresSystemInput  ? <SystemIcon/> : undefined }
                                                            </Grid>
                                                        </MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </Grid>
                                    </Grid>

                                    {/* DYNAMIC PROUCT FIELDS */}
                                    {
                                        hasProductFields && 
                                        <Grid container item sx={{mt:2}}>

                                            <Grid item xs={12}>
                                                {
                                                    false && 
                                                    JSON.stringify(values,null,4)
                                                }
                                                <Title>
                                                    { t('components.forms.productForm.productOptions') }  
                                                </Title>
                                                <Typography>
                                                    { t('components.forms.productForm.productOptionsSummary') }  
                                                </Typography>
                                            </Grid>

                                            {
                                                Object.keys(productFields).map((f,ix)=>{
                                                    let field = productFields[f];
                                                    let {
                                                        type,
                                                        label,
                                                        description,
                                                    } = field;
                                                    let name = `productInput.${f}`;
                                                    if(type === 'integer'){
                                                        return (
                                                            <Grid item key={ix} xs={12} sm={6} md={4}>
                                                                <TextField
                                                                    type            = "number"
                                                                    label           = {label}
                                                                    name            = {name} 
                                                                    disabled        = {disabled}
                                                                    showError       = {showError}
                                                                    helperText      = {description}
                                                                    inputLabelProps = {{shrink : true}}
                                                                />
                                                                <FormSpy 
                                                                    subscription    = {{ values: true }} 
                                                                    onChange        = {({values: v}) => {
                                                                        const   newVals = flat(v),
                                                                                oldVals = flat(values);
                                                                        if(newVals[name] && newVals[name] !== oldVals[name])
                                                                            form.change(name, parseInt(newVals[name]));
                                                                    }}
                                                                />
                                                            </Grid>
                                                        )
                                                    }
                                                    if(type === 'boolean'){
                                                        return (
                                                            <Grid item key={ix} xs={12} sm={6} md={4}>
                                                                <SelectYesNoField
                                                                    label       = {label}
                                                                    name        = {name} 
                                                                    disabled    = {disabled}
                                                                    showError   = {showError}
                                                                    helperText  = {description}
                                                                />
                                                            </Grid>
                                                        )
                                                    }
                                                    else if(type === 'enum'){
                                                        let {options} = field;
                                                        if(!Array.isArray(options)) 
                                                            return null;
                                                        return (
                                                            <Grid item key={ix} xs={12} sm={6} md={4}>
                                                                <Autocomplete
                                                                    disabled                = {disabled} 
                                                                    label                   = {label}
                                                                    name                    = {name}
                                                                    options                 = {options}
                                                                    getOptionValue          = {option => option.value}
                                                                    getOptionLabel          = {option => option.label}
                                                                    isOptionEqualToValue    = {(option,value) => option.value === value?.value}
                                                                    disableCloseOnSelect    = {false}
                                                                    helperText              = {description}
                                                                    textFieldProps          = {{
                                                                        InputLabelProps : {shrink :true}
                                                                    }}
                                                                    
                                                                    /*
                                                                    renderTags = {(value, getTagProps) =>
                                                                        value.map((option, index) => (
                                                                            <Chip {...getTagProps({ index })} color="primary" variant="default" size="small" label={option.label}  />
                                                                        ))
                                                                    }
                                                                    */
                                                                    multiple                = {false}
                                                                />
                                                            </Grid>
                                                        )
                                                    }
                                                    // TODO
                                                    else if(type === 'attachment'){
                                                        let {multi  = false} = field;
                                                        let value   = get(values,name) || [];
                                                        return (
                                                            <Grid item key={ix} xs={12}>
                                                                <NoopFields names={[name]}/>
                                                                <ProductAttachmentsField
                                                                    disabled        = { disabled } 
                                                                    value           = { value } 
                                                                    name            = { name } 
                                                                    multi           = { multi } 
                                                                    label           = { `${label} (${value.length}x)` } 
                                                                    errors          = { errors }
                                                                    submitErrors    = { !get(rest.dirtyFieldsSinceLastSubmit,name) ? submitErrors : undefined}
                                                                    description     = { description } 
                                                                    onChange        = { (attachments) => form.change(name, attachments) }
                                                                />
                                                            </Grid>
                                                        )
                                                    }

                                                    return null;
                                                })
                                            }
                                        </Grid>
                                    }

                                    {
                                        product?.requiresCompilation && 
                                        !Boolean(intersection(['type','processor'], Object.keys(rest.dirtyFields)).length) &&
                                        <Grid container item sx={{mt:2}}>
                                            <Grid item xs={12}>
                                                <Title>
                                                    { t('components.forms.productForm.compilation') }  
                                                </Title>
                                                <Typography gutterBottom>
                                                    { t('components.forms.productForm.compilationSummary') }  
                                                </Typography>
                                                <Typography paragraph>
                                                    { t('components.forms.productForm.compilationDescription') }  
                                                </Typography>
                                                {(
                                                    !isNil(compileProgress)) &&
                                                    <>
                                                        <Box p={1}>
                                                            <LinearProgressWithLabel variant="determinate" value={compileProgress} {...compileDetails}/>
                                                        </Box>
                                                        {
                                                            compileDescription &&
                                                            <Box p={1}>
                                                                <Typography>
                                                                    {compileDescription}
                                                                </Typography>
                                                            </Box>
                                                        }
                                                    </>
                                                }
                                                <Box align="right">
                                                    <ConfirmationButton 
                                                        confirmationText    = { t('components.forms.productForm.compilationConfirmation') } 
                                                        startIcon           = {
                                                            compiling || compilingProduct 
                                                                ? <RefreshIcon loading={true}/> 
                                                                : <BuildIcon/>
                                                        } 
                                                        onClick             = {handleCompileProduct} 
                                                        disabled            = {disabled || compiling} 
                                                        color               = "primary" 
                                                        size                = "large"
                                                        variant             = "contained">
                                                        {
                                                            (compilingProduct || compiling)
                                                                ? t('components.forms.productForm.compiling')
                                                                : product.isCompiled 
                                                                    ? t('components.forms.productForm.recompileLibrary')
                                                                    : t('components.forms.productForm.compileLibrary')
                                                        }
                                                    </ConfirmationButton>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    }

                                    {
                                        false && isAdmin && isAuthenticated &&
                                        <Grid container item sx={{mt:2}}>
                                            <Grid item xs={12}>
                                                <AdminBox mt={2}>
                                                    <Title>
                                                        {t('components.forms.productForm.productData')}
                                                    </Title>
                                                    <JSONViewer src = {product}/>
                                                </AdminBox>
                                            </Grid>
                                        </Grid>
                                    }

                                    {
                                        false && isAdmin && isAuthenticated &&
                                        <Grid container item sx={{mt:2}}>
                                            <Grid item xs={12}>
                                                <AdminBox mt={2}>
                                                    <Title>
                                                        {t('components.forms.productForm.muiRffDetails')}
                                                    </Title>
                                                    <JSONViewer src = {rest}/>
                                                </AdminBox>
                                            </Grid>
                                        </Grid>
                                    }

                                    {
                                        false && isAdmin && isAuthenticated &&
                                        <Grid container item sx={{mt:2}}>
                                            <Grid item xs={12}>
                                                <AdminBox mt={2}>
                                                    <Title>
                                                        {t('components.forms.productForm.submissionErrors')}
                                                    </Title>
                                                    <JSONViewer src = {submitErrors}/>
                                                </AdminBox>
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <Box pb={2}>
                                        <Title>
                                            {t('components.forms.productForm.pricing')}
                                        </Title>
                                        <TextField 
                                            label       = {t('components.forms.productForm.listPrice')}
                                            name        = "listPrice" 
                                            type        = 'number' 
                                            disabled    = {disabled}
                                            inputProps  = {{
                                                style: { fontSize:30, textAlign: 'right' },
                                            }}
                                            InputProps={{
                                                startAdornment : (
                                                    <InputAdornment position="start">
                                                        <Typography style={{fontSize:30}}>
                                                            {currency}
                                                        </Typography>
                                                    </InputAdornment>)
                                            }}
                                            showError = {showError}
                                        />
                                    </Box>

                                    <Grid container item spacing={values.discountType !== 'none' ? 1 : 0}>
                                        <Grid item xs={values.discountType !== 'none' ? 6 : 12}>
                                            <Select 
                                                label       = {t('components.forms.productForm.discountType')}
                                                name        = "discountType"  
                                                disabled    = {disabled}
                                                showError   = {showError}
                                                helperText  = {t('components.forms.productForm.discountTypeHelperText')}
                                                inputLabelProps = {{shrink : true}}
                                            >
                                                <MenuItem value="none">         
                                                    {t('components.forms.productForm.none')}     
                                                </MenuItem>
                                                <MenuItem value="percentage">   
                                                    {t('components.forms.productForm.percentage')}
                                                </MenuItem>
                                                <MenuItem value="fixed">        
                                                    {t('components.forms.productForm.fixed')} 
                                                </MenuItem>
                                            </Select>
                                        </Grid>
                                        {
                                            values.discountType !== 'none' &&
                                            <Grid item xs={6}>
                                                <TextField 
                                                    label       = {
                                                        values.discountType === 'fixed' 
                                                            ? t('components.forms.productForm.discountValue')
                                                            : t('components.forms.productForm.discountValuePercent')
                                                    }
                                                    name        = "discountValue" 
                                                    type        = 'number' 
                                                    disabled    = {disabled}
                                                    helperText  = {t('components.forms.productForm.discountValueHelperText')}
                                                    inputProps  = {{
                                                        style: { textAlign: 'right' },
                                                    }}
                                                    InputProps={ 
                                                        values.discountType === 'fixed' 
                                                            ? {
                                                                startAdornment : (
                                                                    <InputAdornment position="start">
                                                                        {currency}
                                                                    </InputAdornment>)
                                                            }
                                                            : {
                                                                endAdornment : (
                                                                    <InputAdornment position="end">
                                                                        {'%'}
                                                                    </InputAdornment>
                                                                )
                                                            }
                                                    }
                                                    showError   = {showError}
                                                />
                                            </Grid>
                                        }
                                    </Grid>
                                    
                                    <Grid item container>
                                        <Grid item xs={12} sx={{mb:-2}}>
                                            <Title>
                                                {t('components.forms.productForm.attributes')}
                                            </Title>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <SelectYesNoField
                                                label               = {t('components.forms.productForm.listed')}
                                                helperText          = {t('components.forms.productForm.listedHelperText')}
                                                name                = "listed"
                                                disabled            = {disabled}
                                                showError           = {showError}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <SelectYesNoField
                                                label               = {t('components.forms.productForm.available')}
                                                helperText          = {t('components.forms.productForm.availableHelperText')}
                                                name                = "available"  
                                                disabled            = {disabled}
                                                showError           = {showError}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Select 
                                                label               = {t('components.forms.productForm.leadTimeSecondsOverride')}
                                                name                = "leadTimeSecondsOverride"  
                                                value               = {values.leadTimeSecondsOverride === null ? 'default' : values.leadTimeSecondsOverride}
                                                disabled            = {disabled}
                                                showError           = {showError}
                                                helperText          = {t('components.forms.productForm.leadTimeSecondsOverrideHelperText')}
                                                inputLabelProps     = {{shrink : true}}
                                            >
                                                <MenuItem value={"default"}>    
                                                    {t('components.forms.productForm.processorDefault')}
                                                </MenuItem>
                                                <MenuItem value={INSTANT}>      
                                                    {t('components.forms.productForm.immediate')} 
                                                </MenuItem>
                                                {[1,5,15,30,45].map((qty) => (
                                                    <MenuItem key={`${qty}minute`} value={qty * MINUTE}> 
                                                        {t('components.forms.productForm.quantityMinutes', {quantity : qty})}
                                                    </MenuItem>
                                                ))}
                                                {[1,6,12,18].map((qty) => (
                                                    <MenuItem key={`${qty}hour`} value={qty * HOUR}> 
                                                        {t('components.forms.productForm.quantityHours', {quantity : qty})}
                                                    </MenuItem>
                                                ))}
                                                {[1,2,3,4,5,6].map((qty) => (
                                                    <MenuItem key={`${qty}day`} value={qty * DAY}> 
                                                        {t('components.forms.productForm.quantityDays', {quantity : qty})}
                                                    </MenuItem>
                                                ))}
                                                {[1,2,3,4,6,8].map((qty) => (
                                                    <MenuItem key={`${qty}week`} value={qty * WEEK}> 
                                                        {t('components.forms.productForm.quantityWeeks', {quantity : qty})}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <FormSpy subscription = {{values: true}} 
                                                onChange = {({values})=>{
                                                    if(values.leadTimeSecondsOverride === 'default')
                                                        form.change('leadTimeSecondsOverride', null);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Select 
                                                label               = {t('components.forms.productForm.sortPriority')}
                                                helperText          = {t('components.forms.productForm.sortPriorityHelperText')}
                                                name                = "priority"
                                                disabled            = {disabled}
                                                showError           = {showError}
                                                inputLabelProps     = {{shrink : true}}
                                            >
                                                <MenuItem value={0}>    
                                                    {t('components.forms.productForm.low')}
                                                </MenuItem>
                                                <MenuItem value={1}>
                                                    {t('components.forms.productForm.medium')}
                                                </MenuItem>
                                                <MenuItem value={2}>
                                                    {t('components.forms.productForm.high')}
                                                </MenuItem>
                                            </Select>
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <SelectYesNoField
                                                label               = {t('components.forms.productForm.canReview')}
                                                helperText          = {t('components.forms.productForm.canReviewHelperText')}
                                                name                = "canReview"
                                                disabled            = {disabled}
                                                showError           = {showError}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <SelectYesNoField
                                                label               = {t('components.forms.productForm.canExample')}
                                                helperText          = {t('components.forms.productForm.canExampleHelperText')}
                                                name                = "canExample"
                                                disabled            = {disabled}
                                                showError           = {showError}
                                            />
                                        </Grid>

                                        {/*
                                        <Grid item xs={12}>
                                            <Title>
                                                Taxation
                                            </Title>
                                            <Select 
                                                label       = 'Tax Exempt'
                                                name        = "taxExempt"  
                                                disabled    = {disabled}
                                                showError   = {showError}
                                                helperText  = {'If not exempt, tax will be calculated at checkout'}
                                                inputLabelProps = {{shrink : true}}
                                            >
                                                    <MenuItem value={true}>     Yes     </MenuItem>
                                                    <MenuItem value={false}>    No      </MenuItem>
                                            </Select>
                                        </Grid>
                                        */}

                                        <Grid item xs={12}>
                                            <Title>
                                                {t('components.forms.productForm.tagsCategories')}
                                            </Title>
                                            <Autocomplete
                                                disabled                = {disabled} 
                                                label                   = {t('components.forms.productForm.tags')}
                                                name                    = "tags"
                                                freeSolo
                                                options                 = {arr}
                                                value                   = {values.tags}
                                                getOptionValue          = {option => option}
                                                getOptionLabel          = {option => option}
                                                isOptionEqualToValue    = {(option,value) => option === value }
                                                disableCloseOnSelect    = {false}
                                                textFieldProps          = {{
                                                    InputLabelProps : {shrink :true}
                                                }}
                                                renderTags              = {(value, getTagProps) =>
                                                    value.map((option, index) => (
                                                        <Chip 
                                                            {...getTagProps({ index })} 
                                                            color   = "primary" 
                                                            size    = "small" 
                                                            label   = {option} 
                                                        />
                                                    ))
                                                }
                                                multiple
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Autocomplete
                                                disabled                = {disabled} 
                                                label                   = {t('components.forms.productForm.categories')}
                                                name                    = "categories"
                                                freeSolo
                                                options                 = {arr}
                                                value                   = {values.categories}
                                                getOptionValue          = {option => option}
                                                getOptionLabel          = {option => option}
                                                isOptionEqualToValue    = {(option,value) => option === value }
                                                disableCloseOnSelect    = {false}
                                                textFieldProps          = {{
                                                    InputLabelProps : {shrink :true}
                                                }}
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => (
                                                        <Chip {...getTagProps({ index })} color="secondary" size="small" label={option} />
                                                    ))
                                                }
                                                multiple
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <FileUploader 
                            title       = {t('components.forms.productForm.uploadProductCover')}
                            open        = {openCoverUploader} 
                            multiple    = {false}
                            onClose     = {handleCloseCoverUploader} 
                            onSubmit    = {handleCoverUploaderSubmit}
                        />

                        <FileUploader 
                            title       = {t('components.forms.productForm.uploadProductImages')}
                            open        = {openImagesUploader} 
                            onClose     = {handleCloseImagesUploader} 
                            onSubmit    = {handleFileUploaderSubmit}
                        />
                    </form>
                );
            }}
        />
    );
}

export default ProductForm;