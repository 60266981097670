/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Loading Spinner
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       21st November 2020

*******************************************************************************************/

import React                        from 'react';
import { Audio as Loader }          from 'react-loader-spinner'
import { useTheme }                 from '@mui/material';

export const Spinner = ({ 
    height  = 40, 
    width   = 40, 
    color   = "primary",
    ...rest
} = {}) => {
    const theme = useTheme();
    const col   = (theme.palette[color] ? theme.palette[color].main : undefined) || color;
    return(
        <Loader
            color           = {col}
            colorSecondary  = {col}
            height          = {height}
            width           = {width}
            {...rest}
        />
    )
}

export default Spinner;