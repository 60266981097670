/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Analytics
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       25th November 2022

*******************************************************************************************/
import React                            from 'react';
import { Helmet }                       from "react-helmet";
import config                           from '../config';

const {
    production : PRODUCTION_MODE,
    analytics : {
        idGoogleAnalytics   : ID_GOOGLE_ANALYTICS,
        idGoogleTagManager  : ID_GOOGLE_TAG_MANAGER ,
        idClarity           : ID_CLARITY,
        idYandex            : ID_YANDEX,
        idFacebook          : ID_FACEBOOK,
        idPinterest         : ID_PINTEREST,
        idPinterestEmail    : ID_PINTEREST_EMAIL
    },
    adwords : {
        idTracking          : ADWORDS_TRACKING_ID,
        idConversion        : ADWORDS_CONVERSION_ID
    },
} = config;


const hashEmail = async (email) => {

    // TextEncoder encodes the string as bytes
    const encoder       = new TextEncoder();
    const data          = encoder.encode(email);
  
    // Use the SubtleCrypto interface to hash the data with SHA-256 algorithm
    const hashBuffer    = await crypto.subtle.digest('SHA-256', data);
  
    // Convert the hash buffer to a hexadecimal string
    const hashArray     = Array.from(new Uint8Array(hashBuffer));
    const hashedEmail   = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
  
    return hashedEmail;
}

const Clarity = () => {
    if(!ID_CLARITY)
        return null;
    return (
        <Helmet>
            { /* Microsoft Clarity */ }
            <script type="text/javascript">
                {
                    `
                        (function(c,l,a,r,i,t,y){
                            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i+"?ref=bwt";
                            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                        })(window, document, 'clarity', 'script', '${ID_CLARITY}');
                    `
                }
            </script>
        </Helmet>
    )
}

const Google = () => {
    if(!ID_GOOGLE_ANALYTICS && !ID_GOOGLE_TAG_MANAGER)
        return null;
    return (
        <>
            <Helmet>
            {
                    ID_GOOGLE_TAG_MANAGER &&
                    <script type="text/javascript">
                        {
                            `
                                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                                })(window, document, 'script', 'dataLayer', '${ID_GOOGLE_TAG_MANAGER}');
                            `
                        }
                    </script>
                }

                {
                    ID_GOOGLE_ANALYTICS &&
                    <script async src={`https://www.googletagmanager.com/gtag/js?id=${ID_GOOGLE_ANALYTICS}`} />
                }
                {
                    ID_GOOGLE_ANALYTICS &&
                    <script type="text/javascript">
                        {
                            `
                                window.dataLayer = window.dataLayer || [];
                                function gtag(){
                                    dataLayer.push(arguments);
                                }
                                gtag('js', new Date());
                                gtag('config', '${ID_GOOGLE_ANALYTICS}' );
                            `
                        }
                    </script>
                }
                {
                    ADWORDS_TRACKING_ID &&
                    <script async src={`https://www.googletagmanager.com/gtag/js?id=${ADWORDS_TRACKING_ID}`} />
                }
                {
                    ADWORDS_TRACKING_ID && ADWORDS_CONVERSION_ID &&
                    <script type="text/javascript">
                        {
                            `
                                window.dataLayer = window.dataLayer || [];
                                function gtag(){
                                    dataLayer.push(arguments);
                                }
                                gtag('js', new Date());
                                gtag('config', '${ADWORDS_TRACKING_ID}' );
                                gtag('event', 'conversion', {'send_to': '${ADWORDS_TRACKING_ID}/${ADWORDS_CONVERSION_ID}'}); 
                            `
                        }
                    </script>
                }
                
            </Helmet>

            {
                ID_GOOGLE_TAG_MANAGER &&
                <noscript>
                    <iframe 
                        title   = "google_tag_manager"
                        src     = {`https://www.googletagmanager.com/ns.html?id=${ID_GOOGLE_TAG_MANAGER}`} 
                        height  = "0" 
                        width   = "0" 
                        style   = {{ 
                            display    : 'none', 
                            visibility : 'hidden' 
                        }}
                    />
                </noscript>
            }
        </>
    )
}

const Yandex = () => {
    if(!ID_YANDEX)
        return null;
    return (
        <Helmet>
            {/* Yandex */}
            <meta name="yandex-verification" content={ID_YANDEX} />
        </Helmet>
    )
}

const Pinterest = () => {

    const [pinterestHashedEmail, setPinterestHashedEmail] = React.useState(undefined);
    React.useEffect(() => {
        if(ID_PINTEREST_EMAIL){
            hashEmail(ID_PINTEREST_EMAIL)
                .then(setPinterestHashedEmail)
                .catch(err => {
                    console.error(err);
                    setPinterestHashedEmail(undefined);
                })
        }else{
            setPinterestHashedEmail(undefined);
        }
    },[])

    const usePinterest = React.useMemo(() => (
        Boolean(ID_PINTEREST && ID_PINTEREST_EMAIL && pinterestHashedEmail)
    ), [pinterestHashedEmail])

    if(!usePinterest)
        return null;

    return (
        <Helmet>
            <script>
                {
                    `
                        !function(e){
                            if(!window.pintrk){
                                window.pintrk = function () {
                                    window.pintrk.queue.push(Array.prototype.slice.call(arguments))
                                };
                                var n   = window.pintrk; n.queue=[], n.version="3.0" ;
                                var t   = document.createElement("script");
                                t.async = !0, t.src   = e;
                                var r   = document.getElementsByTagName("script")[0];
                                r.parentNode.insertBefore(t,r);
                            }
                        }("https://s.pinimg.com/ct/core.js");
                        pintrk('load', '${ID_PINTEREST}', {em: '${ID_PINTEREST_EMAIL}'});
                    `
                }
            </script>
            <noscript>
                {
                    `
                        <img height="1" width="1" style="display:none;" alt=""
                        src="https://ct.pinterest.com/v3/?event=init&tid=${ID_PINTEREST}&pd[em]=${pinterestHashedEmail}&noscript=1" />
                    `
                }
            </noscript>
        </Helmet>
    )
}

export const FaceBook = () => {
    if(!ID_FACEBOOK)
        return null;
    return (
        <Helmet>
            {/* <!-- Meta Pixel Code --> */}
            <script>
                {
                    `   
                        !function(f,b,e,v,n,t,s)
                        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                        n.queue=[];t=b.createElement(e);t.async=!0;
                        t.src=v;s=b.getElementsByTagName(e)[0];
                        s.parentNode.insertBefore(t,s)}(window, document,'script',
                        'https://connect.facebook.net/en_US/fbevents.js');
                        fbq('init', '${ID_FACEBOOK}');
                        fbq('track', 'PageView');
                    `
                }
            </script>
            <noscript>
                {
                    `
                        <img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${ID_FACEBOOK}&ev=PageView&noscript=1"/>
                    `
                }
            </noscript>
            {/* <!-- End Meta Pixel Code --> */}
        </Helmet>
    )
}

export const Analytics = () => {
    if(!PRODUCTION_MODE)
        return null
    return (
        <React.Fragment>
            <Google  />
            <FaceBook />
            <Clarity/>
            <Pinterest />
            <Yandex />
        </React.Fragment>
    )
}

export default Analytics;   