/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Admin -- CelestialBodies
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       21st August 2021

*******************************************************************************************/
import React                            from 'react';
import { Grid, MenuItem }               from '@mui/material';
import {
    Form
}                                       from 'components';
import { useTranslation }               from 'contexts';
import { 
    TextField,
    Select,
    showErrorOnChange   as showError,
}                                       from 'mui-rff';

// Local Style Overrides
// import './AspectFormStyles.css';
// const capFirst = (x) => x[0].toUpperCase() + x.slice(1,x.length);

const noop = () => {};
const obj = {};
const defaultData = {
    id          : undefined,
    name        : '',
    old         : false,
    description : '',
}

export const CelestialBodyForm = ({
    formData                = defaultData,
    isEditing               = false,
    disabled                = false,
    onSubmit : handleSubmit = noop,
    onCancel : handleCancel = noop,
    FormProps               = obj,
    ...rest
}) => {
    const {t} = useTranslation();
    const validate = (values) => {
        let errors          = {};

        // Required Fields
        let requiredFields  = ['name', 'description'];
        requiredFields.forEach(item => {
            if(values[item] === undefined || values[item] === '')
                errors[item] = errors[item] || t('components.forms.celestialBodyForm.required');
        })
        if(!(typeof values.old === 'boolean'))
            errors.old = errors.old || t('components.forms.celestialBodyForm.mustBeBoolean')

        // Errors
        return errors;
    }

    const initialValues = {
        ...formData
    }

    return (
        <Form
            debug           = {false}
            disabled        = {disabled}
            onSubmit        = {handleSubmit}
            onCancel        = {handleCancel}
            initialValues   = {initialValues}
            validate        = {validate}
            {...FormProps}
            render          = {({disabled, form, error, dirtySinceLastSubmit, submitFailed, submitSucceeded, errors, handleSubmit, values, ...rest}) => {
                return (
                    <form onSubmit={handleSubmit} noValidate>
                        
                        <Grid container>
                            {
                                [   
                                    { name: 'name',        multi : false, translationKey : 'components.forms.celestialBodyForm.name'},
                                    { name: 'description', multi : true,  translationKey : 'components.forms.celestialBodyForm.description'}
                                ]
                                    .map(({name, multi, translationKey, xs = 12},ix) => {
                                        const extra = multi 
                                            ? {multiline:true, minRows:1, maxRows:10, disabled} 
                                            : {disabled : isEditing || disabled, type : "text" };
                                        return (
                                            <Grid key={`text${ix}`} item xs={xs}>
                                                <TextField 
                                                    name        = {name} 
                                                    label       = {t(translationKey)}
                                                    value       = {values[name]}
                                                    {...extra}
                                                    showError   = {showError}
                                                />
                                            </Grid>
                                        )
                                    })
                            }
                            <Grid item xs={12}>
                                <Select disabled = {disabled} name="old" label={t('components.forms.celestialBodyForm.oldNewBody')}>
                                    <MenuItem value={true}>
                                        {t('components.forms.celestialBodyForm.oldBody')}
                                    </MenuItem>
                                    <MenuItem value={false}>
                                        {t('components.forms.celestialBodyForm.newBody')}
                                    </MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                    </form>
                )
            }}
        />
    )
}

export default CelestialBodyForm;