/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Price Tag
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       4th August 2021

*******************************************************************************************/
import React                            from 'react';
import { styled, Box }                  from '@mui/material';

const SaleContainer = styled(Box, {
    shouldForwardProp : prop => !['size','background','color'].includes(prop)
})(({theme, size=10, background = undefined, color=undefined}) => {
    const borderRadius = `${size/10}px ${size/5}px ${size/5}px ${size/10}px`;
    return {
        display                 : 'inline-block',
        width                   : 'auto',
        whiteSpace              : 'nowrap',
        height                  : 2*size,
        minWidth                : 4*size,
        textAlign               : 'center',
        background              : background || theme.palette.secondary.main,

        // '-webkit-border-radius' : borderRadius,
        // '-moz-border-radius'    : borderRadius,
        'WebkitBorderRadius'    : borderRadius,
        'MozBorderRadius'       : borderRadius,
        borderRadius            : borderRadius,
        borderLeft              : `1px solid ${background || theme.palette.secondary.main}`,

        /* This makes room for the triangle */
        marginLeft              : size,
        position                : 'relative',
        color                   : color || 'white',
        fontWeight              : 400,
        fontSize                : `${size}px`,
        lineHeight              : `${2*size}px`,
        padding                 : `0 ${size/2}px 0 ${size/5}px`,
        '&::before' : {
            content             : '""',
            position            : 'absolute',
            display             : 'block',
            left                : -size,
            width               : 0,
            height              : 0,
            borderTop           : `${size}px solid transparent`,
            borderBottom        : `${size}px solid transparent`,
            borderRight         : `${size}px solid ${background || theme.palette.secondary.main}`,
        },
        '&::after' : {
            content             : '""',
            backgroundColor     : color || 'white',
            borderRadius        : '50%',
            width               : size/5,
            height              : size/5,
            display             : 'block',
            position            : 'absolute',
            left                : -size/2 + size/10,
            top                 : size - size/10,
        }
    }
});

/**
 * A price tag, which is a word like 'sale' or 'on sale' in a tag shaped html, at an angle
 * @param {*} param0 
 * @returns 
 */
export const PriceTag = ({children, size=12, ...props}) => (
    <Box width={0}>
        <SaleContainer size={size} background={props?.background} color={props?.color}>
            {children}
        </SaleContainer>
    </Box>
)

export default PriceTag;