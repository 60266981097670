
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Stripe Info Button
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       16th May 2021

*******************************************************************************************/
import React                            from "react";
import { Box, IconButton }              from '@mui/material';
import InfoIcon                         from '@mui/icons-material/Info';
import {
    StripePaymentNotes,
    StripeDemoCards,
    DraggableDialog
}                                       from 'components';
import config                           from '../config';

const { development } = config;

export const StripeInfo = ({ demoCards = false, ...props }) => {
    const [open, setOpen]   = React.useState(false);
    const handleOpen        = React.useCallback( (e) => {
        e.preventDefault();
        setOpen(true);
    }, []);

    const handleClose       = React.useCallback( () => {
        setOpen(false)
    }, []);

    return (
        <React.Fragment>
            <IconButton size="small" onClick={handleOpen} {...props} >
                <InfoIcon style={{fontSize:16}}/>
            </IconButton>
            <DraggableDialog 
                title               = "About Payments" 
                open                = {open} 
                onOk                = {handleClose} 
                onClose             = {handleClose} 
                showButtonsCancel   = {false}
                maxWidth            = "xs"
            >
                <Box>
                    <StripePaymentNotes frameless={true}/>
                </Box>

                {
                    demoCards && development &&
                    <Box sx={{mt:2}}>
                        <StripeDemoCards frameless={true}/>
                    </Box>
                }
            </DraggableDialog>
        </React.Fragment>
    )
}

export default StripeInfo;