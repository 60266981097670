/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
with Typography No Libraries Selected HOC
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       22nd July 2022

*******************************************************************************************/
import React                            from 'react';
import { Typography }                   from '@mui/material';
import { 
    useUser, 
    useLibrary,
    useLibraryViewer,
    useTranslation
}                                       from 'contexts'; 
import withFeatureTypography            from './withFeatureTypography';

const TypographyComponent = (props) => {
    const {t}               = useTranslation();
    const {hasLibraries}    = useLibrary();
    return (
        <Typography align="center" {...props}>
            { 
                props.children || (
                    hasLibraries 
                        ? t('components.withFeatureNoLibrariesSelected.noUserLibrariesSelected') 
                        : t('components.withFeatureNoLibrariesSelected.noUserLibraries')
                )
            }
        </Typography>
    )
};

export const withFeatureNoLibrariesSelected = (Component) => {
    const NewComponent = withFeatureTypography(Component);
    return function WrappedComponent({showFeature : a = true, ...props}){ 
        const {isAuthenticated:b}   = useUser();
        const {hasLibraries:c}      = useLibrary();
        const {libraryId:d}         = useLibraryViewer();
        const showFeature           = React.useMemo(() => [a, b, !c || !d].every(Boolean), [a, b, c, d]);
        return (
            <NewComponent 
                showFeature         = {showFeature}
                typographyComponent = {TypographyComponent}
                {...props}
            />
        )
    }
}

export default withFeatureNoLibrariesSelected;