/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Admin -- Master Library
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       5th September 2021

*******************************************************************************************/
import React                            from 'react';
import moment                           from 'moment';
import { AudioCard }                    from 'material-ui-player'
// import AudioPlayer                      from 'material-ui-audio-player';
import {
    styled,
    useTheme,
    Box,
    MenuItem,
    Grid,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell as TableCellMUI,
    FormHelperText,
    IconButton
}                                       from '@mui/material';
import FileCopyIcon                     from '@mui/icons-material/FileCopy';
import SubjectIcon                      from '@mui/icons-material/Subject';
import HistoryToggleOffIcon             from '@mui/icons-material/HistoryToggleOff';
import GetAppIcon                       from '@mui/icons-material/GetApp';
import {
    Form,
    JSONViewer,
    NoDataPrompt,
    Title,
    FormAlert,
    IconMenu,
    DraggableDialog,
    ItemPaper,
    LoadingData,
    LastQueried
}                                       from 'components';
import {
    FileUploader,
    Confirmation
}                                       from 'components/modals';
import {
    useProduct,
    useMasterLibrary,
    useAngle,
    useCelestialBody,
    useCelestialPoint,
    useHouse,
    useSign,
    useAspect,
    useNetwork,
    useAlert,
    useLocale,
    useTranslation
}                                       from 'contexts';
import {
    formatDuration
}                                       from 'functions';
import {
    capitalCase
}                                       from 'capital-case';
import {
    noCase
}                                       from 'no-case';
import { 
    TextField,
    Select,
    Switches,
    showErrorOnChange   as showError,
}                                       from 'mui-rff';
import {
    PulseButton
}                                       from 'components/pulse';

const TableCell = styled(TableCellMUI)(({theme}) => ({
    padding     : theme.spacing(0.25),
    fontSize    : '0.6rem'
}))

const DEBUG                 = false;
const MAX_SIZE_BYTES        = 100 * 1024 * 1024;

// product, celestialBody, celestialPoint, angle, sign, house, aspect
const FIELDS_MAP = {
    MasterLibraryFixed                                  : [],
    MasterLibraryProduct                                : ['product'],
    MasterLibraryCelestialBody                          : ['celestialBody'],
    MasterLibraryCelestialPoint                         : ['celestialPoint'],
    MasterLibraryAngle                                  : ['angle'],
    MasterLibrarySign                                   : ['sign'],
    MasterLibraryHouse                                  : ['house'],
    MasterLibrarySignHouse                              : ['sign','house'],
    MasterLibraryAngleHouse                             : ['angle','house'],
    MasterLibraryCelestialBodyHouse                     : ['celestialBody','house'],
    MasterLibraryCelestialPointHouse                    : ['celestialPoint','house'],
    MasterLibraryRulerHouse                             : ['celestialBody','house'],
    MasterLibraryHouseHouse                             : ['house','house2'],
    MasterLibraryHouseHouseCelestialBody                : ['house','house2','celestialBody'],
    MasterLibraryAngleSign                              : ['angle','sign'],
    MasterLibraryCelestialBodySign                      : ['celestialBody','sign'],
    MasterLibraryCelestialPointSign                     : ['celestialPoint','sign'],
    MasterLibraryCelestialBodyAspectCelestialBody       : ['celestialBody','aspect','celestialBody2'],
    MasterLibraryCelestialBodyAspectCelestialPoint      : ['celestialBody','aspect','celestialPoint'],
    MasterLibraryCelestialBodyAspectAngle               : ['celestialBody','aspect','angle'],
    MasterLibraryCelestialPointAspectCelestialPoint     : ['celestialPoint','aspect','celestialPoint2'],
    MasterLibraryCelestialPointAspectAngle              : ['celestialPoint','aspect','angle']
};

const noop              = () => {};
const obj               = {};
const defaultFormData   = {};

const formatSeconds = seconds => moment.duration(seconds, "seconds").format("hh:mm:ss", { trim: false })

const useDownload = () => {

    const downloadJSON = React.useCallback((data,fileName) => {
        // Save data as JSON file on the client
        const blob  = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
        const url   = URL.createObjectURL(blob);
        const a     = document.createElement('a');
        a.href      = url;
        a.download  = fileName || 'data.json';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    },[])

    return {
        downloadJSON
    }
}

const ViewTranscript = ({disabled = false, id}) => {

    const {t}                           = useTranslation();
    const {axios}                       = useNetwork();
    const {formatDateTime}              = useLocale();
    const [queried, setQueried]         = React.useState(undefined);
    const [data, setData]               = React.useState(undefined);
    const [loading, setLoading]         = React.useState(false);
    const [downloading, setDownloading] = React.useState(false);
    const {alert}                       = useAlert();
    const {downloadJSON}                = useDownload();

    const queryTranscript               = React.useCallback((id) => {
        return new Promise((resolve,reject) => {
            axios.get(`/api/admin/transcript/${id}`)
                .then(({data}) => data)
                .then(resolve)
                .catch(reject)
        })
    }, [axios])

    const handleViewTranscript  = React.useCallback((id) => () => {
        setLoading(true);
        queryTranscript(id)
            .then(setData)
            .catch(err => {
                alert(err.message,'error')
                setData(undefined)
            })
            .finally(() => {
                setLoading(false);
                setQueried(moment());
            })
    },[alert, queryTranscript])

    const handleDownloadTranscript = React.useCallback((id) => () => {
        setDownloading(true);
        queryTranscript(id)
            .then(data => {
                downloadJSON(data,'transcript.json');
            })
            .catch(err => {
                alert(err.message,'error')
            })
            .finally(() => {
                setDownloading(false);
            })
    },[alert, downloadJSON, queryTranscript])

    const handleCloseTranscript = React.useCallback(() => {
        setData(undefined);
    },[])

    return (
        <React.Fragment>
            {
                id &&
                <IconButton disabled = {disabled || loading} size="small" onClick={handleViewTranscript(id)}>
                    {
                        !loading 
                            ? <SubjectIcon /> 
                            : <LoadingData loading={true} label={null}/> 
                    }
                </IconButton>
            }
            {
                id &&
                <IconButton disabled = {disabled || downloading} size="small" onClick={handleDownloadTranscript(id)}>
                    {
                        !downloading 
                            ? <GetAppIcon /> 
                            : <LoadingData loading={true} label={null}/> 
                    }
                </IconButton>
            }

            {
                !id && 
                <HistoryToggleOffIcon color="error" />
            }  

            {
                data && 
                <DraggableDialog
                    title               = { t('components.forms.masterLibraryForm.viewTranscript') }
                    subtitle            = { t('components.forms.masterLibraryForm.transcriptId',{id : id, created : formatDateTime(moment(data?.createdAt)) }) }
                    open                = { Boolean(data) }
                    onClose             = { handleCloseTranscript }
                    onOk                = { handleCloseTranscript }
                    maxWidth            = { 'md' }
                    fullWidth           = { true }
                    showButtonsCancel   = { false }
                >
                    <Box sx={{"& > * + *" : {mt:2}}}>
                        <ItemPaper sx={{"& > * + *" : {mt:1}}}>
                            {
                                (
                                    data?.paragraphs?.paragraphs || []).map(({sentences,start,end,num_words},ix) => (
                                    <Box key={ix} sx={{m:-1, p:1, ":hover" : { background : theme => theme.palette.action.hover}}}>
                                        <Box display="flex">
                                            <Box>
                                                <Typography component="div" variant="body2" sx={{fontWeight:400}}>
                                                    { t('components.forms.masterLibraryForm.paragraphWithDuration', { number : ix + 1, seconds : Math.abs(end - start).toFixed(1)} )}
                                                </Typography>
                                            </Box>
                                            <Box flexGrow={1}/>
                                            <Box>
                                                <Typography component="div" variant="body2" sx={{fontWeight:400}}>
                                                    { t('components.forms.masterLibraryForm.wordsWithStartEnd', { words : num_words, start : formatSeconds(start), end : formatSeconds(end)} )}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Typography component="div" align="justify">
                                            {
                                                (sentences || []).map(({text}) => text).filter(Boolean).join(' ')
                                            }
                                        </Typography>
                                    </Box>
                                ))
                            }
                            <LastQueried quantity={(data?.paragraphs?.paragraphs || []).length} timestamp={queried}/>
                        </ItemPaper>
                        
                        <ItemPaper>
                            <Title variant="h5">
                                {t('components.forms.masterLibraryForm.rawData')}
                            </Title>
                            <JSONViewer src={data || []}/>
                        </ItemPaper>
                    </Box>
                </DraggableDialog>
            }
        </React.Fragment>
    )
}



export const MasterLibraryForm = ({
    formData                    = defaultFormData,
    isEditing                   = false,
    disabled                    = false,
    onSubmit : handleSubmit     = noop,
    onCancel : handleCancel     = noop,
    FormProps                   = obj,
}) => {
    const {t}                       = useTranslation();
    const theme                     = useTheme();
    const {data:products}           = useProduct();
    const {data:aspects}            = useAspect();
    const {data:angles}             = useAngle();
    const {data:celestialBodies}    = useCelestialBody(); 
    const {data:celestialPoints}    = useCelestialPoint();
    const {data:houses}             = useHouse();
    const {data:signs}              = useSign();
    const {types}                   = useMasterLibrary();

    const [openFileUploader, setOpenFileUploader]   = React.useState();
    const [deleteFile, setDeleteFile]               = React.useState(undefined);
    const [playIndex, setPlayIndex]                 = React.useState((formData?.files || []).length === 1 ? 0 : undefined);

    const fieldDataMap = {
        'product'           : products,
        'angle'             : angles,
        'aspect'            : aspects,
        'celestialBody'     : celestialBodies,
        'celestialPoint'    : celestialPoints,
        'celestialBody2'    : celestialBodies,
        'celestialPoint2'   : celestialPoints,
        'house'             : houses,
        'house2'            : houses,
        'sign'              : signs
    }

    const selectionCriteria = React.useMemo(() => ({
        'random'            : t('components.forms.masterLibraryForm.random'),
        'mostRecent'        : t('components.forms.masterLibraryForm.mostRecent')
    }), [t]);

    const fieldLabels = React.useMemo(() => ({
        'product'           : t('components.forms.masterLibraryForm.product'),
        'angle'             : t('components.forms.masterLibraryForm.angle'),
        'aspect'            : t('components.forms.masterLibraryForm.aspect'),
        'celestialBody'     : t('components.forms.masterLibraryForm.celestialBody'),
        'celestialPoint'    : t('components.forms.masterLibraryForm.celestialPoint'),
        'celestialBody2'    : t('components.forms.masterLibraryForm.celestialBody'),
        'celestialPoint2'   : t('components.forms.masterLibraryForm.celestialPoint'),
        'house'             : t('components.forms.masterLibraryForm.house'),
        'house2'            : t('components.forms.masterLibraryForm.house'),
        'sign'              : t('components.forms.masterLibraryForm.sign')
    }), [t]);

    const validate = React.useCallback( (values) => {

        // Errors Object
        let errors          = {};

        // Check data type field is provided
        if(!values.__t)
            errors.__t = errors.__t || t('components.forms.masterLibraryForm.required');

        // Check data type is valid
        if(!Object.keys(FIELDS_MAP).includes(values.__t))
            errors.__t = errors.__t ||  t('components.forms.masterLibraryForm.mustBeOneOf', { values : Object.keys(FIELDS_MAP).concat(', ') }) 

        // If data type is not valid, then abort and return the errors
        if(errors.__t)
            return errors;

        // Extract the fields for the data type
        let fields = FIELDS_MAP[values.__t];

        // Check the required fields
        let requiredFields = ['key','description','selection'].concat(fields)
        requiredFields.forEach((field,ix)=>{
            if(!values[field])
                errors[field] = errors[field] ||  t('components.forms.masterLibraryForm.required');
        })

        // Ensure Key is alphanumeric only
        var regexp = /^[a-zA-Z0-9-_/]+$/;
        if(values.key && values.key.search(regexp) === -1)
            errors.key = errors.key ||  t('components.forms.masterLibraryForm.mustBeAlpha')

        // Some fields are the same data type, and therefore the field values must differ
        let groups = [
            ['celestialBody',   'celestialBody2'    ],
            ['celestialPoint',  'celestialPoint2'   ]
        ];
        groups.forEach(([a,b])=>{
            if(fields.includes(a) && fields.includes(b))
                if(values[a] === values[b]){
                    let msg =  t('components.forms.masterLibraryForm.fieldsMustDiffer', { fields : fieldLabels[a] } );
                    errors[a] = errors[a] || msg;
                    errors[b] = errors[b] || msg;
                }
        });

        return errors;

    }, [fieldLabels, t]);

    // Open File Uploader
    const handleFileUploaderOpen  = React.useCallback(() => setOpenFileUploader(true), []);

    // Close File Uploader
    const handleFileUploaderClose = React.useCallback(() => setOpenFileUploader(false), []);

    // Initial Values
    const initialValues = React.useMemo(() => ({
        ...formData
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [JSON.stringify(formData)]);;

    return (
        <Form
            debug           = {false}
            disabled        = {disabled}
            onSubmit        = {handleSubmit}
            onCancel        = {handleCancel}
            initialValues   = {initialValues}
            validate        = {validate}
            sticky          = {true}
            showObjectId    = {false}
            {...FormProps}
            render          = {({disabled, form, error, dirtySinceLastSubmit, submitFailed, submitSucceeded, errors, handleSubmit, values, ...rest}) => {
                
                let fields      = FIELDS_MAP[values.__t] || [];
                let activeFiles = (values?.files || []).filter(f => !f.deleted);
                
                // Submit File Uploder
                const handleFileUploaderSubmit = (args) => {
                    let newFiles = [
                        ...values.files, 
                        ...args.map(
                            f => ({
                                file        : f.meta.fileUrl, 
                                name        : f.meta.name, 
                                description : f.meta.type,
                                duration    : f.meta.duration,
                                deleted     : false
                            })
                        )
                    ];
                    newFiles = newFiles.map((f,ix) => ({...f,index:ix}))
                    form.change('files', newFiles)
                    handleFileUploaderClose();
                }

                const handleDownload    = url => window.open(url,'_blank');
                const handlePlay        = ix => setPlayIndex(ix);
                const handleStop        = () => setPlayIndex(undefined);

                return (
                    
                        <form onSubmit={handleSubmit} noValidate>
                            
                            <Grid container spacing={1}>

                                {
                                    values._id && 
                                    <Grid item xs={8} sx={{my:'auto'}}>
                                        <Typography variant="body2">
                                            {
                                                values.__v !== undefined 
                                                    ? t('components.forms.masterLibraryForm.documentIdWithVersion', { id : values._id, version : values.__v + 1 })
                                                    : t('components.forms.masterLibraryForm.documentId', { id : values._id })
                                            }    
                                        </Typography>
                                    </Grid>
                                    
                                }
                                <Grid item xs={values?._id ? 4 : 12}>
                                    <Switches
                                        name        = "ignore"
                                        disabled    = {disabled} 
                                        required    = {true}
                                        data        = {{
                                            label : t('components.forms.masterLibraryForm.ignoreOmit'), 
                                            value : 'ignore'
                                        }}
                                    />
                                </Grid>

                                {  !isEditing && 
                                    <Grid item xs={12}>
                                        <Typography variant="body2">
                                            { t('components.forms.masterLibraryForm.recordingTypeNote') }
                                        </Typography>
                                    </Grid>
                                }

                                <Grid item xs={6}>
                                    <Select 
                                        label           = { t('components.forms.masterLibraryForm.recordingType') }
                                        name            = "__t" 
                                        disabled        = {disabled || isEditing} 
                                        helperText      = { t('components.forms.masterLibraryForm.immutableOnceSaved') }
                                    >
                                        {
                                            types.map((type,ix) => (
                                            <MenuItem key={ix} value={type}>         
                                                {(type || '').replace('MasterLibrary','')}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        label           = "Key" 
                                        name            = "key" 
                                        disabled        = {disabled || isEditing} 
                                        helperText      = { t('components.forms.masterLibraryForm.immutableOnceSaved') }
                                    />
                                </Grid>

                                {
                                    fields.map((field,ix) => {

                                        let label       = fieldLabels[field] || capitalCase(noCase(field));
                                        let fieldData   = fieldDataMap[field];
                                        
                                        if(['celestialBody2','celestialPoint2'].includes(field)){
                                            let fieldA      = field.replace(/\d+/g,''); // Counterpart Field
                                            if(fields.includes(fieldA)){
                                                let fieldAData  = fieldDataMap[fieldA];
                                                let fieldAIx    = values[fieldA] ? fieldAData.findIndex((item) => item._id === values[fieldA] ) : -1;
                                                fieldData       = fieldData.slice(fieldAIx+1)
                                            }
                                        }

                                        // Remove last record of left counterpart
                                        if(
                                            (field === 'celestialBody'  && fields.includes('celestialBody2' )) || 
                                            (field === 'celestialPoint' && fields.includes('celestialPoint2')) 
                                        ){
                                            fieldData = fieldData.slice(0,fieldData.length-1);
                                        }

                                        // Remove invalid values
                                        if(values[field] && !fieldData.find((item) => item._id === values[field]))
                                            form.change(field, undefined);

                                        return (    
                                            <Grid item xs={fields.length > 3 ? 12 : 12/fields.length } key={ix}>
                                                <Select 
                                                    label           = {label}
                                                    name            = {field} 
                                                    disabled        = {disabled || isEditing}
                                                    showError       = {showError}
                                                    helperText      = { t('components.forms.masterLibraryForm.immutableOnceSaved') }
                                                >
                                                    {
                                                        fieldData.map((data, ixB) => (
                                                        <MenuItem value={data._id} key={ixB}>         
                                                            {capitalCase(data.name)}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </Grid>
                                        )
                                    })
                                }

                                <Grid item xs={12}>
                                    <TextField
                                        label           = { t('components.forms.masterLibraryForm.description') }
                                        name            = "description" 
                                        disabled        = {disabled} 
                                        multiline       = {true}
                                        helperText      = { t('components.forms.masterLibraryForm.descriptionHelperText') }
                                    />
                                </Grid>

                                {
                                    values.ignore && 
                                    <Grid item xs={12}>
                                        <FormAlert severity="error">
                                            { t('components.forms.masterLibraryForm.subjectIgnored') }
                                        </FormAlert>
                                    </Grid>
                                }

                                {
                                    !values.ignore && 
                                    <Grid item xs={12}>
                                        <Select 
                                            label           = { t('components.forms.masterLibraryForm.selectionCriteria') }
                                            name            = "selection" 
                                            disabled        = {disabled} 
                                            helperText      = { t('components.forms.masterLibraryForm.selectionCriteriaHelperText') }
                                        >
                                            {
                                                Object
                                                    .keys(selectionCriteria)
                                                    .map((key, ix) => (
                                                        <MenuItem key={key} value={key}>         
                                                            {selectionCriteria[key]}
                                                        </MenuItem>
                                                    ))
                                            }
                                        </Select>    
                                    </Grid>
                                }

                                { 
                                    !values.ignore && 
                                    <Grid item xs={12}>                                        
                                        <Title variant="h6">
                                            { t('components.forms.masterLibraryForm.recordingsCount', { count : activeFiles.length }) }
                                        </Title>
                                        {
                                            activeFiles.length === 0 && 
                                            <FormAlert severity="warning">
                                                { t('components.forms.masterLibraryForm.noFilesAllocated') }
                                            </FormAlert>
                                        }

                                        <FormHelperText>
                                            { t('components.forms.masterLibraryForm.recordingsHelperText') }
                                        </FormHelperText>

                                            <TableContainer>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell >
                                                                { t('components.forms.masterLibraryForm.number') }
                                                            </TableCell>
                                                            <TableCell >
                                                                { t('components.forms.masterLibraryForm.name') }                            
                                                            </TableCell>
                                                            <TableCell >
                                                                { t('components.forms.masterLibraryForm.description') }                     
                                                            </TableCell>
                                                            <TableCell >
                                                                { t('components.forms.masterLibraryForm.duration') }
                                                            </TableCell>
                                                            <TableCell >
                                                                { t('components.forms.masterLibraryForm.source') }
                                                            </TableCell>
                                                            <TableCell align="center">  
                                                                { t('components.forms.masterLibraryForm.lang') }
                                                            </TableCell>
                                                            <TableCell align="center"> 
                                                                { t('components.forms.masterLibraryForm.trans') }
                                                            </TableCell>
                                                            <TableCell align="right">   
                                                                { t('components.forms.masterLibraryForm.actions') }
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            (values?.files || []).map(({name, transcript, description, duration, file, fileGetSignedUrl, deleted, lang}, ix) => {
                                                                const isPlaying = ix === playIndex;
                                                                if(deleted) return null;
                                                                return (
                                                                    <React.Fragment key={ix}>
                                                                        <TableRow key={ix}>
                                                                            <TableCell >{ix + 1}           </TableCell>
                                                                            <TableCell >{name}             </TableCell>
                                                                            <TableCell >{description}      </TableCell>
                                                                            <TableCell >
                                                                                {formatDuration(moment.duration(duration*1000)) || 'N/A'}
                                                                            </TableCell>
                                                                            <TableCell  style={{overflow:'hidden', wordBreak: 'break-all'}}>
                                                                                {file}             
                                                                            </TableCell>
                                                                            <TableCell width={50} align="center">
                                                                                {lang}              
                                                                            </TableCell>
                                                                            <TableCell width={50} align="center">
                                                                                <ViewTranscript id={transcript}/>   
                                                                            </TableCell>
                                                                            <TableCell  style={{whiteSpace:'nowrap'}}>

                                                                                <IconMenu size="small">
                                                                                    <MenuItem onClick={ () => isPlaying ? handleStop() : handlePlay(ix)}>
                                                                                        {isPlaying ? "Hide " : "Show "} Player
                                                                                    </MenuItem>
                                                                                    <MenuItem onClick={ () => handleDownload(fileGetSignedUrl)}>
                                                                                        { t('common.download') }
                                                                                    </MenuItem>
                                                                                    {   
                                                                                        !disabled &&
                                                                                        <MenuItem onClick={ () => setDeleteFile(ix)} >
                                                                                            { t('common.delete') }
                                                                                        </MenuItem>
                                                                                    }
                                                                                </IconMenu>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        {/* Player goes here */ }
                                                                        {
                                                                            activeFiles.length > 0 && isPlaying && 
                                                                            <TableRow style={{background:theme.palette.background.paper}}>
                                                                                <TableCell />
                                                                                <TableCell  colSpan={8}>
                                                                                    <div style={{ marginTop:16, marginBottom:16}}>
                                                                                        <AudioCard src={fileGetSignedUrl} thickness="thin"/>
                                                                                    </div>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        }
                                                                    </React.Fragment>
                                                                )
                                                            }
                                                        )}
                                                    </TableBody>
                                                </Table>
                                                
                                                {
                                                    errors.files &&
                                                    <FormHelperText error={true}>
                                                        {errors.files}
                                                    </FormHelperText>
                                                }

                                                {
                                                    !activeFiles.length &&
                                                    <Box pt={2} p={1}>
                                                        <NoDataPrompt onClick={disabled ? null : handleFileUploaderOpen} buttonText={"Add a new File"}>
                                                            { t('components.forms.masterLibraryForm.noFiles') }
                                                        </NoDataPrompt>
                                                    </Box>
                                                }
                                                {
                                                    activeFiles.length > 0 && !disabled &&
                                                    <Box pt={2} p={1} align="center">
                                                        <PulseButton pulse={true} startIcon={<FileCopyIcon/>} variant="contained" color="secondary" onClick={handleFileUploaderOpen}>
                                                            { t('components.forms.masterLibraryForm.addAnotherFile') }
                                                        </PulseButton>
                                                    </Box>
                                                }
                                                
                                                
                                            </TableContainer>
                                    </Grid>
                                }
                            </Grid>

                            {false && <JSONViewer src={types}/>}

                            {DEBUG && 
                                <Box pt={2}>
                                    <Typography variant="h6" gutterBottom>
                                        { t('components.forms.masterLibraryForm.rawData') }
                                    </Typography>
                                    <JSONViewer src={initialValues}/>
                                </Box>
                            }

                            <FileUploader 
                                title           = { t('components.forms.masterLibraryForm.uploadAudioFile') }
                                open            = {openFileUploader} 
                                acceptAudio     = {false}
                                acceptImage     = {false}
                                acceptVideo     = {false}
                                acceptCustom    = {"audio/mpeg"}
                                multiple        = {true}
                                onClose         = {handleFileUploaderClose} 
                                onSubmit        = {handleFileUploaderSubmit}
                                maxSizeBytes    = {MAX_SIZE_BYTES}
                            />

                            {
                                deleteFile !== undefined &&
                                <Confirmation 
                                    title       = { t('components.forms.masterLibraryForm.confirmDelete') }
                                    open        = {deleteFile !== undefined}
                                    onOk        = {() => {
                                        form.change(
                                            'files', 
                                            // values.files.filter((x,index) => index !== deleteFile)
                                            values.files.map((x,index) => (
                                                index === deleteFile ? {...x, deleted:true} : x
                                            ))
                                        );
                                        setDeleteFile(undefined);
                                    }}
                                    onCancel    = {() => setDeleteFile(undefined)}
                                >
                                    <Typography>
                                        { t('components.forms.masterLibraryForm.confirmDeleteConfirmation') }
                                    </Typography>
                                </Confirmation>
                            }
                        </form>
                );
            }}
        />
    );
}

/*
{files.length > 0 && isPlaying && 
    <TableRow style={{background:theme.palette.background.paper}}>
        <TableCell />
        <TableCell  colSpan={7}>
            <div style={{ marginTop:16, marginBottom:16 }}>
                <AudioPlayer 
                    elevation   = {0}
                    autoplay    = {false}
                    variation   = "secondary"
                    width       = '100%'
                    spacing     = {3}
                    download    = {false}
                    src         = {file} 
                    preload     = "auto"
                    volume      = {false}
                />
            </div>
        </TableCell>
    </TableRow>
}
*/

export default MasterLibraryForm;