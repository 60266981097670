/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Delivery Review
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       5th August 2021

*******************************************************************************************/
import React                            from 'react';
import moment                           from 'moment';
import { 
    useTheme,
    Box, 
    Typography 
}                                       from '@mui/material';
import EditIcon                         from '@mui/icons-material/Edit';
import { 
    RootContainer,
    Button,
    NoDataPrompt,
    Review,
    LoadingData,
    RemoveWithConfirmationButton as RemoveButton,
    DraggableDialog,
    SpaceBox
}                                       from 'components';
import { withTranslation }              from './hoc';
import { ProductReviewForm }            from 'components/forms';
import { 
    useUser, 
    useNetwork,
    useLibrary,
    useOrders
}                                       from 'contexts';
import { FORM_ERROR }                   from 'final-form';
import { useCancelToken }               from 'hooks';

const ID_PREFIX = 'review-delivery';

const obj = {};

export const DeliveryReview = withTranslation( ({
    t,
    component : Component       = Box,
    componentProps              = obj,
    deliveryId                  = undefined, 
    title                       = undefined,
}) => {
    const theme                         = useTheme();
    const { userId, isAuthenticated }   = useUser();
    const {axios, isNetworkReady}       = useNetwork();
    const {cancelToken, isCancel}       = useCancelToken();
    const {library}                     = useLibrary();
    const {orders}                      = useOrders();

    const [review,      setReview]      = React.useState(undefined); 
    const [editReview,  setEditReview]  = React.useState(false);
    const [working,     setWorking]     = React.useState(false);
    const [queried,     setQueried]     = React.useState(undefined);
    const [formData,    setFormData]    = React.useState({});

    const id = React.useMemo(() => (
        [ID_PREFIX,deliveryId].filter(Boolean).join('-')
    ), [deliveryId]);

    const scrollToTop = React.useCallback(() => {
        const el = document.getElementById(id);
        if(el) 
            el.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'start'});
    },[id])

    const handleEditStart               = React.useCallback( () => {
        setEditReview(true);
    }, []);
    
    const handleEditStop                = React.useCallback( () => {
        setEditReview(false);
    }, []);

    const resetReview                   = React.useCallback( () => {
        setReview(undefined);
    },[]);

    React.useEffect( () => {
        setFormData({
            deliveryId      : deliveryId,
            public          : false,        // DEFAULT NOT PUBLIC
            publicDelivery  : false,
            ...review,
            scores : {
                quality         : 0,
                relevance       : 0,
                asDescribed     : 0,
                valueForMoney   : 0,
                ...(review?.scores || {})
            },
        });
    },[deliveryId, review])

    const queryReview = React.useCallback(() => {
        if(deliveryId && isNetworkReady && isAuthenticated){
            setWorking(true);
            axios.get(`/api/product/review/delivery/${deliveryId}?origin=deliveryReview`, {cancelToken})
                .then(({data}) => data)
                .then(setReview)
                .then(() => {
                    setQueried(moment());
                })
                .catch(resetReview)
                .finally(() => {
                    setWorking(false);
                })
        }else{
            resetReview();
        }
    },[axios, cancelToken, deliveryId, isAuthenticated, isNetworkReady, resetReview]);

    const createOrEditReview = React.useCallback((formData) => new Promise((resolve,reject) => {
        if(isNetworkReady && isAuthenticated){
            setWorking(true);
            const url = formData._id 
                ? `/api/product/review/${formData._id}` // EDIT   
                : `/api/product/review/`                // CREATE                   
            
            
            axios.post(url, formData, {cancelToken})
                .then(({data}) => data)
                .then(data => {
                    setReview(data); 
                    handleEditStop();
                    resolve(data);
                })
                .catch(err => {
                    if(isCancel(err)) return reject(err)
                    reject(err);
                })
                .finally(() => {
                    setWorking(false);
                })
        }else{
            resolve({[FORM_ERROR] : t('components.deliveryReview.networkNotAvailable')});
        }
    }),[t, axios, cancelToken, handleEditStop, isAuthenticated, isCancel, isNetworkReady])

    const handleCreateOrEdit = React.useCallback( (formData) => new Promise(resolve => {
        createOrEditReview(formData)
            .then(scrollToTop)
            .then(()            => resolve({}))
            .catch(({errors})   => resolve(errors))
    }), [createOrEditReview, scrollToTop])

    const deleteReview = React.useCallback(() => new Promise((resolve,reject) => {
        if(review && isNetworkReady && isAuthenticated){
            setWorking(true);
            axios.delete(`/api/product/review/${review._id}`, {cancelToken})
                .then(({data}) => data)
                .then(data => {
                    resetReview();
                    return data;
                })
                .then(resolve)
                .catch(err => {
                    if(isCancel(err)) return reject(err);
                    reject(err);
                })
                .finally(() => {
                    setWorking(false);
                })
        }else{
            resolve({[FORM_ERROR] : t('components.deliveryReview.networkOrReviewNotAvailable')});
        }
    }),[t, axios, cancelToken, isAuthenticated, isCancel, isNetworkReady, resetReview, review])

    const handleDelete = React.useCallback( () => new Promise(resolve => {
        deleteReview()
            .then(()            => resolve({}))
            .catch(({errors})   => resolve(errors))
    }), [deleteReview])

    // Execute Query when Query Review Function Changes
    React.useEffect(queryReview, [queryReview]);

    // Check is owner Library context is owned by user, so it will be present //[review?.user,review?.user?.id].includes(userId);
    const isOwner = React.useMemo(() => (
        Boolean(
            isAuthenticated && userId && (
                library.find(   lib => lib?.delivery?._id === deliveryId) ||
                orders.find(    ord => (ord?.deliveries || []).map(del => del._id).includes(deliveryId)  )
            )
        )
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ),[deliveryId, userId, isAuthenticated, JSON.stringify(library || []), JSON.stringify(orders || [])]);

    // Not Public
    /*
    if(false && review && !isOwner && !review.public){
        return (
            <SpaceBox> 
                <Typography align="center">
                    { t('components.deliveryReview.ownerHasNotReviewed')  }
                </Typography>
            </SpaceBox>
        )
    }
    */

    // Render
    return (
        <RootContainer id={id}>
            <Box sx={{'& > * + *' : {mt : 0.5, mr:1}}}>
                <Box display="flex">
                    <Box flexGrow={1}>
                        <Typography gutterBottom variant="h5">
                            {title || t('common.review')}
                        </Typography>
                    </Box>

                    {
                        queried && isOwner && review &&
                        <Box flexShrink = {1}>
                            <Button 
                                disabled    = {working || review?.censored} 
                                startIcon   = {<EditIcon/>} 
                                color       = {theme.palette.mode === 'dark' ? 'secondary' : "primary"} 
                                size        = "small" 
                                onClick     = {handleEditStart}
                            >
                                {t('common.edit')}
                            </Button>
                            <RemoveButton 
                                disabled    = {working || review?.censored} 
                                size        = "small" 
                                onClick     = {handleDelete}
                            >
                                {t('common.delete')}
                            </RemoveButton>
                        </Box>
                    }
                </Box>

                <Component {...componentProps}>

                    {
                        queried && isOwner && !review &&
                        <Box>
                            <Typography>
                                {t('components.deliveryReview.feedbackPrompt')}
                            </Typography>
                        </Box>
                    }

                    { 
                        queried && 
                        <React.Fragment>
                            
                            {
                                !review && isOwner && 
                                <SpaceBox>
                                    <NoDataPrompt
                                        disabled    = {editReview || working || !isNetworkReady}
                                        onClick     = {isNetworkReady ? handleEditStart : undefined}
                                        pluralize   = {false} 
                                        buttonText  = { t('components.deliveryReview.reviewProductDelivery') }
                                    >
                                        { t('components.deliveryReview.youHaveNotReviewed')  }
                                    </NoDataPrompt>
                                </SpaceBox>
                            }

                            {
                                review && (isOwner || review.public) &&
                                <Box>
                                    <Review 
                                        showAlert   = {true} 
                                        showChips   = {true} 
                                        review      = {review}
                                        onEdit      = {handleEditStart} 
                                        onChange    = {setReview}
                                    />
                                </Box>
                            }

                            {
                                !isOwner && (!review || !review.public) &&     
                                <SpaceBox>
                                    <Typography align="center">
                                        { t('components.deliveryReview.ownerHasNotReviewed')  }
                                    </Typography>
                                </SpaceBox>
                            }

                        </React.Fragment>
                    }

                    {
                        !queried && 
                        <SpaceBox textAlign="center">
                            <LoadingData label={t('common.loading')}/>
                        </SpaceBox>
                    }

                    <DraggableDialog
                        title                   = { t('components.deliveryReview.reviewProductDelivery') }
                        open                    = {editReview}
                        showButtons             = {false}
                        onCancel                = {handleEditStop}
                        onClose                 = {handleEditStop}
                        disableBackdropClick    = {true}
                    >
                        <ProductReviewForm 
                            disabled    = {working}
                            onCancel    = {handleEditStop}
                            onSubmit    = {handleCreateOrEdit}
                            formData    = {formData}
                            FormProps   = {{
                                disabledAfterSubmit    : true,
                                changeRequiredToSubmit : true,
                                submitText             : t('components.deliveryReview.submitReview')
                            }}
                            sticky      = {true}
                        />
                    </DraggableDialog>
                </Component>
            </Box>
        </RootContainer>
    )
});

export default DeliveryReview;