/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Credit Card Payment Method Dialog
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       24th December 2020

*******************************************************************************************/

import React                            from 'react';
import { FeatureBox }                   from 'components';
import { Box, Typography }              from '@mui/material';
import { StripePaymentImages }          from 'components';

const Content = () => (
    <React.Fragment>
        <Typography align="justify" variant="body2" paragraph>
            We <strong>do not store</strong> your credit card details on our servers, 
            we use Stripe for processing, which is one of the leading global (fully PCI DSS certified) 
            online payment platforms. 
        </Typography>
        <Typography align="justify" variant="body2">
            Stripe operates in 43 countries, and was responsible for some US$350 billion 
            in transactions as of 2020. Used within some 2 million websites, Stripe asserts that 90% 
            of US adults have purchased from businesses using Stripe.
        </Typography>
        <Box sx={{mt:2}}>
            <StripePaymentImages/>
        </Box>
    </React.Fragment>
);

export const StripePaymentNotes = ({frameless=false, props}) => {
    if(frameless) 
        return <Content/>
    return (
        <FeatureBox title="Note on CC Payments">
            <Content/>
        </FeatureBox>
    )
};

export default StripePaymentNotes;