/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Cart Context
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       7th May 2021

*******************************************************************************************/
import React                    from 'react';
import {clone,isEmpty,isEqual}  from 'lodash';
import moment                   from 'moment'; 
import { useLocation}           from "react-router-dom";
import { useUser }              from './UserContext';
import { useLocale }            from './LocaleContext';
import { useProduct }           from './ProductContext';
import { useAddress }           from './AddressContext';
import { useNetwork }           from './NetworkContext';
import { UserCartLocation }     from '../router/locations';
import { UserCheckoutLocation } from '../router/locations/Locations';
import {
    useStateEphemeral,
    useCancelToken
}                               from 'hooks';
import {debounce}               from '../functions';

// The Context Object
export const CartContext = React.createContext(undefined);

const BASE_API_URL              = '/api/user/cart';
const NOTICE_DURATION           = 5000;
const NOTICE_DURATION_COUPON    = 1000;
const DEBUG                     = false;

// Voltage Provider
export const CartProvider = ({children}) => {
    const location                                              = useLocation();
    const {formatCurrency, currencyFactor}                      = useLocale();
    const {isAuthenticated, ready}                              = useUser();
    const {
        shippingAddress : userShippingAddress, 
        billingAddress  : userBillingAddress
    }                                                           = useAddress();
    const {
        isNetworkReady, 
        axios, 
        socketUsers : socket
    }                                                           = useNetwork();
    const {cancelToken, isCancel}                               = useCancelToken();
    const {data : products}                                     = useProduct();

    const [added,   setAdded]                                   = useStateEphemeral(false, NOTICE_DURATION);
    const [removed, setRemoved]                                 = useStateEphemeral(false, NOTICE_DURATION);

    const [messageCouponSuccess,    setMessageCouponSuccess]    = useStateEphemeral(undefined,NOTICE_DURATION_COUPON);
    const [messageCouponError,      setMessageCouponError]      = useStateEphemeral(undefined,NOTICE_DURATION_COUPON);
    const [onCartPage,              setOnCartPage]              = React.useState(false);

    const [cart,                    setCart]                    = React.useState([]);
    const [free,                    setFree]                    = React.useState(false);
    const [coupon,                  setCoupon]                  = React.useState(undefined);
    const [addresses,               __setAddresses]             = React.useState({billing:undefined, shipping:undefined});
    const [shippingAddressWorking,  setShippingAddressWorking]  = React.useState(false);
    const [billingAddressWorking,   setBillingAddressWorking]   = React.useState(false);
    const [working,                 setWorking]                 = React.useState(false);
    const [workingCoupon,           setWorkingCoupon]           = React.useState(false);

    const [cartValue,               setCartValue]               = React.useState(0);
    const [cartTaxes,               setCartTaxes]               = React.useState(0);
    const [cartTotal,               setCartTotal]               = React.useState(0);
    const [quantity,                setQuantity]                = React.useState(0);
    const [queried,                 setQueried]                 = React.useState(0);
    const [quantityUnique,          setQuantityUnique]          = React.useState(0);

    const [requiresShipping,        setRequiresShipping]        = React.useState(false)
    const [requiresBilling,         setRequiresBilling]         = React.useState(false)
    
    const recordAdded                           = React.useCallback((productId) => {
        if(!onCartPage){
            setRemoved(false);
            setAdded(productId);
        }
    }, [onCartPage, setAdded, setRemoved]);

    const recordRemoved                         = React.useCallback((productId) => {
        if(!onCartPage){
            setAdded(false);
            setRemoved(productId);
        }
    }, [onCartPage, setAdded, setRemoved]);

    const setCartArray                          = React.useCallback(arrayObject => {
        setCart(Array.isArray(arrayObject) ? arrayObject : []);
    },[]);

    const getCart = React.useCallback( () =>  new Promise((resolve, reject) => {
        setWorking(true)
        axios.get(BASE_API_URL, { cancelToken })
            .then(({data}) => data)
            .then(resolve)
            .catch(err => {
                if(isCancel(err)) return reject(err);
                reject(err);
            })
            .catch(reject)
            .finally(() => {
                setWorking(false);
            })
    }),[axios, cancelToken, isCancel]);

    // Api instruct to change quantity
    // Quantity is the TOTAL number of productid that is required.
    const postCart = React.useCallback( ({productId, quantity}) => new Promise((resolve, reject) => {
        setWorking(true)
        axios.post(`${BASE_API_URL}/product/${productId}?quantity=${quantity}`, {quantity}, { cancelToken })
            .then(({data}) => data.items)
            .then(resolve)
            .catch(err => {
                if(isCancel(err)) return reject(err);
                reject(err);
            })
            .finally(() => {
                setWorking(false);
            })
    }), [axios, cancelToken, isCancel])

    // Api instruct to delete a single product in cart
    const deleteProductFromCart = React.useCallback(({productId}) => new Promise((resolve,reject) => {
        setWorking(true)
        axios.delete(`${BASE_API_URL}/product/${productId}`, { cancelToken })
            .then(({data}) => data.items)
            .then(resolve)
            .catch(err => {
                if(isCancel(err)) return reject(err);
                reject(err);
            })
            .catch(reject)
            .finally(() => {
                setWorking(false);
            })
    }),[axios, cancelToken, isCancel])

    // Api instruct to delete all items in cart
    const deleteAllProductsFromCart = React.useCallback( () => new Promise((resolve, reject) => {
        setWorking(true)
        axios.delete(BASE_API_URL , { cancelToken })
            .then(({data}) => data.items)
            .then(resolve)
            .catch(err => {
                if(isCancel(err)) return reject(err);
                reject(err);
            })
            .finally(() => {
                setWorking(false);
            })
    }),[axios, cancelToken, isCancel])

    // Api instruct to check the cart prices
    const checkPrices = React.useCallback( () => new Promise((resolve, reject) => {
        setWorking(true)
        axios.post(`${BASE_API_URL}/confirm`, {}, { cancelToken })
            .then(({data}) => data)
            .then(resolve)
            .catch(err => {
                if(isCancel(err)) return reject(err);
                reject(err);
            })
            .finally(() => {
                setWorking(false);
            })
    }),[axios, cancelToken, isCancel])

    // Api instruct to create order from this cart
    const placeOrder = () => new Promise((resolve, reject) => {
        setWorking(true)
        axios.post(`${BASE_API_URL}/order`, {}, {cancelToken})
            .then(({data:{order,invoice}}) => ({order,invoice}))
            .then(resolve)
            .catch(err => {
                if(isCancel(err)) return reject(err);
                reject(err);
            })
            .finally(() => {
                setWorking(false);
                refresh();
            })
    });

    // Clear Cart
    const clear = React.useCallback(() => {
        setCoupon(undefined);
        setCartArray([]);
    },[setCartArray])

    // Clear the cart on the server
    const clearCart = React.useCallback( () => {
        clear();
        // Mark as removed
        if(cart.length){
            let lastItem        = cart[cart.length -1 ];
            let lastProductId   = lastItem?.product?._id
            recordRemoved(lastProductId); 
        }
        deleteAllProductsFromCart()
            .then(setCartArray)
            .catch(clear);
    },[cart, clear, deleteAllProductsFromCart, recordRemoved, setCartArray])

    // Current Quantity
    const currentQuantityForProduct = React.useCallback( (productId) => {
        // Bad argument
        if(!productId) 
            return 0;
        // Search
        const checkEquality = item => [
            item?.metadata?.product, 
            item?.metadata?.product?._id, 
            item?.product, 
            item?.product?._id
        ].includes(productId)
        return (cart || []).find(checkEquality)?.quantity || 0

    },[cart])

    // Refresh data from server
    const refresh = React.useCallback( (reset) => {
        if(DEBUG) console.log("REFRESHING CART");
        if(isAuthenticated && ready && isNetworkReady){
            if(reset) clear();
            getCart()
                .then(({items, coupon, /*adjustments = [], */ billingAddress:ba, shippingAddress:sa})=>{
                    setCoupon(coupon);
                    setCartArray(items);
                    // setAdjustments(adjustments);
                    __setAddresses({shipping:sa, billing:ba});
                })
                .then(()=>{
                    setQueried(moment())
                })
                .catch(clear)
        }else{
            // clear();
            __setAddresses({shipping:undefined,billing:undefined});
        }
    },[clear, getCart, isAuthenticated, isNetworkReady, ready, setCartArray]) 

    const updateBillingAddress = React.useCallback((address) => new Promise((resolve,reject) => {
        if(DEBUG) console.log('Updating Billing Address');
        setBillingAddressWorking(true);
        axios.post(`${BASE_API_URL}/address/billing`, address, {cancelToken})
            .then(({data}) => {
                __setAddresses(prev => ({...prev, billing:data}));
                resolve();
            })
            .catch(err => {
                if(isCancel(err)) return reject(err);
                reject(err);
            })
            .finally(()=>{
                setBillingAddressWorking(false);
            })
    }),[axios, cancelToken, isCancel])

    const deleteBillingAddress = React.useCallback( () => new Promise((resolve,reject) => {
        if(addresses?.billing && !billingAddressWorking){
            if(DEBUG)  console.log("Deleting Billing Address");
            setBillingAddressWorking(true);
            axios.delete(`${BASE_API_URL}/address/billing`, {cancelToken})
                .then(()=>{
                    __setAddresses(prev => ({...prev, billing:undefined}));
                    resolve();
                })
                .catch(err => {
                    if(isCancel(err)) return reject(err);
                    reject(err);
                })
                .finally(()=>{
                    setBillingAddressWorking(false);
                })
        }
    }),[addresses?.billing, axios, billingAddressWorking, cancelToken, isCancel])

    const updateShippingAddress = React.useCallback( (address) => new Promise((resolve,reject) => {
        if(DEBUG) console.log('Updating Shipping Address');
        setShippingAddressWorking(true);
        axios.post(`${BASE_API_URL}/address/shipping`, address, {cancelToken})
            .then(({data})=>{
                __setAddresses(prev => ({...prev, shipping:data}))
                resolve();
            })
            .catch(err => {
                if(isCancel(err)) return reject(err);
                reject(err);
            })
            .finally(()=>{
                setShippingAddressWorking(false);
            })
    }),[axios, cancelToken, isCancel]);

    const deleteShippingAddress = React.useCallback( () => new Promise((resolve,reject) => {
        if(addresses?.shipping && !shippingAddressWorking){
            if(DEBUG) console.log("Deleting Shipping Address");
            setShippingAddressWorking(true);
            axios.delete(`${BASE_API_URL}/address/shipping`, {cancelToken})
                .then(()=>{
                    __setAddresses(prev => ({...prev, shipping:undefined}));
                    resolve();
                })
                .catch(err => {
                    if(isCancel(err)) return reject(err);
                    reject(err);
                })
                .finally(()=>{
                    setShippingAddressWorking(false);
                })
        }
    }),[addresses?.shipping, axios, cancelToken, isCancel, shippingAddressWorking]);

    // Add Product Quantity to Cart
    // Quantity is the quantity delta, ie, +1 means add one, -1 means remove one
    const addProductToCart = React.useCallback( ({productId, quantity = 1}) => new Promise((resolve,reject) => {

        if(isAuthenticated && ready && isNetworkReady){

            const backup            = clone(cart);
            const backupAdded       = clone(added);
            const backupRemoved     = clone(removed);

            // Get the product from the list of available products
            let product = (products || []).find(p => p._id === productId);
            if(!product)
                throw new Error("Product Not Found");

            let exIndex             = (cart || []).findIndex(item => productId && [item?.product, item?.product?._id].includes(productId))
            let quantityExisting    = exIndex >= 0 ? cart[exIndex].quantity : 0;
            let quantityNew         = Math.max(quantityExisting + quantity, 0); // >= 0, negative quantity nonsensical

            /*
            // Clone the existin cart
            let newCart             = clone(cart);

            // Get the fields for the current item
            const makeFields = cur => {
                let { unit = {} } = cur;
                let { listPrice = 0, discount=0, price=0, taxes=0, total=0 } = unit;
                let q = quantityNew;
                return {
                    unit            : unit,
                    quantity        : q, 
                    listPrice       : q * listPrice,
                    discount        : q * discount,
                    price           : q * price,
                    taxes           : q * taxes, 
                    total           : q * total
                }
            }

            // Already in Cart, Modify the existing record
            if(exIndex >= 0){
                let cur = newCart[exIndex];
                newCart[exIndex] = {
                    ...cur,
                    ...makeFields(cur),
                    product
                }

            // Not in Cart and Quantity is Positive
            // Add Dummy Product now, which will be overwritten on next response from server
            }else if(quantityNew > 0){
                let cur = product;
                newCart = [...newCart, { 
                    _id : uuidv4(), // dummy ID
                    ...makeFields(cur),
                    product
                }]
            }
            */

            // Mark as added
            if(quantityNew > quantityExisting)  
                recordAdded(productId); 
            
            // Mark as removed
            if(quantityNew < quantityExisting)  
                recordRemoved(productId); 

            // set the new cart;
            // setCartArray(newCart);

            // Post the update to the server
            postCart({productId, quantity : quantityNew})
                .then(setCartArray)
                .then(resolve)
                .catch((err)=>{
                    setCartArray(backup);
                    recordAdded(backupAdded);
                    recordRemoved(backupRemoved);
                    console.error(err.message);
                    reject(err);
                })
        }
    }),[isAuthenticated, ready, isNetworkReady, cart, added, removed, products, recordAdded, recordRemoved, setCartArray, postCart])

    // Completely remove product from cart
    const removeProductFromCart = React.useCallback( ({productId}) => new Promise((resolve,reject) => {
        if(productId && isAuthenticated && ready && isNetworkReady){
            const backup            = clone(cart);
            const backupRemoved     = clone(removed);

            // Record Removed
            recordRemoved(productId); 
            
            // Update cart
            // const newCart = cart.filter(item => item?.product !== productId && item?.product?._id !== productId)
            
            // Set the Cart
            // setCartArray(newCart);

            // Delete Product
            deleteProductFromCart({productId})
                .then(setCartArray)
                .then(()=>{
                    recordRemoved(productId);
                })
                .then(resolve)
                .catch((err)=>{
                    console.error(err.message);
                    setCartArray(backup);
                    recordRemoved(backupRemoved);
                    reject(); 
                })
        }else resolve()
    }), [cart, deleteProductFromCart, isAuthenticated, isNetworkReady, ready, recordRemoved, removed, setCartArray])

    // Set the cart quantity and value
    React.useEffect(() => {

        let pr          = cart.reduce((acc,cur) => acc + cur.subtotal, 0);
        let tx          = cart.reduce((acc,cur) => acc + cur.taxes, 0);
        let quantity    = cart.filter(x => x.__t === "PRODUCT").reduce((acc,cur) => acc + cur.quantity, 0);

        // Ensure not negative
        let [value, taxes] = [pr, tx].map(x => Math.max(x,0))

        // Set Value, Taxes and Total
        setCartValue(value);
        setCartTaxes(taxes);
        setCartTotal(value + taxes); // should be the same as sum of above;

        // Set Quantities
        setQuantity(quantity);
        setQuantityUnique([...new Set(cart.filter(c => c.__t === 'PRODUCT').map(x => x._id))].length);
    },[cart])

    // Update shipping status
    React.useEffect( ()=>{
        setRequiresShipping(cart.map(item => item?.product?.requiresShipping).some(Boolean))
        setRequiresBilling(Boolean(cart.length));
    },[cart])

    React.useEffect(() => {
        setOnCartPage([UserCartLocation.path,UserCheckoutLocation.path].includes(location.pathname))
    },[location.pathname])

    // Unmark added notice when move to cart page
    React.useEffect(()=>{
        if(onCartPage){
            setAdded(false);
            setRemoved(false);
        }
    },[onCartPage, setAdded, setRemoved])

    // Call refresh when refresh method changes
    React.useEffect(refresh,[refresh]);

    // Refresh on SocketIO Instruction
    React.useEffect(()=>{
        if(socket){
            socket.on('refresh_cart',       refresh)
            socket.on('refresh_products',   refresh)
            return () => {
                socket.off('refresh_products',  refresh);
                socket.off('refresh_cart',      refresh);
            }
        }
    },[refresh, socket])
    
    /**
     * Set the Billing Address
     */
    const setBillingAddress = React.useCallback( (address) => {
        if(isAuthenticated && ready && isNetworkReady && !isEqual(address,addresses.billing)){
            address && !isEmpty(address) 
                ? updateBillingAddress(address) 
                : deleteBillingAddress();
        }
    },[addresses.billing, deleteBillingAddress, isAuthenticated, isNetworkReady, ready, updateBillingAddress])

    /**
     * Set the Shipping Address
     */
    const setShippingAddress = React.useCallback( (address) => {
        if(isAuthenticated && ready && isNetworkReady && !isEqual(address,addresses.shipping)){
            address && !isEmpty(address) 
                ? updateShippingAddress(address) 
                : deleteShippingAddress();
        }
    },[addresses.shipping, deleteShippingAddress, isAuthenticated, isNetworkReady, ready, updateShippingAddress])


    // Handler to Take on the User Addresses unless they are already defined
    const resetAddresses = React.useCallback( (force=false) => {
        if(force || !addresses.billing)
            setBillingAddress(requiresBilling ? userBillingAddress : undefined);
        if(force || !addresses.shipping)
            setShippingAddress(requiresShipping ? userShippingAddress : undefined);
    },[addresses.billing, addresses.shipping, setBillingAddress, requiresBilling, userBillingAddress, setShippingAddress, requiresShipping, userShippingAddress])
    

    // Helper function 
    const isProductInCart = React.useCallback( (productId) => (
        Boolean(currentQuantityForProduct(productId))
    ),[currentQuantityForProduct])

    // Apply Coupon
    const applyCoupon = React.useCallback(({code}) => new Promise((resolve,reject) => {
        setWorkingCoupon(true);
        axios.post(`${BASE_API_URL}/coupon`, {code}, {cancelToken})
            .then(({data}) => data)
            .then((data) => {
                setMessageCouponSuccess(`Coupon '${data?.code || code}' has been applied`);
                setCoupon(data);
                resolve({});
            })
            .catch((err)=>{
                if(isCancel(err)) return reject(err);
                let {message,errors} = err;
                setMessageCouponError(message);
                resolve(errors);
            })
            .finally(()=>{
                setWorkingCoupon(false);
            })
    }),[axios, cancelToken, isCancel, setMessageCouponError, setMessageCouponSuccess]);

    // Remove Coupon
    const removeCoupon = React.useCallback(() => new Promise((resolve,reject) => {
        setWorkingCoupon(true);
        axios.delete(`${BASE_API_URL}/coupon`, {cancelToken})
            .then(() => {
                setMessageCouponSuccess(coupon?.code ? `Coupon '${coupon?.code}' removed` : 'Coupon removed');
                setCoupon(undefined);
                resolve({});
            })
            .catch((err) => {
                if(isCancel(err)) return reject(err);
                let {message, errors} = err;
                setMessageCouponError(message);
                resolve(errors)
            })
            .finally(() => {
                setWorkingCoupon(false);
            })
    }),[axios, cancelToken, coupon?.code, isCancel, setMessageCouponError, setMessageCouponSuccess]);

    // No items or not requires shipping, discard the addresses
    React.useEffect(()=>{
        if(quantity <= 0 || !requiresBilling)
            setBillingAddress(undefined);
        if(quantity <= 0 || !requiresShipping)
            setShippingAddress(undefined);
    },[quantity, requiresBilling, requiresShipping, setShippingAddress, setBillingAddress])

    /**
     * Remove coupon when cart is emptied and coupon has been applied previously, and addresses have been removed
     */
    React.useEffect( () => {
        let addrBusy   = billingAddressWorking || shippingAddressWorking;
        let addrEmpty  = !addresses?.shipping && !addresses?.billing;
        if(quantity <= 0 && coupon?.code && queried && !addrBusy && addrEmpty){
            removeCoupon();
        }
    },[removeCoupon, quantity, coupon?.code, queried, billingAddressWorking, shippingAddressWorking, addresses?.shipping, addresses?.billing])

    /**
     * Listen to Cart Total and quantity change, and detrmine if free order or not
     */
    React.useEffect(()=>{
        let isFree = Boolean(quantity > 0 && cartTotal <= 0.01); // Value less than a cent and has items
        setFree(isFree);
    },[cartTotal,quantity]);
    
    // Computed fields
    const   cartValueFormatted  = formatCurrency((cartValue || 0)/currencyFactor),
            cartTaxesFormatted  = formatCurrency((cartTaxes || 0)/currencyFactor),
            cartTotalFormatted  = formatCurrency((cartTotal || 0)/currencyFactor),
            hasItems            = quantity > 0,
            isCartEmpty         = quantity <= 0,
            disableWidgets      = onCartPage;

    if(DEBUG){
        console.log("Billing Address",  addresses.billing);
        console.log("Shipping Address", addresses.shipping);
    }

    // Context values
    const value = {
        working,
        cart,
        cartValue,
        cartValueFormatted, 
        cartTaxes,
        cartTaxesFormatted,
        cartTotal,
        cartTotalFormatted,
        free,
        coupon,
        workingCoupon,
        messageCouponSuccess,
        messageCouponError,
        applyCoupon,
        removeCoupon,
        shippingAddress : addresses.shipping,
        setShippingAddress,
        shippingAddressWorking,
        billingAddress : addresses.billing,
        setBillingAddress,
        billingAddressWorking,
        resetAddresses,
        quantity,
        quantityUnique,
        hasItems,
        isEmpty : isCartEmpty,
        disableWidgets,
        added,
        removed,
        queried,
        noticeDuration : NOTICE_DURATION,
        requiresBilling,
        requiresShipping,
        isProductInCart,
        qtyProductInCart : currentQuantityForProduct,
        refresh : debounce(refresh),
        currentQuantityForProduct,
        clearCart,
        checkPrices,
        placeOrder,
        addProductToCart,
        removeProductFromCart,
    };

    return (
        <CartContext.Provider value={value}>
            {children}
        </CartContext.Provider>
    )
}

export const CartConsumer =  ({children}) => {
    return (
        <CartContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('CartConsumer must be used within CartProvider');
                }
                return children(context)
            }}
        </CartContext.Consumer>
    )
}

// useVoltage Hook
export const useCart = () => {
    const context = React.useContext(CartContext);
    if(context === undefined)
        throw new Error('useCart must be used within CartProvider');
    return context;
}