/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Socket On/Off Icon
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       7th May 2021

*******************************************************************************************/
import React                        from 'react';
import { Box, Tooltip }             from '@mui/material';
import {
    Link    as LinkIcon,
    LinkOff as LinkOffIcon
}                                   from '@mui/icons-material';
import {useNetwork}                 from 'contexts';

const Online = (props) => (
    <Tooltip title="Socket Connected">
        <LinkIcon {...props} sx={{color : theme => theme.palette.secondary.main}}/> 
    </Tooltip>
)

const Offline = (props) => (
    <Tooltip title="Socket Disconnected">
        <LinkOffIcon {...props} sx={{color : theme => theme.palette.error.main}}/> 
    </Tooltip>
)

// HOC to set isOnline prop based on useSocketIo hook
const withOnlineStatus = Component => props => {
    const {socketUsers : socket} = useNetwork();
    const isOnline = socket && socket.connected;
    return (
        <Component isOnline={isOnline} {...props}/>
    )
}

export const SocketOff = withOnlineStatus( ({isOnline,...props}) => {
    if(isOnline) return null;
    return (
        <Offline {...props} />
    )
});

export const SocketOn = withOnlineStatus( ({isOnline,...props}) => {
    if(!isOnline) return null;
    return (
        <Online {...props} />
    )
});

export const SocketOnOff = ({children, showOn = true, showOff = true, ...props}) => {
    if(!showOn && !showOff)
        return null;
    return (
        <Box sx={{p:1}}>
            {   
                showOn && 
                <SocketOn  {...props}/>
            }
            {
                showOff && 
                <SocketOff {...props}/>
            }
        </Box>
    )
}

export default SocketOnOff