/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Digital Font
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       8th August 2021

*******************************************************************************************/
import React                            from 'react';
import { Box }                          from '@mui/material';

// Custom Fonts
import './css/font-face-custom.css';

export const DigitalFont = ({children, ...props}) => {
    return (
        <Box component="div" {...props} sx={{...props.sx, fontFamily : 'Digital-7 Mono'}}>
            {children}
        </Box>
    )
}

export default DigitalFont;