/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
MasterLibraryFilterForm
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       8th February 2021

*******************************************************************************************/
import React                                from 'react';
import {clone}                              from 'lodash';
import {titleCase}                          from 'title-case';
import { 
    useTheme, Box, Grid, 
    Chip, Typography, 
    Button 
}                                           from '@mui/material';
import { 
    Form,
}                                           from 'components';
import { 
    useProduct, 
    useMasterLibrary,
    useTranslation
}                                           from 'contexts';
import { useFeatures }                      from 'hooks';
import { 
    Autocomplete,
    showErrorOnChange   as showError,
}                                           from 'mui-rff';

const enforceArray = arr => Array.isArray(arr) ? arr : [arr];

const TYPE_PREFIX   = "MasterLibrary";

const noop = () => {};
const noopvalues = (values) => {};
const obj = {};
const defaultFormData = {
    __t             : [],
    product         : [],
    processor       : [],
    celestialBody   : [],
    celesitalPoint  : [],
    angle           : [],
    sign            : [],
    house           : [],
    aspect          : []
}

// product, celestialBody, celestialPoint, angle, sign, house, aspect
export const MasterLibraryFilterForm = ({
    disabled                    = false,
    formData                    = defaultFormData,
    onSubmit                    = noopvalues,
    onCancel    : handleCancel  = noop,
    debug                       = false,
    FormProps                   = obj,
    ...props
}) => {

    const {t}       = useTranslation();

    // Classes
    const theme     = useTheme();

    // Possible Values from Contexts
    const {
        data:products,
        processors
    }               = useProduct();
    const {types}   = useMasterLibrary();
    const {
        signs,
        angles,
        celestialBodies,
        celestialPoints,
        houses,
        aspects
    }               = useFeatures();

    // Fields Data
    const autoCompleteFields = React.useMemo(() => ({
        __t : {
            data : types.map(x => x.replace(TYPE_PREFIX,'')).filter(Boolean).map(label => ({value : `${TYPE_PREFIX}${label}`, label})),
            translationKey : "components.forms.masterLibraryFilterForm.autoCompleteFields.__t"
        },
        product : {
            data : products.filter(d => !d.deleted).map(d => ({value : d.id, label : d.name})),
            translationKey : "components.forms.masterLibraryFilterForm.autoCompleteFields.product"
        },
        processor : {
            data : (processors.virtual || []).map(p => ({value: p.className, label: p.className})),
            translationKey : "components.forms.masterLibraryFilterForm.autoCompleteFields.processor"
        },
        celestialBody : {
            data : celestialBodies.filter(d => !d.deleted).map(d => ({value : d.id, label : titleCase(d.name)})),
            translationKey : "components.forms.masterLibraryFilterForm.autoCompleteFields.celestialBody"
        },
        celestialPoint : {
            data : celestialPoints.filter(d => !d.deleted).map(d => ({value : d.id, label : titleCase(d.name)})),
            translationKey : "components.forms.masterLibraryFilterForm.autoCompleteFields.celestialPoint"
        },
        sign : {
            data : signs.filter(d => !d.deleted).map(d => ({value : d.id, label : titleCase(d.name)})),
            translationKey : "components.forms.masterLibraryFilterForm.autoCompleteFields.sign"
        },
        angle : {
            data : angles.filter(d => !d.deleted).map(d => ({value : d.id, label : titleCase(d.name)})),
            translationKey : "components.forms.masterLibraryFilterForm.autoCompleteFields.angle"
        },
        aspect : {
            data : aspects.filter(d => !d.deleted).map(d => ({value : d.id, label : titleCase(d.name)})),
            translationKey : "components.forms.masterLibraryFilterForm.autoCompleteFields.aspect"
        },
        house : {
            data : houses.filter(d => !d.deleted).map(d => ({value : d.id, label : titleCase(d.name)})),
            translationKey : "components.forms.masterLibraryFilterForm.autoCompleteFields.house"
        }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [ JSON.stringify(angles), JSON.stringify(aspects), JSON.stringify(celestialBodies), JSON.stringify(celestialPoints), JSON.stringify(houses), JSON.stringify(products), JSON.stringify(signs), JSON.stringify(types), processors.virtual]);
    
    // Validate Handler
    const validate = React.useCallback( (values) => {
        let errors = {};
        return errors;
    },[]);

    // Ensure in object of arrays
    const formatResult = React.useCallback( (formData) => (
        Object
            .keys(formData)
            .reduce((acc,cur) => ({
                ...acc, 
                [cur] : enforceArray(formData[cur]).filter(Boolean)
            }),{})
    ), []);

    // Submit Handler
    const handleSubmit = React.useCallback( (values) => {
        let result              = clone(values);
        
        // Add CelestialPoint2
        // result.celestialPoint2  = result.celestialPoint;

        // Add CelestialBody2
        // result.celestialBody2   = result.celesitalBody;

        // Ensure Array
        result = formatResult(values);

        // Extract values
        result = Object.keys(result).reduce((acc,cur) => ({...acc,[cur] : result[cur].map(x => x.value)}),{})

        // Done
        return onSubmit(result);
    }, [formatResult, onSubmit]);

    // Initial Values
    const initialValues = React.useMemo(() => {
        let temp;
        temp = formatResult(formData)
        temp = Object.keys(temp).reduce((acc,cur) => ({...acc, [cur] : (autoCompleteFields[cur]?.data || []).filter(x => temp[cur].includes(x.value))}),{})
        return temp;
    },[autoCompleteFields, formData, formatResult]);


    return (
        <Form
            debug           = {debug}
            disabled        = {disabled}
            onSubmit        = {handleSubmit}
            onCancel        = {handleCancel}
            initialValues   = {initialValues}
            validate        = {validate}
            {...FormProps}
            render          = {({disabled, reset, form, error, dirtySinceLastSubmit, submitFailed, submitSucceeded, errors, handleSubmit, values, ...rest}) => {
                const filterQuantity    = Object.keys(values).reduce((acc,cur) => (acc + (Array.isArray(values[cur]) ? values[cur].length : Boolean(values[cur]) ? 1 : 0)), 0);
                const handleResetForm   = () => Object.keys(initialValues).forEach(key => form.change(key,[]));
                return (
                    <form onSubmit={handleSubmit} noValidate>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography gutterBottom variant="body2">
                                    { t('components.forms.masterLibraryFilterForm.selectFiltersQuantity', { quantity : filterQuantity } ) }
                                    <Button 
                                        disabled    = {filterQuantity <= 0} 
                                        color       = {theme.palette.mode === 'light' ? "primary" : "secondary"}
                                        onClick     = {handleResetForm}
                                        style       = {{textTransform: 'none'}}
                                    >
                                        { t('components.forms.masterLibraryFilterForm.clearAllFiltersQuantity', { quantity : filterQuantity } ) }
                                    </Button>
                                </Typography>
                            </Grid>
                            {
                                ['__t', 'product', 'processor', 'angle', 'sign', 'house', 'celestialBody', 'celestialPoint', 'aspect'].map((name,ix) => {
                                    const {data, translationKey, label} = autoCompleteFields[name] || {};
                                    if(!data || (!translationKey && !label))
                                        return null;
                                    return (
                                        <Grid key={name} item xs={12} md={6}>
                                            <Autocomplete
                                                id                      = {`autocomlete-filter-${name}`}
                                                disabled                = { disabled }
                                                name                    = { name }
                                                label                   = { translationKey ? t(translationKey) : label }
                                                options                 = { (data || []).map(item => ({ value : item.value, label : item.label }))}
                                                value                   = { values[name] || [] }
                                                getOptionValue          = { option => option }
                                                getOptionLabel          = { option => option.label }
                                                isOptionEqualToValue    = { (option,value) => option && option?.value === value?.value }
                                                helperText              = { t('components.forms.masterLibraryFilterForm.helperText') }
                                                disableCloseOnSelect    = { true }
                                                textFieldProps          = {{InputLabelProps:{shrink:true}}}
                                                renderTags              = {(value, getTagProps, ...rest) => {
                                                    // console.log(value)
                                                    // console.log(getTagProps)
                                                    // console.log(rest)
                                                    return value.map((option, index) => {
                                                        // console.log(getTagProps({ index }))
                                                        return (
                                                            <Chip
                                                                {...getTagProps({ index })}
                                                                color   = "primary"
                                                                size    = "small"
                                                                label   = {option.label}
                                                            />
                                                        )
                                                    })
                                                }}
                                                showError               = {showError}
                                                autoHighlight
                                                // autoSelect
                                                // autoComplete
                                                multiple
                                            />
                                            {
                                                false && 
                                                <>
                                                    <Box>
                                                        {JSON.stringify(values[name] || [])}
                                                    </Box>
                                                    <Box>
                                                        {JSON.stringify(data,null,4)}
                                                    </Box>
                                                </>
                                            }
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </form>
                )
            }}
        />
    )
}

export default MasterLibraryFilterForm;