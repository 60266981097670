/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Order Tax Invoice Panel
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       20th November 2021

*******************************************************************************************/
import React                            from 'react';
import moment                           from 'moment';
import {
    styled,
    useTheme,
    Box,
    Table,
    TableContainer,
    TableRow,
    TableCell as TableCellMUI,
    TableHead,
    TableBody,
    Typography,
}                                       from '@mui/material';
import {
    Title,
    NoDataPrompt,
    ObjectId,
    MakePayment,
    SpaceBox,
    LoadingData,
    JSONViewer,
    RootContainer,
    ItemPaper
}                                       from 'components';
import { withTranslation }              from 'components/hoc';
import {
    PayButton,
}                                       from 'components/buttons'
import { 
    useOrderViewer,
    useUser,
    useLocale
}                                       from 'contexts';
import {
    useInvoices
}                                       from './context';

const OrderTaxInvoiceButton  = React.lazy(() => import('components/order/OrderTaxInvoiceButton'));

const TableCell = styled(TableCellMUI)(({theme})=>({
    padding     : theme.spacing(0.5),
    fontSize    : '0.65rem'
}))

export const OrderTaxInvoice = withTranslation( ({
    t, 
    displayOrderNumber  = true,
    displayRowNumber    = false 
}) => {
    const {data:order, orderId}         = useOrderViewer();
    const theme                         = useTheme();
    const {userId, isAuthenticated }    = useUser();
    const {
        working,
        hasInvoices,
        refresh,
        invoices,
        STATES
    }                                   = useInvoices();
    const {
        formatDate,
        formatCurrency, 
        currency,
        currencyFactor
    }                                   = useLocale();

    const isOwner = React.useMemo(() => (
        Boolean(
            isAuthenticated && 
            userId &&
            userId === order?.user
        )
    ), [isAuthenticated, order?.user, userId])

    // No data, or not orderId provided, abort
    if(!orderId || !order) 
        return null;

    // Render
    return (
        <RootContainer component={ItemPaper}>
            <Box display="flex">
                <Box flexGrow={1}>
                    <Title variant="h5" gutterBottom>
                        {t('components.order.orderTaxInvoice.taxInvoiceQuantity', { quantity : invoices.length })}
                    </Title>
                </Box>
                {
                    displayOrderNumber && 
                    <Box flexShrink={1} style={{margin:'auto'}}>
                        <Typography variant="body2" gutterBottom>
                            ID: <ObjectId value={order.id}/>
                        </Typography>
                    </Box>
                }
            </Box>

            {
                !hasInvoices && 
                <SpaceBox align="center">
                    {
                        working && 
                        <LoadingData/>
                    }
                    {
                        !working && 
                        <NoDataPrompt>
                            {t('components.order.orderTaxInvoice.noInvoices')}
                        </NoDataPrompt>
                        
                    }
                </SpaceBox>
            }
            
            {
                hasInvoices &&
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {
                                    displayRowNumber && 
                                    <TableCell/>
                                }
                                <TableCell align="center">
                                    {t('common.no')}
                                </TableCell>
                                <TableCell align="left">
                                    {t('common.date')}
                                </TableCell>
                                <TableCell style={{width:'100%'}}>
                                    {t('common.status')}
                                </TableCell>
                                <TableCell align="right">
                                    {t('common.value')}
                                </TableCell>
                                {
                                    false &&
                                    <>
                                        <TableCell align="right">
                                            {t('common.paid')}
                                        </TableCell>
                                        <TableCell align="right">
                                            {t('common.refunded')}
                                        </TableCell>
                                    </>
                                }
                                <TableCell align="right" style={{paddingRight:theme.spacing(1)}}>
                                    {t('common.actions')}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                invoices.map((invoice,ix) => {
                                    const {amountToPay,processing}  = invoice;
                                    const requiresPayment           = ['OPEN', 'DRAFT','UNCOLLECTIBLE'].includes(invoice.state) && invoice?.id;
                                    const payable                   = ['OPEN', 'DRAFT'].includes(invoice.state);
                                    return (
                                        <React.Fragment key={ix}>
                                            <TableRow>
                                                {
                                                    displayRowNumber && 
                                                    <TableCell>
                                                        {ix + 1}             
                                                    </TableCell>
                                                }
                                                <TableCell align="center">
                                                    {invoice._number}     
                                                </TableCell>
                                                <TableCell align="left" style={{whiteSpace:'nowrap',width:50}}>
                                                    {formatDate(moment(invoice.createdAt))}  
                                                </TableCell>
                                                <TableCell>
                                                    {STATES[invoice.state] || invoice.state}
                                                    {
                                                        requiresPayment && invoice.amountToPay >= 0 &&
                                                        <>
                                                            , {formatCurrency(invoice.amountToPay/currencyFactor,currency)} Due
                                                        </> 
                                                    }
                                                </TableCell>
                                                <TableCell align="right"> 
                                                    {formatCurrency(invoice.total/currencyFactor,currency)}
                                                </TableCell>

                                                {
                                                    false &&
                                                    <>
                                                        <TableCell align="right"> 
                                                            {formatCurrency(invoice.amountCaptured/currencyFactor,currency)}
                                                        </TableCell>
                                                        <TableCell align="right"> 
                                                            {formatCurrency(invoice.amountRefunded/currencyFactor,currency)}
                                                        </TableCell>
                                                    </>
                                                }

                                                <TableCell align="right" style={{paddingLeft:theme.spacing(1),whiteSpace:'nowrap'}}>
                                                    
                                                    {
                                                        invoice.state === 'PAID' &&
                                                        <OrderTaxInvoiceButton 
                                                            invoiceId   = {invoice.id} 
                                                            variant     = "text" 
                                                            color       = "secondary" 
                                                            size        = "small"
                                                        />              
                                                    }

                                                    {
                                                        requiresPayment && 
                                                            <MakePayment
                                                                invoiceId           = {invoice?.id}
                                                                title               = {t('common.makePayment')}
                                                                currency            = {currency}
                                                                amount              = {amountToPay}
                                                                fixed               = {true}
                                                                allowChangeDefault  = {false}
                                                                onClose             = {refresh}
                                                                render              = {({handleMakePayment})=>(
                                                                    <PayButton 
                                                                        disabled    = {!isOwner || working || amountToPay <= 0 || !payable || processing} 
                                                                        pulse       = {true} 
                                                                        onClick     = {handleMakePayment} 
                                                                        variant     = "contained" 
                                                                        color       = "primary" 
                                                                        size        = "small" 
                                                                        style       = {{
                                                                            width       : '100%', 
                                                                            whiteSpace  : 'nowrap',
                                                                            margin      : theme.spacing(-0.125)
                                                                        }}
                                                                    >
                                                                        {t('common.payNow')}
                                                                    </PayButton>
                                                                )}
                                                            />
                                                    }
                                                </TableCell>
                                            </TableRow>
                                            {
                                                false &&
                                                <TableRow key={'data' + ix}>
                                                    <TableCell colSpan={5}>
                                                        <JSONViewer src={invoice} />
                                                    </TableCell>
                                                </TableRow>
                                            }
                                        </React.Fragment>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </RootContainer>
    )
})

export default OrderTaxInvoice;