/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Dark Theme
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       4th January 2021

*******************************************************************************************/
import { darken, lighten, createTheme, responsiveFontSizes, alpha } from '@mui/material';
import {
    grey,
    // indigo      as primary,
    // purple      as primary,
    // deepPurple  as secondary,
    // red         as error
  }                                     from '@mui/material/colors'
import {baseThemeDefinition as base}    from './base';
import merge                            from 'deepmerge';

const baseDark = merge(base, {
    palette: {
        mode        : 'dark',
    }
})

const theme = responsiveFontSizes(createTheme(baseDark));
// const theme = createTheme(baseDark);

const secondary                                         = '#31c27c';
const primary                                           = '#090258';
// let [primaryLight,      primaryMain,    primaryDark]    = [darken(primary[100],0.25), darken(primary[200],0.25), darken(primary[300],0.25)];
// let [secondaryLight,    secondaryMain,  secondaryDark]  = [lighten(secondary,0.25), secondary, darken(secondary,0.25)];
//let [primaryLight,      primaryMain,    primaryDark]    = [primary[200], primary[300], primary[400]]
let [primaryLight,      primaryMain,    primaryDark]    = [lighten(primary,0.25), primary, darken(primary,0.25)];
let [secondaryLight,    secondaryMain,  secondaryDark]  = [lighten(secondary,0.25), secondary, darken(secondary,0.25)];


const paperColor    = '#424242'
const defaultColor  = '#303030'

export const darkThemeDefinition = merge(baseDark, {
    typography: {
        allVariants: {
            color: "#fff"
        },
    },
    palette: {
        mode : 'dark',
        primary : {
            light   : primaryLight,
            main    : primaryMain,
            dark    : primaryDark
        },
        secondary : {
            light   : secondaryLight,
            main    : secondaryMain,
            dark    : secondaryDark
        },
        background : {
            default : defaultColor,
            paper   : paperColor
        }
    },
    components : {
        datePicker: {
            selectColor: 'red',
        },
        dateTimePicker: {
            selectColor: 'orange',
        },
        MuiIconButton : {
            styleOverrides : {
                colorPrimary : {
                    color : `${secondaryMain}!important`
                }
            }
        },
        MuiSlider : {
            styleOverrides : {
                thumb : {
                    color : `${secondaryMain}!important`
                },
                rail : {
                    color : `${secondaryMain}!important`
                },
                track : {
                    color : `${secondaryMain}!important`
                },
                root : {
                    color : `${secondaryMain}!important`
                },
                colorPrimary : {
                    color : `${secondaryMain}!important`
                }
            }
        },
        // underline when selected
        MuiInputBase : {
            styleOverrides : {
                root : {
                    '&:after': { 
                        borderBottomColor: `${secondaryMain}!important`
                    },
                }
            }
        },
        MuiCheckbox : {
            styleOverrides : {
                root : {
                    color : `${secondaryMain}!important`
                },
            },
        },
        MuiFormLabel : {
            styleOverrides : {
                colorPrimary : {
                    color: primaryLight
                },
                root: {
                    //color : theme.palette.text.primary,
                    "&.Mui-focused": {
                        color: lighten(primaryLight,0.5)
                    }
                }
            }
        },
        MuiInput:{
            styleOverrides : {
                underline: {
                    '&:after':{
                        borderBottomColor : lighten(primaryLight,0.5)
                    }
                }
            }
        },
        MuiAppBar : {
            styleOverrides : {
                colorPrimary : {
                    backgroundColor : defaultColor,
                }
            }
        },
        MuiDialog : {
            styleOverrides : {
                paper : {
                    background : darken(paperColor,0.1),
                }
            }
        },
        MuiDialogContent : {
            styleOverrides : {
                root : {
                    background : lighten(paperColor,0.1)
                }
            }
        },
        MuiAlert:{
            styleOverrides : {
                root : {
                    // backgroundColor : [theme.palette.background.default,'!important'],
                    // border : `1px solid ${theme.palette.divider}`
                }
            }
        },
        MuiButton: {
            styleOverrides : {
                contained : {
                    '&:disabled':{
                        backgroundColor : alpha(grey[400],0.5)
                    }
                },
                outlined : {
                    '&:disabled':{
                        color : alpha(grey[400],0.5)
                    }
                }
            }
        },
        MuiCard: {
            styleOverrides : {
                root : {
                    background : lighten(paperColor,0.050),
                }
            }
        },
        MuiTabs : {
            styleOverrides : {
              flexContainer : {
                borderBottom : `1px solid ${theme.palette.divider}`
              }
            }
        },
        MuiTab : {
            styleOverrides : { 
                root: {
                    backgroundColor         : darken(paperColor,0.05),
                    color                   : secondaryMain,
                    "&:hover": {
                        backgroundColor     : darken(secondaryDark,0.30),
                        color               : theme.palette.text.primary
                    },
                    '&.Mui-selected': {
                        backgroundColor         : darken(secondaryDark,0.40),
                        color                   : theme.palette.text.primary,
                        "&:hover": {
                            backgroundColor     : darken(secondaryMain,0.45),
                            color               : theme.palette.text.primary
                        }
                    },
                },
                textColorPrimary : {
                    color : secondaryMain
                }
            }
        },
        MuiTabScrollButton : {
            styleOverrides : { 
                root: {
                    color : secondaryMain
                }
            }
        }
    }
});

export const darkThemeInvertedDefinition = merge(darkThemeDefinition, {
    palette: {
        mode : 'dark',
        primary     : darkThemeDefinition.palette.secondary,
        secondary   : darkThemeDefinition.palette.primary
    }
})

export const darkThemeInverted  = responsiveFontSizes(createTheme(darkThemeInvertedDefinition));
// export const darkThemeInverted  = createTheme(darkThemeInvertedDefinition);

export const darkTheme          = responsiveFontSizes(createTheme(darkThemeDefinition));
// export const darkTheme          = createTheme(darkThemeDefinition);

export default darkTheme;