/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
No Internet Alert
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       9th July 2021

*******************************************************************************************/
import React                        from 'react';
import {MainAlert}                  from 'components';
import {useNetwork}                 from 'contexts';
import { withTranslation }          from './hoc';

export const AlertNoInternet = withTranslation( ({t}) => {
    const {isOnline} = useNetwork();
    if(Boolean(isOnline))
        return null
    return (
        <MainAlert severity="error">
            {t("common.noInternet")}
        </MainAlert>
    )
})

export default AlertNoInternet;