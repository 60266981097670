/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Skeleton Container
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       11th Jan 2022

*******************************************************************************************/
import { styled, Box } from '@mui/material';

export const SkeletonContainer = styled(Box)({
    position        : 'relative',
    width           : '100%',
    height          : '100%',
    paddingTop      : 2,
    paddingLeft     : 1,
    paddingRight    : 1,
    paddingBottom   : 0,
    minHeight       : 100
});

export default SkeletonContainer;
