/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
FileIcon
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       26th December 2022

*******************************************************************************************/
import React                        from 'react';
import { FileIcon as FileIconSrc }  from 'react-file-icon';

/*
const STYLES = {
    psd : {
        color           : "#34364E",
        labelColor      : "#34364E",
        labelTextColor  : "#31C5F0",
        foldColor       : "#31C5F0",
        gradientOpacity : 0,
        radius          : 2,
        labelUppercase  : true,
    },
    ai : {
        color           : "#423325",
        labelColor      : "#423325",
        labelTextColor  : "#FF7F18",
        foldColor       : "#FF7F18",
        gradientOpacity : 0,
        radius          : 2,
        labelUppercase  : true,
    },
    indd : {
        color           : "#4B2B36",
        labelColor      : "#4B2B36",
        labelTextColor  : "#FF408C",
        foldColor       : "#FF408C",
        gradientOpacity : 0,
        radius          : 2,
        labelUppercase  : true,
    },
    doc : {
        color           : "#2C5898",
        labelColor      : "#2C5898",
        glyphColor      : "rgba(255,255,255,0.4)",
        labelUppercase  : true,
        type            : "document",
    },
    xls : {
        color           : "#1A754C",
        labelColor      : "#1A754C",
        glyphColor      : "rgba(255,255,255,0.4)",
        labelUppercase  : true,
        type            : "spreadsheet"
    },
    png : {
        type            : 'image',
        labelUppercase  : true
    },
    pdf : {
        glyphColor      : "#FF0000",
        labelUppercase  : true,
        type            : 'acrobat',
    },
    zip : {
        glyphColor      : "#FF0000",
        labelUppercase  : true,
        type            : '',
    },
    ppt : {
        color           : "#D14423",
        labelColor      : "#D14423",
        glyphColor      : "rgba(255,255,255,0.4)",
        labelUppercase  : true,
        type            : "presentation"
    },
    document : {
        color           : "#FF8500",
        gradientColor   : "#FFB900",
        glyphColor      : "rgba(255,255,255,0.6)",
        gradientOpacity : 1,
        fold            : false,
        radius          : 6,
        type            : "document"
    },
    spreadsheet : {
        colo            : "#11D51D",
        gradientColor   : "#82FA6C",
        glyphColor      : "rgba(255,255,255,0.6)",
        gradientOpacity : 1,
        fold            : false,
        radius          : 6,
        type            : "spreadsheet"
    },
    presentation : {
        color           : "#1254F8",
        gradientColor   : "#00D2FF",
        glyphColor      : "rgba(255,255,255,0.6)",
        gradientOpacity : 1,
        fold            : false,
        radius          : 6,
        type            : "presentation"
    }
}
*/


// https://codesandbox.io/s/react-file-icon-colored-tmwut?file=/src/style-customize.js:0-3296
const styleDef = [];

// video ////////////////////////////////////
const videoStyle = {
  labelColor: "#6b00a8"
};
const videoExtList = [
  "avi",
  "3g2",
  "3gp",
  "aep",
  "asf",
  "flv",
  "m4v",
  "mkv",
  "mov",
  "mp4",
  "mpeg",
  "mpg",
  "ogv",
  "pr",
  "swfw",
  "webm",
  "wmv",
  "swf",
  "rm"
];

styleDef.push([videoStyle, videoExtList]);

// image ////////////////////////////////////
const imageStyle = {
  labelColor: "#d18f00"
};

const imageExtList = ["png", "jpg", "jpeg", "gif", "bmp", "tif", "tiff"];

styleDef.push([imageStyle, imageExtList]);

// zip ////////////////////////////////////
const zipStyle = {
  labelColor        : "#f7e700",
  labelTextColor    : "#000",
  glyphColor        : "#de9400",
  type              : 'compressed',
};

const zipExtList = ["zip", "zipx", "7zip", "tar", "sitx", "gz", "rar"];

styleDef.push([zipStyle, zipExtList]);

// audio ////////////////////////////////////
const audioStyle = {
  labelColor  : "#b213d6",
  type        : "audio"
};

const audioExtList = [
  "aac",
  "aif",
  "aiff",
  "flac",
  "m4a",
  "mid",
  "mp3",
  "ogg",
  "wav"
];

styleDef.push([audioStyle, audioExtList]);

// text ////////////////////////////////////
const textStyle = {
  labelColor  : "#143c99"
};

const textExtList = [
  "cue",
  "odt",
  "md",
  "rtf",
  "txt",
  "tex",
  "wpd",
  "wps",
  "xlr",
  "fodt"
];

styleDef.push([textStyle, textExtList]);

// system ////////////////////////////////////
const systemStyle = {
  labelColor: "#111"
};

const systemExtList = ["exe", "ini", "dll", "plist", "sys"];

styleDef.push([systemStyle, systemExtList]);

// srcCode ////////////////////////////////////
const srcCodeStyle = {
  glyphColor: "#787878",
  labelColor: "#787878"
};

const srcCodeExtList = [
  "asp",
  "aspx",
  "c",
  "cpp",
  "cs",
  "css",
  "scss",
  "py",
  "json",
  "htm",
  "html",
  "java",
  "yml",
  "php",
  "js",
  "ts",
  "rb",
  "jsx",
  "tsx"
];

styleDef.push([srcCodeStyle, srcCodeExtList]);

// vector ////////////////////////////////////
const vectorStyle = {
  labelColor: "#ff8000"
};

const vectorExtList = ["dwg", "dxf", "ps", "svg", "eps"];

styleDef.push([vectorStyle, vectorExtList]);

// font ////////////////////////////////////
const fontStyle = {
  labelColor: "#555"
};

const fontExtList = ["fnt", "ttf", "otf", "fon", "eot", "woff"];

styleDef.push([fontStyle, fontExtList]);

// objectModel ////////////////////////////////////
const objectModelStyle = {
  labelColor: "#bf6a02",
  glyphColor: "#bf6a02"
};

const objectModelExtList = ["3dm", "3ds", "max", "obj", "pkg"];

styleDef.push([objectModelStyle, objectModelExtList]);

// sheet ////////////////////////////////////
const sheetStyle = {
  labelColor: "#2a6e00"
};

const sheetExtList = ["csv", "fods", "ods", "xlr"];

styleDef.push([sheetStyle, sheetExtList]);

//////////////////////////////////////////////////

// Style by extension /////////////////////////////////

const defaultStyle = {
  pdf: {
    glyphColor  : "white",
    color       : "#D93831",
    type        : 'acrobat',
  }
};

//////////////////////////////////////////////////

function createStyleObj(extList, styleObj) {
  return Object.fromEntries(
    extList.map((ext) => {
      return [ext, styleObj];
    })
  );
}

const styleDefObj = styleDef.reduce((acc, [fileStyle, fileExtList]) => {
  return { ...acc, ...createStyleObj(fileExtList, fileStyle) };
}, defaultStyle);

export const FileIcon = ({extension = undefined, size = 48, ...props}) => {

    const args = React.useMemo(() => styleDefObj[extension] || {}, [extension]);

    return (
        <FileIconSrc
            extension       = {extension}
            size            = {48}
            { ...args }
            { ...props }
        />
    )
}

export default FileIcon;
