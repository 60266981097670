/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
TransactionHistory Context
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       7th May 2021

*******************************************************************************************/
import React                    from 'react';
import moment                   from 'moment';
import {useLocale}              from './LocaleContext';
import {useNetwork}             from './NetworkContext';
import {debounce}               from '../functions';
import {
    useCancelToken,
    useStateEphemeral,
    useCanQuery
}                               from 'hooks';

// The Context Object
export const TransactionHistoryContext = React.createContext(undefined);

// Voltage Provider
export const TransactionHistoryProvider = ({children}) => {
    
    const {allowQueryForUser}                   = useCanQuery();
    const {axios, socketUsers:socket}           = useNetwork();
    const {formatCurrency,currencyFactor}       = useLocale();
    const {cancelToken, isCancel}               = useCancelToken();

    
    const [enabled,         setEnabled]         = React.useState(false);
    const [working,         setWorking]         = React.useState(false);
    const [isRetrieved,     setRetrieved]       = React.useState(false);
    const [queried,         setQueried]         = React.useState(undefined);
    const [history,         setHistory]         = React.useState([]);
    const [quantity,        setQuantity]        = React.useState(0);
    const [dateRange,       setDateRange]       = React.useState({
        start   : moment().startOf('month'),
        finish  : moment().endOf('month')
    });

    const [openingBalance,  setOpeningBalance]  = React.useState(0);
    const [credits,         setCredits]         = React.useState(0);
    const [creditsQuantity, setCreditsQuantity] = React.useState(0);
    const [debits,          setDebits]          = React.useState(0);
    const [debitsQuantity,  setDebitsQuantity]  = React.useState(0);
    const [closingBalance,  setClosingBalance]  = React.useState(0);

    // Messages
    const [messageSuccess,  setMessageSuccess]  = useStateEphemeral(undefined);
    const [messageError,    setMessageError]    = useStateEphemeral(undefined,5000);

    const clear = () => {
        setOpeningBalance(0);
        setHistory([]);
        setQueried(undefined);
    };

    const queryHistory = React.useCallback( () => new Promise( (resolve,reject) => {
        const [start,finish] = [dateRange.start,dateRange.finish].map(d => moment.isMoment(d)? d.valueOf() : d);
        setWorking(true);
        axios.get(`/api/user/account/history?start=${start || 0}&finish=${finish || Date.now()}`, {cancelToken})
            .then(({data}) => data)
            .then(data => {
                setMessageSuccess("Queried transaction history");
                return data;
            })
            .then(resolve)
            .catch(err => {
                if(isCancel(err)) return reject(err);
                setMessageError(err?.message  || "Error retrieving transaction history");
                reject(err);
            })
            .finally(() => {
                setRetrieved(true);
                setWorking(false);
                setQueried(moment());
            })
    }),[axios, cancelToken, dateRange.finish, dateRange.start, isCancel, setMessageError, setMessageSuccess]);

    const refresh   = React.useCallback((reset) => {
        if(enabled && allowQueryForUser){
            if(reset) clear();
            queryHistory()
                .then(({openingBalance,history}) => {
                    setOpeningBalance(openingBalance);
                    setHistory(history);
                })
                .catch(clear)
        }else{
            clear();
        }
    },[enabled, queryHistory, allowQueryForUser])

    React.useEffect(() => {
        let cr = 0, db = 0, crq = 0, dbq = 0;
        history.forEach(({data:{amount:a}, type}) => {
            let amount = type === 'charge' ? a : -a;
            cr  += amount >=    0 ? amount : 0;
            crq += amount >=    0 ? 1 : 0;
            db  += amount <     0 ? Math.abs(amount) : 0; // Positive Sum
            dbq += amount <     0 ? 1 : 0;
        });
        setCredits(cr);
        setCreditsQuantity(crq);
        setDebits(db);
        setDebitsQuantity(dbq);
        setQuantity(history.length);
    },[history,openingBalance])

    React.useEffect(()=>{
        setClosingBalance(openingBalance + credits - debits);
    },[openingBalance,credits,debits])

    // Query Balance
    React.useEffect(()=>{
        if(enabled && allowQueryForUser)
            refresh()
        else
            clear();
    }, [enabled, allowQueryForUser, refresh])

    // Refresh on SocketIO Instruction
    React.useEffect(()=>{
        if(enabled && allowQueryForUser && socket){
            socket.on('refresh_account', refresh)
            return () => {
                socket.off('refresh_account', refresh);
            }
        }
    },[allowQueryForUser, socket, enabled, refresh])

    // Formatted Balance
    const [
        openingBalanceFormatted, 
        creditsFormatted, 
        debitsFormatted, 
        closingBalanceFormatted
    ] = [openingBalance, credits, debits, closingBalance].map(value => formatCurrency(value/currencyFactor));

    // Has Data
    const hasData = quantity > 0;   

    // Context values
    const value = {
        history,
        quantity, 
        hasData,
        setEnabled,
        messageSuccess,
        setMessageSuccess,
        messageError,
        setMessageError,
        openingBalance, openingBalanceFormatted,
        credits,        creditsFormatted,
        creditsQuantity,
        debits,         debitsFormatted,
        debitsQuantity,
        closingBalance, closingBalanceFormatted,
        dateRange,
        setDateRange,
        queried,
        isRetrieved,
        refresh : debounce(refresh),
        working
    };

    return (
        <TransactionHistoryContext.Provider value={value}>
            {children}
        </TransactionHistoryContext.Provider>
    )
}

export const TransactionHistoryConsumer =  ({children}) => {
    return (
        <TransactionHistoryContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('TransactionHistoryConsumer must be used within TransactionHistoryProvider');
                }
                return children(context)
            }}
        </TransactionHistoryContext.Consumer>
    )
}

// useVoltage Hook
export const useTransactionHistory = () => {
    const context = React.useContext(TransactionHistoryContext);
    if(context === undefined)
        throw new Error('useTransactionHistory must be used within TransactionHistoryProvider');
    return context;
}