/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Cart Order Sheet
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       19th July 2021

*******************************************************************************************/
import React                            from 'react';
import { 
    useTheme, 
    Box, 
    Table, 
    TableBody, 
    TableContainer, 
    TableHead 
}                                       from '@mui/material';
import { 
    Title, 
    ItemPaper,
    OrderTableHead,
    OrderTableEmptyRow,
    OrderTableRow,
    OrderTableTotal
}                                       from 'components';
import { withTranslation }              from './hoc';
import { useCart }                      from 'contexts/CartContext';

// Shopping Cart
export const CartOrderSheet = withTranslation( ({t}) => {
    
    const theme             = useTheme();
    const {
        cart,
        cartValue,
        cartTaxes,
        cartTotal,
    }                       = useCart();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const items             = React.useMemo(() => [...cart].filter(x => x.__t === 'PRODUCT'), [JSON.stringify(cart)]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const adjustments       = React.useMemo(() => [...cart].filter(x => x.__t === 'COUPON'), [JSON.stringify(cart)]);
    const adjustmentQty     = React.useMemo(() => adjustments.reduce((a,c) => a + (c.split || []).length,0), [adjustments]);

    let adjustmentIndex = 0;
    
    return (
        <ItemPaper>
            <TableContainer>
                <Title>
                    {t('components.cartOrderSheet.orderSheet')}
                </Title>
                <Table>
                    <TableHead>
                        <OrderTableHead />
                    </TableHead>
                    <TableBody>
                        <OrderTableEmptyRow 
                            name                = {t('components.cartOrderSheet.itemsQuantity', {quantity: items.length})}
                            indentLevel         = {0}
                        /> 
                        {
                            items
                                .sort((a,b) => (b?.price - a?.price))
                                .map((item,ixA) => {
                                    return item.split.map(({quantity, price, subtotal, taxes, total, description, metadata = {}},ixB) => {
                                        let rowNumber = ixB === 0 ? ((ixA+1) + '. ') : null
                                        return (
                                            <OrderTableRow 
                                                key                 = {ixA + '.' + ixB}
                                                name                = {
                                                    <Box display={'flex'}>
                                                        <Box flexShrink={1} pr={1} style={{minWidth:25}}>
                                                            {rowNumber}
                                                        </Box>
                                                        <Box flexGrow={1}>
                                                            <Box>
                                                                '{item?.product?.name}' - {description}
                                                            </Box>
                                                            {
                                                                metadata.error && 
                                                                <Box style={{color:theme.palette.error.main}}>
                                                                    {t('components.cartOrderSheet.noteWell')}: {metadata.error}
                                                                </Box>
                                                            }
                                                        </Box>
                                                    </Box>
                                                } 
                                                requiresShipping    = {item?.product?.requiresShipping}
                                                taxCode             = {item?.taxCode}
                                                taxRate             = {item?.taxRate}

                                                quantity            = {quantity}
                                                unitPrice           = {price}
                                                price               = {subtotal}
                                                taxes               = {taxes}
                                                total               = {total}
                                                indentLevel         = {1}
                                            /> 
                                        )
                                    })
                                })
                        }

                        {
                            adjustmentQty >= 0 && 
                            <OrderTableTotal 
                                name        = {t('components.cartOrderSheet.totalItems')}
                                indentLevel = {0} 
                                isSubtotal  = {true} 
                                totalEx     = {items.reduce((a,c) => a + c.subtotal,0)} 
                                taxes       = {items.reduce((a,c) => a + c.taxes,   0)} 
                                total       = {items.reduce((a,c) => a + c.total,   0)} 
                            />
                        }
                        {
                            adjustmentQty > 0 && 
                            <React.Fragment>
                                <OrderTableEmptyRow 
                                    name                = {t('components.cartOrderSheet.couponQuantity', {quantity : adjustmentQty } )}
                                    indentLevel         = {0}
                                /> 
                                {
                                    (adjustments || []).map(({split = [],...rest}, ixA) => {
                                        return split.map(({quantity, price, subtotal, taxes, total, description, metadata = {}},ixB) => {
                                            adjustmentIndex = adjustmentIndex + 1;
                                            let name = description || t('components.cartOrderSheet.couponIndex', { index : adjustmentIndex })
                                            return (
                                                <OrderTableRow 
                                                    key         = {`${ixA + 1}.${ixB + 1}`} 
                                                    name        = {
                                                        <Box display={'flex'}>
                                                            <Box flexShrink={1} pr={1} style={{minWidth:25}}>
                                                                {adjustmentIndex}.
                                                            </Box>
                                                            <Box flexGrow={1}>
                                                                <Box>
                                                                    {rest.description} - {name}
                                                                </Box>
                                                                {
                                                                    metadata.error && 
                                                                    <Box style={{color:theme.palette.error.main}}>
                                                                        {t('components.cartOrderSheet.noteWell')}: {metadata.error}
                                                                    </Box>
                                                                }
                                                            </Box>
                                                        </Box>
                                                    } 
                                                    taxCode     = {rest?.taxCode}
                                                    taxRate     = {rest?.taxRate}

                                                    quantity    = {quantity ? quantity  : undefined}
                                                    unitPrice   = {quantity ? price     : undefined}
                                                    price       = {subtotal} 
                                                    taxes       = {taxes} 
                                                    total       = {total}
                                                    isSubtotal  = {true} 
                                                    indentLevel = {1} 
                                                />
                                            )
                                        })
                                    })
                                }
                                <OrderTableTotal 
                                    name        = {t('components.cartOrderSheet.totalCoupon')}
                                    indentLevel = {0} 
                                    isSubtotal  = {true} 
                                    totalEx     = {adjustments.reduce((a,c) => a + (c.split || []).reduce((a,c) => a + c.subtotal,0),0)} 
                                    taxes       = {adjustments.reduce((a,c) => a + (c.split || []).reduce((a,c) => a + c.taxes,0),0)} 
                                    total       = {adjustments.reduce((a,c) => a + (c.split || []).reduce((a,c) => a + c.total,0),0)} 
                                />
                            </React.Fragment>
                        }

                        <OrderTableTotal 
                            name        = {t('components.cartOrderSheet.total')}
                            indentLevel = {0} 
                            isSubtotal  = {true} 
                            totalEx     = {cartValue} 
                            taxes       = {cartTaxes} 
                            total       = {cartTotal} 
                            lastRow     = {true}
                        />
                        
                    </TableBody>
                </Table>
            </TableContainer>
        </ItemPaper>
    )
});

export default CartOrderSheet;