/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Confirmation Button
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       30th June 2021

*******************************************************************************************/
import React                    from 'react';
import { 
    styled,
    Box,
    Button as ButtonMUI
}                               from '@mui/material';
import { useTranslation }       from 'contexts';
import { withDisabledOffline }  from './hoc';

const ButtonBase = styled(ButtonMUI,{
    shouldForwardProp : prop => prop !== 'clicked'
})(({theme, clicked = false}) => ({
    ...(clicked && {
        color       : [theme.palette.error.contrastText,'!important'],
        background  : theme.palette.error.main,
        "&:hover": {
            background  : theme.palette.error.dark,
        }
    }),
    ...(!clicked && {

    }),
})) 

const Progress = styled("span")(({theme}) => ({
    top         : 1,
    left        : 1,
    bottom      : 1,
    zIndex      : 1,
    overflow    : 'hidden',
    position    : 'absolute',
    background  : theme.palette.text.secondary,
    opacity     : 0.4
})) 

const Button = withDisabledOffline(ButtonBase);


const noop = () => {}

export const ConfirmationButton = ({
    children, 
    variant                     = "text",
    confirmationText            = undefined,
    onClick                     = noop,
    onConfirmationRequired  : handleConfirmationRequired = noop,
    duration                    = 5000,
    updateFrequency             = 10,
    decimals                    = 0,
    requireConfirmation         = true,
    ProgressProps               = {},
    ...props
}) => {
    
    const [t]                               = useTranslation();

    // Two-step clicked state
    const [clicked,     setClicked]         = React.useState(false);

    // The elapsed time
    const [elapsed,     setElapsed]         = React.useState(0);

    // The remainiing seconds
    const [remainingSecs, setRemainingSecs] = React.useState(duration/1000.0)
    
    // Calculate the remaining seconds
    const calcRemainingSeconds              = React.useCallback( () => {
        return Math.max((duration - elapsed)/1000.0,0);
    }, [duration, elapsed]);
    
    // Two-step click process
    const handleClick = (event) => {
        if(requireConfirmation){
            if(!clicked) 
                return setClicked(true);
            setClicked(false);
        }
        onClick(event);
    }

    // When clicked state changes, handle confirmation required
    React.useEffect(() => {
        handleConfirmationRequired(clicked);
        return () => {
            handleConfirmationRequired(false);
        }
    },[clicked, handleConfirmationRequired])

    // Handle Excape Key Press, Cancel Confirmation Timer.
    React.useEffect(() => {
        const escFunction = (event) => {
            if (event.key === "Escape") {
                setClicked(false);
            }
        }
        document.addEventListener("keydown", escFunction);
        return () => {
            document.removeEventListener("keydown", escFunction);
        }
    },[])

    // If clicked changes to true, countdown
    React.useEffect(()=>{
        if(isNaN(duration) || duration <= 0)
            return;

        if(clicked){
            const now = new Date().getTime();
            const interval = setInterval(()=>{
                const done = new Date().getTime() - now;
                setElapsed(done);
                if(done >= duration){
                    setClicked(false);
                    clearInterval(interval);
                }
            },updateFrequency)
            return ()=>{
                clearInterval(interval);
            }
        }
        
    },[clicked, updateFrequency, duration])

    // If not clicked, set elapsed to zero
    React.useEffect(() => {
        if(!clicked) setElapsed(0);
    },[clicked])

    // Set the remaining
    React.useEffect(() => setRemainingSecs(calcRemainingSeconds()), [calcRemainingSeconds])

    // Show span or not
    const showSpan = React.useMemo(() => !isNaN(duration) && duration > 0, [duration]); // Valid number

    // Render
    return (
        <Button 
            id          = "confirmationButton"
            variant     = {clicked && variant === 'text' ? 'contained' : variant}
            clicked     = {clicked}
            onClick     = {handleClick}
            color       = "error"
            {...props}
        >
            <Box sx={{pr:0.5}}>
                {
                    clicked 
                        ? `${confirmationText || t("common.clickToConfirm")} ${(remainingSecs).toFixed(decimals)}` 
                        : children
                }
            </Box>
            {
                showSpan && 
                <Progress
                    {...ProgressProps} 
                    style       = {{
                        ...ProgressProps.style,
                        width : `${(100 * elapsed/duration)}%`
                    }}
                />
            }
        </Button>
    )
}

export default ConfirmationButton;