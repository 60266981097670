/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Menu Paper
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       13th August 2022

*******************************************************************************************/
import React                            from 'react';
import { styled, Paper as PaperMUI}     from '@mui/material';

const Paper = styled(PaperMUI,{
    shouldForwardProp : prop => prop !== 'spacing'
})(({theme, spacing = 2}) => ({
    display                     : "flex",
    flexDirection               : "column",
    height                      : "100%",
    width                       : "100%",
    overflow                    : "hidden",
    padding                     : theme.spacing(spacing),
    borderBottomLeftRadius      : theme.spacing(spacing),
    borderTopRightRadius        : theme.spacing(spacing),
    '& > * + *' : {
        marginTop : theme.spacing(spacing),
    },
}))

export const MenuPaper = ({spacing = 2, ...props}) => <Paper elevation={4} spacing={spacing} {...props}/>

export default MenuPaper;