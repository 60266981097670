
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
MasterLibrary Context
********************************************************************************************
Master Library Context

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       3rd September 2021

*******************************************************************************************/
import React                            from 'react';
import moment                           from 'moment';
import { 
    useStateEphemeral, 
    useCancelToken,
    useCanQuery
}                                       from '../hooks';
import { 
    useNetwork,
    useAngle,
    useCelestialBody,
    useCelestialPoint,
    useSign,
    useHouse,
    useAspect,
    useAlert
}                                       from '../contexts';


const BASE_API_URL = '/api/admin/library/master';

// The Product Context 
const MasterLibraryContext = React.createContext(undefined);

const PER_PAGE_DEFAULT  = 10;
const PAGE_DEFAULT      = 0;
const DEFAULT_FILTER    = {
    __t             : [],
    product         : [],
    processor       : [],
    celestialBody   : [],
    celestialPoint  : [],
    angle           : [],
    sign            : [],
    house           : [],
    aspect          : []
}

// MasterLibrary Provider
const MasterLibraryProvider = ({children}) => {
    const {allowQueryForAdmin}                  = useCanQuery();
    const {
        axios, 
        socketUsers : socket
    }                                           = useNetwork();
    const {cancelToken, isCancel}               = useCancelToken();
    const angles                                = useAngle();
    const celestialBody                         = useCelestialBody();
    const celestialPoint                        = useCelestialPoint();
    const sign                                  = useSign();
    const house                                 = useHouse();
    const aspect                                = useAspect();
    const {alert}                               = useAlert();
    const [stats,       setStats]               = React.useState({
        // ...rest
        pcntComplete    : 0
    });
    const [statsLoading, setStatsLoading]       = React.useState(false);
    const [enabledTypes,setEnabledTypes]        = React.useState(false);
    const [enabled,     setEnabled]             = React.useState(false);
    const [loading,     setLoading]             = React.useState(false);
    const [queried,     setQueried]             = React.useState(undefined);
    const [data,        setData]                = React.useState([]);
    const [pagination,  setPagination]          = React.useState({page : PAGE_DEFAULT, perPage : PER_PAGE_DEFAULT})
    const [count,       setCount]               = React.useState(0);
    const [types,       setTypes]               = React.useState([]);
    const [filter,      setFilter]              = React.useState(DEFAULT_FILTER);
    const [search,      setSearch]              = React.useState(undefined);
    const resetFilter                           = React.useCallback( () => setFilter(DEFAULT_FILTER), [])
    const resetPagination                       = React.useCallback( () => setPagination(prev => ({...prev, page : PAGE_DEFAULT})), []);

    // Messages
    const [messageSuccess,  setMessageSuccess]  = useStateEphemeral(undefined);
    const [messageError,    setMessageError]    = useStateEphemeral(undefined,5000);

    const getTypes = React.useCallback( () => new Promise((resolve,reject) => {
        axios
            .get(`${BASE_API_URL}/types`, {cancelToken})
            .then(({data}) => data)
            .then(resolve)
            .catch(err => {
                if (isCancel(err)) return resolve([]);              // NOTE USE
                reject(err);
            })
    }),[axios, cancelToken, isCancel]);

    // Reset Types
    const resetTypes = () => setTypes([]);

    // Set Page to 0
    React.useEffect(resetPagination,[filter, resetPagination]);

    // Set Pagination Values
    const setPaginationParameters = React.useCallback((page=0, perPage=100) => {
        let newValue = (page >= 0 && perPage >= 0) 
            ? {page, perPage} 
            : {page: PAGE_DEFAULT, perPage: PER_PAGE_DEFAULT};
        if(newValue.page !== pagination.page || newValue.perPage !== pagination.perPage)
            setPagination(newValue);
    },[pagination.page, pagination.perPage]);

    React.useEffect(() => {
        setEnabledTypes(enabled);
    },[enabled])

    // Get Types
    React.useEffect(()=>{
        if(enabledTypes && allowQueryForAdmin)
            getTypes().then(setTypes).catch(resetTypes);
        else
            resetTypes();
    },[enabledTypes, getTypes, allowQueryForAdmin]);

    // Get the data from the server
    const get = React.useCallback( () => new Promise((resolve,reject) => {
        setLoading(true);
        axios.post(`${BASE_API_URL}?page=${pagination.page}&perPage=${pagination.perPage}&search=${search}`, {filter, search}, {cancelToken})
            .then(({data}) => data)
            .then(({documents,totalCount}) => {
                setMessageSuccess("Queried master library");
                return {
                    documents, totalCount
                }
            })
            .then(resolve)
            .catch(reject)
            .finally(()=>{
                setQueried(moment())
                setLoading(false);
            })
    }),[axios, pagination.page, pagination.perPage, filter, search, cancelToken, setMessageSuccess]);

    const getById = React.useCallback((id, populate=true) => new Promise((resolve,reject) => {
        if(!allowQueryForAdmin) return reject(new Error('unauthorized'));
        if(!id)                 return reject(new Error('id is required'));
        setLoading(true);
        axios.get(`${BASE_API_URL}/${id}?populate=${populate}`, {cancelToken})
            .then(({data}) => data)
            .then(resolve)
            .catch(reject)
            .finally(() => {
                setLoading(false);
            })
    }),[allowQueryForAdmin, axios, cancelToken]);

    const getStats = React.useCallback(() => new Promise((resolve,reject) => {
        if(!allowQueryForAdmin) return reject(new Error('unauthorized'));
        setStatsLoading(true);
        axios.get(`${BASE_API_URL}/stats`, {cancelToken})
            .then(({data}) => data)
            .then(resolve)
            .catch(reject)
            .finally(() => {
                setStatsLoading(false);
            })
    }),[allowQueryForAdmin, axios, cancelToken]);

    const postById = React.useCallback((id, data = {}) => new Promise((resolve,reject) => {
        if(!allowQueryForAdmin) return reject(new Error('unauthorized'));
        if(!id)                 return reject(new Error('id is required'));
        setLoading(true);
        axios.post(`${BASE_API_URL}/${id}`, data, {cancelToken})
            .then(({data}) => data)
            .then(resolve)
            .catch(reject)
            .finally(() => {
                setLoading(false);
            })
    }),[allowQueryForAdmin, axios, cancelToken]);

    const postTranscribeById = React.useCallback((id) => new Promise((resolve,reject) => {
        if(!allowQueryForAdmin) return reject(new Error('unauthorized'));
        if(!id)                 return reject(new Error('id is required'));
        setLoading(true);
        axios.post(`${BASE_API_URL}/${id}/transcribe`, {}, {cancelToken})
            .then(({data}) => data)
            .then(resolve)
            .catch(reject)
            .finally(() => {
                setLoading(false);
            })
    }),[allowQueryForAdmin, axios, cancelToken]);

    // Update
    const updateMasterLibrary = React.useCallback(({_id,...rest}) => new Promise((resolve,reject) => {
        postById(_id,rest)
            .then(data => {
                setMessageSuccess("Master Library Record Updated");
                resolve(data);
            })
            .catch(err => {
                if (isCancel(err)) return reject(err);
                setMessageError(err?.message  || "Error Updating Master Library");
                reject(err);
            })
    }), [isCancel, postById, setMessageError, setMessageSuccess]);

    // Update
    const transcribeMasterLibrary = React.useCallback(({_id}) => new Promise((resolve,reject) => {
        postTranscribeById(_id )
            .then(data => {
                setMessageSuccess("Master Library Transcription Dispatched");
                resolve(data);
            })
            .catch(err => {
                if (isCancel(err)) return reject(err);
                setMessageError(err?.message  || "Error Transcribing Master Library");
                reject(err);
            })
    }), [isCancel, postTranscribeById, setMessageError, setMessageSuccess]);

    // Clear
    const clear = () => setData([]); 

    // Refresh data from server
    const refresh   = React.useCallback( (reset) => {
        if(enabled && allowQueryForAdmin){
            if(reset) clear();
            get()
                .then(({documents,totalCount}) => {
                    setMessageSuccess("Queried master library");
                    setData(documents);
                    setCount(totalCount);
                })
                .catch((err) => {
                    if (isCancel(err)) return
                    setMessageError(err?.message  || "Error retrieving master library");
                    clear();
                })
        }else{
            clear();
        }
    },[enabled, allowQueryForAdmin, get, setMessageSuccess, isCancel, setMessageError]) 

    // Query Master Library
    React.useEffect(refresh, [refresh]);

    const refreshStats = React.useCallback(() => {
        if(enabled && allowQueryForAdmin){
            getStats()
                .then(data => {
                    setStats(prev => ({...prev,...data}))
                })
                .catch(err => {
                    //
                })
        }
    }, [allowQueryForAdmin, enabled, getStats])

    // Query Master Library
    React.useEffect(refreshStats, [refreshStats]);

    // Refresh on SocketIO Instruction
    React.useEffect(() => {
        if(allowQueryForAdmin && enabled && socket){
            socket.on('refresh_master_library', refresh)
            socket.on('refresh_master_library', refreshStats)
            return () => {
                socket.off('refresh_master_library', refresh);
                socket.off('refresh_master_library', refreshStats);
            }
        }
    },[enabled, allowQueryForAdmin, refresh, socket, refreshStats])

    // Listen to event for transcription
    React.useEffect(() => {
        if(socket){
            const handleTranscription = ({masterLibraryId, fileId, transcribeId}) => {

                // No ID provided
                if(!masterLibraryId) 
                    return;

                // Not in current set
                if(data.findIndex(d => d._id === masterLibraryId) < 0) 
                    return;
                
                // Update for ID
                getById(masterLibraryId)
                    .then((replace) => {
                        setData(
                            data => (
                                data.map( ex => ex._id === masterLibraryId ? replace : ex )
                            )
                        )
                    })
                    .catch(console.error)
            }

            const handleTranscribing = ({masterLibraryId, fileId}) => {
                alert(`Transcribing ${masterLibraryId}, File: ${fileId}`,'info');
            }
            const handleTranscriptionError = ({masterLibraryId, fileId, message}) => {
                alert(`Transcription Error ${masterLibraryId}, File: ${fileId || "N/A"} - ${message}`,'error');
            }
            
            socket.on('master_library_transcribing',            handleTranscribing);
            socket.on('master_library_transcribed',             handleTranscription);
            socket.on('master_library_transcription_error',     handleTranscriptionError);
            return () => {
                socket.off('master_library_transcribing',           handleTranscribing);
                socket.off('master_library_transcribed',            handleTranscription);
                socket.off('master_library_transcription_error',    handleTranscriptionError);
            }
        }
    }, [alert, data, getById, socket])

    // Turn on all the features
    React.useEffect(() => {
        [angles,aspect,celestialBody,celestialPoint,sign,house,aspect].forEach((ctx) => (
            ctx.setEnabled(true)
        )) 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    // Context values
    const value = React.useMemo(() => ({
        loading,
        queried,
        get,
        getById,
        postById,
        data,
        enabled,
        setEnabled,
        setEnabledTypes,
        messageSuccess,
        messageError,
        refresh,
        setPaginationParameters,
        updateMasterLibrary,
        transcribeMasterLibrary,
        types,
        pagination,
        count,
        filter,
        setFilter,
        search,
        setSearch,
        resetFilter,
        DEFAULT_FILTER,
        stats,
        statsLoading,
        refreshStats
    }), [count, data, enabled, filter, get, getById, loading, messageError, messageSuccess, pagination, postById, queried, refresh, refreshStats, resetFilter, search, setPaginationParameters, stats, statsLoading, types, updateMasterLibrary, transcribeMasterLibrary]);

    return (
        <MasterLibraryContext.Provider value={value}>
            {children}
        </MasterLibraryContext.Provider>
    )
}

// MasterLibrary Consumer
const MasterLibraryConsumer = ({children}) => {
    return (
        <MasterLibraryContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('MasterLibraryConsumer must be used within MasterLibraryProvider');
                }
                return children(context)
            }}
        </MasterLibraryContext.Consumer>
    )
}

// useMasterLibrary Hook
const useMasterLibrary = () => {
    const context = React.useContext(MasterLibraryContext);
    if(context === undefined)
        throw new Error('useMasterLibrary must be used within MasterLibraryProvider');
    return context;
}

export {
    MasterLibraryProvider,
    MasterLibraryConsumer,
    useMasterLibrary,
}