/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Draggable Dialog
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       24th December 2020

*******************************************************************************************/

import React                            from 'react';
import { Typography }                   from '@mui/material';
import { DraggableDialog }              from 'components';
import { withTranslation }              from 'components/hoc';

const noop      = () => {};
const noopok    = (position) => {};

export const Confirmation = withTranslation( ({
    t,
    children,
    title                       = undefined,
    open                        = false,
    onOk        : handleOk      = noopok,
    onCancel    : handleClose   = noop,
    ...rest
}) => (
    <DraggableDialog
        title           = {title || t('components.modals.confirmation.confirmationRequired')}
        open            = {open}
        onClose         = {handleClose}
        onOk            = {handleOk}
        okenabled       = {true}
        oklabel         = {t('components.modals.confirmation.yes')}
        cancellabel     = {t('components.modals.confirmation.no')}
        {...rest}
    >
        { 
            children || 
            <Typography>
                {t('components.modals.confirmation.confirmYesNo')}
            </Typography> 
        }
    </DraggableDialog>
))

export default Confirmation;