/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Social Accounts
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       12th May 2022

*******************************************************************************************/
import React                            from 'react';
import { styled, Box, IconButton }      from '@mui/material';
import { SocialIcon }                   from 'react-social-icons';

const ButtonsContainer = styled(Box)(({theme}) => ({
    marginTop       : theme.spacing(2),
    marginBottom    : theme.spacing(2),
    '& > *' : {
        margin  : theme.spacing(0.15)
    }
}));

const   SOCIAL_FACEBOOK     = "cosmicprogramdotcom",
        SOCIAL_INSTAGRAM    = "cosmicprogramdotcom",
        SOCIAL_TWITTER      = "CosmicProgram",
        SOCIAL_PINTEREST    = "cosmicprogram",
        SOCIAL_YOUTUBE      = "@cosmicprogram",
        SOCIAL_TIKTOK       = "@cosmicprogram",
        SOCIAL_MEDIUM       = "@cosmicprogram.com"

export const SocialAccounts = ({iconSize=32}) => {
    const args      = React.useMemo(() => ({fgColor:"white", style:{ height: iconSize, width: iconSize}}), [iconSize]);
    const navigate  = React.useCallback( url => () => window.open(url, "_blank" /*, "noreferrer"*/ ),[]);
    return (         
        <ButtonsContainer>
            <IconButton onClick={navigate(`https://twitter.com/${SOCIAL_TWITTER}`)}>
                <SocialIcon {...args} network="twitter" />
            </IconButton>
            <IconButton onClick={navigate(`https://www.facebook.com/${SOCIAL_FACEBOOK}`)}>
                <SocialIcon {...args} network="facebook" />
            </IconButton>
            <IconButton onClick={navigate(`https://www.instagram.com/${SOCIAL_INSTAGRAM}`)}>
                <SocialIcon {...args} network="instagram" />
            </IconButton>
            <IconButton onClick={navigate(`https://pinterest.com/${SOCIAL_PINTEREST}`)}>
                <SocialIcon {...args} network="pinterest" />
            </IconButton>
            <IconButton onClick={navigate(`https://medium.com/${SOCIAL_MEDIUM}`)}>
                <SocialIcon {...args} network="medium"/>
            </IconButton>
            <IconButton onClick={navigate(`https://youtube.com/${SOCIAL_YOUTUBE}`)}>
                <SocialIcon {...args} network="youtube" />
            </IconButton>
            <IconButton onClick={navigate(`https://www.tiktok.com/${SOCIAL_TIKTOK}`)}>
                <SocialIcon {...args} network="tiktok"/>
            </IconButton>
        </ButtonsContainer>
    )
};

export default SocialAccounts;