/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Accordion Components
********************************************************************************************
https://v4.mui.com/components/accordion/

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       15th February 2022

*******************************************************************************************/
import React                            from 'react';
import {
    styled,
    darken,
    lighten,
    Accordion           as AccordionMUI,
    AccordionSummary    as AccordionSummaryMUI,
    AccordionDetails    as AccordionDetailsMUI,
}                                       from '@mui/material';
import { NavigateNext as Icon }         from '@mui/icons-material';

export const Accordion = styled(AccordionMUI)(({theme}) => ({
    background : 'inherit',
    '&.MuiAccordion-root' : {
        boxShadow   : 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&.Mui-expanded': {
            margin          : 'auto',
            marginBottom    : theme.spacing(3),
        },
    }
}))

const AccordionSummaryBase = styled(AccordionSummaryMUI,{
    shouldForwardProp : prop => prop !== 'sticky' && prop !== 'zIndex' && prop !== 'background' && prop !== 'first' && prop !== 'last' && prop !== 'expanded'
})(({theme, sticky = false, zIndex = 2, background = undefined, first = false, last = false, expanded = false}) => {
    background          = background || theme.palette.background.paper
    let backgroundHover = theme.palette.mode === 'dark' ? darken(theme.palette.warning.light,0.25) : lighten(theme.palette.warning.light,0.750)
    return {
        "&.MuiAccordionSummary-root" : {
            background      : background,
            borderBottom    : `1px solid ${theme.palette.divider}`,
            height          : 'fit-content',
            minHeight       : theme.spacing(6),
            '&:hover' : {
                background : backgroundHover,
            },
            '&.Mui-expanded' : {
                background  : lighten(background,0.10),
                height      : 'fit-content!important',
                // height      : 0,
                // minHeight   : theme.spacing(6),
                '&:hover' : {
                    background  : backgroundHover
                },
            },
        },
        ...(first && {
            borderTopLeftRadius     : theme.spacing(1),
            borderTopRightRadius    : theme.spacing(1),
        }),
        ...(last && !expanded && {
            borderBottomLeftRadius     : theme.spacing(1),
            borderBottomRightRadius    : theme.spacing(1),
        }),
        ...(sticky && {
            "&.Mui-expanded" : {
                borderTopLeftRadius     : theme.spacing(0),
                borderTopRightRadius    : theme.spacing(0),
                overflow    : 'clip',
                position    : 'sticky',
                top         : -1,
                zIndex      : zIndex,
                // background  : `${theme.palette.info.main}!important`
            }
        }),
        ".MuiAccordionSummary-expandIconWrapper" : {
            transform   : 'rotate(0deg)!important',
            '&.Mui-expanded': {
                transform   : 'rotate(90deg)!important'
            }
        },
        ".MuiAccordionSummary-content" : {
            '&.Mui-expanded': {
                margin: `${theme.spacing(2)} 0`,
            }
        }
    }
})

export const AccordionSummary = (props) => (
    <AccordionSummaryBase expandIcon = {<Icon />} {...props} />
);


export const AccordionDetails = styled(AccordionDetailsMUI,{
    shouldForwardProp : prop => prop !== 'spacing'
})(({theme, spacing = 2}) => ({
    marginLeft  : theme.spacing(2),
    marginRight : theme.spacing(2),
    boxShadow: '4.0px 8.0px 8.0px hsl(0deg 0% 0% / 0.30)',
    // boxShadow: '5.0px 5.0px 10.0px',
    // boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
    "&.MuiAccordionDetails-root" : {
        background              : darken(theme.palette.background.paper,0.05),
        padding                 : theme.spacing(spacing),
        // borderBottom            : `2px solid ${theme.palette.divider}`,
        borderBottom            : `1px solid ${theme.palette.divider}`,
        borderLeft              : `1px solid ${theme.palette.divider}`,
        borderRight             : `1px solid ${theme.palette.divider}`,
        borderBottomLeftRadius  : theme.spacing(1),
        borderBottomRightRadius : theme.spacing(1),
        overflow                : 'hidden'
        // height                  : 'fit-content'
    }
}))

export default Accordion;