/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Context Wrapper
********************************************************************************************
Builds the tree of contexts from and array of contexts that should be used in sequence

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       7th May 2021

*******************************************************************************************/
import React                          from 'react';

// Contexts in Alphabetical Order
import {APIHealthProvider}            from './APIHealthContext';
import {AddressProvider}              from './AddressContext';
import {AngleProvider}                from './AngleContext';
import {AspectProvider}               from './AspectContext';
import {AutoThemeProvider}            from './AutoThemeContext';
import {CartProvider}                 from './CartContext';
import {CelestialBodyProvider}        from './CelestialBodyContext';
import {CelestialPointProvider}       from './CelestialPointContext';
import {CouponProvider}               from './CouponContext';
// import {CurrentLocationProvider}      from './CurrentLocationContext';
import {EmailSubscriptionProvider}    from './EmailSubscriptionContext';
import {HouseProvider}                from './HouseContext';
import {LibraryProvider}              from './LibraryContext';
// import {LibraryViewerProvider}        from './LibraryViewerContext';
/*

import {LibrarySideBarProvider}       from './LibrarySideBarContext';
*/
import {LocaleProvider}               from './LocaleContext';
// import {MasterLibraryProvider}        from './MasterLibraryContext';
import {NatalDataProvider}            from './NatalDataContext';
import {NetworkProvider}              from './NetworkContext';
import {NotificationProvider}         from './NotificationContext';
import {OrdersProvider}               from './OrdersContext';
import {PaymentMethodProvider}        from './PaymentMethodContext';
import {PaymentProvider}              from './PaymentContext';
import {ProductProvider}              from './ProductContext';
import {SignProvider}                 from './SignContext';
import {SocketIOProvider}             from './SocketIOContext';
import {TransactionHistoryProvider}   from './TransactionHistoryContext';
import {TranslationProvider}          from './TranslationContext';
import {UserProvider}                 from './UserContext';
import {TicketProvider}               from './TicketContext';
import {ExamplesProvider}             from './ExamplesContext';
import {ModalSpacingProvider}         from './ModalSpacingContext';
import {OrderViewerProvider}          from './OrderViewerContext';
import {ServerAlertProvider}          from './ServerAlertContext';
import {PageHeightProvider}           from './PageHeightContext';
import {HeaderProvider}               from './HeaderContext';
import {EmailAdminProvider}           from './EmailAdminContext';
import {CommentProvider}              from './CommentContext';
import {PushProvider}                 from './PushContext';
import {AlertProvider}                from './AlertContext';
import {ContentfulProvider}           from './ContentfulContext';
// import { CommentsThreadProvider }     from './CommentsThreadContext';
import { AccountCreditsProvider }     from './AccountCreditsContext';
// import { OstrioProvider  }            from './OstrioContext';
import { LaunchProvider }             from './LaunchContext';

// ContextWrapper
export const ContextWrapper = ({children}) => {
  
  const contexts = [
    // Top of Tree (Furthest from children)
    // OstrioProvider,
    PageHeightProvider,                       // DONE
    AlertProvider,                            // DONE
    HeaderProvider,                           // DONE

    // Server Reachable
    APIHealthProvider,                        // DONE

    // User Current Location
    // CurrentLocationProvider,               // DONE
    // Translations
    TranslationProvider,                      // DONE
  
    // User & Authentication
    UserProvider,                             // DONE

    // Contentful
    ContentfulProvider,                       // DONE

    // Launch Provider
    LaunchProvider,                           // DONE

    // SocketIO connections
    SocketIOProvider,                         // DONE

    // Overall Network Summary
    NetworkProvider,                          // DONE <<<< NETWORK HERE

    // Server Alerts
    ServerAlertProvider,

    // Info on user locale
    LocaleProvider,

    // User Natal Data
    NatalDataProvider,

    // User Addresses
    AddressProvider,

    // User Payment Methods
    PaymentMethodProvider,

    // User Transaction History
    TransactionHistoryProvider,

    // Account Credits
    AccountCreditsProvider,                   // DONE

    // Houses
    HouseProvider,

    // Aspects
    AspectProvider,

    // Celestial Bodies
    CelestialBodyProvider,

    // Celestial Points
    CelestialPointProvider,

    // Angles
    AngleProvider,

    // Signs
    SignProvider,

    // Master Library
    // MasterLibraryProvider,
    // Comments Thread
    // CommentsThreadProvider,

    // Products
    ProductProvider,

    // Coupons
    CouponProvider,

    // User Orders
    OrdersProvider,

    // Order Viewer
    OrderViewerProvider,

    // User Library
    LibraryProvider,
    // Library Viewer
    // LibraryViewerProvider,
    // LibrarySideBarProvider,

    // Shopping Cart
    CartProvider,

    // Payments
    PaymentProvider,

    // User Notifications
    NotificationProvider,

    // User Email Subscriptions
    EmailSubscriptionProvider,

    // Automatic Dark/Light Theme
    AutoThemeProvider,

    // Ticket Provider
    TicketProvider,

    // Examples
    ExamplesProvider,

    // Modal Spacing
    ModalSpacingProvider,

    // Admin Email
    EmailAdminProvider,

    // Comment Context
    CommentProvider,

    // Server Push Context
    PushProvider

    // Bottom of Tree (Closest to children)
  ].reverse();

  // Now build the tree recursively
  return (
    contexts.reduce((accChild,Context) => (
      <Context>
        {accChild}
      </Context>
    ),children)
  )
};

export default ContextWrapper;