/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Editable Field
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       13th July 2022

*******************************************************************************************/
import React                            from 'react';
import { styled, Box, Typography }      from '@mui/material';
import EditIcon                         from '@mui/icons-material/Edit';
import { Button, IconButton }           from 'components';
import { withTranslation }              from './hoc';

const ContainerEditable = styled(Box,{
    shouldForwardProp : prop => prop !== 'showButtonOnHover' && prop !== 'editable'
})(({theme, showButtonOnHover = false, editable = false}) => {
    const padding = theme.spacing(0.25);
    return {
        display : "flex",
        ...(showButtonOnHover && editable && {
            '& > * + *' : {
                display         : showButtonOnHover ? 'none' : 'initial',
                background      : theme.palette.action.hover,
                margin          : -padding,
                padding         : +padding,
                borderRadius    : +padding,
            },
            '&:hover' : {
                '& > * + *' : {
                    display : 'initial'
                },
            }
        })
    }
})

const ContainerRenderButton = styled(Box,{
    shouldForwardProp : prop => prop !== 'showButtonOnHover'
})(({showButtonOnHover = false}) => ({
    ...(showButtonOnHover && {
        '& > * + *' : {
            display : showButtonOnHover ? 'none' : 'initial',
        },
        '&:hover' : {
            '& > * + *' : {
                display : 'initial'
            },
        }
    })
}))

const noop = () => {};
const obj = {};

export const EditableField = withTranslation( ({
    t,
    value                   = "None",
    placeholder             = undefined,
    component   : Component = Box,
    icon        : Icon      = EditIcon, 
    showButtonOnHover       = false,       
    showIcon                = true,
    editable                = true, 
    disabled                = false, 
    iconButtonProps         = obj, 
    iconProps               = obj, 
    componentProps          = obj,
    renderEdit              = noop, 
    renderButton            = noop,
    onEditStart             = noop,
    onEditFinish            = noop,
    ...props
}) => {

    // Editing State
    const [edit,    setEdit]    = React.useState(false);
    const [hover,   setHover]   = React.useState(false);

    // FInish Editing
    const handleEditCancel      = React.useCallback( () => {
        setEdit(false);
        onEditFinish();
    },  [onEditFinish]); 

    // Start Editing
    const handleEditStart       = React.useCallback( () => {
        if(editable){
            setEdit(true);
            onEditStart();
        }
    },   [onEditStart, editable]); 

    const handleMouseOver      = React.useCallback(() => {
        setHover(true);
    },[])

    const handleMouseOut      = React.useCallback(() => {
        setHover(false);
    }, [])

    // If made not editable
    React.useEffect(() => {
        if(!editable){
            handleEditCancel();
            handleMouseOut();
        }
    },[editable, handleEditCancel, handleMouseOut]);

    React.useEffect(() => {
        if(edit){
            handleMouseOut();
        }
    },[edit, handleMouseOut])

    // Handle Excape Key Press
    React.useEffect(() => {
        const escFunction = (event) => {
            if (event.key === "Escape") {
                handleEditCancel();
            }
        }
        document.addEventListener("keydown", escFunction);
        return () => {
            document.removeEventListener("keydown", escFunction);
        }
    },[handleEditCancel]);

    // Use Render Method when Editing
    const useEditRender = React.useMemo(() => (
        renderEdit && typeof renderEdit === 'function' && renderEdit !== noop
    ), [renderEdit])

    const useButtonRender = React.useMemo(() => (
        renderButton && typeof renderButton === 'function' && renderButton !== noop
    ), [renderButton])

    // Render
    return (
        <Box id="editField" width="100%">
            {
                !edit && 
                <ContainerEditable
                    onMouseOver         = {handleMouseOver} 
                    onMouseLeave        = {handleMouseOut}
                    onDoubleClick       = {handleEditStart} 
                    showButtonOnHover   = {showButtonOnHover}
                    editable            = {editable}
                >
                    <Box flexGrow = {1}>
                        <Typography component={Component} {...props} {...componentProps}>
                            {value || placeholder || t('components.editableField.valueNotSet')}
                        </Typography>
                    </Box>
                    {
                        editable && showIcon && Icon && !useButtonRender && 
                        <Box sx={{transform : `translatey(-2px)`, height : 0}}>
                            <IconButton 
                                disabled    = {disabled} 
                                size        = "small" 
                                {...iconButtonProps}
                                onClick     = {handleEditStart} 
                            >
                                <Icon {...iconProps} sx={{fontSize : '0.75rem', color : theme => theme.palette.error.main,...iconProps.sx}} />
                            </IconButton>
                        </Box>
                    }
                    {
                        editable && showIcon && useButtonRender && 
                        <ContainerRenderButton showButtonOnHover={showButtonOnHover}>
                            {
                                renderButton({handleEditStart, icon : Icon, disabled, edit, value})
                            }
                        </ContainerRenderButton>
                    }
                </ContainerEditable>
            }

            {   
                edit && useEditRender && 
                renderEdit({handleEditCancel, disabled, edit, value, hover})
            }

            {
                edit && !useEditRender && 
                <Box>
                    <Box>
                        <Typography component={Component} {...props} {...componentProps}>
                            {value}
                        </Typography>
                    </Box>
                    <Box align="right">
                        <Button onClick={handleEditCancel} color="primary" size="small">
                            {t('common.cancel')}
                        </Button>
                    </Box>
                </Box>
            }
        </Box>
    )
});

export default EditableField;
