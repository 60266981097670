/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Examples
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       1st June 2022

*******************************************************************************************/

import React                from 'react';
import { 
    useMediaQuery,
    Typography 
}                           from '@mui/material';
import { 
  Title,
  Examples as ExamplesComponent,
  LoadingData,
  ContentfulRichText,
}                           from 'components';
import { 
  useExamples
}                           from 'contexts';
import constants            from '../constants';
import Section              from '../components/Section';
import SectionContainer     from '../components/SectionContainer';

export const WidgetExamples = ({data = undefined}) => {
    const {hasExamples, queried, setEnabled} = useExamples();

    const mdUp = useMediaQuery(theme => theme.breakpoints.up('lg'));

    React.useEffect(() => {
        setEnabled(true);
    },[setEnabled])

    if(!hasExamples || !data)
        return null;

    return (
        <SectionContainer id={constants.ids.examples}>
            <Section
                direction       = {data?.fields?.flexDirection}
                LeftArgs        = {{lg:5, xs:12}}
                RightArgs       = {{lg:7, xs:12}}
                feature         = { 
                    <ExamplesComponent quantity = {mdUp ? 3 : 2} /> 
                }
            >
                <Title component="h2" variant="h2" gutterBottom>
                    {data?.fields?.title}
                </Title>
                {
                    queried &&
                    <>
                        <Typography paragraph variant="h5">
                            {data?.fields?.subtitle}
                        </Typography>
                        <ContentfulRichText content={data?.fields?.content} />
                    </>
                }
                {
                    !queried && 
                    <LoadingData/>
                }
            </Section>
        </SectionContainer>
    )
}

export default WidgetExamples;