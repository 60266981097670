/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Library Radix Compoonent
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       11th October 2021

*******************************************************************************************/
import React                            from 'react';
import { Box, Typography}               from '@mui/material';
import { 
    Radix, 
    LoadingData, 
    JSONViewer
}                                       from 'components';
import withFeatureTypography            from 'components/hoc/withFeatureTypography';
import SkeletonRadix                    from 'components/skeletons/SkeletonRadix';
import { LibrarySubtitle }              from './LibrarySubtitle';
import { 
    useLibrary,
    useLibraryViewer,
    useUser,
    useNetwork
}                                       from 'contexts';
import { RootContainer }                from 'components'; 
import { withTranslation }              from 'components/hoc';
import { 
    useSize, 
    useHoroscopeDescriptor
}                                       from 'hooks';


const SkeletonRadixLoading = withFeatureTypography(SkeletonRadix);

const Loading = withTranslation( ({t,...props}) => (
    <Box sx={{width:'100%'}}>
        <LoadingData {...props} sx={{textAlign:'center',mx:'auto',width:'fit-content'}}>
            { t('common.loading') }
        </LoadingData>
    </Box>
));

const WaitingForData = withTranslation( ({t}) => (
    <Box sx={{width:'100%'}}>
        <Typography align="center">
            { t('common.waitingForData') }
        </Typography>
    </Box>
));

export const LibraryRadix = withTranslation(({
    t,
    id : idIn               = 'radix', 
    title                   = undefined,
    showDescriptor          = false,
    ...props
}) => {

    const {isNetworkReady}              = useNetwork();
    const {ready}                       = useUser();
    const { getHoroscopeDataById }      = useLibrary();
    const {
        libraryId,
        loading : libraryLoading,
        data    : libraryData
    }                                   = useLibraryViewer();
    const id                            = React.useMemo(() => idIn, [idIn]);
    const ref                           = React.useRef(null);
    const {width}                       = useSize(ref)
    const titleRef                      = React.useRef(null);
    const {height:titleHeight}          = useSize(titleRef);

    // Hold LibraryID State
    const [querying,    setQuerying]    = React.useState(true);
    const [data,        setData]        = React.useState(undefined);
    
    const {descriptor}                  = useHoroscopeDescriptor(data, !!libraryData?.metadata?.userFieldsData?.natalRecord?.unknownTime);

    // Clear Data
    const clear                         = React.useCallback(() => setData(undefined), []);

    // Is Page Loading
    const isPageLoading                 = React.useMemo(() => libraryLoading || !ready || !isNetworkReady, [libraryLoading, ready, isNetworkReady]);

    // Refresh Data
    const refresh = React.useCallback( (reset = true) => {
        setQuerying(true);
        if(isNetworkReady && ready && libraryId){
            if(reset) clear();
            getHoroscopeDataById(libraryId)
                .then(setData)
                .catch(clear)
                .finally(() => {
                    setTimeout(() => setQuerying(false), 100);
                });
        }else{
            clear();
        }
    },[clear, getHoroscopeDataById, isNetworkReady, libraryId, ready])

    // Execute Refresh when it is redefined
    React.useEffect(refresh,[refresh]);

    return (
        <RootContainer ref={ref} sx={{position:'relative', m : 'auto'}}>
            <Box>
                {
                    !querying && !isPageLoading && title !== null &&
                    <Box ref={titleRef}>
                        <LibrarySubtitle>
                            {title || t('common.natalChart')}
                        </LibrarySubtitle>
                    </Box>
                }
                
                    {
                        (querying || !data) && 
                        <Box sx={{transform:`translatey(-${titleHeight}px)`}}>
                            <SkeletonRadixLoading 
                                typographyComponent = {querying ? Loading : WaitingForData} 
                                showFeature         = {true}
                            />
                        </Box>
                    }
                    {
                        (!querying && data) && 
                        <Box sx={{transform:`translatey(-${titleHeight}px)`}}>
                            <Radix
                                id      = {id}
                                data    = {data}
                                show    = {true}
                                width   = {width}
                                {...props}
                            />
                        </Box>
                    }
                    {
                        showDescriptor && (!querying && data && descriptor) && 
                        <Typography component="div" sx={{fontSize:'0.75rem'}}>
                            {descriptor}
                        </Typography>
                    }
                    {
                        false && data &&
                        <JSONViewer src={data}/>
                    }
                    {
                        false && data &&
                        <JSONViewer src={libraryData}/>
                    }
            </Box>
        </RootContainer >
    )
})

export default LibraryRadix;