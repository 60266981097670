
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Aspect Context
********************************************************************************************
Boilerplate context, consumer, provider and hook

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       20th August 2021

*******************************************************************************************/
import React                    from 'react';
import moment                   from 'moment';
import {useNetwork}             from './NetworkContext';
import {
    useStateEphemeral
}                               from '../hooks';
import { debounce }             from '../functions';
import { useCancelToken }       from '../hooks';

// The Product Context 
const AspectContext = React.createContext(undefined);

const makeDefaultData = (name,description, major, color,degree,orb) => ({name,description,major, color,degree,orb});
const DEFAULT_DATA = [
    makeDefaultData('Conjunction', 'A conjunction is when two different planets link up together in the same sign,', true, 'purple', 0, 10),
    makeDefaultData('Opposition',  'The opposition is when planets are across the Zodiac wheel from each other', true, 'orange', 180, 10),
    makeDefaultData('Square',      'When you divide the natal chart by 4, you get a 90° angle. With this, we get the square planetary aspect, which represents challenges and conflict between the planets involved', true, 'red',  90,  8),
    makeDefaultData('Trine',       'When the natal chart is divided three ways, it forms a 120° angle called the trine. In astrology, the trine planetary aspect occurs when two planets are in synchronicity with one another', false, 'blue', 120,  8),
    makeDefaultData('Sextile',     'The sextile planetary aspect represents a special 60° angle between two planets in the natal chart. The energy created between these two planets with a sextile aspect is one that is very cooperative', false, 'green', 60,  6)
]

const BASE_API_URL = '/api/astrology/aspect';

// Aspect Provider
const AspectProvider = ({children}) => {

    const {
        axios, 
        socketUsers : socket
    }                                               = useNetwork();
    const {cancelToken,isCancel}                    = useCancelToken();

    const [enabled, setEnabled]                     = React.useState(false);
    const [data,    setData]                        = React.useState([]);
    const [working, setWorking]                     = React.useState(false);
    const [queried, setQueried]                     = React.useState(undefined);
   
    // Error/Success Messages
    const   [messageSuccess,    setMessageSuccess]  = useStateEphemeral(undefined),
            [messageError,      setMessageError]    = useStateEphemeral(undefined,5000);

    // Clear Records
    const clear = React.useCallback(() => setData([]),[]);

    // GET
    const get = React.useCallback(() => new Promise((resolve,reject) => {
        setWorking(true);
        axios.get(BASE_API_URL,{cancelToken})
            .then(({data}) => {
                setMessageSuccess("Aspects queried")
                return data
            })
            .then(resolve)
            .catch(err => {
                if(isCancel(err)) return reject(err);
                setMessageError(err.message || "Error retrieving aspects");
                reject(err)
            })
            .finally(() => {
                setWorking(false);
            })
    }),[axios, cancelToken, isCancel, setMessageError, setMessageSuccess])

    const refresh = React.useCallback( (reset) => {
        setWorking(true);
        if(reset) 
            clear();
        get()
            .then(setData)
            .catch((err) => {
                if(isCancel(err)) return
                clear();
            })
            .finally(() => {
                setWorking(false);
                setQueried(moment());
            })
    },[clear, get, isCancel])

    const create = React.useCallback( ({name, description, major, color, degree, orb, delta}, query=true) => new Promise((resolve,reject) => {
        setWorking(true);
        axios.post(BASE_API_URL, {name, description, major, color, degree, orb, delta}, {cancelToken})
            .then(({data}) => {
                setMessageSuccess('Created aspect');
                return data;
            })
            .then(resolve)
            .catch(err =>{
                if(isCancel(err)) return reject(err);
                setMessageError(err?.message  || "Error creating aspect");
                reject(err);
            })
            .finally(()=>{
                if(query)
                    refresh();
            })
    }),[axios, cancelToken, isCancel, refresh, setMessageError, setMessageSuccess]);

    const deleteById = React.useCallback( (id,query=true) => new Promise((resolve,reject) => {            
        setWorking(true);
        axios.delete(`${BASE_API_URL}/${id}`, {cancelToken})
            .then(data => {
                setMessageSuccess(`Deleted aspect ${id}`);
                resolve(data);
            })
            .catch(err => {
                if(isCancel(err)) return reject(err);
                setMessageError(err?.message  || `Error deleting aspect ${id}`);
                reject(err);
            })
            .finally(()=>{
                if(query)
                    refresh();
            })
    }), [axios, cancelToken, isCancel, refresh, setMessageError, setMessageSuccess])

    const postById = React.useCallback( ({_id, name, description, major, color, degree, orb, delta},query=true) => new Promise((resolve,reject) => {
        setWorking(true);
        axios.post(`${BASE_API_URL}/${_id}`,{name, description, major, color, degree, orb, delta}, {cancelToken})
            .then(({data}) => {
                setMessageSuccess(`Updated aspect ${_id}`);
                return data;
            })
            .then(resolve)
            .catch(err =>{
                if(isCancel(err)) return reject(err);
                setMessageError(err?.message  || `Error editing aspect ${_id}`);
                reject(err);
            })
            .finally(()=>{
                if(query)
                    refresh();
            })
    }), [axios, cancelToken, isCancel, refresh, setMessageError, setMessageSuccess])
    
    // Refresh on authentication
    React.useEffect(() => {
        if(enabled)
            refresh();
    },[enabled, refresh])

    // Refresh on SocketIO Instruction
    React.useEffect(() => {
        if(enabled && socket){
            socket.on('refresh_aspects', refresh)
            return () => {
                socket.off('refresh_aspects', refresh);
            }
        }
    },[enabled, refresh, socket]);

    const enable = React.useCallback( () => setEnabled(true) , []);

    // Context values
    const value = React.useMemo(() => ({
        data,
        enabled,
        setEnabled,
        enable,
        working,
        queried,
        refresh : debounce(refresh),
        create,
        postById,
        deleteById,
        messageSuccess,
        setMessageSuccess,
        messageError,
        setMessageError,
        DEFAULT_DATA,
        BASE_API_URL
    }), [create, data, deleteById, enable, enabled, messageError, messageSuccess, postById, queried, refresh, setMessageError, setMessageSuccess, working]);

    return (
        <AspectContext.Provider value={value}>
            {children}
        </AspectContext.Provider>
    )
}

// Aspect Consumer
const AspectConsumer = ({children}) => {
    return (
        <AspectContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('AspectConsumer must be used within AspectProvider');
                }
                return children(context)
            }}
        </AspectContext.Consumer>
    )
}

// useAspect Hook
const useAspect = () => {
    const context = React.useContext(AspectContext);
    if(context === undefined)
        throw new Error('useAspect must be used within AspectProvider');
    return context;
}

export {
    AspectProvider,
    AspectConsumer,
    useAspect
}