/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Pagination
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       12th September 2023

*******************************************************************************************/

import React                            from 'react';
import { TablePagination }              from '@mui/material';

// Simple Pagination Component
export const Pagination = ({
    options, 
    count, 
    page, 
    perPage, 
    onChangePage, 
    onChangePerPage, 
    disabled            = false, 
    labelRowsPerPage    = undefined
}) => {

    // Handlers
    const handleOnPageChange        = React.useCallback((event, newPage) => onChangePage(newPage), [onChangePage]);
    const handleRowsPerPageChange   = React.useCallback((event) => onChangePerPage(event.target.value), [onChangePerPage]);
    
    // Props
    const backIconButtonProps       = React.useMemo(() => ({ disabled : disabled || page <= 0 }), [disabled, page]);
    const nextIconButtonProps       = React.useMemo(() => ({ disabled : disabled || (page + 1) * perPage >= count }), [count, disabled, page, perPage]);
    const selectProps               = React.useMemo(() => ({ disabled }), [disabled]);

    // Pagination
    return (
        <TablePagination
            component           =   { 'div' }
            rowsPerPageOptions  =   { options }
            count               =   { count }
            rowsPerPage         =   { perPage }
            page                =   { page }
            onPageChange        =   { handleOnPageChange }
            onRowsPerPageChange =   { handleRowsPerPageChange }
            backIconButtonProps =   { backIconButtonProps }
            nextIconButtonProps =   { nextIconButtonProps }
            SelectProps         =   { selectProps }
            labelRowsPerPage    =   { labelRowsPerPage }
        />
    )
}

export default Pagination;