/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
No Data Prompt
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       20th August 2023

*******************************************************************************************/
import React                            from 'react';
import isFunction                       from 'lodash/isFunction';
import { PulseButton }                  from 'components';
import { styled, Box, Typography }      from '@mui/material';
import { useNetwork, useTranslation }   from 'contexts';

const Root = styled(Box)({
    width       : '100%',
    textAlign   : 'center'
})

const noop = () => {};

const obj = {};

export const NoDataPrompt = ({
    children,
    onClick : handleClick       = noop,
    disabled                    = false,
    pluralize : p               = true,
    typographyProps             = obj,
    buttonComponent : Button    = PulseButton,
    buttonText                  = undefined,
    buttonProps                 = obj, 
    render                      = noop,
    ...rest
}) => {
    const {t}               = useTranslation();
    const {isNetworkReady}  = useNetwork();
    const useRender         = Boolean(isFunction(render) && render !== noop);
    const useButton         = Boolean(isFunction(handleClick) && handleClick !== noop);
    return (
        <Root>
            <Typography component="div" color="textPrimary" align="center" {...typographyProps}>
                {children || t('components.noDataPrompt.defaultPrompt') }
            </Typography>
            {
                useRender &&
                <Box p={2}>
                    { 
                        render({onClick:handleClick,disabled}) 
                    }
                </Box>
            }
            {
                ! useRender && useButton &&
                <Box p={2}>
                    <Button 
                        disabled        = { disabled || !isNetworkReady } 
                        color           = "secondary" 
                        variant         = "contained" 
                        size            = "small" 
                        {...buttonProps} 
                        {...rest} 
                        onClick         = {handleClick}
                    >
                        { buttonText || t('components.noDataPrompt.create') }
                    </Button>
                </Box>
            }
        </Root>
    )
}

export default NoDataPrompt;