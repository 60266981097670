/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Show More Replies Button Component
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       12th May 2022

*******************************************************************************************/
import React                            from 'react';
import { Box,Button as ButtonMUI}       from '@mui/material';
import AddIcon                          from '@mui/icons-material/Add';
import { v4 as uuidv4 }                 from 'uuid';
import { RefreshIcon }                  from 'components';
import { useStateEphemeral }            from 'hooks';
import withActionButton                 from './hoc/withActionButton';
import { withDisabledOffline }          from 'components/hoc';
import { useTranslation }               from 'contexts';


const ButtonBase    = withActionButton(ButtonMUI);
const Button        = withDisabledOffline(ButtonBase);
const noop          = () => {};

export const CommentsShowMoreRepliesButton = ({loading = false, hasMore = false, hasPostedReplies = false, hasDeletedReplies = false, allowRefresh = false, onClick = noop, onClickRefresh = noop, ...props}) => {
    
    const {t}                                   = useTranslation();
    const uuid                                  = React.useMemo(() => uuidv4(), []);
    const disabled                              = React.useMemo(() => !hasMore && !hasPostedReplies && !hasDeletedReplies, [hasDeletedReplies, hasMore, hasPostedReplies]);
    const [remaining, setRemaining]             = React.useState(0);
    const [refreshClicked, setRefreshClicked]   = useStateEphemeral(false, 5000, false, setRemaining);

    const handleRefreshClicked                  = React.useCallback(() => setRefreshClicked(true), [setRefreshClicked]);

    const {idShowMore,idRefresh} = React.useMemo(() => ({
        idShowMore  : `showmore-${uuid}`,
        idRefresh   : `refresh-${uuid}`
    }),[uuid]);

    // const sleep = ms => () => new Promise(resolve => setTimeout(resolve,ms));

    /*
    const scrollToId= React.useCallback((id,smooth = true) => {
        const el = document.getElementById(id);
        if(el) el.scrollIntoView({behavior: smooth ? 'smooth' : 'auto', block: 'center', inline: 'center'});
    },[])
    */

    const handleShowMoreRepliesClick = React.useCallback(() => {
        Promise
            .resolve()
            .then(onClick)
            // .then(() => scrollToId(idShowMore))
            // .then(sleep(500))
            // .then(() =>  scrollToId(idShowMore))
            .catch(noop)
    },[onClick])

    const handleRefreshClick = React.useCallback(() => {
        Promise
            .resolve()
            .then(handleRefreshClicked)
            .then(onClickRefresh)
            // .then(() => scrollToId(idRefresh))
            // .then(sleep(500))
            // .then(() => scrollToId(idRefresh))
            .catch(noop)
    },[handleRefreshClicked, onClickRefresh]);

    const labelShowMore = React.useMemo(() => {
        if(loading)                         return t('components.comments.loading');
        if(!hasMore && hasPostedReplies)    return t('components.comments.reload');
        if( hasMore && hasPostedReplies)    return t('components.comments.reload');
        if(!hasMore && !hasPostedReplies)   return t('components.comments.noMoreReplies');
        if( hasMore && !hasPostedReplies)   return t('components.comments.showMoreReplies');
        return null;
    },[loading, t, hasMore, hasPostedReplies])

    return (
        <Box style={{display:'inline-flex'}}>
            {
                labelShowMore && 
                <Box>
                    <Button 
                        id          = {idShowMore}
                        disabled    = {disabled || loading} 
                        startIcon   = {
                            loading 
                                ? <RefreshIcon loading={loading}/> 
                                : <AddIcon sx={{fontSize : 15}}/>
                        }
                        size        = "small" 
                        onClick     = {handleShowMoreRepliesClick}
                    >
                        {
                            labelShowMore
                        }
                    </Button>
                </Box>
            }
            {
                allowRefresh && 
                <Box>
                    <Button 
                        id          = {idRefresh}
                        disabled    = { loading || refreshClicked} 
                        size        = "small" 
                        onClick     = {handleRefreshClick}
                    >
                        {
                            remaining > 0 
                                ? t('components.comments.refreshThreadIn', {in:parseInt(remaining/1000) + 1})
                                : t('components.comments.refreshThread')
                        }
                    </Button>
                </Box>
            }
        </Box>
    )
}

export default CommentsShowMoreRepliesButton;

