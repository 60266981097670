/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Admin -- Aspects
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       3rd January 2021

*******************************************************************************************/
import React                            from 'react';
import {isNil}                          from 'lodash';
import { 
    Grid,
    InputAdornment, IconButton 
}                                       from '@mui/material';
import {
    Colorize as ColorizeIcon
}                                       from '@mui/icons-material';
import {
    ColorBox,
    ColorPicker,
    DraggableDialog,
    Form,
}                                       from 'components';
import { SelectYesNoField }             from './fields';
import {
    useTranslation
}                                       from 'contexts';
import { 
    TextField,
    showErrorOnChange   as showError,
}                                       from 'mui-rff';
import {
    FormSpy
}                                       from 'react-final-form';

// Local Style Overrides
import './styles/AspectFormStyles.css';

const isColor = (strColor) => {
    var s = new Option().style;
    s.color = strColor;
    var test1 = s.color === strColor;
    var test2 = /^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/i.test(strColor);
    return test1 || test2;
}

// const capFirst = (x) => x[0].toUpperCase() + x.slice(1,x.length);

const   DEGREE_MIN  = 0,
        DEGREE_MAX  = 360,
        ORB_MIN     = 0,
        ORB_MAX     = 20,
        DELTA_MIN   =  0,
        DELTA_MAX   = 12;

const noop          = () => {};
const obj           = {};

const defaultFormData = {
    _id         : undefined,
    major       : true,
    name        : '',
    description : '',
    color       : '#000000',
    degree      : 0,
    orb         : 5,
    delta       : undefined,
};

export const AspectForm = ({
    formData                = defaultFormData,
    isEditing               = false,
    disabled                = false,
    onSubmit : handleSubmit = noop,
    onCancel : handleCancel = noop,
    FormProps               = obj,
    ...rest
}) => {
    
    const {t}                                   = useTranslation();
    const [openColorPicker, setOpenColorPicker] = React.useState(false);

    const validate = (values) => {
        
        // Empty Errors
        let errors = {};

        // Required Strings
        let requiredStringFields = ['name','description','color'];
        requiredStringFields.forEach(item => {
            if(!values[item])
                errors[item] = t('components.forms.aspectForm.required');
            if(!errors[item] && typeof values[item] !== 'string')
                errors[item] = t('components.forms.aspectForm.mustBeString')
        })

        let requiredBooleanFields = ['major']
        requiredBooleanFields.forEach(item => {
            if(isNil(values[item]))
                errors[item] = t('components.forms.aspectForm.required');
            if(!errors[item] && typeof values[item] !== 'boolean')
                errors[item] = t('components.forms.aspectForm.mustBeBoolean')
        })

        // Required Numeric
        let requiredNumericFields = ['degree','orb'];
        requiredNumericFields.forEach(item => {
            if(values[item] === undefined || values[item] === null)
                errors[item] = t('components.forms.aspectForm.required');
            if(!errors[item] && isNaN(values[item]))
                errors[item] = t('components.forms.aspectForm.mustBeNumeric')
        })

        // Required Numeric
        let requiredNumericIntegerFields = ['delta'];
        requiredNumericIntegerFields.forEach(item => {
            if(values[item] === undefined || values[item] === null)
                errors[item] = t('components.forms.aspectForm.required');
            if(!errors[item] && isNaN(values[item]))
                errors[item] = t('components.forms.aspectForm.mustBeNumeric');
            if(!errors[item] && !Number.isInteger(values[item]))
                errors[item] = t('components.forms.aspectForm.mustBeInteger');
        })

        // Check Name has no invalid characters
        if(!errors.name && !/^[a-z0-9]+$/.test(values.name))
            errors.name = t('components.forms.aspectForm.mustBeLowerAlpha');

        // Check Degree is in range
        if(!errors.degree && (values.degree < DEGREE_MIN || values.degree > DEGREE_MAX))
            errors.degree = `[${DEGREE_MIN}, ${DEGREE_MAX}]`;

        // Check Orb is in range
        if(!errors.orb && (values.orb < ORB_MIN || values.orb > ORB_MAX))
            errors.orb = `[${ORB_MIN}, ${ORB_MAX}]`;

        // Check Orb is in range
        if(!errors.delta && (values.delta < DELTA_MIN || values.delta > DELTA_MAX))
            errors.delta = `[${DELTA_MIN}, ${DELTA_MAX}]`;

        // Check is Color
        if(!errors.color && !isColor(values.color))
            errors.color = t('components.forms.aspectForm.invalidColor');

        // Done
        return errors;
    }

    const initialValues = {
        ...formData
    }

    const handleOpenColorPicker     = () => setOpenColorPicker(true);
    const handleCloseColorPicker    = () => setOpenColorPicker(false);

    return (
        <Form
            debug           = {false}
            disabled        = {disabled}
            onSubmit        = {handleSubmit}
            onCancel        = {handleCancel}
            initialValues   = {initialValues}
            validate        = {validate}
            {...FormProps}
            render          = {({disabled, form, error, dirtySinceLastSubmit, submitFailed, submitSucceeded, errors, handleSubmit, values, ...rest}) => {
                const handleColorChange = color => form.change('color',color);
                return (
                    <form onSubmit={handleSubmit} noValidate>
                        <Grid container>
                            {
                                [
                                    { name : 'name',        multi : false, translationKey : 'components.forms.aspectForm.name'},
                                    { name : 'description', multi : true,  translationKey : 'components.forms.aspectForm.description'}
                                ].map(({name,multi, translationKey},ix) => {
                                    const extra = multi 
                                        ? {multiline:true, minRows:1, maxRows:5, disabled} 
                                        : {disabled : isEditing || disabled};
                                    return (
                                        <Grid key={`text${ix}`} item xs={12}>
                                            <TextField 
                                                name        = {name} 
                                                label       = {t(translationKey)}
                                                value       = {values[name]}
                                                showError   = {showError}
                                                {...extra}
                                            />
                                        </Grid>
                                    )
                                })
                            }
                            {
                                [
                                    {name : 'major', translationKey : "components.forms.aspectForm.major"}
                                ].map(({name,translationKey},ix) => {
                                    return (
                                        <Grid key={`boolean${ix}`} item xs={12}>
                                            <SelectYesNoField
                                                name            = {name} 
                                                label           = {t(translationKey)}
                                                value           = {values[name]}
                                                showError       = {showError}
                                            />
                                        </Grid>
                                    )
                                })
                            }
                            {
                                [ 
                                    { name : 'degree',   translationKey : 'components.forms.aspectForm.degree' },
                                    { name : 'orb',      translationKey : 'components.forms.aspectForm.orb' }
                                ].map(({name,translationKey},ix) => {
                                    const extra = {disabled};
                                    return (
                                        <Grid key={`numeric${ix}`} item xs={2}>
                                            <TextField 
                                                type            = "number"
                                                name            = {name} 
                                                label           = {t(translationKey)}
                                                value           = {values[name]}
                                                showError       = {showError}
                                                {...extra}
                                            />
                                        </Grid>
                                    )
                                })
                            }
                            {
                                [
                                    { name : 'delta', translationKey : 'components.forms.aspectForm.delta' }
                                ].map(({name,translationKey},ix) => {
                                    const extra = {disabled};
                                    const val   = values[name];
                                    return (
                                        <Grid key={`numericInteger${ix}`} item xs={2}>
                                            <TextField 
                                                type        = "number"
                                                name        = {name} 
                                                label       = {t(translationKey)}
                                                value       = {!isNil(values[name]) ? values[name] : ""}
                                                showError   = {showError}
                                                {...extra}
                                            />
                                            <FormSpy subscription = {{values: true}} 
                                                onChange = {({values})=>{
                                                    if(values[name] && values[name] !== val)
                                                        form.change(name, parseInt(values[name]));
                                                }}
                                            />
                                        </Grid>
                                    )
                                })
                            }
                            <Grid item xs={6}>
                                <TextField 
                                    name            = "color" 
                                    label           = {t('components.forms.aspectForm.color')}
                                    disabled        = {disabled}
                                    value           = {values.color}
                                    onChange        = {(event) => form.change('color', event.target.value.toLowerCase())}
                                    InputProps={{
                                        startAdornment : (
                                            <InputAdornment position="start">
                                                <ColorBox color={values.color} />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {
                                                    !disabled && 
                                                    <IconButton
                                                        onClick     = {disabled ? null : handleOpenColorPicker}
                                                        onMouseDown = {disabled ? null : handleOpenColorPicker}
                                                        edge        = "end"
                                                        size        = "small"
                                                    >
                                                        <ColorizeIcon />
                                                    </IconButton>
                                                }
                                            </InputAdornment>
                                        )
                                    }}
                                    showError       = {showError}
                                />
                            </Grid>
                        </Grid>
                        <DraggableDialog title={t('components.forms.aspectForm.selectColor')} open={openColorPicker} onOk={handleCloseColorPicker} onClose={handleCloseColorPicker} showButtonsCancel={false}>
                            <ColorPicker 
                                color           = {values.color} 
                                disableAlpha    = {true}
                                onChange        = {handleColorChange}
                            />
                        </DraggableDialog>
                    </form>
                )
            }}
        />
    )
}

export default AspectForm;