/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Like
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       15th June 2023

*******************************************************************************************/
import React                    from 'react';
import { 
    useTheme,
    Box, 
    IconButton,
    Typography
}                               from '@mui/material';
import ThumbUpIcon              from '@mui/icons-material/ThumbUp';
import ThumbDownIcon            from '@mui/icons-material/ThumbDown';
import PrettyNumber             from './PrettyNumber'
import {RefreshIcon}            from 'components';
import { 
    useNetwork, 
    useUser,
}                               from 'contexts';
import { 
    useLike
}                               from 'hooks';

const noop = () => {};

export const Like = ({
    reference                           = undefined, 
    disabled        : disabledIn        = false,
    likeIcon        : LikeIcon          = ThumbUpIcon, 
    dislikeIcon     : DislikeIcon       = ThumbDownIcon,
    likeColor       : likeColorIn       = "auto",
    dislikeColor    : dislikeColorIn    = "error",
    size                                = "medium",
    onClick         : handleClick       = noop,
}) => {
    const theme                         = useTheme();
    const {isNetworkReady }             = useNetwork();
    const {
        isAuthenticated,
        loginWithPopup 
    }                                   = useUser();
    const {
        remove,
        like,
        dislike,
        working,
        data,
        queried,
        isLiked,
        isDisliked
    }                                   = useLike(reference);

    const handleLikeClick = React.useCallback((e) => (
        !isAuthenticated 
            ? loginWithPopup()
            : (isLiked ? remove : like)()
                .then(() => handleClick(e))
                .catch(() => {})
    ),[handleClick, isAuthenticated, isLiked, like, loginWithPopup, remove])

    const handleDislikeClick = React.useCallback((e) => (
        !isAuthenticated 
            ? loginWithPopup()
            : (isDisliked ? remove : dislike)()
                .then(() => handleClick(e))
                .catch(() => {})
    ),[dislike, handleClick, isAuthenticated, isDisliked, loginWithPopup, remove])

    // Is Disabled
    const disabled = React.useMemo(() => (
        Boolean(disabledIn || working || !isNetworkReady)
    ), [disabledIn, isNetworkReady, working])

    const processColor = React.useCallback((col) => {
        if(col === 'auto') col = theme.palette.mode === 'dark' ? 'secondary' : 'primary'
        if(theme.palette[col]) return theme.palette[col].main;
        return col;
    },[theme.palette])

    const likeColor     = React.useMemo(() => processColor(likeColorIn),[likeColorIn, processColor]);
    const dislikeColor  = React.useMemo(() => processColor(dislikeColorIn),[dislikeColorIn, processColor]);

    if(!reference)
        return null;

    return (
        <Box display="flex" width = 'fit-content'>
            {
                !queried && working &&
                <Box display="flex" sx={{my:'auto', mr:1, color:theme => theme.palette.divider /*color:theme => theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.primary.main*/}}>
                    <RefreshIcon loading={true} /*color="inherit"*/ />
                </Box>
            }
            <Box display="flex" sx={{my:'auto'}}>
                
                {
                    Boolean(data?.likeCount) && 
                    <Typography color={likeColor} component="div" variant="body2" sx={{my:'auto'}}>
                        <PrettyNumber value={data?.likeCount} precision={1} />
                    </Typography>
                }

                <Box sx={{ color: likeColor }}>
                    <IconButton 
                        disabled    = {disabled} 
                        color       = {data?.value === true ? 'inherit' : "default"}
                        size        = {size} 
                        onClick     = {handleLikeClick}
                    >
                        <LikeIcon />
                    </IconButton>
                </Box>
            </Box>
            <Box display="flex" sx={{my:'auto'}}>
                
                <Box sx={{ color: dislikeColor }}>
                    <IconButton 
                        disabled    = {disabled} 
                        color       = {data?.value === false ? 'inherit' : "defaiult"} 
                        size        = {size}
                        onClick     = {handleDislikeClick}
                    >
                        <DislikeIcon /> 
                    </IconButton>
                </Box>

                {
                    Boolean(data?.dislikeCount) && 
                    <Typography color={dislikeColor} component="div" variant="body2" sx={{my:'auto'}}>
                        <PrettyNumber value={data?.dislikeCount} precision={1} />
                    </Typography>
                }
            </Box>
        </Box>
    )
}

export default Like;