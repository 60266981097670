/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Error Bar for Google Perspective Stats
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       23rd January 2023

*******************************************************************************************/
import React                            from 'react';
import {
    styled,
    useTheme,
    Box,
}                                       from '@mui/material';

const ErrorBarContainer = styled(Box)(({theme}) => ({
    display     : 'flex',
    position    : 'relative',
    overflow    : 'hidden',
    width       : '100%',
    height      : '100%',
    background  : theme.palette.background.paper,
    // border      : `1px solid ${theme.palette.divider}`,
    border      : `1px solid ${theme.palette.text.secondary}`,
    borderRadius: theme.spacing(0.5)
}))

const ErrorBarMarker = styled(Box,{
    shouldForwardProp : prop => prop !== 'valuepcnt'
})(({valuepcnt = 50}) => ({
    position    : 'absolute', 
    top         : -1, 
    bottom      : -1, 
    width       : 0, 
    left        : `${valuepcnt}%`,
}))

const ErrorBarLine = styled(Box,{
    shouldFormwardProp : prop => prop !== 'valuepcntmin' && prop !== 'valuepcntmax'
})(({valuepcntmin = 0, valuepcntmax = 100}) => ({
    position    : 'absolute', 
    top         : 0, 
    bottom      : 0, 
    left        : `${valuepcntmin}%`,
    right       : `${100 - valuepcntmax}%`,
    transform   : `translatey(-50%)`
}))

const ErrorBarSD = styled(Box,{
    shouldFormwardProp : prop => prop !== 'mean' && prop !== 'std'
})(({mean = 50, std = 0}) => ({
    position    : 'absolute', 
    top         : '25%', 
    bottom      : '25%', 
    left        : `${Math.max(mean - std,0)}%`,
    right       : `${Math.max(100 - (mean + std),0)}%`,
}))

export const PerspectiveErrorBar = ({min = 0, max = 1, mean = 0.5, std = 0}) => {
    const theme = useTheme();
    return (
        <ErrorBarContainer sx={{height:theme.spacing(2.5)}}>
            <ErrorBarSD mean={100 * mean} std = {100 * std} sx={{bgcolor:theme.palette.divider}}/>
            <ErrorBarLine valuepcntmin = {min * 100} valuepcntmax = {max * 100} sx={{borderBottom : `1px solid ${theme.palette.divider}`}}/>
            <ErrorBarMarker valuepcnt = { min * 100} sx={{borderLeft : '1px solid green'}}/>
            <ErrorBarMarker valuepcnt = {mean * 100} sx={{borderLeft : `1px solid ${theme.palette.info.main}`}}/>
            <ErrorBarMarker valuepcnt = { max * 100} sx={{borderLeft : '2px solid red'}}/>
        </ErrorBarContainer>
    )
}

export default PerspectiveErrorBar;