/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Admin -- Email
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       11th June 2022

*******************************************************************************************/
import React                            from 'react';
import { useTheme, Box }                from '@mui/material';
import ReactEmailEditor                 from 'react-email-editor';
import {
        RootContainer,
        Button,
        SkeletonFancyWaitingForNetwork,
        Title
}                                       from 'components';
import { withTranslation }              from './hoc';

const noop = () => {};

export const EmailEditor = withTranslation( ({
        t,
        title           = undefined,
        design          = undefined,
        onExportDesign  : handleExportDesign    = noop,
        onExportHtml    : handleExportHtml      = noop,
        onReady         : handleReady           = noop,
        onImage         : handleImage           = noop,
        ...props
}) => {
        
        const theme                     = useTheme();
        const emailEditorRef            = React.useRef(null);
        const [ready,   setReady]       = React.useState(false);

        const exportHtml = React.useCallback( () => new Promise((resolve,reject) => {
                if(emailEditorRef.current){
                        emailEditorRef.current.editor.exportHtml(({design,html}) => {
                        handleExportDesign({design});
                        handleExportHtml({html});
                        resolve({design,html})
                        });
                }else reject(new Error(t('components.emailEditor.editorNotLoaded')))
        }), [t, handleExportDesign, handleExportHtml] );

        const exportDesign = React.useCallback( () => new Promise((resolve,reject) => {
                if(emailEditorRef.current){
                        emailEditorRef.current.editor.exportHtml(({design}) => {
                        handleExportDesign({design});
                        resolve(design)
                        });
                }else reject(new Error(t('components.emailEditor.editorNotLoaded')))
        }), [t, handleExportDesign])

        // editor instance is created
        const onLoad = React.useCallback( () => {
            // you can load your template here;
            // const design = {};
            if(design && emailEditorRef.current){
                emailEditorRef.current.editor.loadDesign(design);
            }
        }, [emailEditorRef, design]);

        React.useEffect(onLoad, [design, onLoad]);
        
        // editor is ready
        const onReady = React.useCallback( () => {
                setReady(true);
                handleReady(true);
        }, [handleReady]);

        React.useEffect(() => {
                if(emailEditorRef.current && ready){
                        emailEditorRef.current.editor.registerCallback('image', handleImage);
                }
        },[emailEditorRef, handleImage, ready])

        React.useEffect(() => {
                if(emailEditorRef.current && ready){
                        emailEditorRef.current.editor.setAppearance({
                                theme: theme.palette.mode
                        })
                }
        },[theme.palette.mode, emailEditorRef, ready])

        return (
            <RootContainer minHeight={400}> 
                {props.render && props.render({title, ready, exportDesign, exportHtml})}
                {!props.render && 
                        <Box display="flex" style={{width:'100%'}}>
                                <Box flexGrow={1}>
                                        <Title>
                                                {title || t('components.emailEditor.emails')}
                                        </Title>
                                </Box>
                                <Box flexShrink={1}>
                                        <Button disabled={!ready} onClick={exportDesign} variant="contained" color="primary">
                                                {t('components.emailEditor.exportDesign')}
                                        </Button>
                                </Box>
                                <Box flexShrink={1} ml={1}>
                                        <Button disabled={!ready} onClick={exportHtml} variant="contained" color="primary">
                                                {t('components.emailEditor.exportHtml')}
                                        </Button>
                                </Box>
                        </Box>
                }
                <Box style={{display:ready ? 'inherit' : 'none', position:'relative'}}>
                        <ReactEmailEditor
                                projectId       = {1}
                                ref             = {emailEditorRef}
                                onLoad          = {onLoad}
                                onReady         = {onReady}
                                options         = {{
                                        appearance: {
                                                theme: theme.palette.mode,
                                        },
                                        displayMode : 'email',
                                }}
                                style           = {{minHeight:500,height:'fit-contnt'}}
                        />
                </Box>
                {
                        !ready && 
                        <Box style={{position:'relative', height:400}}>
                                <SkeletonFancyWaitingForNetwork />
                        </Box>
                }
            </RootContainer>
        )
});

export default EmailEditor;
