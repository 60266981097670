/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Hero
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       1st June 2022

*******************************************************************************************/
import React                    from 'react';
import { Box, Typography }      from '@mui/material';
import { 
    RootContainer, 
    Title,
}                               from 'components';
import constants                from '../constants';

export const Hero = React.forwardRef(({children, data = undefined}, ref) => {
    if(!data)
        return null;
    return (
        <RootContainer ref={ref} id={constants.ids.hero}>
            <Box>
                <Title component="h1" variant="h2" gutterBottom sx={{color:'white'}}>
                    {data?.fields?.title}
                </Title>
                <Typography paragraph variant="h5" sx={{color:'white'}}>
                    {data?.fields?.subtitle}
                </Typography>
            </Box>
            {children}
        </RootContainer>
    );
});

export default Hero;