
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Pulse Button
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       27th December 2020

*******************************************************************************************/

import React                    from 'react';
import { Button }               from '@mui/material';
import withPulse                from './withPulse';
import { withDisabledOffline }  from 'components/hoc';

const Component = withPulse(Button);

const BasePulseButton = ({pulse = true, disabled = false, ...props }) => (
    <Component pulse={pulse && !disabled} disabled={disabled} {...props}/>
)

export const PulseButton = withDisabledOffline(BasePulseButton);

export default PulseButton;