/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
DateRangeContextSelector
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       29th January 2023

*******************************************************************************************/
import React                            from 'react';
import { DateRangeChips }               from 'components';
import { DateRangeDialog }              from 'components/modals';
import { useDateRange }                 from 'contexts';

export const DateRangeContextSelector = ({disabled = false}) => {
    const {
        handleChange    : handleDateRangeChange,
        handleOpen      : handleDateRangeOpen,
        handleClose     : handleDateRangeClose,
        value           : dateRange,
        open            : openDateRange,
    } = useDateRange();
    return (
        <>
            <DateRangeChips 
                disabled    = {disabled} 
                start       = {dateRange.start} 
                finish      = {dateRange.finish} 
                onClick     = {handleDateRangeOpen}
            />
            <DateRangeDialog 
                open        = {openDateRange} 
                start       = {dateRange?.start}
                finish      = {dateRange?.finish}
                onClose     = {handleDateRangeClose}
                onCancel    = {handleDateRangeClose}
                onSubmit    = {handleDateRangeChange}
            />
        </>
    )
};

export default DateRangeContextSelector;