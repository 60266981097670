/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Comments Tree
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       12th May 2022

*******************************************************************************************/
import React                            from 'react';
import { 
    styled, 
    alpha,
    useTheme, 
    Box, 
    Button as ButtonMUI,
    Typography 
}                                       from '@mui/material';
import ArrowRightIconMUI                from '@mui/icons-material/ArrowRight';
import { CommentForm }                  from 'components/forms';
import RefreshIconMUI                   from 'components/RefreshIcon';
import { 
    Gravatar,
    IconButton,
}                                       from 'components'
import {
    useComments 
}                                       from 'hooks';
import { 
    useUser,
    useTranslation
}                                       from 'contexts';

import SkeletonComment                  from 'components/skeletons/SkeletonComment';

// Local Components
import CommentsShowMoreRepliesButton    from './CommentsShowMoreRepliesButton';
import CommentsContent                  from './CommentsContent';
import CommentsHeader                   from './CommentsHeader';
import CommentsBody                     from './CommentsBody';
import CommentsAlert                    from './CommentsAlert';
import withTinyIcon                     from './hoc/withIcon';
import { withDisabledOffline }          from 'components/hoc';

const noop = () => {};

const BORDER_THICKNESS = 1

const ButtonBase = styled(ButtonMUI)(({theme}) => ({
    padding             : 2,
    textTransform       : 'initial',
    fontSize            : 8,
    minWidth            : 60,
    marginRight         : theme.spacing(0.5),
    color               : theme.palette.mode === 'dark' 
        ? theme.palette.secondary.main 
        : theme.palette.primary.main,
    '& > .MuiButton-startIcon' : {
        marginRight         : 0,
        // width               : 25
    }
}));
const Button = withDisabledOffline(ButtonBase);

const RefreshIcon = withTinyIcon(RefreshIconMUI);

const RootBox = styled(Box)(({theme}) => ({
    borderBottom  : `1px dashed ${theme.palette.divider}`
}));

const ArrowRightIcon = styled(ArrowRightIconMUI,{
    shouldForwardProp : prop => prop !== 'rotate'
})(({rotate = 0}) => ({
    fontSize            : 25,
    padding             : 0,
    margin              : 0,
    transform           : `rotate(${rotate}deg)`
}));

const BlurShield = styled(Box)(({theme}) => ({
    position            :'absolute',
    top                 : theme.spacing(-1),
    bottom              : theme.spacing(-0.5),
    left                : theme.spacing(-1),
    right               : theme.spacing(-1),
    backdropFilter      : 'blur(2px)', 
    margin              : theme.spacing(1),
    borderRadius        : theme.spacing(1)
}))

const RootSeparator = ({color = 'inherit', showBorder = true, ...props}) => (
    <Box pt={1} sx={{minHeight : theme => theme.spacing(2)}} {...props}>
        {
            showBorder &&
            <RootBox style={{borderBottomColor:color}}/>
        }
    </Box>
);

const SideRibbonContainer = styled(Box,{
    shouldForwardProp : prop => prop !== 'sideRibbon' && prop !== 'framed'
})(({theme,sideRibbon = false, framed = false}) => {
    return {
        ...(sideRibbon && {
            borderLeft          : `${BORDER_THICKNESS}px solid black`, 
            marginLeft          : -BORDER_THICKNESS,
        }),
        ...(framed && {
            border  : `1px solid ${theme.palette.error.main}`, 
            margin  : -1, 
            width   : `calc(100% + 2px)`
        })
    }
});

const SideRibbon = ({gravatarSize = 48, colorBorder = 'black'}) => (
    <SideRibbonContainer 
        sideRibbon  = {true} 
        style       = {{
            position        : 'absolute', 
            top             : 0, 
            left            : gravatarSize/2, 
            bottom          : 0, 
            borderLeftColor : colorBorder
        }}
    />
);

const CommentContainer = styled(Box,{
    shouldForwardProp : prop => !['replying','deleting','editing','buffer'].includes(prop)
})(({theme,replying = false, deleting = false, editing = false, buffer = false}) => ({
    ...(replying && {
        background          : theme.palette.mode === 'dark' 
            ? alpha(theme.palette.primary.main,0.05) 
            : alpha(theme.palette.primary.main,0.05),
    }),
    ...(deleting && {
        background          : alpha(theme.palette.error.main,0.1),
    }),
    ...(editing && {
        background          : theme.palette.mode === 'dark' 
            ? alpha(theme.palette.warning.main,0.05) 
            : alpha(theme.palette.warning.main,0.05),
    }),
    ...(buffer && {
        marginLeft          : theme.spacing(-1),
        marginRight         : theme.spacing(-1),
        paddingLeft         : theme.spacing(1),
        paddingRight        : theme.spacing(1),
        paddingBottom       : theme.spacing(1),
        width               : `calc(100% + ${theme.spacing(2)})`,
        borderRadius        : theme.spacing(1),
        border              : `1px solid ${theme.palette.divider}`,
    }),
}));

export const CommentsTree = ({parentId = undefined, borderColor = undefined, newComment = false, level = 0, master = false, onChange : handleChange = noop, ...props}) => {
    const theme                             = useTheme();
    const {t}                               = useTranslation();
    const {userId, emailMD5}                = useUser();
    const { 
        isRoot, 
        data, 
        loading,
        deleting : loadingDeleting,
        counting,
        hasMore,
        comments,
        hasComments,
        childCount,
        handleSubmitComment,
        handleEditComment,
        handleDeleteComment,
        queryMore,
        queried,
        queriedParent,
        viewChildren, setViewChildren,
        gravatarSize,
        gravatarSizeParent,
        refresh
    }                                           = useComments({parentId, level, onChange : handleChange});

    const colorBorder                           = React.useMemo(() => borderColor || theme.palette.divider, [theme.palette.divider, borderColor])
    const [blurChildren,    setBlurChildren]    = React.useState(false);
    const [editing,         setEditing]         = React.useState(false);
    const [replying,        setReplying]        = React.useState(false);
    const [deleting,        setDeleting]        = React.useState(false);

    const handleEditing                         = React.useCallback(setEditing,[setEditing]);
    const handleReplying                        = React.useCallback(setReplying,[setReplying]);
    const handleDeleting                        = React.useCallback(setDeleting, [setDeleting]);
    const handleViewChildrenToggle              = React.useCallback(() => setViewChildren(prev => !prev), [setViewChildren]);

    const colorAuto                             = React.useMemo(() => theme.palette.mode === 'light' ? "primary" : "secondary", [theme.palette.mode]);
    const viewChildrenButton                    = React.useMemo(() => (hasComments || childCount > 0 || counting || loading) && !isRoot, [childCount, counting, hasComments, isRoot, loading]);
    const isOwner                               = React.useMemo(() => data?.user?.id && data?.user?.id === userId, [data?.user?.id, userId]);
    const highlight                             = React.useMemo(() => replying || deleting || loadingDeleting || editing, [deleting, editing, loadingDeleting, replying]);

    React.useEffect(() => {
        setBlurChildren(editing || replying || deleting);
    },[editing, replying, deleting])

    React.useEffect(() => {
        if(replying)
            setViewChildren(true); 
    },[replying, setViewChildren])

    return (
        <SideRibbonContainer sideRibbon = {queriedParent && !isRoot && !highlight} framed={false} style={{borderLeftColor : colorBorder, position:'relative'}}>
            <CommentContainer 
                replying = {replying} 
                deleting = {deleting || loadingDeleting} 
                editing  = {editing} 
                buffer   = {highlight}  
                style    = {{
                    position    : 'absolute', 
                    top         : 0,
                    bottom      : 0,
                    left        : -gravatarSizeParent/2, 
                    right       : 0,
                    width       :`calc(100% + ${gravatarSizeParent/2}px + ${theme.spacing(2)})`
                }} 
            />
            {
                isRoot && queriedParent && 
                <Box ml={1} pb={1} position = "relative" >
                    {   !isOwner && !data?.comment && 
                        <SideRibbon gravatarSize={gravatarSize} colorBorder={colorBorder} /> 
                    }
                    <Box width="100%">
                        {
                            data?.comment &&
                            <Box id = "commentSeedContainer" mb={1}>
                                <CommentContainer display = 'flex' width="100%" replying={true} buffer={true}>
                                    <Box flexShrink={1} style={{paddingTop:theme.spacing(0.5),paddingBottom:theme.spacing(0.5)}}>
                                        <Gravatar size={gravatarSize} emailMD5={data?.user?.emailMD5} style={{border:`1px solid ${colorBorder}`}}/>
                                    </Box>
                                    <Box flexGrow={1} pl={1}>
                                        <Box>
                                            <CommentsHeader data = {data} newComment = {false} expandable={false}/>
                                        </Box>
                                        <CommentsContent 
                                            comment         = {data?.comment}
                                            replying        = {false}
                                            replyingText    = {t('components.comments.replyingText')}
                                            deletingText    = {t('components.comments.deletingText')}
                                        />
                                    </Box>
                                </CommentContainer>
                            </Box>
                        }
                        {
                            !isOwner &&
                            <Box id = "commentCreateContainer" pb={0.5}>
                                <Box display = 'flex' width="100%">
                                    <Box flexShrink={1} style={{position:'relative' , top:theme.spacing(0.5), paddingBottom:theme.spacing(1), marginBottom:theme.spacing(-1)}}>
                                        {data?.comment && <SideRibbon gravatarSize={gravatarSize} colorBorder={colorBorder} /> } 
                                        <Gravatar size={gravatarSize} emailMD5={emailMD5} style={{border:`1px solid ${colorBorder}`}}/>
                                    </Box>
                                    <Box flexGrow={1} pl={1}>
                                        <CommentForm 
                                            disabled = {loading}
                                            formData = {{
                                                parentId    : parentId, 
                                                id          : undefined,    
                                                comment     : undefined
                                            }}
                                            FormProps = {{
                                                SubmitButtonProps : {size:'small'},
                                                CancelButtonProps : {size:'small'},
                                                ResetButtonProps  : {size:'small'},
                                            }}
                                            onSubmit            = {handleSubmitComment}
                                            enterToShowButtons  = {true}
                                            placeholder         = {t('components.comments.writeComment')}
                                            alertComponent      = {CommentsAlert}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        }
                        {
                            isOwner &&
                            <Box pt={1} style={{marginLeft:theme.spacing(-0.5),marginRight:theme.spacing(-0.5)}}>
                                <CommentsAlert severity="warning">
                                    <Typography align="center" variant="body2">
                                        {t('components.comments.cantReplyToSelf')}
                                    </Typography>
                                </CommentsAlert>
                            </Box>
                        }
                    </Box>
                </Box>
            }

            <Box>
                {
                    isRoot && (false || !queried) &&
                    Array(1).fill(undefined).map((_,ix) => (
                        <Box position="relative" ml={1} key={ix}>
                            <SideRibbonContainer sideRibbon={false} style={{marginLeft:gravatarSizeParent/2,borderLeftColor : colorBorder}}>
                                <Box style={{marginLeft:-gravatarSizeParent/2, /*background:theme.palette.background.paper*/ }}>
                                    <SkeletonComment circleSize={gravatarSizeParent}/>
                                </Box>
                                <RootSeparator showBorder={!highlight} color={colorBorder}/>
                            </SideRibbonContainer>
                        </Box>
                    ))
                }
                {   
                    !isRoot && !queriedParent &&
                    <Box position="relative" style={{left : -gravatarSizeParent/2, width : `calc(100% + ${gravatarSizeParent/2}px)`, /*background:theme.palette.background.paper*/}}>
                        <SkeletonComment circleSize={gravatarSizeParent}/>
                    </Box>
                }
                {
                    !isRoot && queriedParent &&
                    <Box display="flex" style={{position:'relative',left : -gravatarSizeParent/2,width : `calc(100% + ${gravatarSizeParent/2}px)`}}>
                        <Box flexShrink={1} style={{position:'relative',top:theme.spacing(0.75)}}>
                            <Gravatar size={gravatarSizeParent} emailMD5={data?.user?.emailMD5} style={{border:`1px solid ${colorBorder}`}}/>
                        </Box>
                        <Box flexGrow={1} pl={1} margin='auto'>
                            <Box>
                                <CommentsHeader 
                                    loading     = {loading} 
                                    data        = {data} 
                                    newComment  = {newComment} 
                                    expandable  = {!blurChildren}
                                />
                            </Box>
                            <Box>
                                <CommentsBody  
                                    data        = {data} 
                                    loading     = {loading} 
                                    deleting    = {loadingDeleting}
                                    level       = {level}
                                    onReply     = {handleSubmitComment} 
                                    onEdit      = {handleEditComment} 
                                    onDelete    = {handleDeleteComment}
                                    onEditing   = {handleEditing}
                                    onReplying  = {handleReplying}
                                    onDeleting  = {handleDeleting}
                                    canEdit     = {!data?.deleted && !data?.newComment}
                                    newComment  = {newComment}
                                />
                            </Box>
                        </Box>
                    </Box>
                }
                <Box style={{position:'relative'}}>

                    { 
                        queriedParent && !isRoot && !newComment && !(blurChildren && !hasComments) && !data?.deleted && 
                        <Box display="flex" style={{paddingLeft:gravatarSizeParent/2}}>
                            <Box style={{color:colorBorder}}>
                                <Button 
                                    disabled    = {loading || counting || !viewChildrenButton}  
                                    color       = {colorAuto}
                                    size        = "small" 
                                    startIcon   = {<ArrowRightIcon rotate = {viewChildren && childCount > 0 ? 90 : 0} /> } 
                                    onClick     = {handleViewChildrenToggle} 
                                >
                                    { ( counting || loading)                        && t('components.comments.searching') }
                                    { (!counting && !loading) && childCount <= 0    && t('components.comments.noReplies') }
                                    { (!counting && !loading) && childCount > 0     && 
                                        <> 
                                            { viewChildren 
                                                ? t('components.comments.hideReplies', {count:childCount}) 
                                                : t('components.comments.showReplies', {count:childCount})
                                            }
                                        </> 
                                    }
                                </Button>
                            </Box>
                            <Box>
                                <IconButton color = {colorAuto} onClick={refresh} disabled={loading} size="small">
                                    <RefreshIcon loading={loading || loadingDeleting} />
                                </IconButton>
                            </Box>
                        </Box>
                    }

                    {   (viewChildren || isRoot) && !data?.deleted && 
                        <Box style={{paddingLeft:gravatarSizeParent/2}}>

                            {
                                (comments || []).filter(c => c.newComment).map((data,ix) => (
                                    <Box key={ix} pl={1}>
                                        <CommentsTree 
                                            parentId    = { data?.id } 
                                            level       = { level + 1 } 
                                            master      = { false }
                                            newComment  = { true }
                                            borderColor = { colorBorder }
                                        />
                                    </Box>
                                ))
                            }

                            {
                                (comments || []).filter(c => !c.newComment).map((data,ix) => (
                                    <Box key={ix} pl={1}>
                                        <CommentsTree 
                                            parentId    = { data?.id } 
                                            level       = { level + 1 } 
                                            master      = { false }
                                            borderColor = { colorBorder }
                                        />
                                    </Box>
                                ))
                            }

                            { (hasComments || isRoot) &&
                                <Box style={{position:'relative',left:-BORDER_THICKNESS}}>
                                    <CommentsShowMoreRepliesButton 
                                        color               = {colorBorder} 
                                        loading             = {loading} 
                                        hasMore             = {hasMore}
                                        allowRefresh        = {isRoot || comments.filter(c => c.deleted || c.censored || c.newComment).length > 0}
                                        onClick             = {queryMore} 
                                        onClickRefresh      = {refresh}
                                    />  
                                </Box>
                            }

                        </Box>
                    }
                    {blurChildren &&
                        <BlurShield />
                    }
                </Box>
            </Box>

            {
                !isRoot && !highlight &&
                <RootSeparator showBorder={!highlight} color={colorBorder}/>
            }
        </SideRibbonContainer>
    )
};

export default CommentsTree;