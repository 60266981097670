/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Skeleton Library Entries
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       20th November 2020

*******************************************************************************************/

import React                            from 'react';
import { Box }                          from '@mui/material';
import { Skeleton }                     from '@mui/material';
import { useSize }                      from 'hooks';
import { 
    ContainerHorizontal, 
    ContainerVertical 
}                                       from './components';

export const SkeletonEntries = ({itemHeight = 60, ...props}) => {

    const ref       = React.useRef();
    const {height}  = useSize(ref);

    return (
        <ContainerVertical align="center" width="100%" height="100%" spacing={0.5} ref={ref} {...props}>
            <Box>
                <ContainerHorizontal>
                    <Box width="50%">
                        <Skeleton variant="text" width="100%" />
                    </Box>
                    <Box flexGrow={1}/>
                </ContainerHorizontal>
                <ContainerHorizontal>
                    <Box width="75%">
                        <Skeleton variant="text" width="100%" />
                    </Box>
                    <Box flexGrow={1}/>
                </ContainerHorizontal>
            </Box>
            <ContainerVertical spacing={0.25} flexGrow={1} overflow={"hidden"}>
                {
                    Array(Math.ceil(height/itemHeight)).fill(undefined).map((_,ix) => (
                        <Box height={itemHeight} key={ix} width="100%">
                              <Skeleton variant="rectangular" width="100%" height="100%"/>  
                        </Box>
                    ))
                }
            </ContainerVertical>
        </ContainerVertical>
    );
};

export default SkeletonEntries;