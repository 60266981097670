/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Section Container
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       10th December 2022

*******************************************************************************************/
import {  styled, Box } from '@mui/material';

export const SectionContainer= styled(Box)(({theme}) => ({
    position : 'relative',
    [theme.breakpoints.down('lg')] : {
        padding         : theme.spacing(6),
    },
    [theme.breakpoints.down('md')] : {
        padding         : theme.spacing(4),
    },
    [theme.breakpoints.down('sm')] : {
        padding         : theme.spacing(2),
    },
    padding         : theme.spacing(8),
    paddingBottom   : `${theme.spacing(0)}!important`,
}));

export default SectionContainer;