
export const constants = {
    ids : {
        about               : 'about',
        examples            : 'examples',
        examplesArchives    : 'examplesArchives',
        crossSell           : 'crossSell',
        libraryPrivate      : 'libraryPrivate',
        libraryPublic       : 'libraryPublic',
        testimonials        : 'testimonials',
        videos              : 'videos',
        hero                : 'hero',
        archives            : 'archives'
    }
}

export default constants;