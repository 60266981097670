
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
convenience hook to determine if query can be performed
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       8th March 2022

*******************************************************************************************/
import React            from 'react';
import { useUser }      from 'contexts/UserContext';
import { useNetwork }   from 'contexts/NetworkContext';

export const useCanQuery = () => {

    const { 
        isNetworkReady, 
        socketUsers
    }                       = useNetwork();
    const { 
        isAdmin, 
        isAuthenticated, 
        ready // JWT Ready
    }                       = useUser();

    // conditions
    const allowQueryForAnonymous  = isNetworkReady;
    const allowQueryForUser       = [allowQueryForAnonymous, isAuthenticated, ready, socketUsers].every(Boolean);
    const allowQueryForAdmin      = [allowQueryForUser, isAdmin].every(Boolean);

    const memoisedState = React.useMemo(() => ({
        allowQueryForAnonymous,
        allowQueryForUser,
        allowQueryForAdmin
    }), [allowQueryForAdmin, allowQueryForAnonymous, allowQueryForUser])

    return memoisedState;
};

export default useCanQuery;