/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Not Found Component
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       16th October 2021

*******************************************************************************************/

import React                    from 'react';
import PageContainer            from 'components/PageContainer';
import Component                from 'components/errors/NotFound';
import Seo                      from 'components/Seo';

export const NotFound = (props) => {
    return (
        <PageContainer breadcrumbs={true}>
            <Seo
                title           = { "Not Found"}
                description     = { `Error Not Found` }
            />
            <Component {...props} />
        </PageContainer>
    )
}

export default NotFound;