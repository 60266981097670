/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
View User Button
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       29th January 2023

*******************************************************************************************/
import React                            from 'react';
import {
    Box,
}                                       from '@mui/material';
import {
    Gravatar,
    DraggableDialog,
    LoadingData
}                                       from 'components';
import User                             from '../views/admin/users/User';
import {
    useUser,
    useNetwork,
    useAlert,
}                                       from 'contexts';
import { useCancelToken }               from 'hooks';

export const ViewUserButton = ({id, emailMD5, name, size = 25, disabled = false}) => {
    const {isAdmin}                     = useUser();
    const { axios, isNetworkReady }     = useNetwork();
    const { cancelToken, isCancel }     = useCancelToken();
    const { alert }                     = useAlert();
    const [data,    setData]            = React.useState(undefined);
    const [working, setWorking]         = React.useState(false);
    
    const handleViewUser = React.useCallback(() => {
        if(isAdmin && isNetworkReady){
            setWorking(true);
            axios.get(`/api/admin/user/${id}`, {cancelToken})
                .then(({data}) => {
                    alert("User Retrieved",'info');
                    return data
                })
                .then(setData)
                .catch(err => {
                    if(isCancel(err)) return
                    alert(err.message,'error')
                })
                .finally(() => {
                    setWorking(false);
                })
        }
    },[alert, axios, cancelToken, id, isAdmin, isCancel, isNetworkReady])

    const handleCloseUser = React.useCallback(() => setData(undefined),[]);

    if(!id) 
        return null

    return (
        <>
            <Box display="flex" onClick={!working && !disabled && isAdmin ? handleViewUser : null} sx={{width:'fit-content', cursor : !working && !disabled && isAdmin ? 'pointer' : 'unset'}}>
                <Box sx={{my:'auto'}}>
                    <Gravatar emailMD5={emailMD5} size={size}/>
                </Box>
                <Box sx={{ml:0.5, my:"auto"}}>
                    {name}
                </Box>
                {
                    working && 
                    <Box sx={{ml:0.5, my:"auto"}}>
                        <LoadingData label={null}/>
                    </Box>
                }
            </Box>
            {
                isAdmin &&
                <DraggableDialog 
                    title               = {"View User"} 
                    open                = {Boolean(data)} 
                    onClose             = {handleCloseUser} 
                    onCancel            = {handleCloseUser} 
                    showButtons         = {false}
                    maxWidth            = "sm"
                    fullWidth           = {true}
                >
                    <User user={data} disabled={disabled} scrollable={false}/>
                </DraggableDialog>
            }
        </>
    )
}

export default ViewUserButton;