/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Routes
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       22nd December 2020

*******************************************************************************************/
import React, {lazy}                    from 'react';
import {
    Route, 
    Switch as Routes, // NB: in v6 switch will be replaced by 'routes'
}                                       from 'react-router-dom';

// Locations
import * as locations                   from './locations'; 

// Layout
import Home                             from 'views/main/home';
import About                            from 'views/main/About';
import FAQ                              from 'views/main/FAQ';
import Health                           from 'views/main/Health';
import Radix                            from 'views/main/Radix';
import Founders                         from 'views/main/Founders';

import NotFound                         from 'views/error/NotFound';
import Forbidden                        from 'views/error/Forbidden';

const Shop                              = lazy(() => import('../views/main/Shop'))
const Product                           = lazy(() => import('../views/main/Product'))
const AdminRouter                       = lazy(() => import('./AdminRouter'));
const ArchiveRouter                     = lazy(() => import('./ArchiveRouter'));
const ErrorRouter                       = lazy(() => import('./ErrorRouter'));
const PolicyRouter                      = lazy(() => import('./PolicyRouter'));
const UserRouter                        = lazy(() => import('./UserRouter'));
const VerifyRouter                      = lazy(() => import('./VerifyRouter'));
const ResourceRouter                    = lazy(() => import('./ResourceRouter'));
const BlogRouter                        = lazy(() => import('./BlogRouter'));
const PreviewRouter                     = lazy(() => import('./PreviewRouter'));

export const MainRouter = () => {

    return (
        <Routes>
            <Route path={locations.VerifyLocation.path}                         component={VerifyRouter} /> 
            <Route path={locations.AdminLocation.path}                          component={AdminRouter} />
            <Route path={locations.UserLocation.path}                           component={UserRouter} />
            <Route path={locations.PolicyLocation.path}                         component={PolicyRouter} />
            <Route path={locations.ErrorLocation.path}                          component={ErrorRouter} />
            
            <Route path={locations.ExamplesCollectionLocation.path}             component={ArchiveRouter} /> 
            <Route path={locations.ArchiveCollectionLocation.path}              component={ArchiveRouter} /> 

            <Route path={locations.BooksCollectionLocation.path}                component={ResourceRouter} />
            <Route path={locations.ResourceCollectionLocation.path}             component={ResourceRouter} />

            <Route path={locations.BlogLocation.path}                           component={BlogRouter} />
            <Route path={locations.PreviewLocation.path}                        component={PreviewRouter} />

            <Route path={locations.ProductLocation.path}                        component={Product} />
            <Route path={locations.ShopLocation.path}                           component={Shop} />
            
            <Route path={locations.AboutLocation.path} exact                    component={About} />
            <Route path={locations.FoundersLocation.path} exact                 component={Founders} />
            <Route path={locations.FAQLocation.path} exact                      component={FAQ} />
            <Route path={locations.HomeLocation.path} exact                     component={Home} />
            <Route path={locations.HealthLocation.path} exact                   component={Health} />
            <Route path={locations.RadixLocation.path}                          component={Radix} />
            <Route path={locations.ApiLocation.path}                            component={Forbidden} />
            <Route path="*"                                                     component={NotFound} />
        </Routes>
    )
}

/*
{
    locations.ProductLocation.toRoute({ component: Product, invalid: NotFound }, true)
}
*/

export default MainRouter;
