/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Exit Intent Promotion
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       10th February 2024

*******************************************************************************************/
import React        from 'react'
import {
    useTheme,
    Box,
    Typography,
}                   from '@mui/material';
import { 
    Title,
    FreeSignTiles,
    Logo
}                   from 'components'

export const ExitIntentPromotion = () => {
    const theme = useTheme();
    return (
        <Box sx={{"& > * + *" : {mt: 2}}}>

            <Box>
                <Logo maxHeight={75} dark={theme.palette.mode !== 'dark'}/>
            </Box>
            
            <Box>
                <Title variant="h6">
                    Check out your FREE Venus Signs
                </Title>
            </Box>
            
            <Box>
                <Typography variant={"body2"} paragraph align="justify">
                    Your Venus placement is a deeply personal and individualised aspect of your astrological makeup, 
                    one that is entirely yours and cannot be replicated by anyone else. 
                </Typography>

                <Typography variant={"body2"} paragraph>
                    Get your FREE Venus Sign and Sabian Symbol Below!
                </Typography>
            </Box>

            <Box sx={{mt:4}}>
                <FreeSignTiles gridItemProps={{xs:12,sm:12,md:12}}/>
            </Box>
        </Box>
    )
}

export default ExitIntentPromotion;