/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Tickets Admin Alert
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       9th December 2021

*******************************************************************************************/
import React                            from 'react';
import { Box }                          from '@mui/material';
import { Alert } from '@mui/material';
import { Button }                       from 'components';
import {
    useTicket,
    useUser
}                                       from 'contexts';

export const TicketsAdminAlert = ({
    buttonProps = {},
    ...props
}) => {
    // const classes                               = useStyles(props);
    const {
        admin,
        working,
        refresh,
        newTicketCount,
        newTicketMessageCount,
        newTicketClosedCount
    }                                           = useTicket();
    const {isAuthenticated, isAdmin}            = useUser();

    // User Not Authenticated
    if(!isAuthenticated)
        return null;

    // User isn't admin
    if(!isAdmin)
        return null;

    // Not admin mode
    if(!admin)
        return null;

    const showAlert = Boolean([newTicketCount, newTicketMessageCount, newTicketClosedCount].reduce((a,c) => a + c, 0));

    // Determine Severity
    let severity;
    if(newTicketClosedCount > 0 && !newTicketCount && !newTicketMessageCount)
        severity = 'error';
    else 
    if(newTicketCount > 0 && !newTicketMessageCount && !newTicketClosedCount)
        severity = 'info'
    else 
        severity = "warning";

    // Build the phrases
    const phrases = [
        newTicketCount          ? `new tickets`     : undefined,
        newTicketMessageCount   ? `new messages`    : undefined,
        newTicketClosedCount    ? `closed tickets`  : undefined
    ].filter(Boolean)
        .map((phrase, ix, arr) => (
            <React.Fragment>
                {arr.length >= 2 && ix === arr.length - 2 ? ' and ' : ' '}<strong>{phrase}</strong>
            </React.Fragment>
        ))

    if(!showAlert)
        return null;

    return (
        <Box pb={1}>
            <Alert 
                severity = {severity}
                action={
                    <Button 
                        disabled    = {working} 
                        onClick     = {refresh} 
                        variant     = "contained" 
                        color       = "primary" 
                        size        = "small"
                        {...buttonProps}
                    >
                        Refresh
                    </Button>
                }
                {...props}
            >
                There have been {phrases} submitted since you last queried the server
            </Alert>
        </Box>
    )
}

export default TicketsAdminAlert;