/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Prettu Number
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       21st January 2023

*******************************************************************************************/
import React from 'react';

// Function to Prettyfy a Large Number
const pretty            = (value, args) => {
    const suffixes = ['K', 'M', 'B', 'T', 'P', 'E'];
    if (!value)                 return null;
    if (Number.isNaN(value))    return null;
    if (value < 1000)           return value;
    const exp           = Math.floor(Math.log(value) / Math.log(1000));
    const returnValue   = (value / Math.pow(1000, exp)).toFixed(args) + suffixes[exp - 1];
    return returnValue;
};

export const PrettyNumber = ({value = 0, precision = 1}) => (
    <React.Fragment>
        { pretty(value, precision) }
    </React.Fragment>
)

export default PrettyNumber;