/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Archives
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       28th December 2022

*******************************************************************************************/
import React                from 'react';
import { 
  useMediaQuery,
  Box 
}                           from '@mui/material';
import { ArchiveCrossSell } from 'components';
import constants            from '../constants';

export const Archives = (props) => {
    const smDown = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <Box id={constants.ids.archives} {...props} sx={{mx:-2,overflow:'hidden',...props.sx}}>
            <Box sx={{mx:-5}}>
                <ArchiveCrossSell quantity={smDown ? 4 : 6} showTitle={false} auto={false} imageWidth={500}/>
            </Box>
        </Box>
    )
}

export default Archives