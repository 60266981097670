/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Count Down
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       27th July 2021

*******************************************************************************************/
import React            from 'react';
import { styled, Box }  from '@mui/material';
import moment           from 'moment';
import { useClock }     from 'hooks';

const noop = () => {};

// Absolute value and left-pad Function
const absPad = x => Math.abs(x || 0).toString().padStart(2,'0');

const formatterFunction = ({prefix,days,hours,minutes,seconds}) => `${prefix}${absPad(days)}:${absPad(hours)}:${absPad(minutes)}:${absPad(seconds)}`;

const TimerContainer = styled(Box,{
    shouldForwardProp : prop => prop !== 'expiring'
})(({theme, expiring = false}) => ({
    width : '100%',
    ...(expiring && {
        color       : theme.palette.error.main,
        fontWeight  : 800
    })
}))

export const CountDown = ({
    to,
    finishingSeconds    = 60,
    allowNegative       = false,
    onExpire            = noop,
    force               = false,
    formatter           = formatterFunction,
    prefix              = undefined,
    finishedValue       = undefined,
    period              = 250,
    ...props
}) => {

    // Check
    if(!moment.isMoment(to))
        throw new Error('to must be a moment');
    if(isNaN(period) || period < 0)
        throw new Error('period must be positive numeric')

    const {render, ...rest} = props;

    const {now}                         = useClock(period);

    // The Duration
    const [duration,    setDuration]    = React.useState(undefined);

    // Formatted Result
    const [result,      setResult]      = React.useState(undefined); 

    // Is Finished
    const [finished,    setFinished]    = React.useState(false);

    // CountDown
    React.useEffect(() => {

        let delta, duration;
            
        // Difference
        delta = to - now;

        // Callback
        if(delta <= 0 && delta >= -Math.abs(period)){
            onExpire();
        }

        // If negatives not allowed, then lower bound at zero
        delta = allowNegative ? delta : Math.max(delta,0); 

        // Convert to duration
        duration = moment.duration(delta, 'milliseconds')

        // Set state duration variable
        setDuration(duration);
        setFinished(delta <= 0);
        
    },[allowNegative, now, onExpire, period, to])

    // Function to format the duration
    const formatDuration = React.useCallback((duration) => {

        // Not a duration
        if(!moment.isDuration(duration)) 
            return null;

        // Is duration in past?
        const isNeg = duration < 0;

        // Build the various elements
        const   days    = Math.floor(duration.asDays()),
                hours   = duration.hours(),
                minutes = duration.minutes(),
                seconds = duration.seconds();

        // The Prefix
        const prefix = isNeg ? '-' : ''
        
        // Now build the formatted duration
        const formattedDuration = formatter({ prefix, days, hours, minutes, seconds, duration});

        // done
        return formattedDuration;

    },[formatter])

    // Set the result when duration/formatDuration changes
    React.useEffect(() => {
        setResult(
            formatDuration(duration)
        );
    },[formatDuration, duration])

    // Is Finishing or Not
    const isFinishing = React.useMemo(() => (
        !isNaN(finishingSeconds) 
            ? duration < finishingSeconds * 1000 
            : false
    ), [duration, finishingSeconds]);

    // Done, Render
    return (
        <TimerContainer component="span" {...rest} expiring={isFinishing}>
            { 
                render && 
                render({countDown:result, duration, isFinishing, finished})}
            {
                !render && (!finished || !finishedValue) &&
                <>
                    {prefix} {result}
                </>
            }
            {
                !render && (finished && finishedValue) &&
                <>
                    {finishedValue}
                </>
            }
        </TimerContainer>
    )
}

export default CountDown;