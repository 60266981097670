/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Ticket Form
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       8th December 2021

*******************************************************************************************/
import React                            from 'react';
import {omitBy,isNil,isEmpty}           from 'lodash';
import moment                           from 'moment';
import flat                             from 'flat';
import { 
    styled, alpha, Grid, 
    Box, Paper, MenuItem, 
    Typography 
}                                       from '@mui/material';
import TicketIcon                       from '@mui/icons-material/ConfirmationNumber'
import {
    Form,
    JSONViewer,
    Button,
    ViewProductButton,
    ViewOrderButton
}                                       from 'components';
import {
    useTicket,
    useProduct,
    useOrders,
    useLocale,
    useLibrary,
    useTranslation
}                                       from 'contexts';
import { 
    TextField,
    Select,
    showErrorOnChange   as showError
}                                       from 'mui-rff';
import {
    FormSpy
}                                       from 'react-final-form';

const UserPaper = styled(Paper)(({theme}) => ({
    padding         : theme.spacing(1),
    paddingBottom   : theme.spacing(2),
    borderRadius    : theme.spacing(0.5),
    border          : `1px solid ${theme.palette.divider}`,
    background      : alpha(theme.palette.primary.main,0.15),
    display         : 'flex',
    flexDirection   : 'column',
    height          : '100%'
}));

const SystemPaper = styled(Paper)(({theme}) => ({
    padding         : theme.spacing(1),
    paddingBottom   : theme.spacing(2),
    borderRadius    : theme.spacing(0.5),
    border          : `1px solid ${theme.palette.divider}`,
    background      : alpha(theme.palette.info.light,0.15)
}));

const TEXT_SIZE = '0.6rem'
const TextContainer = styled(Box)(({theme}) => ({
    fontSize : TEXT_SIZE
}));

const noop = () => {}
const obj = {}

const ALLOW_LEGAL_QUERY = false;
const GRID_SPACING = 1;


const defaultFormData = {
    category        : undefined,
    manager         : undefined,
    user            : undefined,
    subject         : undefined,
    text            : undefined,
    inRelationTo : {
        order       : undefined,
        delivery    : undefined,
        library     : undefined,
        product     : undefined
    },
}

export const TicketForm = ({
    formData                = defaultFormData,
    responsive              = false,
    textRows                = 5,
    variant                 = undefined,
    readOnly                = false,
    disabled : disabledIn   = false,
    allowEditDropdowns      = true,
    onSubmit : handleSubmit = noop,
    onCancel : handleCancel = noop,
    FormProps               = obj,
    allowLibrary            = true,
    allowOrder              = true,
    allowDelivery           = true,
    ...rest
}) => {
    const {t} = useTranslation();
    const {
        categories : categoriesOriginal,
        categoriesRequireProduct,
        categoriesRequireOrder,
        categoriesRequireDelivery,
        categoriesRequireLibrary
    }                                   = useTicket();
    const {data     : productsRaw}      = useProduct();
    const {orders   : ordersData}       = useOrders();
    const {library  : libraryData}      = useLibrary();
    const { 
        formatDateTime, 
        currencyFactor
    }                                   = useLocale();

    const [category,    setCategory]    = React.useState(undefined);
    const [order,       setOrder]       = React.useState(undefined);
    const [product,     setProduct]     = React.useState(undefined);
    const [delivery,    setDelivery]    = React.useState(undefined);
    const [library,     setLibrary]     = React.useState(undefined);

    const products                      = React.useMemo(() => (
        (productsRaw || []).filter(p => !p?.deleted && p?.listed)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [JSON.stringify(productsRaw)]);

    // Dynamically Build Categories
    const categoryLabels = React.useMemo( () => (
        omitBy({
            GENERAL     : t("components.forms.ticketForm.generalInquiry"),
            LEGAL       : ALLOW_LEGAL_QUERY || disabledIn                   
                            ? t("components.forms.ticketForm.legalInquiry")                                                    
                            : undefined,
            PRODUCT     : !isEmpty(products) || disabledIn || readOnly
                            ? t("components.forms.ticketForm.productInquiry")                      
                            : undefined,
            ORDER       : allowOrder && (!isEmpty(ordersData) || disabledIn || readOnly)
                            ? t("components.forms.ticketForm.orderInquiry")    
                            : undefined,
            DELIVERY    : allowDelivery && (!isEmpty(ordersData) || disabledIn || readOnly) 
                            ? t("components.forms.ticketForm.deliveryInquiry")                     
                            : undefined,
            LIBRARY     : allowLibrary && (!isEmpty(libraryData) || disabledIn || readOnly)
                            ? t("components.forms.ticketForm.userLibraryInquiry") 
                            : undefined
        },isNil)
    ), [t, allowDelivery, allowLibrary, allowOrder, disabledIn, libraryData, ordersData, products, readOnly]);

    // Filter Categories
    const categories = React.useMemo(() => (
        categoriesOriginal
            .filter(cat => (
                Object.keys(categoryLabels).includes(cat)
            ))
    ), [categoriesOriginal, categoryLabels]);

    const validate  = React.useCallback( (values) => {
        let errors          = {};
        ['category','subject','text'].forEach(key => {
            if(!values[key])
                errors[key] = errors[key] || t("components.forms.ticketForm.required");
        })
        let {category} = values;
        if(category){
            const maps = {
                product     : categoriesRequireProduct,
                order       : categoriesRequireOrder,
                delivery    : categoriesRequireDelivery,
                library     : categoriesRequireLibrary
            }
            Object.keys(maps).forEach(key => {
                if((maps[key] || []).includes(category)){
                    if(!(values?.inRelationTo || {})[key]){
                        errors[`inRelationTo.${key}`] = errors[`inRelationTo.${key}`] || t("components.forms.ticketForm.required");
                    }
                }
            })
        }
        return flat.unflatten(errors);

    }, [t, categoriesRequireDelivery, categoriesRequireLibrary, categoriesRequireOrder, categoriesRequireProduct]);

    // Handle Submit Wrapper
    const handleSubmitInner = React.useCallback( 
        (formData) => handleSubmit(formData)
    , [handleSubmit]);

    // Initial Values
    const initialValues = React.useMemo(() => ({
        ...formData
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [JSON.stringify(formData)]);

    // Render
    return (
        <Form
            debug               = {false}
            disabled            = {disabledIn}
            onSubmit            = {handleSubmitInner}
            onCancel            = {handleCancel}
            initialValues       = {initialValues}
            validate            = {validate}
            submitText          = { t("components.forms.ticketForm.submitMessage") }
            SubmitButtonProps   = {{
                startIcon : <TicketIcon/>,
                ...rest.SubmitButtonProps
            }}
            {...FormProps}
            render              = {({disabled, form, error, dirtySinceLastSubmit, submitFailed, submitSucceeded, errors, handleSubmit, values, ...rest}) => {

                const libraryOptions = [...libraryData]
                    .sort((a,b) => moment(b.createdAt) - moment(a.createdAt));

                const deliveryOptions = [...ordersData]
                    .filter(x => {
                        if(categoriesRequireLibrary.includes(values?.category)){
                            if(!(values?.inRelationTo?.library)) 
                                return false;
                            return (x.deliveries || []).map(x => x.library).includes(values?.inRelationTo?.library)
                        }
                        return true
                    })
                    .sort((a,b) => moment(b.createdAt) - moment(a.createdAt))
                    .reduce((a,c) => a.concat(c.deliveries),[]);
                
                const orderOptions = [...ordersData]
                    .filter(x => {
                        if(categoriesRequireLibrary.includes(values?.category)){
                            if(!(values?.inRelationTo?.library)) 
                                return false;
                            return (x.deliveries || []).map(x => x.library).includes(values?.inRelationTo?.library)
                        }
                        return true
                    })
                    .filter(x => {
                        if(categoriesRequireDelivery.includes(values?.category)){
                            if(!(values?.inRelationTo?.delivery)) 
                                return false;
                            return (x.deliveries || []).map(x => x._id).includes(values?.inRelationTo?.delivery)
                        }
                        return true
                    })
                    .sort((a,b) => moment(b.createdAt) - moment(a.createdAt));

                const argsL         = responsive ? {xs:12,sm:6,md:5} : {xs:12};
                const argsR         = responsive ? {xs:12,sm:6,md:7} : {xs:12};
                const validCategory = categories.includes(values?.category);
                return (
                    <form onSubmit={handleSubmit} noValidate>

                        <Grid container>
                            <Grid item {...argsL}>
                                <SystemPaper>
                                    <Box display="flex">
                                        <Box>
                                            <Typography>
                                                { t("components.forms.ticketForm.ticketAssignmentDetails") }
                                            </Typography>
                                        </Box>
                                        {
                                            !allowEditDropdowns && 
                                            <Box pl={0.5} style={{marginTop:'auto', marginBottom:'auto'}}>
                                                <Typography style={{fontStyle:'italic'}} variant="body2">
                                                    ({ t("components.forms.ticketForm.systemGenerated") })
                                                </Typography>
                                            </Box>
                                        }
                                    </Box>
                                    <Grid container spacing={GRID_SPACING}>
                                        <Grid item xs={12}>
                                            <Select 
                                                disabled        = {disabled}
                                                variant         = {variant}
                                                name            = {'category'} 
                                                label           = { t("components.forms.ticketForm.category") }
                                                showError       = {showError}
                                                inputLabelProps = {{ shrink : true }}
                                                inputProps      = {{readOnly : readOnly || !allowEditDropdowns}}
                                                helperText      = {
                                                    !readOnly ? (
                                                        categories.length 
                                                            ? t("components.forms.ticketForm.categoryHelperText")
                                                            : t("components.forms.ticketForm.categoryHelperTextNone")
                                                        ) 
                                                    : null
                                                }
                                            >
                                                {
                                                    categories
                                                        .filter(x => x !== 'DELIVERY')
                                                        .map((category,ix)=>(
                                                            <MenuItem 
                                                                key     = {ix} 
                                                                value   = {category}
                                                            >
                                                                <TextContainer>
                                                                    {categoryLabels[category]}
                                                                </TextContainer>
                                                            </MenuItem>
                                                        ))
                                                }
                                            </Select>
                                        </Grid>
                                        {
                                            Boolean(categoriesRequireProduct.includes(values?.category) && validCategory) && 
                                            <Grid item xs={12}>
                                                <Box display="flex">
                                                    <Box flexGrow={1}>
                                                        <Select 
                                                            disabled        = {disabled}
                                                            variant         = {variant}
                                                            name            = {'inRelationTo.product'} 
                                                            label           = { t("components.forms.ticketForm.product") }
                                                            showError       = {showError}
                                                            inputLabelProps = {{ shrink : true }}
                                                            inputProps      = {{readOnly : readOnly || !allowEditDropdowns || values?.category !== 'PRODUCT'}}
                                                            helperText      = {
                                                                !readOnly ? (
                                                                products.length 
                                                                    ? t("components.forms.ticketForm.productHelperText")
                                                                    : t("components.forms.ticketForm.productHelperTextNone")
                                                                ) : null
                                                            } 
                                                        >
                                                            {
                                                                products
                                                                    .map((product,ix)=>(
                                                                    <MenuItem 
                                                                        key     = {ix} 
                                                                        value   = {product.id}
                                                                    >
                                                                        <TextContainer>
                                                                            {product.name}
                                                                        </TextContainer>
                                                                    </MenuItem>
                                                                ))
                                                            } 
                                                        </Select>
                                                    </Box>
                                                    {
                                                        false && 
                                                        <Box pl={1} style={{margin:'auto'}}>
                                                            <ViewProductButton productId={values.inRelationTo.product} />
                                                        </Box>
                                                    }
                                                </Box>
                                            </Grid>
                                        }

                                        {
                                            Boolean(categoriesRequireLibrary.includes(values?.category) && validCategory) && 
                                            <Grid item xs={12} sm={12}>
                                                <Box display="flex">
                                                    <Box flexGrow={1}>
                                                        {(disabledIn || readOnly) && 
                                                            <TextField 
                                                                disabled    = {disabled}
                                                                variant     = {variant}
                                                                name        = {'inRelationTo.library'} 
                                                                label       = { t("components.forms.ticketForm.library") }
                                                                showError   = {showError}
                                                                inputProps  = {{readOnly : readOnly || !allowEditDropdowns || values?.category !== 'LIBRARY', sx:{fontSize:TEXT_SIZE}}}
                                                            />
                                                        }
                                                        {!disabledIn && !readOnly &&
                                                            <Select 
                                                                disabled        = {disabled}
                                                                variant         = {variant}
                                                                name            = {'inRelationTo.library'} 
                                                                label           = { t("components.forms.ticketForm.library") }
                                                                showError       = {showError}
                                                                inputLabelProps = {{ shrink : true }}
                                                                inputProps      = {{readOnly : readOnly || !allowEditDropdowns || values?.category !== 'LIBRARY', sx:{fontSize:TEXT_SIZE}}}
                                                                helperText      = {
                                                                    libraryOptions.length 
                                                                        ? t("components.forms.ticketForm.libraryHelperText")
                                                                        : t("components.forms.ticketForm.libraryHelperTextNone")
                                                                }
                                                            >
                                                                {
                                                                    libraryOptions
                                                                        .map((library,ix)=>(
                                                                            <MenuItem 
                                                                                key     = {ix} 
                                                                                value   = {library.id}
                                                                            >
                                                                                <TextContainer>
                                                                                    {library?.id?.toUpperCase()}
                                                                                </TextContainer>
                                                                            </MenuItem>
                                                                        ))
                                                                }
                                                            </Select>
                                                        }
                                                    </Box>
                                                    {
                                                        false && 
                                                        <Box pl={1} style={{marginTop:'auto'}}>
                                                            <Button size="small" variant="contained" color="primary">
                                                                {t("components.forms.ticketForm.viewLibrary")}
                                                            </Button>
                                                        </Box>
                                                    }
                                                </Box>
                                            </Grid>
                                        }

                                        {
                                            Boolean(categoriesRequireOrder.includes(values?.category) && validCategory) && 
                                            <Grid item xs={12} sm={12} md={{LIBRARY:12}[values?.category] || 12}>
                                                <Box display="flex">
                                                    <Box flexGrow={1}>
                                                        {(disabledIn || readOnly) && 
                                                            <TextField 
                                                                disabled    = {disabled}
                                                                variant     = {variant}
                                                                name        = {'inRelationTo.order'} 
                                                                label       = { t("components.forms.ticketForm.order") }
                                                                showError   = {showError}
                                                                inputProps  = {{ 
                                                                    readOnly : readOnly || !allowEditDropdowns || values?.category !== 'ORDER', sx:{fontSize:TEXT_SIZE}
                                                                }}
                                                                
                                                            />
                                                        }
                                                        {!disabledIn && !readOnly &&
                                                            <Select
                                                                disabled        = {disabled}
                                                                variant         = {variant}
                                                                name            = {'inRelationTo.order'} 
                                                                label           = { t("components.forms.ticketForm.order") }
                                                                showError       = {showError}
                                                                inputLabelProps = {{ shrink : true }}
                                                                inputProps      = {{ readOnly : readOnly || !allowEditDropdowns || values?.category !== 'ORDER'}}
                                                                helperText      = {
                                                                    orderOptions.length 
                                                                        ? t("components.forms.ticketForm.orderHelperText") 
                                                                        : t("components.forms.ticketForm.orderHelperTextNone")
                                                                }
                                                            >
                                                                {
                                                                    orderOptions
                                                                        .map((order,ix) => {
                                                                            let quantityDeliveries = order?.deliveries?.length || 0;
                                                                            return (
                                                                                <MenuItem 
                                                                                    key     = {ix} 
                                                                                    value   = {order.id}
                                                                                >
                                                                                    <TextContainer>
                                                                                        <Box>
                                                                                            {order?.id?.toUpperCase()}
                                                                                        </Box>
                                                                                        <Box>
                                                                                            { t("components.forms.ticketForm.orderCostSummary", {cost : order.total/currencyFactor, quantityDeliveries, date: formatDateTime(moment(order.createdAt)) }) }
                                                                                        </Box>
                                                                                    </TextContainer>
                                                                                </MenuItem>
                                                                            )
                                                                        })
                                                                }
                                                            </Select>
                                                        }
                                                    </Box>
                                                    {
                                                        false &&
                                                        <Box pl={1} style={{margin:'auto'}}>
                                                            <ViewOrderButton orderId={values.inRelationTo.order} dialogProps = {{style : {zIndex:'1301'}}}/>
                                                        </Box>
                                                    }
                                                </Box>
                                            </Grid>
                                        }

                                        {
                                            Boolean(categoriesRequireDelivery.includes(values?.category) && validCategory) && 
                                            <Grid item xs={12} sm={12} md={{LIBRARY:12}[values?.category] || 12}>
                                                {(disabledIn || readOnly) && 
                                                    <TextField 
                                                        disabled    = {disabled}
                                                        variant     = {variant}
                                                        name        = {'inRelationTo.delivery'} 
                                                        label       = { t("components.forms.ticketForm.delivery") }
                                                        showError   = {showError}
                                                        inputProps  = {{ readOnly : readOnly || !allowEditDropdowns || values?.category !== 'DELIVERY', sx:{fontSize:TEXT_SIZE}}}
                                                    />
                                                }
                                                {!disabledIn && !readOnly &&
                                                    <Select 
                                                        disabled        = {disabled}
                                                        variant         = {variant}
                                                        name            = {'inRelationTo.delivery'} 
                                                        label           = { t("components.forms.ticketForm.delivery") }
                                                        showError       = {showError}
                                                        inputLabelProps = {{ shrink : true }}
                                                        inputProps      = {{ readOnly : readOnly || !allowEditDropdowns || values?.category !== 'DELIVERY'}}
                                                        helperText      = {
                                                            deliveryOptions.length 
                                                                ? t("components.forms.ticketForm.deliveryHelperText")
                                                                : t("components.forms.ticketForm.deliveryHelperTextNone")
                                                        }
                                                    >
                                                        {
                                                            deliveryOptions
                                                                .map((delivery,ix) => (
                                                                    <MenuItem 
                                                                        key     = {ix} 
                                                                        value   = {delivery.id}
                                                                    >
                                                                        <TextContainer>
                                                                            <Box>
                                                                                {delivery?.id?.toUpperCase()}
                                                                            </Box>
                                                                            <Typography style={{fontSize:'inherit'}}>
                                                                                { t("components.forms.ticketForm.productName", {name : delivery?.product?.name}) } 
                                                                            </Typography>
                                                                        </TextContainer>
                                                                    </MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                }
                                            </Grid>
                                        }
                                    </Grid>
                                </SystemPaper>
                            </Grid>
                            <Grid item {...argsR}>
                                <UserPaper>
                                    <Box>
                                        <Typography>
                                            { t("components.forms.ticketForm.ticketContents") }
                                        </Typography>
                                    </Box>
                                    <Grid container spacing={GRID_SPACING}>
                                        <Grid item xs={12}>
                                            <TextField 
                                                disabled    = {disabled}
                                                variant     = {variant}
                                                name        = {'subject'} 
                                                label       = { t("components.forms.ticketForm.subject") }
                                                showError   = {showError}
                                                inputProps  = {{ readOnly }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField 
                                                disabled    = {disabled}
                                                variant     = {variant}
                                                name        = {'text'} 
                                                label       = { t("components.forms.ticketForm.message") }
                                                multiline   = {true}
                                                rows        = {textRows}
                                                showError   = {showError}
                                                inputProps  = {{ readOnly }}
                                            />
                                        </Grid>
                                    </Grid>
                                </UserPaper>
                            </Grid>
                        </Grid>

                        {!disabledIn && allowEditDropdowns && !readOnly &&
                            <FormSpy subscription={{values: true}} 
                                onChange = {({values}) => {

                                    if(values?.category && !categories.includes(values?.category)){
                                        form.change('category', undefined);
                                    }else if(!values?.category){
                                        ['product','order','delivery','library'].forEach(k => {
                                            form.change(`inRelationTo.${k}`, undefined); // Reset on Category Change
                                        })
                                    }

                                    if(values?.category !== category){
                                        setCategory(values?.category);
                                        ['product','order','delivery','library'].forEach(k => {
                                            form.change(`inRelationTo.${k}`, undefined); // Reset on Category Change
                                        })
                                    }
                                    if(values?.inRelationTo?.product !== product){
                                        setProduct(values?.inRelationTo?.product);
                                    }
                                    if(values?.inRelationTo?.library !== library){
                                        setLibrary(values?.inRelationTo?.library);
                                        const deliveryId = libraryData.find(x => x._id === values?.inRelationTo?.library)?.delivery?._id;
                                        form.change('inRelationTo.delivery', deliveryId); // Cascade change to delivery form spy
                                    }
                                    if(values?.inRelationTo?.delivery !== delivery){
                                        setDelivery(values?.inRelationTo?.delivery);
                                        const orderId = ordersData.find(o => (o?.deliveries || []).map(d => d?._id).includes(values?.inRelationTo?.delivery))?._id;
                                        form.change('inRelationTo.order', orderId); // Casecade change to order form spy
                                    }
                                    if(values?.inRelationTo?.order !== order){
                                        setOrder(values?.inRelationTo?.order);
                                    }
                                }}
                            />
                        }

                        {false && <JSONViewer src={values} />}
                        {false && <JSONViewer src={ordersData} />}
                        {false && <JSONViewer src={libraryData} />}
                        {false && <JSONViewer src={formData} />}
                        
                    </form>
                )
            }}
        />
    )
}

export default TicketForm;