/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Skeleton Blog Post
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       7th January 2024

*******************************************************************************************/
import React                    from 'react';
import { Box }                  from '@mui/material';
import { Skeleton }             from 'components';

export const SkeletonBlogPost = () => (
    <Box sx={{"& > * + *" : {mt:2}}}>
        <Box sx={{aspectRatio:"2"}}>
            <Skeleton width="100%" height="100%" />
        </Box>
        <Skeleton height="30px" width="80%" sx={{ml:'auto'}} />
        <Box display="flex" flexDirection="column" sx={{"& > * + *" : {mt:1}}}>
            {
                [60,40,20].map((width,ix) => (
                    <Skeleton key={ix} height="20px" width={`${width}%`} sx={{ml:'auto'}} />
                ))
            }
            <Skeleton height="40px" width="35%" sx={{ml:'auto'}} />
        </Box>
        <Box sx={{m:4}}>
            <Skeleton height="150px" width="100%" />
        </Box>
        <Box>
            <Skeleton height="100vh" />
        </Box>
    </Box>
)

export default SkeletonBlogPost;