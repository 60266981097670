/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Currency Component
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       8th July 2021

*******************************************************************************************/
import React                            from 'react';
import isFunction                       from 'lodash/isFunction';
import { Counter }                      from 'components';
import { useLocale }                    from 'contexts';

export const Currency = ({value : valueIn, colorless=false, prefix=undefined, suffix=undefined, decimals = 2, showCurrency = false, ...props}) => {
    
    // Locale Context
    const { 
        formatCurrency, 
        currency,
        currencyFactor
    } = useLocale();

    // Value
    const value = React.useMemo(() => (
        valueIn / currencyFactor
    ), [currencyFactor, valueIn])

    // Formatting Function
    const formattingFn = React.useCallback( (value) => formatCurrency(parseFloat(value) || 0, currency), [currency, formatCurrency]);

    if(props.render && isFunction(props.render)){
        return props.render({
            value, 
            valueFormatted : `${showCurrency ? (currency + ' ') : ''}${prefix ? prefix : ''}${formattingFn(value)}${suffix ? suffix : ''}`, 
            prefix, 
            suffix, 
            currency, 
            currencyFactor, 
            showCurrency
        })
    }

    return (
        <span>
            {showCurrency ? currency : undefined} {prefix}<Counter value = {(value || 0)} colorless={colorless} formattingFn={formattingFn} decimals={decimals}/>{suffix}
        </span>
    )
}

export default Currency;