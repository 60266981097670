/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Resource Form
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       31st December 2022

*******************************************************************************************/
import React                            from 'react';
import { Grid }                         from '@mui/material';
import { Form }                         from 'components';
import { useTranslation }               from 'contexts';
import { 
    TextField,
    showErrorOnChange   as showError,
}                                       from 'mui-rff';

const defaultFormData = {
    asin        : '',
    title       : '',
    review      : '',
    description : ''
}
const noop = () => {};
const obj = {};

export const ResourceForm = ({
    formData                = defaultFormData,
    disabled                = false,
    onSubmit : handleSubmit = noop,
    onCancel : handleCancel = noop,
    FormProps               = obj
}) => {

    const {t}       = useTranslation();
    const validate  = React.useCallback( (values) => {
        
        // Empty Errors
        let errors = {};

        if(!values.asin)
            errors.asin = errors.asin || t('components.forms.resourceForm.required');
        
        const nchars = 10;
        const re = new RegExp(`^([A-Z0-9]{${nchars}})$`);
        if(!re.test(values.asin))
            errors.asin = errors.asin || t('components.forms.resourceForm.requiredAlphaLength', {length : nchars});

        // Done
        return errors;
    }, [t]);

    // Initial Form Data
    const initialValues = React.useMemo(() => ({
        ...formData
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [JSON.stringify(formData)])

    return (
        <Form
            debug           = {false}
            disabled        = {disabled}
            onSubmit        = {handleSubmit}
            onCancel        = {handleCancel}
            initialValues   = {initialValues}
            validate        = {validate}
            {...FormProps}
            render          = {({disabled, form, error, dirtySinceLastSubmit, submitFailed, submitSucceeded, errors, handleSubmit, values, ...rest}) => {
                return (
                    <form onSubmit={handleSubmit} noValidate>
                        <Grid container>
                            {
                                [

                                    {name:'asin',           multiline:false},
                                    {name:'title',          multiline:false},
                                    {name:'description',    multiline:true},
                                    {name:'review',         multiline:true}

                                ].map(({name, multiline} ) => {
                                    const extra = multiline 
                                        ? {multiline:true, minRows:5, maxRows:10} 
                                        : {};
                                    return (
                                        <Grid key={name} item xs={12}>
                                            <TextField 
                                                disabled    = {disabled}
                                                name        = {name} 
                                                label       = {t(`components.forms.resourceForm.${name}`)}
                                                showError   = {showError}
                                                {...extra}
                                            />
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </form>
                )
            }}
        />
    )
}

export default ResourceForm;