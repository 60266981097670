/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
House System Field
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       6th March 2023

*******************************************************************************************/
import React                            from 'react';
import { MenuItem }                     from '@mui/material';
import { Select }                       from 'mui-rff';
import { useTranslation }               from 'contexts';

export const HOUSE_SYSTEM_DATA = [
    {value : "auto",              translationKey : 'common.auto'},
    {value : "placidus",          translationKey : 'common.placidus'},
    {value : "topocentric",       translationKey : 'common.topocentric'},
    {value : "whole-sign",        translationKey : 'common.wholeSign'}  
]

export const HouseSystemField = ({name = 'houseSystem', label = undefined, disabled = false, ...props}) => {

    const {t} = useTranslation();
    const houseSystemData = React.useMemo(() => (
        HOUSE_SYSTEM_DATA
            .map(({value,translationKey}) => ({
                value,
                label : t(translationKey)
            }))
    ),[t]);

    return (
        <Select
            name        = {name}
            label       = {label || t('common.houseSystem')}
            disabled    = {disabled}
            {...props}
        >
            {
                houseSystemData.map(({value,label},ix) => (
                    <MenuItem value={value} key={ix}>
                        {label}
                    </MenuItem>
                ))
            }
        </Select>
    )
}

export default HouseSystemField;
