/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Product Field
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       6th March 2023

*******************************************************************************************/
import React                            from 'react';
import { MenuItem }                     from '@mui/material';
import { Select }                       from 'mui-rff';
import { useProduct, useTranslation }   from 'contexts';

export const ProductField = ({
    name        = "product", 
    label       = undefined, 
    useVirtual  = true,
    usePhysical = false,
    processor   = undefined,
    disabled    = false, 
    ...props
}) => {

    const {data}    = useProduct();
    const {t}       = useTranslation();
    return (
        <Select
            name        = {name}
            label       = {label || t('common.product')}
            disabled    = {disabled}
            {...props}
        >
            {
                data
                    .filter(d => (
                        ((useVirtual && d.isVirtual) || (usePhysical && !d.isVirtual)) && 
                        (!processor || processor === 'all' || d.processor === processor))
                    )
                    .map((p,ix) => (
                        <MenuItem value={p.id} key={ix}>
                            {p.name}
                        </MenuItem>
                    ))
            }
        </Select>
    )
}

export default ProductField;


