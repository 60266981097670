/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Config
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       14th November 2023

*******************************************************************************************/

const ENV               = process?.env?.NODE_ENV;
const PRODUCTION_MODE   = Boolean(ENV === "production");
const DEVELOPMENT_MODE  = Boolean(ENV === "development");

export const config = {
    env                     : ENV,
    production              : PRODUCTION_MODE,
    development             : DEVELOPMENT_MODE,
    company                 : process?.env?.REACT_APP_COMPANY,
    website                 : process?.env?.REACT_APP_WEBSITE,
    publicUrl               : process?.env?.REACT_APP_PUBLIC_URL,
    rootUrl                 : process?.env?.REACT_APP_ROOT_URL,
    staticRootUrl           : process?.env?.REACT_APP_STATIC_ROOT_URL,
    debounce : {
        wait                : 1000,  //ms
        options : {
            leading         : true,
            trailing        : false,
            maxWait         : 1000  //ms
        }
    },
    reviews : {
        scoreMax            : 5
    },
    archive : {
        grayscale           : true
    },
    auth0 : {
        domain              : process?.env?.REACT_APP_AUTH0_DOMAIN,
        namespace           : process?.env?.REACT_APP_AUTH0_NAMESPACE,
        clientId            : process?.env?.REACT_APP_AUTH0_CLIENT_ID
    },
    stripe : {
        publishableKey      : process?.env?.REACT_APP_STRIPE_PUBLISHABLE_KEY
    },
    googleMaps : {
        apiKey              : process?.env?.REACT_APP_GOOGLE_MAPS_API_KEY
    },
    contentful : {
        spaceId             : process?.env?.REACT_APP_CONTENTFUL_SPACE_ID,
        accessToken         : process?.env?.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
        accessTokenPreview  : process?.env?.REACT_APP_CONTENTFUL_ACCESS_TOKEN_PREVIEW
    },
    imageBoss : {
        token               : process?.env?.REACT_APP_IMAGEBOSS_TOKEN
    },
    analytics : {
        idGoogleAnalytics   : process?.env?.REACT_APP_ANALYTICS_ID_GOOGLE_ANALYTICS,
        idGoogleTagManager  : process?.env?.REACT_APP_ANALYTICS_ID_GOOGLE_TAG_MANAGER,
        idClarity           : process?.env?.REACT_APP_ANALYTICS_ID_CLARITY,
        idYandex            : process?.env?.REACT_APP_ANALYTICS_ID_YANDEX,
        idFacebook          : process?.env?.REACT_APP_ANALYTICS_ID_FACEBOOK,
        idPinterest         : process?.env?.REACT_APP_ANALYTICS_ID_PINTEREST,
        idPinterestEmail    : process?.env?.REACT_APP_ANALYTICS_ID_PINTEREST_EMAIL
    },
    adwords : {
        idTracking          : process?.env?.REACT_APP_ADWORDS_TRACKING_ID,
        idConversion        : process?.env?.REACT_APP_ADWORDS_CONVERSION_ID
    },
    cookies : {
        paths : {
            cookieConsent       : "cookieConsent",
            exitIntent          : "exitIntentTriggered"
        }
    }
}

export default config;

