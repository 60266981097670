/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Personal Library
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       1st June 2022

*******************************************************************************************/

import React                from 'react';
import { 
  CardMedia, 
  Typography,
  Box
}                           from '@mui/material';
import {
  Title,
  ContentfulRichText,
  Button
}                           from 'components';
import Section              from '../components/Section';
import SectionContainer     from '../components/SectionContainer';
import { useHistory }       from 'react-router-dom';




export const WidgetImage = ({data = undefined, width = 1000}) => {
  const history = useHistory();
  if(!data)
    return null;
  return (
      <SectionContainer>
          <Section
            direction = {data?.fields?.flexDirection}
            feature = {
                <CardMedia 
                  component   = "img"
                  src         = {
                    data?.fields?.image?.fields?.file?.url && width
                      ? `${data?.fields?.image?.fields?.file?.url}?w=${width}`
                      : data?.fields?.image?.fields?.file?.url
                  }
                  title       = {data?.fields?.title}
                  alt         = {data?.fields?.title}
                />
            }
          >
            <Title component="h2" variant="h2" gutterBottom>
              {data?.fields?.title}
            </Title>
            <Typography paragraph variant="h5">
              {data?.fields?.subtitle}
            </Typography>
            <ContentfulRichText content={data?.fields?.content} />
            {
              data?.fields?.link &&
              <Box sx={{mt:2}}>
                <Button 
                  color     = "primary"
                  variant   = "contained"
                  onClick   = {() => history.push(data?.fields?.link)}
                >
                  Find Out More
                </Button>
              </Box>
            }
          </Section>        
        </SectionContainer>
  )
}

export default WidgetImage;