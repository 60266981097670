
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Contentful Context
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       18th April 2023

*******************************************************************************************/
import React                from 'react';
import moment               from 'moment';
import { createClient }     from "contentful";
import { useUser }          from 'contexts';
import { 
    useQueryParam, 
    BooleanParam
}                           from 'use-query-params';
import config               from '../config';

// Get credentials
const {
    contentful : {
        spaceId             : SPACE_ID,
        accessToken         : ACCESS_TOKEN,
        accessTokenPreview  : ACCESS_TOKEN_PREVIEW
    }
} = config;

// Make Client
const client = createClient({
    space           : SPACE_ID,
    accessToken     : ACCESS_TOKEN
});

const clientPreview = createClient({
    space           : SPACE_ID,
    accessToken     : ACCESS_TOKEN_PREVIEW,
    host            : 'preview.contentful.com', // NEEDED TO PREVIEW
})

const _getAuthors = (preview = false) => (
    (preview ? clientPreview : client)
        .getEntries({
            content_type    : 'componentAuthor',
            order           : 'fields.priority',
        })
        .then(response => response.items)
);

const _getBlog = (preview = false) => (
    (preview ? clientPreview : client)
        .withoutUnresolvableLinks
        .getEntries({
            content_type    : 'pageBlogPost',
            order           : '-fields.publishedDate',
        })
        .then(response => response.items)
);

const _getBlogPost = (slug, preview = false) => {
    return (
        (preview ? clientPreview : client)
            .withoutUnresolvableLinks
            .getEntries({
                content_type: 'pageBlogPost',
                'fields.slug': slug,
            })
            .then(response => response.items)
    )
};

const _getProducts = (preview = false) => (
    (preview ? clientPreview : client)
        .withoutUnresolvableLinks
        .getEntries({
            content_type: 'pageProduct',
        })
        .then(response => response.items)
);

const _getProduct = (slug,preview=false) => (
    (preview ? clientPreview : client)
        .withoutUnresolvableLinks
        .getEntries({
            content_type: 'pageProduct',
            'fields.slug': slug,
        })
        .then(response => response.items)
);

const _getPage = (slug, preview = false, exact = true) => (
    (preview ? clientPreview : client)
        .withoutUnresolvableLinks
        .getEntries({
            content_type: 'page',
            [
                exact 
                    ? 'fields.slug' 
                    : 'fields.slug[match]' 
            ] : slug,
        })
        .then(response => response.items)
);

const _getLanding = (slug, preview = false) => (
    (preview ? clientPreview : client)
        .withoutUnresolvableLinks
        .getEntries({
            content_type    : 'pageLanding',
            'fields.slug'   : slug,
            'include'       : 10
        })
        .then(response => response.items)
);

const _getArchive = (slug, preview = false) => (
    (preview ? clientPreview : client)
        .withoutUnresolvableLinks
        .getEntries({
            content_type: 'pageArchive',
            'fields.slug': slug,
        })
        .then(response => response.items)
);

const _getLegal = (slug, preview = false) => (
    (preview ? clientPreview : client)
        .withoutUnresolvableLinks
        .getEntries({
            content_type: 'pageLegal',
            'fields.slug': slug,
        })
        .then(response => response.items)
);

const _getFAQ = (slug,preview = false) => (
    (preview ? clientPreview : client)
        .withoutUnresolvableLinks
        .getEntries({
            content_type: 'pageFAQ',
            'fields.slug': slug,
        })
        .then(response => response.items)
);

// The Product Context 
const ContentfulContext = React.createContext({});

// Contentful Provider
const ContentfulProvider = ({children}) => {

    const [ previewRaw ]                        = useQueryParam('preview', BooleanParam);
    const { 
        isLoading, 
        isAuthenticated, 
        isAdmin
    }                                           = useUser();
    const [authors,         setAuthors]         = React.useState([])
    const [posts,           setPosts]           = React.useState([])
    const [loading,         setLoading]         = React.useState(true)
    const [queried,         setQueried]         = React.useState(false);
    const preview                               = React.useMemo(() => (
        Boolean(
            (!isLoading && isAuthenticated && isAdmin) && 
            previewRaw
        )
    ), [isLoading, isAuthenticated, isAdmin, previewRaw]);

    // Modify Client based on Preview Field
    const getAuthors        = React.useCallback(()                      => _getAuthors(preview), [preview] )
    const getBlog           = React.useCallback(()                      => _getBlog(preview), [preview] )
    const getBlogPost       = React.useCallback((slug)                  => _getBlogPost(slug,preview), [preview] )
    const getProducts       = React.useCallback(()                      => _getProducts(preview), [preview] )
    const getProduct        = React.useCallback((slug)                  => _getProduct(slug, preview), [preview] )
    const getPage           = React.useCallback((slug, exact = true)    => _getPage(slug, preview, exact), [preview] )
    const getLanding        = React.useCallback((slug)                  => _getLanding(slug, preview), [preview] )
    const getArchive        = React.useCallback((slug)                  => _getArchive(slug, preview), [preview] )
    const getLegal          = React.useCallback((slug)                  => _getLegal(slug, preview), [preview] )
    const getFAQ            = React.useCallback((slug)                  => _getFAQ(slug, preview), [preview] )

    React.useEffect(() => {
        Promise.all([
            getBlog(),
            getAuthors()
        ])
            .then(([posts, authors]) => {
                setPosts(posts);
                setAuthors(authors);
            })
            .finally(() => {
                setLoading(false)
                setQueried(moment())
            })
    }, [getAuthors, getBlog])

    // Context values
    const value = React.useMemo(() => ({
        preview,
        client,
        clientPreview,
        loading,
        queried,
        authors,
        posts,
        getAuthors,
        getBlog,
        getBlogPost,
        getProducts,
        getProduct,
        getLegal,
        getFAQ,
        getPage,
        getArchive,
        getLanding
    }),[authors, getArchive, getAuthors, getBlog, getBlogPost, getFAQ, getLanding, getLegal, getPage, getProduct, getProducts, loading, posts, preview, queried])

    return (
        <ContentfulContext.Provider value={value}>
            {children}
        </ContentfulContext.Provider>
    )
}

// Contentful Consumer
const ContentfulConsumer = ({children}) => {
    return (
        <ContentfulContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('ContentfulConsumer must be used within ContentfulProvider');
                }
                return children(context)
            }}
        </ContentfulContext.Consumer>
    )
}

// useContentful Hook
const useContentful = () => {
    const context = React.useContext(ContentfulContext);
    if(context === undefined)
        throw new Error('useContentful must be used within ContentfulProvider');
    return context;
}

export {
    ContentfulProvider,
    ContentfulConsumer,
    useContentful
}