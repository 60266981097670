
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
with Pulse HOC
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       5th December 2022

*******************************************************************************************/
import { styled }           from '@mui/material';

export const withPulse = Component => styled(Component,{
    shouldForwardProp : prop => prop !== 'duration' && prop !== 'pulse'
})(({theme , pulse = false, duration = 1000})=>({
    ...( pulse && {
        animation: `pulse ${duration} infinite`,
        "@keyframes pulse": {
            "0%" : {
                transform: 'scale(0.98)',
                boxShadow: '0 0 0 0 rgba(0, 0, 0, 0.7)'
            },
            "70%" : {
                transform: 'scale(1)',
                boxShadow: '0 0 0 5px rgba(0, 0, 0, 0)'
            },
            "100%" : {
                transform: 'scale(0.98)',
                boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)'
            }
        }
    })
}))

export default withPulse;