
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Optional Link
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       29th July 2022

*******************************************************************************************/
import React                from 'react';
import {Link as LinkDOM}    from "react-router-dom";

// Component
export const LinkOptional = ({to, ...props}) => {
    const {children, ...rest} = props;

    // No Child
    if(!children) 
        return null;

    // No Link
    if(!to) 
        return (
            <React.Fragment>
                {children}
            </React.Fragment>
        )

    // With Link
    return (
        <LinkDOM to={to} {...rest}>
            {children}
        </LinkDOM>
    )
}

// Re-Export 
export const Link = LinkDOM;

// HOC
export const withLinkOptional = (Component) => {
    return function WrappedComponent(to, ...props){
        return (
            <LinkOptional to={to}>
                <Component {...props} />
            </LinkOptional>
        )
    }
}

export default LinkOptional