/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Address Block
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       18th July 2021

*******************************************************************************************/
import React                    from 'react';
import AddShoppingCartIcon      from '@mui/icons-material/AddShoppingCart';
import { 
    useTranslation,
    useUser,
    useCart,
    useLaunch
}                               from 'contexts';
import { RefreshIcon }          from 'components'
import { PulseButton }          from 'components/pulse';
import {useProduct}             from 'contexts';

export const AddToCartButton = ({children,disabled=false, productId,...props}) => {
    const [t]                           = useTranslation();
    const {launched}                    = useLaunch();
    const {
        isRetrieved, 
        getProductById
    }                                   = useProduct();
    const [productData, setProductData] = React.useState(undefined);

    React.useEffect(()=>{
        if(productId && isRetrieved){
            const data = getProductById(productId);
            setProductData(data);
        }else{
            setProductData(undefined);
        }
    },[productId, isRetrieved, getProductById])

    const {
        isAuthenticated,
        loginWithPopup  : login
    }                               = useUser();
    const { 
        working, 
        addProductToCart
    }                               = useCart();
    const handleAddClick            = React.useCallback( () => addProductToCart({productId, quantity:1}), [addProductToCart, productId]);
    return (
        <PulseButton 
            disabled    = {disabled || working || !productData?.available || !launched} 
            pulse       = {!isAuthenticated} 
            startIcon   = {
                working 
                    ? <RefreshIcon loading={true} /> 
                    : <AddShoppingCartIcon />
            } 
            onClick     = {isAuthenticated ? handleAddClick : login} 
            variant     = "contained" 
            color       = "primary" 
            size        = "large"
            {...props}
        >
            {
                children 
                    ? children 
                    : (
                        isAuthenticated 
                            ? productData?.available 
                                ? (
                                    launched 
                                        ? t('common.addToCart') 
                                        : t('common.availableSoon')
                                )
                                : t('common.unavailable')
                            : t('common.loginToBuy')
                    )
            }
        </PulseButton>
    )
}

export default AddToCartButton;