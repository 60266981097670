/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
LiveModeChip
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       27th January 2023

*******************************************************************************************/
import React                            from 'react';
import { Chip }                         from '@mui/material';
import SyncAltIcon                      from '@mui/icons-material/SyncAlt';
import { withTranslation }              from './hoc';

export const LiveModeChip = withTranslation( ({t, ...props}) => (
    <Chip 
        icon    = {<SyncAltIcon/>} 
        label   = {t('common.live')} 
        size    = "small" 
        color   = "error" 
        {...props} 
    />
))

export default LiveModeChip;