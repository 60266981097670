/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Sort Direction Icons
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       22nd August 2022

*******************************************************************************************/
import React                            from 'react';
import { styled, Box, Typography }      from '@mui/material';
import {
    FaSortAmountDownAlt as FaSortAmountDown,
    FaSortAmountUp
}                                       from "react-icons/fa"

const noop = () => {};

const withAscendinDescending = Icon => styled(Icon,{
    shouldForwardProp : prop => !['ascending','disabled'].includes(prop)
})(({theme, ascending=false, disabled=false}) => ({
    color       : "inherit",
    fontSize    : "inherit",
    ...(ascending && {
        color : theme.palette.info.main
    }),
    ...(!ascending && {
        color : theme.palette.error.main
    }),
    ...(disabled && {
        color : [theme.palette.divider,'!important']
    })
}))

const Container = styled(Box, {
    shouldForwardProp : prop => !['pointer'].includes(prop)
})(({pointer = false}) => ({
    display : "inline-block",
    ...(pointer && {
        cursor : "pointer"
    })
}));

const Content = styled(Box)(({theme}) => ({
    display : "flex",
    "& > * + *" : {
        marginLeft : theme.spacing(0.5)
    }
}));

export const SortDirectionIcons = ({disabled = false, descending = true, showLabel = false, onClick : handleClick = noop, ...props}) => {

    const Icon              = React.useMemo(() => descending ? FaSortAmountDown : FaSortAmountUp, [descending])
    const IconComponent     = React.useMemo(() => withAscendinDescending(Icon), [Icon])
    const TypeComponent     = React.useMemo(() => withAscendinDescending(Typography), [])

    return (
        <Container pointer={Boolean(handleClick && handleClick !== noop)} onClick={handleClick} {...props}>
            <Content>
                <IconComponent ascending={!descending} disabled={disabled} sx={{transform : "translatey(2px)"}}/> 
                {
                    showLabel &&
                    <TypeComponent component = "div" ascending={!descending} disabled={disabled} sx={{transform : "translatey(-1px)"}}>
                        {descending ? 'Desc' : 'Asc' }
                    </TypeComponent>
                }
            </Content>
        </Container>
    )
}

export default SortDirectionIcons;