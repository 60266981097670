/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
QR Code
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       29th May 2023

*******************************************************************************************/
import React                            from 'react';
import QRCodeBase                       from "react-qr-code";
import { Box }                          from '@mui/material';
import { DraggableDialog }              from 'components';

const DEFAULT_SIZE          = 75;
const DEFAULT_QRCODE_SIZE   = 256;

const obj = {};
export const QRCode = ({size = DEFAULT_SIZE, qrCodeSize = DEFAULT_QRCODE_SIZE, level = "L", p = 0.5, value : valueIn = undefined, containerProps = obj, qrCodeProps = obj, ...props}) => {
    
    const [open, setOpen]   = React.useState(false);
    const handleOpen        = React.useCallback(() => setOpen(true),[]);
    const handleClose       = React.useCallback(() => setOpen(false),[]);
    const handleClick       = React.useCallback((e) => {
        e.stopPropagation();
        handleOpen();
    },[handleOpen])

    const value = React.useMemo(() => {

        // Value Explicitly Provided
        if(valueIn)
            return valueIn;

        // Extract Variables from location
        const {protocol, hostname, port} = window.location;

        // Base address
        const base = `${protocol}//${hostname}`;
        
        // Incude Port
        if(port && port !== 80 && port !== 443)
            return base + `:${port}`;

        // Without Port
        return base;

    },[valueIn])

    const style = React.useMemo(() => ({
        width       : "auto", 
        maxWidth    : "100%",
        height      : "100%",
        ...props.style,
        ...qrCodeProps.style
    }),[props.style, qrCodeProps.style])

    return (
        <Box {...containerProps} sx={{width:size, height:size, bgcolor: 'white', borderRadius : isNaN(p) ? p : theme => theme.spacing(2*p), p,...containerProps.sx}}>
            <QRCodeBase 
                level   = {level}
                {...props}
                {...qrCodeProps}
                size    = { qrCodeSize }
                viewBox = {`0 0 ${qrCodeSize} ${qrCodeSize}`}
                style   = {{ ...style, cursor : "pointer" }}
                value   = {value}
                onClick = {handleClick}
            />
            <DraggableDialog
                open        = {open}
                title       = "Share QR Code"
                onClose     = {handleClose}
                onCancel    = {handleClose}
                showButtons = {false}
                maxWidth    = "md"
            >
                <Box {...containerProps} sx={{width:2*DEFAULT_QRCODE_SIZE, height:2*DEFAULT_QRCODE_SIZE, maxWidth:'50vw', maxHeight:'50vw', bgcolor: 'white', borderRadius: theme => theme.spacing(2), p:2, ...containerProps.sx}}>
                    <QRCodeBase 
                        level   = {level}
                        {...props}
                        {...qrCodeProps}
                        size    = { 2 * qrCodeSize }
                        viewBox = {`0 0 ${2 * qrCodeSize} ${2 * qrCodeSize}`}
                        style   = {style}
                        value   = {value}
                    />
                </Box>
            </DraggableDialog>
        </Box>
    )
}

export default QRCode;