/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Order Transaction Titles
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       30th July 2021

*******************************************************************************************/
import React                            from 'react';
import { Grid, Typography }             from '@mui/material';
import { Skeleton }                     from '@mui/material';
import {
    RootContainer,
    Title,
    Price,
    ItemPaper,
}                                       from 'components';
import { withTranslation }              from 'components/hoc';
import { useOrderViewer }               from 'contexts';


const OrderPriceTile = ({title, price}) => (
    <ItemPaper sx={{ m : 0, p : theme => `${theme.spacing(4)}!important`}}>
        <Title align="center" variant="h5">
            {title}
        </Title>
        <Typography align="center" component="div" sx={{fontSize:'1.25rem'}}>
            <Price price={price} unit={null}/>
        </Typography>
    </ItemPaper>
)

export const OrderTransactionTiles = withTranslation( ({ t }) => {
    
    const {
        queried,
        data : order,
        orderId,
    } = useOrderViewer();

    // Is there a refund ? 
    const hasRefund = React.useMemo(() => (
        Boolean(order && order.amountRefunded > 0)
    ), [order]);

    // Build the Tile Data
    const tileData = React.useMemo(() => {
        const data = [
            ["total",       order?.total],
            [!order || hasRefund 
                ? "charged" 
                : "paid",   order?.amountCaptured],
            ["refunded",    order?.amountRefunded],
            ["netPaid",     order?.amountCaptured - order?.amountRefunded]
        ].map(value => order ? value : 0)
        return (
            hasRefund
                ? data 
                : data.slice(0,2)
        );
    }, [hasRefund, order])

    // No orderID, return empty
    if(!orderId)
        return null;

    // No order or No Values (ie free), return empty
    if(!order || !(['total','amountCaptured','amountRefunded'].map(x => order[x]).some(Boolean)))
        return null;

    return (
        <RootContainer id="orderTransactionTiles">
            <Grid container>
                {
                    tileData.map(([key,price],ix) => (
                        <Grid key={ix} item xs={6} md={hasRefund ? 3 : 6}>
                            {   
                                queried && 
                                <OrderPriceTile
                                    title   = { t(`components.order.orderTransactionTiles.${key}`) } 
                                    price   = {price} 
                                />
                            }
                            {   
                                !queried &&
                                <Skeleton 
                                    variant = "rectangular" 
                                    width   = "100%" 
                                    height  = { 100 } 
                                />
                            }
                        </Grid>
                    ))
                }
            </Grid>
        </RootContainer>
    );
});

export default OrderTransactionTiles;