/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Skeleton
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       24th July 2022

*******************************************************************************************/
import React                            from 'react';
import { Typography }                   from '@mui/material';
import { useNetwork, useTranslation}    from 'contexts';
import withFeatureTypography            from './withFeatureTypography';

const TypographyComponent = (props) => {
    const {t} = useTranslation();
    return (
        <Typography align="center" {...props}>
            { props.children || t('common.waitingForNetwork') }
        </Typography>
    )
};

export const withWaitingForNetwork = (Component) => {
    const NewComponent = withFeatureTypography(Component);
    return function WrappedComponent({showFeature = true, ...props}){ 
        const {isNetworkReady}  = useNetwork();
        return (
            <NewComponent 
                showFeature         = {showFeature && !isNetworkReady}
                typographyComponent = {TypographyComponent}
                {...props}
            />
        )
    }
}

export default withWaitingForNetwork;