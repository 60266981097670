/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Alert Message Group
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       9th May 2021

*/

import React            from 'react';
import {
    styled, 
    Box, 
    Alert 
}                       from '@mui/material';

const Root = styled(Box)(({theme}) => ({
    width       :  '100%',
    height      : 'fit-content',
    minHeight   : 50,
    '& > * + *' : {
        marginTop : theme.spacing(0.5)
    }
}));

const obj = {};

export const AlertMessageGroup = ({
    children, 
    classes,
    success         = undefined, 
    warning         = undefined, 
    error           = undefined, 
    containerProps  = obj,
    ...props
}) => {
    
    const data = React.useMemo(() => (
        [
            {severity : 'success', message : success},
            {severity : 'warning', message : warning},
            {severity : 'error',   message : error}
        ].filter(item => Boolean(item.message))
    ),[error, success, warning]);

    return (
        <Root {...containerProps}>
            {
                data.map(({severity,message}, ix) => (
                    <Alert key={ix} severity={severity} {...props}>  
                        {message}    
                    </Alert> 
                ))
            }
            {children}
        </Root>
    )
}

export default AlertMessageGroup;