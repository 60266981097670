
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Locale
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       9th Jan 2021

*******************************************************************************************/
import React                            from 'react';
import {useLocale, useTranslation}      from 'contexts';

export const Locale = () => {
    const { currency }      = useLocale();
    const {t,i18n}          = useTranslation();
    return (
        <React.Fragment>
            {t('common.locale')}: {currency} / {i18n.language}
        </React.Fragment>
    )
}

export default Locale;