
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Geo Display
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       25th December 2020

*******************************************************************************************/
import React                            from 'react';
import { 
    styled,
    Typography as TypographyMUI 
}                                       from '@mui/material';

export const Typography = styled(TypographyMUI)({
    fontSize : '1rem'
})

export const CardHeading = (props) => (
    <Typography color="textSecondary" gutterBottom {...props}/>
)
    
export default CardHeading;