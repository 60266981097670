/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Paginated
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       20th November 2020

*******************************************************************************************/

import React                            from 'react';
import {isEqual}                        from 'lodash';
import { Box }                          from '@mui/material';
import Pagination                       from './Pagination';
import RootContainer                    from '../RootContainer';

const   DEFAULT_PER_PAGE            = undefined,
        DEFAULT_PER_PAGE_OPTIONS    = [5, 10, 20, 50, 100];
const   arr                         = [];

const sortSetToList = x => (
    [...new Set(x)]
        .filter(x => !isNaN(x))
        .sort((a,b) => a - b)
);

const noop = () => {}

/**
 * Finds the nearest value in an array of numbers.
 * Example: nearestValue(array, 42)
 * 
 * @param {Array<number>} arr
 * @param {number} val the ideal value for which the nearest or equal should be found
 */
const nearestValue = (arr, val) => (
    arr
        .reduce((p, n) => (
            Math.abs(p) > Math.abs(n - val) ? n - val : p
        ), 
        Infinity
    ) + val
);

// Paginated component that utilized Render Method
export const Paginated = ({
    children,
    disabled                = false,
    data            : d     = arr, 
    page            : p     = 0, 
    perPage         : pp    = DEFAULT_PER_PAGE, 
    perPageOptions          = DEFAULT_PER_PAGE_OPTIONS, 
    showTop                 = false,
    showBottom              = true,
    externalPagination      = false,
    onPerPageChange         = noop,
    onPageChange            = noop,
    documentCount           = undefined,
    labelRowsPerPage        = undefined,
    ...props
}) => {

    const [options]                             = React.useState(sortSetToList([...perPageOptions, pp]));
    const [data,    setData]                    = React.useState(Array.isArray(d) ? d : []);
    const [dataSub, setDataSub]                 = React.useState([]);
    const [page,    setPage]                    = React.useState(p);
    const [perPage, setPerPage]                 = React.useState(pp || perPageOptions[0]);
    const [count,   setCount]                   = React.useState(0);

    React.useEffect(() => {
        onPerPageChange(perPage);
    },[onPerPageChange, perPage])

    React.useEffect(() => {
        onPageChange(page);
    },[onPageChange, page])

    // Update input parms
    React.useEffect(() => {
        const newData = Array.isArray(d) ? d : [];
        if(!isEqual(data, newData)) 
            setData(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [d]);

    // Set Page
    React.useEffect(() => {
        setPage(p);
    }, [p]);

    // Set Per Page
    React.useEffect(() => {
        // setPerPage( perPageOptions.includes(pp) ? pp : nearestValue(perPageOptions, pp));
        setPerPage( 
            perPageOptions.includes(pp) 
                ? pp 
                : Array.isArray(perPageOptions) && perPageOptions.filter(x => !isNaN(x))?.length && !isNaN(pp)
                    ? nearestValue(perPageOptions, pp) 
                    : perPageOptions[0]
        );
        //setPerPage( perPageOptions.includes(pp) ? pp : perPageOptions[0]);
    }, [perPageOptions, pp])

    // Subset when data, page, perPage changes
    React.useEffect( () => {
        let sub = !externalPagination 
            ? data.slice(page * perPage, (page + 1) * perPage) 
            : data;
        if(!isEqual(sub,dataSub)) 
            setDataSub(sub);
    }, [data, dataSub, externalPagination, options, page, perPage, perPageOptions]);

    // Change Count Value, when data changes length
    React.useEffect(() => {
        setCount(
            externalPagination && !isNaN(documentCount) 
                ? documentCount 
                : data.length
        );
    },[data.length, documentCount, externalPagination]);

    // when count changes internally, start at beginning
    React.useEffect(()=> {
        if(!externalPagination)
            setPage(0)
    },[count, externalPagination]);

    // Handle Change Page
    const handleChangePage = React.useCallback( page => {
        if(externalPagination)
            onPageChange(page)
        setPage(page)
    }, [externalPagination, onPageChange]);

    // Handle Change Per Page
    const handleChangePerPage = React.useCallback( perPageNew => {
        if(externalPagination)
            onPerPageChange(perPageNew)
        if(perPageNew !== perPage){
            setPerPage(perPageNew);
            setPage(0);
            onPageChange(0);
        }
    }, [externalPagination, onPageChange, onPerPageChange, perPage]);

    // Pagination Args
    const args = React.useMemo(() => ({
        disabled,
        options, 
        count, 
        page, 
        perPage,
        onChangePage        : handleChangePage,
        onChangePerPage     : handleChangePerPage,
        labelRowsPerPage    : labelRowsPerPage
    }), [count, disabled, handleChangePage, handleChangePerPage, options, page, perPage, labelRowsPerPage]);

    return (
        <RootContainer spacing={1}>
            {
                showTop && 
                <Box>
                    <Pagination 
                        {...args} 
                    />
                </Box>
            }
            {
                children && 
                <Box>
                    {children}
                </Box>
            }
            {
                props.render && 
                <Box>
                    {
                        props.render({
                            data : dataSub, 
                            page, 
                            perPage, 
                            perPageOptions, 
                            count, 
                            ...props
                        })
                    }
                </Box>
            }
            {
                showBottom && 
                <Box>
                    <Pagination 
                        {...args} 
                    />
                </Box>
            }
        </RootContainer>
    )
}

export default Paginated;