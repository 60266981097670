/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Product Review Form
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       13th February 2021

*******************************************************************************************/
import React                            from 'react';
import {isNil}                          from 'lodash';
import config                           from '../../config';
import { Grid, Box, Typography }        from '@mui/material';
import CloudUploadIcon                  from '@mui/icons-material/CloudUpload';
import {
    Form,
    StarRating,
    FormAlert
}                                       from 'components';
import { useTranslation }               from 'contexts';
import { 
    TextField,
    Checkboxes,
    showErrorOnChange   as showError,
}                                       from 'mui-rff';

const noop              = () => {}
const obj               = {};
const STAR_STEPS        = config.reviews.scoreMax;
const STAR_CATEGORIES   = [
    { key : 'quality' },
    { key : 'relevance' },
    { key : 'asDescribed' },
    { key : 'valueForMoney' }
];
const STAR_SCORES_DEFAULT = (
    STAR_CATEGORIES
        .map(({key}) => key)
        .reduce((acc,cur) => ({
            ...acc,
            [cur] : undefined
        }),{})
);

const defaultFormData = {
    _id             : undefined,
    deliveryId      : undefined,
    public          : false,
    tagline         : undefined,
    review          : undefined,
    scores          : STAR_SCORES_DEFAULT 
}

export const ProductReviewForm = ({
    formData                = defaultFormData,
    disabled                = false,
    sticky                  = false,
    onSubmit : handleSubmit = noop,
    onCancel : handleCancel = noop,
    FormProps               = obj,
    ...rest
}) => {
    const {t}                               = useTranslation();
    const [starsChanged, setStarsChanged]   = React.useState(false);
    const validate                          = React.useCallback( (values) => {

        // Empty error object
        let errors          = {};

        // If disabled, no error check
        if(disabled) 
            return errors;

        // Now process errors
        const bounds = {
            review  : { min : 10,   max : 3000 },
            tagline : { min : 5,    max : 50 }
        };

        Object.keys(bounds).forEach(k => {
            const {min,max} = bounds[k];
            if(isNil(values[k]))
                errors[k] = errors[k] || t('components.forms.productReviewForm.required')
            if(values[k] && values[k].length > max)
                errors[k] = errors[k] || t('components.forms.productReviewForm.maximumChars', {maximum : max, current : values[k].length})
            if(values[k] && values[k].length < min)
                errors[k] = errors[k] || t('components.forms.productReviewForm.minimumChars', {minimum : min, current : values[k].length})
        })

        // Done
        return errors;
    },[t, disabled])

    // Initial Values
    const initialValues = React.useMemo(() => ({
        ...formData
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [JSON.stringify(formData)]);

    return (
        <Form
            debug                   = {false}
            disabled                = {disabled}
            onSubmit                = {handleSubmit}
            onCancel                = {handleCancel}
            initialValues           = {initialValues}
            validate                = {validate}
            successMessage          = {t('components.forms.productReviewForm.successMesssage')}
            {...FormProps}
            SubmitButtonProps       = {{
                startIcon : <CloudUploadIcon/>,
                ...rest.SubmitButtonProps
            }}
            showObjectId            = {false}
            disabledSubmit          = {!starsChanged && !initialValues._id}
            disabledAfterSubmit     = {true}
            sticky                  = {sticky}
            render                  = {({disabled, form, error, dirtySinceLastSubmit, submitFailed, submitSucceeded, errors, handleSubmit, values, ...rest}) => {
                return (
                    <form onSubmit={handleSubmit} noValidate>
                        <Box>
                            <Typography sx={{fontWeight:400}} gutterBottom>
                                { t('components.forms.productReviewForm.reviewDetails') }
                            </Typography>
                            <TextField 
                                label       = { t('components.forms.productReviewForm.summary') }
                                helperText  = { t('components.forms.productReviewForm.summaryHelperText') }
                                name        = "tagline" 
                                disabled    = {disabled}
                                showError   = {showError}
                                multiline   = {true}
                                maxRows     = {10}
                                variant     = "filled"
                            />
                            <TextField 
                                label       = { t('components.forms.productReviewForm.detailedProductReview') }
                                helperText  = { t('components.forms.productReviewForm.detailedProductReviewHelperText') }
                                name        = "review" 
                                disabled    = {disabled}
                                showError   = {showError}
                                multiline   = {true}
                                maxRows     = {10}
                                variant     = "filled"
                            />
                        </Box>

                        <Box mt={2}>
                            <Typography sx={{fontWeight:400}} gutterBottom>
                                { t('components.forms.productReviewForm.privacySettings') }
                            </Typography>
                            <Box align="right">
                                <Typography component="div" variant="body2" align="justify">
                                    { t('components.forms.productReviewForm.privacySettingsSummary') }
                                </Typography>
                                <Box sx={{ml:2}}>
                                    <Checkboxes
                                        disabled    = {disabled}
                                        name        = {'public'}
                                        required    = {true}
                                        data        = {{
                                            label : t('components.forms.productReviewForm.allowSeenPublicly'), 
                                            value : true
                                        }}
                                        helperText  = { t('components.forms.productReviewForm.allowSeenPubliclyHelperText') }
                                    />
                                </Box>
                            </Box>
                        </Box>
                        
                        <Box mt={2}>
                            <Typography sx={{fontWeight:400}} gutterBottom>
                                { t('components.forms.productReviewForm.deliveryRatings') }
                            </Typography>
                            <Box>
                                {
                                    !initialValues._id &&
                                    <Box pt={1} pb={1}>
                                        <FormAlert severity="warning">
                                            { t('components.forms.productReviewForm.deliveryRatingsSummary') }
                                        </FormAlert>
                                    </Box>
                                }
                                <Typography variant="body2" align="justify" gutterBottom>
                                    { t('components.forms.productReviewForm.deliveryRatingsDescription', {categories : STAR_CATEGORIES.length, steps : STAR_STEPS } ) }
                                </Typography>
                                <Typography variant="body2" align="justify">
                                    { t('components.forms.productReviewForm.weLookAtReviews') }
                                </Typography>
                            </Box>

                            <Grid container spacing={2} sx={{mt:1}}>
                                {
                                    STAR_CATEGORIES.map(({key},ix) => (
                                        <Grid key={ix} item xs={6}>
                                            <StarRating
                                                steps       = {STAR_STEPS}
                                                disabled    = {disabled}
                                                label       = {t(`components.forms.productReviewForm.${key}`)}
                                                value       = {values.scores[key]}     
                                                onChange    = {(value) => {
                                                    setStarsChanged(true);
                                                    form.change(`scores.${key}`,value)
                                                }}
                                            />
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </Box>
                    </form>
                )
            }}
        />
    )
}

export default ProductReviewForm;