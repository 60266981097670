/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
withDisabledOffline
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       9th May 2021

*******************************************************************************************/
import React                        from 'react';
import {useNetwork}                 from 'contexts';

export const withDisabledOffline = (Component, includeSockets=true) => {
    
    return function WrappedComponent({ disabled = false, ...props}) {
        const {isNetworkReady,isSocketConnected,isOnline} = useNetwork();
        disabled =  disabled || 
                    !(includeSockets ? (isOnline && isNetworkReady && isSocketConnected) : isOnline);
        if(!Component)
            return null;
        return (
            <Component disabled={disabled} {...props} />
        )
    }

}

export default withDisabledOffline;