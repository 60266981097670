/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
useTable Hook
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       10th January 2021

*******************************************************************************************/
import React from 'react'

// Reducer constants
const CONSTANTS = {
        DATA                    : 'DATA',
        SELECT_ALL              : "SELECT_ALL",
        UNSELECT_ALL            : "UNSELECT_ALL",
        SELECT_ID               : "SELECT_ID",
        UNSELECT_ID             : "UNSELECT_ID",
}

// Initial State
const INITIAL_STATE = {
        data                    : [],
        selected                : [],

        retrieved               : false,
        isAllSelected           : false,
        isSomeSelected          : false,
        isNoneSelected          : true,
        isRetrieved             : false,
        isIndeterminate         : false,
        hasData                 : false
}

const processFlags = (state) => {

        // Some binary flags
        const isAllSelected     = state.selected.length === state.data.length;
        const isSomeSelected    = state.selected.length > 0;
        const isNoneSelected    = state.selected.length === 0;
        const isRetrieved       = state.retrieved;
        const isIndeterminate   = isSomeSelected && !isAllSelected;
        const hasData           = state.data.length > 0;
        return {
                ...state,
                isAllSelected,
                isSomeSelected,
                isNoneSelected,
                isRetrieved,
                isIndeterminate,
                hasData,
        }
}
    
// The reducer
const reducer = (state, action) => {
        let data, selected, id, ids;
        switch(action.type){
                case CONSTANTS.DATA:
                        data            = (action?.data || []); 
                        ids             = data.map((item,id) => item?._id || id);
                        selected        = state.selected.filter(item => ids.includes(item)); // Only include relevant items
                        return processFlags({...state, data, selected, retrieved:true})
                case CONSTANTS.SELECT_ALL:
                        data            = (state?.data || []);
                        selected        = data.map((item,id) => item._id || id);
                        return processFlags({...state, selected})
                case CONSTANTS.UNSELECT_ALL:
                        selected        = []
                        return processFlags({...state, selected})
                case CONSTANTS.SELECT_ID:
                        id              = action.id;
                        selected        = id 
                                ? [...new Set((state?.selected || []).concat([id]))] 
                                : [...(state?.selected || [])]
                        return processFlags({...state, selected})
                case CONSTANTS.UNSELECT_ID:
                        id              = action.id;
                        selected        = (state?.selected || []).filter(item => item !== id);
                        return processFlags({...state, selected})
                default:
                        return state;
        }
}

export const useTableData = (data = []) => {

        // Reducer
        const [state, dispatch] = React.useReducer(reducer, INITIAL_STATE);

        // Methods
        const setData           = React.useCallback((data) => dispatch({type:CONSTANTS.DATA,data}),             [dispatch]);
        const selectId          = React.useCallback((id)   => dispatch({type:CONSTANTS.SELECT_ID,id}),          [dispatch]);
        const unselectId        = React.useCallback((id)   => dispatch({type:CONSTANTS.UNSELECT_ID,id}),        [dispatch]);
        const selectAll         = React.useCallback(()     => dispatch({type:CONSTANTS.SELECT_ALL}),            [dispatch]);
        const unselectAll       = React.useCallback(()     => dispatch({type:CONSTANTS.UNSELECT_ALL}),          [dispatch]);

        React.useEffect(()=> {
            setData(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[JSON.stringify(data)])

        // Return the state
        return {
                ...state,
                setData,
                selectId,
                unselectId,
                selectAll,
                unselectAll, 
        }
}

export default useTableData;