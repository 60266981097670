
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Free Sign Tiles
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       7th January 2024

*******************************************************************************************/
import React                            from 'react';
import { useHistory }                   from 'react-router-dom';
import {
    Box, CardActions, Grid
}                                       from '@mui/material';
import { 
    Card, CardContent, CardMedia, Typography,
    Button 
}                                       from '@mui/material';

import {
    SpaceBox,
    FeatureBox
}                                       from 'components';
import {
    useContentfulPage,
}                                       from 'hooks';

const obj = {};

const Cards = ({ childPages, gridItemProps = obj}) => {
    const history           = useHistory();
    const handleButtonClick = (slug) => history.push(`/${slug}`);
    return (
        <Grid container spacing={2}>
        {
            childPages.map((page, index) => (
                <Grid key = {index} item xs={12} sm={6} md={4} {...gridItemProps} sx={{mx:'auto'}}>
                    <Card 
                        style   = {{cursor:'pointer', width: '100%', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
                        onClick = {() => handleButtonClick(page.fields.slug)}
                    >
                        <CardMedia
                            component   = "img"
                            alt         = {page.fields.title}
                            height      = "140"
                            image       = {`${page.fields.featuredImage.fields.file.url}?w=300`}
                        />
                        <CardContent>
                            <Typography variant="h6" component="div">
                                {page.fields.title}
                            </Typography>
                        </CardContent>
                        <CardActions style={{ justifyContent: 'flex-end' }}>
                            <Button
                                variant = "contained"
                                color   = "primary"
                            >
                                Learn More
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

export const FreeSignTiles = ({slug = "preview/sign", gridItemProps = obj}) => {

    const { data : rawData} = useContentfulPage(slug,false); 
    
    const data = React.useMemo(() => (
        (rawData || []).filter(entry => (
            entry?.fields?.slug && 
            entry?.fields?.slug?.startsWith(slug) && 
            entry?.fields?.slug !== slug
        ))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ),[JSON.stringify(rawData)])

    const hasCards = React.useMemo(() => Boolean(data?.length), [data?.length])
    return (
        <Box>
            {
                hasCards
                    ? (
                        <Cards childPages={data} gridItemProps={gridItemProps}/>
                    )
                    : (
                        <FeatureBox>
                            <SpaceBox>
                                <Typography sx={{textAlign:'center',fontWeight:400}}>
                                    Coming Soon ...
                                </Typography>
                            </SpaceBox>
                        </FeatureBox>
                    )
            }
        </Box>
    )
}

export default FreeSignTiles;