/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Credit Card Payment Method Dialog
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       24th December 2020

*******************************************************************************************/

import React                            from 'react';
import { DraggableDialog }              from 'components';
import { withTranslation }              from 'components/hoc';
import { PaymentMethodForm }            from 'components/forms';

const noop = () => {}
const handleSubmitDefault = ({paymentMethod,isDefault}) => {};

export const PaymentMethod = withTranslation( ({
    t,
    open        = false,
    disabled    = false, 
    onOk        : handleSubmit  = handleSubmitDefault,
    onCancel    : handleClose   = noop
}) => (
    <DraggableDialog
        title           = { t('components.modals.paymentMethod.createPaymentMethod') }
        open            = {open}
        onClose         = {handleClose}
        showButtons     = {false}
        maxWidth        = "xs"
    >
        <PaymentMethodForm 
            disabled    = {disabled} 
            onSubmit    = {handleSubmit} 
            onCancel    = {handleClose} 
        />
    </DraggableDialog>
))

export default PaymentMethod;