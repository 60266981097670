/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Skeleton Address
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       2nd August 2022

*******************************************************************************************/
import React                            from 'react';
import { Box, Skeleton}                 from '@mui/material';
import { 
    ContainerHorizontal,
    ContainerVertical 
}                                       from './components';

export const SkeletonAddress = ({...props}) => {
    return ( 
        <Box width="100%">
            <ContainerHorizontal>
                <Box component ={Skeleton} width="10%" variant="text"/>
                <Box component ={Skeleton} width="20%" variant="text"/>
                <Box component ={Skeleton} width= "5%" variant="text"/>
                <Box component ={Skeleton} width= "20%" variant="text"/>
                <Box flexGrow  ={1}/>
            </ContainerHorizontal>
            <ContainerVertical>
                <Skeleton variant="text" width="100%"/>
                <Skeleton variant="text" width="100%"/>
                <Skeleton variant="text" width="75%"/>
            </ContainerVertical>
            <ContainerHorizontal>
                <Box component  ={Skeleton} width="25%" variant="text"/>
                <Box flexGrow   ={1}/>
                <Box component  ={Skeleton} width="35%" variant="text"/>
            </ContainerHorizontal>
        </Box>
    )
}

export default SkeletonAddress;