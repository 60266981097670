/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Public Library Form
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       15th October 2021

*******************************************************************************************/
import React                            from 'react';
import {isNil}                          from 'lodash';
import { 
    useTheme, 
    MenuItem, 
    Box, Typography 
}                                       from '@mui/material';
import { useTranslation }               from 'contexts';
import {
    Form,
}                                       from 'components';
import { SelectYesNoField }             from './fields';
import { 
    Select,
    showErrorOnChange as showError,
}                                       from 'mui-rff';

const noop              = () => {};
const noopSub           = (values) => {};
const obj               = {};
const defaultFormData   = {
    id              : undefined,
    value           : false,
    commentsEnabled : false,
};

export const PublicLibraryForm = ({
    formData                        = defaultFormData,
    disabled                        = false,
    onSubmit : handleSubmitMaster   = noopSub,
    onCancel : handleCancel         = noop,
    FormProps                       = obj
}) => {

    const {t}                       = useTranslation();
    const theme                     = useTheme();

    // Validation Function
    const validate                  = React.useCallback( (values) => {
        let errors = {};
        ['value'].forEach(field => {
            if(isNil(values[field]))
                errors[field] = errors[field] || t('components.forms.publicLibraryForm.required');
        })
        // Public
        if(values.value){
            ['commentsEnabled'].forEach(field => {
                if(isNil(values[field]))
                    errors[field] = errors[field] || t('components.forms.publicLibraryForm.required');
            })
        }
        return errors;
    }, [t]);

    // Initial Form Data
    const initialValues             = React.useMemo(() => ({
        ...formData
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [JSON.stringify(formData)])

    // Handle Submit
    const handleSubmit              = React.useCallback( (values) => {
        if(!values.value && isNil(values.commentsEnabled))
            values.commentsEnabled = false;
        handleSubmitMaster(values);
    }, [handleSubmitMaster]);

    return (
        <Form
            debug                   = {false}
            disabled                = {disabled}
            onSubmit                = {handleSubmit}
            onCancel                = {handleCancel}
            initialValues           = {initialValues}
            validate                = {validate}
            showObjectId            = {false}
            {...FormProps}
            render = { ({disabled, form, error, dirtySinceLastSubmit, submitFailed, submitSucceeded, errors, handleSubmit, values, ...rest}) => {
                
                return (
                    <form onSubmit={handleSubmit} noValidate>
                        <Box>
                            <Typography variant="h5">
                                {t('components.forms.publicLibraryForm.library')}
                            </Typography>

                            {
                                !values?.value && 
                                <>
                                    <Typography variant="body2" gutterBottom>
                                        {t('components.forms.publicLibraryForm.doYouWantPrivate')}
                                    </Typography>
                                    <Typography variant="body2" sx={{fontWeight:600}}>
                                        {t('components.forms.publicLibraryForm.doYouWantPrivateNote')}
                                    </Typography>
                                </>
                            }

                            {
                                values?.value &&
                                <>
                                    <Typography variant="body2" gutterBottom>
                                        {t('components.forms.publicLibraryForm.doYouWantPublic')}
                                    </Typography>
                                    <Typography variant="body2" sx={{fontWeight:600}}>
                                        {t('components.forms.publicLibraryForm.doYouWantPublicNote')}
                                    </Typography>
                                </>
                            }

                            <Select 
                                disabled            = {disabled} 
                                name                = "value" 
                                label               = {t('components.forms.publicLibraryForm.privacySetting')}
                                formControlProps    = {{ 
                                    margin  : 'normal',
                                    style   : {marginTop:theme.spacing(1)}
                                }} 
                                showError           = {showError}
                            >
                                <MenuItem value={true}  > 
                                    {t('components.forms.publicLibraryForm.public')}
                                </MenuItem>
                                <MenuItem value={false} >
                                    {t('components.forms.publicLibraryForm.private')}
                                </MenuItem>
                            </Select>
                        </Box>
                        {   
                            values.value && 
                            <Box pt={1}>
                                <Typography variant="h5">
                                    {t('components.forms.publicLibraryForm.comments')}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    {t('components.forms.publicLibraryForm.doYouWantComments')}
                                </Typography>
                                <Typography variant="body2" sx={{fontWeight:600}}>
                                    {t('components.forms.publicLibraryForm.doYouWantCommentsNote')}
                                </Typography>
                                <SelectYesNoField 
                                    name                = "commentsEnabled" 
                                    label               = {t('components.forms.publicLibraryForm.enableComments')}
                                    disabled            = {disabled} 
                                    showError           = {showError}
                                />
                            </Box>
                        }
                    </form>
                )
            }}
        />
    )
}

export default PublicLibraryForm;