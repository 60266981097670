/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Admin -- CelestialPoints
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       21st August 2021

*******************************************************************************************/
import React                            from 'react';
import { Grid }                         from '@mui/material';
import { Form }                         from 'components';
import { useTranslation }               from 'contexts';
import { 
    TextField,
    showErrorOnChange   as showError,
}                                       from 'mui-rff';

// Local Style Overrides
// import './AspectFormStyles.css';
// const capFirst = (x) => x[0].toUpperCase() + x.slice(1,x.length);

const noop = () => {};
const obj = {};

const defaultFormData = {
    id          : undefined,
    name        : '',
    description : '',
}

export const CelestialPointForm = ({
    formData                = defaultFormData,
    isEditing               = false,
    disabled                = false,
    onSubmit : handleSubmit = noop,
    onCancel : handleCancel = noop,
    FormProps               = obj,
    ...rest
}) => {
    const {t} = useTranslation();
    const validate = (values) => {
        let errors          = {};

        // Required Fields
        let requiredFields  = ['name','description'];
        requiredFields.forEach(item=>{
            if(values[item] === undefined || values[item] === '')
                errors[item] = errors[item] || t('components.forms.celestialPointForm.required');
        })

        // Errors
        return errors;
    }

    const initialValues = {
        ...formData
    }

    return (
        <Form
            debug           = {false}
            disabled        = {disabled}
            onSubmit        = {handleSubmit}
            onCancel        = {handleCancel}
            initialValues   = {initialValues}
            validate        = {validate}
            {...FormProps}
            render          = {({disabled, form, error, dirtySinceLastSubmit, submitFailed, submitSucceeded, errors, handleSubmit, values, ...rest}) => {
                return (
                    <form onSubmit={handleSubmit} noValidate>
                        <Grid container>
                        {
                            [   
                                { name: 'name',         multi: false, translationKey : 'components.forms.celestialPointForm.name'},
                                { name: 'description',  multi: true,  translationKey : 'components.forms.celestialPointForm.description'}
                            ]
                                .map(({name, multi, translationKey, xs = 12 }, ix) => {
                                    const extra = multi 
                                        ? {multiline:true, minRows:1, maxRows:10, disabled} 
                                        : {disabled : isEditing || disabled, type : "text" };
                                    return (
                                        <Grid key={`text${ix}`} item xs={xs}>
                                            <TextField 
                                                name        = {name} 
                                                label       = {t(translationKey)}
                                                value       = {values[name]}
                                                {...extra}
                                                showError   = {showError}
                                            />
                                        </Grid>
                                    )
                                })
                        }
                        </Grid>
                    </form>
                )
            }}
        />
    )
}

export default CelestialPointForm;