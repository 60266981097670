/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Comments Component
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       1st May 2022

*******************************************************************************************/
import React                            from 'react';
import { 
    styled,
    useTheme, Box, DialogTitle, 
    IconButton, Typography 
}                                       from '@mui/material';
import CloseIcon                        from '@mui/icons-material/Close';
import AllOutIcon                       from '@mui/icons-material/AllOut';
import { ItemPaper }                    from 'components'
import { 
    CommentSubscriptionProvider,
    useModalSpacing,
    useHeader,
    useTranslation
}                                       from 'contexts';

// Local Components
import CommentsTitle                    from './CommentsTitle';
import CommentsDetachButton             from './CommentsDetachButton';
import CommentsTree                     from './CommentsTree';


const noop              = () => {};

const PopupContainer = styled(Box,{
    shouldForwardProp : prop => prop !== 'topSpace'
})(({theme, topSpace = 100}) => ({
    position    : 'fixed',
    background  : theme.palette.background.paper, 
    // backdropFilter: 'blur(0px)',
    borderColor : theme.palette.divider,
    borderTop   : '1px solid', 
    borderRight : '1ps xolid', 
    bottom      : 0, 
    left        : 0,
    height      : `calc(100% - ${topSpace}px)`,
    maxHeight   : `calc(100% - ${topSpace}px)`,
    zIndex      : 1000, 
    overflow    : 'scroll',
    width       : 'calc(50% - 4px)', 
    [theme.breakpoints.down('xl')] : {
        width       : '41.6%', 
    },
    [theme.breakpoints.down('lg')] : {
        width       : '75%', 
        height      : '60%',
    },
    [theme.breakpoints.down('md')] : {
        width       : '100%', 
        height      : '50%'
    }
}))


const CommentsDetached = ({parentId, childCount, onClose : handleClose = noop, onChange : handleChange = noop, ...props}) => {
    const {dims,show}                                       = useHeader();
    const theme                                             = useTheme();
    const { bottom, defaults : { bottom : defaultBottom }}  = useModalSpacing();
    const commentsBoxStyle                                  = React.useMemo(() => ({overflow:'scroll', paddingBottom : bottom - defaultBottom + theme.spacing(2)}), [bottom, defaultBottom, theme])
    const containerBoxStyle                                 = React.useMemo(() => ({height:'100%'}), []);
    const dialogTitleStyle                                  = React.useMemo(() => ({padding:theme.spacing(0.5), paddingLeft:theme.spacing(2), background:theme.palette.action.hover}), [theme]);
    return (
        <PopupContainer topSpace={show ? dims.height : 0}>
            <Box display="flex" flexDirection="column" style={containerBoxStyle}>
                <Box flexShrink={1}>
                    <DialogTitle style={dialogTitleStyle}>
                        <Box display="flex" alignItems="center">
                            <CommentsTitle childCount={childCount} /> 
                            <Box>
                                <IconButton onClick={handleClose} size="large">
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    </DialogTitle>  
                </Box>
                {/* RECURSIVE, No Title*/}
                <Box component={ItemPaper} flexGrow={1} style={commentsBoxStyle}>
                    <CommentsEntryPoint 
                        onChange    = {handleChange} 
                        parentId    = {parentId} 
                        master      = {false} // Explicitly false
                    />
                </Box>
            </Box>
        </PopupContainer>
    );
}


const CommentsEntryPoint = ({parentId, showTitle = true, showDetachedButtons = true, master = false, onChange = noop, onDetach = noop, ...props}) => {
    
    const theme                             = useTheme();
    const {t}                               = useTranslation();
    const [childCount,      setChildCount]  = React.useState(0)
    const [detached,        setDetached]    = React.useState(false);
    const handleOpen                        = React.useCallback(() => setDetached(true), []);
    const handleClose                       = React.useCallback(() => setDetached(false), []);
    const handleChange                      = React.useCallback( ({childCount, ...rest}) => {
        onChange({childCount, ...rest})
        setChildCount(childCount);
    }, [onChange]);

    React.useEffect(() => {
        if(detached){
            window.scrollTo({ top : 0, behavior : 'smooth' })
        }
        onDetach(detached)
    },[detached, onDetach, parentId])

    const scrollToElement = React.useCallback( (id) => {
        try{
            const element = document.getElementById(id);
            if(element)  element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'start'});
        }catch(err){
            // silent
        }
    }, []);

    const containerBoxStyle = React.useMemo(() => ({background: detached ? theme.palette.action.disabledBackground : 'inherit'}), [detached, theme.palette.action.disabledBackground]);
    const iconProps         = React.useMemo(() => ({style:{fontSize:20}}), []);
    const dialogProps       = React.useMemo(() => ({fullScreen:false}), []);
    const rootId            = React.useMemo(() => `comment-${parentId}`, [parentId])

    return (
        <CommentSubscriptionProvider rootId={parentId}>
            <Box id={rootId} width = "100%" style={{marginLeft:theme.spacing(-0.5)}}>
                {   
                    master && (showTitle || showDetachedButtons) &&
                    <Box display={'flex'} style={{width:'100%'}}> 
                        {
                            showTitle && !detached &&  
                            <Box flexGrow={1} pb={1}> 
                                <CommentsTitle childCount={childCount} /> 
                            </Box>
                        }
                        {
                            showDetachedButtons && 
                            <Box pb={detached ? 2 : 0} flexGrow={detached ? 1 : 0} flexShrink={detached ? 0 : 1} style={containerBoxStyle}>
                                {
                                    detached &&  
                                    <Typography component={Box} pt={3} align="center"> 
                                        {t('components.comments.commentsDetached')}
                                    </Typography> 
                                }   
                                <Box display="flex" justifyContent="center" m="auto">
                                    <Box>
                                        <CommentsDetachButton 
                                            parentId    = {parentId} 
                                            color       = "default"
                                            iconProps   = {iconProps}
                                            onOpen      = {handleOpen}
                                            onClose     = {handleClose}
                                            onChange    = {handleChange}
                                            Icon        = {AllOutIcon}
                                            render      = {({parentId, handleChange, handleClose}) => {
                                                const handleCloseClick = (el) => {
                                                    handleClose(el);
                                                    scrollToElement(rootId)
                                                }
                                                return (
                                                    <CommentsDetached
                                                        parentId    = {parentId} 
                                                        childCount  = {childCount} 
                                                        onClose     = {handleCloseClick} 
                                                        onChange    = {handleChange}
                                                    />
                                                )
                                            }}
                                        />
                                    </Box>
                                    {
                                        !detached && 
                                        <Box>
                                            <CommentsDetachButton 
                                                parentId    = {parentId} 
                                                color       = "default"
                                                iconProps   = {iconProps}
                                                dialogProps = {dialogProps}
                                                onChange    = {handleChange}
                                            />
                                        </Box>
                                    }
                                </Box>
                            </Box>
                        }
                    </Box>
                }

                {/* Make Recursively if modal not detached */}
                {(!detached || !master) && 
                    <CommentsTree
                        parentId        = {parentId} 
                        level           = {0}
                        onChange        = {handleChange}
                        borderColor     = {theme.palette.divider}
                    />
                }
            </Box>
        </CommentSubscriptionProvider>
    )
}

export const Comments = ({parentId, ...props}) => {
    return (
        <CommentsEntryPoint 
            master      = {true} 
            parentId    = {parentId} 
            {...props}
        />
    )
}

export default Comments;