/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
TabPanel
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       2nd January 2021

*******************************************************************************************/
import React                from 'react';
import { Tabs, Tab}         from '@mui/material';
import { RootContainer }    from 'components';

const TabPanel = ({children, unmountOnExit=false, value, index, ...props}) => {
    if(unmountOnExit && value !== index) 
        return null;
    return (
        <RootContainer
            role            = { `tabpanel` }
            id              = { `tabpanel-${index}` } 
            aria-labelledby = { `tabpanel-${index}` }
            hidden          = { value !== index }
            {...props}
            sx              = {{
                height : '100%',
                ...props.sx
            }}
        >
            {children}
        </RootContainer>
    );
};

export {
    TabPanel,
    Tab,
    Tabs
}

export default TabPanel;