/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
ColorBox
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       8th June 2021

*******************************************************************************************/

import React from 'react';
import {Box} from '@mui/material'

// Default Width
const   DEFAULT_WIDTH   = 15,
        DEFAULT_HEIGHT  = 15;

export const ColorBox = ({color = "black", width = DEFAULT_WIDTH, height = DEFAULT_HEIGHT, ...props }) => (
    <Box 
        sx={{
            bgcolor     : color,
            width,
            height,
            textAlign   : 'center',
            border      : '1px solid',
            borderColor : theme => theme.palette.divider
        }} 
        {...props}
    />
)

export default ColorBox;