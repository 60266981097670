/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Cart Component
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       29th June 2021

*******************************************************************************************/
import React                            from 'react';
import { 
    RootContainer,
    CartItemsSheet,
    CartOrderSheet,
}                                       from 'components';
import {useScrollToHash}                from 'hooks';
import { useCart }                      from 'contexts/CartContext';

// Shopping Cart
export const Cart = ({
    readOnly            = false,
    actions             = undefined,
    showClearCart       = true,
    showBrowse          = true,
    tabular             = false
}) => {  
    const { hasItems }      = useCart();
    useScrollToHash();
    return (
        <RootContainer id="cartitems">  
            {   
                hasItems && !tabular && 
                <CartItemsSheet showBrowse={showBrowse} readOnly={readOnly}/>
            }
            {
                hasItems && tabular && 
                <CartOrderSheet />
            }
        </RootContainer>
    )
}

export default Cart;