/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
useFeatures Hook
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       27th March 2022

*******************************************************************************************/
import React            from 'react';
import {
    useCelestialBody,
    useCelestialPoint,
    useAngle,
    useSign,
    useHouse,
    useAspect,
}                       from 'contexts';

export const useFeatures = () => {

    const {data:signs,              enable:enabled1}   = useSign();
    const {data:angles,             enable:enabled2}   = useAngle();
    const {data:celestialBodies,    enable:enabled3}   = useCelestialBody();
    const {data:celestialPoints,    enable:enabled4}   = useCelestialPoint();
    const {data:houses,             enable:enabled5}   = useHouse();
    const {data:aspects,            enable:enabled6}   = useAspect();
    
    // Enable All Features
    React.useEffect(() => {
        [ enabled1, enabled2, enabled3, enabled4, enabled5, enabled6 ].forEach(enabled => enabled());
    },[enabled1, enabled2, enabled3, enabled4, enabled5, enabled6]);

    // Done
    return {
        signs,
        angles,
        celestialBodies,
        celestialPoints,
        houses,
        aspects
    }
}

export default useFeatures;