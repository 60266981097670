
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Push Context
********************************************************************************************
Boilerplate context, consumer, provider and hook

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       14th August 2022

*******************************************************************************************/
import React                            from 'react';
import { useNetwork }                   from './NetworkContext';
import { useAlert }                     from './AlertContext';
import { useUser }                      from './UserContext';

// The Product Context 
const PushContext = React.createContext(undefined);

// Push Provider
const PushProvider = ({children}) => {

    const {logout}                  = useUser();
    const {socketUsers : socket}    = useNetwork();
    const {alert}                   = useAlert();

    React.useEffect(() => {
        if(socket){
            const handleLogout = () => {
                alert("Logging out user session",'info');
                logout();
            }
            socket.on('logout', handleLogout);
            return () => {
                socket.off('logout', handleLogout);
            }
        }
    }, [alert, logout, socket])

    // Context values
    const value = {
    };

    return (
        <PushContext.Provider value={value}>
            {children}
        </PushContext.Provider>
    )
}

// Push Consumer
const PushConsumer = ({children}) => {
    return (
        <PushContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('PushConsumer must be used within PushProvider');
                }
                return children(context)
            }}
        </PushContext.Consumer>
    )
}

// usePush Hook
const usePush = () => {
    const context = React.useContext(PushContext);
    if(context === undefined)
        throw new Error('usePush must be used within PushProvider');
    return context;
}

export {
    PushProvider,
    PushConsumer,
    usePush
}