/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Personal Library
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       1st June 2022

*******************************************************************************************/

import React                from 'react';
import { 
  Box,
  Typography 
}                           from '@mui/material';
import {
  Title,
  ContentfulRichText
}                           from 'components';
import SectionContainer     from '../components/SectionContainer';

export const WidgetRichText = ({data = undefined}) => {
  if(!data)
    return null;
  return (
    <SectionContainer>
      <Box sx={{maxWidth:1000,mx:'auto'}}>
        <Title component="h2" variant="h2" gutterBottom>
          {data?.fields?.title}
        </Title>
        <Typography paragraph variant="h5">
          {data?.fields?.subtitle}
        </Typography>
        <ContentfulRichText content={data?.fields?.content} />    
      </Box>
    </SectionContainer>
  )
}

export default WidgetRichText;