/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
LibraryComments
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       16th October 2021

*******************************************************************************************/
import React                            from 'react';
import { Box, Typography }              from '@mui/material';
import {
    RootContainer
}                                       from 'components';
import {
    CommentsThread,
    CommentsNotPublic
}                                       from 'components/comments';
import { 
    useLibraryViewer,
    useTranslation
}                                       from 'contexts';

export const LibraryComments = ({...props}) => {
    const {t} = useTranslation();
    const {
        libraryId,
        isPublic,
        commentsEnabled
    } = useLibraryViewer();
    if(!libraryId) 
        return null;
    return (
        <RootContainer>
            {
                isPublic && commentsEnabled && 
                <CommentsThread reference = {libraryId} {...props} />
            }

            {   isPublic && !commentsEnabled && 
                <CommentsNotPublic sx={{pr:2,pb:2}}>
                    <Typography>
                        { t('components.library.libraryComments.commentsDisabled') }
                    </Typography>
                </CommentsNotPublic>
            }

            {   // Only owner can see this, 'isOwner' explicit check not required. If not public, only owner can view.
                !isPublic && 
                <CommentsNotPublic sx={{pr:2,pb:2}}>
                    <Box>
                        <Typography gutterBottom>
                            { t('components.library.libraryComments.commentsDisabled') }
                        </Typography>
                        <Typography variant="body2">
                            ({ t('components.library.libraryComments.privateLibrary') })
                        </Typography>
                    </Box>
                </CommentsNotPublic>
            }
        </RootContainer>
    )
}

export default LibraryComments;