/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Email Confirmation Warning
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       24th August 2023

*******************************************************************************************/
import React                            from 'react';
import { Box, Typography }              from '@mui/material';
import WarningIcon                      from '@mui/icons-material/Warning';
import { useTranslation }               from 'contexts';

export const EmailConfirmationWarning = (props) => {
    const {t} = useTranslation();
    return (
        <Box display="flex">
            <Box>
                <WarningIcon sx={{color : theme => theme.palette.warning.dark}}/>
            </Box>
            <Box ml={1}>
                <Typography sx={{color : theme => theme.palette.warning.dark}}>
                    { t('components.emailConfirmationWarning.checkEmail') }
                </Typography>
            </Box>
        </Box>
    )
}

export default EmailConfirmationWarning;
