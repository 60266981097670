/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Footer Button
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       22nd December 2020

*******************************************************************************************/
import React                            from 'react';
import { Button }                       from '@mui/material';
import { Link }                         from 'react-router-dom';
import { FONT_FAMILY_FEATURE }          from 'themes';

export const FooterButton = ({children, ...props}) => {
    return (
        <Button 
            component   = {Link} 
            size        = "small" 
            color       = "inherit" 
            {...props}
             sx = {{
                ...props.sx, 
                m : '2px', border :'none!important', fontFamily : FONT_FAMILY_FEATURE, textTransform : 'lowercase', color : 'inherit'
            }}
        >          
            {children}
        </Button>
    )
}

export default FooterButton;