/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Admin -- Coupons
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       14th November 2021

*******************************************************************************************/
import React                            from 'react';
import moment                           from 'moment';
import {clone,isNil}                    from 'lodash';
import { 
    Chip, Grid, MenuItem, InputAdornment, IconButton 
}                                       from '@mui/material';
import RefreshIcon                      from '@mui/icons-material/Refresh';
import {
    Form
}                                       from 'components';
import {
    NoopFields,
    DateTimeField,
    SelectYesNoField
}                                       from './fields';
import {
    useProduct,
    useLocale,
    useTranslation
}                                       from 'contexts';
import { 
    Select,
    Autocomplete,
    TextField,
    showErrorOnChange   as showError,
}                                       from 'mui-rff';

const MIN_CODE_LENGTH = 4;

function generateRandonString(length) {
    var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var charLength = chars.length;
    var result = '';
    for ( var i = 0; i < length; i++ ) {
       result += chars.charAt(Math.floor(Math.random() * charLength));
    }
    return result;
 }
  

const noop = () => {};
const obj = {};

export const CouponForm = ({
    formData                = obj,
    isEditing               = false,
    disabled                = false,
    onSubmit                = noop,
    onCancel : handleCancel = noop,
    FormProps               = obj,
    ...rest
}) => {
    const {t}           = useTranslation();
    const {currency}    = useLocale();
    const {data}        = useProduct();

    const initialValues = {
        ...formData,
        discountValue : formData.discountType === 'fixed' ? formData.discountValue / 100 : formData.discountValue
    }

    const handleSubmit = React.useCallback( (values) => {
        const formData = clone(values);
        if(formData.discountType === 'fixed')
            formData.discountValue = Math.round(formData.discountValue * 100);
        return onSubmit(formData);
    }, [onSubmit]);

    // Autocomplete Product Data
    const productData = React.useMemo(() => (

        data
            .filter(d => /*d.available &&*/ !d.deleted)
            .map(d => ({
                value : d.id,
                label : d.name
            }))
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [JSON.stringify(data)]);

    // Autocomplete Product Type Data
    const productTypeData = React.useMemo(() => ([
        {value : 'virtual',   label : 'Virtual' },
        {value : 'physical',  label : 'Physical'}
    ]), []);

    // Discount Type Data 
    const discountTypeData = React.useMemo(() => ([
        {value : 'fixed',       label : 'Fixed Amount', unit : currency},
        {value : 'percentage',  label : 'Percentage',   unit : '%'}
    ]), [currency]);

    const __tData = React.useMemo(() => ([
        {value : 'OrderCoupon',     label : 'Order'      },
        {value : 'ProductCoupon',   label : 'Product'    }
    ]), []);

    const validate = React.useCallback((values) => {

        // Empty Errors Object
        let errors = {}, types;

        // Handle Required Function
        const required = (key) => {
            if(isNil(values[key])){
                errors[key] = errors[key] || t('components.forms.couponForm.required');
            }
        }

        // Ensure Type is picked
        ['__t','code'].forEach(required);

        // Base Required
        if(values.__t){
            ['enabled','description','public','timesUsedMax','excludeShipping','discountValue','discountType'].forEach(required);
        }

        // Product Coupon Required
        if(values.__t === 'ProductCoupon'){
            ['excludeSaleItems','timesUsedMaxPerOrder'].forEach(required);
        }

        // Regex check for code
        if(values.code && /[^0-9a-zA-Z]/.test(values.code))
            errors.code = errors.code || t('components.forms.couponForm.mustBeAlpha');

        // Check Length
        if(values.code && values.code.length < MIN_CODE_LENGTH)
            errors.code = errors.code || t('components.forms.couponForm.lengthMinChar',{minLength:MIN_CODE_LENGTH});

        // timesUsedMax,timesUsedMaxPerOrder integer in range [0,inf]
        ['timesUsedMax', 'timesUsedMaxPerOrder'].forEach(key => {
            if(!isNil(values[key])){
                if(!Number.isInteger(values[key]) && !/^[-0-9]+$/.test(values[key]))
                    errors[key] = errors[key] || t('components.forms.couponForm.mustBeInteger');
                if(parseInt(values[key]) < 0)
                    errors[key] = errors[key] || t('components.forms.couponForm.mustBePositiveOrZero');
            }
        })

        // Check Dates
        if(values.validTo && values.validFrom && values.validFrom.isSameOrAfter(values.validTo)){
            errors.validFrom    = errors.validFrom  || t('components.forms.couponForm.validFromValidTo');
            errors.validTo      = errors.validTo    || t('components.forms.couponForm.validToValidFrom');
        }

        // Check Discount Types
        types = ['fixed','percentage'];
        if(!isNil(values.discountType) && !types.includes(values.discountType)){
            errors.discountType = errors.discountType || t('components.forms.couponForm.mustBeOneOf', { values : types.join(', ') });
        }

        // Check Discount Values
        if(!isNil(values.discountValue)){
            let d = parseFloat(values.discountValue);
            if((d > 100 || d <= 0) && values.discountType === 'percentage'){
                errors.discountValue = errors.discountValue || t('components.forms.couponForm.mustBeInRange',{minimum:0,maximum:100});
            } else if(d <= 0){
                errors.discountValue = errors.discountValue || t('components.forms.couponForm.mustBePositive');
            }
        }

        // Check Discount Types
        types = productTypeData.map(x => x.value);
        if(!isNil(values.types) && !(values.types || []).map(t => types.includes(t)).every(Boolean) )
            errors.types = errors.types || t('components.forms.couponForm.mustBeOneOf', { values : types.join(', ') });

        // Return Errors
        return errors;
    },[t, productTypeData]);

    return (
        <Form
            debug           = {false}
            disabled        = {disabled}
            onSubmit        = {handleSubmit}
            onCancel        = {handleCancel}
            initialValues   = {initialValues}
            validate        = {validate}
            showObjectId    = {false}
            {...FormProps}
            render          = {({disabled, form, error, dirtySinceLastSubmit, submitFailed, submitSucceeded, errors, handleSubmit, values, ...rest}) => {
                const handleDatePickerChange = fieldName => value => form.change(fieldName,value);
                return (
                    <form onSubmit={handleSubmit} noValidate>
                        <Grid container spacing={1}>
                            <Grid item xs={7}>
                                <TextField
                                    disabled        = {disabled}
                                    name            = "code"
                                    label           = {t('components.forms.couponForm.couponCode')}
                                    helperText      = {t('components.forms.couponForm.mustBeUnique')}
                                    showError       = {showError}
                                    InputProps      = {{
                                        endAdornment: (
                                            <IconButton size="small" onClick={() => {
                                                form.change('code', generateRandonString(6))
                                            }}>
                                                <RefreshIcon />
                                            </IconButton>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Select
                                    name            = "__t"
                                    label           = {t('components.forms.couponForm.type')}
                                    disabled        = {(values._id && values.__t) || disabled}
                                    helperText      = {t('components.forms.couponForm.immutableOnceSet')}
                                    showError       = {showError}
                                    inputLabelProps = {{shrink : true}}
                                >
                                    {
                                        __tData.map(({value,label},ix)=>(
                                            <MenuItem key={ix} value={value}>
                                                {label}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </Grid>
                            <Grid item xs={2}>
                                <SelectYesNoField
                                    disabled        = {disabled}
                                    name            = "enabled"
                                    label           = {t('components.forms.couponForm.enabled')}
                                    helperText      = {t('components.forms.couponForm.turnOnOff')}
                                    showError       = {showError}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <Select
                                    disabled        = {disabled}
                                    name            = "discountType"
                                    label           = {t('components.forms.couponForm.discountType')}
                                    showError       = {showError}
                                    inputLabelProps = {{shrink : true}}
                                >
                                    {
                                        discountTypeData.map(({value,label},ix)=>(
                                            <MenuItem key={ix} value={value}>
                                                {label}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    disabled        = {disabled}
                                    name            = "discountValue"
                                    label           = {t('components.forms.couponForm.discountValue')}
                                    type            = "number"
                                    showError       = {showError}
                                    InputProps={{
                                        endAdornment : ( 
                                            <InputAdornment position="end">
                                                {discountTypeData.find(x => x.value === values.discountType)?.unit || null}
                                            </InputAdornment> 
                                        ) 
                                    }}
                                    fieldProps      = {{
                                        parse : v => v ? parseFloat(v) : undefined
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    disabled        = {disabled}
                                    name            = "description"
                                    label           = {t('components.forms.couponForm.description')}
                                    maxRows         = {10}
                                    showError       = {showError}
                                    helperText      = {t('components.forms.couponForm.generalDescription')}
                                    multiline
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <SelectYesNoField
                                    disabled        = {disabled}
                                    name            = "public"
                                    label           = {t('components.forms.couponForm.makePublic')}
                                    helperText      = {t('components.forms.couponForm.displayedOnHomepage')}
                                    showError       = {showError}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    disabled        = {disabled}
                                    name            = "timesUsedMax"
                                    label           = {t('components.forms.couponForm.maxUses')}
                                    helperText      = {t('components.forms.couponForm.zeroMeansNoLimit')}
                                    type            = "number"
                                    showError       = {showError}
                                    InputProps      = {{ inputProps: { min: 0 } }}
                                    fieldProps      = {{
                                        parse : v => v ? parseInt(v) : undefined
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <SelectYesNoField
                                    disabled        = {disabled}
                                    name            = "excludeShipping"
                                    label           = {t('components.forms.couponForm.excludeShipping')}
                                    showError       = {showError}
                                    helperText      = {t('components.forms.couponForm.protectShippingFees')}
                                />
                            </Grid>
                            <>
                                <NoopFields names={['validFrom','validTo']} />
                            </>
                            <Grid item xs={6}>
                                <DateTimeField
                                    name            = 'validFrom' 
                                    label           = {t('components.forms.couponForm.validFrom')}
                                    disabled        = {disabled} 
                                    viewTime        = {true}
                                    disableFuture   = {false}
                                    onChange        = {handleDatePickerChange('validFrom')}
                                    usetimezone     = {true}
                                    value           = {values.validFrom ? moment(values.validFrom) : null}
                                    helperText      = {t('components.forms.couponForm.ifSetTimeWhen', { when : 'AFTER' })}
                                    showError       = {showError}
                                    // validateFields  = {['validFrom','validTo']}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <DateTimeField
                                    name            = 'validTo' 
                                    label           = {t('components.forms.couponForm.validTo')}
                                    disabled        = {disabled} 
                                    viewTime        = {true}
                                    disableFuture   = {false}
                                    disablePast     = {true}
                                    onChange        = {handleDatePickerChange('validTo')}
                                    usetimezone     = {true}
                                    value           = {values.validTo ? moment(values.validTo) : null}
                                    helperText      = {t('components.forms.couponForm.ifSetTimeWhen', { when : 'BEFORE' })}
                                    showError       = {showError}
                                    // validateFields  = {['validFrom','validTo']}
                                />
                            </Grid>
                            
                            {
                                values.__t === "ProductCoupon" && 
                                <React.Fragment>
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            disabled                = {disabled}
                                            name                    = "products"
                                            label                   = {t('components.forms.couponForm.products')}
                                            options                 = {productData.map(x => x.value)}
                                            value                   = {values.products}
                                            getOptionValue          = {option => option}
                                            getOptionLabel          = {option => productData.find(x => x.value === option)?.label}
                                            disableCloseOnSelect    = {true}
                                            isOptionEqualToValue    = {(option,value) => option === value }
                                            textFieldProps          = {{
                                                InputLabelProps : {shrink :true}
                                            }}
                                            renderTags              = {(value, getTagProps) =>
                                                value.map((option, index) => (
                                                    <Chip
                                                        {...getTagProps({ index })}
                                                        color   = "primary"
                                                        size    = "small"
                                                        label   = {productData.find(x => x.value === option)?.label || option} 
                                                    />
                                                ))
                                            }
                                            helperText              = {t('components.forms.couponForm.noProductsMeansAll')}
                                            multiple
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Autocomplete
                                            disabled                = {disabled}
                                            name                    = "types"
                                            label                   = {t('components.forms.couponForm.productTypes')}
                                            options                 = {productTypeData.map(x => x.value)}
                                            value                   = {values.types}
                                            getOptionValue          = {option => option}
                                            getOptionLabel          = {option => productTypeData.find(x => x.value === option)?.label}
                                            isOptionEqualToValue    = {(option,value) => option === value }
                                            disableCloseOnSelect    = {true}
                                            textFieldProps          = {{
                                                InputLabelProps : {shrink :true}
                                            }}
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => {
                                                    return (
                                                        <Chip
                                                            {...getTagProps({ index })}
                                                            color   = "primary"
                                                            size    = "small"
                                                            label   = {productTypeData.find(x => x.value === option)?.label || option}
                                                        />
                                                    )
                                                })
                                            }
                                            multiple
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            disabled        = {disabled}
                                            name            = "timesUsedMaxPerOrder"
                                            label           = {t('components.forms.couponForm.maxUsesPerOrder')}
                                            type            = "number"
                                            showError       = {showError}
                                            InputProps      = {{ inputProps: { min: 0 } }}
                                            fieldProps      = {{
                                                parse : v => v ? parseInt(v) : undefined
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <SelectYesNoField
                                            disabled        = {disabled}
                                            name            = "excludeSaleItems"
                                            label           = {t('components.forms.couponForm.excludeSaleItems')}
                                            showError       = {showError}
                                        />
                                    </Grid>
                                </React.Fragment>
                            }
                        </Grid>
                    </form>
                );
            }}
        />
    );
}

export default CouponForm;