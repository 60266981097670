
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
ModalSpacing Context
********************************************************************************************
Boilerplate context, consumer, provider and hook

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       19th February 2022

*******************************************************************************************/
import React from 'react';

// The Product Context 
const ModalSpacingContext = React.createContext(undefined);

const   DEFAULT             = 16;
const   DEFAULT_TOP         = DEFAULT,
        DEFAULT_LEFT        = 0 * DEFAULT,
        DEFAULT_RIGHT       = 0 * DEFAULT,
        DEFAULT_BOTTOM      = DEFAULT;

// ModalSpacing Provider
const ModalSpacingProvider = ({children}) => {

    const [top,     setTop]     = React.useState(DEFAULT_TOP);
    const [bottom,  setBottom]  = React.useState(DEFAULT_BOTTOM);
    const [left,    setLeft]    = React.useState(DEFAULT_LEFT);
    const [right,   setRight]   = React.useState(DEFAULT_RIGHT);

    const reset = React.useCallback(() => {
        setTop(     DEFAULT_TOP);
        setBottom(  DEFAULT_BOTTOM);
        setLeft(    DEFAULT_LEFT);
        setRight(   DEFAULT_RIGHT);
    },[]);

    // Context values
    const value = React.useMemo(() => ({
        top,
        setTop,
        bottom,
        setBottom,
        left,
        setLeft,
        right,
        setRight,
        reset,
        defaults : {
            top         : DEFAULT_TOP,
            bottom      : DEFAULT_BOTTOM,
            left        : DEFAULT_LEFT,
            right       : DEFAULT_RIGHT
        }
    }), [bottom, left, reset, right, top]);

    return (
        <ModalSpacingContext.Provider value={value}>
            {children}
        </ModalSpacingContext.Provider>
    )
}

// ModalSpacing Consumer
const ModalSpacingConsumer = ({children}) => {
    return (
        <ModalSpacingContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('ModalSpacingConsumer must be used within ModalSpacingProvider');
                }
                return children(context)
            }}
        </ModalSpacingContext.Consumer>
    )
}

// useModalSpacing Hook
const useModalSpacing = () => {
    const context = React.useContext(ModalSpacingContext);
    if(context === undefined)
        throw new Error('useModalSpacing must be used within ModalSpacingProvider');
    return context;
}

export {
    ModalSpacingProvider,
    ModalSpacingConsumer,
    useModalSpacing
}