/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
React Final Form - Form HOC
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       9th May 2021

*******************************************************************************************/

import React                            from 'react';
import {omit,isEmpty,isEqual, size}     from 'lodash';
import flat                             from 'flat';
import { 
    styled,
    useTheme, 
    alpha, 
    lighten, 
    Box, 
    DialogActions as DialogActionsMUI, 
    Typography 
}                                       from '@mui/material';
import CheckIcon                        from '@mui/icons-material/CheckCircleOutline';
import CancelIcon                       from '@mui/icons-material/Cancel';
import UndoIcon                         from '@mui/icons-material/Undo';
import { 
    JSONViewer, 
    FormAlert, 
    FormFailBox,
    ObjectId,
    Button,
}                                       from 'components';
import { withTranslation }              from './hoc';
import { Confirmation }                 from 'components/modals';
import { 
    FORM_ERROR, 
    useStateEphemeral
}                                       from 'hooks';
import { Form as FormRFF, FormSpy}      from 'react-final-form';

const DialogActions = styled(DialogActionsMUI,{
    shouldForwardProp : prop => prop !== 'sticky'
})(({theme,sticky=false}) => ({
    '&.MuiDialogActions-root' : {
        marginTop : theme.spacing(2),
        '& > * + *' : {
            marginLeft : theme.spacing(2)
        },
    },
    ...(sticky && {
        background      : alpha(lighten(theme.palette.primary.main, 0.6), 0.50),
        padding         : [theme.spacing(2),'!important'],
        paddingBottom   : [theme.spacing(2),'!important'],
        backdropFilter  : 'blur(3px)',
        position        : 'absolute',
        bottom          : 0,
        left            : 0,
        right           : 0,
        zIndex          : 10000
    })
}))

const sleep             = ms => new Promise(resolve => setTimeout(resolve, ms));
const noop              = () => {}
const obj               = {};
const enforceArray      = x => Array.isArray(x) ? x : [x];

export const Form = withTranslation( ({
    t,
    initialValues                       = obj,
    debug                               = false,
    disabled                            = false,
    changeRequiredToSubmit              = false,
    submitText                          = undefined,
    resetText                           = undefined,
    cancelText                          = undefined,
    successMessage                      = undefined,
    submittingMessage                   = undefined,
    confirmationRequired                = false,
    confirmationRequiredText            = undefined,
    onSubmit                            = noop,
    onCancel : handleCancel             = noop,
    onReset  : handleReset              = noop,
    onChange : handleChange             = noop,
    validate : handleValidation         = noop,
    ButtonProps                         = obj,
    SubmitButtonProps                   = obj,
    ResetButtonProps                    = obj,
    CancelButtonProps                   = obj,
    submitDelay                         = 500,
    showCancelButton                    = true,
    showResetButton                     = false,
    showSubmitButton                    = true,
    forceEnableReset                    = false,
    description                         = "",
    sticky                              = false,
    disabledSubmit                      = false,
    disabledAfterSubmit                 = true,
    resetOnSubmit                       = false,
    keepDirtyOnReinitialize             = false,
    showObjectId                        = true,
    showAlerts                          = true,
    alertComponent : AlertComponent     = FormAlert,
    ...props
}) => {
    
    const theme                                 = useTheme();
    const [open,            setOpen]            = React.useState(false);
    const [showMessages,    setShowMessages]    = useStateEphemeral(false,2000); // Success Message AutoHide

    const fixErrorHelper    = React.useCallback( (qty) => {
        return t('components.form.errorsPrompt');
        // if(qty <= 0)    return undefined;
        // if(qty === 1)   return 'Please correct the following error';
        // return `Please correct the following ${qty}x errors`;
    },[t]);

    const validate                              = React.useCallback( (values) => {
        return new Promise( resolve => {
            Promise.resolve() // incase validation func is not promisified
                .then(() => handleValidation(values))
                .then(errors => {
                    const errorCount  = Object.keys(omit(errors,FORM_ERROR)).length;
                    if(errorCount > 0)
                        errors[FORM_ERROR] = errors[FORM_ERROR] || fixErrorHelper(errorCount);
                    resolve(errors)
                })
                .catch(({errors = {}, message}) => {
                    const errorCount  = Object.keys(omit(errors,FORM_ERROR)).length;
                    errors[FORM_ERROR] = errors[FORM_ERROR] || (errorCount > 0 ? fixErrorHelper(errorCount) : message)
                    resolve(errors)
                })
        })
    }, [fixErrorHelper, handleValidation]);

    const handleConfirmOpen     = React.useCallback( () => setOpen(true), []);
    const handleConfirmClose    = React.useCallback( () => setOpen(false), []);
    const handleSubmit          = React.useCallback( async (formData) => {
        handleConfirmClose()
        await sleep(submitDelay);
        let result = onSubmit(formData);
        setShowMessages(true); // show succes message on timer
        return result
    }, [handleConfirmClose, onSubmit, setShowMessages, submitDelay]);
    
    return (
        <FormRFF
            onSubmit                = {handleSubmit}
            initialValues           = {initialValues}
            validate                = {validate}
            {...props}
            keepDirtyOnReinitialize = {keepDirtyOnReinitialize}
            render                  = {(propsInner) => {

                let {
                    form, 
                    submitting, 
                    /* reset, */
                    /*touched,*/ 
                    submitError, submitErrors:rawSubmitErrors, 
                    submitFailed, submitSucceeded,
                     /*dirty, pristine,*/ 
                     modifiedSinceLastSubmit, dirtySinceLastSubmit, 
                     error, errors, values, handleSubmit
                } = propsInner;

                const   submitErrors = omit(rawSubmitErrors, [FORM_ERROR,'FORM_ERROR']); // Omit the Form Error

                // Helper function to remove nested objects
                const stripObjects = dict => (
                    Object
                        .keys(dict)
                        .reduce((acc,cur) => {
                            return typeof dict[cur] !== 'object' 
                                ? {...acc, [cur]:dict[cur]}
                                : acc
                        },{})
                );

                const restart = () => {
                    form.setConfig('keepDirtyOnReinitialize', false);
                    form.restart();
                    form.setConfig('keepDirtyOnReinitialize', keepDirtyOnReinitialize);
                }
                const handleSubmitPromise = (e) => new Promise((resolve) => {
                    resetOnSubmit 
                        ? Promise.resolve(handleSubmit(e))
                            .then(result => {
                                if(isEmpty(result)) 
                                    restart(); // No Errors, Allow Restart
                                return result; // Next
                            })
                            .then(resolve)  // Done
                            .catch(({errors}) => {
                                resolve(errors);
                            })
                        : Promise.resolve(handleSubmit(e))
                            .then(resolve)  // Done
                            .catch(({errors}) => {
                                resolve(errors);
                            })
                })

                // Errors and Error Quantities
                const   submitErrorsQty         = size(flat(submitErrors)),
                        outOfScopeErrors        = stripObjects(omit(submitErrors, Object.keys(flat(values)))); // Errors Not from Values
                const   hasSubmitErrors         = submitErrorsQty > 0;
                const   outOfScopeErrorsQty     = size(outOfScopeErrors);
                const   hasOutOfScopeErrors     = outOfScopeErrorsQty > 0;
                const   errorsQty               = size(errors);

                // const    errorsTouched           = Object.keys(pick(errors,Object.keys(values))).reduce((acc,key) => ({...acc, [key] : touched[key]}),{});
                // const    allErrorsTouched        = size(errorsTouched) > 0 && Object.values( errorsTouched ).every(Boolean);

                return (
                    <Box width="100%" id="formRoot" sx={{pb:sticky ? 4 : 0}}>
                        {
                            showObjectId && (values?.id || values?._id) && 
                            <ObjectId value={values?.id || values?._id} render = {({id,handleCopy,allowCopy}) => (
                                <Typography 
                                    variant = "body2" 
                                    onClick = { allowCopy ? handleCopy : null } 
                                    style   = {{cursor : allowCopy ? 'pointer' : 'default'}}
                                >
                                    {t('common.objectId', {id})}
                                </Typography>
                            )}/>
                        }
                        {
                            description && 
                            <Typography component="div">
                                {description}
                            </Typography>
                        }

                        <FormFailBox failure = {submitFailed && (!modifiedSinceLastSubmit || errorsQty)} >
                            
                            {/* submission error */}
                            {   !modifiedSinceLastSubmit && submitFailed && submitError && 
                                <AlertComponent severity="error">
                                    {submitError}
                                </AlertComponent>
                            }
                            {/* submission error -- fields */}
                            {   !modifiedSinceLastSubmit && submitFailed && hasSubmitErrors && !submitError &&
                                <AlertComponent severity="error">
                                    {fixErrorHelper(submitErrorsQty)}
                                </AlertComponent>
                            }
                            {/* validation error */}
                            {   !modifiedSinceLastSubmit && submitFailed && error && 
                                <AlertComponent severity="error">
                                    {error}
                                </AlertComponent>
                            }
                            {/* submitting */}
                            {   showAlerts && !modifiedSinceLastSubmit && submitting && 
                                <AlertComponent severity="info">
                                    {submittingMessage || t('common.submitting')}
                                </AlertComponent>
                            }
                            {/* submission success */}
                            {   showAlerts && showMessages && !modifiedSinceLastSubmit && submitSucceeded &&
                                <AlertComponent severity="success">
                                    {successMessage || t('components.form.successPrompt') }
                                </AlertComponent>
                            }

                            {/* HOC Render, pass through original props */}
                            {   props.render && 
                                <Box>
                                    {props.render({...propsInner, disabled : Boolean(disabled || submitting || (disabledAfterSubmit && submitSucceeded))})}
                                </Box>
                            }

                            {/* Other Errors */}
                            {
                                hasOutOfScopeErrors && !dirtySinceLastSubmit && 
                                <Box>
                                    <Typography color='error'>
                                        { t('components.form.otherSubmissionErrors') }
                                    </Typography>
                                    {
                                        Object.keys(outOfScopeErrors).map((key,ix) => (
                                            <Typography key={ix} color='error' variant="body2">
                                                <strong>{key}:</strong> {enforceArray(outOfScopeErrors[key]).join(', ')}
                                            </Typography>
                                        ))
                                    }
                                </Box>
                            }
                            {/* DEBUG Json Viewer Errors */}
                            {
                                debug &&
                                <Box>
                                    <Typography>
                                        {t('common.debug')}
                                    </Typography>
                                    <JSONViewer src={errors}/>
                                </Box>
                            }
                            {/* DEBUG Json Viewer Values */}
                            {
                                debug && 
                                <Box>
                                    <Typography>
                                        {t('common.debug')}
                                    </Typography>
                                    <JSONViewer src={values}/>
                                </Box>
                            }
                            
                            {/* Dialog Actions */}
                            
                            {[showResetButton,showCancelButton,showSubmitButton].some(Boolean) &&
                            
                                <DialogActions sticky={sticky}>
                                    {
                                        showResetButton && 
                                        <FormSpy subscription={{ values:true, pristine: true, submitting: true, hasValidationErrors: true, visited:true}}>
                                            {props => {
                                                const initialize    = () => {
                                                    props.form.restart(initialValues);
                                                    handleReset();
                                                }
                                                const isVisited     = Object.values(props.visited).some(Boolean);
                                                return (
                                                    <Box style={{color:theme.palette.error.dark}}>
                                                        <Button
                                                            startIcon   = {<UndoIcon/>}
                                                            type        = "button" 
                                                            variant     = "text" 
                                                            color       = 'inherit'
                                                            disabled    = {(!forceEnableReset && isEqual(initialValues, props.values)) || props.submitting || (props.pristine && !isVisited)}
                                                            onClick     = {initialize}
                                                            {...ButtonProps}
                                                            {...ResetButtonProps}
                                                        >
                                                            {resetText || t('common.reset')}
                                                        </Button>
                                                    </Box>
                                                )
                                            }}
                                        </FormSpy>
                                    }

                                    {
                                        showCancelButton &&
                                        <FormSpy subscription={{ pristine: true, dirty:true, modifiedSinceLastSubmit:true, touched:true, submitting: true, values:true}}>
                                            {props => {
                                                return (
                                                    <Box style={{color:theme.palette.error.dark}}>
                                                        <Button 
                                                            startIcon   = {<CancelIcon/>}
                                                            disabled    = {props.submitting} 
                                                            type        = "button" 
                                                            variant     = "text" 
                                                            color       = "inherit"  
                                                            onClick     = {handleCancel} 
                                                            {...ButtonProps}
                                                            {...CancelButtonProps}
                                                        >
                                                            {cancelText || t('common.cancel')}
                                                        </Button> 
                                                    </Box>
                                                )
                                            }}
                                        </FormSpy>
                                    }

                                    {showSubmitButton &&
                                        <FormSpy subscription={{ pristine: true, touched: true, dirty: true, dirtySinceLastSubmit : true, valid: true}}>
                                            {props => {
                                                return (
                                                    <Button 
                                                        startIcon   = {<CheckIcon/>}
                                                        disabled    = {
                                                            disabledSubmit 
                                                            || ((changeRequiredToSubmit && isEqual(initialValues, values)) 
                                                            || (!props.valid && props.touched && !props.dirtySinceLastSubmit)) 
                                                            || disabled 
                                                            || submitting
                                                        } 
                                                        type        = "button" 
                                                        variant     = "contained" 
                                                        color       = "secondary" 
                                                        onClick     = {(e) => {
                                                            return (props.valid && confirmationRequired) 
                                                                ? Promise.resolve(handleConfirmOpen())
                                                                : handleSubmitPromise(e)
                                                        }}
                                                        {...ButtonProps}
                                                        {...SubmitButtonProps}
                                                    >
                                                        {submitText || t('common.submit')}
                                                    </Button>
                                                )
                                            }}
                                        </FormSpy>
                                    }                                

                                </DialogActions>
                            }

                            <Confirmation 
                                open    = {open}
                                title   = { t('common.pleaseConfirm') }
                                onCancel= {handleConfirmClose} 
                                onOk    = {handleSubmitPromise}
                            >
                                <Typography>
                                {
                                    (typeof confirmationRequiredText === 'function' 
                                        ? confirmationRequiredText(values) 
                                        : confirmationRequiredText
                                    ) || t('common.confirmationRequired')
                                }
                                </Typography>
                            </Confirmation>
                            <FormSpy
                                subscription    = {{ valid: true, values: true, }}
                                onChange        ={({values, valid}) => {
                                    if(valid){
                                        handleChange(values)
                                    }
                                }}
                            />
                        </FormFailBox>
                        
                        {
                            sticky &&
                            <Box height={'65px'}/>
                        }
                    </Box>
                )
            }}
        />
    )
});

export default Form;
