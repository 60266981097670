/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Ticket Message Form
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       10th December 2021

*******************************************************************************************/
import React                            from 'react';
import { Grid }                         from '@mui/material';
import { Message as MessageIcon }       from '@mui/icons-material';
import { Form }                         from 'components';
import { useTranslation }               from 'contexts';
import { 
    TextField,
    showErrorOnChange   as showError,
}                                       from 'mui-rff';

const MAX_LENGTH = 10000;
const noop = () => {};

const defaultFormData = {
    ticket  : undefined,    // hidden
    user    : undefined,    // hidden
    text    : undefined
}

export const TicketMessageForm = ({
    formData                = defaultFormData,
    disabled                = false,
    onSubmit : handleSubmit = noop,
    onCancel : handleCancel = noop,
    variant                 = undefined,
    showCancelButton        = false,
    submitText              = undefined,
    FormProps = {
        showCancelButton    : false, // dupe
        disabledAfterSubmit : false,
        showResetButton     : false,
        resetOnSubmit       : true,
        SubmitButtonProps   : {
            startIcon : <MessageIcon/>
        }
    }
}) => {
    const {t}       = useTranslation();

    const validate  = React.useCallback( (values) => {
        let errors = {};
        let {text = undefined} = values;
        if(!text){
            errors.text = errors.text || t("components.forms.ticketMessageForm.required");
        }
        if(text && text.length >= MAX_LENGTH)
            errors.text = errors.text || t("components.forms.ticketMessageForm.maxCharacters", {maximum : MAX_LENGTH, current : text.length}); 
        return errors;
    }, [t]);

    // Initial Values
    const initialValues = React.useMemo(() => ({
        ...formData
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [JSON.stringify(formData)]);

    return (
        <Form
            debug           = {false}
            disabled        = {disabled}
            onSubmit        = {handleSubmit}
            onCancel        = {handleCancel}
            initialValues   = {initialValues}
            validate        = {validate}    
            submitText      = {submitText || t('common.submit')}
            {...FormProps}
            showCancelButton= {showCancelButton}
            render          = {({disabled, form, error, dirtySinceLastSubmit, submitFailed, submitSucceeded, errors, handleSubmit, values, ...rest}) => {
                return (
                    <form onSubmit = {handleSubmit} noValidate>
                        <Grid container>
                            <Grid item xs={12}>
                                <TextField 
                                    disabled    = {disabled}
                                    name        = {'text'} 
                                    label       = {t("components.forms.ticketMessageForm.createComment")}
                                    showError   = {showError}
                                    multiline   = {true}
                                    variant     = {variant}
                                    minRows     = {10}
                                    maxRows     = {50}
                                    helperText  = {t("components.forms.ticketMessageForm.createCommentHelperText", {maxLength : MAX_LENGTH})}
                                />
                            </Grid>
                        </Grid>
                    </form>
                );
            }}
        />
    );
}

export default TicketMessageForm;