
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
With Action Button
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       20th December 2022

*******************************************************************************************/

import { styled } from '@mui/material';

export const withActionButton = Button => styled(Button)(({theme}) => ({
        padding             : 2,
        textTransform       : 'initial',
        fontSize            : 8,
        minWidth            : 60,
        marginRight         : theme.spacing(0.5),
        color               : theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.primary.main
}))

export default withActionButton;