/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
EditTemplateForm
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       4th August 2022

*******************************************************************************************/
import React                            from 'react';
import pick                             from 'lodash/pick';
import isEmpty                          from 'lodash/isEmpty';
import handleParseCode                  from 'html-react-parser'
import prettier                         from "prettier/standalone";
import parserHtml                       from "prettier/parser-html";
import { 
    CopyBlock, 
    a11yDark, 
    a11yLight 
}                                       from "react-code-blocks";
import { 
    useTheme, 
    useMediaQuery, 
    Grid, 
    Box, 
    Typography,
    FormHelperText 
}                                       from '@mui/material';
import Form                             from 'components/Form';
import DraggableDialog                  from 'components/DraggableDialog';
import { 
    RootContainer,
    FormFailBox, 
    IconMenu, 
    MenuItem,
    IconButton
}                                       from 'components';
import { useTranslation }               from 'contexts';
import { useSize }                      from 'hooks';
import EditIcon                         from '@mui/icons-material/Edit';
import {
    ListItemIcon,
    ListItemText
}                                       from '@mui/material';
import { 
    TextField,
    showErrorOnChange   as showError,
}                                       from 'mui-rff';

const noop = () => {};
const GrapesEditor = React.lazy(() => import('components/grapeseditor/GrapesEditor'));

const DEFAULT_TEMPLATE = {html : undefined, css : undefined, js : undefined};

export const EditTemplate = ({template : templateIn = DEFAULT_TEMPLATE, onChange : handleChange = noop, ...props}) => {
    
    const {t}                                   = useTranslation();
    const theme                                 = useTheme();
    const [open,            setOpen]            = React.useState(false);
    const [template,        setTemplate]        = React.useState(templateIn);
    const [templateTemp,    setTemplateTemp]    = React.useState(undefined);
    const ref                                   = React.useRef(null);
    const refPreview                            = React.useRef(null);
    const {width:previewWidth}                  = useSize(refPreview);
    const refCap                                = React.useRef(null);

    const handleOpen    = React.useCallback(() => setOpen(true), []);
    const handleClose   = React.useCallback(() => setOpen(false), []);

    const handleOk      = React.useCallback(() => {
        setTemplate(templateTemp);
        handleChange(templateTemp);
        handleClose();
    }, [handleChange, handleClose, templateTemp])

    // Reset Temp on Close
    React.useEffect(() => {
        if(!open)
            setTemplateTemp(undefined);
    },[open])

    const copyBlockTheme = React.useMemo(() => ({
        dark    : a11yDark,
        light   : a11yLight 
    }),[])

    const handleFormatCode      = React.useCallback( (code) => prettier.format( code, { semi : false, parser : "html", plugins : [parserHtml]}), []);
    const handleSetTemplateTemp = React.useCallback(({html,css}) => setTemplateTemp(prev => ({...prev, html,css})), []);
    const handleBuildHTML       = React.useCallback( (title,full = true) => {
        if(!full)
            return `
                <div>
                    <div>
                        ${template?.html || ''}
                    </div>
                    <style> ${template?.css  || ''}</style>
                    <script>${template?.js   || ''}</script>
                </div>
            `
        return `
            <html>
                <head>
                    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
                    <meta http-equiv="X-UA-Compatible" content="IE=edge">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <meta name="color-scheme" content="light dark">
                    <meta name="supported-color-schemes" content="light dark">
                    <title>${title}</title>
                </head>
                <body id="root">
                    ${template?.html || ''}
                </body>
                <style> ${template?.css  || ''}</style>
                <script>${template?.js   || ''}</script>
            </html>
        `
    },[template?.css, template?.html, template?.js])

    const templateForFormat = React.useMemo(() => handleBuildHTML('HTML Email',true),  [handleBuildHTML]);
    const templateForRender = React.useMemo(() => handleBuildHTML('HTML Email',false), [handleBuildHTML]);
    const smUp              = useMediaQuery(theme => theme.breakpoints.up('lg'));
    
    return (
        <>
            <RootContainer position="relative" sx={{ height:{md:'calc(100vh - 290px)', xs :'fit-content' }}}>
                {
                    templateForFormat &&
                    <Grid container direction="row-reverse" spacing={0} style={{height:'100%', width:'100%', overflow:'scroll', borderRadius:theme.spacing(1), border:`2px solid ${theme.palette.divider}`}}>
                        
                        <Grid item  xs={12} sm={12} md={6} lg={5} 
                            sx = {{
                                borderLeft  : `1px solid ${theme.palette.divider}`, 
                                position    : 'relative', 
                                overflow    : 'scroll',
                                // aspectRatio : {xs:'1.41',md:'unset'}
                                height      : { xs:'calc(50vh - 145px)', md :'initial'}
                            }}
                        >
                            <Box style={{position : 'absolute', top:0, left:0, right:0, bottom:0}}>
                                <Box ref={refCap} id="capture" style={{background:theme.palette.action.disabledBackground, minHeight:`100%`}}>
                                    { handleParseCode(templateForRender) }
                                </Box>
                            </Box>
                            <Box position="sticky" align="right" top={theme.spacing(0.25)} right={theme.spacing(0.25)}>
                                {
                                    !false && 
                                    <IconButton onClick={handleOpen}>
                                        <EditIcon/> 
                                    </IconButton>
                                }
                                {
                                    false && 
                                    <IconMenu
                                        render = {({handleCloseMenu}) => (
                                            <MenuItem onClick={(e) => {
                                                handleOpen();
                                                handleCloseMenu();
                                            }}>
                                                <ListItemIcon>
                                                    <EditIcon/> 
                                                </ListItemIcon>
                                                <ListItemText primary={t('common.edit')}/>
                                            </MenuItem>
                                        )}
                                    />
                                }
                            </Box>
                        </Grid>

                        {
                            smUp && 
                            <Grid item  xs={0} sm={0} md={0} lg={2} style={{borderLeft:`1px solid ${theme.palette.divider}`, paddingLeft:2, paddingRight:2, position:'relative', overflow:'scroll'}}>
                                <Box pt={0} p={1} style = {{background:theme.palette.action.disabledBackground}}>
                                    <Box ref={refPreview} style={{zoom:'40%', minHeight : 1.41 * previewWidth}}>
                                        { handleParseCode(templateForRender) }
                                    </Box>
                                </Box>
                            </Grid>
                        }

                        <Grid item xs={12} sm={12} md={6} lg={5} 
                            sx = {{
                                position    : 'relative', 
                                overflow    : 'scroll',
                                // aspectRatio : {xs:'1.41',md:'unset'}
                                height      : { xs:'calc(50vh - 145px)', md :'initial'}
                            }}
                        >
                            <Box style={{position : 'absolute', top:0, left:0, right:0, bottom:0}}>
                                <Box ref = { ref }>
                                    <CopyBlock
                                        text            = { handleFormatCode(templateForFormat) }
                                        language        = { 'html' }
                                        showLineNumbers = { false }
                                        theme           = { copyBlockTheme[theme.palette.mode] || a11yLight }
                                        wrapLines       = { true }
                                        customStyle     = {{
                                            width       : '100%',
                                            overflowX   : 'scroll',
                                            fontSize    : '0.6rem',
                                            wordWrap    : 'break-word',
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                }
            </RootContainer>
            {
                open && 
                <DraggableDialog
                    title       = {t('components.forms.editTemplateForm.editTemplate')}
                    open        = {open}
                    fullScreen  = {true}
                    onOk        = {handleOk}
                    onCancel    = {handleClose}
                    onClose     = {handleClose}
                >
                    <GrapesEditor 
                        html        = {template.html}
                        css         = {template.css} 
                        js          = {template.js}
                        onChange    = {handleSetTemplateTemp}
                    />
                </DraggableDialog>
            }
        </>
    );
}

const defaultFormData = {
    title       : undefined,
    description : undefined,
    html        : '',
    css         : '',
    js          : ''
}

const TITLE_MAX_LENGTH = 25;

export const EditTemplateForm = ({
    disabled                    = false,
    formData                    = defaultFormData,
    onSubmit    : handleSubmit  = noop,
    onCancel    : handleCancel  = noop,
    onReset     : handleReset   = noop,
    sticky                      = true,
    ...props
}) => {
    const {t}                                       = useTranslation();
    const [templateChanged, setTemplateChanged]     = React.useState(false);
    const validate                                  = React.useCallback( (formData) => {
        const errors = {};
        ['title','description','html','css'].forEach(field => {
            if(!formData[field])
                errors[field] = errors[field] || t('components.forms.editTemplateForm.required')
        })
        if(formData.title && formData.title.length > TITLE_MAX_LENGTH)
            errors.title = errors.title ||  t('components.forms.editTemplateForm.maxChars', { maximum : TITLE_MAX_LENGTH, current : formData.title.length })
        return errors;
    },[t]);

    const initialValues = React.useMemo(() => ({
        ...formData
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [ JSON.stringify(formData) ]);
    
    return (
        <Form
            disabled                = {disabled}
            onSubmit                = {handleSubmit}
            onCancel                = {handleCancel}
            onReset                 = {handleReset}
            initialValues           = {initialValues}
            validate                = {validate}
            successMessage          = { t('components.forms.editTemplateForm.submitted') }
            showObjectId            = {false}
            sticky                  = {sticky}
            disabledAfterSubmit     = {false}
            render                  = {({disabled, form, error, dirty, dirtySinceLastSubmit, submitFailed, submitSucceeded, errors, handleSubmit, values, ...rest}) => {
                const handleTemplateChange = (data) => { 
                    Object
                        .entries(data)
                        .forEach(([key,value]) => {
                            form.change(key,value);
                        });
                    setTemplateChanged(true);
                };
                const templateErrors        = pick(errors,['html','css','js']);
                const showTemplateErrors    = !isEmpty(templateErrors) && (dirtySinceLastSubmit || templateChanged);
                return (
                    <form onSubmit={handleSubmit} noValidate>
                        <Box display="flex" flexDirection={'column'} height="100%" width="100%">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <TextField 
                                        label       = { t('components.forms.editTemplateForm.title') }
                                        name        = {'title'}
                                        disabled    = {disabled}
                                        showError   = {showError}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={8} lg={9}>
                                    <TextField 
                                        label       = { t('components.forms.editTemplateForm.description') }
                                        name        = {'description'}
                                        disabled    = {disabled}
                                        showError   = {showError}
                                    />
                                </Grid>
                            </Grid>
                            
                            <Box flexGrow={1} pt={2} position="relative" display="flex" flexDirection={"column"}>
                                <FormFailBox failure={showTemplateErrors} flexGrow={1}>
                                    <Typography>
                                        { t('components.forms.editTemplateForm.editTemplate') }
                                    </Typography>
                                    <EditTemplate 
                                        template = {{html : values.html, css : values.css, js : values.js}}
                                        onChange = {handleTemplateChange}
                                    />
                                </FormFailBox>
                            </Box>

                            {
                                showTemplateErrors && 
                                <Box align="right" width="100%">
                                    {
                                        Object.entries(templateErrors).map(([key,value],ix) => (
                                            <FormHelperText error style={{textAlign:'right'}}>
                                                {key} - {value}
                                            </FormHelperText>
                                        ))
                                    }
                                </Box>
                            }
                        </Box>
                    </form>
                )
            }}
        />
    )
}

export default EditTemplateForm;
