
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Main theme
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       4th January 2021

*******************************************************************************************/
import { 
  createTheme, 
  darken, 
  lighten, 
  responsiveFontSizes 
}                                       from '@mui/material';
import {baseThemeDefinition as base}    from './base';
import {
  // indigo as primary,
  // blue        as primary,
  // pink        as secondary
  // pink        as primary,
  // deepPurple  as secondary,
  // red
}                                       from '@mui/material/colors'
import merge                            from 'deepmerge';

const secondary                                         = '#31c27c';
const primary                                           = '#221f78';
// let [primaryLight,      primaryMain,    primaryDark]    = [primary[300], primary[500], primary[700]];
let [primaryLight,      primaryMain,    primaryDark]    = [lighten(primary,0.25), primary, darken(primary,0.25)];
let [secondaryLight,    secondaryMain,  secondaryDark]  = [lighten(secondary,0.25), secondary, darken(secondary,0.25)];

const baseLight = merge(base, {
  palette: {
    mode        : 'light',
  }
})

const theme = responsiveFontSizes(createTheme(baseLight));
// const theme = createTheme(baseLight);

export const lightThemeDefinition = merge(baseLight,{
  palette: {
    primary : {
      light   : primaryLight,
      main    : primaryMain,
      dark    : primaryDark
    },
    secondary : {
      light   : secondaryLight,
      main    : secondaryMain,
      dark    : secondaryDark
    },
    background : {
      default : '#fafafa'
    }
  },
  components : {
    MuiInputLabel: {
      styleOverrides : {
        outlined: {
          backgroundColor : 'white',
          paddingLeft     : 2,
          paddingRight    : 2
        }
      }
    },
    MuiDialogContent : {
      styleOverrides : {
        root : {
          background : darken(theme.palette.background.paper,0.025)
        }
      }
    },
    MuiCard: {
      styleOverrides : {
        root : {
          background : darken(theme.palette.background.paper,0.050),
        }
      }
    },
    MuiTabs : {
      styleOverrides : {
        flexContainer : {
          borderBottom : `1px solid ${theme.palette.divider}`
        }
      }
    },
    MuiTab : {
      styleOverrides : { 
        root: {
          backgroundColor         : darken(theme.palette.background.paper,0.05),
          color                   : primaryMain,
          "&:hover": {
            backgroundColor     : darken(theme.palette.background.paper,0.075),
            color               : primaryMain
          },
          '&.Mui-selected': {
            backgroundColor         : darken(theme.palette.background.paper,0.15),
            color                   : primaryDark,
            "&:hover": {
                backgroundColor     : darken(theme.palette.background.paper,0.175),
                color               : primaryMain
            }
          },
        },
        textColorPrimary : {
            color : primaryMain
        }
      }
    }
  }
});

// console.log(lightThemeDefinition);

export const lightTheme = responsiveFontSizes(createTheme(lightThemeDefinition));
// export const lightTheme = createTheme(lightThemeDefinition);

export default lightTheme;