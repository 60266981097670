/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Drag and Drop Component
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       15th July 2021

*******************************************************************************************/
import React                            from 'react';
import {isEqual}                        from 'lodash';
import ImageList                        from '@mui/material/ImageList';
import ImageListItem                    from '@mui/material/ImageListItem';

// Drag and Drop
import { DndProvider }                  from 'react-dnd';
import { useDrag, useDrop }             from 'react-dnd';
import { HTML5Backend as Backend}       from 'react-dnd-html5-backend';
import update                           from 'immutability-helper';
import { withTranslation }              from './hoc';


const Card = ({ id, object, render, index, moveCard }) => {
    const ref = React.useRef(null);
    const [, drop] = useDrop({
        accept: "CARD",
        hover(item, monitor) {
            if (!ref || !ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current.getBoundingClientRect();

            // Get vertical middle
            //// const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Get horizontal middle
            //// const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

            // Determine mouse position
            //// const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            //// const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Get pixels to the left
            //// const hoverClientX = clientOffset.x - hoverBoundingRect.left;

            const h = hoverBoundingRect.bottom - hoverBoundingRect.top;
            const w = hoverBoundingRect.right - hoverBoundingRect.left;
            const {x:dx,y:dy} = monitor.getDifferenceFromInitialOffset();
            if(Math.abs(dx) < 0.25*w && Math.abs(dy) < 0.25*h)
                return;
            
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            
            // Dragging downwards
            /*if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }*/

            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        }
    });

    const [{ isDragging }, drag] = useDrag({
        type : "CARD",
        item : {id, index },
        collect: monitor => ({
            isDragging : monitor.isDragging()
        })
    });

    drag(drop(ref));

    return (
        <React.Fragment>
            {render({ref,object,index,isDragging})}
        </React.Fragment>
    );
};

const RenderNotImplemented = withTranslation( ({t}) => (
    <div>
        {t('components.dragAndDrop.renderMethodNotImplemented')}
    </div>
));

const arr = [];
const noop = () => {};

export const DragAndDrop = ({
    cols                        = 3, 
    data        : dataIn        = arr, 
    cellheight                  = 'auto',
    onChange                    = noop,
    render                      = ({ref, object, index, opacity, isDragging}) => <RenderNotImplemented/>,
})=>{

    // Convert data array to data w/ index array of objects
    const [data, setData]   = React.useState([]);
    React.useEffect(()=>{
        if(!isEqual(dataIn, data))
            setData(dataIn.map((d,ix) => ({id:ix,value:d})))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dataIn])

    const moveCard = React.useCallback(
        (dragIndex, hoverIndex) => {
            const dragCard = data[dragIndex]
            const newData = update(data, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, dragCard],
                ],
            })
            onChange(newData.map(item => item.value));
            // setData(newData)
        },
        [data, onChange],
    );

    return (
        <DndProvider backend={Backend}>
            <ImageList cols={cols} cellheight={cellheight}>
                {
                    data.map((object,index) => (
                        <ImageListItem key={object.id} style={{margin:0, padding:0}}>
                            <Card
                                key         = {object.id}
                                id          = {object.id}
                                index       = {index}
                                object      = {object}
                                moveCard    = {moveCard}
                                render      = {render}
                            />
                        </ImageListItem>
                    )
                )}
            </ImageList>
        </DndProvider>
    )
}

export default DragAndDrop;