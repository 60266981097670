/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Testimonials
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       1st June 2022

*******************************************************************************************/

import React                    from 'react';
import { 
    useMediaQuery,
    darken, 
    Box, 
    Typography 
}                               from '@mui/material';
import Masonry                  from '@mui/lab/Masonry';
import {
    Title,
    Review,
    SpaceBox,
    LoadingData,
    ContentfulRichText
}                               from 'components';
import { useNetwork }           from 'contexts';
import { 
    useCancelToken, 
    useCanQuery 
}                               from 'hooks';
import constants                from '../constants';
import SectionContainer         from '../components/SectionContainer';

export const WidgetTestimonials = ({data = undefined}) => {
    
    const upLg                                          = useMediaQuery(theme => theme.breakpoints.up('lg'));
    const {allowQueryForAnonymous}                      = useCanQuery();   
    const {axios}                                       = useNetwork();
    const {cancelToken, isCancel}                       = useCancelToken();

    const [testimonials,        setTestimonials]        = React.useState([]);
    const [testimonialsLoading, setTestimonialsLoading] = React.useState(false);
    
    // Query Testimonials
    React.useEffect(() => {
        let {minScore = 2.5, quantity = 10} = data?.fields || {};
        if(allowQueryForAnonymous){
            setTestimonialsLoading(true);
            axios.get(`/api/public/library/testimonials?limit=${quantity}&minScore=${minScore}`, {cancelToken})
                .then(({data}) => data)
                .then(data => (
                    data.slice(
                        0, 
                        quantity * Math.ceil( data.length / quantity )
                    )
                ))
                .then(setTestimonials)
                .catch((err) => {
                    if(isCancel(err)) return;
                    setTestimonials([]);
                })
                .finally(() => {
                    setTimeout(() => setTestimonialsLoading(false), 1000);
                })
        }
    },[axios, cancelToken, isCancel, allowQueryForAnonymous, data?.fields])

    // Check has testimonials
    const hasTestimonials = React.useMemo(() => (
        testimonials && testimonials?.length > 0
    ), [testimonials]);

    // No Testimonials
    if(!data || !hasTestimonials)
      return null;

    return (
        <SectionContainer id = {constants.ids.testimonials}>
            <Box>
                <Title component="h2" variant="h2" gutterBottom>
                    {data?.fields?.title}
                </Title>
                <Typography paragraph variant="h5">
                    {data?.fields?.subtitle}
                </Typography>
                <ContentfulRichText content={data?.fields?.content} />
            </Box>
            {
                !testimonialsLoading &&
                <Box sx={{mr:-4,mt:4}}>
                    <Masonry columns={upLg && testimonials?.length > 1 ? 2 : 1} spacing={4}>
                        {
                            testimonials.map((review,ix) => (
                                <Box 
                                    key = {ix} 
                                    sx  = {{
                                        p : 2,
                                        borderRadius    : theme => theme.spacing(2), 
                                        bgcolor         : theme => darken(theme.palette.background.paper,[0.02, 0.06, 0.04][ix % 3])
                                    }}
                                >
                                    <Review 
                                        review          = {review} 
                                        view            = {false} 
                                        showModerate    = {false} 
                                    />
                                </Box>
                            )
                        )}
                    </Masonry>
                </Box> 
            }

            {
                testimonialsLoading && 
                <SpaceBox align="center">
                    <LoadingData/>
                </SpaceBox>
            }
        </SectionContainer>
    )
}

export default WidgetTestimonials;


/*
const Testimonial = ({children, author=undefined,...props}) => {
  const classes = useStyles(props);
  return (
    <div className={clsx(classes.root,classes.testimonial)}>
      <Box>
        <Typography gutterBottom>
          {children}
        </Typography>
      </Box>
      <Box align="right">
        Review made by <strong>{author || "Anonymous"}</strong>
      </Box>
    </div>
  )
}
*/