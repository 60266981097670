/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Custom Blocks
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       29th July 2022

*******************************************************************************************/
import logo_dark                        from 'resources/logos/logo_dark_dot.png';
import logo_light                       from 'resources/logos/logo_light_dot.png';
import config                           from '../../config';

const {
    company         : COMPANY,
    website         : WEBSITE,
    staticRootUrl   : STATICROOTURL = ''
} = config;

const loremIpsum = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut 
labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco 
laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in 
voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat 
non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`

export const customLogoDark = (theme) => ({
    label       : 'Logo Dark',
    category    : 'Media',
    media       : `<div style="background:#fff;padding:4px;"><img src="${STATICROOTURL}${logo_dark}" alt="Logo Dark" style="max-width:50px;height:auto"/></div>`,
    activate    : true,
    content     : `
        <div style="text-align:center;width:100%">
            <img src="${STATICROOTURL}${logo_dark}" alt="Logo Dark" style="max-width:200px;height:auto;"/>
        </div>
    `
})

export const customLogoLight = (theme) => ({
    label       : 'Logo Light',
    category    : 'Media',
    media       : `<div style="background:#000;padding:4px;"><img src="${STATICROOTURL}${logo_light}" alt="Logo Light" style="max-width:50px;height:auto;"/></div>`,
    activate    : true,
    content     : `
        <div style="text-align:center;width:100%">
            <img src="${STATICROOTURL}${logo_light}" alt="Logo Light" style="max-width:200px;height:auto;"/>
        </div>
    `
});

export const divider = (theme) => ({
    label       : 'Divider',
    category    : 'Layouts',
    content     : `
        <table style="width: 100%; margin-top: 10px; margin-bottom: 10px;">
            <tr>
                <td class="divider"></td>
            </tr>
        </table>
        <style>
            .divider {
                background-color: rgba(0, 0, 0, 0.1);
                height: 1px;
            }
        </style>
    `,
    attributes: {class:'gjs-fonts gjs-f-divider'}
});


export const page = (theme) => ({
    label       : 'Page',
    category    : 'Layouts',
    content     : {
        type            : 'html',
        style           : { 
            padding         : theme.spacing(2), 
            'margin-left'   : 'auto', 
            'margin-right'  : 'auto',
            'max-width'     : '500px',
            'min-height'    : '400px',
            'background'    : theme.palette.background.paper
        },
        activeOnRender  : 1
    },
    attributes: { class: 'gjs-fonts gjs-f-b1' },
})

export const section = (theme) => ({
    label       : 'Section',
    category    : 'Typography',
    content     : `
        <div class="gjs-section">
            <div class="gjs-heading">
                Insert title here
            </div>
            <div class="gjs-paragraph">
                ${loremIpsum}
            </div>
        </div>
        <style>
            .gjs-section > * + * {
                margin-top: ${theme.spacing(2)};
            }
            .gjs-heading {
                color: #000000;
                font-size: 2em;
                font-weight: bold;
            }
            .gjs-paragraph {
                color: #000000;
                text-align: justify;
            }
        </style>
    `,
    attributes  : {class:'gjs-fonts gjs-f-h1p'}
});

export const text = (theme) => ({
    id          : 'text',
    label       : 'Text',
    category    : 'Typography',
    content: {
        type    : 'text',
        content : `
            <div class="gjs-paragraph">
                ${loremIpsum}
            </div>
        `,
        style   : { 
           'margin-block-start': theme.spacing(2),
           'text-align':'justify'
        },
        activeOnRender: 1
    },
    attributes: { class: 'gjs-fonts gjs-f-text' },
});

export const salutation = (theme) => ({
    label       : 'Salutation',
    category    : 'Fields',
    content: {
        type    : 'html',
        content : `
            <div class="gjs-paragraph">
                Dear {{firstname}} {{lastname}},
            </div>
        `,
        style   : { 
           'margin-block-start': theme.spacing(2),
           'text-align':'left'
        },
        activeOnRender: 1
    },
    attributes: { class: 'gjs-fonts gjs-f-text' },
});

export const ending = (theme) => ({
    label       : 'Ending',
    category    : 'Fields',
    content: {
        type    : 'text',
        content : 'Yours Sincerely',
        style   : { 
           'margin-block-start': theme.spacing(2),
           'text-align':'left'
        },
        activeOnRender: 1
    },
    attributes: { class: 'gjs-fonts gjs-f-text' },
});

export const company = (theme) => ({
    label       : 'Company Name',
    category    : 'Fields',
    content: {
        type    : 'text',
        content : COMPANY,
        style   : { 
           'text-align':'left'
        },
        activeOnRender: 1
    },
    attributes: { class: 'gjs-fonts gjs-f-text' },
});

export const website = (theme) => ({
    label       : 'Website',
    category    : 'Fields',
    content: {
        type    : 'text',
        content : WEBSITE,
        style   : { 
           'text-align':'left'
        },
        activeOnRender: 1
    },
    attributes: { class: 'gjs-fonts gjs-f-text' },
});

export const quote = (theme) => ({
    label       : "Quote",
    category    : "Typography",
    content     : `
        <blockquote class="quote">
            ${loremIpsum}
        </blockquote>
        <style>
            .quote {
                text-align: justify;
                border-left: 5px solid grey;
                padding: 16px;
            }
        </style>
    `,
    attributes  : {class:'fa fa-quote-right'}
});

export const button = (theme) => ({
    label       : "Button",
    category    : "Components",
    content     : `
        <a class="cjs-button" role="button">
            Button
        </a>
        <style>
            .cjs-button {
                appearance: button;
                background-color: ${theme.palette.primary.main};
                border: 1px solid ${theme.palette.primary.main};
                border-radius: 4px;
                box-sizing: border-box;
                color: ${theme.palette.primary.contrastText};
                cursor: pointer;
                font-family: Graphik,-apple-system,system-ui,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
                font-size: 14px;
                line-height: 1.15;
                overflow: visible;
                padding: ${theme.spacing(1.5)} ${theme.spacing(2)};
                position: relative;
                text-align: center;
                text-decoration:none;
                text-transform: uppercase;
                transition: all 80ms ease-in-out;
                user-select: none;
                -webkit-user-select: none;
                touch-action: manipulation;
                width: fit-content;
            }
            .cjs-button:disabled {
                opacity: .5;
            }
            .cjs-button:focus {
                outline: 0;
            }
            .cjs-button:hover {
                background-color: ${theme.palette.primary.dark};
                border-color: ${theme.palette.primary.dark};
            }
            .cjs-button:active {
                background-color: ${theme.palette.primary.light};;
                border-color: ${theme.palette.primary.light};;
            }
        </style>
    `,
    attributes  : {class:'gjs-fonts gjs-f-button'}
});
