
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Library Context
********************************************************************************************
Boilerplate context, consumer, provider and hook

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       11th October 2021

*******************************************************************************************/
import React                            from 'react';
import moment                           from 'moment';
import {useNetwork}                     from './NetworkContext';
import {useUser}                        from './UserContext';
import {
    useStateEphemeral,
    useCancelToken
}                                       from '../hooks';
import {UserLibraryCollectionLocation}  from '../router/locations';

// The Product Context 
const LibraryContext = React.createContext(undefined);

const BASE_API_URL = '/api/user/library' // OR /api/library/user


// Library Provider
const LibraryProvider = ({children}) => {

    const {isAuthenticated, ready}              = useUser();
    const {
        isNetworkReady, 
        axios, 
        socketUsers : socket
    }                                           = useNetwork();
    const {cancelToken,isCancel}                = useCancelToken();
    const [loading,         setLoading]         = React.useState(false);
    const [loadingPublic,   setLoadingPublic]   = React.useState(false);
    const [loadingLibraries,setLoadingLibraries]= React.useState(false);
    const [queried,         setQueried]         = React.useState(undefined);
    const [library,         setLibrary]         = React.useState([]);
    const [libraryId,       setLibraryIdRaw]    = React.useState(undefined);
    const [libraryPath,     setLibraryPath]     = React.useState(UserLibraryCollectionLocation.path);

    // Messages
    const [messageSuccess,  setMessageSuccess]  = useStateEphemeral(undefined);
    const [messageError,    setMessageError]    = useStateEphemeral(undefined,5000);
    const [horoscopeData,   setHoroscopeData]   = React.useState({});

    // Get the data from the server
    const getUserLibrary = React.useCallback( () => new Promise((resolve,reject) => {
        setLoading(true);
        setLoadingPublic(true);
        axios.get(BASE_API_URL, {cancelToken})
            .then(({data}) => {
                setMessageSuccess("Queried user library");
                return data
            })
            .then(resolve)
            .catch(err => {
                if(isCancel(err)) return reject(err);
                setMessageError(err?.message  || "Error retrieving user library");
                reject(err);
            })
            .finally(()=>{
                setQueried(moment())
                setLoading(false);
                setLoadingPublic(false);
            })
    }),[axios, cancelToken, setMessageSuccess, isCancel, setMessageError]);

    const getLibraryById = React.useCallback((id,origin=undefined) => new Promise((resolve,reject) => {
        if(id){
            setLoadingLibraries(true);
            axios.get(`${BASE_API_URL}/${id}?origin=${origin || 'libraryContext.getLibraryById'}`, {cancelToken})
                .then(({data}) => data)
                .then(resolve)
                .catch(err => {
                    if(isCancel(err)) return reject(err);
                    reject(err);
                })
                .finally(() => {
                    setLoadingLibraries(false);
                })
        }else{
            resolve({})
        }
    }),[axios, cancelToken, isCancel])

    const getHoroscopeDataById = React.useCallback( (id) => new Promise((resolve, reject) => {

        // Memoised
        if(horoscopeData[id])
            return resolve(horoscopeData[id]);
            
        // Query
        axios.get(`${BASE_API_URL}/${id}/horoscope`, {cancelToken})
            .then(({data}) => {
                setHoroscopeData(prev => ({...prev, [id]:data}))
                return data
            })
            .then(resolve)
            .catch(err => {
                if(isCancel(err)) return reject(err);
                reject(err);
            })
    }),[axios, cancelToken, horoscopeData, isCancel]);

    const postUpdatePrivacy = React.useCallback( ({id, value = false, commentsEnabled = false}) => new Promise((resolve, reject) => {
        setLoadingPublic(true);
        axios.post(`${BASE_API_URL}/${id}/public`, {value, commentsEnabled}, {cancelToken})
            .then(({data}) => data)
            .then(resolve)
            .catch(err => {
                if(isCancel(err)) return reject(err);
                reject(err);
            })
    }),[axios, cancelToken, isCancel]);

    // Reset Library
    const resetUserLibrary = React.useCallback(() => {
        setHoroscopeData({});
        setLibrary([]);
    },[]);

    // Get library record by id
    /*const getLibraryById = React.useCallback( async (id) => {
        return library.find(x => x._id === id) || undefined;
    },[library])
    */

    // Record View
    const postView = React.useCallback( (id) => new Promise((resolve, reject) => {
        axios.post(`${BASE_API_URL}/${id}/view`,{},{cancelToken})
            .then(({data}) => data)
            .then(resolve)
            .catch(err => {
                if(isCancel(err)) return reject(err);
                reject(err);
            })
    }),[axios, cancelToken, isCancel]);

    // Refresh the Orders
    const refresh = React.useCallback(() => {
        if(isNetworkReady && isAuthenticated && ready){
            getUserLibrary().then(setLibrary).catch(resetUserLibrary);
        }
        else
            resetUserLibrary();
    },[getUserLibrary, isAuthenticated, isNetworkReady, ready, resetUserLibrary])

    const refreshLibraryById = React.useCallback((libraryId) => {
        if(isNetworkReady && isAuthenticated && ready && libraryId){
            console.log("refreshLibraryById",libraryId);
            getLibraryById(libraryId,'libraryContet.refreshLibraryById')
                .then(upd => {
                    setLibrary(prev => prev.map(ex => upd.id && upd.id === ex.id ? upd : ex));
                })
            .catch(err => {
                //
            })
        }
    }, [getLibraryById, isAuthenticated, isNetworkReady, ready])

    // Query every X seconds
    React.useEffect(() => {
        refresh();
        const interval = setInterval(refresh, 15 * 60 * 1000); // 15 minutes
        return () => {
            clearInterval(interval);
        }
    },[refresh])

    // Refresh on SocketIO Instruction
    React.useEffect(() => {
        if(socket && isNetworkReady && isAuthenticated && ready){
            socket.on('refresh_libraries',      refresh)
            socket.on('refresh_library',        refreshLibraryById)
            return () => {
                socket.off('refresh_libraries', refresh);
                socket.off('refresh_library',   refreshLibraryById)
            }
        }
    },[refresh, isNetworkReady, socket, ready, isAuthenticated, refreshLibraryById])

    /*
    React.useEffect(()=>{
        return () => {
            setLoading(true);
        }
    },[])
    */

    // Update the LibraryId
    const setLibraryId = React.useCallback( (id) => {
        if(id){
            const isMine = Boolean(library.find(x => x._id === id));
            setLibraryIdRaw(isMine ? id : undefined);
        }else{
            setLibraryIdRaw(undefined);
        }
    },[library])

    const libraryPending    = React.useMemo(() => Array.isArray(library) ? library.filter(x => !Boolean(x?.available)) : [], [library]); 
    const libraryDelivered  = React.useMemo(() => Array.isArray(library) ? library.filter(x =>  Boolean(x?.available)) : [], [library]);
    const libraryPrivate    = React.useMemo(() => libraryDelivered.filter(x => !Boolean(x?.public)), [libraryDelivered]);
    const libraryPublic     = React.useMemo(() => libraryDelivered.filter(x =>  Boolean(x?.public)), [libraryDelivered]);

    const qtyPending        = React.useMemo(() => libraryPending.length,    [libraryPending]);
    const qtyDelivered      = React.useMemo(() => libraryDelivered.length,  [libraryDelivered]);
    const qtyPrivate        = React.useMemo(() => libraryPrivate.length,    [libraryPrivate]);
    const qtyPublic         = React.useMemo(() => libraryPublic.length,     [libraryPublic]);

    const hasPending        = React.useMemo(() => Boolean(qtyPending),      [qtyPending]);
    const hasDelivered      = React.useMemo(() => Boolean(qtyDelivered),    [qtyDelivered]);
    const hasPrivate        = React.useMemo(() => Boolean(qtyPrivate),      [qtyPrivate]);
    const hasPublic         = React.useMemo(() => Boolean(qtyPublic),       [qtyPublic]);

    const hasLibraries      = React.useMemo(() => Boolean(hasPending || hasDelivered), [hasDelivered, hasPending]);

    React.useEffect(()=>{
        const hasLibs = Array.isArray(library) && library.length > 0;
        let target = UserLibraryCollectionLocation.path;
        if(hasLibs){
            const id = libraryId; // || ((hasPublic || hasPrivate) ? library.filter(x => x.available)[0]?.id : library[0]?.id)
            if(id) target += `?id=${id}` 
        }
        setLibraryPath(target);
    },[libraryId, library, hasPublic, hasPrivate])

    // Context values
    const value = {
        queried,
        refresh, // : debounce(refresh),
        loading,
        loadingPublic,
        loadingLibraries,
        library,
        libraryPending,
        libraryDelivered,
        libraryPrivate,
        libraryPublic,
        libraryPath,
        libraryId,
        setLibraryId,
        horoscopeData,

        qtyPending,
        hasPending,
        qtyDelivered,
        hasDelivered,
        qtyPrivate,
        hasPrivate,
        qtyPublic,
        hasPublic,
        hasLibraries,

        getHoroscopeDataById,
        getLibraryById,
        postUpdatePrivacy,
        postView,
        refreshLibraryById,

        messageSuccess,
        setMessageSuccess,
        messageError,
        setMessageError,
    };

    return (
        <LibraryContext.Provider value={value}>
            {children}
        </LibraryContext.Provider>
    )
}

// Library Consumer
const LibraryConsumer = ({children}) => {
    return (
        <LibraryContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('LibraryConsumer must be used within LibraryProvider');
                }
                return children(context)
            }}
        </LibraryContext.Consumer>
    )
}

// useLibrary Hook
const useLibrary = () => {
    const context = React.useContext(LibraryContext);
    if(context === undefined)
        throw new Error('useLibrary must be used within LibraryProvider');
    return context;
}

export {
    LibraryProvider,
    LibraryConsumer,
    useLibrary
}