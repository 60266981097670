
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Popover
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       7th May 2021

*******************************************************************************************/
import React                                from 'react';
import { Box, Typography }                  from '@mui/material';

import {
    useCart,
    useNetwork
}                                           from 'contexts';
import DialogActions                        from '@mui/material/DialogActions';
import {
    ViewCartCheckoutButton, 
    CartCondensedTable,
    RemoveWithConfirmationButton,
    RootContainer
}                                           from 'components';
import { withTranslation }                  from './hoc';
import { useLocation }                      from "react-router-dom";
import { 
    UserCartLocation,
    UserCheckoutLocation 
}                                           from 'router/locations';

const ClearCartButton = withTranslation( ({t, children, ...props}) => {
    const { clearCart, isEmpty }    = useCart();
    const { isNetworkReady }        = useNetwork();
    const handleClearCart           = React.useCallback( (e) => {
        e.preventDefault();
        e.stopPropagation();
        clearCart();
    },[clearCart]);
    return (
        <RemoveWithConfirmationButton disabled={isEmpty || !isNetworkReady} variant="contained" onClick={handleClearCart} {...props}>
            {children || t('common.clearCart')}
        </RemoveWithConfirmationButton>
    )
});

const ViewCartButton = withTranslation( ({t, children, ...props}) => {
    const { isEmpty } = useCart();
    return (
        <ViewCartCheckoutButton disabled={isEmpty} variant="contained"{...props} sx={{...props?.sx,ml:2}}>
            {children || t('common.viewCart')}
        </ViewCartCheckoutButton>
    )
});

export const CartContents = withTranslation( ({t, readOnly = false}) => {
    const {
        cart : items,
        quantity,
        isEmpty,
    }                       = useCart();
    const location          = useLocation();
    
    // Viewing Cart
    const isViewingCart     = React.useMemo(() => (
        [UserCartLocation.path].includes(location.pathname)
    ), [location.pathname]);

    // Viewing Checkout
    const isViewingCheckout = React.useMemo(() => (
        [UserCheckoutLocation.path].includes(location.pathname)
    ), [location.pathname]);

    return (
        <RootContainer>
            <Box sx={{py:2, color : theme => theme.palette.text.primary}}>
                {
                    isEmpty && 
                    <Typography variant="body2" align="center">
                        { t('components.cartContents.cartEmpty') } 
                    </Typography>
                }
                {
                    !isEmpty && 
                    <React.Fragment>
                        <Typography variant="body2" align="center">
                            { t('components.cartContents.productsItemsInCart', {productsQuantity : items.length, itemsQuantity : quantity}) }
                        </Typography>
                        <CartCondensedTable readOnly={readOnly || isViewingCart || isViewingCheckout} showTax={true}/>
                    </React.Fragment>
                }
            </Box>

            {
                !isViewingCart /*&& !isViewingCheckout*/ && !isEmpty &&
                <DialogActions onClick={e => e.stopPropagation()} >
                    <ClearCartButton/>
                    <ViewCartButton/>
                </DialogActions>
            }
            
        </RootContainer>
    )
});

export default CartContents;