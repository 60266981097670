
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Main Alert
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       9th July 2021

*******************************************************************************************/
import React                    from 'react';
import { 
    styled, 
    Alert as AlertMUI,
    Box
}                               from '@mui/material';
import { useTranslation }       from 'contexts/TranslationContext';
import {
    CancelIconButton
}                               from 'components/iconButtons'

const Alert = styled(AlertMUI)({
    borderRadius : 0
})

// Full-Width Alert box with Resend Button
export const MainAlert = React.forwardRef( ({dismissable = false, ...props}, ref) => {
    
    const [t]               = useTranslation();
    const [open, setOpen]   = React.useState(true);
    
    if(!open)
        return null;

    return (
        <Box ref={ref} width="100%">
            <Alert 
                {...props}
                action = {
                    dismissable 
                        ? <>{props?.action} <CancelIconButton size="small" onClick={() => setOpen(false)} TooltipProps={{title:t('common.dismiss')}}/> </>
                        : props?.action
                } 
            />
        </Box>
    )
})

export default MainAlert;