/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Payment Context
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       13th May 2021

*******************************************************************************************/
import React                    from 'react';
import { useStripe }            from '@stripe/react-stripe-js';
import { usePaymentMethod }     from './PaymentMethodContext';
import { useNetwork }           from './NetworkContext';
import { useCancelToken }       from 'hooks';

// The Context Object
export const PaymentContext = React.createContext(undefined);

const URL_BASE_STRIPE = '/api/user/payment/charge/stripe';

// Voltage Provider
export const PaymentProvider = ({children}) => {

    const stripe                    = useStripe(); 
    const {axios}                   = useNetwork();
    const {cancelToken, isCancel}   = useCancelToken();
    const {paymentMethods}          = usePaymentMethod();
    const [message, setMessage]     = React.useState(undefined);

    const isValidPaymentMethodId    = React.useCallback( (paymentMethodId) => {
        const searchResult = paymentMethods
            .find(pm => pm._id === paymentMethodId)
        return Boolean(searchResult);
    },[paymentMethods]);


    const chargeStripeConfirm = React.useCallback( ({ paymentIntentId = undefined, paymentMethodId = undefined }) => new Promise((resolve, reject) => {
        
        if(!paymentMethodId) 
            return reject(new Error("Invalid of Missing Payment Method ID"));
        if(!paymentIntentId) 
            return reject(new Error("Invalid of Missing Payment Intent ID"));
        if(!isValidPaymentMethodId(paymentMethodId)) 
            return reject(new Error('Payment id not owned by current user'));

        axios.post(`${URL_BASE_STRIPE}/confirm` , { paymentMethodId, paymentIntentId}, {cancelToken})
            .then(resolve)
            .catch(err => {
                if (isCancel(err)) return reject(err);
                reject(err);
            })

    }),[axios, cancelToken, isCancel, isValidPaymentMethodId])

    
    const chargeStripeAttempt = React.useCallback( ({ invoiceId = undefined, paymentMethodId = undefined, currency = undefined, amount = 0.0, orderId = undefined, isTip = false}) => new Promise((resolve,reject) => {

        if(!paymentMethodId) 
            return reject(new Error("Invalid of Missing Payment Method ID"));
        if(!isValidPaymentMethodId(paymentMethodId)) 
            return reject(new Error('Payment Method ID not owned by current user'));

        axios.post(`${URL_BASE_STRIPE}/attempt`, { invoiceId, paymentMethodId, amount, currency, orderId, isTip}, {cancelToken})
            .then(resolve)
            .catch(err => {
                if (isCancel(err)) return reject(err);
                reject(err);
            })
        
    }),[axios, cancelToken, isCancel, isValidPaymentMethodId])


    /**
     * CHARGE STRIPE
     */
    const chargeStripe = React.useCallback( ({invoiceId, paymentMethodId, amount, currency, orderId = undefined, isTip = false}) => new Promise( (resolve,reject) => {

        chargeStripeAttempt({ invoiceId, paymentMethodId, amount, currency, orderId, isTip})
            .then(({data}) => new Promise((resolve,reject)=>{
                let {clientSecret, success, requiresAction} = data;
                if(requiresAction){
                    setMessage("Challenge Required");
                    stripe.handleCardAction(clientSecret)
                        .then(({error, paymentIntent })=>{
                            const success = !Boolean(error);
                            resolve({success, requiresAction, error, paymentIntent})
                        })
                        .catch(reject)
                }else{
                    resolve({success, requiresAction:false })
                }
            }))
            .then(result => new Promise((resolve,reject) => {
                let {success, requiresAction} = result;
                if(requiresAction){
                    if(success){
                        setMessage("Challenge Successful, Charging");
                        let {paymentIntent : {id : paymentIntentId}} = result;
                        chargeStripeConfirm({paymentMethodId, paymentIntentId})
                            .then(({data}) =>{
                                resolve({success:data.success});
                            })  
                            .catch(reject)
                    }else{
                        setMessage("Challenge Failed");
                        resolve({success:false, error : {message:'Challenge Failed'}});
                    }
                }else{
                    resolve(result);
                }
            }))
            .then(result => {
                if(!result.success)
                    return reject(new Error(result?.error?.message || "Payment Failed"));
                setMessage("Charged");
                resolve();
            })
            .catch(reject)
            .finally(()=>{
                setMessage(undefined);
            })

    }),[chargeStripeAttempt, chargeStripeConfirm, stripe])

    // Context values
    const value = {
        chargeStripe,
        message
    };

    return (
        <PaymentContext.Provider value={value}>
            {children}
        </PaymentContext.Provider>
    )
}

export const PaymentConsumer =  ({children}) => {
    return (
        <PaymentContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('PaymentConsumer must be used within PaymentProvider');
                }
                return children(context)
            }}
        </PaymentContext.Consumer>
    )
}

// useVoltage Hook
export const usePayment = () => {
    const context = React.useContext(PaymentContext);
    if(context === undefined)
        throw new Error('usePayment must be used within PaymentProvider');
    return context;
}