/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Stripe Text Fields w/ Material UI
********************************************************************************************
Based loosely off: 
  https://github.com/mui-org/material-ui/issues/16037
  https://codesandbox.io/s/stripe-0xez4?file=/src/App.tsx:252-330

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       28th December 2020

*******************************************************************************************/

import React        from "react";
import {
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
}                   from '@stripe/react-stripe-js';
import {TextField}  from '@mui/material';
import StripeInput  from "./StripeInput";

/*
const StripeTextFieldOld = ({
    InputLabelProps,
    labelErrorMessage,
    stripeElement,
    InputProps = {},
    inputProps,
    ...other
}) => (
  <TextField
      fullWidth
      InputLabelProps = {{
          ...InputLabelProps,
          shrink: true
      }}
      InputProps = {{          
          ...InputProps,
          inputProps: {
            ...inputProps,
            ...InputProps.inputProps,
            component: stripeElement
          },
          inputComponent: StripeInput
      }}
      {...other}
  />
);
*/

export const StripeTextField = ({
  disabled,
  helperText,
  InputLabelProps,
  InputProps = {},
  inputProps,
  error,
  labelErrorMessage,
  stripeElement,
  ...other
}) => {

  return (
    <TextField
      fullWidth
      disabled = {disabled}
      InputLabelProps={{
        ...InputLabelProps,
        shrink: true
      }}
      error={error}
      InputProps={{
        ...InputProps,
        inputProps: {
          ...inputProps,
          ...InputProps.inputProps,
          component: stripeElement,
          disabled,
        },
        inputComponent: StripeInput
      }}
      helperText={error ? labelErrorMessage : helperText}
      {...other}
    />
  );
};

export const StripeTextFieldNumber = (props) => {
  return (
    <StripeTextField
      label         = "Credit Card Number"
      stripeElement = {CardNumberElement}
      {...props}
    />
  );
}

export const StripeTextFieldExpiry = (props) => {
  return (
    <StripeTextField
      label         = "Expiry"
      stripeElement = {CardExpiryElement}
      {...props}
    />
  );
}

export const StripeTextFieldCVC = (props) => {
  return (
    <StripeTextField
      label         = "CVC Code"
      stripeElement = {CardCvcElement}
      {...props}
    />
  );
}
