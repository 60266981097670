/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Glyphs
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       2nd February 2021

*******************************************************************************************/
// Angles
import acLight              from 'resources/glyphs/Asc_White.png';
import acDark               from 'resources/glyphs/Asc_Black.png';
import mcLight              from 'resources/glyphs/Mc_White.png';
import mcDark               from 'resources/glyphs/Mc_Black.png';
// Bodies
import sunDark              from 'resources/glyphs/SunIcon_Black.png';
import sunLight             from 'resources/glyphs/SunIcon_White.png';
import moonDark             from 'resources/glyphs/Moon18_Black.png';
import moonLight            from 'resources/glyphs/Moon18_White.png';
import mercuryDark          from 'resources/glyphs/Astro_Mercury_Black.png';
import mercuryLight         from 'resources/glyphs/Astro_Mercury_White.png';
import venusDark            from 'resources/glyphs/Astro_Venus_Black.png';
import venusLight           from 'resources/glyphs/Astro_Venus_White.png';
import marsDark             from 'resources/glyphs/Astro_Mars_Black.png';
import marsLight            from 'resources/glyphs/Astro_Mars_White.png';
import jupiterDark          from 'resources/glyphs/Astro_Jupiter_Black.png';
import jupiterLight         from 'resources/glyphs/Astro_Jupiter_White.png';
import saturnDark           from 'resources/glyphs/Astro_Saturn_Black.png';
import saturnLight          from 'resources/glyphs/Astro_Saturn_White.png';
import uranusDark           from 'resources/glyphs/Astro_Uranus_Black.png';
import uranusLight          from 'resources/glyphs/Astro_Uranus_White.png';
import neptuneDark          from 'resources/glyphs/Astro_Neptune_Black.png';
import neptuneLight         from 'resources/glyphs/Astro_Neptune_White.png';
import plutoDark            from 'resources/glyphs/Astro_Pluto_Black.png';
import plutoLight           from 'resources/glyphs/Astro_Pluto_White.png';
import chironDark           from 'resources/glyphs/Astro_Chiron_Black.png';
import chironLight          from 'resources/glyphs/Astro_Chiron_White.png';
// Points
import lilithLight          from 'resources/glyphs/Lilith_White.png';
import lilithDark           from 'resources/glyphs/Lilith_Black.png';
import southnodeDark        from 'resources/glyphs/Astro_Southnode_Black.png';
import southnodeLight       from 'resources/glyphs/Astro_Southnode_White.png';
import northnodeDark        from 'resources/glyphs/Astro_NorthNode_Black.png';
import northnodeLight       from 'resources/glyphs/Astro_NorthNode_White.png';
// Signs
import aquariusLight        from 'resources/glyphs/Astro_Aquarius_White.png';
import aquariusDark         from 'resources/glyphs/Astro_Aquarius_Black.png';
import piscesLight          from 'resources/glyphs/Astro_Pisces_White.png';
import piscesDark           from 'resources/glyphs/Astro_Pisces_Black.png';
import ariesLight           from 'resources/glyphs/Astro_Aries_White.png';
import ariesDark            from 'resources/glyphs/Astro_Aries_Black.png';
import taurusLight          from 'resources/glyphs/Astro_Taurus_White.png';
import taurusDark           from 'resources/glyphs/Astro_Taurus_Black.png';
import geminiLight          from 'resources/glyphs/Astro_Gemini_White.png';
import geminiDark           from 'resources/glyphs/Astro_Gemini_Black.png';
import cancerLight          from 'resources/glyphs/Astro_Cancer_White.png';
import cancerDark           from 'resources/glyphs/Astro_Cancer_Black.png';
import leoLight             from 'resources/glyphs/Astro_Leo_White.png';
import leoDark              from 'resources/glyphs/Astro_Leo_Black.png';
import virgoLight           from 'resources/glyphs/Astro_Virgo_White.png';
import virgoDark            from 'resources/glyphs/Astro_Virgo_Black.png';
import libraLight           from 'resources/glyphs/Astro_Libra_White.png';
import libraDark            from 'resources/glyphs/Astro_Libra_Black.png';
import scorpioLight         from 'resources/glyphs/Astro_Scorpio_White.png';
import scorpioDark          from 'resources/glyphs/Astro_Scorpio_Black.png';
import sagittariusLight     from 'resources/glyphs/Astro_Sagittarius_White.png';
import sagittariusDark      from 'resources/glyphs/Astro_Sagittarius_Black.png';
import capricornLight       from 'resources/glyphs/Astro_Capricorn_White.png';
import capricornDark        from 'resources/glyphs/Astro_Capricorn_Black.png';
// Houses
import h1Light              from 'resources/glyphs/I_Old_White.png';
import h1Dark               from 'resources/glyphs/I_Old_Black.png';
import h2Light              from 'resources/glyphs/II_Old_White.png';
import h2Dark               from 'resources/glyphs/II_Old_Black.png';
import h3Light              from 'resources/glyphs/III_Old_White.png';
import h3Dark               from 'resources/glyphs/III_Old_Black.png';
import h4Light              from 'resources/glyphs/IV_Old_White.png';
import h4Dark               from 'resources/glyphs/IV_Old_Black.png';
import h5Light              from 'resources/glyphs/V_Old_White.png';
import h5Dark               from 'resources/glyphs/V_Old_Black.png';
import h6Light              from 'resources/glyphs/VI_Old_White.png';
import h6Dark               from 'resources/glyphs/VI_Old_Black.png';
import h7Light              from 'resources/glyphs/VII_Old_White.png';
import h7Dark               from 'resources/glyphs/VII_Old_Black.png';
import h8Light              from 'resources/glyphs/VIII_Old_White.png';
import h8Dark               from 'resources/glyphs/VIII_Old_Black.png';
import h9Light              from 'resources/glyphs/IX_Old_White.png';
import h9Dark               from 'resources/glyphs/IX_Old_Black.png';
import h10Light             from 'resources/glyphs/X_Old_White.png';
import h10Dark              from 'resources/glyphs/X_Old_Black.png';
import h11Light             from 'resources/glyphs/XI_Old_White.png';
import h11Dark              from 'resources/glyphs/XI_Old_Black.png';
import h12Light             from 'resources/glyphs/XII_Old_White.png';
import h12Dark              from 'resources/glyphs/XII_Old_Black.png';
// Aspects
import conjunctionLight     from 'resources/glyphs/Astro_Conjunction_White.png';
import conjunctionDark      from 'resources/glyphs/Astro_Conjunction_Black.png';
import oppositionLight      from 'resources/glyphs/Astro_Opposition_White.png';
import oppositionDark       from 'resources/glyphs/Astro_Opposition_Black.png';
import squareLight          from 'resources/glyphs/Astro_Square_White.png';
import squareDark           from 'resources/glyphs/Astro_Square_Black.png';
import trineLight           from 'resources/glyphs/Astro_Trine_White.png';
import trineDark            from 'resources/glyphs/Astro_Trine_Black.png';
import sextileLight         from 'resources/glyphs/Astro_Sextile_White.png';
import sextileDark          from 'resources/glyphs/Astro_Sextile_Black.png';

export const glyphs = {
    light : {
        // Angles
        ascendant   : acLight,
        midheaven   : mcLight,
        // Bodies
        sun         : sunLight,
        moon        : moonLight,
        mercury     : mercuryLight,
        venus       : venusLight,
        mars        : marsLight,
        jupiter     : jupiterLight,
        saturn      : saturnLight,
        uranus      : uranusLight,
        neptune     : neptuneLight,
        pluto       : plutoLight,
        chiron      : chironLight,
        // Points
        northnode   : northnodeLight,
        southnode   : southnodeLight,
        lilith      : lilithLight,
        // Signs
        aquarius    : aquariusLight,
        pisces      : piscesLight,
        aries       : ariesLight,
        taurus      : taurusLight,
        gemini      : geminiLight,
        cancer      : cancerLight,
        leo         : leoLight,
        virgo       : virgoLight,
        libra       : libraLight,
        scorpio     : scorpioLight,
        sagittarius : sagittariusLight,
        capricorn   : capricornLight,
        // Houses
        h1          : h1Light,
        h2          : h2Light,
        h3          : h3Light,
        h4          : h4Light,
        h5          : h5Light,
        h6          : h6Light,
        h7          : h7Light,
        h8          : h8Light,
        h9          : h9Light,
        h10         : h10Light,
        h11         : h11Light,
        h12         : h12Light,
        // Aspects
        conjunction : conjunctionLight,
        opposition  : oppositionLight,
        square      : squareLight,
        trine       : trineLight,
        sextile     : sextileLight
    },
    dark : {
        // Angles
        ascendant   : acDark,
        midheaven   : mcDark,
        // Bodies
        sun         : sunDark,
        moon        : moonDark,
        mercury     : mercuryDark,
        venus       : venusDark,
        mars        : marsDark,
        jupiter     : jupiterDark,
        saturn      : saturnDark,
        uranus      : uranusDark,
        neptune     : neptuneDark,
        pluto       : plutoDark,
        chiron      : chironDark,
        // Points
        northnode   : northnodeDark,
        southnode   : southnodeDark,
        lilith      : lilithDark,
        // Signs
        aquarius    : aquariusDark,
        pisces      : piscesDark,
        aries       : ariesDark,
        taurus      : taurusDark,
        gemini      : geminiDark,
        cancer      : cancerDark,
        leo         : leoDark,
        virgo       : virgoDark,
        libra       : libraDark,
        scorpio     : scorpioDark,
        sagittarius : sagittariusDark,
        capricorn   : capricornDark,
        // Houses
        h1          : h1Dark,
        h2          : h2Dark,
        h3          : h3Dark,
        h4          : h4Dark,
        h5          : h5Dark,
        h6          : h6Dark,
        h7          : h7Dark,
        h8          : h8Dark,
        h9          : h9Dark,
        h10         : h10Dark,
        h11         : h11Dark,
        h12         : h12Dark,
        // Aspects
        conjunction : conjunctionDark,
        opposition  : oppositionDark,
        square      : squareDark,
        trine       : trineDark,
        sextile     : sextileDark
    }
}

export default glyphs;