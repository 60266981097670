
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
EmailAdmin Context
********************************************************************************************
Boilerplate context, consumer, provider and hook

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       13th Jun 2022

*******************************************************************************************/
import React from 'react';

// The Product Context 
const EmailAdminContext = React.createContext(undefined);

// EmailAdmin Provider
const EmailAdminProvider = ({children}) => {

    const [design,  setDesign]  = React.useState(undefined);
    const [html,    setHtml]    = React.useState(undefined);

    // Context values
    const value = React.useMemo(() => ({
        design,
        setDesign,
        html,
        setHtml
    }), [design, html]);

    return (
        <EmailAdminContext.Provider value={value}>
            {children}
        </EmailAdminContext.Provider>
    )
}

// EmailAdmin Consumer
const EmailAdminConsumer = ({children}) => {
    return (
        <EmailAdminContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('EmailAdminConsumer must be used within EmailAdminProvider');
                }
                return children(context)
            }}
        </EmailAdminContext.Consumer>
    )
}

// useEmailAdmin Hook
const useEmailAdmin = () => {
    const context = React.useContext(EmailAdminContext);
    if(context === undefined)
        throw new Error('useEmailAdmin must be used within EmailAdminProvider');
    return context;
}

export {
    EmailAdminProvider,
    EmailAdminConsumer,
    useEmailAdmin
}