
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
PageHeight Context
********************************************************************************************
Boilerplate context, consumer, provider and hook

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       10th April 2022

*******************************************************************************************/
import React from 'react';

// The Product Context 
const PageHeightContext = React.createContext(undefined);

// PageHeight Provider
const PageHeightProvider = ({children}) => {

    const [height, setHeight] = React.useState('100vh');

    // Context values
    const value = {
        height,
        heightInner : `calc(${height} - ${44 + 16}px - 75px)`,
        setHeight
    };

    return (
        <PageHeightContext.Provider value={value}>
            {children}
        </PageHeightContext.Provider>
    )
}

// PageHeight Consumer
const PageHeightConsumer = ({children}) => {
    return (
        <PageHeightContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('PageHeightConsumer must be used within PageHeightProvider');
                }
                return children(context)
            }}
        </PageHeightContext.Consumer>
    )
}

// usePageHeight Hook
const usePageHeight = () => {
    const context = React.useContext(PageHeightContext);
    if(context === undefined)
        throw new Error('usePageHeight must be used within PageHeightProvider');
    return context;
}

export {
    PageHeightProvider,
    PageHeightConsumer,
    usePageHeight
}