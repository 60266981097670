/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
LibrarySet Component
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       16th October 2021

*******************************************************************************************/
import React                            from 'react';
import { 
    styled, useMediaQuery, //lighten, darken, 
    useTheme, AppBar, Paper, Box, Grid, 
    Typography 
}                                       from '@mui/material';
import {
    Title,
    ProductCrossSell,
    ItemPaper,
    TabPanel,
    Tab,
    Tabs,
    ObjectId,
}                                       from 'components';
import {
    SkeletonFancyNoLibrariesSelected
}                                       from 'components/skeletons';
import SkeletonTabs                     from 'components/skeletons/SkeletonTabs';
import SkeletonPerson                   from 'components/skeletons/SkeletonPerson';
import {
    SkeletonRadixNoLibrariesSelected
}                                       from 'components/skeletons';
import LibraryRadix                     from './LibraryRadix';
import LibraryDetails                   from './LibraryDetails';
import LibraryPlaylist                  from './LibraryPlaylist';
import LibraryReview                    from './LibraryReview';
import LibraryTickets                   from './LibraryTickets';
import LibraryAvatar                    from './LibraryAvatar';
import LibraryViewOrderButton           from './LibraryViewOrderButton';
import LibraryComments                  from './LibraryComments';
import {
    FileUploader
}                                       from 'components/modals';
import { 
    useLibrary,
    useLibraryViewer,
    useUser,
    useNetwork,
    CommentsThreadProvider,
    useTranslation
}                                       from 'contexts';
import NotFound                         from 'components/errors/NotFound';
import { withTranslation }              from 'components/hoc';
// import {useSize}                        from 'hooks';

const withContainer = Component => styled(Component)(({theme}) => ({
    "& > * + *" : {
        marginTop : theme.spacing(2)
    }
}))

const BoxContainer              = withContainer(Box);
const GridContainer             = withContainer(Grid);
const GridContainerPaperRatio   = styled(GridContainer)(({theme}) => ({
    minHeight   : 0,
    aspectRatio : '1.41'
}));

const ContentBlock = styled(Box)(({theme}) => ({
    height          : '100%', 
    overflow        : 'hidden',
    [theme.breakpoints.down('lg')] : {
        minHeight : 400,
    }
}));

const TitleContainer = styled(Box)(({theme}) => ({
    display : 'inline-flex',
    '& > * + *' : {
        marginLeft : theme.spacing(1)
    }
}));

const LibraryDetailsContainer = styled(Box)(({theme}) => ({
    marginTop : theme.spacing(1),
    overflow : 'hidden',
    [theme.breakpoints.between('sm','md')] : {
        overflow    : 'scroll',
        maxHeight   : 300
    }
}));

const GridRoot = styled(Grid)(({theme}) => ({
    position    : 'relative',
    height      : '100%',
    background  : 'transparent'
}));

const LibraryParticulars = React.forwardRef( ({component : Component = Box, componentProps = {}, showTitle = true, force = false, sensitive = true,...props}, ref) => {
    const {t}                               = useTranslation();
    const theme                             = useTheme();
    const {isAuthenticated, ready}          = useUser();
    const {isNetworkReady }                 = useNetwork();
    const {refreshLibraryById}              = useLibrary();
    const { 
        data, 
        setData, 
        loading, 
        libraryId,
        isOwner,
        updateLibrary : update
    }                                       = useLibraryViewer();

    const [uploading,       setUploading]   = React.useState(false);
    const [openUploader,    setOpenUploader]= React.useState(false);

    const views                             = React.useMemo(() => (data?.views || 0) + 1, [data?.views]); // increment to account for this view
    const showRadix                         = React.useMemo(() => data?.available || force, [data?.available, force]);
    const isPageLoading                     = React.useMemo(() => loading || !ready || !isNetworkReady, [isNetworkReady, loading, ready]);

    const updateLibrary = React.useCallback( (data) => new Promise((resolve,reject) => {
        update(data)
            .then(data => {
                refreshLibraryById(libraryId);
                setData(data);
                resolve({});
            })
            .catch(reject)
    }), [libraryId, refreshLibraryById, setData, update]);

    const handleOpenPhotoUploader   = React.useCallback(() => setOpenUploader(true), []);
    const handleClosePhotoUploader  = React.useCallback(() => setOpenUploader(false), []);
    const handlePhotoUploaderSubmit = React.useCallback( (files, allFiles) => new Promise((resolve,reject) => {
        let uploads = files.map(f => f?.meta?.fileUrl).filter(Boolean);
        setUploading(true);
        updateLibrary({photo:uploads[0]})
            .then(() => {
                setOpenUploader(false);
                resolve({})
            })
            .catch(reject)
            .finally(()=> {
                setUploading(false)
            })
    }),[updateLibrary]);

    const showVomponent = React.useMemo(() => (
        !isPageLoading && isNetworkReady && isAuthenticated && libraryId
    ), [isAuthenticated, isNetworkReady, isPageLoading, libraryId])

    return (
        <Box id="libraryParticulars" ref={ref} display="flex" flexDirection="column" style={{height:'100%'}}>

            {
                showVomponent &&
                <Component {...componentProps} style={{height:'100%', width:'100%', position:'relative', ...componentProps.style}}>
                    <Grid container spacing={0} justifyContent= "center" alignItems="stretch" style={{position:'sticky', top:theme.spacing(2), zIndex:21}}>
                        
                        <Grid item xs={12} style={{borderBottom : `1px solid ${theme.palette.divider}`,marginBottom:theme.spacing(1)}}>

                            <Box display="flex" width="100%">
                                <Box flexShrink={1} pr={1} pt={0.5}>
                                    <LibraryAvatar 
                                        src     = {data?.photoGetSignedUrl} 
                                        style   = {{cursor: isOwner ? 'pointer' : 'default',position:'relative',top:theme.spacing(-0.5)}} 
                                        onClick = {isOwner ? handleOpenPhotoUploader : undefined}
                                    />
                                </Box>
                                <Box flexGrow={1}>
                                    {
                                        showTitle &&
                                        <TitleContainer>
                                            <Title variant="h5" paragraph={libraryId ? false : !true}>
                                                { t('components.library.librarySet.libraryParticulars.userLibraryRecord') }
                                            </Title>
                                            <LibraryViewOrderButton />
                                        </TitleContainer>
                                    }    
                                    {
                                        ready && !loading &&
                                        <Typography gutterBottom variant="body2">
                                            ID: <ObjectId value={libraryId}/> {Boolean(views) && <>({views} views)</>}
                                        </Typography>
                                    }
                                    {
                                        (!ready || loading) &&
                                        <Typography gutterBottom variant="body2">
                                            {t('common.loading')}
                                        </Typography>
                                    }
                                </Box>
                            </Box>
                        </Grid>    

                        { 
                            (!true || showRadix) && 
                            <Grid item xs={12} sm={6} md={12} >
                                <Paper id="radixcontainer">
                                    <LibraryRadix showDescriptor={true}/>
                                </Paper>
                            </Grid>
                        }

                        <Grid item {...(showRadix ? {xs:12,sm:6,md:12} : {xs:12})}>
                            <LibraryDetailsContainer>
                                <LibraryDetails sensitive={sensitive}/>
                            </LibraryDetailsContainer>
                        </Grid>
                    </Grid>
                </Component>
            }

            {   
                !showVomponent && 
                <ContentBlock id="libraryParticularsLoading" display="flex" flexDirection="column">
                    <Box>               
                        <SkeletonPerson />
                    </Box>
                    <Box>
                        <SkeletonRadixNoLibrariesSelected/>
                    </Box>
                    <Box>
                        <SkeletonTabs quantity={2}/>
                    </Box>
                    <Box id="nolibs" flexGrow={1} mt={0.5} style={{minHeight:600}} position="relative">
                        <SkeletonFancyNoLibrariesSelected showCircle={false}/>
                    </Box>
                </ContentBlock>
            }

            <FileUploader 
                disabled    = {uploading}
                title       = { t('components.library.librarySet.libraryParticulars.changePhoto') }
                open        = {openUploader} 
                multiple    = {false}
                onClose     = {handleClosePhotoUploader} 
                onSubmit    = {handlePhotoUploaderSubmit}
            />
        </Box>
    );
});

function tabProps(index) {
    return {
        id: `tab-${index}`,
    };
}

const DEFAULT_SHOW_CROSS_SELL = !false;

const PromotionalSkeletons = () => (
    <React.Fragment>
        <GridContainerPaperRatio item xs={12}>
            <SkeletonFancyNoLibrariesSelected showCircle={false} />
        </GridContainerPaperRatio>
        {
            Array(3).fill(undefined).map((_,ix) => (
            <GridContainerPaperRatio key={ix} item xs={4}>
                <SkeletonFancyNoLibrariesSelected showCircle={false} />
            </GridContainerPaperRatio>
        ))}
    </React.Fragment>
)

const LibraryContent = withTranslation( ({t, sensitive = false, force=false, showCrossSell = DEFAULT_SHOW_CROSS_SELL}) => {
    const {isAdmin, isAuthenticated, ready}     = useUser();
    const {
        loading, 
        libraryId,
        isOwner,
        isAvailable,
    }                                           = useLibraryViewer();
    const {isNetworkReady }                     = useNetwork();
    const [value,   setValue]                   = React.useState(0);
    const [disabled, setDisabled]               = React.useState(false);
    const lgUp                                  = useMediaQuery(theme => theme.breakpoints.up('lg'));

    // Handle Tab Change
    const handleTabChange                       = React.useCallback( (event, newValue) => setValue(newValue), []);

    // The Various Sub-Components
    const components = React.useMemo(() => (
        [
            {
                Component   : LibraryComments,
                label       : t('components.library.librarySet.libraryContent.comments'),
                hash        : '#comments',
                visible     : isAvailable,
                args        : {
                    onDetach : setDisabled
                }
            },

            {
                Component   : LibraryReview,
                label       : t('components.library.librarySet.libraryContent.review'),
                hash        : '#review',
                visible     : isAvailable && (isOwner || isAdmin),
                args        : {}
            },

            {
                Component   : LibraryTickets,
                label       : t('components.library.librarySet.libraryContent.tickets'),
                hash        : '#tickets',
                visible     : isOwner,
                args        : {}
            }

        ].filter(data => data.visible)
    ),[t, isAdmin, isAvailable, isOwner]);

    const hasComponents         = React.useMemo(() => components.length > 0, [components.length]);
    const hasComponentsMulti    = React.useMemo(() => components.length > 1, [components.length]);

    const isPageLoading         = React.useMemo(() => (loading || !ready || !isNetworkReady), [isNetworkReady, loading, ready]);

    return (
        <>
            <ContentBlock id="librarySetPlayList">
                <LibraryPlaylist sensitive={sensitive} force={force}/>
            </ContentBlock >

            {
                !isPageLoading && hasComponents &&
                <BoxContainer id="librarySetComponents" sx={{mt:2}}>
                    {
                        hasComponentsMulti &&
                        <AppBar position="static" color="inherit" elevation={0}>
                            <Tabs value={value} onChange={handleTabChange} >
                                {
                                    components.map(({label},ix) => (
                                        <Tab 
                                            disabled    = { disabled } 
                                            key         = { ix } 
                                            label       = { label } 
                                            { ...tabProps(ix) } 
                                        />
                                    ))
                                }
                            </Tabs>
                        </AppBar>
                    }
                    
                    {
                        components.map(({Component,args},ix) => (
                            <TabPanel key={ix} value={value} index={ix}>
                                <Component {...args}/> 
                            </TabPanel>
                        ))
                    }

                </BoxContainer>
            }

            {   
                !isPageLoading && showCrossSell && isAuthenticated && libraryId && lgUp &&
                <ProductCrossSell quantity={2} omitInCart={false} GridProps = {{ xs:12, sm:12, md:6, lg:6 }} />
            }

            {   
                (isPageLoading || !libraryId) &&
                <Box width="100%" minHeight={300} display="flex" flexDirection={"column"} mt={0.5}>
                    <Box>
                        <SkeletonTabs />
                    </Box>
                    <Box mt={0.5} flexGrow={1} position="relative">
                        <SkeletonFancyNoLibrariesSelected showCircle={false} />
                    </Box>
                </Box>
            }
        </>
    )
})

// Library Collection Component
export const LibrarySet = React.forwardRef( ({sensitive = false, showTitle=true, force=false, showCrossSell = DEFAULT_SHOW_CROSS_SELL, ...props}, ref) => {
    const { t }                                 = useTranslation();
    const {ready}                               = useUser();
    const { postView }                          = useLibrary();
    const {
        data,
        error, 
        loading, 
        libraryId,
    }                                           = useLibraryViewer();
    const {isNetworkReady }                     = useNetwork();
    
    // Set libraryId, increment
    React.useEffect(() => {
        if(libraryId) 
            postView(libraryId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[libraryId])

    const isPageLoading = React.useMemo(() => (loading || !ready || !isNetworkReady), [isNetworkReady, loading, ready]);

    if(error || (data && data?.delivery && ![ "PROCESSING", "COMPLETE_PENDING", "COMPLETE" ].includes(data?.delivery?.status))){
        return (
            <Box p={2} height="100%">
                <NotFound>
                    { t('components.library.librarySet.error404') }
                </NotFound>
            </Box>
        )
    }
    
    // Render
    return (
        <CommentsThreadProvider>
            <Box 
                component={ItemPaper} 
                ref = {ref} 
                id  = {'librarySet'} 
            >

                <GridRoot 
                    container 
                    direction   = 'row' 
                    alignItems  = "stretch" 
                    spacing     = {2} 
                    sx          = {{ width : theme => `calc(100% + ${theme.spacing(2)})`}}
                >

                    {/* LHS */}
                    <Grid item xs={12} sm={12} md={5} lg={4} sx={{display: 'flex', flexGrow:1, flexDirection:'column', position:'relative'}}>
                        <LibraryParticulars showTitle={showTitle} force={force} sensitive={sensitive}/>
                    </Grid>

                    {/* RHS */}
                    <Grid item xs={12} sm={12} md={7} lg={8} sx={{display: 'flex', flexGrow:1, flexDirection:'column', position:'relative'}}>
                        <LibraryContent sensitive={sensitive} force={force} showCrossSell={showCrossSell} />
                    </Grid>


                    {/* Bottom Skeleton */}
                    { 
                        false && (isPageLoading || !libraryId) && 
                        <Grid item xs={12}>
                            <PromotionalSkeletons />
                        </Grid>
                    }
                </GridRoot>
            </Box>
        </CommentsThreadProvider>
    );
});

export default LibrarySet;