/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Popover
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       7th May 2021

*******************************************************************************************/
import React                                from 'react';
import { Box, IconButton }                  from '@mui/material';
import { Menu }                             from './Menu';
import { ShoppingCartTitle }                     from './Titles';
import { CartContents as Component}         from '../CartContents';
import { CartIconWithQuantity }             from 'components';
import { useUser, useCart }                 from 'contexts';

export const DropDownCart = () => {
    // Classes
    const {quantity}                = useCart();
    const {isAuthenticated}         = useUser();
    return (
        <Menu 
            showArrow   = {true} 
            render      = {({handleClick})=> (
                <IconButton
                    disabled    = {!isAuthenticated}
                    onClick     = {handleClick}
                    size        = "large"
                    sx          = {{
                        color : theme => theme.palette.mode ==='light' ? 'black' : 'white',
                    }}
                >
                    <CartIconWithQuantity quantity={quantity} iconProps={{sx:{fontSize:'2rem'}}} />
                </IconButton>
            )}
        >
            <ShoppingCartTitle />
            <Box width = {400}>
                <Component readOnly={false}/>
            </Box>
        </Menu>
    )
    
    // if(!isAuthenticated) return null;
    /*
    return (
        <ClickAwayListener onClickAway={handleClose}>
            <div className={classes.root}>
                <IconButton
                    disabled={!isAuthenticated}
                    className={classes.iconButton}
                    onClick = {handleOpenClose}
                    size="large">
                    <CartIconWithQuantity quantity={quantity}/>
                </IconButton>
                <Dropdown title={title} anchorEl={anchorEl} open={open} onClose={handleClose}>
                    <CartContents readOnly={false}/>
                </Dropdown>
            </div>
        </ClickAwayListener>
    );
    */
}

export default DropDownCart;