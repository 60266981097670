/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Star Ratings
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       13th February 2021

*******************************************************************************************/
import React                            from 'react';
import {isNil}                          from 'lodash';
import { 
    useTheme,
    alpha, 
    Box, 
    Typography 
}                                       from '@mui/material';
import { amber, grey}                   from '@mui/material/colors';
import StarIcon                         from '@mui/icons-material/Star';
import StarHalfIcon                     from '@mui/icons-material/StarHalf';
const noop  = () => {}

const sizes = {
    small : 20,
    medium : 25,
    large : 30,
    default : 30
}

//
export const StarRating = ({
    label, 
    disabled        = false,
    size            = 'default', 
    value : valueIn = 0, 
    steps           = 5, 
    onChange : handleChange = noop, 
    showValue       = true,
    showTips        = true,
    align           = "center",
    labelProps      = {},
    ...props
}) => {
    const theme                         = useTheme();
    const [value,       setValue]       = React.useState(valueIn);
    const [valueTemp,   setValueTemp]   = React.useState(undefined);
    React.useEffect(() => setValue(valueIn), [valueIn])
    return (
        <Box>
            {
                label &&
                <Box align={align}>
                    <Typography variant="body1" {...labelProps}>
                        {label}
                    </Typography>
                </Box>
            }
            <Box align={align} mt={0.5}>
                {
                    Array(steps).fill(1).map((v,ix) => {
                        const handleClick = () => {
                            if(disabled) return;
                            let proposed = ix + 1;
                            let newValue = proposed === valueTemp && proposed === value ? ix : proposed;
                            handleChange(newValue);
                            setValueTemp(newValue);
                        }
                        const handleDoubleClick = () => {
                            if(disabled) return;
                            let proposed = ix + 1;
                            let newValue = proposed === value ? 0 : proposed
                            handleChange(newValue); // Allow Toggle
                            setValueTemp(newValue);
                        }
                        const handleEnter   = () => {
                            if(disabled) return;
                            setValueTemp(ix + 1);
                        }
                        const handleLeave   = () => {
                            if(disabled) return;
                            setValueTemp(undefined);
                        }
                        const hasTempValue  = !isNil(valueTemp);
                        const isFilled      = ix < (hasTempValue ? valueTemp : value);
                        const isBad         = ix === 0;
                        const isGood        = ix === steps - 1;
                        const isHalf        = (ix === Math.floor(value)) && (Math.ceil(value) > ix);
                        const Icon          = isHalf ? StarHalfIcon : StarIcon
                        const fontSize      = isNaN(size) ? (sizes[size] || sizes.default) : size;

                        return ( 
                            <Box key={ix} style={{display:'inline-block', verticalAlign:'top', position:'relative', width:fontSize,height:fontSize + 0.25*fontSize}} onMouseEnter={handleEnter} onMouseLeave={handleLeave}>
                                <StarIcon
                                    htmlColor       = {isHalf ? 'lightgrey' : 'white'} 
                                    style           = {{
                                        zIndex      : 1,
                                        left        : 0,
                                        right       : 0,
                                        position    : 'absolute',
                                        fontSize    : fontSize,
                                        cursor      : disabled ? 'default' : 'pointer',
                                        transition  : 'color 0.25s ease',
                                        stroke      : grey[600],
                                        strokeWidth : '0.75px'
                                    }}
                                />
                                <Icon 
                                    htmlColor       = {isFilled ? (hasTempValue ? theme.palette.secondary.main : amber[400]) : 'lightgrey'} 
                                    style           = {{
                                        zIndex      : 2,
                                        left        : 0,
                                        right       : 0,
                                        position    : 'absolute',
                                        fontSize    : fontSize,
                                        cursor      : disabled ? 'default' : 'pointer',
                                        transition  : 'color 0.25s ease'
                                    }}
                                    onClick         = {handleClick}
                                    onDoubleClick   = {handleDoubleClick}
                                />
                                {
                                    showTips && isBad  && 
                                    <Typography style={{fontSize:0.25*fontSize, position:'absolute',top:fontSize,left:0,right:0,background:alpha(theme.palette.error.light,0.5),borderRadius:4}}> 
                                        Low
                                    </Typography> 
                                }
                                {
                                    showTips && isGood && 
                                    <Typography style={{fontSize:0.25*fontSize, position:'absolute',top:fontSize,left:0,right:0,background:alpha(theme.palette.success.light,0.5),borderRadius:4}}> 
                                        High 
                                    </Typography> 
                                }
                            </Box>
                        )
                    })
                }
                {
                    label && showValue &&
                    <Box>
                        <Typography variant="body2">
                            ({valueTemp || value || 0}/{steps})
                        </Typography>
                    </Box>
                }
            </Box>
        </Box>
    )
};

export default StarRating;