/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Product Reviews
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       4th December 2021

*******************************************************************************************/
import React                    from 'react';
import {isEqual,isEmpty}        from 'lodash';
import { useMediaQuery, Box }   from '@mui/material';
import Masonry                  from '@mui/lab/Masonry';
import {
    RootContainer,
    NoDataPrompt,
    LoadingData,
    Quote,
    Review,
    SpaceBox,
    FeatureBox,
    JSONViewer
}                               from 'components';
import { useNetwork }           from 'contexts';
import { useCancelToken }       from 'hooks';

export const ProductReviews = ({
    productId : productIdIn, 
    component : Component       = FeatureBox,
    quantity                    = 3, 
    minScore                    = 2.5, 
    hideIfNone                  = false,
    ...props
}) => {

    const {isNetworkReady, axios}               = useNetwork();
    const {cancelToken, isCancel}               = useCancelToken();

    const upLg                                  = useMediaQuery(theme => theme.breakpoints.up('lg'));
    const [reviews,         setReviews]         = React.useState([]);
    const [reviewsQuantity, setReviewsQuantity] = React.useState(0);
    const [productId,       setProductId]       = React.useState(undefined);
    const [working,         setWorking]         = React.useState(false);

    // Update Product ID
    React.useEffect(() => (
        setProductId(productIdIn)
    ), [productIdIn]);

    // Clear reviews
    const clearReviews = React.useCallback( () => setReviews([]), []);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(clearReviews,[productId]);

    // Query Database Callback
    const queryDatabase = React.useCallback( () => new Promise((resolve,reject) => {
        if(productId && isNetworkReady){
            if(quantity <= 0) return reject({});
            setWorking(true);
            axios.get(`/api/product/${productId}/reviews?limit=${quantity}&minScore=${minScore}&random=true`, {cancelToken})
                .then(({data}) => data)
                .then(resolve)
                .catch(err => {
                    if(isCancel(err)) return reject(err);
                    reject(err);
                })
                .finally(() => {
                    setWorking(false);
                })
        }else{
            reject();
        }
    }),[axios, cancelToken, isCancel, isNetworkReady, minScore, productId, quantity])

    const refresh = React.useCallback(() => {
        queryDatabase()
            .then(reviewsNew => {
                if(!isEqual(reviews, reviewsNew))
                    setReviews(reviewsNew);
            })
            .catch(clearReviews);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[queryDatabase])

    // Query Database when callback is modified
    React.useEffect(refresh,[refresh])

    React.useEffect(() => {
        setReviewsQuantity(Array.isArray(reviews) ? reviews.length : 0)
    }, [reviews]);

    if(hideIfNone && (!reviews || !reviews.length))
        return null;

    return (
        <Component>
            {
                props.renderTitle && 
                props.renderTitle({refresh, working, reviews, reviewsQuantity, quantity})
            }
            <RootContainer>
                {
                    Boolean(!isEmpty(reviews) && quantity) && 
                    <Masonry columns={upLg && reviews?.length > 1 ? 2 : 1} spacing={1}>
                        {
                            reviews.map((review,ix) => {
                                const handleChange = (data) => setReviews(prev => prev.map((p,index) => index === ix ? data : p));
                                return (
                                    <Box key={ix}>
                                        <Quote sx={{p:0, zoom: reviews?.length > 1 ? 0.75 : 1}}>
                                            {false && <JSONViewer src={review} /> }
                                            <Review 
                                                review      = {review}
                                                onChange    = {handleChange}
                                            />
                                        </Quote>
                                    </Box>
                                )
                            })
                        }
                    </Masonry>
                }
                {
                    !working && isEmpty(reviews) &&
                    <SpaceBox>
                        <NoDataPrompt>
                            No reviews submitted
                        </NoDataPrompt>
                    </SpaceBox>
                }
                { 
                    working &&
                    <SpaceBox align="center">
                        <LoadingData />
                    </SpaceBox>
                }
            </RootContainer>
        </Component>
    )
}

export default ProductReviews;