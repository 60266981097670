/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Sanitized CC
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       29th December 2020

*******************************************************************************************/
import React from 'react';

// Star Pad
const starPad = (x, places) => (
    String(x).padStart(places,'*')
);

// Sanitize Function
const sanitize = (x) => {
    let san;
    san = (x || '****').replace(' ','').trim();
    san = san.length > 4 ? san.slice(san.length-4, san.length) : starPad(san,4); // Exactly 4 chars
    san = `**** **** **** ${san}`;
    return san;
}

export const SanitizedCC = ({ cardNumber = "**** **** **** ****" }) => {
    const san = React.useMemo(() => sanitize(cardNumber), [cardNumber])
    return san
}

export default SanitizedCC;