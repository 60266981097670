/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
AstroVenusian CookieConsent
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       10th January 2021

*******************************************************************************************/

import React                            from 'react';
import isbot                            from 'isbot'
import { boolean }                      from 'boolean';
import { useCookies }                   from 'react-cookie';
import { Link, useLocation}             from 'react-router-dom';
import { 
    styled, 
    useTheme, useMediaQuery, //alpha, 
    Button, Box, Typography, DialogActions 
}                                       from '@mui/material';
import CheckCircleOutlineIcon           from '@mui/icons-material/CheckCircleOutline';
import CookieConsentComponent           from "react-cookie-consent";

import { 
    PolicyPrivacyLocation,
    PolicyCookiesLocation
}                                       from '../router/locations';
import {
    Logo
}                                       from 'components'
import { withTranslation }              from './hoc';
import {
    // useOstrio
}                                       from 'contexts';
import { useStateEphemeral }            from 'hooks';
import config                           from '../config';

const COOKIE_NAME           = config.cookies.paths.cookieConsent;
const BUTTON_WRAPPER_CLASS  = "ButtonWrapper";

const Seperator = styled(Box)(({theme})=>({
    width           : '10%',
    marginLeft      : 'auto',
    marginRight     : 'auto',
    marginTop       : theme.spacing(3),
    marginBottom    : theme.spacing(3),
    borderTop       : `1px solid ${theme.palette.primary.contrastText}`
}))

const noop = () => {};

const ConsentButton = ({children, onClick = noop, ...props}) => (
    <DialogActions id="cookieConsentActions" sx={{p:2}}>
        <Button 
            size        = "large"
            variant     = "contained" 
            color       = "secondary" 
            onClick     = {onClick} 
            startIcon   = {<CheckCircleOutlineIcon/>}
            style       = {{width:200}}
        >
            {children}
        </Button>
    </DialogActions>
)

const PrivacyButton = withTranslation( ({t,...props}) => (
    <Link to={PolicyPrivacyLocation.path} sx={{color : theme => theme.palette.primary.contrastText}}>
        {t('common.privacyPolicy')}
    </Link>
));

const CookieButton = withTranslation( ({t,...props}) => (
    <Link to={PolicyCookiesLocation.path} sx={{color : theme => theme.palette.primary.contrastText}}>
        {t('common.cookiePolicy')}
    </Link>
));

// Extend isbot to recognise prerender
// https://www.npmjs.com/package/isbot
isbot.extend(['^.*prerender.*$'])

export const CookieConsent = withTranslation( ({t}) => {
    const theme                 = useTheme();
    const [cookies]             = useCookies([COOKIE_NAME]);    
    const [show, setShow]       = React.useState(false);
    const location              = useLocation();
    const [ready]               = useStateEphemeral(false, 30000, true); // Set to true after 30 seconds
    
    const isRobot               = React.useMemo(() => isbot(navigator.userAgent), []);
    // const {isPrerendering}      = useOstrio();

    const handleHide            = React.useCallback(() => setShow(false), [] );
    
    React.useEffect(() => {
        setShow(!boolean(cookies[COOKIE_NAME]))
    }, [cookies])

    const zIndex            = React.useMemo(() => 1000000, []);
    const isPolicyPage      = React.useMemo(() => (
        [PolicyCookiesLocation.path, PolicyPrivacyLocation.path].includes(location.pathname)
    ), [location.pathname]);
    const mdUp              = useMediaQuery(theme => theme.breakpoints.up('md'));

    // Override Jinke Styles
    React.useEffect(() => {
        const style = document.createElement('style')
        style.innerHTML = `
            .${BUTTON_WRAPPER_CLASS} {
                width: 100%;
            }
        ` 
        document.head.appendChild(style);
        return () => {
            document.head.removeChild(style);
        }
    },[]);

    // Ignore cookie consent for robot
    if(!ready || isRobot || window.IS_PRERENDERING)
        return null;

    return (
        <>
            {
                show && !isPolicyPage &&
                <Box style={{position:'fixed',top:0,left:0,right:0,bottom:0, /*background:alpha('#000',0.75),*/ zIndex}}/>
            }
            <CookieConsentComponent
                cookieName          = {COOKIE_NAME}
                location            = "bottom"
                buttonText          = {t('common.accept')}
                expires             = {365}
                ButtonComponent     = {ConsentButton}
                color               = "secondary"
                enableDeclineButton = {false}
                buttonWrapperClasses= {BUTTON_WRAPPER_CLASS} 
                onAccept            = {handleHide}
                style               = {{ 
                    color       : theme.palette.primary.contrastText, 
                    background  : theme.palette.primary.main,
                    zIndex      : zIndex + 1
                }}
            >
                <Box display="flex" style={{zIndex : zIndex + 1}}>
                    {
                        mdUp && 
                        <Box flexShrink={1} pr={2} pl={2} style={{height:0}}>
                            <Logo dark={false} minWidth={0}/>
                        </Box>
                    }
                    <Box flexGrow={1}>
                        {
                            !isPolicyPage &&
                            <>
                                <Typography variant="h4" paragraph>
                                    {t('components.cookieConsent.yourPrivacy')}
                                </Typography>
                                <Typography paragraph variant="subtitle1" component="p" align="justify">
                                    {t('components.cookieConsent.yourPrivacyContent')}
                                </Typography>
                                <Seperator />
                            </>
                        }
                        <Typography variant="subtitle1" component="p">
                            {t('components.cookieConsent.confirmation', { acceptText : t('common.accept') } )}: <PrivacyButton/>, <CookieButton/>
                        </Typography>
                    </Box>
                </Box>
            </CookieConsentComponent>
        </>
    );
})

export default CookieConsent;