/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
SelectProductForm
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       25th September 2022

*******************************************************************************************/
import React                            from 'react';
import { Form }                         from 'components';
import { ProductField }                 from './fields/ProductField';
import { 
    showErrorOnChange   as showError,
}                                       from 'mui-rff';
import {
    RootContainer
}                                       from 'components';

const noop = () => {}
const obj = {};

const defaultFormData = {
    id : undefined
}

export const SelectProductForm = ({
    formData                = defaultFormData,
    disabled                = false,
    useVirtual              = true,
    usePhysical             = false,
    processor               = undefined,
    onSubmit : handleSubmit = noop,
    onCancel : handleCancel = noop,
    FormProps               = obj,
}) => {
    
    const validate = React.useCallback( (values) => {
        let errors = {};
        return errors;
    },[]);

    // Initial Form Data
    const initialValues = React.useMemo(() => ({
        ...formData
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [JSON.stringify(formData)])

    return (
        <RootContainer>
            <Form
                debug                   = {false}
                disabled                = {disabled}
                onSubmit                = {handleSubmit}
                onCancel                = {handleCancel}
                initialValues           = {initialValues}
                validate                = {validate}
                showObjectId            = {false}
                changeRequiredToSubmit  = {true}
                {...FormProps}
                render          = {({disabled, form, error, dirtySinceLastSubmit, submitFailed, submitSucceeded, errors, handleSubmit, values, ...rest}) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate>
                            <ProductField 
                                name        = "id"
                                useVirtual  = {useVirtual}
                                usePhysical = {usePhysical}
                                processor   = {processor}
                                disabled    = {disabled} 
                                showError   = {showError}
                            />
                        </form>
                    )
                }}
            />
        </RootContainer>
    )
}

export default SelectProductForm;