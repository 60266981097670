/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Library Editable Field
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       13th July 2022

*******************************************************************************************/
import React                            from 'react';
import isEmpty                          from 'lodash/isEmpty';
import flat                             from 'flat';
import { Box }                          from '@mui/material';
import { FieldForm }                    from 'components/forms';
import { RootContainer, EditableField } from 'components';
import { LibrarySubtitle }              from '.';
import { FORM_ERROR }                   from 'final-form';

const noop = () => {};
const obj = {};

export const LibraryEditableField = ({
    value                   = undefined, 
    title                   = undefined, 
    name                    = 'field', 
    disabled                = false, 
    fieldFormProps          = obj, 
    editableFieldProps      = obj,
    editable                = true, 
    onSubmit                = noop, 
    ...props
}) => {

    // Submit Handler
    const handleSubmit = React.useCallback( editCancel => data => new Promise((resolve) => {
        Promise.resolve()
            .then(() => onSubmit(data))
            .then(result => {
                if(isEmpty(result)) editCancel();
                resolve(result);
            })
            .catch(({message,errors}) => {
                const restoredObject = flat.unflatten({[FORM_ERROR]:message, ...errors}); // Unflat Heirarchy
                resolve(restoredObject)
            })
    }), [onSubmit]);

    return (
        <RootContainer {...props}>
            <Box>
                {
                    title && 
                    <LibrarySubtitle>
                        {title}
                    </LibrarySubtitle>
                }
                <EditableField 
                    value               = {value}
                    editable            = {editable}
                    disabled            = {disabled}
                    showButtonOnHover   = {true}
                    {...editableFieldProps}
                    renderEdit          = {({handleEditCancel,value}) => (
                        <Box>
                            <FieldForm 
                                value       = {value}
                                name        = {name}
                                onCancel    = {handleEditCancel}
                                onSubmit    = {handleSubmit(handleEditCancel)}
                                {...fieldFormProps}
                            />
                        </Box>
                    )}
                />
            </Box>
        </RootContainer>
    )
}

export default LibraryEditableField;
