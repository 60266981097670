/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
AlertLaunch
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       18th May 2023

*******************************************************************************************/
import React                from 'react';
import { 
    Box,
    useMediaQuery
}                           from '@mui/material';
import MainAlert            from './MainAlert';
import DigitalFont          from './digitalfont/DigitalFont';
import { withTranslation }  from './hoc';
import { useLaunch }        from 'contexts';

/**
 * Component for an alert showing a launch date
 * 
 * @param {Object} params - The params for the object
 * @param {moment} params.launchDate - The launch date as a moment object, must be in the future
 * 
 * @component
 * @example
 * const launchDate = moment().add(1,'days'); // 1 day in the future
 * reuturn (
 *      <AlertLaunch launchDate={launchDate} />
 * )
 */
export const AlertLaunch = withTranslation( ({t}) => {

    const {launched, clock, launchDate} = useLaunch();
    const mdUp = useMediaQuery(theme => theme.breakpoints.up('md'));

    // In the Past, Return Nothing
    if(launched)
        return null;

    // Render the Alert
    return (
        <MainAlert 
            severity    = "info"
            action      = { 
                mdUp ? 
                    <Box display="flex" sx={{my:'auto',mr:2}}>
                        <DigitalFont sx={{fontSize:'2.5rem'}}>
                            {clock}
                        </DigitalFont>
                    </Box>
                : null
            }
        >
            { t('components.alertLaunch.message', { timestamp : launchDate.format("Do MMMM YYYY") }) }
            {
                !mdUp && 
                <Box display="flex" mt={2}>
                    <Box flexGrow={1}/>
                    <Box>
                        <DigitalFont sx={{fontSize:'2rem'}}>
                            {clock}
                        </DigitalFont>
                    </Box>
                </Box>
            }
        </MainAlert>
    )
});

export default AlertLaunch;