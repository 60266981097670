/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Refresh Icon
********************************************************************************************
'loading' property:
    if set to true, causes the icon to spin indefinately

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       14th June 2021

*******************************************************************************************/

import React                    from 'react';
import { withRotation }         from './hoc';
import CachedIcon               from '@mui/icons-material/Cached';

const RotatingCachedIcon = withRotation(CachedIcon);

export const RefreshIcon = ({
    loading = false,
    ...props
}) => {
    return (
        <RotatingCachedIcon rotate={loading} {...props}/>
    )
}

export default RefreshIcon;