
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Pulse Chip
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       6th July 2021

*******************************************************************************************/

import React                from 'react';
import { Chip }             from '@mui/material';
import withPulse            from './withPulse';

const Component = withPulse(Chip);

export const PulseChip = ({ pulse = true, disabled = false, ...props}) => {
    return (
        <span>
            <Component pulse={pulse && !disabled} disabled={disabled} {...props}/>
        </span>
    )
};

export default PulseChip;