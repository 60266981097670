/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Cart Component
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       29th June 2021

*******************************************************************************************/
import React                            from 'react';
import {  
    styled, 
    lighten, 
    Grid, 
    Chip, 
    Box, 
    Button      as ButtonMUI, 
    Typography  as TypographyMUI 
}                                       from '@mui/material';
import {
    Payment as PaymentIcon,
}                                       from '@mui/icons-material';
import { 
    Title,
    Sticky,
    ItemPaper,
    RefreshIcon,
    Currency
}                                       from 'components';
import { withTranslation }              from './hoc';
import { useCart, useUser, useLocale}   from 'contexts';
import { withDisabledOffline }          from 'components/hoc';

const Button = withDisabledOffline(ButtonMUI);

const TRANSITION_TIME = '0.5s';

const CheckoutContainerUnstuck = styled(Box)(({theme}) => ({
    background                  : theme.palette.background.paper,
    transitionTimingFunction    : 'ease',
    transition                  : TRANSITION_TIME,
}));

const withCheckoutContainerStuck    = stickyPosition => styled(Box)(({theme}) => ({
    borderRadius                : theme.spacing(1),
    background                  : [lighten(theme.palette.primary.light,0.5),'!important'],
    '&:hover':{
        background              : [lighten(theme.palette.primary.light,0.40),'!important'],
    },
    transitionTimingFunction    : 'ease',
    transition                  : TRANSITION_TIME,
    ...(stickyPosition === 'top' && {
        borderTopLeftRadius         : 0,
        borderTopRightRadius        : 0,
    }),
    ...(stickyPosition === 'left' && {
        borderTopLeftRadius         : 0,
        borderBottomLeftRadius      : 0,
    }),
    ...(stickyPosition === 'bottom' && {
        borderBottomLeftRadius      : 0,
        borderBottomRightRadius     : 0
    }),
    ...(stickyPosition === 'right' && {
        borderTopRightRadius        : 0,
        borderBottomRightRadius     : 0
    })
}));

const CheckoutDataContainer = styled(Box)(({theme}) => ({
    display         : "flex",
    flexDirection   : "column",
    height          : "100%",
    padding         : 0, 
    [theme.breakpoints.down('md')] : {
        padding     : theme.spacing(2),
    },
    [theme.breakpoints.down('md')]:{
        maxWidth : '100%'
    },
}));

const Typography = styled(TypographyMUI,{
    shouldForwardProp : prop => prop !== 'isStuck' && prop !== 'isSmall' && prop !== 'total'
})(({theme, isSmall = false, isStuck = false, total = false}) => ({
    ...(isSmall && {
        fontSize        : '0.75rem',
        [theme.breakpoints.down('md')] : {
            fontSize : '0.70rem',
            paddingTop : theme.spacing(0.5)
        },
        [theme.breakpoints.down('sm')] : {
            fontSize : '0.60rem',
            paddingTop : theme.spacing(0.25)
        },
    }),
    ...(isStuck && {
        color : theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.text.primary, //theme.palette.primary.contrastText,
    }),
    ...(total && {
        fontSize : '1.50rem',
        fontWeight : 400,
        marginTop : 'auto',
        marginBottom : 'auto',
        paddingLeft : theme.spacing(1)
    })
}));

const Commentry = styled(Typography,{
    shouldForwardProp : prop => prop !== 'isStuck' && prop !== 'isSmall' && prop !== 'total'
})(({theme, isStuck = false}) => ({
    textAlign       : 'justify',
    paddingBottom   : theme.spacing(1),
    borderBottom    : '1px solid',
    ...(isStuck && {
        borderBottomColor    : theme.palette.primary.dark,
    }),
    ...(!isStuck && {
        borderBottomColor    : theme.palette.text.primary,
    })
}));

const CheckoutDataStuck = styled(Box,{
    shouldForwardProp : prop => prop !== "showSticky"    
})(({showSticky = false}) => ({
    ...(showSticky && {
        borderRadius    : 'inherit',
        background      : 'unset'
    })
}));

const TotalText = styled(Box)({
    marginTop       : 'auto',
    marginBottom    : 'auto',
    color           : 'inherit'
});

const ItemPaperCheckout = (props) => {
    return (
        <ItemPaper {...props} elevation={props.elevation} />
    )
};

const noop = () => {};
const obj = {};

const FreeChip = withTranslation( ({t, free = false} = {}) => {
    if(!free) 
        return null;
    return (
        <Chip 
            label   = {t('components.cartFooter.freeOrder').toUpperCase()}
            color   = "primary" 
            size    = "small"
        />
    )
});

export const CartFooter = withTranslation( ({
    t,
    disabled                    = false,
    fullWidth                   = false,
    summary                     = false,
    description                 = false,
    submitButtonText            = undefined,
    comment                     = undefined,
    quantity                    = 0,
    value                       = 0.0,
    taxes                       = 0.0,
    onClick : handleClick       = noop,
    SubmitButtonProps           = obj,
    taxCode                     = undefined,
    sticky                      = true,
    stickyPosition              = "bottom",
    children,
    ...props
}) => {
    
    const {working}                 = useCart();
    const {canPurchase}             = useUser(); // User Suspended etc
    const {
        formatCurrency,
        currencyFactor,
        currency
    } = useLocale();
    const [isStuck, setStuck]       = React.useState(false);
    const [isFree, setIsFree]       = React.useState(value <= 0 && quantity > 0);
    const CheckoutContainerStuck    = React.useMemo(() => withCheckoutContainerStuck(stickyPosition), [stickyPosition])
    
    // Change isFree state variable when value or quantity changes
    React.useEffect(() => setIsFree(value <= 0 && quantity > 0), [value,quantity])

    const showSticky = React.useMemo(() => sticky && isStuck, [isStuck, sticky])

    return (
        <Sticky 
            enabled             = {sticky}
            onStick             = {setStuck} 
            position            = {stickyPosition}
            stuckComponent      = {CheckoutContainerStuck}
            unstuckComponent    = {CheckoutContainerUnstuck}
        >   
            <Box component="div" id="checkoutcontainer" display="flex" flexDirection="row-reverse" style={{borderRadius : 'inherit'}}>
                <CheckoutDataStuck
                    component   = {ItemPaperCheckout} 
                    showSticky  = {showSticky} 
                    elevation   = {showSticky ? 4 : 0}
                    style       = {{textAlign:'right', marginLeft:0, position:'relative', height:'100%'}}
                >
                    <CheckoutDataContainer id="pricing">
                        {
                            !summary && 
                            <React.Fragment>
                                <Typography component={Title} color="inherit" gutterBottom isStuck={showSticky}>
                                    <FreeChip free={isFree}/> {t('components.cartFooter.orderSummary')}
                                </Typography>
                                {
                                    false && quantity > 0 &&
                                    <Commentry isStuck = {showSticky} isSmall = {true} paragraph>
                                        {
                                            t('components.cartFooter.commentary', 
                                            {
                                                quantity,
                                                averagePrice    : formatCurrency((value/currencyFactor)/quantity, currency), 
                                                totalPrice      : formatCurrency(value/currencyFactor, currency)
                                            })
                                        }
                                    </Commentry>
                                }
                            </React.Fragment>
                        }

                        <Box flexGrow={1}>
                            <Box display='flex'>
                                <Box flexGrow   = {1}/>
                                <Box flexShrink = {1}>
                                    <Grid container spacing={0} >

                                        {
                                            summary &&
                                            <React.Fragment>
                                                <Grid item xs={12} component={TotalText}>
                                                    <Typography sx={{color:'inherit'}} isStuck={showSticky}>
                                                        {t('components.cartFooter.totalOrderAmount')}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} component={TotalText}>
                                                    <Typography isStuck={showSticky} total={true} color="inherit" gutterBottom >
                                                        <Currency value={value + taxes} showCurrency={true} />
                                                    </Typography >
                                                </Grid>
                                            </React.Fragment>
                                        }
                                        {
                                            !summary && 
                                            <React.Fragment>
                                                 <Grid item xs={12} component={TotalText}>
                                                    <Typography color="inherit" isStuck={showSticky}>
                                                        {t('components.cartFooter.totalExTax', {taxCode : taxCode || t('components.cartFooter.taxes') })}
                                                    </Typography>
                                                </Grid>
                                                 <Grid item xs={12} component={TotalText}>
                                                    <Typography isStuck={showSticky} total={true} color="inherit" gutterBottom >
                                                        <Currency value={value} />
                                                    </Typography >
                                                </Grid>
                                                <Grid item xs={12} component={TotalText}>
                                                    <Typography color="inherit" isStuck={showSticky}>
                                                        {taxCode || t('components.cartFooter.taxes')}:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} component={TotalText}>
                                                    <Typography isStuck={showSticky} total={true} color="inherit">
                                                        <Currency value={taxes}/>
                                                    </Typography >
                                                </Grid>

                                                <Grid item xs={12} component={TotalText}>
                                                    <Typography color="inherit" isStuck={showSticky}>
                                                        {t('common.total')}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} component={TotalText}>
                                                    <Typography isStuck={showSticky} total={true}>
                                                        <Currency value={value + taxes} showCurrency={true}/>
                                                    </Typography >
                                                </Grid>
                                            </React.Fragment>
                                        }
                                    </Grid>
                                </Box>
                            </Box>

                        </Box>

                        {
                            children &&
                            <Box>
                                {children}
                            </Box>
                        }

                        <Box pt={1}>
                            {
                                comment && 
                                <Box sx={{my:1}}>
                                    <Typography component="div" color="inherit" isStuck={showSticky}>
                                        {comment}
                                    </Typography>
                                </Box>
                            }
                            <Button 
                                startIcon   = {working ? <RefreshIcon loading={true}/> : <PaymentIcon/>} 
                                onClick     = {handleClick} 
                                variant     = "contained" 
                                color       = "primary" 
                                size        = "large" 
                                {...SubmitButtonProps}
                                disabled    = {!canPurchase || disabled || SubmitButtonProps.disabled || working} 
                            >
                                {submitButtonText || t('components.cartFooter.checkoutNow')}
                            </Button>
                        </Box>
                    </CheckoutDataContainer>
                </CheckoutDataStuck>
            </Box>
        </Sticky>
    )
})

export default CartFooter