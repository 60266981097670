/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Horoscope Component
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       17th April 2023

*******************************************************************************************/
import React                            from 'react';
import moment                           from 'moment';
import {
    useNetwork,
}                                       from 'contexts';
import {
    useCancelToken,
    useStateEphemeral
}                                       from 'hooks';

const validateLatitude = (val) => {
    if(isNaN(val)) 
        return false; 
    return Math.abs(val) <= 90
}

const validateLongitude = (val) => {
    if(isNaN(val)) 
        return false; 
    return Math.abs(val) <= 180
}

export const useQueryHoroscopeData = ({birthDateTime, localTime, houseSystem, zodiac, lat, lng }) => {
   
    const [horoscopeData, setHoroscopeData] = React.useState(undefined);
    const [queried, setQueried]             = React.useState(false);
    const [working, setWorking]             = React.useState(false);
    const { axios, isNetworkReady }         = useNetwork();
    const { cancelToken, isCancel}          = useCancelToken();
    const [errorMessage, setErrorMessage]   = useStateEphemeral(undefined);

    React.useEffect(() => {

        if(!axios || !isNetworkReady){
            setHoroscopeData(undefined);
            return;
        }

        // Missing Data
        if(![birthDateTime, houseSystem, zodiac].every(Boolean)){
            setHoroscopeData(undefined);
            return;
        }

        // Invalid Lat/Lng
        if(!validateLatitude(lat) || !validateLongitude(lng)){
            setHoroscopeData(undefined);
            setErrorMessage('Invalid Latitude/Longitude')
            return;
        }

        // Now Query
        setWorking(true);
        axios.post('/api/astrology/horoscope', {birthDateTime, houseSystem, localTime, zodiac, lat, lng}, {cancelToken})
            .then(({data}) => data)
            .then(setHoroscopeData)
            .catch((err) => {
                if(isCancel(err)) return;
                console.error(err)
                setErrorMessage(err.message)
                setHoroscopeData(undefined);
            })
            .finally(() => {
                setQueried(moment())
                setWorking(false);
            })

    },[axios, birthDateTime, cancelToken, houseSystem, isCancel, isNetworkReady, lat, lng, localTime, setErrorMessage, zodiac])

    return {
        horoscopeData, 
        working, 
        queried,
        errorMessage
    }
}

export default useQueryHoroscopeData;