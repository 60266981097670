/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Product Card Component
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       6th August 2021

*******************************************************************************************/

import React                    from 'react';
import TextTruncate             from 'react-text-truncate';
import { titleCase }            from 'title-case';
import { useHistory }           from 'react-router-dom';
import { 
    styled, 
    lighten, 
    useTheme, 
    Box, 
    Typography, 
    Tooltip 
}                               from '@mui/material';
import ShoppingCartIconMUI      from '@mui/icons-material/ShoppingCart';
import VisibilityIconMUI        from '@mui/icons-material/Visibility';
import DeleteIconMUI            from '@mui/icons-material/Delete';
import SearchIcon               from '@mui/icons-material/Search';
import MoneyIcon                from '@mui/icons-material/MonetizationOn';
import FlightTakeoffIcon        from '@mui/icons-material/FlightTakeoff';
import EditIconMUI              from '@mui/icons-material/Edit';
import {
    Button,
    IconButton,
    MediaCard,
    PriceWithSale,
    EditProductButton,
    TagChip,
    AddToCartButton,
    PriceTag,
}                               from 'components';
import { withTranslation }      from './hoc';
import { 
    ProductLocation,
    UserCartLocation
}                               from 'router/locations/Locations';
import { 
    useProduct,
    useCart,
    useUser
}                               from 'contexts';
import { useImageCDN }          from 'hooks';
import { isEmpty }              from 'lodash';
import { 
    documentToPlainTextString 
}                               from '@contentful/rich-text-plain-text-renderer';

const IMAGE_HEIGHT_DEFAULT = 150;

const withIcon = Icon => styled(Icon)({
    display         : 'inline',
    fontSize        : '0.7rem',
})

const EditIcon          = withIcon(EditIconMUI);
const VisibilityIcon    = withIcon(VisibilityIconMUI);
const ShoppingCartIcon  = withIcon(ShoppingCartIconMUI);
const DeleteIcon        = withIcon(DeleteIconMUI);
const TypographyIcon    = withIcon(Typography);

const InCartTagContainer = styled(Box)({
    position        : 'absolute', 
    transform       : 'translateY(-50%)',
    
    zIndex          : 2, 
    top             : 0, 
    right           : 0,
    padding         : 0, 
});

const InCartTag = styled(Box,{
    shouldForwardProp : prop => prop !== 'inCart' && prop !== 'isAdmin'
})(({theme, inCart=false, isAdmin=false}) => ({
    display         : 'inline',
    borderRadius    : 3,
    paddingLeft     : theme.spacing(0.5),
    paddingRight    : theme.spacing(0.5),
    marginRight     : theme.spacing(0.5),
    background      : theme.palette.background.paper,
    ...(inCart && {
        background      : theme.palette.info.light,
        border          : `1px solid ${theme.palette.info.main}`
    }),
    ...(isAdmin && {
        background      : lighten(theme.palette.secondary.light,0.5),
        border          : `1px solid ${theme.palette.secondary.main}`
    })
}));

const Description = styled(Typography)({
    fontSize    : '0.75rem',
    textAlign   : 'justify'
});

const Abstract = styled(Typography)({
    fontWeight  : 600,
    fontSize    : '0.75rem',
    textAlign   : 'center',
    fontStyle   : 'italic',
});

const PriceContainer = styled(Box)(({theme}) => ({
    fontSize : '1.00rem!important',
    [theme.breakpoints.up('sm')] : {
        fontSize : '1.20rem!important'
    },
    [theme.breakpoints.up('md')] : {
        fontSize : '1.40rem!important'
    },
    [theme.breakpoints.up('lg')] : {
        fontSize : '1.60rem!important'
    },
}));

const PriceTagContainer = styled(Box)(({theme}) => ({
    // border      : '1px solid black',
    position    : 'relative',
    scale       : '0.6',
    transform   : 'translateY(-50%) rotate(-15deg)'
    // position:'absolute', scale:'0.5', top:'50%', right:theme.spacing(-1), padding:0, textAlign:'right', transformOrigin : 'center left', transform:'translateY(-50%) translateX(100%) rotate(-15deg)'
}))

export const ProductCard = withTranslation( ({
    t, 
    productId, 
    editable        = true, 
    actionsHeight   = 125, 
    showAddToCart   = !true, 
    showViewDetails = !false, 
    showDescription = true,
    showImage       = true,
    imageHeight     = IMAGE_HEIGHT_DEFAULT
}) => {
    
    const theme                         = useTheme();
    const history                       = useHistory();
    const {
        working,
        qtyProductInCart,
        removeProductFromCart
    }                                   = useCart();
    const {
        isAuthenticated,
        isAdmin
    }                                   = useUser();
    const {
        data        : products,
        contentful  : contentfulCollection,
        DEFAULT_PRODUCT_IMAGE,
        GRAYSCALE_WHEN_UNAVAILABLE
    }                                   = useProduct();
    const convert                       = useImageCDN();
    const [isEditing,   setEditing]     = React.useState(false);
    const [lookingAt,   setLookingAt]   = React.useState(undefined);
    const [contentful,  setContentful]  = React.useState({});
    
    // Update product if id or products change
    const product = React.useMemo(() => (
        productId 
            ? products.find(item => item._id === productId) 
            : undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [productId, JSON.stringify(products)]);

    React.useEffect(() => {
        if(product?.slug){
            setContentful(contentfulCollection.find(x => x?.fields?.slug === product?.slug))
        }else{
            setContentful({})
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[JSON.stringify(contentfulCollection),product?.slug])

    // Is Available
    const isAvailable                   = React.useMemo(() => (
        product?.available && product?.listed && !product?.deleted
    ),[product?.available, product?.deleted, product?.listed])


    const handleViewClick           = React.useCallback( () => history.push(ProductLocation.toUrl({slug: product.slug})), [history, product])
    const handleViewCart            = React.useCallback( () => history.push(`${UserCartLocation.path}`), [history])
    const handleViewCartItem        = React.useCallback( () => history.push(`${UserCartLocation.path}#${productId}`), [history, productId])
    const handleDelClick            = React.useCallback( () => removeProductFromCart({productId}), [productId, removeProductFromCart])
    const handleOpenChange          = React.useCallback( (value) => setEditing(value ? productId : undefined), [productId])

    const qtyCart                   = React.useMemo(() => qtyProductInCart(productId), [productId, qtyProductInCart]);
    const inCart                    = React.useMemo(() => Boolean(qtyCart), [qtyCart]);
    const isLooking                 = React.useMemo(() => isAdmin && (lookingAt?._id === productId || isEditing === productId), [isAdmin, isEditing, lookingAt?._id, productId]);
    const iconButtonProps           = React.useMemo(() => ({
        color       : 'secondary',
        size        : 'small',
        Icon        : EditIcon,
    }), [])

    const convertedImageDefault = React.useMemo(() => convert(DEFAULT_PRODUCT_IMAGE, {
        operation   : 'width',
        width       : 500,
        options     : `grayscale:${isAvailable || !GRAYSCALE_WHEN_UNAVAILABLE ? false : true}`
    }),[convert,isAvailable,DEFAULT_PRODUCT_IMAGE, GRAYSCALE_WHEN_UNAVAILABLE]);

    const converteImage = React.useMemo(() => convert(product.cover || product.images[0],{
        operation   : 'width',
        width       : 500,
        options     : `grayscale:${isAvailable || !GRAYSCALE_WHEN_UNAVAILABLE ? false : true}`
    }),[convert, isAvailable, product.cover, product.images, GRAYSCALE_WHEN_UNAVAILABLE])

    if(!product)
        return null;

    return (
        <Box width="100%" height="100%" id={`productCardRoot${productId}`} onMouseEnter = {() => setLookingAt(product) } onMouseLeave = {event => setLookingAt(undefined)}>
            <div style={{position:'relative'}}>
                {(inCart || isAdmin) && editable &&
                    <InCartTagContainer>
                        {
                            isLooking && 
                            <InCartTag isAdmin={true}>
                                {
                                    isAdmin &&
                                    <EditProductButton 
                                        productId       = {productId} 
                                        IconButtonProps = {iconButtonProps}
                                        onOpenChange    = {handleOpenChange}
                                    />
                                }
                            </InCartTag>
                        }
                        {
                            inCart && 
                            <InCartTag inCart={true}> 
                                <TypographyIcon color="textSecondary">
                                    {qtyCart}x
                                </TypographyIcon>
                                <IconButton size="small" onClick={handleViewCartItem}>
                                    <Tooltip title={t('common.viewItemInCart')}>
                                        <VisibilityIcon/>
                                    </Tooltip>
                                </IconButton>
                                <IconButton size="small" onClick={handleViewCart}>
                                    <Tooltip title={t('common.viewCart')}>
                                        <ShoppingCartIcon/>
                                    </Tooltip>
                                </IconButton>
                                <IconButton disabled={working} size="small" onClick={handleDelClick}>
                                    <Tooltip title={t('common.removeFromCart')}>
                                        <DeleteIcon />
                                    </Tooltip>
                                </IconButton>
                            </InCartTag>
                        }
                    </InCartTagContainer>
                }
            </div>

            <MediaCard
                onClick         = {handleViewClick}
                image           = {converteImage || convertedImageDefault}
                imageHeight     = {imageHeight || IMAGE_HEIGHT_DEFAULT}
                showImage       = {showImage}   
                actionsHeight   = {actionsHeight}
                title           = {
                    <Box display="flex">
                        <Box flexGrow={1}/>
                        <Box flexShrink={1} display="flex">
                            <Box>
                                {contentful?.fields?.name || product.name}
                            </Box>
                            {
                                product.onSale && 
                                <PriceTagContainer>
                                    <PriceTag color={theme.palette.secondary.textContrast} background={theme.palette.error.main}>
                                        {t('common.onSale')}
                                    </PriceTag>
                                </PriceTagContainer>
                            }
                        </Box>
                        <Box flexGrow={1}/>
                    </Box>
                }
                description = {
                    <React.Fragment>

                        <Box sx={{minHeight:50}}>
                            <Abstract component="div" paragraph={showDescription}>
                                {contentful?.fields?.abstract || t('common.noAbstract')}
                            </Abstract>
                        </Box>

                        {
                            showDescription && 
                            <Description component="div" paragraph>
                                <TextTruncate
                                    text    = {
                                        !isEmpty(contentful?.fields?.description)
                                            ? documentToPlainTextString(contentful?.fields?.description)
                                            : t('common.noDescription')
                                    }
                                    line    = {5}
                                />
                            </Description>
                        }

                        {
                            false &&
                            <Typography component='div' gutterBottom>  
                                {
                                    product.onSale && 
                                    <TagChip icon={<MoneyIcon/>} color="default" label={t('common.onSale')} />
                                }
                                {
                                    product.requiresShipping && 
                                    <TagChip icon={<FlightTakeoffIcon/>} color="default" label={t('common.isShipped')}/>
                                }
                                {
                                    [product?.tags || [], product?.categories || [] ]
                                    .map((arr,ixA) => (
                                        arr.filter(Boolean).map((value,ixB) => (
                                            <TagChip
                                                key     = {`${ixA}-${ixB}`} 
                                                label   = {titleCase(value)} 
                                                color   = {['primary','secondary'][ixA]} size={"small"}
                                            />
                                        ))
                                    ))}
                            </Typography>
                        }
                    </React.Fragment>
                }
                buttonText  = {t('common.viewDetails')}
                actions     = {
                    (showAddToCart || showViewDetails) ? 
                    <React.Fragment>
                        {
                            false && isAdmin && isAuthenticated && isLooking &&
                            <EditProductButton 
                                productId       = {productId} 
                                useIconButton   = {false}
                                ButtonProps     = {{
                                    variant     : "contained",
                                    color       : "secondary",
                                }}
                                onOpenChange ={(value)=>{
                                    setEditing(value);
                                }}
                            />
                        }
                        { 
                            showViewDetails &&
                            <Button startIcon={<SearchIcon/>} onClick={handleViewClick} variant="contained" color="primary" size="large">
                                {t('common.viewDetails')}
                            </Button>
                        }
                        {
                            showAddToCart &&
                            <AddToCartButton productId={productId} />
                        }
                    </React.Fragment>
                    : null
                }
                subdescription = {
                    <Box id="availablefor" pt={0} style={{color:theme.palette.text.primary,textAlign:'right'}}>
                        <Typography component='div'>
                            {t('common.availableFor')}:
                        </Typography>
                        <PriceWithSale
                            priceOriginal   = {product.listPrice}
                            price           = {product.price}
                            component       = {PriceContainer}
                        />
                    </Box>
                }
            />
        </Box>
    )
});

export default ProductCard;