/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Library Tickets
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       30th April 2022

*******************************************************************************************/
import React                            from 'react';
import { Box }                          from '@mui/material';
import { Tickets  }                     from 'components';
import { useLibraryViewer, useUser }    from 'contexts';

export const LibraryTickets = ({...props}) => {

    // Library Viewer Context
    const { libraryId, isOwner} = useLibraryViewer();
    const {isAuthenticated}     = useUser();

    // No Delivery ID, Return Nothing
    if(!libraryId || !isOwner || !isAuthenticated)
        return null;

    // Return the Delivery Review
    return (
        <Box id="libraryTickets">
            <Tickets {...props} libraryId={libraryId} />
        </Box>
    )
}

export default LibraryTickets;