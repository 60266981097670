/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Geo Display
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       25th December 2020

*******************************************************************************************/
import React                            from 'react';
import { 
    styled,
    Box, 
    Table, 
    TableBody, 
    TableRow, 
    TableCell as TableCellMUI
}                                       from '@mui/material';
import isEmpty                          from 'lodash/isEmpty';
import flat                             from 'flat';
import { FieldForm }                    from 'components/forms';
import { EditableField }                from 'components';
import { withTranslation }              from './hoc';
import { FORM_ERROR }                   from 'final-form';

const TableCell = styled(TableCellMUI,{
    shouldForwardProp : prop => prop !== 'head'
})(({theme, head = false}) => ({
    padding         : 0,
    paddingRight    : theme.spacing(0.5),
    paddingBottom   : theme.spacing(0.25),
    paddingTop      : theme.spacing(0.25),
    border          : 'none',
    ...(head && {
        fontWeight  : 800,
        width       : 0,
    })
}))

const noop = () => {};

export const GeoDisplay = withTranslation( ({
    t,
    lat,
    lng,
    address,
    onChange    : handleChange = noop,
}) => {

    const formatGeo = React.useCallback(val => {
        val = parseFloat(val)
        if(isNaN(val)) 
            return 'N/A'
        return val.toFixed(4);
    }, []);

    // Submit Handler
    const handleSubmit = React.useCallback( editCancel => data => new Promise((resolve) => {
        Promise.resolve()
            .then(() => handleChange({...data,lat,lng}))
            .then(result => {
                if(isEmpty(result)) editCancel();
                resolve(result);
            })
            .catch(({message,errors}) => {
                const restoredObject = flat.unflatten({[FORM_ERROR]:message, ...errors}); // Unflat Heirarchy
                resolve(restoredObject)
            })
    }), [handleChange, lat, lng]);

    return (
        <Box width = "100%">
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell head={true} sx={{verticalAlign: 'top'}}>
                            {t('common.address')}:
                        </TableCell>
                        <TableCell>
                            <EditableField 
                                value               = {address || null}
                                editable            = {true}
                                disabled            = {false}
                                showButtonOnHover   = {true}
                                renderEdit          = {({handleEditCancel,value}) => (
                                    <Box>
                                        <FieldForm 
                                            value       = {value}
                                            name        = {'address'}
                                            onCancel    = {handleEditCancel}
                                            onSubmit    = {handleSubmit(handleEditCancel)}
                                            maxLength   = {128}
                                        />
                                    </Box>
                                )}
                                sx                  = {{fontSize:'inherit',cursor:'pointer'}}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell head={true}>
                            { t('common.lat') }:
                        </TableCell>
                        <TableCell>
                            { formatGeo(lat) }
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell head={true}>
                            { t('common.lng') }:
                        </TableCell>
                        <TableCell>
                            { formatGeo(lng) }
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Box>
    )
});

export default GeoDisplay;
