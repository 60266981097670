/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
IconMenu
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       7th August 2022

*******************************************************************************************/
import React                                from 'react';
import MoreHorizIcon                        from '@mui/icons-material/MoreHoriz';
import MenuMUI                              from '@mui/material/Menu';
import MenuItemMUI                          from '@mui/material/MenuItem';
import { IconButton }                       from 'components';
import { withTranslation }                  from './hoc';

const defaultMenuProps          = {};
const defaultIconButtonProps    = {};
const defaultIconProps          = {};

export const IconMenu = withTranslation( ({
    t,
    disabled        = false,
    icon : Icon     = MoreHorizIcon,
    menuProps       = defaultMenuProps, 
    iconButtonProps = defaultIconButtonProps,
    iconProps       = defaultIconProps, 
    ...props
}) => {
    const {children, render, ...rest}   = props;
    const [anchorEl, setAnchorEl]       = React.useState(null);
    const handleOpenMenu                = React.useCallback( (e) => setAnchorEl(e.currentTarget), []);
    const handleCloseMenu               = React.useCallback( ()  => setAnchorEl(null), []);
    const menuPropsStyle                = React.useMemo(() => ({...menuProps.style}), [menuProps?.style])
    return <>
        <IconButton
            disabled        = {disabled}
            aria-controls   = "menu"
            aria-haspopup   = "true"
            aria-label      = {t('components.iconMenu.openToShowMore')}
            title           = {t('components.iconMenu.openToShowMore')}
            {...rest}
            {...iconButtonProps}
            onClick         = {handleOpenMenu}
        >
            <Icon {...iconProps}/>
        </IconButton>
        <MenuMUI
            id              = "menu"
            keepMounted     = {true}
            onClick         = {handleCloseMenu}
            onClose         = {handleCloseMenu}
            {...menuProps}
            style           = {menuPropsStyle}
            open            = {Boolean(anchorEl)}
            anchorEl        = {anchorEl}
        >   
            {   
                render && 
                render({handleCloseMenu,disabled})
            }
            {
                !render && 
                children
            }
        </MenuMUI>
    </>;    
});

export const MenuItem = MenuItemMUI;

export default IconMenu