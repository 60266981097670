/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Google Maps
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       2nd July 2021

*******************************************************************************************/

import React                                from 'react';
// import { useGeocode }                       from 'hooks';
import { useTheme, Box}                     from '@mui/material';
import { Map, GoogleApiWrapper, Marker}     from 'google-maps-react';
import { useUser, useTranslation}           from 'contexts';
import { withTranslation }                  from './hoc';
import config                               from '../config';

const {
    googleMaps : {
        apiKey : GOOGLE_MAPS_API_KEY
    }
}   = config;


const POSITION_DEFAULT  = {lat:  -33.8688, lng: 151.2093}
const onChangeDefault   = (position) => {};
const defaultStyle      = {};

const GoogleMapsComponent = React.memo( ({
    lat,
    lng,
    radius                  = 5,
    width                   = '100%',
    height                  = '100%',
    draggable               = true,
    google,
    onChange                = onChangeDefault,
    showMarker              = true,
    style                   = defaultStyle,
    ...rest
}) => {
    const {t}                       = useTranslation();
    const {metadata}                = useUser();
    const theme                     = useTheme();
    // const {lookupLatLng}            = useGeocode();

    /*
    const [lat,     setLat]         = React.useState(undefined); 
    const [lng,     setLng]         = React.useState(undefined); 
    const [width,   setWidth]       = React.useState(undefined); 
    const [height,  setHeight]      = React.useState(undefined); 
    */

    /*
    React.useEffect(() => setLat(latIn),        [latIn]);   // Lat
    React.useEffect(() => setLng(lngIn),        [lngIn]);   // Lng
    React.useEffect(() => setWidth(widthIn),    [widthIn])  // Width
    React.useEffect(() => setHeight(heightIn),  [heightIn]) // Height
    */

    // If position changes, then perform lookup
    /*
    React.useEffect(() => {
        if(!isNaN(lat) && !isNaN(lng)){
            lookupLatLng(lat,lng)
                .then(({lat, lng, address}) => {
                    onChange({lat, lng, address})
                })
                .catch(err => {
                    // Silent
                })
        }
    }, [lookupLatLng, lat, lng, onChange])
    */

    const onMarkerClick = React.useCallback( (props, marker, e) => {
        // Nothing
    },[]);

    const moveMap = React.useCallback( (mapProps, map) => {
        // Nothing
    },[]);
    
    const handleMapClick = React.useCallback( (mapProps, map, clickEvent) => {
        // Nothing
    }, []);

    const moveMarker = React.useCallback( (mapProps, map, coord) => {
        const { latLng : geo } = coord;
        const   lat = geo.lat(),
                lng = geo.lng();
        onChange({lat,lng})
        // setLat(undefined); // Will prevent dupe api call
        // setLng(lng);
        // setLat(lat);
    }, [onChange]);

    const center = React.useMemo(() => ({
        lat: lat || metadata?.geoip?.latitide   || POSITION_DEFAULT?.lat, 
        lng: lng || metadata?.geoip?.longitude  || POSITION_DEFAULT?.lng
    }),[lat, lng, metadata?.geoip?.latitide, metadata?.geoip?.longitude]);

    // Container and Maps Style
    const mapsStyle = React.useMemo(() => (
        { width : '100%', height : '100%', borderRadius : radius, ...style}
    ),[radius, style]);

    const containerStyle = React.useMemo(() => (
        { width : '100%', height : '100%', border : `1px ${theme.palette.divider} solid`, borderRadius : radius}
    ),[radius, theme.palette.divider]);

    return (
        <Box style={{width,height}}>
            <Map
                google          = {google}
                zoom            = {14}
                containerStyle  = {containerStyle}
                center          = {center}
                initialCenter   = {center}
                onDragend       = {moveMap}
                onClick         = {handleMapClick}
                draggable       = {draggable}
                {...rest}
                style           = {mapsStyle}
            >
                {
                    showMarker &&
                    <Marker 
                        onClick     = {onMarkerClick} 
                        name        = {t('components.googleMapsDynamic.currentLocation')} 
                        draggable   = {draggable} 
                        onDragend   = {moveMarker}
                        position    = {center}
                    />
                }
            </Map>
        </Box>
    );
}
)

const LoadingContainer = withTranslation( ({t}) => (
    <div>
        {t('common.loading')}
    </div> 
));

export const GoogleMapsDynamic = GoogleApiWrapper({ 
    apiKey              : GOOGLE_MAPS_API_KEY,
    LoadingContainer    : LoadingContainer,
})(GoogleMapsComponent);

export default GoogleMapsDynamic;
