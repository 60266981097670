/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Contentful Page Conponent
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       14th January 2024

*******************************************************************************************/
import React                    from 'react';
import {
    Box,
    Grid,
    Typography,
    useMediaQuery
}                               from '@mui/material';
import { 
    Title,
    ContentfulSEO,
    ContentfulRichText,
    NotFound,
    SkeletonPage,
    TitleContainerWithSocialShare,
    ProductCrossSell,
    ArchiveCrossSell,
    CardMediaWithLoading,
    ReadMore
}                               from 'components';
import {
    useContentfulPage,
}                               from 'hooks';


const ImageDescription = ({disabled = false, title, description, }) => {

    if(disabled)
        return null;

    if(!title || !description)
        return null;

    return (
        <Box sx={{mx:0.5}}>
            {
                title &&
                <Typography sx={{fontSize : '0.6rem', fontWeight:400, textAlign:'left'}}>
                    Featured Image: { title }
                </Typography>
            }
            {
                description &&
                <Typography sx={{fontSize : '0.6rem', textAlign : 'justify'}}>
                    { description }
                </Typography>
            }
        </Box>
    )
}

const obj = {};

export const ContentfulPage = ({
    children, 
    slug                                = undefined, 
    title                               = "Page", 
    bannerAspectRatio                   = "2", 
    includeCoverImageAsBanner           = true,
    includeCoverImage                   = true,
    includeImageDescription             = false, 
    includeProducts                     = true,
    includeExamples                     = false,
    container   : Container             = React.Fragment,
    skeleton    : SkeletonComponent     = SkeletonPage,
    actions                             = undefined,
    socialShare                         = true,
    socialShareProps                    = obj,
    previewOnly                         = false,
}) => {
    
    const { 
        queried, 
        loading, 
        data 
    } = useContentfulPage(slug); 

    const {
        title       : imgTitle, 
        description : imgDesc
    
    } = React.useMemo(() => (

        data?.fields?.featuredImage?.fields || {}

    // eslint-disable-next-line react-hooks/exhaustive-deps
    ),[ JSON.stringify(data?.fields?.featuredImage?.fields)])

    const mdUp = useMediaQuery(theme => theme.breakpoints.up('md'))

    if(!slug) 
        return <NotFound/>

    if(queried && !data)
        return <NotFound />

    return (
        <Box component={Container || 'div'} sx={{overflow : !includeCoverImageAsBanner ? "visible" : 'hidden'}}>
            
            <ContentfulSEO 
                data        = { data?.fields?.seoFields }
                title       = { data?.fields?.title}
                description = { data?.fields?.shortDescription}
                image       = { data?.fields?.featuredImage?.fields?.file?.url } // Fallback
            />

            {
                loading && 
                <SkeletonComponent />
            }

            {
                !loading &&
                <Grid container sx={{height:'100%'}}>
                    {
                        (includeCoverImageAsBanner || mdUp) && 
                        <Grid item xs={12} md={includeCoverImageAsBanner  ? 12 : 3} align="center" sx={{position:'relative'}}>
                            {
                                includeCoverImage && data &&
                                <Box 
                                    position = "sticky" 
                                    sx = {
                                        includeCoverImageAsBanner 
                                            ? { mx:-4, mt:-4 } 
                                            : { top : theme => theme.spacing(2)}
                                    }
                                >
                                    <CardMediaWithLoading
                                        component           = "img"
                                        src                 = {`${data?.fields?.featuredImage?.fields?.file?.url}?fm=jpg&fl=progressive`}
                                        alt                 = {data?.fields?.featuredImage?.fields?.title}
                                        {...(
                                            includeCoverImageAsBanner 
                                                ? { 
                                                    sx                  : {
                                                        aspectRatio : bannerAspectRatio
                                                    },
                                                    skeletonAspectRatio : bannerAspectRatio
                                                }
                                                : { }
                                        )}
                                        // sx                  = {{aspectRatio:"2"}}
                                        // skeletonAspectRatio = {"2"}
                                        skeletonShowLoading = { false }
                                    />

                                    {
                                        !includeCoverImageAsBanner && 
                                        <ImageDescription disabled={!includeImageDescription} title={imgTitle} description={imgDesc}/>
                                    }
                                </Box>
                            }
                        </Grid>
                    }

                    <Grid item xs={12} md={includeCoverImageAsBanner ? 12 : 9} sx={{"& > * + * " : {mt : 2}}}>
                        <Box align="left" display="flex">

                            {
                                socialShare && 
                                <TitleContainerWithSocialShare {...socialShareProps}>
                                    <Box display="flex">
                                        <Box >
                                            <Title>
                                                { data?.fields?.title || title }
                                            </Title>
                                        </Box>
                                        <Box flexGrow={1} sx={{ml:2,my:'auto'}}>
                                            { 
                                                actions && 
                                                actions()
                                            }
                                        </Box>
                                    </Box>
                                </TitleContainerWithSocialShare>
                            }
                            { 
                                !socialShare && 
                                <Title>
                                    { data?.fields?.title || title }
                                </Title>
                            }
                        </Box>


                        <ReadMore enabled={previewOnly}>
                            <Box>
                                <ContentfulRichText content={data?.fields?.body} />
                            </Box>
                            <Box sx={{zoom:0.75}}>
                                <ContentfulRichText content={data?.fields?.footnotes} />
                            </Box>
                        </ReadMore>


                        <Box>
                            {children}
                        </Box>

                        {
                            includeCoverImageAsBanner && 
                            <Box>
                                <ImageDescription disabled={!includeImageDescription} title={imgTitle} description={imgDesc}/>
                            </Box>
                        }

                        {
                            includeProducts &&
                            <ProductCrossSell quantity={2} omitInCart = {false} />
                        }

                        {
                            includeExamples &&
                            <ArchiveCrossSell />
                        }

                    </Grid>
                </Grid>
            }
        </Box>
    )
}

export default ContentfulPage;