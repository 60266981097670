
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
LibrarySideBarContext
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       5th June 2022

*******************************************************************************************/
import React                    from 'react';
import { 
    useLibrary,
    useLibraryViewer,
    useUser
}                               from '.';
import { 
    withDefault,
    useQueryParam, 
    BooleanParam,
}                               from 'use-query-params';

const   LibrarySideBarContext   = React.createContext(undefined);

const   LOCAL_STORAGE_KEY       = 'LibrarySideBarContext.libraryFilter';
const   DEFAULT_VIEW_PENDING    = false,
        DEFAULT_VIEW_PUBLIC     = true,
        DEFAULT_VIEW_PRIVATE    = true;

const LibrarySideBarProvider = ({children}) => {

    const { 
        hasPrivate, 
        hasPublic,
        // libraryPending,
        // libraryPrivate,
        // libraryPublic,
    }                                           = useLibrary();
    const { userId, isAuthenticated}            = useUser();
    const { data, /*libraryId,*/ setLibraryId } = useLibraryViewer();

    const [viewPending,     setViewPending]     = useQueryParam('pending', withDefault(BooleanParam, DEFAULT_VIEW_PENDING));
    const [viewPublic,      setViewPublic]      = useQueryParam('public',  withDefault(BooleanParam, DEFAULT_VIEW_PUBLIC));
    const [viewPrivate,     setViewPrivate]     = useQueryParam('private', withDefault(BooleanParam, DEFAULT_VIEW_PRIVATE));
    
    /*
    const validIds = React.useMemo( () => {
            return [
                ...(viewPending ? libraryPending.map(x => x.id) : []),
                ...(viewPublic  ? libraryPublic.map( x => x.id) : []),
                ...(viewPrivate ? libraryPrivate.map(x => x.id) : []),
            ].filter(Boolean);
        }, 
        [libraryPending, libraryPrivate, libraryPublic, viewPending, viewPrivate, viewPublic]
    );
    */

    const storageKey = React.useMemo(() => userId && isAuthenticated ? `${LOCAL_STORAGE_KEY}/${userId}` : LOCAL_STORAGE_KEY, [isAuthenticated, userId]);

    React.useEffect(() => {
        if(userId && isAuthenticated){
            try{
                const {
                    viewPending = DEFAULT_VIEW_PENDING, 
                    viewPublic  = DEFAULT_VIEW_PUBLIC, 
                    viewPrivate = DEFAULT_VIEW_PRIVATE,
                } = JSON.parse(localStorage.getItem(storageKey)) || {};

                setViewPending(viewPending);
                setViewPublic(viewPublic);
                setViewPrivate(viewPrivate);

            }catch(err){
                //
            }
        }
    },[isAuthenticated, setLibraryId, setViewPending, setViewPrivate, setViewPublic, storageKey, userId]);

    React.useEffect(() => {
        if(userId && isAuthenticated){
            localStorage.setItem(
                storageKey, 
                JSON.stringify({viewPending,viewPublic,viewPrivate})
            );
        }
    }, [isAuthenticated, storageKey, userId, viewPending, viewPrivate, viewPublic]) 

    // Change the view pending
    React.useEffect(() => {
        let view = !(hasPrivate || hasPublic) || (data && !data.available)
        if(view) setViewPending(view);
    },[data, hasPrivate, hasPublic, setViewPending])

    /*
    React.useEffect(() => {
        if(validIds.length){
            if(!validIds.includes(libraryId))
                setLibraryId(validIds[0]);
        }else{
            setLibraryId(undefined)
        }
    }, [libraryId, setLibraryId, validIds])
    */

    const value = React.useMemo(() => ({
        viewPending,    
        setViewPending,
        viewPublic,     
        setViewPublic,
        viewPrivate,    
        setViewPrivate
    }), [setViewPending, setViewPrivate, setViewPublic, viewPending, viewPrivate, viewPublic]);

    return (
        <LibrarySideBarContext.Provider value={value}>
            {children}
        </LibrarySideBarContext.Provider>
    )
}

// LibraryViewer Consumer
const LibrarySideBarConsumer = ({children}) => {
    return (
        <LibrarySideBarContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('LibrarySideBarConsumer must be used within LibrarySideBarProvider');
                }
                return children(context)
            }}
        </LibrarySideBarContext.Consumer>
    )
}

const useLibrarySideBar = () => {
    const context = React.useContext(LibrarySideBarContext)
    if (context === undefined)
        throw new Error('useLibrarySideBar must be used within a LibrarySideBarProvider')
    return context
}

export {
    LibrarySideBarProvider,
    LibrarySideBarConsumer,
    useLibrarySideBar
}
