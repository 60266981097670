/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Base Theme
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       4th January 2021

*******************************************************************************************/
import { createTheme, responsiveFontSizes } from '@mui/material';
import {
  CheckBoxOutlineBlank    as CheckBoxOutlineBlankIcon,
  CheckBox                as CheckBoxIcon,
  IndeterminateCheckBox   as IndeterminateCheckBoxIcon
}                                           from '@mui/icons-material';

import {green,blue}                         from '@mui/material/colors'

import "@fontsource/playfair-display" 
import "@fontsource/great-vibes"
import "@fontsource/noto-sans"
import "@fontsource/libre-baskerville"

const MUI_VARIANT   = 'standard';
const MUI_FULLWIDTH = true;

export const FONT_FAMILY_BASE       = ['-apple-system', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(', ');
export const FONT_FAMILY_FEATURE    = "Libre Baskerville"; // HistoraBeralin

// const theme = createTheme();

export const baseThemeDefinition = {
    typography: {
      h1: {
        fontFamily: `${FONT_FAMILY_FEATURE}!important`,
        // fontWeight: 800
      },
      h2: {
        fontFamily: `${FONT_FAMILY_FEATURE}!important`,
        // fontWeight: 700
      },
      h3: {
        fontFamily: `${FONT_FAMILY_FEATURE}!important`,
        // fontWeight: 600
      },
      h4: {
        fontFamily: `${FONT_FAMILY_FEATURE}!important`,
        // fontWeight: 500
      },
      h5: {
        fontFamily: `${FONT_FAMILY_FEATURE}!important`,
        // fontWeight: 400
      },
      h6: {
        fontFamily: `${FONT_FAMILY_FEATURE}!important`,
        // fontWeight: 300
      },
      fontFamily        : FONT_FAMILY_BASE,  
      fontSize          : 14,
      fontWeightLight   : 200,
      fontWeightRegular : 300,
      fontWeightMedium  : 400,
    },
    components: {
      MuiButton : {
        styleOverrides : {
          label : {
          },
        }
      },
      MuiInputBase : {
        styleOverrides : {
          inputMultiline : {
            textAlign : 'justify'
          }
        }
      },
      MuiStepIcon : {
        styleOverrides : {
          "root": {
            width : 30,
            height: 30,
            "&.Mui-completed": {
              "color": [green[100],'!important'],
              "&:hover": {
                "color": [green[200],'!important'],
              }
            }
          },
          "&.Mui-active": {
            "color": [blue[400],'!important']
          },
          text : {
            // fontSize : '1rem'
          }
        }
      },
      MuiStepConnector : {
        styleOverrides : {
          alternativeLabel : {
            top : 15
          }
        }
      },
      MuiDialog: {
        styleOverrides : {
          paperFullScreen : {
            // margin                  : '16px!important',
            // height                  : `calc(100% + 16px)`,
            // transform               : 'translatey(-8px)',
            borderBottomLeftRadius  : 0*10,
            borderBottomRightRadius : 0*10
          }
        }
      },
      MuiGrid : {
        defaultProps : {
          spacing : 2
        }
      },
      MuiTextField : {
        defaultProps : {
          variant   : MUI_VARIANT,
          fullWidth : MUI_FULLWIDTH,
          InputLabelProps : {
            shrink  : true 
          },
        }
      },
      MuiFormControl : {
        styleOverrides : {
          root : {
            marginTop : 5
          },
        },
        defaultProps : {
          variant   : MUI_VARIANT,
          fullWidth : MUI_FULLWIDTH
        }
      },
      MuiCheckbox : {
        styleOverrides : {
          root : {
            fontSize : 16
          },
        },
        defaultProps : {
          icon                : <CheckBoxOutlineBlankIcon />,
          checkedIcon         : <CheckBoxIcon />,
          indeterminateIcon   : <IndeterminateCheckBoxIcon />,
        }
      },
      MuiPaper : {
        styleOverrides : {
          root : {
            // border      : `1px solid ${theme.palette.divider}`,
          },
        },
        defaultProps : {
          elevation : 0,
          square    : true,
        }
      },
      MuiCard : {
        defaultProps : {
          elevation : 0,
          square    : true
        }
      },
      MuiDateTimePickerTabs : {
        styleOverrides : {
          root : {
            // paddingTop  : 16,
            minWidth    :'unset'
          }
        }
      },
      MuiPickersToolbar : {
        styleOverrides : {
          content : {
            margin  : 8,
            width   : `calc(100% - 16px)`
          }
        }
      },
      MuiCalendarOrClockPicker : {
        styleOverrides : {
          root : {
            // flexDirection: "column-reverse"
          }
        }
      },
      MuiTabs : {
        styleOverrides : {
          scrollableX : {
            marginLeft    : -16,
            marginRight   : -16,
          },
          flexContainer : {
            ':not(.MuiTabs-flexContainerVertical)' : {
              paddingLeft   :  16,
              paddingRight  :  16,
              '& > *' : {
                // borderTopRightRadius        : `8px!important`,
                // borderTopLeftRadius         : `8px!important`,
                // marginRight                 : `8px!important`,
              }
            }
          },
          flexContainerVertical : {
            '& > *' : {
              // borderTopRightRadius        : `8px!important`,
              // borderBottomRightRadius     : `8px!important`,
              // marginBottom                : `8px!important`,
            }
          },
        }
      },
      MuiTab : {
        styleOverrides : { 
          root: {
            minWidth                : 125,
            '&.Mui-selected': {
              fontWeight              : 800
            },
          },
        }
      }
    },
};

export const baseTheme = responsiveFontSizes(createTheme(baseThemeDefinition));
// export const baseTheme = createTheme(baseThemeDefinition);

export default baseTheme;