/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Cart Component
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       29th June 2021

*******************************************************************************************/
import React                            from 'react';
import { useLocation }                  from 'react-router-dom';
import { v4 as uuidv4 }                 from 'uuid';
import { 
    styled,
    alpha,
    useTheme, 
    useMediaQuery, 
    Box, 
    Grid, 
    Paper,
    Typography, 
    Tooltip,
    Table,
    TableContainer,
    TableBody,
    TableRow,
    TableCell as TableCellMUI,
    TableHead
}                                       from '@mui/material';
import WarningIconMUI                   from '@mui/icons-material/Warning';
import { 
    RefreshIcon,
    RemoveWithConfirmationButton as RemoveButton,
    Price,
    CartIconWithQuantity,
    CartQuantityModifyer,
    ViewProductButton,
    Logo
}                                       from 'components';
import { withTranslation }              from './hoc';
import { PulseChip }                    from 'components/pulse';
import {
    useCart,
    useNetwork,
    useProduct
}                                       from 'contexts';
import { useImageCDN }                  from 'hooks';

const ImageContainerLarge = styled(Box)(({theme}) => ({
    position    : 'relative',
    textAlign   : 'center',
    aspectRatio : '1.41',
    overflow    : 'hidden',
}));

const ImageContainerSmall = styled(Box)(({theme}) => ({
    position    : 'relative',
    minWidth    : 100,
    maxWidth    : 100,
    textAlign   : 'center'
}));

const Image = styled('img')({
    width       : '100%',
    height      : '100%',
    objectFit   : 'contain'
});

const Wrapper = styled(Paper, {
    shouldForwardProp : prop => prop !== 'deleted' && prop !== 'highlighted' && prop !== 'pulse'
})(({theme, deleted = false, highlighted = false, pulse=false}) => ({
    padding : theme.spacing(2),
    ...(highlighted && {
        border      : [`1px solid ${theme.palette.secondary.main}`,'!important'],
        background  : alpha(theme.palette.secondary.light,0.20),
        margin      : -1,
    }),
    ...(deleted && {
        border      : [`1px solid ${theme.palette.error.main}`,'!important'],
        background  : [alpha(theme.palette.error.light,0.20),'!important'],
        margin      : -1,
    }),
    ...(pulse && {
        animation: `pulse 500ms infinite`,
        "@keyframes pulse": {
            "0%" : {
                transform: 'scale(1)',
                boxShadow: `0 0 0 0 ${alpha(theme.palette.primary.dark,0.7)}`
            },
            "100%" : {
                transform: 'scale(1)',
                boxShadow: `0 0 0 3px ${alpha(theme.palette.primary.dark,0.25)}`
            }
        },
    })
}));

const TableCell = styled(TableCellMUI,{
    shouldForwardProp : prop => prop !== 'description' && prop !== 'lastRow' && prop !== 'unit'
})(({theme,description = false, lastRow = false, unit=false}) => ({
    padding     : theme.spacing(0.5),
    fontSize    : '0.65rem',
    [theme.breakpoints.down('xl')] : {
        fontSize : '0.75rem'
    },
    [theme.breakpoints.down('lg')] : {
        fontSize : '0.65rem'
    },
    [theme.breakpoints.down('md')] : {
        fontSize : '0.60rem'
    },
    [theme.breakpoints.down('sm')] : {
        fontSize : '0.60rem'
    },
    ...(description && {
        whiteSpace:'nowrap',minWidth:100,maxWidth:200,textOverflow:'ellipsis',overflow:'hidden'
    }),
    ...(lastRow && {
        borderBottom : 'none'
    }),
    ...(unit && {
        color       : theme.palette.text.secondary,
        fontSize    : '1em',
    })
}));

const Title = styled(Typography)(({theme}) => ({
    fontSize        : '1.5em',
    whiteSpace      : 'nowrap',
    overflow        : 'hidden',
    textOverflow    : 'ellipsis',
    width           : '100%',
    paddingRight    : theme.spacing(1)
}))

const WarningIcon = styled(WarningIconMUI)(({theme}) => ({
    fontSize        : '1rem',
    color           : theme.palette.warning.main,
    marginTop       : -5,
    marginBottom    : -5
}));

const QuantityContainer = styled(Box)(({theme}) => ({
    border          : `1px solid transparent`,
    display         : "flex",
    textAlign       : "right",
    padding         : theme.spacing(2)
}));

const DescriptionContainer = styled(Box)(({theme}) => ({
    display                 : "-webkit-box!important",
    "-webkit-line-clamp"    : '3',
    "-webkit-box-orient"    : "vertical",
    wordBreak               : "break-all",
    overflow                : "hidden!important"
}));

const ChipDelete = (props) => (
    <PulseChip color="secondary" size="small" {...props}/>
);

const Money  = ({value, unit=null, sale=false, prefix=undefined, ...props}) => (
    <Price unit={unit} prefix={prefix} sale={sale} price={value} {...props} />
);

const ProductOrCouponImage = ({__t, convert, coverImage, productId}) => {
    const theme = useTheme();
    switch(__t){
        case("PRODUCT"):
            return (
                <Image 
                    src = { convert(coverImage, {operation:'width', width:250}) } 
                    alt = {`product ${productId}`} 
                />
            )
        case("COUPON"):
            return (
                <Logo 
                    width       = "100%" 
                    sx          = {{ maxWidth : 150 }} 
                    maxHeight   = {null} 
                    to          = {null}
                    minWidth    = {0} 
                    dark        = {theme.palette.mode === 'light'}
                />
            )
        default:
            return null
    }
}

const Quantity = withTranslation( ({t, deleted, handleChange, productId, quantity, readOnly, working, ...props}) => {
    if(!productId)
        return null;
    return (
        <Box display="flex" flexShrink={1} {...props} style={{marginTop:'auto'}}>
            <Box flexShrink={1}>
                <Typography style={{margin:'auto'}}>
                    {t('components.cartItem.changeQuantity')}
                </Typography>
            </Box>
            <Box flexShrink={1} style={{transform:'translatey(-2px)'}} pl={1}>
                <CartQuantityModifyer 
                    title       = {null} 
                    disabled    = {working} 
                    readOnly    = {readOnly || deleted} 
                    quantity    = {quantity} 
                    onClick     = {handleChange}
                />
            </Box>
        </Box>
    )
});

export const CartItem = withTranslation( ({
    t,
    item                    = undefined,
    readOnly : readOnlyIn   = false,
    showBrowse              = true
}) => {
    const {
        working,
        addProductToCart, 
        removeProductFromCart,
        removeCoupon
    }                                               = useCart();
    const {isNetworkReady}                          = useNetwork();
    const {hash}                                    = useLocation();
    const convert                                   = useImageCDN();
    const {
        DEFAULT_PRODUCT_IMAGE,
        contentful : contentfulCollection
    }                                               = useProduct();
    const [deleting, setDeleting]                   = React.useState();
    const [changingQuantity, setChangingQuantity]   = React.useState(false);

    const { 
        __t                 = undefined,
        product : {
            _id : productId = undefined,
            slug,
            cover           = undefined,
            images          = [],
            deleted         = false
        } = {},
        currency    = undefined,
        taxCode     = undefined,
        taxRate     = 0.0,
        quantity    = 1,
        split       = [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }                       = React.useMemo(() => item || {}, [JSON.stringify(item || {})]) 

    const data = React.useMemo(() => (
            slug 
                ? contentfulCollection.find(x => x?.fields?.slug === slug)
                : undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ),[JSON.stringify(contentfulCollection), slug])

    const name              = __t === 'COUPON' ? `Coupon` : (data?.fields?.name || item?.product?.name || item?.description || t('common.productName'))
    const description       = data?.fields?.abstract || item?.product?.description || item?.description || t('common.description')
    const readOnly          = readOnlyIn || !productId || !isNetworkReady;
    const highlight         = hash && hash.replace('#','') === productId;
    const disableDelete     = !productId && __t === 'PRODUCT';

    const handleRemove      = React.useCallback( async () => {
        setDeleting(true);
        await new Promise(resolve => setTimeout(resolve,500));
        switch(__t){
            case('PRODUCT'):
                await removeProductFromCart({productId});
                break;
            case('COUPON'):
                await removeCoupon();
                break;
            default:
                break;
        }
        setDeleting(false);
    }, [__t, productId, removeCoupon, removeProductFromCart]);

    React.useEffect(() => (
        setDeleting(false)
    ), [productId, setDeleting])

    const handleChange = React.useCallback( (value) => new Promise((resolve,reject) => {
        setChangingQuantity(true)
        Promise
            .resolve()
            .then(() => addProductToCart({productId, quantity : value}))
            .then(resolve)
            .catch(reject)
            .finally(() => {
                setChangingQuantity(false);
            })
    }), [addProductToCart, productId]);

    const mdUp = useMediaQuery(theme => theme.breakpoints.up('md'));
    const lgUp = useMediaQuery(theme => theme.breakpoints.up('lg'));

    const coverImage = React.useMemo(() => (
        cover || images[0] || DEFAULT_PRODUCT_IMAGE
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [cover, JSON.stringify(images)])

    if(!item)
        return null;

    return (
        <Box width="100%" id={`#${productId || uuidv4()}`} >
            <Wrapper deleted={deleted || deleting} highlighted={highlight} pulse={highlight}>
                <Grid container >
                    {
                        lgUp &&
                        <Grid item xs={2} lg={3} >
                            <ImageContainerLarge id="imageLarge" >
                                <ProductOrCouponImage {...{__t, convert, coverImage, productId}} />
                            </ImageContainerLarge>
                        </Grid>
                    }
                    <Grid item xs={12} lg={9}>

                        <Box display="flex">

                            {
                                !lgUp &&
                                <ImageContainerSmall id="imageSmall" mx={'auto'} pt={1}>
                                    <ProductOrCouponImage {...{__t, convert, coverImage, productId}} />
                                </ImageContainerSmall>
                            } 

                            <Box flexGrow={1} ml={2}>
                                <Box display="flex">
                                    <Box flexShrink={1}>
                                        <Title>
                                            {name}
                                        </Title>
                                    </Box>
                                    {
                                        quantity > 0 &&
                                        <Box sx={{ m:'auto', px:1 }}>
                                            <CartIconWithQuantity quantity={quantity}/>
                                        </Box>
                                    }
                                    <Box flexGrow={1}>
                                        {   
                                            deleted && 
                                            <ChipDelete label={t('common.productUnavailable')} />
                                        }
                                        {   
                                            (!deleted && deleting) && 
                                            <ChipDelete label={t('common.deletingItem')}/>
                                        } 
                                    </Box>
                                    <Box flexGrow={1}/>
                                    <Box flexShrink={1} sx={{display:'inherit',"& > * + *" : { ml:1}}}>
                                        {
                                            mdUp && showBrowse && !deleted && __t === 'PRODUCT' && 
                                            <ViewProductButton productId={productId} disabled={readOnly} size="small" >
                                                {t('common.browse')}
                                            </ViewProductButton>
                                        }
                                        {
                                            !disableDelete &&
                                            <RemoveButton size="small" color="error" disabled={readOnlyIn || !isNetworkReady || working} onClick={handleRemove}>
                                                {t('common.remove')}
                                            </RemoveButton>
                                        }
                                    </Box>
                                </Box>
                                <DescriptionContainer pt={2}>
                                    <Typography variant="body2">
                                        {
                                            description
                                        }
                                    </Typography>
                                </DescriptionContainer>
                            </Box>
                        </Box>

                        <Box>

                            <Box display="flex" mt={2}>
                                <Box flexGrow={1}/>
                                <QuantityContainer>
                                    <Box flexGrow={1}/>
                                    {
                                        changingQuantity &&
                                        <Box style={{whiteSpace: 'nowrap'}}>
                                            <RefreshIcon color="secondary" loading={true}/>
                                        </Box>
                                    }
                                    {
                                        isNetworkReady && 
                                        <Quantity {...{deleted, handleChange, productId, quantity, readOnly, working}} pl={1}/>
                                    }
                                </QuantityContainer>
                            </Box>

                            <Box display="flex" mt={2}>
                                <Box flexGrow={1}/>
                                <Box>
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell />
                                                    <TableCell unit={true}>
                                                        {t('common.description')}
                                                    </TableCell>
                                                    <TableCell unit={true} align="center">
                                                        {t('common.ccy')}
                                                    </TableCell>
                                                    <TableCell unit={true} align="center">
                                                        {t('common.qty')}
                                                    </TableCell>
                                                    <TableCell unit={true} align="right" style={{minWidth:50}}>
                                                        {t('common.price')}
                                                    </TableCell>
                                                    <TableCell unit={true} align="right">
                                                        {t('common.amount')}
                                                    </TableCell>
                                                    <TableCell unit={true} align="center" colSpan={2}>
                                                        {t('common.tax')}
                                                    </TableCell>
                                                    <TableCell unit={true} align="right">
                                                        {t('common.subtotal')}
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    split.map(({description,quantity,price,subtotal,taxes,total,_id:_sid,metadata={}},ix) => (
                                                        <TableRow key={ix}>
                                                            <TableCell>
                                                                {ix+1}
                                                            </TableCell>
                                                            <TableCell description={true}>
                                                                <Box display="flex">
                                                                    <Box flexGrow={1}>
                                                                        {description}
                                                                    </Box>
                                                                    {
                                                                        metadata.error &&
                                                                        <Box flexShrink={1}>
                                                                            <Tooltip title={metadata.error}>
                                                                                <WarningIcon />
                                                                            </Tooltip>
                                                                        </Box>
                                                                    }
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {currency}
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {quantity}x
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <Money value={price} />
                                                            </TableCell>
                                                            <TableCell align="right" >
                                                                <Money value={subtotal} />
                                                            </TableCell>
                                                            <TableCell align="right" style={{whiteSpace:'nowrap'}}>
                                                                {taxCode} ({(100*taxRate).toFixed(0)}%)
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <Money value={taxes} />
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <Money value={total} />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                }
                                                <TableRow>
                                                    <TableCell lastRow={true}/>
                                                    <TableCell lastRow={true}>
                                                        {t('common.total')}
                                                    </TableCell>
                                                    <TableCell lastRow={true} align="center">
                                                        {currency}
                                                    </TableCell>
                                                    <TableCell lastRow={true} align="center">
                                                        {quantity}x
                                                    </TableCell>
                                                    <TableCell lastRow={true} align="right">
                                                        <Money value={split.reduce((acc,cur) => acc + cur.subtotal,0)/quantity} />
                                                    </TableCell>
                                                    <TableCell lastRow={true} align="right">
                                                        <Money value={split.reduce((acc,cur) => acc + cur.subtotal,0)} />
                                                    </TableCell>
                                                    <TableCell lastRow={true} align="right" colSpan={2}>
                                                        <Money value={split.reduce((acc,cur) => acc + cur.taxes,0)} />
                                                    </TableCell>
                                                    <TableCell lastRow={true} align="right">
                                                        <Money value={split.reduce((acc,cur) => acc + cur.total,0)} />
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Wrapper>
        </Box>
    );
})

export default CartItem;