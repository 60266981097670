/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Contentful Rich Text
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       18th April 2023

*******************************************************************************************/
import React                    from 'react';
import {Link}                   from 'react-router-dom';
import {
    Box,
    styled,
    Typography,
    CardMedia,
    TableContainer,
    Table,
    TableRow,
    TableCell
}                               from '@mui/material';
import {
    JSONViewer,
    Quote,
    HoroscopeComponent,
    Radix,
    SkeletonRadix
}                               from 'components';
import { 
    documentToReactComponents 
}                               from "@contentful/rich-text-react-renderer";
import { 
    documentToPlainTextString 
}                               from '@contentful/rich-text-plain-text-renderer';
import { BLOCKS, INLINES }      from "@contentful/rich-text-types";


const withParaSpacing = (Component) => styled(Component)(({theme}) => ({
    marginBottom : theme.spacing(2),
    ":last-child" : {
        marginBottom : 0
    }
}));

const Paragraph = withParaSpacing(Typography);
const ListItem  = withParaSpacing(Box);
const List      = styled(Box)(({theme}) => ({
    color : theme.palette.text.secondary
}));

const renderOptions = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Paragraph sx={{textAlign:'justify'}}>
                    {children}
                </Paragraph>
            )
        },
        [BLOCKS.HEADING_1]: (node, children) => {
            return (
                <Typography variant="h1" gutterBottom>
                    {children}
                </Typography>
            )
        },
        [BLOCKS.HEADING_2]: (node, children) => {
            return (
                <Typography variant="h2" gutterBottom>
                    {children}
                </Typography>
            )
        },
        [BLOCKS.HEADING_3]: (node, children) => {
            return (
                <Typography variant="h3" gutterBottom>
                    {children}
                </Typography>
            )
        },
        [BLOCKS.HEADING_4]: (node, children) => {
            return (
                <Typography variant="h4" gutterBottom>
                    {children}
                </Typography>
            )
        },
        [BLOCKS.HEADING_5]: (node, children) => {
            return (
                <Typography variant="h5" gutterBottom>
                    {children}
                </Typography>
            )
        },
        [BLOCKS.HEADING_6]: (node, children) => {
            return (
                <Typography variant="h6" gutterBottom>
                    {children}
                </Typography>
            )
        },
        [BLOCKS.OL_LIST]: (node, children)      => <List component="ol">{children}</List>,
        [BLOCKS.UL_LIST]: (node, children)      => <List component="ul">{children}</List>,
        [BLOCKS.LIST_ITEM]: (node, children)    => <ListItem component = "li"> {children} </ListItem>,
        [BLOCKS.HR]: (node, children) => {
            return (
                <hr/>
            )
        },
        [BLOCKS.QUOTE]: (node, children) => {
            return (
                <Quote sx={{my:4}}>
                    <Typography color="inherit" align="justify">
                        {children}
                    </Typography>
                </Quote>
            )
        },
        [BLOCKS.TABLE]: (node, children) => {
            return (
                <TableContainer>
                    <Table>
                        {children}
                    </Table>
                </TableContainer>
            )
        },
        [BLOCKS.TABLE_ROW]: (node, children) => {
            return (
                <TableRow>
                    {children}
                </TableRow>
            )
        },
        [BLOCKS.TABLE_CELL]: (node, children) => {
            return (
                <TableCell>
                    {children}
                </TableCell>
            )
        },
        [BLOCKS.TABLE_HEADER_CELL]: (node, children) => {
            return (
                <TableCell sx={{fontWeight:400}}>
                    {children}
                </TableCell>
            )
        },
        [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
            
            // target the contentType of the EMBEDDED_ENTRY to display as you need
            if (node?.data?.target?.sys?.contentType?.sys?.id === "codeBlock") {
                return (
                    <pre>
                        <code>
                            {node.data.target.fields.code}
                        </code>
                    </pre>
                );
            }
    
            if (node?.data?.target?.sys?.contentType?.sys?.id === "videoEmbed") {
                return (
                    <iframe
                        src             = {node.data.target.fields.embedUrl}
                        title           = {node.data.target.fields.title}
                        height          = "100%"
                        width           = "100%"
                        frameBorder     = "0"
                        scrolling       = "no"
                        allowFullScreen = {true}
                    />
                );
            }

            // Natal Data Component
            if(node?.data?.target?.sys?.contentType?.sys?.id === 'componentNatalData'){
                const { location : {lat, lon}, birthDateTime, localTime = true} = node?.data?.target?.fields;
                return (
                    <Box align="center" display="flex" width="100%">
                        <Box sx={{mx:'auto'}}>
                            <HoroscopeComponent 
                                birthDateTime   = { (birthDateTime || '').split('+').shift() } // Strip timezone
                                lat             = { lat } 
                                lng             = { lon } 
                                localTime       = { localTime }
                                houseSystem     = { "auto" } 
                                zodiac          = { "tropical" } 
                                render          = { ({horoscopeData,working,queried}) => (
                                    <>
                                        {
                                            (!queried || working) &&
                                            <SkeletonRadix width={400} showText={false}/>
                                        }
                                        {
                                            queried && !working &&
                                            <Radix width = {400} data = {horoscopeData} />
                                        }
                                    </>
                            )}/>
                        </Box>
                        {
                            false &&
                            <JSONViewer src={node?.data?.target} />
                        }
                    </Box>
                )  
            }
            
            if(node?.data?.target?.sys?.contentType?.sys?.id === 'componentRichImage'){
                const fullWidth = node?.data?.target?.fields?.fullWidth;
                return (
                    <Box display="flex" flexDirection="column" width={fullWidth ? '100%' : '75%'} sx={{my:4, mx : 'auto'}}>
                        {
                            false &&
                            <JSONViewer src={node?.data?.target} />
                        }
                        <CardMedia 
                            src         = {`${node.data.target.fields.image.fields.file.url}?fm=jpg&fl=progressive`}
                            alt         = {node.data.target.fields.image.fields.fileName}
                            component   = "img"
                            sx          = {{ width : '100%' }}
                        />

                        {
                            node?.data?.target?.fields?.caption &&
                            <Typography align="center" variant="subtitle1" gutterBottom color="text.secondary">
                                {node?.data?.target?.fields?.caption}
                            </Typography>
                        }

                    </Box>
                )
            }   
                   
        },
    
        [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
            // render the EMBEDDED_ASSET as you need
            return (
                <CardMedia 
                    src         = {`https://${node.data.target.fields.file.url}?fm=jpg&fl=progressive`}
                    alt         = {node.data.target.fields.description}
                    component   = "img"
                    sx          = {{ width : '100%' }}
                    // height  = {node.data.target.fields.file.details.image.height}
                    // width   = {node.data.target.fields.file.details.image.width}
                />
            );
        },

        [INLINES.EMBEDDED_ENTRY]: (node, children) => {
            // target the contentType of the EMBEDDED_ENTRY to display as you need
            if (node?.data?.target?.sys?.contentType?.sys?.id === "pageBlogPost") {
                return (
                    <Link to={`/blog/${node.data.target.fields.slug}`} style={{ textDecoration: 'none' }}>            
                        {node.data.target.fields.title}
                    </Link>
                );
            }
        }
    },
};


export const ContentfulRichText = ({content, plainText = false, ...props}) => {
    // console.log(content)
    // console.log(documentToReactComponents(content, renderOptions))
    if(!content)
        return
    return (
        <Box {...props}>
            { 
                plainText 
                    ? documentToPlainTextString(content) 
                    : documentToReactComponents(content, renderOptions) 
            }
        </Box>
    )
}

export default ContentfulRichText;
  