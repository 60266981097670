/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Videos
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       1st June 2022

*******************************************************************************************/
import React                from 'react';
import { 
    styled, 
    Box,
    useTheme,
    useMediaQuery
}                           from '@mui/material';
import Video                from 'components/Video';
import { useIsTouchScreen } from 'hooks';
import constants            from '../constants';

const GifContainer = styled(Box)(({theme}) => ({
    display         : 'flex',
    alignItems      : 'center',
    justifyContent  : 'center',
    position        : 'relative',
    height          : '100%',
    width           : '100%',
    background      : 'black',
    overflow        : 'hidden',
    padding         : theme.spacing(0)
}))

const VideoContainer = styled(Box)(({theme})=>({
    height          : '100%',
    overflow        : 'hidden',
    background      : 'black'
}))

export const Videos = ({src = undefined, preview = undefined}) => {
    const isTouchScreen         = useIsTouchScreen();
    const theme                 = useTheme();
    const matches               = useMediaQuery(theme.breakpoints.up('sm'));
    const [playing, setPlaying] = React.useState(false);
    const handlePlay            = React.useCallback((src) => setPlaying(src || true), []);

    if(!src && !preview)
        return null;

    return (
        <Box id={constants.ids.videos} sx={{minHeight:650,height:'100%'}}>
            
            {
                preview && (isTouchScreen || !playing || !matches) && 
                <GifContainer>
                    <img 
                        src         = {preview} 
                        alt         = ""
                        style       = {{
                            zIndex          : 1,
                            float           : 'left',
                            // minWidth        : '60%',
                            // maxWidth        : '100%'
                        }}
                    />
                </GifContainer> 
            }

            {   
                src && !isTouchScreen && matches &&
                <VideoContainer>
                    <Box
                        style   = {{
                            zIndex          : 2,
                            // transform       : 'translatex(350px)',
                            float           : 'right',
                            minHeight       : '100%',
                            minWidth        : "100%"
                        }}
                    >
                        <Video 
                            onPlay  = {handlePlay}
                            sources = {[src]}
                        />
                    </Box>
                </VideoContainer>
            }
            
        </Box>
    )
}

export default Videos;