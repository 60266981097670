/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Comments Component
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       1st May 2022

*******************************************************************************************/
import React                            from 'react';
import { useTheme, Box, IconButton }    from '@mui/material';
import OpenInNewIcon                    from '@mui/icons-material/OpenInNew';
import { DraggableDialog }              from 'components'
import { useTranslation }               from 'contexts';
import {Comments}                       from './Comments'

const noop              = () => {};
const obj               = {}

export const CommentsDetachButton = ({
    disabled    = false, 
    parentId    = undefined, 
    Icon        = OpenInNewIcon, 
    onClick     = noop, 
    onOpen      = noop, 
    onClose     = noop, 
    onChange    = noop, 
    dialogProps = obj, 
    iconProps   = {}, 
    ...other
}) => {

    const {render, ...props} = other;

    const {t}                           = useTranslation();

    // theme/classes
    const theme                         = useTheme();
    
    // Open modal or not
    const [open, setOpen]               = React.useState(false);

    // the Child Count
    const [childCount, setChildCount]   = React.useState(0);

    // Force Close
    const handleClose                   = React.useCallback( () => setOpen(false), []);

    // Toggle Open/Close
    const handleOpenToggle              = React.useCallback( () => setOpen(prev => !prev), [])
    
    // Click Handler
    const handleClick                   = React.useCallback( (e) => {
        handleOpenToggle();
        onClick(e);
    },[handleOpenToggle, onClick]);

    // Count Change
    const handleChange                  = React.useCallback( ({childCount, ...rest}) => {
        onChange({childCount,...rest})
        setChildCount(childCount)
    }, [onChange]);

    // Open/Close Event
    React.useEffect(() => {
        if(open) {
            onOpen();
         } else {
            onClose();
         }
    }, [onClose, onOpen, open])

    // Deconstruct Props
    const {style, ...restIconProps} = iconProps;

    // No Parent Id
    if(!parentId)
        return null;

    // Done
    return (
        <Box width="100%">
            <Box align="center">
                <IconButton disabled={disabled} color={theme.palette.mode === 'light' ? 'primary' : 'secondary'} size="small" {...props} onClick={handleClick} >
                    <Icon 
                        size    = "small" 
                        style   = {{
                            fontSize    : 14, 
                            ...style, 
                            transform   : open ? 'rotate(180deg)' : 'rotate(0deg)'
                        }} 
                        {...restIconProps}
                    />
                </IconButton>
            </Box>
            
            {
                open && !render && 
                <DraggableDialog
                    title                   = { t('components.comments.commentsCount', {count : childCount || 0 })}
                    open                    = {open}
                    fullScreen              = {!true}
                    sticky                  = {!true}
                    showButtons             = {false}
                    {...dialogProps}
                    onOk                    = {handleClose}
                    onClose                 = {handleClose}
                    onCancel                = {handleClose}
                    fullWidth               = {true}
                    disableBackdropClick    = {true}
                >
                    <div style={{width:'100%',maxWidth:'90vw'}}>
                        <Comments 
                            parentId    = {parentId} 
                            master      = {false} 
                            onChange    = {handleChange}
                        />
                    </div>
                </DraggableDialog>
            }

            {
                open && render && 
                render({
                    open,
                    parentId, 
                    handleChange, 
                    handleOpenToggle, 
                    handleClose
                }) 
            }
        </Box>
    )
}

export default CommentsDetachButton;