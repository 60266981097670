
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Copyright
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       9th Jan 2021

*******************************************************************************************/
import React                            from 'react';
import moment                           from 'moment';
import config                           from '../config';

const START_YEAR = 2020;

export const Copyright = ({to = '/', dark=true, ...rest}) => {
    const thisYear = moment().year()
    const year = thisYear !== START_YEAR ? `${START_YEAR} - ${thisYear}` : thisYear;
    return (
        <React.Fragment>
            © {year}, {config.company} / {config.website}
        </React.Fragment>
    )
}

export default Copyright;