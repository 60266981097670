/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
useImageCDN Hook
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       23rd March 2023

*******************************************************************************************/
import React            from 'react';
import crypto           from 'crypto';
import { ImageBoss }    from 'imageboss-js'; // typescript or es6
import useS3Parser      from './useS3Parser';
import logo_light       from 'resources/logos/logo_light_dot.png';
import logo_dark        from 'resources/logos/logo_dark_dot.png';
import config           from '../config';

const {
    production : PRODUCTION,
    imageBoss : {
        token : SECRET
    }
} = config;

const obj = {};

const VALID_BUCKETS = [
    'cosmicprogram-development-dropbox',
    'cosmicprogram-production-dropbox',
    'cosmicprogram-production-website',
    'cosmicprogram-development-website',
    'cosmicprogram-development-assets',
    'cosmicprogram-production-assets'
];

const STATIC_MEDIA_SOURCE = 'cosmicprogram-static-media';

function extractPathname(urlOrPath) {
    try {
        const parsedURL = new URL(urlOrPath);
        return parsedURL.pathname;
    } catch (error) {
        // If it's not a valid URL, assume it's a relative path
        return urlOrPath;
    }
}
  
const LOGO_LIGHT_PATH           = extractPathname(logo_light);
const LOGO_DARK_PATH            = extractPathname(logo_dark);
const WATERMARK_SOURCE          = STATIC_MEDIA_SOURCE;
const [
    WATERMARK_LIGHT_PATH, 
    WATERMARK_DARK_PATH
]                               = [LOGO_LIGHT_PATH,LOGO_DARK_PATH].map(p => encodeURIComponent(p.replace(/^\/static\/media/i,"")))

// Function to build options with watermark
export const watermarkOption    = (width = 100,light = true) => (
    `wmk-position:northeast,wmk-width:${width},wmk-pad:8,wmk-source:${WATERMARK_SOURCE},wmk-path:${light ? WATERMARK_LIGHT_PATH : WATERMARK_DARK_PATH}`
);

export const useImageCDN = (productionOnly = false, force=false) => {
    const parseS3       = useS3Parser();
    const disabled      = React.useMemo(() => productionOnly && !PRODUCTION, [productionOnly]);
    const convert       = React.useCallback( (src, args = obj) => {

        // Disabled or no src provided
        if(disabled || !src)
            return src;
        
        try {

            // Vars
            let source, 
                imagePath;

            // Static Files
            if(src.startsWith('/static/media')){

                imagePath   = src.replace(/^\/static\/media/i,"");  // Unchanged
                source      = STATIC_MEDIA_SOURCE;                  // Static Source

                if(!PRODUCTION && !force)
                    return src;
                
            } else if(src.startsWith('/static')){
                imagePath   = src;                                  // Unchanged
                source      = 'static';                             // Static Source

                if(!PRODUCTION && !force)
                    return src;
            
            // Valid Bucket  
            } else if(VALID_BUCKETS.map(bucket => src.includes(bucket)).some(Boolean)){ 

                // Deconstruct S3 Bucket Properties
                ({bucket : source, key : imagePath} = parseS3(src));
                
                // ensure leading slash
                if(!imagePath.startsWith('/'))
                    imagePath = `/${imagePath}`;

            // Not Valid
            } else {
                // console.error(`Possible invalid src to convert: (${src}}`);
                return src;
            }

            // Convert using image boss CDN
            // Docs:
            //  https://imageboss.me/docs
            //  https://www.npmjs.com/package/imageboss-js
            const converted = ImageBoss.getURL(
                imagePath, 
                {
                    source      : source ,
                    operation   : 'cdn',    // Default
                    ...args
                }
            )

            // If Signed Required
            const signed = Boolean(SECRET);
            if(signed){
                const hmac      = crypto.createHmac('sha256',SECRET);
                const imagePath = new URL(converted).pathname;
                const bossToken = hmac.update(imagePath).digest('hex');
                return `${converted}?bossToken=${bossToken}`;
            }

            // Done
            return converted;

        // Handle silently
        } catch (err) {
            //
        }

        // Fallback
        return src;
        
    },[disabled, force, parseS3]);

    // Done
    return convert;
}

export default useImageCDN;