/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Shop Component
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       29th June 2021

*******************************************************************************************/
import React                        from 'react';
import {isNil}                      from 'lodash';
import { 
    Box, 
    Grid, 
    Typography 
}                                   from '@mui/material';
import SkeletonProductCard          from 'components/skeletons/SkeletonProductCard';
import { 
    Title, 
    ProductCard,
    SpaceBox
}                                   from 'components'
import {useProduct,useNetwork}      from 'contexts';

const obj = {};

export const Shop = ({
    title       = undefined, 
    TitleProps  = obj
}) => {

    const {isOnline}                = useNetwork();
    const { queried, data }         = useProduct();

    
    const products                  = React.useMemo(() => (
        
        data
            .filter(x => !x.deleted && (x.listed || isNil(x.listed)))
            .sort((a,b) => b.price - a.price)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [JSON.stringify(data)])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const hasProducts               = React.useMemo(() => Boolean(products.length), [JSON.stringify(products)]);

    return (
        <Box width="100%">
            {
                title &&
                <Title gutterBottom {...TitleProps}>
                    {title}
                </Title>
            }
            <Grid container>
                {
                    !isOnline && !hasProducts &&
                    <Grid item xs={12}>
                        <Typography align="center" color="textSecondary">
                            Waiting for Network
                        </Typography>
                    </Grid>
                }
                {
                    isOnline && queried && !hasProducts && 
                    <Grid item xs={12}>
                        <SpaceBox spacing={10}>
                            <Typography align="center" color="textSecondary">
                                There are no products available at the present time
                            </Typography>
                        </SpaceBox>
                    </Grid>
                }
                {   isOnline && !queried && 
                    Array(6).fill(undefined).map((_,ix) => (
                        <Grid key={ix} item xs={12} md={6} lg={4}>
                            <SkeletonProductCard />
                        </Grid>
                    ))
                }
                {
                    hasProducts && 
                    products.map(({_id}, ix) => (
                        <Grid key={_id} item xs={12} sm={6} md={4} lg={products.length % 4 ? 4 : 3}> {/* 4 wide if multiple of 4 else 3 wide*/}
                            <ProductCard productId={_id} showImage={true}/>
                        </Grid>
                    ))
                }
            </Grid>
        </Box>
    )
}

export default Shop;