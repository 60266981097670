/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Stripe Payment Images
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       28th December 2020

*******************************************************************************************/

import React                            from 'react';
import {
    useTheme,Box,Typography
}                                       from '@mui/material';
import LockIcon                         from '@mui/icons-material/Lock';

// Images
import imageStripeLight                 from 'resources/paymentMethods/stripe_light_crop.png';
import imageStripeDark                  from 'resources/paymentMethods/stripe_dark_crop.png';
import imageVisa                        from 'resources/paymentMethods/1.png';
import imageMastercard                  from 'resources/paymentMethods/2.png';
import imageAmex                        from 'resources/paymentMethods/22.png';

export const StripePaymentImages = ({mode = null}) => {
    const theme     = useTheme();
    const imagesLHS = [(mode || theme.palette.mode) !== 'dark' ? imageStripeDark : imageStripeLight];
    const imagesRHS = [imageVisa,imageMastercard,imageAmex];
    return (
        <Box display="flex">
            <Box display="flex" flexShrink={1} sx={{position:'relative',border:'2px solid', p:1, borderRadius : theme => theme.spacing(1), scale : '60%'}}>
                <Box sx={{position:'absolute',top:theme => theme.spacing(0.1), left :theme => theme.spacing(1),textAlign:'center'}}>
                    <Typography sx={{fontSize:'0.85rem'}}>
                        Powered by
                    </Typography>
                </Box>
                <Box sx={{my:'auto', transform:'translatey(10px)'}}>
                    <LockIcon />
                </Box>
                <Box flexGrow={1} style={{paddingLeft:8,paddingRight:8}} sx={{transform:'translatey(10px)'}}>
                    {
                        imagesLHS   
                        .map((src,ix)=>(
                                <img key={ix} src={src} alt={`method${ix}`} style={{maxWidth:100, width : `${(100/imagesLHS.length).toFixed(1)}%`}}/>
                        ))
                    }
                </Box>
            </Box>
            <Box sx={{textAlign:'right',my:'auto',transform:'translatey(3px)'}}>
                {
                    imagesRHS
                    .map((src,ix)=>(
                            <img key={ix} src={src} alt={`method${ix}`} style={{maxWidth:60, width : `${(100/imagesRHS.length).toFixed(1)}%`}}/>
                    ))
                }
            </Box>
        </Box>
    )
}

export default StripePaymentImages;