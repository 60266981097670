/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Address Block
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       18th July 2021

*******************************************************************************************/
import React                from 'react';
import {
    styled,
    Box 
}                           from '@mui/material';

const Root = styled(Box, {
    shouldForwardProp : prop => prop !== 'plain'
})(({plain = false}) => ({
    ...(!plain && {
        minHeight   : 100,
    }),
    width       : '100%'
}))

const Field = styled(Box,{
    shouldForwardProp : prop => prop !== 'plain'
})(({plain = false}) => ({
    ...(!plain && {
        fontFamily  : 'Courier New, Courier, Lucida Sans Typewriter, Lucida Typewriter, monospace',
        fontSize    : '0.75rem' 
    })
}))

export const AddressBlock = ({
    children,
    firstName, 
    middleName, 
    lastName, 
    address1, 
    address2, 
    city, 
    state, 
    country, 
    postCode,
    plain = false,
    ...props
}) => {
    
    const addressee = React.useMemo(() => (
        [firstName, middleName, lastName].filter(Boolean).join(' ')
    ), [firstName, lastName, middleName])

    const cityState = React.useMemo(() => (
        [city, state, postCode].filter(Boolean).join(', ')
    ), [city, postCode, state])

    return (
        <Root sx={props.sx} plain={plain}>
            {
                children || 
                [addressee, address1, address2, cityState, country].map((field,ix) => (
                    <Field key={ix} children={field} plain={plain}/>
                ))
            }
        </Root>
    )
}

export default AddressBlock;