/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Admin -- Houses
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       13th February 2021

*******************************************************************************************/
import React                            from 'react';
import {isEqual,isEmpty,pick,uniqWith}  from 'lodash';
import {titleCase}                      from 'title-case';
import formatcoords                     from 'formatcoords';
import moment                           from 'moment';
import { 
    useTheme, 
    Checkbox, 
    Avatar, 
    Grid, 
    Box, 
    MenuItem, 
    FormHelperText,
    ListSubheader
}                                       from '@mui/material';
import PersonIcon                       from '@mui/icons-material/Person';
import {
    Form,
    GoogleMapsStatic,
    CreateNatalData,
    Thumbnail
}                                       from 'components';
import {
    FabAdd,
}                                       from 'components/fabs';
import {
    useNatalData,
    useLocale,
    useTranslation
}                                       from 'contexts';
import {
    useImageCDN
}                                       from 'hooks';
import { 
    TextField,
    Select,
    Autocomplete,
    showErrorOnChange   as showError,
}                                       from 'mui-rff';
import {FormSpy}                        from 'react-final-form';

// const makeTitle = (t) => titleCase(t.replace(/([a-z])([A-Z])/g, '$1 $2'));

const NATAL_FIELDS_REQUIRED = ['lat','lng','address','birthDateTime','unknownTime','localTime'];
const NATAL_FIELDS_OPTIONAL = ['description','gender','isUser','photo','photoGetSignedUrl']
const obj                   = {};

const isNatalData = (x) => {
    if(typeof x !== 'object') return false;
    return NATAL_FIELDS_REQUIRED.every(item => x.hasOwnProperty(item));
}

const UserProfileIcon = () => (
    <PersonIcon 
        sx={{
            color       : theme => theme.palette.secondary.main,
            fontSize    : '0.65rem'
        }}
    />
)

const DynamicField = ({form, disabled, values, field, type, name, warnings = obj, ...props}) => {

    const {t}                                       = useTranslation();
    const theme                                     = useTheme();
    const {
        natalData : NatalDataOrig, 
        quantity : natalDataQuantity
    }                                               = useNatalData();
    const convert                                   = useImageCDN();
    const [natalData, setNatalData]                 = React.useState([]);

    React.useEffect(() => {
        if(type === 'natal'){
            let NATAL_FIELDS = [
                ...NATAL_FIELDS_REQUIRED,
                ...NATAL_FIELDS_OPTIONAL
            ];
            let data = [ values[name], ...NatalDataOrig]    // Include the list of records plus current value
                .filter(Boolean)                            // Not Empty                         
                .filter(isNatalData)                        // Is Valid
                .map(nd => pick(nd, NATAL_FIELDS))          // Pick only the necessary fields
                .sort((a, b) => moment(a.birthDateTime) - moment(b.birthDateTime) )

            // Select unique only
            data = uniqWith(data, isEqual);                 

            // Now set the state variable
            setNatalData(data)
        }else{
            // Not needed
            setNatalData([]);
        }
    },[NatalDataOrig, type, name, values])

    const {formatDate, formatTime}  = useLocale();

    let label   = t(`components.forms.deliveryUserInputForm.${name}`)
    let warning = name && values[name] ? warnings[values[name]] : undefined;
    
    switch(type){
        case 'natal':
            return (
                <Box display="flex">
                    <Box flexGrow={1}>
                        <Autocomplete
                            name                    = {name} 
                            label                   = {natalDataQuantity ? (label + ` (${natalDataQuantity})`) : label}
                            disabled                = {disabled}
                            showError               = {showError}
                            value                   = {values[name] || null}
                            options                 = {natalData}
                            disableCloseOnSelect    = {false}
                            autoSelect              = {true}
                            // blurOnSelect            = {true}
                            isOptionEqualToValue    = {(option,value) => isEqual(option, value)}
                            getOptionValue          = {option => option}
                            getOptionLabel          = {option => {
                                if(isEmpty(option)) 
                                    return t('components.forms.deliveryUserInputForm.pleaseSelectNatalRecord');
                                let label = [
                                    formatDate(moment.utc(option?.birthDateTime)),
                                    option?.unknownTime 
                                        ? undefined 
                                        : formatTime(moment.utc(option.birthDateTime)),
                                    option?.localTime ? "LMT" : "GMT",
                                    option?.lat && option?.lng 
                                        ? formatcoords(option?.lat,option?.lng).format({decimalPlaces:1}) 
                                        : undefined,
                                    option?.address 
                                        ? option.address 
                                        : undefined
                                ].filter(Boolean).join('; ');
                                return label
                            }}

                            renderInput = {(params) => {
                                return (
                                    <TextField
                                        {...params} 
                                        disabled        = {disabled}
                                        label           = {natalDataQuantity ? (label + ` (${natalDataQuantity})`) : label}
                                        name            = {name}
                                        InputLabelProps = {{
                                            shrink : true
                                        }}
                                    />
                                )
                            }}
                            
                            renderOption = {(option,optionData,...other) => {
                                // console.log(optionData);
                                return (
                                    <MenuItem 
                                        key     = {option.key} 
                                        onClick = {(e) => {
                                            option.onClick(e);
                                            form.change(name, optionData);
                                        }}
                                    >
                                        <Box display="flex" sx={{fontSize:'0.75em'}} ml={-1}>
                                            <Box style={{margin:'auto'}}>
                                                <Checkbox checked={option['aria-selected']} style={{padding:theme.spacing(1),fontSize:16}}/>
                                            </Box>
                                            <Box style={{width:50,padding:2}}>
                                                <Avatar style = {{width:50,height:50}} src={optionData?.photoGetSignedUrl} alt=""/>
                                            </Box>
                                            <Box ml={1} style={{width:50,padding:2}}>
                                                <GoogleMapsStatic zoom={15} lat={optionData?.lat} lng={optionData?.lng} width={100} height={100}/>
                                            </Box>
                                            <Box ml={1} sx={{overflow:'hidden'}}>
                                                {   (optionData?.description || optionData?.isUser) &&
                                                    <Box display="flex">
                                                        {
                                                            optionData?.description && 
                                                            <Box>
                                                                {optionData?.description} {optionData?.isUser ? ` (${t('components.forms.deliveryUserInputForm.you')})` : null}
                                                            </Box>
                                                        }
                                                        {
                                                            !optionData?.description && optionData?.isUser &&
                                                            <Box>
                                                                { t('components.forms.deliveryUserInputForm.yourNatalRecord') }
                                                            </Box>
                                                        }
                                                        <Box flexGrow={1}>
                                                            {optionData?.isUser ? <UserProfileIcon/> : null}
                                                        </Box>
                                                    </Box>
                                                }
                                                <div>
                                                    <strong>{formatDate(moment.utc(optionData.birthDateTime))} {optionData?.unknownTime ? null : formatTime(moment.utc(optionData.birthDateTime))} {optionData?.localTime ? "LMT" : "GMT"}</strong>
                                                </div>
                                                {optionData?.lat && optionData?.lng &&
                                                    <div>
                                                        {formatcoords(optionData?.lat,optionData?.lng).format({decimalPlaces:1})}
                                                    </div>
                                                }
                                                <div style={{wordBreak: "break-word",maxWidth:200}}>
                                                    {optionData?.address}
                                                </div>
                                            </Box>
                                        </Box>
                                    </MenuItem>
                                )
                            }}
                            multiple={false}
                        />
                        
                        {
                            warning && 
                            <FormHelperText error>
                                {warning}
                            </FormHelperText>
                        }
                        <FormSpy
                            subscription = {{ values: true }}
                            onChange     = {({values}) => {
                                form.change(name, values[name]);
                            }}
                        />
                    </Box>
                    <Box flexShrink={1} pt={1} mr={-2}>
                        <CreateNatalData 
                            disabled    = {disabled}
                            component   = {FabAdd} 
                            tooltip     = {t('components.forms.deliveryUserInputForm.createNatalData')} 
                            pulse       = {!Boolean(natalData.length)} 
                            color       = "primary"
                        />
                    </Box>
                </Box>
            );

        case 'enum': 
            if(field?.options){
                const hasPreview    = (field?.options || []).map(({image}) => image).some(Boolean);
                const quantity      = (field?.options || []).length;
                return (
                    <>
                        <Select 
                            disabled        = { disabled }
                            name            = { name } 
                            label           = { quantity ? label + ` (${quantity})` : label }
                            helperText      = { field?.helperText || field?.description } 
                            showError       = { showError }
                            inputLabelProps = {{ 
                                shrink : true 
                            }}
                        >

                            {
                                hasPreview &&
                                <ListSubheader sx={{fontWeight:800}}>
                                    {t('components.forms.deliveryUserInputForm.clickThumbnailForPreview')}
                                </ListSubheader>
                            }

                            {
                                (field?.options || []).map(({value, label : optionLabel, image : preview}, id) => {
                                    const   SMALL_SIZE          = 50, 
                                            LARGE_SIZE          = 500;
                                    const [thumbnail,highres]   = [ SMALL_SIZE, LARGE_SIZE ].map(width => convert( preview, { operation : 'width', width }));
                                    const title                 = titleCase(optionLabel || value)
                                    return (
                                        <MenuItem key={id} value={value}>
                                            <Box display="flex" sx = {{ width:'100%' }}>
                                                <Box flexGrow={1} sx = {{ my:'auto' }}>
                                                    { title }
                                                </Box>
                                                {
                                                    preview &&
                                                    <Box 
                                                        onClick = { 
                                                            e => {
                                                                e.stopPropagation(); 
                                                                e.preventDefault();
                                                            }
                                                        }
                                                    >
                                                        <Thumbnail 
                                                            title           = { t('components.forms.deliveryUserInputForm.preview') } // 
                                                            thumbnail       = { thumbnail }  
                                                            src             = { highres }  
                                                            thumbnailSize   = { 25 }
                                                        >
                                                            {t('components.forms.deliveryUserInputForm.previewSampleDescription')}
                                                        </Thumbnail>
                                                    </Box>
                                                }
                                            </Box>
                                        </MenuItem>
                                    )
                                })
                            }
                        </Select>
                        { 
                            warning && 
                            <FormHelperText error> 
                                {warning} 
                            </FormHelperText> 
                        }
                    </>
                )
            }
            break;
        case 'string':
        default:
            if( field?.enum && Array.isArray(field?.enum)){
                const quantity = (field?.enum || []).length
                return (
                    <>
                        <Select 
                            disabled        = { disabled }
                            name            = { name } 
                            label           = { quantity ? label + ` (${quantity})` : label } 
                            helperText      = { field?.helperText || field?.description } 
                            showError       = { showError}
                            inputLabelProps = {{ 
                                shrink : true 
                            }}
                        >
                            {
                                field.enum.map((value,id) => (
                                    <MenuItem key={id} value={value}>
                                        { titleCase(value) }
                                    </MenuItem>
                                ))
                            }
                        </Select>
                        { 
                            warning && 
                            <FormHelperText error> 
                                {warning} 
                            </FormHelperText> 
                        }
                    </>
                )
            }
            break;
        }

    // Fallback
    return ( 
        <>
            <TextField 
                disabled    = { disabled }
                type        = "text"
                name        = { name } 
                label       = { label }
                value       = { values[name] }
                showError   = { showError }
                helperText  = { field?.helperText || field?.description } 
            />
            {
                warning && 
                <FormHelperText error>
                    {warning}
                </FormHelperText>
            }
        </>
    )
}

const noop = () => {};

export const DeliveryUserInputForm = ({
    formData                    = obj,
    types                       = obj,
    fields                      = obj,
    delivery                    = obj, // Details About the Delivery
    disabled                    = false,
    onSubmit : handleSubmit     = noop,
    onCancel : handleCancel     = noop,
    FormProps                   = obj,
}) => {
    const {t}       = useTranslation();
    const validate  = React.useCallback( (values) => {

        let errors = {};

        // Required Fields
        Object
            .keys(formData)
            .forEach(item=>{
                if(values[item] === undefined || values[item] === '')
                    errors[item] = errors[item] || t('components.forms.deliveryUserInputForm.required');
            })

        // Errors
        return errors;

    }, [t, formData]);

    const initialValues = React.useMemo(() => (

        Object.entries(formData).reduce((acc,[key,value]) => {
            return {
                ...acc,
                [key] : value|| fields.find(x => x.name === key)?.default || undefined
            }
        },{})
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [JSON.stringify(fields), formData]);

    return (
        <Form
            debug           = {false}
            disabled        = {disabled}
            onSubmit        = {handleSubmit}
            onCancel        = {handleCancel}
            initialValues   = {initialValues}
            validate        = {validate}
            {...FormProps}
            render          = {({disabled, form, error, dirtySinceLastSubmit, submitFailed, submitSucceeded, errors, handleSubmit, values, ...rest}) => {
                return (
                    <form onSubmit={handleSubmit} noValidate>
                        <Grid container>
                            {
                                Object.keys(types).map((key,ix) => {
                                    let field = fields.find(x => x.name === key) || {};
                                    return (
                                        <Grid key={ix} item xs={12}>
                                            <DynamicField 
                                                disabled    = {disabled} 
                                                key         = {ix} 
                                                type        = {types[key]} 
                                                field       = {field} 
                                                name        = {key}
                                                form        = {form} 
                                                values      = {values}
                                                warnings    = {field?.warnings}
                                            />
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </form>
                )
            }}
        />
    )
}

export default DeliveryUserInputForm;