/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Cart Component
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       29th June 2021

*******************************************************************************************/
import React                            from 'react';
import { 
    RootContainer,
    CartItem,
}                                       from 'components';
import { useCart}                       from 'contexts';

// Shopping Cart
export const CartItemsSheet = ({ readOnly = false, showBrowse = true, ...props}) => {
    const { cart }          = useCart();
    return (
        <RootContainer>  
            {
                cart.map((item,ix) => (
                    <CartItem
                        key                 = {ix}
                        item                = {item}
                        readOnly            = {readOnly}
                        showBrowse          = {showBrowse}
                    />
                ))
            }
        </RootContainer>
    )
}

export default CartItemsSheet;