/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Filter Form
********************************************************************************************
Creates Filter Expression for Filtering Mongoose/MongoDB Queries

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       20th August 2022

*******************************************************************************************/
import React                        from 'react';
import { Grid, MenuItem }           from '@mui/material';
import { 
    showErrorOnChange   as showError,
    Select,
    TextField
}                                   from 'mui-rff';
import Form                         from 'components/Form'
import { useTranslation }           from 'contexts';

const DEFAULT_OPTIONS_FIELDS = [
    {value:'none',      translationKey : "components.forms.filterForm.none", validOperators : undefined }
]

const DEFAULT_OPTIONS_OPERATORS = [
    {value:'$regex',    translationKey : "components.forms.filterForm.regexMatch" },
    {value:'$eq',       translationKey : "components.forms.filterForm.equals" },
    {value:'$ne',       translationKey : "components.forms.filterForm.notEquals" },
    {value:'$gt',       translationKey : "components.forms.filterForm.greaterThan" },
    {value:'$gte',      translationKey : "components.forms.filterForm.greaterThanOrEqual" },
    {value:'$lt',       translationKey : "components.forms.filterForm.lessThan" },
    {value:'$lte',      translationKey : "components.forms.filterForm.lessThanOrEqual" }
];

const DEFAULT_FORM_DATA = {
    field       : undefined,
    operator    : undefined,
    value       : undefined
};

const noop = () => {};

const formPropsDefault = {}

export const FilterForm = ({
    disabled                = false,
    formData                = DEFAULT_FORM_DATA,
    optionsFields           = DEFAULT_OPTIONS_FIELDS,
    optionsOperators        = DEFAULT_OPTIONS_OPERATORS,
    onSubmit : handleSubmit = noop,
    onCancel : handleCancel = noop,
    onChange : handleChange = noop,
    FormProps               = formPropsDefault,
    ...props
}) => {

    const {t} = useTranslation();

    // Validation Function
    const validate      = React.useCallback( (values) => {
        let errors = {};
        ['field','operator'].forEach(key => {
            if(!values[key])
                errors[key] = errors[key] || t('components.forms.filterForm.required')
        })
        return errors;
    }, [t]);

    // Initial Values
    const initialValues = React.useMemo(() => ({
        ...formData
    }), [formData])

    return (
        <Form
            debug           = {false}
            disabled        = {disabled}
            onSubmit        = {handleSubmit}
            onCancel        = {handleCancel}
            onChange        = {handleChange}
            initialValues   = {initialValues}
            validate        = {validate}
            showResetButton = {true}
            {...FormProps}
            render          = {({disabled, form, error, dirtySinceLastSubmit, submitFailed, submitSucceeded, errors, handleSubmit, values, ...rest}) => {
                return (
                    <form onSubmit={handleSubmit} noValidate>
                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                <Select 
                                    name            = "field" 
                                    label           = {t('components.forms.filterForm.field')}
                                    disabled        = {disabled}
                                    showError       = {showError}
                                    variant         = "filled" 
                                    inputLabelProps = {{shrink:'true'}}
                                    fullWidth
                                >
                                    {optionsFields.map(({value,label,translationKey},ix) => (
                                        <MenuItem key={ix} value={value}>
                                            {label || t(translationKey)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Select 
                                    name            = "operator" 
                                    label           = {t('components.forms.filterForm.operator')}
                                    disabled        = {disabled}
                                    showError       = {showError}
                                    variant         = "filled" 
                                    inputLabelProps = {{shrink:'true'}}
                                    fullWidth 
                                >
                                    {optionsOperators.map(({value,label,translationKey},ix) => (
                                        <MenuItem key={ix} value={value}>
                                            {label || t(translationKey)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField 
                                    name        = "value" 
                                    label       = {t('components.forms.filterForm.value')}
                                    showError   = {showError}
                                    variant     = "filled"
                                    fullWidth 
                                />
                            </Grid>
                        </Grid>
                    </form>
                )
            }}
        />
    )
}

export default FilterForm;