
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Network Context
********************************************************************************************
Boilerplate context, consumer, provider and hook

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       5th December 2021

*******************************************************************************************/
import React                            from 'react';
import { useInternet }                  from 'hooks/services/useInternet';
import { useAxios }                     from 'hooks/services/useAxios';
import { useAPIHealth }                 from './APIHealthContext';
import { useSocketIO }                  from './SocketIOContext';

const REQUIRE_SOCKET = false;


export const useNetworkHook = ({requireSocket = REQUIRE_SOCKET } = {}) => {

    const {isOnline}            = useInternet();
    const {
        socketRoot, 
        socketUsers
    }                           = useSocketIO();
    const {axios}               = useAxios();

    // Online State
    const [isSocketConnected,   setIsSocketConnected]     = React.useState(socketRoot && socketRoot.connected);
    
    //const [isNetworkReady,      setIsNetworkReady]        = React.useState(isOnline && (isSocketConnected || !requireSocket));

    React.useEffect(() => {
        const handleConnect     = () => setIsSocketConnected(true);
        const handleDisconnect  = () => setIsSocketConnected(false);
        if(socketRoot){
            setIsSocketConnected(socketRoot.connected);
            socketRoot.on('connect',        handleConnect);
            socketRoot.on('disconnect',     handleDisconnect);
            return () => {
                socketRoot.off('connect',       handleConnect);
                socketRoot.off('disconnect',    handleDisconnect);
            }
        }else{
            handleDisconnect();
        }
    },[socketRoot])

    /*
    React.useEffect(() => {
        setIsNetworkReady(isOnline && (isSocketConnected || !REQUIRE_SOCKET));
    },[isSocketConnected, isOnline])
    */

    const isNetworkReady = React.useMemo(() => (
        Boolean(isOnline && (isSocketConnected || !requireSocket))
    ), [isOnline, isSocketConnected, requireSocket])

    const memoisedState = React.useMemo(() => ({
        isOnline,
        isSocketConnected,
        isNetworkReady,
        axios,
        socketRoot,
        socketUsers
    }), [axios, isNetworkReady, isOnline, isSocketConnected, socketRoot, socketUsers])

    return memoisedState;
};

// The Product Context 
const NetworkContext = React.createContext(undefined);

// Network Provider
const NetworkProvider = ({children}) => {

    const {
        axios,
        isOnline,
        isSocketConnected,
        isNetworkReady : isNetworkReadyBase,
        socketRoot,
        socketUsers
    }                                       = useNetworkHook();
    const {
        checked,
        isAPIHealthy,
        checkAPIHealthy
    }                                       = useAPIHealth();

    const [isNetworkReady, setNetworkReady] = React.useState(isNetworkReadyBase);

    React.useEffect(()=>{
        setNetworkReady(Boolean((!checked || isAPIHealthy))); // && isNetworkReadyBase));
    },[checked, isAPIHealthy, isNetworkReadyBase])

    // Check api if socket drops
    React.useEffect(()=>{
        if(!isSocketConnected){
            checkAPIHealthy();
        }
    },[checkAPIHealthy, isSocketConnected])

    // Context values
    const value = React.useMemo(() => ({
        // Network Flags
        isOnline,
        isSocketConnected,
        isNetworkReady,
        // Sockets
        socketRoot,
        socketUsers,
        // REST Queries
        axios,
        // Server Healthy
        isAPIHealthy,
        checkAPIHealthy,
    }), [axios, checkAPIHealthy, isAPIHealthy, isNetworkReady, isOnline, isSocketConnected, socketRoot, socketUsers]);

    return (
        <NetworkContext.Provider value={value}>
            {children}
        </NetworkContext.Provider>
    )
}

// Network Consumer
const NetworkConsumer = ({children}) => {
    return (
        <NetworkContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('NetworkConsumer must be used within NetworkProvider');
                }
                return children(context)
            }}
        </NetworkContext.Consumer>
    )
}

// useNetwork Hook
const useNetwork = () => {
    const context = React.useContext(NetworkContext);
    if(context === undefined)
        throw new Error('useNetwork must be used within NetworkProvider');
    return context;
}

export {
    NetworkProvider,
    NetworkConsumer,
    useNetwork
}