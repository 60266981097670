/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
AZ Filter
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       27th December 2022

*******************************************************************************************/
import React                from 'react';
import {
    styled,
    alpha,
    useMediaQuery,
    Box,
    Typography as TypographyMUI
}                           from '@mui/material';

const noop = () => {}

const AZContainer = styled(Box,{
    shouldForwardProp : prop => prop !== 'selected' && prop !== 'disabled'
})(({theme, selected = false, disabled = false}) => ({
    padding         : 0,
    paddingLeft     : 2,
    paddingRight    : 2,
    borderRadius    : 4,
    display         : 'inline-block',
    cursor          : disabled ? 'initial' : 'pointer',
    minWidth        : 10,
    ...(selected && {
        fontWeight : 800,
    }),
    ...(selected && !disabled && {
        background : alpha(theme.palette.secondary.main,0.5)
    }),
    ...(selected && disabled && {
        background : theme.palette.action.disabledBackground
    })
}));

const Typography = styled(TypographyMUI)(({theme}) => ({
    [theme.breakpoints.up('xs')] : {
        fontSize : '0.6rem'
    },
    [theme.breakpoints.up('sm')] : {
        fontSize : '0.80rem'
    }
}));

const Root = styled(Box)({
    lineHeight : 0
});

export const AZFilter = ({disabled = false, value = undefined, onChange = noop}) => {

    const handleClick = React.useCallback((letter) => {
        if(disabled) return;
        onChange(letter);
    },[disabled, onChange])

    const letters = React.useMemo(() => (
        Array(26)
            .fill(undefined)
            .map((_,ix) => (ix + 10).toString(36).toUpperCase())
    ), []);

    return (
        <Root id="rootAZFilter">
            {
                letters.map( letter => {
                    const selected  = (value || '').toString().toUpperCase() === letter;
                    return (
                        <AZContainer 
                            key         = {letter} 
                            disabled    = {disabled} 
                            selected    = {selected} 
                            onClick     = {() => handleClick(selected ? undefined : letter)}
                        >
                            <Typography align="center">
                                {letter}
                            </Typography>
                        </AZContainer>
                    )
                })}
        </Root>
    )
};

const FilterContainerBlock = styled(Box)({
    textAlign   : 'right'
});

const FilterContainerAbsolute = styled(Box)(({theme}) => ({
    maxWidth    : 'calc(100% - 75px)',
    position    : 'absolute', 
    top         : theme.spacing(1), 
    right       : theme.spacing(1)
}));

export const withAZFilter = Component => {
    return function({filterProps = {}, ...props}){
        const smUp = useMediaQuery(theme => theme.breakpoints.up('sm'));
        return (
            <Box width="100%" position="relative">

                {
                    !smUp && 
                    <FilterContainerBlock>
                        <AZFilter {...filterProps}/>
                    </FilterContainerBlock>
                }

                <Component {...props}/>

                {
                    smUp && 
                    <FilterContainerAbsolute>
                        <AZFilter {...filterProps}/>
                    </FilterContainerAbsolute>
                }
                
            </Box>
        )
    }
}; 

export default AZFilter;
