/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Items Count
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       20th November 2020

*******************************************************************************************/
import React                from 'react';
import { Box, Typography }  from '@mui/material';

export const ItemsCount = ({children, count, suffix=undefined, ...props}) => {
    if(isNaN(count)) 
        return null
    return (
        <Box sx={{ position : 'absolute', display : 'inline-block', pl : 0.5}}>
            <Typography variant="body2" {...props} sx={{fontStyle:'italic',...props.sx}}>
                {children}{count}x items {suffix}
            </Typography>
        </Box>
    )
}

export default ItemsCount;
