/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
with Action Button HOC
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       22nd July 2022

*******************************************************************************************/
import React                            from 'react';
import { styled, Box }                  from '@mui/material';
import { useNetwork, useTranslation}    from "contexts";
import PulseButton                      from 'components/pulse/PulseButton';
import useSize                          from 'hooks/useSize';

const obj = {};

const withStyledContainerComponent = Component => styled(Component, {
    shouldForwardProp : (prop) => prop !== 'minWidth' && prop !== 'minHeight'
})(({minWidth = 0, minHeight = 0})=>({
    width       : '100%',
    height      : '100%',
    overflow    : 'hidden',
    position    : "relative",
    minHeight   : minHeight,
    minWidth    : minWidth
}));

const ButtonContainer = styled(Box)(({theme}) => ({
    position    : 'absolute',
    top         : '50%',
    left        : '50%',
    transform   : `translate(-50%, -50%)`,
}))

export const withFeatureButton = Component => ({
    showFeature                                         = true,
    containerComponent  : ContainerComponent            = Box,
    buttonComponent     : Button                        = PulseButton, 
    buttonProps                                         = obj, 
    componentProps                                      = obj,
    containerProps                                      = obj,
    ...props
}) => {
    const ref               = React.useRef(null)
    const {width,height}    = useSize(ref);
    const {isNetworkReady}  = useNetwork();
    const {t}               = useTranslation();
    const args              = React.useMemo(() => ({
        disabled    : Boolean(buttonProps.disabled || !isNetworkReady), 
        onClick     : () => alert('onClick(...) not implemented'),
        color       : "secondary", 
        variant     : "contained", 
        size        : "small"
    }),[buttonProps?.disabled, isNetworkReady]);
    const StyledContainerComponent = React.useMemo(() => withStyledContainerComponent(ContainerComponent), [ContainerComponent]);
    return (
        <StyledContainerComponent {...containerProps} minWidth={width} minHeight={height}>
            <Component {...props} {...componentProps} />
            {
                showFeature && 
                <ButtonContainer ref={ref}>
                    <Button {...args} {...buttonProps} sx={{m:1,...buttonProps.sx}}>
                        {buttonProps.children || buttonProps.label || t('common.submit')}
                    </Button>
                </ButtonContainer>
            }
        </StyledContainerComponent>
    )
}

export default withFeatureButton;