/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Skeleton Radix
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       21st July 2022

*******************************************************************************************/
import React                            from 'react';
import { Box, Skeleton }                from '@mui/material';

export const SkeletonRadix = ({showText=true, ...props}) => (
    <Box width = "100%" display="flex" position="relative" {...props}>
        {
            showText &&
            <Skeleton variant='text' width="30%" style={{position:'absolute',top:0,left:0}}/>
        }
        <Box width="100%" minHeight={0} sx={{mx:4,my:1,aspectRatio:'1'}} >
            {
                <Skeleton 
                    variant = "circular" 
                    width   = "100%" 
                    height  = "100%"
                />
            }
        </Box>
    </Box>
)

export default SkeletonRadix;