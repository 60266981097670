/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Footer
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       22nd December 2020

*******************************************************************************************/
import React                            from 'react';
import {titleCase}                      from 'title-case';
import { 
    useMediaQuery,
    useTheme,
    alpha,
    Box, 
    Toolbar, 
    Paper, 
    ButtonGroup,
    Typography
}                                       from '@mui/material';
import { 
    LogoMonogram, 
    Copyright,
    GlobalLoader,
    Locale,
    ThemeSwitch,
    SocialAccounts,
    QRCode,
    StripePaymentImages
}                                       from 'components';
import { 
    AboutLocation,
    FAQLocation,
    PolicyPrivacyLocation,
    PolicyTermsLocation,
    PolicyCookiesLocation,
    UserContactLocation
}                                       from 'router/locations';
import { useTranslation, useNetwork }   from 'contexts';
import { 
    useImageCDN, 
    useSoftwareVersion 
}                                       from 'hooks';
import FooterButton                     from './FooterButton';
import universe                         from 'resources/universe/universe.jpg'
import config                           from '../../config';

export const Footer = ({ height = 225, showRule = true, ...props}) => {
    const [t]                           = useTranslation();
    const theme                         = useTheme();
    const convert                       = useImageCDN() 
    const { isAPIHealthy }              = useNetwork();
    const { isEnabled, versionClient }  = useSoftwareVersion();
    const minSize                       = useMediaQuery(theme => theme.breakpoints.up('md'));
    const color                         = React.useMemo(() => theme.palette.primary.main, [theme.palette.primary.main])
    
    // Construct the Server Status Term
    const serverStatus                  = React.useMemo(() => (
        [
            isAPIHealthy                    ? 'HEALTHY'             : 'UNREACHABLE',
            (isEnabled && versionClient)    ? `v${versionClient}`   : undefined    
        ].filter(Boolean).join(', ')
    ),[isAPIHealthy, isEnabled, versionClient])

    return (
        <Paper 
            square 
            {...props}
            sx = {{
                ...props.sx,
                borderTop       : showRule ? theme => `${theme.spacing(3)} solid ${color}` : 'none',
                minHeight       : height,
                py              : 6,
                px              : 2,
                color           : theme => theme.palette.primary.contrastText,
                bgcolor         : theme => theme.palette.primary.dark,
                backgroundImage : `linear-gradient(${alpha(color,0.75)},${alpha(color,0.25)}), url(${convert(universe,{operation:'width',width:1000})})`,
                backgroundSize  : 'cover',
                textAlign       : 'center'
            }}
        >
            <Box display="flex" sx={{mx:'auto'}}>
                <Box flexGrow={1}/>
                <Box>
                    <LogoMonogram dark={false}/>
                </Box>
                <Box sx={{ml:2}}>
                    <QRCode />
                </Box>
                <Box flexGrow={1}/>
            </Box>

            <SocialAccounts />

            {
                minSize &&
                <Toolbar sx={{justifyContent:'center'}}>
                    <ButtonGroup variant="text">
                        <FooterButton to={AboutLocation.path}>           
                            {t('common.about')}
                        </FooterButton>
                        <FooterButton to={FAQLocation.path}>             
                            {t('common.faq')}
                        </FooterButton>
                        <FooterButton to={PolicyTermsLocation.path}>     
                            {t('pages.policies.termsAndConditions.title')}
                        </FooterButton>
                        <FooterButton to={PolicyPrivacyLocation.path}>   
                            {t('pages.policies.privacyPolicy.title')}
                        </FooterButton>
                        <FooterButton to={PolicyCookiesLocation.path}>   
                            {t('pages.policies.cookiePolicy.title')}
                        </FooterButton>
                        <FooterButton to={UserContactLocation.path}>     
                            {t('common.contact')}
                        </FooterButton>
                        <ThemeSwitch/>
                    </ButtonGroup>
                </Toolbar>
            }
            {
                !minSize &&
                <ThemeSwitch condensed={true}/>
            }

            <Box sx={{maxWidth:300,mx:'auto',mb:2}}>
                <StripePaymentImages mode="dark"/>
            </Box>

            <Typography component="div" variant="body2" sx={{color:'inherit','& > * + *':{mt:1}}}>
                <Box>
                    {titleCase(config.env)} Server is: {serverStatus}
                </Box>
                <Box>
                    <Locale />
                </Box>
                <Box>
                    <Copyright/>
                </Box>
                <Box sx={{minHeight:40}}>
                    <GlobalLoader color={theme.palette.primary.light}/>
                </Box>
            </Typography>    

        </Paper>
    );
}

export default Footer;