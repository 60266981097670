/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
GeoLocate Modal
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       24th December 2020

*******************************************************************************************/

import React                            from 'react';
import { 
    styled,
    Typography, 
    Box, 
    InputLabel 
}                                       from '@mui/material';
import {
    DraggableDialog,
    GeoLookup,
    GoogleMapsDynamic,
    GeoDisplay,
    FeatureBox
}                                       from 'components';
import { withTranslation }              from 'components/hoc';
import { useGeocode }                   from 'hooks';

const StyledBox = styled(Box)(({theme}) => ({
    marginTop       : theme.spacing(1),
    marginBottom    : theme.spacing(1),
    padding         : theme.spacing(0)
}))

const noop = () => {};
const noopOk = (position) => {};

export const GeoLocate = withTranslation( ({
    t,
    open        : openIn        = false,
    lat         : latIn,
    lng         : lngIn,
    address     : addressIn,
    onOk        : handleOk      = noopOk,
    onCancel    : handleCancel  = noop,
}) => {

    const open                      = React.useMemo(() => openIn, [openIn]);
    const {lookupLatLng}            = useGeocode();
    
    // The actual Result
    const [result,setResult]        = React.useState({lat:latIn, lng:lngIn, address:addressIn});

    // Reset fields to input args
    const resetFields               = React.useCallback( () => {
        setResult({lat:latIn, lng:lngIn, address:addressIn});
    }, [latIn, lngIn, addressIn]);

    // When values Change
    React.useEffect(resetFields, [resetFields])

    const isValid                   = React.useCallback( ({lat, lng, address}) => Boolean(!isNaN(lat) && !isNaN(lng) && address), []);

    const handleLookupChange        = React.useCallback( (pos)      => {
        let {lat,lng, address, success} = (pos || {});
        if(success){
            let valid = isValid({lat, lng, address});
            if(valid)
                setResult({lat, lng, address});
        }
    }, [isValid])


    const handleMapChange           = React.useCallback( async (pos)    => {
        let {lat,lng} = (pos || {});
        let {address} = await lookupLatLng(lat,lng)
        let valid  = isValid({lat, lng, address});
        if(valid){
            setResult({lat, lng, address});
        }
    
    }, [isValid, lookupLatLng]);

    const handleGeoDisplayChange    = React.useCallback( (formData) => {
        setResult(prev => ({...prev,...formData}));
    },[]);

    const handleDialogCancel        = React.useCallback( () => {
        resetFields();
        handleCancel();
    }, [handleCancel, resetFields]);

    const handleDialogOk            = React.useCallback( () => handleOk(result), [handleOk, result]);

    return (
        <DraggableDialog
            title       = { t('components.modals.geoLocate.title') }
            open        = {open}
            onClose     = {handleDialogCancel}
            onOk        = {handleDialogOk}
            okenabled   = {isValid(result)}
        >
            <Box display="flex" flexDirection='column' alignItems='stretch' height="100%">
                <Typography variant="body2" gutterBottom>
                    { t('components.modals.geoLocate.summary') }
                </Typography>
                <StyledBox>
                    <GeoLookup 
                        onChange    = {handleLookupChange} 
                        helperText  = { t('components.modals.geoLocate.geoLookup.helperText') }
                    />
                </StyledBox>
                { 
                    <StyledBox>
                        <FeatureBox> 
                            <InputLabel shrink={true}>
                                { t('components.modals.geoLocate.resolvedGeolocation') }
                            </InputLabel>
                            <Box>
                                <GeoDisplay {...result} onChange={handleGeoDisplayChange}/> 
                            </Box>
                        </FeatureBox>
                    </StyledBox>
                }
                <StyledBox flexGrow={1}>
                    <Box flexGrow={1} style={{position:'relative', width: '100%', height:'100%', minHeight:150}}>
                        <GoogleMapsDynamic
                            lng         = {result.lng} 
                            lat         = {result.lat} 
                            onChange    = {handleMapChange}
                        />
                    </Box>
                </StyledBox>
            </Box>
        </DraggableDialog>
    )
})

export default GeoLocate;
