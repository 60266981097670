/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Widget Video
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       30th June 2022

*******************************************************************************************/

import React                from 'react';
import {
  Box,
  alpha,
  styled,
  Typography
}                           from '@mui/material';
import {
  Title,
  ContentfulRichText
}                           from 'components';
import ReactPlayer          from 'react-player';
import { 
  SectionSimple as Section
}                           from '../components/Section';
import SectionContainer     from '../components/SectionContainer';

const VideoContainer = styled(Box)(({theme}) => ({
    display         : 'flex', 
    marginLeft      : 'auto', 
    marginRight     : 'auto', 
    maxWidth        : '1000px', 
    width           : '90%', 
    height          : 'auto',
    // minHeight       : '200px',
    position        : 'relative',
    // borderRadius    : theme.spacing(1),
    overflow        : 'hidden',
    border          : `1px solid ${theme.palette.divider}`,
    background      : alpha(theme.palette.divider,0.025)
}));

export const WidgetVideo = ({data = undefined}) => {

  const ref                     = React.useRef(null);

  const [playing, setPlaying]   = React.useState(false);
  const handlePlay              = React.useCallback(() => setPlaying(true), []);
  const handleStop              = React.useCallback(() => setPlaying(false), []);

  const hasContent = React.useMemo(() => {
    return [data?.fields?.title,data?.fields?.subtitle,data?.fields?.content].some(Boolean)
  },[data?.fields?.content, data?.fields?.subtitle, data?.fields?.title])

  React.useEffect(() => {
    if(playing){
      if (ref.current) {
        // TODo
      }
    }
  },[playing])

  if(!data)
    return null;
  
  return (
    <SectionContainer>
      {
        hasContent && 
        <Section sx={{maxWidth:1000,mx:'auto', mb:2}}>
          {
            data?.fields?.title &&
            <Title component="h2" variant="h2" gutterBottom>
              {data?.fields?.title}
            </Title>
          }
          {
            data?.fields?.subtitle &&
            <Typography paragraph variant="h5">
              {data?.fields?.subtitle}
            </Typography>
          }
          { 
            data?.fields?.content &&
            <ContentfulRichText content={data?.fields?.content} />    
          }
        </Section>
      }
      <Section>
        <VideoContainer sx={{position:'relative'}}>
            <Box ref={ref}/>
            <ReactPlayer 
              url       = {data?.fields?.video?.fields?.file?.url}
              light     = {
                <img
                  alt = "null"
                  src = {`${data?.fields?.poster?.fields?.file?.url}?w=1000`}
                  style={{
                    backgroundSize      : "cover",
                    backgroundPosition  : "center",
                    width               : "100%",
                    height              : "100%",
                  }}
                />
              }
              width           = "100%" 
              height          = "100%" 
              controls        = {true}
              playing         = {playing}
              onClickPreview  = {handlePlay}
              onStart         = {handlePlay}
              onPlay          = {handlePlay}
              onPause         = {handleStop}
              onEnded         = {handleStop}
            />
        </VideoContainer>
      </Section>
    </SectionContainer>
  )
}

export default WidgetVideo;