/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Tickets Table
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       9th December 2021

*******************************************************************************************/
import React                            from 'react';
import moment                           from 'moment';
import {
    styled,
    alpha, Checkbox, Table,
    TableContainer, 
    TableHead, 
    TableBody, 
    TablePagination,
    TableCell       as TableCellMUI, 
    TableRow        as TableRowMUI
}                                       from '@mui/material';
import {
    RootContainer,
    NoDataPrompt,
    YesNoIcon,
    Gravatar,
    JSONViewer,
    NewChip,
    SpaceBox,
    ViewUserButton
}                                       from 'components';
import {
    useLocale,
    useTicket,
    useUser
}                                       from 'contexts';
import { useClock, withClickHandler }   from 'hooks';

const DEBUG = false;

const TableRow = styled(TableRowMUI)({
    cursor : 'pointer'
})
const TableRowMod = withClickHandler(TableRow);

const TableCell = styled(TableCellMUI,{
    shouldForwardProp : prop => !['unread','datecell','pointer','spacing','highlighted'].includes(prop)
})(({theme, spacing = 1, highlighted = false, unread = false, datecell = false, pointer = false}) => ({
    paddingLeft     : theme.spacing(spacing),
    paddingRight    : theme.spacing(spacing),
    ...(highlighted && {
        background  : alpha(theme.palette.secondary.main,0.25)
    }),
    ...(pointer && {
        cursor : 'pointer'
    }),
    ...(datecell && {
        whiteSpace : ['nowrap','!important']
    }),
    ...(unread && {
        fontWeight : 800
    })
}))

export const TicketsTable = ({
    ticketId        : ticketIdIn, 
    adminMode                           = false, 
    disabled                            = false, 
    onClick         : handleClick       = undefined, 
    onClickCreate   : handleClickCreate = undefined, 
    debug                               = DEBUG,
    ...props
}) => {

    const {formatDateTime}              = useLocale();
    const {
        tickets,
        admin,
        page,
        setPage,
        limit,
        setLimit,
        totalItems
    }                                   = useTicket();
    const { isAuthenticated }           = useUser();
    const {now}                         = useClock();

    // Avoid a layout jump when reaching the last page with empty rows.
    const handleChangePage              = React.useCallback( (event, newPage) => {
        if(disabled) return;
        setPage(newPage);
    }, [disabled, setPage]);

    const handleChangeRowsPerPage       = React.useCallback( (event) => {
        if(disabled) return;
        setLimit(parseInt(event.target.value, 10));
        setPage(0); // 0 based
    }, [disabled, setLimit, setPage]);

    // State Variables
    const ticketId      = React.useMemo(() => ticketIdIn, [ticketIdIn]);

    // Has Empty Rows
    const hasTickets    = React.useMemo(() => Array.isArray(tickets) ? Boolean(tickets.length > 0) : false, [tickets]);

    // tickets needs to be an array
    if(!Array.isArray(tickets))
        return null;

    if(!isAuthenticated)
        return null;
        
    // Render
    return (
        <RootContainer>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>
                                User
                            </TableCell>
                            <TableCell  style={{width:'20%'}}>
                                Subject 
                            </TableCell>
                            <TableCell  style={{width:'80%'}} align="left" colSpan={2}>
                                Preview
                            </TableCell>
                            <TableCell >
                                Type 
                            </TableCell>
                            <TableCell  align="center">
                                Qty
                            </TableCell>
                            <TableCell  align="center">
                                Assigned
                            </TableCell>
                            {false && 
                                <TableCell  align="center">
                                    Read   
                                </TableCell>
                            }
                            <TableCell  align="center">
                                Open     
                            </TableCell>
                            <TableCell datecell={true}>
                                Last Viewed
                            </TableCell>
                            <TableCell datecell={true}>
                                Created  
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tickets.map((t,ix) => {
                            
                            const handleRowClick = () => {
                                if(disabled) return;
                                handleClick(t.id);
                            }
                            const isSelected    = Boolean(ticketId && ticketId === t.id); 
                            const isAssigned    = Boolean(t.manager);
                            const isClosed      = Boolean(t.closed);
                            const isOpen        = !isClosed;
                            const isUnread      = admin ? t.managerUnread : t.userUnread;
                            const isRead        = !isUnread;
                            const {createdAt,updatedAt} = t;
                            return (
                                <TableRowMod hover={true} key={t.id || ix} onClick={handleRowClick} onDoubleClick={handleRowClick}>
                                    <TableCell highlighted={isSelected} pointer={!disabled}>     
                                        <Checkbox disabled={true} checked={isSelected} style={{padding:0}}/>
                                    </TableCell>
                                    {
                                        !adminMode &&
                                        <TableCell highlighted={isSelected} pointer={!disabled} style={{paddingTop:0,paddingBottom:0}} align="center">
                                            {t?.user?.emailMD5 && 
                                                <Gravatar 
                                                    alt         = {t?.user?.name || 'Users Gravatar'} 
                                                    emailMD5    = {t?.user?.emailMD5} 
                                                    style       = {{cursor:'pointer'}}
                                                    size        = {30}
                                                />
                                            }
                                        </TableCell>
                                    }
                                    {
                                        adminMode &&
                                        <TableCell onClick={e => e.stopPropagation()} highlighted={isSelected} pointer={!disabled} style={{
                                            whiteSpace      : 'nowrap',
                                            overflow        : 'hidden',
                                            textOverflow    : 'ellipsis',
                                            maxWidth        : 150
                                        }}>
                                            <ViewUserButton id={t?.user?.id} emailMD5={t?.user?.emailMD5} name={t?.user?.name} disabled={disabled}/>
                                        </TableCell>
                                    }
                                    <TableCell highlighted={isSelected} pointer={!disabled} unread={isUnread}>
                                        {t.subject}
                                    </TableCell>
                                    <TableCell highlighted={isSelected} pointer={!disabled} unread={isUnread} style={{width:'100%',maxWidth:200,textOverflow:'ellipsis',overflow: 'hidden', whiteSpace:'nowrap'}}>
                                        {t.text}
                                    </TableCell>
                                    <TableCell highlighted={isSelected} pointer={!disabled} unread={isUnread} align="center">
                                        {isUnread && <NewChip />}
                                    </TableCell>
                                    <TableCell highlighted={isSelected} pointer={!disabled}>
                                        {t.category}
                                    </TableCell>
                                    <TableCell highlighted={isSelected} pointer={!disabled} align= "center">
                                        {(t?.messages || []).length || '-'}
                                    </TableCell>
                                    <TableCell highlighted={isSelected} pointer={!disabled} align="center">
                                        <YesNoIcon value={isAssigned}/>
                                    </TableCell>
                                    {
                                        false && 
                                        <TableCell highlighted={isSelected} pointer={!disabled} align="center">
                                            <YesNoIcon value={isRead}/>
                                        </TableCell>
                                    }
                                    <TableCell highlighted={isSelected} pointer={!disabled} align="center">
                                        <YesNoIcon value={isOpen}/>
                                    </TableCell>
                                    <TableCell highlighted={isSelected} pointer={!disabled} datecell={true}>
                                        { 
                                            Boolean(updatedAt) && 
                                            moment.duration( moment(updatedAt).diff(now) ).humanize(true) 
                                        }
                                    </TableCell>
                                    <TableCell highlighted={isSelected} pointer={!disabled} datecell={true}>
                                        { 
                                            Boolean(createdAt) && 
                                            formatDateTime(moment(createdAt))
                                        }
                                    </TableCell>
                                </TableRowMod>
                            )
                        })}
                        
                    </TableBody>
                </Table>
            </TableContainer>
            {
                debug && 
                <JSONViewer src={tickets}/>
            }
            {
                hasTickets && 
                <TablePagination
                    disabled            = {disabled}
                    rowsPerPageOptions  = {[1, 5, 10, 25]}
                    component           = 'div'
                    count               = {totalItems}
                    rowsPerPage         = {limit}
                    page                = {page}
                    onPageChange        = {handleChangePage}
                    onRowsPerPageChange = {handleChangeRowsPerPage}
                />
            }
            {
                !hasTickets && 
                <SpaceBox>
                    <NoDataPrompt disabled = {disabled} onClick = {!adminMode ? handleClickCreate : undefined} >
                        There are no support tickets
                    </NoDataPrompt>
                </SpaceBox>
            }
        </RootContainer>
    );
}

export default TicketsTable;