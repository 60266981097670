/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Comments Body
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       12th May 2022

*******************************************************************************************/
import React                            from 'react';
import {isEmpty}                        from 'lodash';
import { 
    styled, 
    Paper, 
    Box, 
    Typography 
}                                       from '@mui/material';
import { CommentForm }                  from 'components/forms';
import { 
    useUser,
    useTranslation
}                                       from 'contexts';

// Local Components
import CommentsContent                  from './CommentsContent';
import CommentsActions                  from './CommentsActions';
import CommentsAlert                    from './CommentsAlert';

const noop = () => {};
const obj = {};

const Root = styled(Box,{
    shouldForwardProp : prop => prop !== 'rootBox'
})(({theme,rootBox = false}) => ({
    ...(rootBox && {
        borderBottom : `1px dashed ${theme.palette.divider}`
    })
}));

const EditContainer = styled(Box)(({theme}) => ({
    paddingBottom : theme.spacing(1)
}));

const ReplyContainer = styled(Box)(({theme}) => ({
    transform : `translatey(calc(${theme.spacing(1)} - 1px))`,
    margin : parseInt(theme.spacing(-1)) + 1,
    marginTop : theme.spacing(1),
    marginBottom : theme.spacing(1)
}));

const DeleteContainer = styled(Box)(({theme}) => ({
    position :'relative',
    left : theme.spacing(-1),
    width :`calc(100% + ${theme.spacing(2)})`
}));

const ReplyPaper = styled(Paper)(({theme}) => ({
    background : 'transparent',
    padding : theme.spacing(1),
    paddingBottom : theme.spacing(1),
    paddingTop : theme.spacing(1),
    borderBottomRightRadius : theme.spacing(1),
    borderTopLeftRadius : theme.spacing(1),
}));

/**
 * 
 * COMMENT DATA
 * 
 */
export const CommentsBody = ({
    data                                = obj,
    level                               = 0, 
    loading                             = false, 
    deleting                            = false,
    newComment                          = false,
    canEdit         : canEditIn         = false,
    onReply         : handleReply       = noop, 
    onDelete        : handleDelete      = noop,
    onEdit          : handleEdit        = noop,

    onEditing       : handleEditing     = noop,
    onReplying      : handleReplying    = noop,
    onDeleting      : handleDeletingIn  = noop,
}) => {
    const {t}                                   = useTranslation();
    const {userId}                              = useUser();
    
    const [replying,        setReplying]        = React.useState(false);
    const [reply,           setReply]           = React.useState(false);
    const [deleteHighlight, setDeleteHighlight] = React.useState(deleting);
    const [editing,         setEditing]         = React.useState(false);
    const [edit,            setEdit]            = React.useState(undefined);

    const handleReplyStart                      = React.useCallback(() => setReply(true), []);
    const handleReplyCancel                     = React.useCallback(() => setReply(false), []);
    const handleEditStart                       = React.useCallback(() => setEdit({ parentId : data.parent, id : data.id, comment : data.comment}), [data]);
    const handleEditCancel                      = React.useCallback(() => setEdit(undefined), []);
    const handleDeleting                        = React.useCallback((value) => {
        handleDeletingIn(value);
        setDeleteHighlight(value);
    }, [handleDeletingIn])

    React.useEffect(() => {
        handleEditing(Boolean(edit));
    },[edit,handleEditing])

    React.useEffect(() => {
        handleReplying(Boolean(reply));
    },[reply,handleReplying])

    React.useEffect(() => {
        setDeleteHighlight(deleting);
    },[deleting])

    const {
        comment = '', 
        censored,
        moderated,
        user : {
            id : authorId   = undefined, 
        } = {} 
    }                                           = React.useMemo(() => data || {}, [data]);
    const isOwner                               = React.useMemo(() => Boolean(userId && authorId === userId), [authorId, userId]);
    const isAdminComment                        = React.useMemo(() => (data?.user?.metadata?.roles || []).includes('admin'), [data?.user?.metadata?.roles])
    const isDeleted                             = React.useMemo(() => Boolean(data?.deleted), [data])
    const isModerated                           = React.useMemo(() => Boolean(data?.moderated), [data])
    const isCensored                            = React.useMemo(() => Boolean(data?.censored), [data])
    const showActions                           = React.useMemo(() => (
        !isCensored && !isDeleted
    ), [isCensored, isDeleted]);
    const canEdit                               = React.useMemo(() => (
        !isCensored && !isModerated && isOwner && !reply && !deleteHighlight && !deleting && canEditIn
    ), [canEditIn, deleteHighlight, deleting, isCensored, isModerated, isOwner, reply]);
    const moderationReason = React.useMemo(() => (
        isModerated 
            ? ((data?.moderationLogs || [])[data?.moderationLogs?.length-1]?.reason || "").toUpperCase()
            : undefined
    ),[data?.moderationLogs, isModerated])

    // Handle Excape Key Press
    React.useEffect(() => {
        const escFunction = (event) => {
            if (event.key === "Escape") {
                setReply(false);
                setEdit(undefined);
            }
        }
        document.addEventListener("keydown", escFunction);
        return () => {
            document.removeEventListener("keydown", escFunction);
        }
    },[])

    const handleReplySubmit = React.useCallback( (data) => new Promise((resolve) => {
        setReplying(true);
        handleReply(data)
            .then((result) => {
                if(isEmpty(result)) {
                    handleReplyCancel();
                }
                resolve(result);
            })
            .finally(() => {
                setReplying(false)
            });
    }), [handleReply, handleReplyCancel]);

    const handleEditSubmit = React.useCallback( (data) => new Promise((resolve) => {
        setEditing(true);
        handleEdit(data)
            .then((result) => {
                if(isEmpty(result)) handleEditCancel();
                resolve(result);
            })
            .finally(() => {
                setEditing(false)
            });
    }), [handleEdit, handleEditCancel]);

    const handleDeleteSubmit = React.useCallback( () => {
        handleDelete(data?.id);
    }, [data?.id, handleDelete]);

    const formProps = React.useMemo(() => ({
        SubmitButtonProps   : {size:'small'},
        CancelButtonProps   : {size:'small'},
        ResetButtonProps    : {size:'small'},
    }), []);

    const replyFormData = React.useMemo(() => ({
        parentId    : data.id, 
        id          : undefined,    
        comment     : undefined
    }),[data.id])
    
    const isRootBox = React.useMemo(() => (
        Boolean(level === 0 && !newComment)
    ),[level, newComment]);

    return ( 
        <Root rootBox={isRootBox}>
            {   
                !comment &&
                <Typography>
                    {t('components.comments.noComment')}
                </Typography>
            }
            { 
                comment && !edit && 
                <CommentsContent 
                    comment         = {
                        censored 
                            ? t('components.comments.commentCensored')
                            : moderated 
                                ? t('components.comments.commentModerated', {reason:moderationReason})
                                : comment
                    }
                    canEdit         = {canEdit}
                    replying        = {Boolean(reply)}
                    newComment      = {newComment}
                    deleting        = {deleteHighlight}
                    onDoubleClick   = {canEdit ? handleEditStart : null} 
                    linkify         = {isAdminComment}
                />
            }
            
            {
                comment && edit && 
                <EditContainer id = "commentEditContainer">
                    <CommentForm 
                        disabled            = {editing || !canEdit}
                        formData            = {edit}
                        FormProps           = {formProps}
                        showCancelButton    = {true}
                        showResetButton     = {false}
                        onSubmit            = {handleEditSubmit}
                        onCancel            = {handleEditCancel}
                        placeholder         = {t('components.comments.editComment')}
                        submitText          = {t('components.comments.update')}
                        alertComponent      = {CommentsAlert}
                    />
                </EditContainer>
            }

            {   
                comment && reply &&
                <ReplyContainer id = "commentReplyContainer">
                    <ReplyPaper>
                        <CommentForm 
                            rows                = {10}
                            variant             = "outlined"
                            label               = {t('components.comments.writeComment')}
                            disabled            = {replying}
                            formData            = {replyFormData}
                            FormProps           = {formProps}
                            showCancelButton    = {true}
                            showResetButton     = {false}
                            onSubmit            = {handleReplySubmit}
                            onCancel            = {handleReplyCancel}
                        />
                    </ReplyPaper>
                </ReplyContainer>
            }

            {
                comment && showActions && 
                <DeleteContainer id = "commentDeleteContainer">
                    <CommentsActions 
                        data            = {data} 
                        loading         = {loading} 
                        deleting        = {deleting}
                        reply           = {reply} 
                        edit            = {edit} 
                        onClickReply    = {handleReplyStart} 
                        onClickEdit     = {handleEditStart} 
                        onClickDelete   = {handleDeleteSubmit}
                        onDeleting      = {handleDeleting}
                    />
                </DeleteContainer>
            }
        </Root>
    )
};

export default CommentsBody;