/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Dropzone Uploader
********************************************************************************************
https://react-dropzone-uploader.js.org/

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       20th June 2021

*******************************************************************************************/
import React                            from 'react';
import Dropzone                         from 'react-dropzone-uploader'
import { css as emotionCss }            from "@emotion/css";
import { 
    styled, 
    css, 
    useTheme,
    Button, 
    Box, 
    Chip, 
    DialogActions, 
    LinearProgress,
    Typography,
}                                       from '@mui/material';
import CancelIcon                       from '@mui/icons-material/Cancel';
import RemoveCircleIcon                 from '@mui/icons-material/RemoveCircle';
import RestartIcon                      from '@mui/icons-material/SettingsBackupRestore';

import { FormAlert }                    from 'components';
import { withTranslation }              from './hoc';
import { 
    useStateEphemeral, 
    useFileUploader 
}                                       from 'hooks';

import 'react-dropzone-uploader/dist/styles.css';
import './styleOverrides/dropzoneUploader.css';

const styles = {
    dropzone : theme => ({
        display         : 'flex',
        flexDirection   : 'column',
        alignItems      : 'center',
        width           : '100%',
        minHeight       : 120,
        overflow        : 'scroll',
        position        : 'relative',
        boxSizing       : 'border-box',
        transition      : 'all .15s linear',
        border          : '0px solid #d9d9d9',
        borderRadius    : theme.spacing(0.5),
    }),
    preview : theme => ({
        padding         : '10px 3%',
        display         : 'flex',
        flexDirection   : 'row',
        alignItems      : 'center',
        justifyContent  : 'space-between',
        position        : 'relative',
        width           : '100%',
        minHeight       : 60,
        zIndex          : 1,
        borderBottom    : `1px solid ${theme.palette.divider}`,
        boxSizing       : 'border-box',
        color           : [theme.palette.primary.textContrast,'!important'],
        fontSize        : '0.75rem'
    }),
    inputLabelWithFiles : theme => ({
        width           : '100%',
        cursor          : 'pointer',
        textAlign       : 'center',
        padding         : theme.spacing(2)
    })
}

const Root = styled(Box)(({theme}) => ({
    width           : '100%',
    border          : `1px dashed ${theme.palette.divider}`,
    padding         : theme.spacing(0),
    borderRadius    : theme.spacing(0.5),
    background      : theme.palette.background.paper
}));

const AlertContainer = styled(Box)(({theme}) => ({
    padding         : theme.spacing(1),
    paddingBottom   : 0
}));

const HeaderContainer = styled(Box)(({theme}) => ({
    padding         : theme.spacing(1)
}));

const formatBytes = (b) => {
    const units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let l = 0, n = b;
    while (n >= 1024) {
      n /= 1024
      l += 1
    }
    return `${n.toFixed(n >= 10 || l < 1 ? 0 : 1)}${units[l]}`
}

const formatDuration = (seconds) => {
    const date = new Date(0)
    date.setSeconds(seconds)
    const dateString = date.toISOString().slice(11, 19)
    if (seconds < 3600) 
        return dateString.slice(3)
    return dateString
}

const noopsubmit    = (files, allFiles) => console.log("DropzoneUploader onSubmit(...) Not implemented");
const arr = [];

const PreviewTitle = withTranslation(({
    t, 
    status,
    name,
    size,
    duration,
    ...props
}) => {
    // Build the title
    const theTitle = React.useMemo(() => {
        let title = `${name || '?'}, ${formatBytes(size)}`
        if (duration) 
            title = `${title}, ${formatDuration(duration)}`

        // Failed
        if (status === 'error_upload_params' || status === 'exception_upload' || status === 'error_upload')
            title = t("components.dropzoneUploader.previewTitleUploadFailed", {title}) //`${title} (upload failed)`
        
        // Cancelled
        if (status === 'aborted') 
            title = t("components.dropzoneUploader.previewTitleCancelled", {title}) //`${title} (cancelled)`
        
        return title;
    },[t, duration, name, size, status])
    
    if(props.render) 
        return props.render({t, title : theTitle})

    return (
        <React.Fragment>
            {theTitle}
        </React.Fragment>
    )
})

const FileTooSmall = withTranslation(({ t }) => (
    <span>
        {t("components.dropzoneUploader.fileTooSmall") }
    </span>
));

const FileTooBig = withTranslation(({ t }) => (
    <span>
        {t("components.dropzoneUploader.fileTooBig") }
    </span>
));

class Preview extends React.PureComponent {
    
    render() {
        const {
            className,
            imageClassName,
            style,
            imageStyle,
            fileWithMeta : { 
                cancel, remove, restart 
            },
            meta : { 
                name = '', percent = 0, size = 0, previewUrl, status, duration, validationError 
            },
            isUpload,
            canCancel,
            canRemove,
            canRestart,
            extra: { 
                minSizeBytes 
            },
        } = this.props
    
        if (status === 'error_file_size' || status === 'error_validation') {
            return (
                <div className={className} style={style}>
                    <span className="dzu-previewFileNameError">
                        <PreviewTitle {...{status, name, size, duration}}/>
                    </span>
                    { 
                        status === 'error_file_size' && size < minSizeBytes && 
                        <FileTooSmall /> 
                    }
                    { 
                        status === 'error_file_size' && size >= minSizeBytes && 
                        <FileTooBig /> 
                    }
                    {
                        status === 'error_validation' && 
                        <span>
                            { String(validationError) }
                        </span>
                    }
                    {
                        canRemove && 
                        <span className="dzu-previewButton" onClick={remove}>
                            <RemoveCircleIcon color="primary"/>
                        </span>
                    }
                </div>
            )
        }
    
        return (
            <Box className={className} style={style}>
                
                <PreviewTitle 
                    {...{status, name, size, duration}} 
                    render = {({title}) => (
                        <Box>
                            { 
                                previewUrl 
                                    ? <img className={imageClassName} style={imageStyle} src={previewUrl} alt={title} title={title} />
                                    : <span className="dzu-previewFileName"> {title} </span>
                            }
                        </Box>
                    )}
                />
                    
                <Box className="dzu-previewStatusContainer" style={{width:'100%'}}>
                    {
                        isUpload && (
                        <LinearProgress 
                            variant="determinate" value={status === 'done' || status === 'headers_received' ? 100 : percent} 
                            style={{width:'100%', height:5, transform:'translateY(3px)'}}/>
                    )}
                    {
                        status === 'uploading' && canCancel && (
                        <span className="dzu-previewButton" onClick={cancel}>
                            <CancelIcon color="error"/>
                        </span>
                    )}
                    {
                        status !== 'preparing' && status !== 'getting_upload_params' && status !== 'uploading' && canRemove && (
                        <span className="dzu-previewButton" onClick={remove}>
                            <RemoveCircleIcon color="primary"/>
                        </span>
                    )}
                    {
                        ['error_upload_params', 'exception_upload', 'error_upload', 'aborted', 'ready'].includes(status) && canRestart && (
                        <span className="dzu-previewButton" onClick={restart}>
                            <RestartIcon color="secondary"/>
                        </span>
                    )}
                </Box>
            </Box>
        )
    }
}

const SubmitButton = withTranslation( ({
    t, 
    className, 
    buttonClassName, 
    style, 
    buttonStyle, 
    disabled : disabledIn, 
    content, 
    files, 
    onSubmit, 
    extra, 
    ...rest
}) => {
    
    const badMultiple       = React.useMemo(() => (
        Boolean(!extra.multiple && files?.length > 1)
    ), [extra.multiple, files?.length]);

    const disabled         = React.useMemo(() => (
        Boolean(
            disabledIn || 
            files.some( file => ['preparing', 'getting_upload_params', 'uploading'].includes(file.meta.status)) ||
            !files.some(file => ['headers_received', 'done'].includes(file.meta.status)) ||
            badMultiple
        )
    ),[disabledIn, badMultiple, files]);
    
    const handleSubmit      = React.useCallback( () => {
      onSubmit(files.filter(f => ['headers_received', 'done'].includes(f.meta.status)))
    }, [files, onSubmit]);

    return (
        <React.Fragment>
            {
                badMultiple && 
                <Box p={2}>
                    <Typography color="error" variant="body2" align="center">
                        {t('components.dropzoneUploader."pleaseRemoveItems', {quantity : files.length - 1})}
                    </Typography>
                </Box>
            }    
            <DialogActions className={className} style={{width:'100%',...style}}>
                <Button 
                    className   = {buttonClassName + 'xxxx'} 
                    style       = {buttonStyle} type="submit" 
                    color       = "primary" 
                    variant     = "contained" 
                    {...rest} 
                    onClick     = {handleSubmit} 
                    disabled    = {disabled}
                >
                    {content}
                </Button>
            </DialogActions>
        </React.Fragment>
    )
});

const Header = withTranslation( ({t, multiple, accept, acceptArray, count}) => {
    return (
        <Typography component='div' variant="body2">
            {
                multiple 
                    ? t('components.dropzoneUploader.acceptsMultiple')
                    : t('components.dropzoneUploader.acceptsOne')
            }
            { 
                acceptArray.map((item,ix) => (
                    <Box key={ix} style={{display:'inline-block'}} sx={{mx:0.5}}>
                        {acceptArray.length > 1 && ix === acceptArray.length - 1 && ' and '} 
                        <Chip size="small" color="primary" label={item} style={{margin:2}}/>
                    </Box>
                ))
            }
            {
                Boolean(count > 0) &&
                <Box>
                    {t('components.dropzoneUploader.filesSelected', {quantity : count})}
                </Box>
            }
        </Typography>
    )
});

export const DropzoneUploader = withTranslation( ({
    t,
    acceptImage             = true,
    acceptAudio             = false,
    acceptVideo             = false,
    acceptCustom : custom   = arr, // ie ['audio/mp3','audio/mp4'] etc ...
    onSubmit                = noopsubmit,
    multiple                = true,
    maxSizeBytes            = 10e6,
    ...props
}) => {
    const theme             = useTheme();

     // first we need to convert to something emotion can understand
    const dropzoneClass                     = css(styles.dropzone(theme));
    const previewClass                      = css(styles.preview(theme));
    const inputLabelWithFilesClass          = css(styles.inputLabelWithFiles(theme));

    const dropzoneClassName                 = emotionCss(dropzoneClass.styles);
    const previewClassName                  = emotionCss(previewClass.styles);
    const inputLabelWithFilesClassName      = emotionCss(inputLabelWithFilesClass.styles);

    // Check file restrictions
    const acceptPresets     = React.useMemo(() => ([ 
        acceptImage ? t('components.dropzoneUploader.acceptImage') : undefined, 
        acceptAudio ? t('components.dropzoneUploader.acceptAudio') : undefined,
        acceptVideo ? t('components.dropzoneUploader.acceptVideo') : undefined
    ]), [t, acceptAudio, acceptImage, acceptVideo]);

    const acceptCustom      = React.useMemo(() => (
        Array.isArray(custom) ? custom : [custom]
    ), [custom]);

    const acceptArray       = React.useMemo(() => (
        acceptPresets.concat(acceptCustom).filter(Boolean)
    ), [acceptCustom, acceptPresets]);

    const accept            = React.useMemo(() => acceptArray.join(','), [acceptArray]);

    if(!accept.length)
        throw new Error(t('components.dropzoneUploader.acceptLengthPositive'));

    // Keep track of the file count
    const [count, setCount] = React.useState(0);

    const {getSignedParams} = useFileUploader(); 

    const [messageSuccess,  setMessageSuccess]  = useStateEphemeral(undefined);
    const [messageError,    setMessageError]    = useStateEphemeral(undefined,5000);
    
    // called every time a file's `status` changes
    const handleChangeStatus = React.useCallback( ({ meta, file }, status) => { 
        if(['preparing','ready','started','uploading','restarted','done'].includes(status)){
            setMessageSuccess(
                t(`components.dropzoneUploader.${status}`,{fileName : file.name})                    
            );
        }
        if(['aborted','removed'].includes(status)){
            setMessageError(
                t(`components.dropzoneUploader.${status}`,{fileName : file.name})   
            );
        }
        // console.log(status, meta, file) 
    }, [t, setMessageError, setMessageSuccess]);
    
    // receives array of files that are done uploading when submit button is clicked
    const handleSubmit = React.useCallback( (files, allFiles) => {
        Promise.resolve()
            .then(() => {
                if(files.length > 1 && !multiple)
                    throw new Error( t('components.dropzoneUploader.onlyOneFilePermitted') );
                return onSubmit(files,allFiles)
            })
            .then(() => {
                allFiles.forEach(f => f.remove());
                setMessageError(undefined);
                setMessageSuccess( t('components.dropzoneUploader.allFilesProcessed') );
            })
            .catch(err => {
                setMessageError( t('components.dropzoneUploader.errorSubmitting', {message : err.message}) );
            });
    }, [t, multiple, onSubmit, setMessageError, setMessageSuccess]);

    const InputText = React.useCallback( (files) => {
        setCount(files.length);
        return (
            <Typography align="center" color="textPrimary">
                { t('components.dropzoneUploader.dragFileOrClick') }
            </Typography>
        )
    }, [t]);

    // specify upload params and url for your files
    const getUploadParams = React.useCallback( ({file, meta: {name, type} }) => new Promise((resolve) => {
        getSignedParams({fileName:name, contentType:type, maxSizeBytes})
            .then(resolve)
            .catch(err => {
                console.error(err);
                setMessageError(err.message)
            })
        }), 
        [getSignedParams, maxSizeBytes, setMessageError]
    );

    return (
        <Root>
            {
                messageError && 
                <AlertContainer>
                    <FormAlert severity="error">
                        {messageError}
                    </FormAlert>
                </AlertContainer>
            }
            {
                messageSuccess && 
                <AlertContainer>
                    <FormAlert severity="info">
                        {messageSuccess}
                    </FormAlert>
                </AlertContainer>
            }

            <HeaderContainer>
                <Header multiple={multiple} accept={accept} acceptArray={acceptArray} count={count}/>
            </HeaderContainer>

            <Dropzone
                accept                  = {accept}
                getUploadParams         = {getUploadParams}
                onChangeStatus          = {handleChangeStatus}
                onSubmit                = {handleSubmit}
                inputContent            = {InputText}
                inputWithFilesContent   = {InputText}
                SubmitButtonComponent   = {SubmitButton}
                PreviewComponent        = {Preview}
                classNames              = {{
                    dropzone            : dropzoneClassName, 
                    inputLabelWithFiles : previewClassName,
                    preview             : inputLabelWithFilesClassName
                }}
                multiple                = {multiple}
                {...props}
            />
        </Root>
    )
});

export default DropzoneUploader;