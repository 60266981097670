/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Network On/Off Icon
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       8th March 2021

*******************************************************************************************/

import React                            from 'react';
import { 
    styled, 
    Box,
    Tooltip 
}                                       from '@mui/material';
import {useNetwork}                     from 'contexts';
import {
    Wifi        as NetworkOnIconMUI,
    WifiOff     as NetworkOffIconMUI
}                                       from '@mui/icons-material';
import {withTranslation}                from './hoc';

const NetworkOnIcon = styled(NetworkOnIconMUI)(({theme}) => ({
    color : theme.palette.secondary.main
}));

const NetworkOffIcon = styled(NetworkOffIconMUI)(({theme}) => ({
    color : theme.palette.error.main
}))

const Online = withTranslation( ({t,...props}) => (
    <Tooltip title={t('common.networkConnected')}>            
        <NetworkOnIcon {...props}/> 
    </Tooltip>
));

const Offline = withTranslation( ({t,...props}) => (
    <Tooltip title={t('common.networkDisconnected')}>            
        <NetworkOffIcon color="error" {...props}/> 
    </Tooltip>
));

// HOC to set isOnline prop based on useSocketIo hook
const withOnlineStatus = Component => props => {
    const {isOnline} = useNetwork();
    return (
        <Component isOnline={isOnline} {...props}/>
    )
}

export const NetworkOff = withOnlineStatus( ({isOnline, ...props}) => {
    if(isOnline) return null;
    return (
        <Offline {...props}/>
    )
});

export const NetworkOn = withOnlineStatus( ({isOnline, ...props}) => {
    if(!isOnline) return null;
    return (
        <Online {...props}/>
    )
});

export const NetworkOnOff = ({children, ...props}) => (
    <Box sx={{p:1}}>
        <NetworkOn  {...props} />
        <NetworkOff {...props} />
    </Box>
)

export default NetworkOnOff;