/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Playlist Component
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       25th October 2022

*******************************************************************************************/
import React                    from 'react';
import moment                   from 'moment';
import {titleCase}              from 'title-case';
import isNil                    from 'lodash/isNil';
import pickBy                   from 'lodash/pickBy';
// import { v4 as uuidv4 }         from 'uuid';
import {
    styled,
    alpha,
    lighten,
    darken,
    useTheme,
    useMediaQuery,
    Box,
    Chip,
    Collapse,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow as TableRowMUI,
    TableCell as TableCellMUI,
    Tooltip,
    Typography,
    // IconButton,
    CardMedia,
    MenuItem
}                               from '@mui/material';
import AudioFileIcon            from '@mui/icons-material/AudioFile';
import PlayIcon                 from '@mui/icons-material/PlayCircleFilled';
import PauseIcon                from '@mui/icons-material/PauseCircleFilled';
import ReplayIcon               from '@mui/icons-material/Replay';
import PlayCircleOutlineIcon    from '@mui/icons-material/PlayCircleOutline';
// import CommentIcon              from '@mui/icons-material/Comment';
import CloseIcon                from '@mui/icons-material/Close';
import glyphs                   from 'components/glyphs';
import {
    Button,
    JSONViewer,
    LibraryJinkePlayer,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    PublicBooksAdvertisement,
    // DraggableDialog,
    // CommentsThread,
    FatButton,
    EditLibraryButton,
    IconMenu,
    CompletionBar
}                               from 'components';
import { withTranslation }      from './hoc';
import { CancelIconButton }     from './iconButtons';
import SkeletonPlaylist         from 'components/skeletons/SkeletonPlaylist';
import {
    SkeletonFancyNoLibrariesSelected
}                               from 'components/skeletons';

import {
    useUser,
    useNetwork,
    MasterLibraryProvider,
    // useAlert,
    // useCommentsThread
}                               from 'contexts';
import { 
    useCanQuery, 
    useImageCDN,
    withClickHandler,
    // useOnScreen,
    useStateEphemeral
}                               from 'hooks';
import astrolabe                from 'resources/wiki/PersianAstrolabes.jpeg'

const STORAGE_KEY                   = "playprogress";
const DURATION_FORMATTER_DEFINITION = undefined; //"hh:mm:ss"
const COLLAPSE_DURATION             = 100;
const FEATS                         = ['angle', 'aspect', 'celestialPoint', 'celestialBody', 'celestialPoint2', 'celestialBody2', 'house', 'house2', 'sign', 'sign2'];
const ICONS_IMAGE_HEIGHT            = 12;

const arr = [];
const obj = {};

/*
function customJoin(arr, s1, s2) {
    return arr.slice(0,-1).join(s1).concat(arr.length > 1 ? s2 : '', arr.slice(-1));
}
*/

const ProgressBar = styled(Box,{
    shouldForwardProp : prop => !['pcnt'].includes(prop)
})(({theme, pcnt = 0}) => {
    const   ceA = alpha(theme.palette.primary.main,0.50), 
            ceB = alpha(theme.palette.primary.main,0.65),
            ceC = alpha(theme.palette.primary.main,0.75);
    const   ceW = 5;
    return {
        position                : 'absolute', 
        left                    : 0, 
        bottom                  : 0, 
        height                  : theme.spacing(1.0), 
        // borderTopRightRadius    : theme.spacing(0.5), 
        width                   :`${pcnt}%`, 
        //background              : theme.palette.primary.main,
        background              : `repeating-linear-gradient( 45deg, ${ceA} 0px, ${ceA} ${ceW}px, ${ceB} ${ceW}px, ${ceB} ${2*ceW}px)`,
        '&:hover' : {
            background : `repeating-linear-gradient( 45deg, ${ceB} 0px, ${ceB} ${ceW}px, ${ceC} ${ceW}px, ${ceC} ${2*ceW}px)!important`
        }
    }
});

const TableRow = styled(TableRowMUI,{
    shouldForwardProp : prop => !['invalid','selected','playing','lastRow','pointer'].includes(prop)
})(({theme,invalid = false, selected = false, playing = false, lastRow = false, pointer = false, unauthorized = false}) => {
    const   ceA     = alpha(theme.palette.error.light,0.50), 
            ceB     = alpha(theme.palette.error.light,0.65),
            ceC     = alpha(theme.palette.error.light,0.75);
    const   ceAA    = alpha(theme.palette.divider,0.05), 
            ceBB    = alpha(theme.palette.divider,0.10),
            ceCC    = alpha(theme.palette.divider,0.15);
    const   ceW = 5;
    return {
        cursor : pointer ? 'pointer' : 'default',
        background : `${alpha(theme.palette.background.paper,0.10)}!important`,
        '&:hover' : {
            background : `${darken(theme.palette.background.paper,0.125)}!important`,
        },
        ...(invalid && {
            // https://dev.to/snkds/how-to-create-striped-backgrounds-with-css-5dfn
            background : `repeating-linear-gradient( 45deg, ${ceA} 0px, ${ceA} ${ceW}px, ${ceB} ${ceW}px, ${ceB} ${2*ceW}px)`,
            '&:hover' : {
                background : `repeating-linear-gradient( 45deg, ${ceB} 0px, ${ceB} ${ceW}px, ${ceC} ${ceW}px, ${ceC} ${2*ceW}px)!important`
            }
            /*
            background : alpha(theme.palette.error.light,0.50),
            '&:hover' : {
                background : `${alpha(theme.palette.error.light,0.65)}!important`,
            }
            */
        }),
        ...(!invalid && unauthorized &&{
            // https://dev.to/snkds/how-to-create-striped-backgrounds-with-css-5dfn
            background : `repeating-linear-gradient( 45deg, ${ceAA} 0px, ${ceAA} ${ceW}px, ${ceBB} ${ceW}px, ${ceBB} ${2*ceW}px)`,
            '&:hover' : {
                background : `repeating-linear-gradient( 45deg, ${ceBB} 0px, ${ceBB} ${ceW}px, ${ceCC} ${ceW}px, ${ceCC} ${2*ceW}px)!important`
            }
            /*
            background : alpha(theme.palette.error.light,0.50),
            '&:hover' : {
                background : `${alpha(theme.palette.error.light,0.65)}!important`,
            }
            */
        }),
        ...(selected && !invalid && {
            // border      : `2px solid ${theme.palette.primary.light}`,
            background  : `${alpha(theme.palette.primary.light,0.5)}!important`,
            '&:hover' : {
                background  : alpha(theme.palette.primary.light,0.25),
                // background : `${darken(theme.palette.info.light,0.05)}!important`,
            }
        }),
        ...(playing && !invalid &&{
            fontWeight : 600
        }),
        ...(lastRow && {
            borderBottom : 'none'
        }) 
    }
});

const TableCell = styled(TableCellMUI)(({theme}) => ({
    // verticalAlign       : 'top', 
    borderBottom        : 'inherit',
    background          : 'transparent',
    "&.MuiTableCell-root" : {
        padding         : theme.spacing(0.5),
        paddingLeft     : theme.spacing(0.5),
        paddingRight    : theme.spacing(0.5),
    }
}));

const ChaptersContentsResume = styled(Box,{
    shouldForwardProp : prop => prop !== 'conceal'
})(({conceal = false}) => ({
    position : 'relative',
    // borderRadius : 8,
    // overflow:'hidden',
    ...(conceal && {
        // maxHeight       : 'calc(100vh - 240px)', 
        aspectRatio     : '1.41',
        overflow        : 'hidden'
    })
}));

const TypographyNotReady = styled(Typography)(({theme}) => ({
    color           : theme.palette.error.main,
    fontWeight      : 400
}));

const Gradient = styled(Box)(({theme}) => ({
    position        :'absolute',
    top             : 0,
    bottom          : 0,
    left            : 0,
    right           : 0, 
    background      : `linear-gradient(to bottom, rgba(255, 255, 255, 0.0), ${darken(theme.palette.action.active,0.75)})`
}));


const OverlayButtonContainer = styled(Box)(({theme}) => {
    const   ceA = theme.palette.mode === 'light' 
                    ? lighten(theme.palette.primary.light,0.90) 
                    : darken(theme.palette.primary.light,0.50), 
            ceB = theme.palette.mode === 'light' 
                    ? lighten(theme.palette.primary.light,0.95) 
                    : darken(theme.palette.primary.light,0.55) 
    const   ceW = 20;
    return {
        position        : 'absolute', 
        top             : 0,
        left            : 0,
        right           : 0,
        bottom          : 0,
        // backgroundImage : `url("${astrolabe}")`,
        // background      : theme.palette.background.paper,
        // background      : alpha(theme.palette.background.paper, 0.50), 
        // backdropFilter  : 'blur(4px)', 
        border          : `1px solid ${theme.palette.divider}`,
        borderRadius    : theme.spacing(1), 
        // margin          : theme.spacing(-0.5),
        // https://dev.to/snkds/how-to-create-striped-backgrounds-with-css-5dfn
        background : `repeating-linear-gradient( 135deg, ${ceA} 0px, ${ceA} ${ceW}px, ${ceB} ${ceW}px, ${ceB} ${2*ceW}px)`,
    }
});

const FeatureIconSpacer = styled(Box,{
    shouldForwardProp : prop => prop !== 'iconHeight'
})(({theme,iconHeight = 0}) => ({
    display         : 'inline-block',
    marginBottom    : iconHeight/2,
    marginLeft      : theme.spacing(0.5), 
    marginRight     : theme.spacing(0.5), 
    width           : theme.spacing(1),
    borderBottom    : `2px solid ${theme.palette.text.disabled}`,
}));

const FeatureIconImage = styled(CardMedia, {
    shouldForwardProp : prop => prop !== 'iconHeight'
})(({iconHeight = 0}) => ({
    height          : iconHeight,
    width           : 'auto'
}));

const secToTime = (seconds, separator) => {
    return [
        parseInt(seconds / 60 / 60),
        parseInt(seconds / 60 % 60),
        parseInt(seconds % 60)
    ].join(separator ? separator : ':')
    .replace(/\b(\d)\b/g, "0$1").replace(/^00:/,'');
};

const noop = () => {};

const FastCollapse = (props) => (
    <Collapse {...props} timeout={COLLAPSE_DURATION} />
)

const FeatureIcons = ({metadata = {}, ...props}) => {

    // Theme
    const theme                 = useTheme();

    // The features and subset keys
    const featsKeys             = Object.keys(metadata).filter(x => FEATS.includes(x));

    // Images State
    const images                = React.useMemo(() => (
        glyphs[theme.palette.mode === 'light' ? 'dark' : 'light']
    ),[theme.palette.mode]);

    const featsArray = React.useMemo(() => (
        featsKeys
            .map(feat => {
                let m       = metadata[feat] || {};
                let name    = (m?.name || '').toLowerCase();
                let src     = images[name];
                return src && name 
                    ? [src, name] 
                    : null;
            })
            .filter(Boolean)
    ), [featsKeys, images, metadata]);

    // No Features
    if(!Array.isArray(featsArray) || featsArray.length <= 0)
        return null

    // Render Features
    return (
        <Box display="flex" sx={{"& > * + *" : { ml : 1}}}>
            {
                featsArray.map(([src, name], ix, arr) => {
                    const title = name ? titleCase(name) : `Feature ${ix + 1}`;
                    return (
                        <React.Fragment key={ix}>
                            {
                                false && ix > 0 && ix <= arr.length - 1 && 
                                <FeatureIconSpacer iconHeight={ICONS_IMAGE_HEIGHT} />
                            }
                            <Box key={ix}>
                                <Tooltip title={title}>
                                    <FeatureIconImage iconHeight={ICONS_IMAGE_HEIGHT} component="img" src={src} alt={name} />
                                </Tooltip>
                            </Box>
                        </React.Fragment>
                    )
                })
            }
        </Box>
    )
}

/*
const Comments = ({identifier, identifierRef, group, groupRef, metadata, ...props}) => {
    
    const [open, setOpen] = React.useState(false);
    
    const handleOpen    = React.useCallback((e) => {
        if(e) e.stopPropagation();
        setOpen(true)
    }, []);

    const handleClose   = React.useCallback((e) => {
        if(e) e.stopPropagation();
        setOpen(false)
    }, []);

    if(!identifier || !group)
        return null;

    return (
        <>
            <IconButton onClick={handleOpen}>
                <CommentIcon/>
            </IconButton>
            <DraggableDialog
                title                   = {"Comments"}
                open                    = {open}
                onClose                 = {handleClose}
                onCancel                = {handleClose}
                size                    = "md"
                fullWidth               = {true}
                showButtons             = {false}
            >
                <CommentsThread 
                    group               = {group} 
                    groupRef            = {groupRef}
                    identifier          = {identifier}
                    identifierRef       = {identifierRef} 
                    showTitle           = {false} 
                    showDetachedButtons = {false} 
                    metadata            = {metadata}
                    privateThread       = {false}
                />
            </DraggableDialog>
        </>
    )
}
*/

const stripQuery = x => (x || '').split('?').shift() 


const Chapters = withTranslation( ({
    t,
    parentId        = undefined,
    playing         = false, 
    isAvailable     = false,
    autoscroll      = true,
    playList        = arr, 
    files           = arr, 
    playListIndex   = 0, 
    currentTime     = 0, 
    onClick         : handleClick, 
    onDoubleClick   : handleDoubleClick, 
}) => {
    
    const theme                         = useTheme();
    const {isAdmin}                     = useUser();
    const {allowQueryForAdmin}          = useCanQuery();
    const [fileClicked, setFileClicked] = useStateEphemeral(undefined,5000);
    const filePlaying                   = React.useMemo(() => stripQuery(playList[playListIndex]?.file), [playList, playListIndex])

    const scrollToCurrent   = React.useCallback( () => {
        if(parentId){
            const el = document.getElementById(parentId);    
            el.style.scrollMargin = '5px';
            el.scrollIntoView();
        }
        const divElement = document.getElementById('isPlaying');
        if(divElement){ 
            divElement.style.scrollMargin = '50px';
            divElement.scrollIntoView({ block : 'nearest', inline : 'start' });
        }
    }, [parentId]);

    // Scroll when playListIndex Changes
    React.useEffect(() => {
        if(fileClicked && fileClicked === filePlaying)
            return;
        if(autoscroll && playing){
            const timeout = setTimeout(scrollToCurrent, COLLAPSE_DURATION + 50);
            return () => {
                clearTimeout(timeout);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[autoscroll, playListIndex, playing, scrollToCurrent]);

    const mdDown    = useMediaQuery(theme => theme.breakpoints.down('md'));
    const lgUp      = useMediaQuery(theme => theme.breakpoints.up('lg'));
    
    return (
        <>
            <TableContainer >
                <Table>
                    <TableHead />
                    <TableBody>
                        { 
                            files.map(({fileGetSignedUrl : file, description, name, metadata, masterLibrary, duration, permission, canListen},ix) => {
                                const   baseFile    = stripQuery(file);
                                const   isThisFile  = baseFile && baseFile === filePlaying; // Comparison minus query parameters
                                const   valid       = Boolean(file);
                                const   categories  = (
                                    canListen 
                                        ? (
                                            Object.keys( 
                                                pickBy(permission, Boolean) 
                                            )
                                                .filter(x => (!(['everyone','sample'].includes(x))))
                                                .map(x=> titleCase(x.replace('owner','customer')))
                                                .join(', ') 
                                        ) : ''
                                );
                                const formattedDuration = (
                                    duration > 0 
                                        ? moment.duration(duration,'seconds').format(DURATION_FORMATTER_DEFINITION) 
                                        : ''
                                )
                                return (
                                    <TableRow 
                                        id              = {isThisFile ? 'isPlaying' : undefined} 
                                        onClick         = {
                                            (isAvailable || isAdmin) && file 
                                                ? () => {
                                                    setFileClicked(baseFile);
                                                    handleClick(file);
                                                }
                                                : undefined
                                        } 
                                        onDoubleClick   = {
                                            (isAvailable || isAdmin) && file 
                                                ? () => {
                                                    setFileClicked(baseFile);
                                                    handleDoubleClick(file) 
                                                }
                                                : undefined
                                        } 
                                        key             = {ix} 
                                        unauthorized    = {!canListen}
                                        invalid         = {canListen && !valid}
                                        selected        = {isThisFile}
                                        playing         = {isThisFile && playing}
                                        lastRow         = {ix >= files.length - 1}
                                        pointer         = {valid && (isAvailable || isAdmin)}
                                        hover 
                                        sx              = {{position:'relative'}}
                                    >
                                        <TableCell sx={{whiteSpace:'nowrap', textAlign:'right',pl:theme => `${theme.spacing(1)}!important`}}>
                                            {ix + 1}
                                        </TableCell>
                                        <TableCell sx={{px:theme => `${theme.spacing(0)}!important`}}>
                                            {
                                                <AudioFileIcon 
                                                    sx      = {{
                                                        fontSize    : 18, 
                                                        transform   : 'translatey(2px)'
                                                    }} 
                                                    color   = {
                                                        valid 
                                                            ? (
                                                                isThisFile 
                                                                    ? "primary" 
                                                                    : "default"
                                                            )
                                                            : !canListen ? "black" : "error"
                                                    }
                                                />
                                            }
                                        </TableCell>
                                        <TableCell style={{minWidth:150}} width="100%">
                                            <Typography component="div" sx={{fontSize:'inherit'}}>
                                                {description || titleCase(name)}
                                            </Typography>
                                            {
                                                !canListen &&
                                                <Typography component="div" sx={{fontSize:'inherit',fontWeight:400}}>
                                                    { t('components.playlist.availableFor', { categories } )}
                                                </Typography>
                                            }
                                            {
                                                canListen && !valid && 
                                                <Typography component="div" sx={{fontSize:'inherit',fontWeight:400}}>
                                                    { t('components.playlist.topicUnavailable') }
                                                </Typography>
                                            }
                                            {
                                                mdDown &&
                                                <Box sx={{mt:1}}>
                                                    <FeatureIcons metadata={metadata} />
                                                </Box>
                                            }    
                                        </TableCell>

                                        

                                        {
                                            !mdDown &&
                                            <TableCell align="left" sx={{px:0}}>
                                                <FeatureIcons metadata={metadata} />
                                            </TableCell>
                                        }           

                                        {
                                            !lgUp &&
                                            <TableCell style={{whiteSpace:'nowrap'}}>
                                                {
                                                    isThisFile && isAvailable && 
                                                    <Chip 
                                                        icon    = {!playing ? <PlayIcon/> : <PauseIcon/>} 
                                                        size    = "small" 
                                                        color   = "primary" 
                                                        label   = {playing ? "Pause" : "Play"}
                                                        // label   = {playing ? `${moment.duration(currentTime,'seconds').format(DURATION_FORMATTER_DEFINITION).replace(/millisecond(s?)/g,'ms').replace(/second(s?)/g,'s')}` : 'Play'}
                                                        style   = {{cursor:'pointer'}}
                                                    />
                                                }
                                            </TableCell>
                                        }

                                        <TableCell align="right" sx={{px:0, minWidth:lgUp ? 100 : 'initial'}}>
                                            {
                                                isAvailable && canListen && duration > 0
                                                    ? (
                                                        isThisFile && lgUp
                                                            ? (
                                                                <CompletionBar 
                                                                    icon            = {!playing ? <PlayIcon sx={{color:"white",fontSize : '1rem'}}/> : <PauseIcon sx={{color:"white" ,fontSize : '1rem'}}/>} 
                                                                    background      = {theme.palette.primary.light}
                                                                    color           = {theme.palette.primary.main}
                                                                    borderColor     = {null}
                                                                    pcntComplete    = {100 * currentTime/duration} 
                                                                    typographyProps = {{
                                                                        variant : 'body2',
                                                                        sx : {
                                                                            py          : 0.25,
                                                                            px          : 0.25,
                                                                            fontSize    : '0.75rem',
                                                                            color       : theme => theme.palette.getContrastText(theme.palette.primary.main)
                                                                        }
                                                                    }}
                                                                    sx = {{ 
                                                                        // minHeight    : theme => theme.spacing(3),
                                                                        borderRadius    : theme => theme.spacing(2), 
                                                                        whiteSpace      :'nowrap',
                                                                        px              :0.5
                                                                    }}
                                                                >
                                                                    {
                                                                        [
                                                                            currentTime < 1 
                                                                                ? '0:00' :
                                                                                moment.duration(currentTime,'seconds').format(DURATION_FORMATTER_DEFINITION).replace(/millisecond(s?)/g,'ms').replace(/second(s?)/g,'s') ,
                                                                            formattedDuration
                                                                        ].join(' / ')
                                                                    }
                                                                </CompletionBar>
                                                            )
                                                            : <Chip 
                                                                    size    = "small"
                                                                    label   = { formattedDuration }
                                                            />
                                                    )
                                                    : null
                                            }
                                        </TableCell>
                                        <TableCell align="right" sx={{pl:0,pr: allowQueryForAdmin ? 'initial' : 3}} onClick={e => e.stopPropagation()}>
                                            {
                                                allowQueryForAdmin &&
                                                <MasterLibraryProvider>
                                                    <IconMenu>
                                                        <EditLibraryButton libraryId={masterLibrary} ButtonProps={{color:'inherit', component:MenuItem, sx:{fontSize:'0.6rem',whiteSpace:'nowrap'}}}>
                                                            {t('common.editMaster')}
                                                        </EditLibraryButton>
                                                    </IconMenu>
                                                </MasterLibraryProvider>
                                            }
                                        </TableCell>

                                        {
                                            isThisFile &&
                                            <ProgressBar pcnt={100 * (currentTime / duration)} />
                                        }
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
});

const LibraryDetails = withTranslation( ({t, currentIndex = 0, totalQuantity : quantity = 0, hasFiles = true}) => {
    const index = currentIndex + 1;
    return (
        <>
            {
                hasFiles 
                    ? t("components.playlist.currentFileIsOf", {index, quantity})
                    : t("components.playlist.currentFileIs",   {index})
            }
        </>
    )
});

const LibraryPlayingSummary = withTranslation( ({
    t, 
    loading         = true, 
    isAvailable     = false, 
    playing         = false, 
    variant         = "body2", 
    currentIndex    = 0, 
    files           = arr, 
    includeDetails  = true, 
    compact         = false
}) => {
    const totalQuantity             = React.useMemo(() => Array.isArray(files) ? files.length : 0, [files]);
    const hasFiles                  = React.useMemo(() => totalQuantity > 0, [totalQuantity]);
    const totalDuration             = React.useMemo(() => hasFiles ? files.reduce((acc,cur) => acc + ((cur?.canListen ? cur.duration : 0) || 0),0) : 0, [files, hasFiles]);
    const durationLabel             = React.useMemo(() => (
        totalDuration !== 0 
            ? (
                moment
                    .duration(totalDuration,'seconds')
                    .format(DURATION_FORMATTER_DEFINITION) 
            ) : '0:00'
    ),[totalDuration]);


    if(loading || !hasFiles)
        return null;

    return (
        <>
            {
                isAvailable &&
                <Typography component="div" variant={variant} gutterBottom>
                    { !compact
                        ? t('components.playlist.includesTopicsWithDuration', { quantity : totalQuantity, duration : durationLabel} )
                        : <Chip size="small" label={durationLabel} />
                    }
                    {
                        includeDetails && !isNil(currentIndex) && playing &&  
                        <>
                            , <LibraryDetails 
                                hasFiles            = {hasFiles}
                                currentIndex        = {currentIndex}
                                totalQuantity       = {totalQuantity}
                            />
                        </>
                    } 
                </Typography>
            }
        </>
    )
});

const AccordionMod = withClickHandler(Accordion);

/*
const ScrollOrigin = ({id, expanded = false, playing = false, ...props}) => {
    
    const ref           = React.useRef(null);
    const isOnScreen    = useOnScreen(ref)

    const scrollToElement = React.useCallback( (id) => {
        if(!id) return;
        const divElement = document.getElementById(id);
        if(divElement){ 
            divElement.scrollIntoView({ 
                behavior    : 'smooth',
                block       : "nearest", 
                inline      : "nearest" 
            });
        }
    }, [])

    React.useEffect(() => {
        if(false && id && expanded && !isOnScreen && playing){
            scrollToElement(id);
        }
    },[id, expanded, isOnScreen, scrollToElement, playing])

    return (
        <Box ref={ref} id={id} {...props}/>
    )
}
*/

const ChaptersCollapseable = withTranslation( ({
    t,
    data                                    = arr,
    autoscroll                              = true,
    playing                                 = false, 
    playList                                = arr, 
    files                                   = arr, 
    playListIndex                           = 0, 
    currentTime                             = 0, 
    onClick         : handleClick           = noop, 
    onDoubleClick   : handleDoubleClick     = noop, 
    onPlayToggle    : handlePlayToggle      = noop,
    force                                   = false,
    loading                                 = true, 
    showResumeButtons                       = false, 
    isAvailable                             = false,
    allowComments                           = false,
    crossSellQuantity                       = 5, 
    sticky                                  = true,
    ...props
}) => {
    const theme                         = useTheme();
    // const uid                           = React.useMemo(uuidv4, [])
    const chapters                      = React.useMemo(() => [...new Set(files.map(f => f.metadata.chapterTitle))], [files]);
    const [playInThis,  setPlayInThis]  = React.useState(false);
    const [expanded,    setExpanded]    = React.useState(false /*chapters.length > 0 ? chapters[0] : false*/);

    const handleChange = React.useCallback(chapter => async (event, isExpanded) => {
        if(isExpanded){
            if(expanded){
                setExpanded(false);
                await new Promise(resolve => setTimeout(resolve, 250 + COLLAPSE_DURATION));
            }
            setExpanded(chapter);
        }else{
            setExpanded(false);
        }
    },[expanded]);

    const handleToggleClick = React.useCallback( (e) => {
        e.stopPropagation();
        handlePlayToggle();
    }, [handlePlayToggle]);

    // Set Expaned if Files Index Changes, or if playing state is changed
    React.useEffect(() => {
        if(!Array.isArray(data) || playListIndex < 0 || playListIndex > playList.length - 1) 
            return;
        const allFilesIndex = (data || []).findIndex(({fileGetSignedUrl:file}) => file && file === playList[playListIndex].file)
        if(allFilesIndex >= 0){
            let ex = data[allFilesIndex]?.metadata?.chapterTitle;
            if(playing) setExpanded(ex);    // Allows expanded to change based on clicking accordion
            setPlayInThis(ex);              // Retain value regardless on if expanded or not
        }else{
            setPlayInThis(false);
        }
    },[data, playing, playList, playListIndex])

    // Count Chapters and Appendixes
    const allow = React.useMemo(() => (isAvailable || force), [force, isAvailable]);

    let ch      = 0, 
        apx     = 0;

    return (
        <>
        {
            chapters.map((chapter,ix) => {
                const filesSubset   = files.filter(f => f.metadata.chapterTitle === chapter)
                const isAppendix    = Boolean(filesSubset.length > 0 ? filesSubset[0]?.metadata?.appendix : false)
                const isPrologue    = Boolean(filesSubset.length > 0 ? filesSubset[0]?.metadata?.prologue : false)
                const isEpilogue    = Boolean(filesSubset.length > 0 ? filesSubset[0]?.metadata?.epilogue : false)
                const isExpanded    = chapter === expanded && !showResumeButtons;
                const isPlayInThis  = chapter === playInThis;
                const [
                    darkValue,
                    lightValue
                ]                   = isExpanded ? [0.5,0.85] : [0.5,0.85];
                const colorBase     = isPrologue || isEpilogue ? theme.palette.success : theme.palette.info;
                const background    = (
                    theme.palette.mode === 'dark' 
                        ? darken( colorBase.dark, darkValue) 
                        : lighten(colorBase.light,lightValue)
                );
                const summaryArgs   = allow ? {} : {expandIcon:null};
                // const topId         = `${uid}-${ix}`
                const chapterPrefix = isAppendix 
                    ? t('components.playlist.appendixIndex',{index : ++apx})
                    : (
                        isPrologue 
                            ? t('components.playlist.prologue') 
                            : (
                                isEpilogue 
                                    ? t('components.playlist.epilogue')
                                    : t('components.playlist.chapterIndex',{index : ++ch})
                            )
                    );
                const Icon      = playing ? PauseIcon : PlayIcon;
                const parentId  = `chapter${ix}`;
                return (
                    <React.Fragment key = {ix}>
                        {
                            /*
                            <ScrollOrigin id={`container/${encodeURI(chapter)}`} playing={playing}>
                                <div id={topId} />
                            </ScrollOrigin>
                            */
                        }
                        <AccordionMod 
                            expanded                = {allow && isExpanded} 
                            onChange                = {allow ? handleChange(chapter) : undefined}
                            TransitionComponent     = {FastCollapse}
                            TransitionProps         = {{ unmountOnExit: true }} 
                        >
                            <AccordionSummary 
                                id          = {parentId}
                                first       = {Boolean(ix === 0)}
                                last        = {Boolean(ix === chapters.length - 1)}
                                expanded    = {isExpanded}
                                background  = {background} 
                                sticky      = {sticky} 
                                {...summaryArgs} 
                                style       = {{ 
                                    cursor : isAvailable ? 'pointer' : 'default'
                                }}
                            >
                                <Box display='flex' width="100%">
                                    <Box flexShrink={1} sx={{my:'auto'}}>
                                        <Typography variant="body1" sx={{color:'inherit'}}>
                                            <strong>{chapterPrefix}</strong> - {chapter} {allow && <>({filesSubset.length})</>}
                                        </Typography>
                                    </Box>
                                    {
                                        isPlayInThis && allow &&
                                        <Box pl={1} flexShrink={1} sx={{my:'auto',transform:'translatey(3px)'}}>
                                            { <Icon onClick = {handleToggleClick} style   = {{fontSize:20}} /> }
                                        </Box>
                                    }
                                    <Box flexGrow={1} sx={{my:'auto'}}/>
                                    {
                                        !allow && 
                                        <TypographyNotReady component="div" variant="body1">
                                            {t('components.playlist.notReadyPreparing')}
                                        </TypographyNotReady>
                                    }
                                    {
                                        isExpanded && 
                                        <Box flexShrink={1} sx={{my:'auto',transform:'translatey(3px)'}}>
                                            <LibraryPlayingSummary
                                                loading         = {loading}
                                                isAvailable     = {isAvailable}
                                                compact         = {true}
                                                playing         = {playing} 
                                                currentIndex    = {playListIndex} 
                                                files           = {filesSubset} 
                                                includeDetails  = {false}
                                                variant         = "body1"
                                            />
                                        </Box>
                                    }
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails spacing={0}>
                                <Chapters
                                    parentId        = {parentId}
                                    isAvailable     = {isAvailable}
                                    playing         = {playing}
                                    playList        = {playList}
                                    files           = {filesSubset}
                                    playListIndex   = {playListIndex}
                                    currentTime     = {currentTime}
                                    onClick         = {handleClick} 
                                    onDoubleClick   = {handleDoubleClick}
                                    autoscroll      = {autoscroll} // no dup
                                    allowComments   = {allowComments}
                                    {...props}
                                />
                            </AccordionDetails>

                            {
                                isExpanded && (ix+1) % 3 === 0 && ix > 0 &&
                                <Box mt={2}>
                                    <PublicBooksAdvertisement quantity={crossSellQuantity} />
                                </Box>
                            }
                            
                        </AccordionMod>
                    </React.Fragment>
                )
            })
        }
        </>
    )
});

const ChaptersCollapseableMod   = withClickHandler(ChaptersCollapseable);
const ChaptersMod               = withClickHandler(Chapters);

const PlayResumeOverlay = withTranslation( ({
    t,
    resumeNumber, 
    resumeTime, 
    onClickPlay     : handleClickPlay       = noop, 
    onClickResume   : handleClickResume     = noop, 
    onClickDismiss  : handleClickDismiss    = noop,
}) => {
    
    return (
        <Box display="flex" flexDirection={"column"} width="100%" height="100%">
            {
                handleClickDismiss !== noop && 
                <Box sx={{position:'absolute',top:0,right:0,p:1}}>
                    <CancelIconButton Icon={CloseIcon} onClick={handleClickDismiss} TooltipProps={{title:'Dismiss'}}/>
                    {
                        false && 
                        <Button startIcon={<CloseIcon/>} size="small" onClick={handleClickDismiss} color={'error'}>
                            {t('common.dismiss')}
                        </Button>
                    }
                </Box>
            }
            <Box flexGrow={1}/>
            <Box align="center" display="flex" width="100%">
                <Box flexGrow={1}/>
                <Box m={2}>
                    <FatButton
                        icon    = {ReplayIcon}
                        onClick = {handleClickPlay}
                    >
                        {t('components.playlist.playFromTheStart')}
                    </FatButton>
                </Box>
                <Box m={2}>
                    <FatButton 
                        icon    = {PlayCircleOutlineIcon}
                        onClick = {handleClickResume}
                    >
                        {   
                            Boolean(resumeNumber && resumeTime) &&
                            t("components.playlist.resumeNumberFromTime", { index : resumeNumber, timeStamp : secToTime(resumeTime) })
                        }   
                    </FatButton>
                </Box>
                <Box flexGrow={1}/>
            </Box>
            <Box flexGrow={1}/>
        </Box>
    )
});

export const Playlist = withTranslation( ({
    t,
    identifyer              = undefined, 
    data                    = arr, 
    component : Component   = Box, 
    componentProps          = obj, 
    // sensitive               = false, 
    force                   = false, 
    isAvailable             = true,
    loading                 = false,
    autoscroll              = true,
    getAudioInstance        = noop,
    onPlayChange            = noop,
    onPlayIndexChange       = noop,
    // allowComments           = false,
    crossSellQuantity       = 5,
    sticky                  = true,
}) => {

    const {userId, isAuthenticated}                 = useUser();
    const {isNetworkReady}                          = useNetwork();
    const convert                                   = useImageCDN();
    // const {alert}                                   = useAlert();

    // Controlls
    const [id, setId]                               = React.useState(undefined);

    // The current time    
    const [currentTime,     setCurrentTime]         = React.useState(0);

    // Player Instance
    const [instance,        setInstance]            = React.useState(undefined);

    // The actual playlist
    const [playing,         setPlaying]             = React.useState(false);
    const [playList,        setPlayList]            = React.useState(undefined);
    const [playListIndex,   setPlayListIndex]       = React.useState(undefined);
    const [resumed,         setResumed]             = React.useState(false);
    const [resumeFrom,      setResumeFrom]          = React.useState(undefined);
    const [resumePristine,  setResumePristine]      = React.useState(true);

    // Check has files
    const hasFiles              = React.useMemo(() => Array.isArray(data) && data?.length > 0, [data]);
    const hasPlayList           = React.useMemo(() => Array.isArray(playList) && playList.length > 0, [playList]);
    const hasChapters           = React.useMemo(() => hasFiles && (data || []).map(f => f?.metadata?.chapterTitle).map(Boolean).every(Boolean), [data, hasFiles]);
    const files                 = React.useMemo(() => hasFiles ? data : [], [data, hasFiles]);
    const available             = React.useMemo(() => isAvailable || force, [force, isAvailable]);
    const sequence              = React.useMemo(() => (available || hasChapters) ? files : files.slice(0,8),[available, files, hasChapters])
    const showPlayer            = React.useMemo(() => force || (!loading && hasPlayList && available), [available, force, hasPlayList, loading]);
    const showResumeButtons     = React.useMemo(() => !playing && resumeFrom && !resumed, [playing, resumeFrom, resumed]);
    const storageKey            = React.useMemo(() => userId && isAuthenticated ? `${STORAGE_KEY}/${userId}` : STORAGE_KEY, [isAuthenticated, userId])

    React.useEffect(() => {
        getAudioInstance(instance);
    },[getAudioInstance, instance])

    React.useEffect(() => {
        onPlayIndexChange(playListIndex);
    },[onPlayIndexChange, playListIndex])

    React.useEffect(() => {
        onPlayChange(playing);
    },[onPlayChange, playing])

    const handlePlayToggle = React.useCallback(() => {
        try{
            if(!instance) 
                throw new Error(t('components.playlist.noPlayerInstance'));
            playing 
                ? instance.pause() 
                : instance.play();
            // alert('toggle called')
        }catch(err){
            // silent
        }
    },[t, instance, playing]);

    const click = React.useCallback(fileName => {
        if(hasPlayList){
            let ixNew = playList.findIndex(x => x.file === fileName)
            if(ixNew >= 0){
                if(ixNew !== playListIndex) 
                    setPlayListIndex(ixNew);
                handlePlayToggle();
            }
        }
    },[handlePlayToggle, hasPlayList, playList, playListIndex])

    // Override Jinke Styles
    React.useEffect(() => {
        const style = document.createElement('style')
        style.innerHTML = `
            .music-player-panel {
                z-index: 10000!important;
            }
        `
        document.head.appendChild(style);
        return () => {
            document.head.removeChild(style);
        }
    },[showResumeButtons]);

    const removeSessionStorage = React.useCallback(() => {
        if(identifyer){
            let prev = JSON.parse(window.localStorage.getItem(storageKey));
            delete prev[identifyer];
            window.localStorage.setItem(storageKey, JSON.stringify(prev));
        }
    },[identifyer,storageKey])

    // Update Identifyer reference
    const identifyerRef = React.useRef(identifyer);
    React.useEffect(() => {
        identifyerRef.current = identifyer;
    },[identifyer])

    // Update Resume Time, without dependency on identifier
    React.useLayoutEffect(() => {
        if(identifyerRef.current){
            const prev = JSON.parse(window.localStorage.getItem(storageKey)) || {};
            // Set Resume From
            if(!playing){
                let thisProgress = prev[identifyerRef.current];
                setResumeFrom(
                    thisProgress 
                        ? { playListIndex : thisProgress.playListIndex, currentTime : thisProgress.currentTime }
                        : undefined
                )
            }
            // Update play progress
            else {
                window.localStorage.setItem(storageKey, JSON.stringify({...prev, [identifyerRef.current] : {playListIndex, currentTime}}));
            }
        }
        setResumePristine(false);
    },[playing, playListIndex, currentTime, storageKey])

    // Set Resumed flag to True for this Instance
    React.useEffect(() => {
        if(playing) setResumed(true);
    },[playing])

    const handleAudioPlay       = React.useCallback( () => {
        setPlaying(true)
    }, []);
    const handleAudioPause      = React.useCallback( () => {
        setPlaying(false)
    }, []);
    const handleAudioEnded      = React.useCallback( () => {
        removeSessionStorage();
        setPlaying(false);
    }, [removeSessionStorage]);

    const handleAudioAbort      = React.useCallback( () => setPlaying(false),   []);
    const handlePlayIndexChange = React.useCallback( (playIndex) => setPlayListIndex(playIndex), []);
    const handleAudioProgress   = React.useCallback( ({currentTime,playIndex,paused,ended}) => {
        setCurrentTime(currentTime);
        setPlayListIndex(playIndex);
        setPlaying(!paused && !ended);
    }, []);

    const handleClickPlay       = React.useCallback( () => {
        setResumed(true);
        setPlayListIndex(0);
        setTimeout(() => {
            if(instance){
                instance.currentTime = 0;
                instance.play();
                // alert('click play')
            }
        },250)
    }, [instance]);

    const handleClickResume     = React.useCallback(() => {
        setResumed(true);
        setPlayListIndex(resumeFrom?.playListIndex || 0);
        setTimeout(() => {
            if(instance){
                instance.currentTime = resumeFrom?.currentTime || 0
                instance.play();
                // alert('click resume')
            }
        },250)
    }, [instance, resumeFrom?.currentTime, resumeFrom?.playListIndex])

    const handleClickDismiss     = React.useCallback(() => {
        setResumed(true);
    }, [])

    // Update libraryId arg change
    React.useEffect(() => {
        if(identifyer) setId(identifyer);
        setResumed(false); // Reset Resumed Flag when identifyer changes
    },[identifyer])

    React.useEffect(() => {
        setPlaying(false);
        setPlayListIndex(0);
        setCurrentTime(0);
    }, [id])

    React.useEffect(() => {
        let hasNewData  = false, 
            playList    = [];
        if(data && identifyer){
            playList = ((isAvailable || force ? data : undefined) || [])
                .map((record,ix) => ({
                    ix, 
                    file        : record.fileGetSignedUrl || record.file, 
                    name        : record.name, 
                    description : record.description,
                    canListen   : record.canListen
                }))
                .filter(d => Boolean(d.file && d.canListen))
            hasNewData = playList.length > 0;
        }
        setPlaying(false);
        setPlayListIndex(hasNewData ? 0          : undefined);
        setPlayList(hasNewData      ? playList   : undefined);
    },[data, force, identifyer, isAvailable])

    // Overlay with resume buttons
    const showOverlayWithButtons = React.useMemo(() => (
        !loading && !resumePristine && showResumeButtons && resumeFrom && (isAvailable || force)
    ), [force, isAvailable, loading, resumeFrom, resumePristine, showResumeButtons]); 

    // Overlay without resume buttons
    const showOverlaySimple = React.useMemo(() => (
        !showOverlayWithButtons && (loading || resumePristine)
    ),[loading, resumePristine, showOverlayWithButtons])

    return (
        <Box width = "100%" id='playlist'>
            {   
                false && 
                <JSONViewer src={data} />
            }

            {
                false &&
                <JSONViewer 
                    src={{
                        showResumeButtons,
                        resumeFrom,
                        resumePristine,
                        isAvailable,
                        showOverlayWithButtons,
                        loading,
                        playing,
                        resumed,
                        force
                    }} 
                />
            }
            {   
                (!identifyer || loading || !isNetworkReady) && 
                <Box id="librarySetLoading" height="100%" display="flex" flexDirection="column" position="relative">
                    <Box>               
                        <SkeletonPlaylist quantity={0}/>
                    </Box>
                    <Box id="nolibs" flexGrow={1} mt={1}>
                        <SkeletonFancyNoLibrariesSelected showCircle={false}/>
                    </Box>
                </Box>
            }

            {   
                identifyer && !loading && isNetworkReady &&
                <Component {...componentProps} >
                    {
                        hasFiles &&
                        <Box id="chapters" sx={{maxHeight: showOverlayWithButtons || showOverlaySimple ? 'initial' : 350, overflow:'scroll', scrollSnapType: "y mandatory", scrollPadding: '50%'}}>
                            <ChaptersContentsResume conceal={(showOverlayWithButtons || showOverlaySimple)}>
                                {
                                    !isAvailable && !hasChapters &&
                                    <Gradient />
                                }
                                {
                                    hasChapters
                                        ?   <ChaptersCollapseableMod
                                                // group               = { group }
                                                data                = { data }
                                                isAvailable         = { isAvailable }
                                                playing             = { playing }
                                                playList            = { playList }
                                                files               = { sequence }
                                                playListIndex       = { playListIndex }
                                                currentTime         = { currentTime }
                                                onClick             = { click } 
                                                onDoubleClick       = { click } 
                                                onPlayToggle        = { handlePlayToggle }
                                                force               = { force }
                                                showResumeButtons   = { showResumeButtons }
                                                loading             = { loading }
                                                autoscroll          = { autoscroll }
                                                // allowComments       = { allowComments }
                                                crossSellQuantity   = { crossSellQuantity }
                                                sticky              = {sticky}
                                            />

                                        :   <ChaptersMod
                                                // group               = { group }
                                                playing             = { playing }
                                                isAvailable         = { isAvailable }
                                                playList            = { playList }
                                                files               = { sequence }
                                                playListIndex       = { playListIndex }
                                                currentTime         = { currentTime }
                                                onClick             = { click } 
                                                onDoubleClick       = { click } 
                                                force               = { force }
                                                autoscroll          = { autoscroll }
                                                // allowComments       = { allowComments }
                                            />
                                }
                                {
                                    showOverlaySimple &&
                                    <OverlayButtonContainer id="overlay" />
                                }
                                {
                                    showOverlayWithButtons &&
                                    <OverlayButtonContainer id="overlay" sx={{aspectRatio:'1.41',overflow:'hidden'}}>
                                        <CardMedia 
                                            component   = "img" 
                                            src         = {convert(astrolabe, {operation:'width',width:500})} 
                                            sx          = {{
                                                position : 'absolute', top:0, left:0, minWidth:'100%', minHeight:'100%',
                                                filter   : 'blur(1px) opacity(25%);'
                                            }}
                                        />
                                        <PlayResumeOverlay 
                                            resumeNumber    = {(resumeFrom?.playListIndex || 0) + 1} 
                                            resumeTime      = {resumeFrom?.currentTime || 0}
                                            onClickPlay     = {handleClickPlay}
                                            onClickResume   = {handleClickResume}
                                            onClickDismiss  = {handleClickDismiss}
                                        />
                                    </OverlayButtonContainer>
                                }
                            </ChaptersContentsResume>    
                            {false && JSON.stringify(data || [],null,4)}
                        </Box>
                    }
                </Component>
            }

            <LibraryJinkePlayer 
                hidden              = { !showPlayer || showResumeButtons }
                getAudioInstance    = { setInstance }
                playList            = { playList }
                playListIndex       = { playListIndex }
                onAudioPlay         = { handleAudioPlay}  
                onAudioPause        = { handleAudioPause }
                onAudioEnded        = { handleAudioEnded }
                onAudioAbort        = { handleAudioAbort}   
                onPlayIndexChange   = { handlePlayIndexChange }
                onAudioProgress     = { handleAudioProgress }
                onAudioListChange   = { handleAudioPause }
            />

        </Box>
    )
});

export default Playlist;

/*
<TableCell align="right">
    {
        isAvailable && Object.keys(d?.metadata).filter(x => FEATS.includes(x)).some(Boolean) &&
        <Box display="flex">
            
            
            {
                allowComments &&
                <Box sx={{my:'auto'}}>
                    <Comments 
                        identifier      = {identifier} 
                        identifierRef   = "masterLibrary.files"
                        group           = {group}
                        groupRef        = {"archive"}
                        metadata        = {{
                            masterLibrary:d.masterLibrary
                        }}
                    />
                </Box>
            }

            {
                allowComments && identifier && group && 
                <Box sx={{my:'auto'}}>
                    <Typography>
                        { childCounts.find(x => x.identifier === identifier && x.group === group)?.childCount || 0}
                    </Typography>
                </Box>
            }
        </Box>
    }
</TableCell>
*/