/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Draggable Dialog
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       24th December 2020

*******************************************************************************************/

import React                from 'react';
import uniqueId             from 'lodash/uniqueId';
import { 
  styled,
  darken, useTheme, Box
}                           from '@mui/material';
import Dialog               from '@mui/material/Dialog';
import Paper                from '@mui/material/Paper';
import MuiDialogTitle       from '@mui/material/DialogTitle';
import MuiDialogContent     from '@mui/material/DialogContent';
import MuiDialogActions     from '@mui/material/DialogActions';
import IconButton           from '@mui/material/IconButton';
import CloseIcon            from '@mui/icons-material/Close';
import Typography           from '@mui/material/Typography';
import CheckIcon            from '@mui/icons-material/CheckCircleOutline';
import CancelIcon           from '@mui/icons-material/Cancel';
import LoadingBar           from 'react-top-loading-bar'

import {
  TitleWithReadOnlyChip,
  Button
}                           from 'components';
import { withTranslation }  from './hoc';
import { useModalSpacing }  from 'contexts';
import { useIsTouchScreen } from 'hooks';
import Draggable            from 'react-draggable';

const PaperDraggable = ({ handle = undefined, ...props }) => {

  const onDragStart = (e, data) => {
    e.stopPropagation();
    e.preventDefault();
  };
  const onDragStop = (e, data) => {
    e.stopPropagation();
    e.preventDefault();
  };
  const onDrag = (e, data) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const isTouch = useIsTouchScreen();
  if(isTouch) 
    return <Paper {...props} />

  return (
    <Draggable 
      onStart = {onDragStart}
      onStop  = {onDragStop}
      onDrag  = {onDrag}
      handle  = {handle} 
      cancel  = {'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const CloseButton = styled(IconButton)(({theme}) => ({
  position  : 'absolute',
  right     : theme.spacing(1),
  top       : theme.spacing(1),
  color     : theme.palette.grey[500],
}))


const DialogTitle = ({ children, onClose, ...other }) => (
  <MuiDialogTitle {...other} sx={{m:0,pb:0}}>
    {children}
    {
      onClose ? (
      <CloseButton
        aria-label  = "close"
        onClick     = {onClose}
        size        = "large"
      >
        <CloseIcon />
      </CloseButton>
    ) : null}
  </MuiDialogTitle>
);

const DialogContent = styled(MuiDialogContent)(({theme}) => ({
  paddingBottom : `${theme.spacing(2)}!important`,
  '&.MuiDialogContent-root' : {
    paddingTop  : theme.spacing(0),
  }
}));

const DialogActions = styled(MuiDialogActions)(({theme}) => ({
  '&.MuiDialogActions-root' : {
    borderTop   : `1px dotted ${theme.palette.divider}`,
    margin      : 0,
    paddingTop  : 0,
    padding     : theme.spacing(2),
  }
}));

const noop = () => {}

/*
const calculatePaperStyles = ({
  fullScreen          = false, 
  top     : topSrc    = 0, 
  bottom  : bottomSrc = 0
}) => {
  const top     = fullScreen ? 0                    : topSrc;
  const bottom  = fullScreen ? (bottomSrc + topSrc) : bottomSrc;

  if(fullScreen){
    let avgY = (top + bottom) / 2.0;
    return {
      marginTop     : avgY,
      marginBottom  : avgY, 
      maxHeight     : `calc(100% - ${2*avgY}px)`,
      transform     : `translateY(-${avgY - top}px)`
    }
  }else{
    return {
      marginTop     : top,
      marginBottom  : bottom, 
      maxHeight     : `calc(100% - ${top + bottom}px)`
    }
  }  
}
*/

// https://robertmarshall.dev/blog/disablebackdropclick-of-forwardrefdialog-is-deprecated-solution-and-example/
function DialogMigrate({
  children,
  disableBackdropClick,
  disableEscapeKeyDown,
  onClose,
  onClick         : handleClick       = noop,
  onDoubleClick   : handleDoubleClick = noop, 
  PaperProps,
  ...rest
}) {

  const handleClose = React.useCallback( (event, reason) => {
    if (disableBackdropClick && reason === "backdropClick") {
      return false;
    }
    if (disableEscapeKeyDown && reason === "escapeKeyDown") {
      return false;
    }
    if (typeof onClose === "function") {
      onClose();
    }
  }, [disableBackdropClick, disableEscapeKeyDown, onClose]);

  const BasePaperProps  = { onClick: e => e.stopPropagation() }
  const onBackdropClick = e => e.stopPropagation();
  const onDoubleClick   = e => e.stopPropagation();

  return (
    <Dialog 
      onClose         = {handleClose} 
      PaperProps      = {{
        ...BasePaperProps, 
        ...PaperProps
      }}
      onBackdropClick = {onBackdropClick} 
      onDoubleClick   = {onDoubleClick} 
      {...rest} 
    >
      {children}
    </Dialog>
  );
}

const default_okbuttonprops = {};
const default_cancelbuttonrprops = {};
const default_paperprops = {};
const default_dialogcontentprops = {};

export const DraggableDialog = withTranslation( ({
  t,
  children,
  title                               = undefined,
  draggable                           = true,
  subtitle                            = undefined,
  open                                = false,
  onClose       : handleDialogClose   = noop,
  onOk          : handleDialogOk      = noop,
  showButtons                         = true,
  showButtonsOk                       = true,
  showButtonsCancel                   = true,
  okenabled                           = true,
  cancelenabled                       = true,
  okbuttonprops                       = default_okbuttonprops,
  oklabel                             = undefined,
  cancelbuttonprops                   = default_cancelbuttonrprops,
  cancellabel                         = undefined,
  readOnly                            = false,
  fullScreen                          = false,
  disableBackdropClick                = false,
  PaperProps                          = default_paperprops,
  dialogContentProps                  = default_dialogcontentprops,
  progress : progressIn               = undefined,
  ...props
}) => {

    const theme             = useTheme();
    const isTouch           = useIsTouchScreen();
    const {
      top,
      bottom,
      left,
      right
    }                       = useModalSpacing();
    
    // Set ID
    const id                = React.useMemo(() => uniqueId('dd-'), []);

    // Fixed Buttons Style
    const buttonStyle       = React.useMemo(() => ({ minWidth : 150}), []);

    // Show Action Buttons or Not
    const showActions       = React.useMemo(() => (
      Boolean(showButtons && (showButtonsOk || showButtonsCancel))
    ), [showButtons, showButtonsCancel, showButtonsOk]);

    /*
    React.useEffect(() => {
      if (open) {
        document.body.style.overflow = 'hidden';
        return () => {
          document.body.style.overflow = 'visible';
        }
      }
      else {
        document.body.style.overflow = 'visible';
      }
    }, [open]);
    */

    const style = React.useMemo(() => {
      if(fullScreen){
        // let t     = top,
        //    b     = bottom + top;
        // let avgY  = (t + b) / 2.0;
        return {
          position      : 'fixed',
          top           : 0, //theme.spacing(2),
          left          : left,
          right         : right,
          bottom        : bottom, //2 * avgY
          /*
            top           : -8,
            left          : left,
            right         : right,
            marginBottom  : 2 * avgY,
            width         : `calc(100% - ${left + right}px - 32px)`,
            height        : `calc(100% - ${2 * avgY}px)`,
            // transform     : `translateY(-${avgY}px)`
          */
        }
      }else{
        return {
          marginTop     : top,
          marginBottom  : bottom,
          marginLeft    : left,
          marginRight   : right,
          maxHeight     : `calc(100% - ${top + bottom}px)`
        }
      }
    }, [bottom, fullScreen, left, right, top])

    const PaperComponent = React.useCallback((props) => (
      draggable && !fullScreen 
        ? <PaperDraggable {...props} />
        : <Paper {...props} />
    ),[draggable, fullScreen])

    const progress = React.useMemo(() => progressIn, [progressIn]);

    return (
        <DialogMigrate
          open                  = {open}
          fullScreen            = {fullScreen}
          PaperComponent        = {PaperComponent}
          aria-labelledby       = "draggable-dialog-title"
          disableBackdropClick  = {disableBackdropClick}
          style                 = {style}
          {...props}
          PaperProps            = {PaperProps}
          onClose               = {handleDialogClose}
        >
            <DialogTitle 
              id      = {id} 
              style   = {{ cursor : draggable && !fullScreen && !isTouch  ? 'move' : 'inherit', padding:16, paddingBottom:theme.spacing(1)}} 
              onClick = {(e) => e.preventDefault()}
              onClose = {handleDialogClose}
            >
              <TitleWithReadOnlyChip 
                title     = {title || t('components.draggableDialog.userInputRequired')} 
                readOnly  = {readOnly} 
              />
              { 
                subtitle &&  
                <Typography color="textSecondary"> 
                  {subtitle}
                </Typography> 
              }
            </DialogTitle>
            {
              progress > 0 &&
              <Box sx={{position:'relative'}}>
                <LoadingBar transitionTime={0} loaderSpeed={0} color={theme.palette.secondary.main} progress = {progress} shadow={false} containerStyle={{position:'relative'}}/>
              </Box>
            }
            <DialogContent 
              id        = "dialogContent" 
              {...dialogContentProps}
              style     = {{
                background    : darken(theme.palette.background.paper,0.025),
                overflow      : 'scroll', 
                paddingTop    : theme.spacing(2), 
                paddingBottom : theme.spacing(fullScreen ? 2 : 2), 
                marginBottom  : theme.spacing(fullScreen ? (showActions ? 0*2 : 0) : 0), 
                paddingLeft   : theme.spacing(2), 
                paddingRight  : theme.spacing(2),
                ...dialogContentProps?.style
              }} 
            >
              {children}
            </DialogContent>

            { 
              showActions && 
              <DialogActions>
                  {
                    showButtonsCancel && 
                    <Box style={{color:theme.palette.error.main}}>
                      <Button 
                        startIcon = {<CancelIcon/>}
                        disabled  = {!cancelenabled}       
                        onClick   = {handleDialogClose} 
                        color     = "inherit" 
                        variant   = "text" 
                        style     = {buttonStyle} 
                        {...cancelbuttonprops}
                      >
                        {cancellabel || t('common.cancel')}
                      </Button>
                    </Box>
                  }
                  {
                    showButtonsOk && 
                    <Box ml={2}>
                      <Button 
                        autoFocus 
                        startIcon = {<CheckIcon/>}
                        disabled  = {!okenabled} 
                        onClick   = {handleDialogOk} 
                        color     = "secondary" 
                        variant   = "contained" 
                        style     = {buttonStyle} 
                        {...okbuttonprops}
                      >
                        {oklabel || t('common.ok')}
                      </Button>
                    </Box>
                  }
              </DialogActions>
            }
        </DialogMigrate>
    );
});

export default DraggableDialog;