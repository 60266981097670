/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Likes Display
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       23rd October 2022

*******************************************************************************************/
import React                    from 'react';
import {
    styled,
    alpha,
    Box,
    Typography as TypographyMUI,
}                               from '@mui/material';
import ThumbUpIconMUI           from '@mui/icons-material/ThumbUp';
import ThumbDownIconMUI         from '@mui/icons-material/ThumbDown';
import PrettyNumber             from './PrettyNumber'

const FONT_SIZE = '0.75rem';

const Typography = styled(TypographyMUI)(({theme}) => ({
    lineHeight      : 1,
    marginTop       : 'auto',
    marginBottom    : 'auto',
    fontSize        : FONT_SIZE
}));

const ThumbContainer = styled(Box)(({theme}) => ({
    display         : "flex",
    marginLeft      : theme.spacing(0.5),
    marginRight     : theme.spacing(0.5),
    color           : 'grey'
}));

const RootContainer = styled(Box)(({theme}) => ({
    pointerEvents       : 'none',
    position            : 'absolute',
    display             : "flex",
    bottom              : 0,
    right               : 0,
    padding             : theme.spacing(0.5),
    background          : alpha(theme.palette.primary.main,0.5),
    borderTopLeftRadius : theme.spacing(1),
}));

export const LikeIcon = styled(ThumbUpIconMUI, {
    shouldForwardProp : prop => prop !== 'likes' && prop !== 'fontSize'
})(({theme, likes = 0, fontSize = FONT_SIZE}) => ({
    color           : likes ? theme.palette.secondary.main : 'inherit',
    fontSize        : fontSize, 
    marginTop       : 'auto',
    marginBottom    : 'auto'
}));

export const DislikeIcon = styled(ThumbDownIconMUI, {
    shouldForwardProp : prop => prop !== 'dislikes' && prop !== 'fontSize'
})(({theme, dislikes = 0, fontSize = FONT_SIZE}) => ({
    color           : dislikes ? theme.palette.error.main : 'inherit',
    fontSize        : fontSize, 
    marginTop       : 'auto',
    marginBottom    : 'auto'
}));

const noop = () => {};

export const LikeOnlyDisplay = ({likes = 0, onClick = noop, reversed = false, ...props}) => (
    <ThumbContainer {...props} sx={{flexDirection : reversed ? 'row-reverse' : 'row', ...props?.sx}}>
        <Box onClick={onClick}>
            <LikeIcon likes={likes} />
        </Box>
        {
            Boolean(likes) &&
            <Typography color="secondary" sx={{ [reversed ? 'mr' : 'ml'] : 0.5 }}>
                <PrettyNumber value={likes} precision={1} />
            </Typography>
        }
    </ThumbContainer>
)

export const DislikeOnlyDisplay = ({dislikes = 0, onClick = noop, reversed = false, ...props}) => (
    <ThumbContainer {...props} sx={{flexDirection : reversed ? 'row-reverse' : 'row', ...props?.sx}}>
        <Box onClick={onClick}>
            <DislikeIcon dislikes = {dislikes} />
        </Box>
        {
            Boolean(dislikes) &&
            <Typography color={"error"} sx={{ [reversed ? 'mr' : 'ml'] : 0.5 }}>
                <PrettyNumber value={dislikes} precision={1} />
            </Typography>
        }
    </ThumbContainer>
)

export const LikeDisplay = ({likes = 0, onClickLike = noop, dislikes = 0, onClickDislike = noop, zIndex = 1, likeCountLeft = true, dislikeCountLeft = false, ...props}) => (
    <RootContainer {...props} sx={{zIndex, ...props?.sx}}>
        <LikeOnlyDisplay    likes       = {likes}       reversed={true}     onClick={onClickLike}       />
        <DislikeOnlyDisplay dislikes    = {dislikes}    reversed={false}    onClick={onClickDislike}    />
    </RootContainer>
)

export default LikeDisplay;