/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
View Cart / Checkout Button
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       29th June 2021

*******************************************************************************************/
import React                    from 'react';
import { 
    Link, 
    useHistory, 
    useLocation
}                               from "react-router-dom";
import { UserCartLocation }     from 'router/locations';
import { CheckoutButton }       from 'components/buttons';
import { 
    useCart,
    useTranslation
}                               from 'contexts';

export const ViewCartCheckoutButton = ({children, disabled = false, ...props}) => {

    const history                           = useHistory();
    const location                          = useLocation();
    const [isViewingCart, setViewingCart]   = React.useState(false);
    const { isEmpty, working}               = useCart();
    const [t]                               = useTranslation();

    React.useEffect(() => {
        setViewingCart(location.pathname === UserCartLocation.path);
    },[location])

    const handleClick = () => history.push(UserCartLocation.path);

    if(isViewingCart) 
        return null;

    return (
        <CheckoutButton
            onClick     = {handleClick} 
            disabled    = {disabled || isEmpty || working} 
            component   = {Link} 
            to          = {UserCartLocation.path} 
            {...props}
        >
            {children || `${t('common.viewCart')} / ${t('common.checkout')}`}
        </CheckoutButton>
    )
}

export default ViewCartCheckoutButton