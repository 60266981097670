/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Loading
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       31st December 2020

*******************************************************************************************/

import React                            from 'react';
import { 
    styled,
    Box, 
    Typography,
    CircularProgress,
    Backdrop as BackdropMUI
}                                       from '@mui/material';
import {grey}                           from '@mui/material/colors';
import { alpha }                        from '@mui/material/styles';

const Backdrop = styled(BackdropMUI)(({theme}) => ({
    zIndex      : theme.zIndex.drawer + 1,
    color       : '#fff',
    background  : alpha(grey[900], 0.75)
}))

export const Loading = ({children,...rest}) => (
    <Backdrop open={true} {...rest}>
        <CircularProgress color="inherit" />
        {   
            children && 
            <Box sx={{pl:1}}>
                <Typography variant="body2">
                    {children}
                </Typography>
            </Box>
        }
    </Backdrop>
);

export default Loading;

