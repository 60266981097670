/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Skeleton Playlist
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       19th July 2022

*******************************************************************************************/
import React                            from 'react';
import { Box }                          from '@mui/material';
import { Skeleton }                     from '@mui/material';
import { 
    ContainerHorizontal, 
    ContainerVertical 
}                                       from './components';

export const SkeletonPlaylist = ({ quantity = 10 }) => (
    <ContainerVertical width="100%">
        <Box>
            <ContainerHorizontal>
                <Box component = {Skeleton} width="35%" variant="text"/>
                <Box flexGrow={1}/>
                <Box component = {Skeleton} width="20%" variant="text"/>
                <Box component = {Skeleton} width="10%" variant="text"/>
            </ContainerHorizontal>
            <ContainerHorizontal>
                <Box component = {Skeleton} width="50%" variant="text"/>
                <Box flexGrow={1}/>
                <Box component = {Skeleton} width="5%" variant="text"/>
            </ContainerHorizontal>
            <ContainerHorizontal>
                <Box component = {Skeleton} width="30%" variant="text"/>
                <Box flexGrow={1}/>
            </ContainerHorizontal>
        </Box>
        {
            quantity > 0 &&
            <ContainerVertical>
                {
                    Array(quantity).fill(undefined).map((_,ix) => (
                        <Skeleton key={ix} height={35} width="100%" variant="rectangular"/>
                    ))
                }
            </ContainerVertical>
        }
    </ContainerVertical>
);

export default SkeletonPlaylist;