/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Contentful Author Component
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       23rd April 2023

*******************************************************************************************/
import React                            from 'react';
import {
    alpha,
    Box,
    Typography,
    Avatar,
    Grid
}                                       from '@mui/material';
import { 
    FeatureBox,
    ContentfulRichText,
    Title,
}                                       from 'components';
import { withTranslation }              from './hoc';


export const ContentfulAuthor = withTranslation( ({t, author, useBiography = false}) => {

    if(!author)
        return null;

    return (
        <FeatureBox sx = {{height:'100%', borderRadius:theme => theme.spacing(2), padding:2, bgcolor:theme => alpha(theme.palette.primary.main,0.05)}}>
            <Grid container>
                <Grid item xs={12} sm={3} md={2}>
                    <Box sx={{width:'100%',aspectRatio:'1',maxWidth:150,mx:'auto'}}>
                        <Avatar
                            src     = {`${author?.fields?.avatar?.fields?.file?.url}?w=250&h=250`}
                            alt     = {author?.fields?.name} 
                            sx      = {{width:'100%', height:'100%'}}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={9} md={10}>
                    <Box align="left">
                        <Title variant="h6" gutterBottom>
                            {t('components.contentfulAuthor.aboutAuthor', {author : author?.fields?.name})}
                        </Title>
                    </Box>
                    <Box>
                        {
                            !useBiography && 
                            <Typography component="div" align="justify">
                                {author?.fields?.about}
                            </Typography>
                        }
                        { 
                            useBiography && 
                            <ContentfulRichText content={author?.fields?.biography} />
                        }
                    </Box>
                    {
                        author?.fields?.avatarReference && 
                        <Box mt={2}>
                            <Typography component="div" align="right" sx={{fontSize:'0.75rem'}}>
                                * {author?.fields?.avatarReference}
                            </Typography>
                        </Box>
                    }
                </Grid>
            </Grid>
        </FeatureBox>
    )
});

export default ContentfulAuthor;