/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Exit Intent
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       10th February 2024

*******************************************************************************************/
import React                            from 'react';
import moment                           from 'moment';
import exitIntent                       from 'exit-intent';
import { useLocation }                  from 'react-router-dom';
import { useCookies }                   from 'react-cookie';
import { 
    Dialog,  
    DialogContent,
}                                       from '@mui/material';
import { useUser }                      from 'contexts';
import { ExitIntentPromotion }          from 'components'
import { PreviewLocation }              from 'router/locations';
import config                           from '../config';

const OFFSET                = 10;
const STORAGE_LIFE          = 7;
const COOKIE_PATH           = config.cookies.paths.exitIntent;

export const ExitIntent = ({
    offset          = OFFSET, 
    storageName     = COOKIE_PATH, 
    storageLife     = STORAGE_LIFE
}) => {

    const [cookies, setCookie]  = useCookies([storageName]); 
    const [open,    setOpen]    = React.useState(!false);
    const [entered, setEntered] = React.useState(false);
    
    const {isAuthenticated}     = useUser();
    const {pathname}            = useLocation();

    const abort                 = React.useMemo(() => (pathname || '').includes(PreviewLocation.path), [pathname])

     // Open Dialog
    const handleOpen            = React.useCallback( () => {
        setOpen(true);
    }, []);

    // Close Dialog
    const handleClose           = React.useCallback( () => {
        setOpen(false);
    }, []);

    // Effect to close on page change
    React.useEffect(() => setOpen(false), [pathname])

    // Effect to track mouse entry
    React.useEffect(() => {
        
        // Once Entered, No need to track
        if(abort || entered) 
            return;

        // Short Circuit if Exit Intent Triggered Already
        const exitIntentTriggered = cookies[storageName];
        if (exitIntentTriggered) {
            return;
        }

        // Handle Mouse Move, Set Enter if Offset is Breached.
        const handleMouseMove = (e) => {
            const { clientX, clientY } = e;
            const { innerWidth, innerHeight } = window;
            if (clientX > offset && clientX < innerWidth - offset && clientY > offset && clientY < innerHeight - offset) {
                setEntered(true);
            }
        };

        document.addEventListener('mousemove', handleMouseMove);
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
        };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [abort, JSON.stringify(cookies), entered]);

    React.useEffect(() => {

        // If not entered, no exit intent
        if(abort || !entered)
            return;

        const handleExitIntent = () => {
            
            // Mark Open
            handleOpen();
            
            // Set a cookie to track that the exit intent has been triggered, Expires in 1 day
            // Ensure Cookie Consent is Set
            if(storageLife > 0){
                setCookie(
                    storageName, 
                    'true', 
                    { 
                        path    : '/', 
                        expires : moment().add(storageLife, 'day').toDate()
                    }
                );
            }
        }

        // Initialise
        const removeExitIntent = exitIntent({
            threshold       : Math.max(offset - 1,0),
            maxDisplays     : 1,
            eventThrottle   : 100,
            onExitIntent    : handleExitIntent  
        });

        // Cleanup
        return () => {
            removeExitIntent()
        }

    },[abort, entered, handleOpen, offset, pathname, setCookie, storageLife, storageName])

    if(!open || isAuthenticated)
        return null;
  
    return (
        <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
            <DialogContent>
                <ExitIntentPromotion />
            </DialogContent>
        </Dialog>
    )
}

export default ExitIntent;