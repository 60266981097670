
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
copy to clipboard hook
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       27th September 2023

*******************************************************************************************/
import React            from 'react';
import { 
    useAlert,
    useTranslation
}                       from 'contexts';

// Function to copy
const fallbackCopyToClipboard = (text) => {
    const textarea          = document.createElement('textarea');
    textarea.value          = text;
    textarea.style.position = 'fixed';  // Ensure it's not visible
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
}

async function copyToClipboard(text) {
    if (navigator.clipboard) {
        try {
            await navigator.clipboard.writeText(text)    
        } catch (err) {
            fallbackCopyToClipboard(text);
        }
    } else {
        // Fallback to the document.execCommand method
        fallbackCopyToClipboard(text);
    }
}

export const useCopyToClipboard = () => {

    const {t}           = useTranslation();
    const { alert }     = useAlert();

    // Copy Function
    const copyFunction = React.useCallback( async (value, successAlert = undefined) =>  {
        try {

            if(!value)
                throw new Error('Input value is empty')

            // Perform the copy
            await copyToClipboard(value);

            // Alert Success
            alert(
                successAlert || t('hooks.useCopyToClipboard.copiedToClipboard')
            )    
        } catch (err) {

            console.error(err)

            // Alert Failure
            alert(
                err.message 
                    ? t('hooks.useCopyToClipboard.couldNotCopyWithMessage', {message : err.message })
                    : t('hooks.useCopyToClipboard.couldNotCopy' ),
                "error"
            )
        }
    }, [t, alert])

    return copyFunction;
}

export default useCopyToClipboard;

    