/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Skeleton Table
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       24th July 2022

*******************************************************************************************/
import React                    from 'react';
import { Box }                  from '@mui/material';
import { Skeleton }             from '@mui/material';
import { 
    ContainerHorizontal, 
    ContainerVertical 
}                               from './components';


export const SkeletonTable = ({rowHeight = 35, rowQuantity = 10, header = true, footer = true, ...props}) => {
    
    return (
        <ContainerVertical>
            {
                header && 
                <ContainerHorizontal spacing={0.25} height={25}>
                    <Box flexGrow={1}/>
                    <Skeleton variant="rectangular" width="15%" height="100%"/>
                    <Skeleton variant="rectangular" width="15%" height="100%"/>
                    <Skeleton variant="rectangular" width="15%" height="100%"/>
                </ContainerHorizontal>
            }
            <ContainerVertical spacing={0.5}>
                {
                    Array(rowQuantity).fill(undefined).map((_,ix) => (
                        <Skeleton key={ix} variant="rectangular" width="100%" height={rowHeight} />
                    ))
                }
            </ContainerVertical>
            {
                footer && 
                <Box>
                    <Box display="flex" height={50}>
                        <Box flexGrow={1}/>
                        <Skeleton variant="rectangular" width="30%" height="100%"/>
                    </Box>
                    <Box display="flex">
                        <Box flexGrow={1}/>
                        <Skeleton variant="text" width="25%"/>
                    </Box>
                </Box>
            }
        </ContainerVertical>
    );
}

export default SkeletonTable;
