
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
CacheBuster Context
********************************************************************************************
Boilerplate context, consumer, provider and hook
based loosely off: 
https://dev.to/flexdinesh/cache-busting-a-react-app-22lk
https://github.com/CagriAldemir/react-cache-buster

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       15th Jan 2021

*******************************************************************************************/
import React            from 'react';
import { compare }      from 'compare-versions';

// Debug Mode
const DEBUG = false;

// The Product Context 
const CacheBusterContext = React.createContext(undefined);

// Helper Function, to compare versions
const isVersionNew = (metaVersion, serverVersion) => compare(serverVersion, metaVersion, '>');

// CacheBuster Provider
const CacheBusterProvider = ({
    children            = null,
    isEnabled           = false,
    isVerboseMode       = false
}) => {

    const [cacheStatus, setCacheStatus] = React.useState({
        loading             : true,
        isLatestVersion     : false,
        version             : undefined,
        versionClient       : undefined,
    });


    // Logger Function
    const log = React.useCallback((message, isError) => {
        isVerboseMode && (isError ? console.error(message) : console.log(message));
    },[isVerboseMode]);


    // Function to check
    const refresh = React.useCallback(async () => {
        if(DEBUG) console.log('Clearing cache and hard reloading...')
        try {
            if (window?.caches) {
                const { caches } = window;
                const cacheNames = await caches.keys();
                for (const cacheName of cacheNames) {
                    caches.delete(cacheName);
                }
                log('The cache has been deleted.');
                window.location.reload(true);

            }
        } catch (error) {
            log('An error occurred while deleting the cache.', true);
            log(error, true);
        }
    },[log]);


    const checkCacheStatus = React.useCallback( async (shouldReload = false) => {
        try {

            // Load the existing version from local storage
            const existingVersion = localStorage.getItem('appVersion');

            // Null Auth
            const headers = {
                authorization: 'Bearer null'
            }

            // Load the client version from meta.json file
            const metaVersion   = await fetch(existingVersion ? `/meta.json?v=${existingVersion}` : '/meta.json', {method:'GET',headers})
                                            .then(res => res.json())
                                            .then(({version:metaVersion}) => metaVersion)

            // Load the server version from query
            const serverVersion = await fetch('/api/public/version',{method:'GET',headers})
                                            .then(res => res.json())
                                            .then(({data}) => data)
                                            .then(({version:serverVersion}) => serverVersion)

            // Update the local storage
            localStorage.setItem('appVersion', serverVersion);

            // Report
            if(DEBUG) console.log('Client Version:', metaVersion);
            if(DEBUG) console.log('Server Version:', serverVersion);

            // Should Refresh be Forced
            const shouldForceRefresh = isVersionNew(metaVersion, serverVersion);

            // Change State
            if (shouldForceRefresh) {
                if(DEBUG) console.log(`New software version - ${serverVersion}. Forcing Refresh`);
                setCacheStatus({
                    loading             : false,
                    isLatestVersion     : false,
                    version             : serverVersion,
                    versionClient       : metaVersion
                });

                // Refresh if flag set
                if(shouldReload)
                    refresh();

            } else {
                if(DEBUG) console.log(`You already have the latest version - ${serverVersion}. No cache refresh needed.`);
                setCacheStatus({
                    loading             : false,
                    isLatestVersion     : true,
                    serverVersion       : serverVersion,
                    versionClient       : metaVersion
                });
            }
        }catch(err){

            log('An error occurred while checking cache status.', true);
            log(err, true);

            //Since there is an error, if isVerboseMode is false, the component is configured as if it has the latest version.
            !isVerboseMode &&
                setCacheStatus(prev => ({
                    ...prev,
                    loading             : false,
                    isLatestVersion     : true
                }));
        }
    },[isVerboseMode, log, refresh])

    React.useEffect(() => {
        if(isEnabled){
            checkCacheStatus(true); // Refresh first time, after that, just flag that update is needed
            let interval = setInterval(checkCacheStatus, 5 * 60 * 1000) // Every 5 minutes
            return () => {
                clearInterval(interval);
            }
        }else{
            log('React Cache Buster is disabled.');
        }
    }, [checkCacheStatus, isEnabled, log]);


    const value = React.useMemo(() => ({
        isEnabled,
        isVerboseMode,
        version             : cacheStatus.version,
        versionClient       : cacheStatus.versionClient,
        loading             : cacheStatus.loading,
        isLatestVersion     : cacheStatus.isLatestVersion,
        refresh
    }),[cacheStatus.isLatestVersion, cacheStatus.loading, cacheStatus.version, cacheStatus.versionClient, isEnabled, isVerboseMode, refresh]);

    return (
        <CacheBusterContext.Provider value={value}>
            {children}
        </CacheBusterContext.Provider>
    )
}

// CacheBuster Consumer
const CacheBusterConsumer = ({children}) => {
    return (
        <CacheBusterContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('CacheBusterConsumer must be used within CacheBusterProvider');
                }
                return children(context)
            }}
        </CacheBusterContext.Consumer>
    )
}

// useCacheBuster Hook
const useCacheBuster = () => {
    const context = React.useContext(CacheBusterContext);
    if(context === undefined)
        throw new Error('useCacheBuster must be used within CacheBusterProvider');
    return context;
}

export {
    CacheBusterProvider,
    CacheBusterConsumer,
    useCacheBuster
}