/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Mobile DateTimePicker for RFF
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       18th February 2023

*******************************************************************************************/

import React                        from 'react';
import {
	MobileDateTimePicker  as MobileDateTimePickerMUI
}                                   from '@mui/x-date-pickers';
import { TextField }      			from '@mui/material';
import { Field }                    from 'react-final-form';
import { showErrorOnChange }        from 'mui-rff';


export function DateTimePicker(props) {
	const { name, fieldProps, required, ...rest } = props;
	return (
		<Field
			name        = {name}
			render      = {(fieldRenderProps) => (
                <DateTimePickerWrapper {...fieldRenderProps} {...rest} />
            )}
			required    = {required}
			{...fieldProps}
		/>
	);
}

export default DateTimePicker;

function DateTimePickerWrapper(props) {
	const {
		input: { name, onChange, value, ...restInput },
		meta,
		showError = showErrorOnChange,
		required,
		...rest
	} = props;

	const { error, submitError } = meta;
	const isError = showError({ meta });
	const { helperText, textFieldProps, ...lessrest } = rest;

	return (
		<MobileDateTimePickerMUI
			onChange   			= {onChange}
			value       		= {value === '' ? null : value}
			{...lessrest}
			renderInput 		= {(inputProps) => (
				<TextField
					{...textFieldProps}
					{...inputProps}
					fullWidth   = {true}
					helperText  = {isError ? error || submitError : helperText}
					error       = {inputProps.error || isError}
					name        = {name}
					required    = {required}
					inputProps  = {{
						...inputProps.inputProps,
						onBlur: (event) => {
							inputProps.inputProps?.onBlur?.(event);
							restInput.onBlur(event);
						},
						onFocus: (event) => {
							inputProps.inputProps?.onFocus?.(event);
							restInput.onFocus(event);
						},
					}}
				/>
			)}
		/>
	);
}