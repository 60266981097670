/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Comment Form
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       1st May 2022

*******************************************************************************************/
import React                            from 'react';
import {isNil}                          from 'lodash';
import { useTheme, Box }                from '@mui/material';
import CloudUploadIcon                  from '@mui/icons-material/CloudUpload';
import CancelIcon                       from '@mui/icons-material/Cancel';
import {
    Form,
    Login
}                                       from 'components';
import { useTranslation }               from 'contexts';
import { 
    TextField,
    showErrorOnChange   as showError,
}                                       from 'mui-rff';
import {FormSpy}                        from 'react-final-form';
import { useUser } from 'contexts';

const noop  = () => {}
const obj   = {};

const defaultFormData =  {
    parentId        : undefined,
    id              : undefined,
    comment         : undefined,
};

export const CommentForm = ({
    formData                    = defaultFormData,
    disabled                    = false,
    onSubmit : handleSubmit     = noop,
    onCancel : handleCancel     = noop,
    onReset : handleResetIn     = noop,
    onFocus : handleFocus       = noop,
    FormProps                   = obj,
    showResetButton             = true,
    showCancelButton            = false,
    enterToShowButtons          = false,
    placeholder                 = undefined,
    submitText                  = undefined,
    cancelText                  = undefined,
    variant                     = "standard",
    label                       = null,
    showObjectId                = false,
    rows                        = null,
    alertComponent              = undefined,
    ...rest
}) => {
    const {t}                       = useTranslation();
    const theme                     = useTheme();
    const {
        isAuthenticated,
        loginWithPopup
    }                               = useUser();
    const [entered, setEntered]     = React.useState(!enterToShowButtons);
    const [comment, setComment]     = React.useState(formData.comment)

    React.useEffect(() => {
        handleFocus(entered);
    },[entered, handleFocus])

    const validate = React.useCallback( (values) => {

        // Empty error object
        let errors          = {};

        // If disabled, no error check
        if(disabled) 
            return errors;
    
        const maximum   = 2000;
        const value     = (values.comment || '').trim();
        if(!isNil(values.comment) && (isNil(value) || !value))
            errors.comment = errors.comment || t('components.forms.commentForm.required')
        if(values.comment && values.comment.length > maximum)
            errors.commant = errors.comment || t('components.forms.commentForm.maxCharacters', {maximum, current : values.comment.length})
            
        // Done
        return errors;
    },[disabled, t])

    const handleReset   = React.useCallback( () => {
        if(enterToShowButtons)
            setEntered(false);
        handleResetIn();
    }, [enterToShowButtons, handleResetIn]);

    const handleEntered = React.useCallback( () => {
        if(enterToShowButtons)
            setEntered(true);
    }, [enterToShowButtons]);

    // Initial Values
    const initialValues = {
        ...formData
    }

    // Parent Id is Required
    if(!initialValues.parentId)
        return null;

    return (
        <Form
            debug                   = {false}
            disabled                = {disabled}
            onSubmit                = {handleSubmit}
            onCancel                = {handleCancel}
            onReset                 = {handleReset}
            initialValues           = {initialValues}
            validate                = {validate}
            successMessage          = {t('components.forms.commentForm.commentSubmitted')}
            {...FormProps}
            SubmitButtonProps       = {{
                startIcon : <CloudUploadIcon/>,
                ...FormProps.SubmitButtonProps
            }}
            ResetButtonProps        = {{
                startIcon : <CancelIcon/>,
                ...FormProps.ResetButtonProps,
            }}
            resetText               = {cancelText || t('components.forms.commentForm.cancel')}
            submitText              = {submitText || t('components.forms.commentForm.submit')}
            changeRequiredToSubmit  = {true}
            disabledSubmit          = {!initialValues.parentId || !comment}
            disabledAfterSubmit     = {true}
            forceEnableReset        = {true}
            sticky                  = {false}
            showSubmitButton        = {entered}
            showResetButton         = {entered && showResetButton}
            showCancelButton        = {showCancelButton}
            resetOnSubmit           = {true}
            showObjectId            = {showObjectId}
            alertComponent          = {alertComponent}
            render                  = {({disabled, form, error, dirtySinceLastSubmit, submitFailed, submitSucceeded, errors, handleSubmit, values, ...rest}) => {
                return (
                    <form onSubmit={handleSubmit} noValidate>
                        <Box display="flex">
                            <Box flexGrow={1}>
                                <TextField 
                                    multiline
                                    label       = {label}
                                    name        = "comment" 
                                    disabled    = {disabled}
                                    showError   = {showError}
                                    // maxrows     = {10}
                                    rows        = {rows}
                                    variant     = {variant}
                                    placeholder = {
                                        isAuthenticated 
                                            ? (placeholder || t('components.forms.commentForm.placeholder'))
                                            : t('components.forms.commentForm.loginToComment')
                                    }
                                    onFocus     = {handleEntered}
                                    onKeyDown   = {isAuthenticated ? null : loginWithPopup}
                                    // onBlur      = {() => setEntered(false)}
                                    inputProps  = {{readOnly : !isAuthenticated}}
                                    // inputProps  = {{maxRows : 10}}
                                />
                            </Box>
                            {
                                !isAuthenticated &&
                                <Box>
                                    <Login color={theme.palette.mode === 'dark' ? 'secondary' : 'primary'} size="small" popup={true} variant="outlined" sx={{ml:1,transform:'translatey(5px)'}}>
                                        {t('common.login')}
                                    </Login>
                                </Box>
                            }
                            </Box>
                        <FormSpy 
                            subscription = {{values:true}}
                            onChange     = {props => {
                                if(props?.values?.comment !== comment)
                                    setComment(props?.values?.comment)
                            }}
                        />
                        <FormSpy 
                            subscription = {{submitSucceeded:true}}
                            onChange     = {props => {
                                if(enterToShowButtons)
                                    setEntered(false)
                            }}
                        />
                    </form>
                )
            }}
        />
    )
}

export default CommentForm;