/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Credits Balance Component
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       22nd December 2020

*******************************************************************************************/
import React                            from 'react';
import { 
    useUser,
    useAccountCredits,
    useLocale
}                                       from 'contexts';
import { withTranslation }              from './hoc';

export const CreditsBalance = withTranslation( ({t, render = undefined}) => {
    const { 
        balance, 
        isRetrieved,
        working, 
        CREDITS_NAME
    }                                       = useAccountCredits();
    const { isAuthenticated }               = useUser();
    const { formatNumber }                  = useLocale();
    
    if(!isAuthenticated || !isRetrieved)
        return null;
        
    return (
        <>
            {
                render && 
                render({balance, working, title : CREDITS_NAME})
            }
            {   
                !render &&
                <span>{!working ? formatNumber(balance || 0) : t('common.loading') }</span>
            }
        </>
    )
});

export default CreditsBalance;