/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
with Feature Loading HOC
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       22nd July 2022

*******************************************************************************************/
import React                            from 'react';
import { styled, Box }                  from '@mui/material';
import LoadingData                      from 'components/LoadingData';
import useSize                          from 'hooks/useSize';

const obj = {};

const LoadingComponentContainer = styled(Box)(({theme}) => ({
    position    : 'absolute',
    top         : '50%',
    left        : '50%',
    transform   : `translate(-50%, -50%)`,
}))

const withStyledContainerComponent  = Component => styled(Component, {
    shouldForwardProp : (prop) => prop !== 'minWidth' && prop !== 'minHeight' && prop !== 'loading'
})(({loading = false, minWidth = 0, minHeight = 0}) => ({
    position    : "relative",
    width       : '100%',
    height      : '100%',
    overflow    : 'hidden',
    minHeight   : loading ? minHeight : 0,
    minWidth    : loading ? minWidth  : 0
}));

export const withFeatureLoading = Component => ({
    containerComponent  : ContainerComponent            = Box,
    loading                                             = false, 
    showFeature                                         = false,
    loadingComponent : LoadingComponent                 = LoadingData,
    containerProps                                      = obj,
    loadingProps                                        = obj,
    componentProps                                      = obj,
    ...props
}) => {

    const ref                       = React.useRef(null)
    const {width,height}            = useSize(ref);
    const StyledContainerComponent  = React.useMemo(() => withStyledContainerComponent(ContainerComponent), [ContainerComponent]);
    return (
        <StyledContainerComponent {...containerProps} loading={loading} minHeight={height} minWidth={width}>
            <Component {...props} {...componentProps}/>
            {   
                (loading || showFeature) &&
                <LoadingComponentContainer ref={ref}>
                    <LoadingComponent {...loadingProps} />
                </LoadingComponentContainer>
            }
        </StyledContainerComponent>
    )
}

export default withFeatureLoading