/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Fat Button
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       30th November 2022

*******************************************************************************************/
import React                            from 'react';
import {
    alpha,
    styled,
    Box,
    Button as ButtonMUI,
}                                       from '@mui/material';
import withDisabledOffline              from 'components/hoc/withDisabledOffline';

const StyledButton = styled(ButtonMUI)(({theme})=>{
    const color  = theme.palette.mode === 'light' 
        ? theme.palette.primary 
        : theme.palette.secondary;
    return {
        '&.MuiButton-root' : {
            padding     : theme.spacing(2),
            background  : alpha(color.light,0.3),
            color       : color.main,
            '&:hover' : {
                background  : color.light,
                color       : color.contrastText,
            },
        }
    }
});

const Button = withDisabledOffline(StyledButton);

export const FatButton = ({ children, icon : Icon = undefined, iconProps = {}, iconContainerProps = {}, ...props }) => (
    <Button {...props} >
        <Box display="flex" flexDirection="column" height="100%">
            {
                Icon && 
                <Box width="100%" {...iconContainerProps}>
                    <Icon {...iconProps} style={{fontSize:100,...iconProps.style}}/>
                </Box>
            }
            <Box minHeight="25px" width="100%"> 
                {children}
            </Box>
        </Box>
    </Button>  
)

export default FatButton;
