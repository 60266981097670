/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Cache Buster
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       1st Jan 2021

*******************************************************************************************/
import React                    from 'react';
import { 
    useMediaQuery, 
    Button 
}                               from '@mui/material';
import {
    MainAlert,
    RefreshIcon
}                               from 'components';
import { withTranslation }      from './hoc';
import { 
    useCacheBuster,
    useNetwork
}                               from 'contexts';

const GRACE_PERIOD = 15000;

export const AlertVersionUpdate = withTranslation( ({t}) => {
    
    const { isOnline }              = useNetwork();
    const { 
        isEnabled, 
        version, 
        versionClient,
        loading, 
        isLatestVersion, 
        refresh
    }                               = useCacheBuster();
    const [ready,   setReady]       = React.useState(false);
    const [working, setWorking]     = React.useState(false);

    // Handle Button Click
    const handleClick = React.useCallback( () => {
        setWorking(true); // Set working
        refresh();
    }, [refresh]);

    // Set not working when client version changes
    React.useEffect(() => (
        setWorking(false)
    ), [versionClient]);

    // Allow this to be shown only after an inocculation period;
    React.useEffect(() => {
        const timeout = setTimeout(() => setReady(true), GRACE_PERIOD);
        return () => {
            clearTimeout(timeout);
        }
    },[])

    const mdUp = useMediaQuery(theme => theme.breakpoints.up('md'));

    // Break Conditions
    if(!isOnline || isLatestVersion || loading || !isEnabled || !ready){
        return null;
    }
    
    // Render
    return (
        <MainAlert 
            severity    = "info" 
            action      = {
                <Button 
                    disabled    = { working } 
                    startIcon   = { 
                        <RefreshIcon loading={working} size="small"/> 
                    } 
                    onClick     = { handleClick } 
                    size        = "small" 
                    variant     = "contained" 
                    color       = "primary"
                >
                    {
                        mdUp 
                            ? t('common.refreshBrowser')
                            : t('common.refresh')
                    }
                </Button>
            }
        >
            {
                version 
                    ? t('components.alertVersionUpdate.newVersionWithVersion', { version })
                    : t('components.alertVersionUpdate.newVersionDefault' )
            }
        </MainAlert>
    )
});

export default AlertVersionUpdate;