/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
useContentdulPage Hook
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       20th November 2020

*******************************************************************************************/
import React                from 'react';
import moment               from 'moment';
import { 
    useContentful,
}                            from 'contexts';

export const useContentfulPage = (slug, exact = true) => {

    const { getPage }               = useContentful();
    const [ queried, setQueried ]   = React.useState(undefined);
    const [ loading, setLoading ]   = React.useState(false);
    const [ data,    setData ]      = React.useState(undefined);

     React.useEffect(() => {
        setLoading(true);
        getPage(slug, exact)
            .then(items => exact ? items[0] : items)
            .then(setData)
            .finally(() => {
                setLoading(false)
                setQueried(moment())
            })
    },[ getPage, slug, exact])

    return {
        queried,
        loading,
        data,
        dataSeo : data?.fields?.seoFields
    }
}

export default useContentfulPage;