/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Examples
********************************************************************************************
Produces an array of example maps readings for the user to click and listen

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       1st November 2021

*******************************************************************************************/
import React                            from 'react';
import isNil                            from 'lodash/isNil';
import moment                           from 'moment';
import formatcoords                     from 'formatcoords';
import { 
    styled,
    Box,
    Card, 
    DialogActions, 
    Grid, 
    Typography 
}                                       from '@mui/material';
import {
    FindInPage as FindInPageIcon
}                                       from '@mui/icons-material';
import {
    useHistory
}                                       from 'react-router-dom';
import {
    FeatureBox,
    GoogleMapsStatic,
    NoDataPrompt,
    Skeleton,
    LoadingData,
    SpaceBox,
    LibraryAvatar,
    Button
}                                       from 'components';
import { withTranslation }              from './hoc';
import {
    useLocale,
    useUser,
    useExamples
}                                       from 'contexts';
import { UserLibraryLocation }          from 'router/locations/Locations';

const obj = {};

const MapsContainer = styled(Box)({
    width           : '100%',
    height          : 100,
    marginBottom    : 5 
})

const RelativeContainer = styled(Box)({
    position        : 'relative'
})

const ChildContainer = styled(Box)({
    height          : '100%',
    position        : 'relative'
})

const CenteredContainer = styled(Box)({
    position        : 'absolute',
    textAlign       : 'center',
    top             : '50%',
    transform       : 'translateY(-50%)',
    left            : 0,
    right           : 0
})

const Text = styled(Typography)({
    fontSize        : 10
})

// List of examples
export const Examples = withTranslation( ({
    t,
    component   : Container     = FeatureBox, 
    productId   : productIdIn   = undefined, 
    quantity    : quantityIn    = 3, 
    zoom        : zoomIn        = 12, 
    containerProps              = obj,
    hideIfNone                  = false,
    ...props
}) => {

    // Hooks
    const history                       = useHistory();
    const {
        loading,
        working,
        refresh,
        setEnabled,
        data:examples,
        hasExamples,
        quantity,
        setQuantity,
        productId,
        setProductId
    }                                   = useExamples();
    const {formatDateTime}              = useLocale();
    const {
        isAuthenticated,
        loginWithPopup : login
    }                                   = useUser();

    // Update Quantity
    React.useEffect(() => {
        if(quantityIn !== quantity)
            setQuantity(quantityIn)
    }, [quantity, quantityIn, setQuantity]);

    // Update ProductId
    React.useEffect(() => {
        if(productIdIn !== productId)
            setProductId(productIdIn);
    }, [productId, productIdIn, setProductId]);

    // Enable examples context
    React.useEffect(() => {
        setEnabled(true);
    }, [setEnabled]);

    // Update Zoom
    const zoom              = React.useMemo(() => zoomIn, [zoomIn])
    const examplesQuantity  = React.useMemo(() => Array.isArray(examples) ? examples.length : 0, [examples])

    if(hideIfNone && !hasExamples)
        return null;

    return (
        <Container id={`examplesRoot`} {...containerProps}>
            {
                props.renderTitle && 
                props.renderTitle({refresh, working, examplesQuantity})
            }
            <Box width="100%">
                {
                    hasExamples && 
                    
                        <Grid container>
                        {
                            // Iterate over examples
                            examples.map((example,ix) => {

                                // Extract natal data
                                const {lat,lng,birthDateTime} = example?.metadata?.userFieldsData?.natalRecord || {};
                                
                                // Handle navigate to library record
                                const handleNavigateToLibrary = () => history.push(UserLibraryLocation.toUrl({id:example._id}));

                                // Check if natal record is valid
                                const isValid = !isNil(lat) && !isNil(lng) && birthDateTime;

                                // Return
                                return (
                                    <Grid id={`example${ix}Container`} key = {ix} item lg={4} md={6} sm={6} xs={12}>
                                        {
                                            false && 
                                            JSON.stringify(example,null,4)
                                        }
                                        {
                                            isValid && 
                                            <Card 
                                                id = {`example${ix}Card`} 
                                                sx = {{
                                                    background  : 'inherit',
                                                    height      : '100%',
                                                    border      : 'none'
                                                }}
                                            >
                                                <Box display="flex" flexDirection="column" style={{height:'100%'}}>
                                                    <Box flexGrow={1}>
                                                        <MapsContainer>
                                                            <GoogleMapsStatic zoom={zoom} lat={lat.toFixed(2)} lng={lng.toFixed(2)} onClick = {handleNavigateToLibrary}/>
                                                        </MapsContainer>
                                                        <Box pt={1} display="flex">
                                                            <Box pr={1}>
                                                                <LibraryAvatar src={example.photoGetSignedUrl} size={50}/>
                                                            </Box>
                                                            <Box>
                                                                <Text id={`example${ix}Coords`} sx={{fontWeight:600}}>
                                                                    {formatcoords(lat, lng).format({decimalPlaces:1})}
                                                                </Text>
                                                                <Text id={`example${ix}DOB`} >
                                                                    {t('components.examples.bornYear',{year:moment(birthDateTime).year()})}
                                                                </Text>
                                                                {
                                                                    example?.processDate &&
                                                                    <Text id={`example${ix}views`} sx={{fontStyle:'italic'}}>
                                                                        {t('components.examples.createdAt', {timeStamp : formatDateTime(moment(example?.processDate)) })}
                                                                    </Text>
                                                                }
                                                                <Text id={`example${ix}views`} sx={{fontStyle:'italic'}}>
                                                                    {t('components.examples.viewedTimes',{quantity:example?.views || 0})}
                                                                </Text>
                                                            </Box>
                                                        </Box>

                                                    </Box>
                                                    <Box flexShrink={1}>
                                                        <DialogActions id={`example${ix}Actions`} sx={{pr:0,pb:0}}>
                                                            <Button 
                                                                startIcon   = {<FindInPageIcon/>} 
                                                                size        = "small" 
                                                                variant     = "contained" 
                                                                color       = "primary" 
                                                                onClick     = {isAuthenticated ? handleNavigateToLibrary : login}
                                                            >
                                                                {
                                                                    isAuthenticated 
                                                                        ? t('components.examples.viewReading')
                                                                        : t('common.loginToView')
                                                                }
                                                            </Button>
                                                        </DialogActions>
                                                    </Box>
                                                </Box>
                                            </Card>
                                        }
                                        {
                                            !isValid && 
                                            <RelativeContainer>
                                                <CenteredContainer>
                                                    <Typography id={`example${ix}NoData`} variant="body2">
                                                        {t('common.noData')}
                                                    </Typography>
                                                </CenteredContainer>
                                            </RelativeContainer>
                                        }
                                    </Grid>
                                )
                            })
                        }
                        </Grid>
                }
                {
                    !hasExamples &&
                    <ChildContainer>
                        {
                            false && loading && 
                            <Grid container style={{height:'100%'}} spacing={1}>
                                {
                                    Array(quantity).fill(1).map((_,ix) => (
                                        <Grid key = {ix} item sm={4} xs={12} style={{aspectRatio:'1.4142'}}>
                                            <Skeleton loading={true} height="100%"/>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        }
                        <SpaceBox align="center">
                            { 
                                loading 
                                    ? <LoadingData />
                                    : (
                                        <NoDataPrompt> 
                                            {t('components.examples.noPublicExamples')} 
                                        </NoDataPrompt>
                                    )
                            }
                        </SpaceBox>
                    </ChildContainer>
                }
            </Box>
        </Container>
    )
});

export default Examples;