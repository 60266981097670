/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Pages -- Health
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       5th December 2021

*******************************************************************************************/
import React                from 'react';
import { Typography }       from '@mui/material';
import { 
    Title,
    PageContainer,
    RootContainer
}                           from 'components'
import {
    useAPIHealth
}                           from 'contexts';

export const Health = () => {
    const {isAPIHealthy}                    = useAPIHealth();
    return (
        <RootContainer>
            <PageContainer>
                <Title>
                    Heath Check
                </Title>
                <Typography>
                    {
                        isAPIHealthy 
                        ? 'Server Healthy' 
                        : "Server Not responsive"
                    }
                </Typography>
            </PageContainer>
        </RootContainer>
    )
}

export default Health;