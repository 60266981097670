/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
useFormValidation Hook
********************************************************************************************
Based Loosely off: 
    https://codesandbox.io/s/vn5v04484y?file=/src/useFormValidation.js

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       24th December 2020

*******************************************************************************************/
import React            from 'react'
import {isEqual}        from 'lodash';
import { 
    FORM_ERROR as FORM_ERROR_FF
}                       from 'final-form';
import {useNetwork}     from '../contexts';

const isPromise = (value) => {
    return value && value.then && typeof value.then === 'function';
}

export const FORM_ERROR = FORM_ERROR_FF;

export const useFormValidation = ({ 
    initialState    = {}, 
    validate, 
    onSubmit,
    onSubmitSuccess = ()        => {},
    onSubmitFailure = (err)     => {},
    onSubmitStart   = ()        => {},
    onSubmitEnd     = ()        => {},
    onChange        = (values)  => {}
} = {}) => {
    const {isNetworkReady}                  = useNetwork();
    const [touched,         setTouched]     = React.useState({});
    const [values,          setValues]      = React.useState(initialState);
    const [errors,          setErrors]      = React.useState({});
    const [isSubmitting,    setSubmitting]  = React.useState(false);
    const [isDirty,         setDirty]       = React.useState(false);

    // Set dirty if the values are different to the initial state
    React.useEffect(() => {
        setDirty(!isEqual(values,initialState))
    }, [values,initialState]);

    React.useEffect(() => { 
        onChange(values,errors);
    //eslint-disable-next-line react-hooks/exhaustive-deps
    },[values,errors])

    React.useEffect(()=>{
        if(!isNetworkReady){
            setErrors(existing => {
                return {...existing, [FORM_ERROR]: "Offline"};
            }); // Error in non-promis version  
        }else{
            setErrors({});
        }
    //eslint-disable-next-line react-hooks/exhaustive-deps
    },[isNetworkReady])
 
    // submission
    React.useEffect(() => {
        if (isSubmitting) {
            onSubmitStart();
            const noErrors = Object.keys(errors).length === 0;
            if (noErrors && onSubmit){
                try{
                    // Submit, may get a promise back or just data
                    const result = onSubmit(values);

                    if(isPromise(result)){
                        return result
                            .then((result) => {
                                let {errors:submissionErrors = {}} = result || {};
                                setErrors(prev => ({...prev,...submissionErrors}));
                                if(Object.keys(submissionErrors).length === 0){
                                    onSubmitSuccess();
                                }
                            })
                            .catch((err) => {
                                setErrors({[FORM_ERROR]: err.message || "Form Error"}); // Error in non-promis version
                                onSubmitFailure(err);
                            })
                            .finally(() => {
                                setSubmitting(false); // Done
                                onSubmitEnd();
                            })
                    }else{
                        const {errors:submissionErrors={}} = result || {};
                        setErrors(prev => ({...prev,...submissionErrors}));
                        if(Object.keys(submissionErrors).length === 0)
                            onSubmitSuccess();
                    } 
                }catch(err){
                    setErrors({[FORM_ERROR]: err.message || "Form Error"}); // Error in non-promise version
                    onSubmitFailure(err);
                }
            }else if(noErrors){ // no onSubmit function
                onSubmitSuccess();
            }

            // Done
            onSubmitEnd();
            setSubmitting(false);
        }
    //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmitting]);

    const processValidation = (force = false) => {
        return new Promise(async (resolve) => {
            const validationErrors  = await validate(values);
            const touchedKeys       = Object.keys(touched);
            const errors            = Object.keys(validationErrors).reduce((acc,cur) => {
                return (touchedKeys.includes(cur) || force)
                    ? {...acc,[cur]:validationErrors[cur]}
                    : {...acc}
            },{})
            setErrors(errors);
            resolve(errors);
        })
    }

    const handleFocus = (event) => {
        setTouched((oldTouched) => ({
            ...oldTouched,
            [event.target.name]: true, 
        }))
    }

    const handleChange = (event) => {

        handleFocus(event); // Simulate focus gained
        setValues((oldValues) => ({
            ...oldValues,
            [event.target.name]: event.target.value
        }));
    }

    const handleBlur = () => {
        processValidation();
    }

    const handleSubmit = (event) => {
        event && event.preventDefault();
        touchAll();
        processValidation(true)
            .then(()=>{
                setSubmitting(true); // trigger useEffect
            });
    }

    const touchAll = () => {
        let newTouched = Object.keys(values).reduce((acc,cur) => ({...acc,[cur]:true}),{});
        setTouched(newTouched);
    }
    const touchOne = (name) => {
        setTouched((oldTouched)=> ({...oldTouched,[name] : true}))
    }

    const reset = () => {
        setValues(initialState); 
        setTouched({});
        setErrors({});
    }
 
    const mutate = (name, value, touch=true) => {
        const names = Object.keys({...initialState,...values});
        if(names.includes(name)){
            // Mark as touched
            if(touch) touchOne(name) 
            // Set the Values
            setValues( prev => ({...prev,  [name] : value}));
        }else{
            console.log(`${name} not recognised`);
        } 
    } 

    //eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(processValidation,[values]);
 
    const   hasErrors   = Boolean(Object.keys(errors).length),
            isPristine  = !isDirty;

    return {
        handleSubmit,
        handleChange,
        handleFocus,
        handleBlur,
        mutate,
        touch : touchOne,
        values,
        errors,
        touched,
        isSubmitting,
        isDirty,
        isPristine,
        reset,
        hasErrors,
        validate    : processValidation
    };
};

export default useFormValidation;