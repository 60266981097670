/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Notifications
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       2nd August 2021

*******************************************************************************************/
import React                            from 'react';
import {isEqual}                        from 'lodash';
import moment                           from 'moment';
import {useHistory,useLocation}         from 'react-router-dom';
import {
    styled,
    useTheme,
    alpha,
    // darken,
    Box,
    TableContainer,
    Table,
    TableRow as TableRowMUI,
    TableCell as TableCellMUI,
    TableHead,
    TableBody,
    Typography,
    Tooltip
}                                       from '@mui/material';
import { 
    useNotification, 
    useLocale,
    useLibraryViewer,
    LibraryViewerProvider
}                                       from 'contexts';
import { 
    DeleteIconButton,
    ReadIconButton
}                                       from 'components/iconButtons';
import {
    NoDataPrompt,
    ObjectId,
    Button
}                                       from 'components';
import { withTranslation }              from './hoc';
import {
    UserOrderLocation,
    UserLibraryLocation,
    UserLibraryCollectionLocation
}                                       from 'router/locations';

const DELIVERY_QUERY_PARAM_NAME = 'deliveryId'

const NotificationButton = ({onClick, fontSize = '0.75rem', fontStyle = 'italic', ...props}) => {
    const theme = useTheme();
    return (
        <Button 
            onClick     = {onClick} 
            color       = {theme.palette.mode === 'light' ? "primary" : 'secondary'} 
            {...props}
            sx          = {{
                textTransform   : 'none', 
                p               : 0.25, 
                mx              : 0,
                minWidth        : 0,
                fontSize        : fontSize,
                fontStyle       : fontStyle,
                whiteSpace      : 'nowrap',
                ...props.sx
            }}   
        />
    )
}

const TableRow = styled(TableRowMUI,{
    shouldForwardProp : prop => prop !== 'currentRow'
})(({theme,currentRow=false}) => ({
    /*background : theme.palette.background.paper,
    '&:hover' : {
        background : [darken(theme.palette.background.paper,0.025),"!important"],
    },
    */
    ...(currentRow && {
        background : alpha(theme.palette.error.main,0.25),
        '&:hover' : {
            background : `${alpha(theme.palette.error.main,0.35)}!important`,
        }
    })
}))

const TableCell = styled(TableCellMUI,{
    shouldForwardProp : prop => !['objectId','head','lastRow','lastCol','nowrap','message'].includes(prop)
})(({theme, objectId = false, head = false, lastRow = false, lastCol = false, nowrap = false, message = false}) => ({
    padding             : 0,
    fontSize            : '0.75em',
    paddingRight        : theme.spacing(0.5),
    ...(head && {
        fontWeight      : 400
    }),
    ...(objectId && {
        paddingRight    : 1,
        fontStyle       : 'italic',
        textAlign       : 'center',
        minWidth        : 50
    }),
    ...(lastCol && {
        textAlign       : 'right',
        paddingLeft     : theme.spacing(0),
        paddingRight    : theme.spacing(0)
    }),
    ...(lastRow && {
        borderBottom    : 'none',
    }),
    ...(nowrap && {
        whiteSpace      : 'nowrap',
    }),
    ...(message && {
        textAlign       : 'left',
        paddingLeft     : theme.spacing(0.25),
        paddingRight    : theme.spacing(0.25)
    })
}));

const Component = withTranslation( ({t, ...props}) => {
    
    const history                           = useHistory();
    const location                          = useLocation();
    const {libraryId}                       = useLibraryViewer();
    const [lastViewed, setLastViewed]       = React.useState(undefined);
    const {
        working, 
        notifications, 
        markDeleted,
        markRead
    }                                       = useNotification();
    const { 
        formatDateTime, 
        formatTime,
        formatDate
    } = useLocale();
    
    const formatdt  = React.useCallback( dt => {
        const isToday = moment(dt).format('L') === moment().format('L')
        return isToday 
            ? formatTime(dt) 
            : formatDate(dt);
    }, [formatDate, formatTime]);

    return (
        <Box width="100%">
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell head={true} nowrap={true}>
                                {t('common.time')}
                            </TableCell>
                            <TableCell head={true} objectId={true}>
                                {t('components.notifications.table.ord')}
                            </TableCell>
                            <TableCell head={true} objectId={true}>
                                {t('components.notifications.table.del')}
                            </TableCell>
                            <TableCell head={true} objectId={true}>
                                {t('components.notifications.table.lib')}
                            </TableCell>
                            <TableCell head={true} sx={{pl:1}}>
                                {t('common.message')}
                            </TableCell>
                            <TableCell lastCol={true} nowrap={true}/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            notifications.map(({ _id : id, read, message, order, delivery, library, createdAt }, ix) => {

                                const notificationData      = {
                                    order       : order         ? order         : null,
                                    delivery    : delivery      ? delivery      : null,
                                    library     : library       ? library       : null 
                                }
                                const handleUpdateLastViewed    = () => setLastViewed(notificationData)
                                const handleDelete              = (e) => {
                                    if(e) e.stopPropagation();
                                    markDeleted(id);
                                }
                                const handleRead                = (e) => {
                                    if(e) e.stopPropagation();
                                    markRead({id, read:true, query : true})
                                }
                                const handleJumpToOrder         = () => {
                                    handleUpdateLastViewed();
                                    handleRead(false);
                                    window.scrollTo({ top : 0, behavior : 'smooth' })
                                    history.push(UserOrderLocation.toUrl({id:order}))
                                }
                                const handleJumpToDelivery         = () => {
                                    handleUpdateLastViewed();
                                    handleRead(false);
                                    if(!delivery)
                                        window.scrollTo({ top : 0, behavior : 'smooth' })
                                    history.push(UserOrderLocation.toUrl({id:order}) + (delivery ? `?${DELIVERY_QUERY_PARAM_NAME}=${delivery}` : ''))
                                }
                                const handleJumpToLibrary   = () => {
                                    handleUpdateLastViewed();
                                    handleRead(false);
                                    history.push(UserLibraryCollectionLocation.path + `?id=${library}`)
                                }
                                const lastRow                   = ix >= notifications.length -1;
                                const hasLibrary                = Boolean(library);
                                const hasOrder                  = Boolean(order);
                                const isCurrent                 = isEqual(notificationData,lastViewed);

                                const isCurrentPage             = [UserOrderLocation.toUrl({id:order})].includes(location.pathname)
                                const isOrderPage               = [hasOrder   ? UserOrderLocation.toUrl({id:order})                 : undefined].filter(Boolean).includes(location.pathname)
                                const isLibraryPage             = [hasLibrary ? UserLibraryLocation.toUrl({id:library})             : undefined].filter(Boolean).includes(location.pathname)
                                const isLibraryCollectionPage   = [hasLibrary ? UserLibraryCollectionLocation.toUrl()               : undefined].filter(Boolean).includes(location.pathname) && libraryId && libraryId === library;
                                if(!lastViewed && [isCurrentPage,isOrderPage,isLibraryPage,isLibraryCollectionPage].some(Boolean)){
                                    handleUpdateLastViewed()
                                }
                                const timestamp                 = moment(createdAt);
                                return (
                                    <TableRow key={id} currentRow={isCurrent} hover={true}>
                                        <TableCell message={true} lastRow={lastRow}>
                                            <Tooltip title={formatDateTime(timestamp)} arrow>
                                                <Typography 
                                                    color   = {
                                                        read 
                                                            ? 'textSecondary' 
                                                            : 'inherit'
                                                    }
                                                    style   = {{
                                                        fontSize        : 'inherit', 
                                                        marginRight     : 2,
                                                        display         : 'inline',
                                                        whiteSpace      : 'nowrap'
                                                    }} 
                                                >
                                                    { formatdt(timestamp) }
                                                </Typography>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell objectId={true} lastRow={lastRow}>
                                            <ObjectId 
                                                value   = { order } 
                                                length  = { -4 } 
                                                render  = {({ id }) => (
                                                    <NotificationButton onClick = {handleJumpToOrder}>
                                                        { t('components.notifications.orderId', { id }) }
                                                    </NotificationButton>
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell objectId={true} lastRow={lastRow}>
                                            <ObjectId 
                                                value   = { delivery } 
                                                length  = { -4 } 
                                                render  = {({ id }) => (
                                                    <NotificationButton onClick = {handleJumpToDelivery}>
                                                        { t('components.notifications.deliveryId', { id }) }
                                                    </NotificationButton>
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell objectId={true} lastRow={lastRow}>
                                            <ObjectId 
                                                value   = { library } 
                                                length  = { -4 } 
                                                render  = {({ id }) => (
                                                    <NotificationButton onClick = {handleJumpToLibrary}>
                                                        { t('components.notifications.libraryId', { id }) }
                                                    </NotificationButton>
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell message={true} lastRow={lastRow}>
                                            { 
                                                hasLibrary && 
                                                <NotificationButton onClick = {handleJumpToLibrary} fontSize = 'inherit' fontStyle = 'inherit'>
                                                    {message}
                                                </NotificationButton>
                                            }
                                            {
                                                hasOrder && !hasLibrary &&
                                                <NotificationButton onClick = {handleJumpToDelivery} fontSize = 'inherit' fontStyle = 'inherit'>
                                                    {message}
                                                </NotificationButton>
                                            }
                                            {
                                                !hasOrder &&
                                                <Typography 
                                                    color   = {read ? 'textSecondary' : 'inherit'}
                                                    style   = {{
                                                        fontSize        : 'inherit', 
                                                        marginRight     : 2,
                                                        display         : 'inline',
                                                        whiteSpace      : 'nowrap'
                                                    }}
                                                >
                                                    {message}
                                                </Typography>
                                            }
                                        </TableCell>
                                        <TableCell lastCol={true} nowrap={true} lastRow={lastRow}>
                                            {
                                                !read &&
                                                <ReadIconButton 
                                                    color       = "secondary" 
                                                    size        = "small" 
                                                    disabled    = {working || read} 
                                                    onClick     = {handleRead}
                                                />
                                            }
                                            <DeleteIconButton   
                                                color       = "secondary" 
                                                size        = "small" 
                                                disabled    = {working} 
                                                onClick     = {handleDelete}
                                            />
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {
                notifications.length === 0 && 
                <Box p={2}>
                    <NoDataPrompt variant="body2">
                        { t('components.notifications.noNotifications') }
                    </NoDataPrompt>
                </Box>
            }
        </Box>
    )
});

export const Notifications = (props) => {
    return (
        <LibraryViewerProvider>
            <Component {...props} />
        </LibraryViewerProvider>
    )
}


export default Notifications;