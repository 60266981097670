/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Comment Form
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       1st May 2022

*******************************************************************************************/
import React                            from 'react';
import flatten, {unflatten}             from 'flat';
import CloudUploadIcon                  from '@mui/icons-material/CloudUpload';
import CancelIcon                       from '@mui/icons-material/Cancel';
import { Form }                         from 'components';
import { useTranslation }               from 'contexts';
import { 
    TextField,
    showErrorOnChange   as showError,
}                                       from 'mui-rff';
import {FormSpy}                        from 'react-final-form';

const noop  = () => {}
const obj   = {};

export const FieldForm = ({
    name                                = 'field',
    label                               = null,
    value : valueIn                     = undefined,
    disabled                            = false,
    onSubmit    : handleSubmitMaster    = noop,
    onCancel    : handleCancel          = noop,
    onReset     : handleResetIn         = noop,
    FormProps                           = obj,
    enterToShowButtons                  = false,
    placeholder                         = undefined,
    submitText                          = undefined,
    cancelText                          = undefined,
    minLength                           = 0,
    maxLength                           = 32,
    ...rest
}) => {
    const {t}                       = useTranslation();
    const [entered, setEntered]     = React.useState(!enterToShowButtons);
    const [value,   setValue]       = React.useState(valueIn)

    const validate = React.useCallback( (vals) => {

        let values = flatten(vals)

        // Empty error object
        let errors = {};

        // If disabled, no error check
        if(disabled) 
            return errors;

        // Now process errors
        const bounds = {
            [name] : { min : minLength,   max : maxLength },
        };

        Object.keys(bounds).forEach(k => {
            const {min, max} = bounds[k];
            let value = (values[k] || '').trim();
            if(value.length > max)
                errors[k] = errors[k] || t('components.forms.fieldForm.maxChars', { maximum : max, current : value.length })
            if(value.length < min)
                errors[k] = errors[k] || t('components.forms.fieldForm.minChars', { minimum : min, current : value.length })
        })

        // Done
        return unflatten(errors);

    },[disabled, maxLength, minLength, name, t])

    // Initial Values
    // https://final-form.org/docs/final-form/field-names
    const initialValues = React.useMemo(() => (
        unflatten({ [name] : valueIn || "" })
    ), [name, valueIn])

    const handleReset   = React.useCallback( () => {
        if(enterToShowButtons)
            setEntered(false);
        handleResetIn();
    },[enterToShowButtons, handleResetIn])

    const handleEntered = React.useCallback( () => {
        if(enterToShowButtons)
            setEntered(true);
    }, [enterToShowButtons])

    // Handle Submit
    const handleSubmit = (vals) => {
        let values = flatten(vals)
        if(!values[name]) 
            values[name] = ""
        return handleSubmitMaster(unflatten(values));
    }

    return (
        <Form
            debug                   = {false}
            disabled                = {disabled}
            onSubmit                = {handleSubmit}
            onCancel                = {handleCancel}
            onReset                 = {handleReset}
            initialValues           = {initialValues}
            validate                = {validate}
            successMessage          = "Submitted"
            {...FormProps}
            SubmitButtonProps       = {{
                size      : "small",
                startIcon : <CloudUploadIcon/>,
                ...FormProps.SubmitButtonProps
            }}
            CancelButtonProps        = {{
                size      : "small",
                startIcon : <CancelIcon/>,
                ...FormProps.ResetButtonProps
            }}
            cancelText              = {cancelText || t('components.forms.fieldForm.cancelText')}
            submitText              = {submitText || t('components.forms.fieldForm.submitText')}
            changeRequiredToSubmit  = {true}
            disabledSubmit          = {false}
            disabledAfterSubmit     = {true}
            sticky                  = {false}
            showSubmitButton        = {entered}
            showResetButton         = {false}
            showCancelButton        = {entered}
            resetOnSubmit           = {true}
            showObjectId            = {false}
            render                  = {({disabled, form, error, dirtySinceLastSubmit, submitFailed, submitSucceeded, errors, handleSubmit, values, ...rest}) => {
                return (
                    <form onSubmit={handleSubmit} noValidate>
                        <TextField 
                            multiline
                            label       = {label} //"Comment"
                            name        = {name}
                            disabled    = {disabled}
                            showError   = {showError}
                            maxRows     = {10}
                            placeholder = {placeholder || t('components.forms.fieldForm.placeholder')}
                            onFocus     = {handleEntered}
                        />
                        <FormSpy 
                            subscription = {{values:true}}
                            onChange     = {props => {
                                if(props?.values[name] !== value)
                                    setValue(props?.values[name] || '')
                            }}
                        />
                        <FormSpy 
                            subscription = {{submitSucceeded:true}}
                            onChange     = {props => {
                                if(enterToShowButtons)
                                    setEntered(false)
                            }}
                        />
                    </form>
                )
            }}
        />
    )
}

export default FieldForm;