/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
LibraryViewOrderButton
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       29th May 2022

*******************************************************************************************/
import React                            from 'react';
import {
    Tooltip,
    IconButton,
}                                       from '@mui/material';
import {
    ShoppingBasket      as ShoppingBasketIcon
}                                       from '@mui/icons-material';
import {Link}                           from 'react-router-dom';
import { 
    useLibraryViewer,
    useUser,
}                                       from 'contexts';
import { 
    UserOrderLocation,
}                                       from 'router/locations/Locations';
import { withTranslation }              from 'components/hoc';

export const LibraryViewOrderButton = withTranslation( ({t}) => {
    
    const {userId,isAdmin}  = useUser();
    const {data, loading}   = useLibraryViewer();
    const orderId           = React.useMemo(() => data?.delivery?.order, [data?.delivery?.order]);
    const deliveryId        = React.useMemo(() => data?.delivery?.id, [data?.delivery?.id]);
    const isOwner           = React.useMemo(() => userId && userId === data?.user, [data?.user, userId]);
    const destination       = React.useMemo(() => orderId && deliveryId ? `${UserOrderLocation.toUrl({id:orderId})}?deliveryId=${deliveryId}` : '/', [deliveryId, orderId]); // Destination
    const showOrderButton   = React.useMemo(() => !loading && orderId && deliveryId && (isOwner || isAdmin), [deliveryId, isAdmin, isOwner, loading, orderId]);
    
    // Abort
    if(!showOrderButton)
        return null;

    return (
        <IconButton component={Link} to={destination} size="small" color="secondary">
            <Tooltip title={t('components.library.libraryViewOrderButton.viewOriginalOrder')}>
                <ShoppingBasketIcon/>
            </Tooltip>
        </IconButton>
    )
})

export default LibraryViewOrderButton;