/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Read More
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       3rd April 2024

*******************************************************************************************/

import React                            from 'react';
import {Box}                            from '@mui/material'
import Button                           from './Button';

export const ReadMore = ({ children, enabled=false, maxHeight = 200 }) => {

    const [isTruncated, setIsTruncated]     = React.useState(true);
    const containerRef                      = React.useRef(null);

    const containerStyle = {
        maxHeight : isTruncated ? `${maxHeight}px` : 'none',
        overflow  : 'hidden',
    };

    if(!enabled)
        return children;

    return (
        <>
            <Box style={containerStyle} ref={containerRef}>
                {children}
            </Box>
            <Box textAlign={'right'}>
                <Button variant="contained" onClick = {() => setIsTruncated(!isTruncated)} >
                    ... Read { isTruncated ? 'More' : 'Less' }
                </Button>
            </Box>
        </>
    );
};

export default ReadMore;