
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Cart Add Notice
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       3rd May 2021

*******************************************************************************************/
import React                    from 'react';
import { styled, Box }          from '@mui/material';
import { useCart }              from 'contexts/CartContext';
import { useUser, useNetwork }  from 'contexts';
import {
    ViewCartCheckoutButton,
    MainAlert
}                               from 'components';
import { withTranslation }      from './hoc';

const COLOR = 'white';

const Root = styled(Box)({
    width           : '100%',
    position        : 'sticky',
    top             : 0,
    height          : 0,
    zIndex          : 1000,
})

// Full-Width Alert box with Resend Button
export const AlertCartAdd = withTranslation( ({ t }) => {
    
    const { added, quantity }   = useCart();
    const { isNetworkReady }    = useNetwork();
    const { isAuthenticated }   = useUser();

    // Not Online
    if(!isNetworkReady || !isAuthenticated || !added)
        return null;

    return (
        <Root>
            <MainAlert 
                icon        = {false} 
                dismissable = {!true} 
                action      = {
                    <Box style={{color:COLOR}}>
                        <ViewCartCheckoutButton variant="outlined" color="inherit" size="small"/>
                    </Box>
                }
                sx = {{
                    '&.MuiAlert-root' : {
                        borderRadius    : 0,
                        background      : theme => theme.palette.primary.main,
                    },
                    '& > .MuiAlert-message' : {
                        color           : COLOR,
                        maxWidth        : 600,
                        px              : theme => theme.spacing(2),
                        ml              : 'auto',
                        mr              : 0
                    },
                    '& > .MuiAlert-action' : {
                        px              : theme => theme.spacing(2),
                        ml              : 0,
                        mr              : 'auto',
                    }
                }}
            >
                <Box component="span" sx={{pr:0.5}}>
                    { 
                        quantity >  0
                            ? t('components.shoppingCart.itemAdded') 
                            : t('components.shoppingCart.cartEmpty')
                    }
                </Box>
            </MainAlert>
        </Root>
    )
});
// 

export default AlertCartAdd;