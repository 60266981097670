/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
DropZone -- OBSOLETE, USE DROPZONE UPLOADER INSTEAD
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       20th June 2021

*******************************************************************************************/

import React                          from 'react';
import {isEmpty}                      from 'lodash';
import {useDropzone}                  from 'react-dropzone';
import {Box, Typography}              from '@mui/material';
import { withTranslation }            from './hoc';

const baseStyle = {
  flex: 1,
  position:'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  minHeight : 300,
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};


export const Dropzone = withTranslation( ({
  t,
  accept    = 'image/*',
  maxFiles  = 10,
}) => {

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept, 
    maxFiles,
    onDropAccepted : console.log
  });

  const style = React.useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  const acceptedFileItems = React.useMemo(() => (
    acceptedFiles.map(file => (
      <li key={file.path}>
        { t("components.dropzone.fileSizeBytes", { path : file.path, size : file.size }) }
      </li>
    ))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ),[t, JSON.stringify(acceptedFiles || [])]);

  const fileRejectionItems = React.useMemo(() => (
    fileRejections.map(({ file, errors }) => (
      <li key={file.path}>
        { t("components.dropzone.fileSizeBytes", { path : file.path, size : file.size }) }
        <ul>
          {
            errors.map(e => (
              <li key={e.code}>
                {e.message}
              </li>
            ))
          }
        </ul>
      </li>
    ))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ),[t, JSON.stringify(fileRejections || [])]);
  
  return (
    <div>
      {
        maxFiles && 
        <Typography paragraph>
          { t("components.dropzone.uploadMaxFiles", { quantity : maxFiles}) }
        </Typography>
      }
      <div className="container">
          <Box {...getRootProps({style})} >
              <input {...getInputProps()} />
              <Typography style={{position:'absolute',top:'50%',bottom:'50%'}}>
                { t("components.dropzone.dragAndDrop" ) }
              </Typography>
          </Box>
          <Box>
            {
              !isEmpty(acceptedFileItems) && 
              <Box>
                <Typography variant="h6"> 
                  { t("components.dropzone.acceptedFiles" ) }
                </Typography>
                <ul>
                  {acceptedFileItems}
                </ul>
              </Box>
            }
            {
              !isEmpty(fileRejectionItems) && 
              <Box>
                <Typography variant="h6"> 
                  { t("components.dropzone.rejectedFiles" ) }
                </Typography>
                <ul>
                  {fileRejectionItems}
                </ul>
              </Box>
            }
          </Box>
      </div>
    </div>
  );
});

export default Dropzone;