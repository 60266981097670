/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
withTranslation HOC
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       3rd September 2023

*******************************************************************************************/

import React                            from 'react';
import { useTranslation }               from 'contexts';

const withTranslation = (Component) => {
    return function TranslatedComponent(props) {
        const { t, i18n } = useTranslation();
        return (
            <Component 
                t       = {t} 
                i18n    = {i18n} 
                {...props} 
            />
        );
    };
};

export {
    withTranslation,
    useTranslation
}
  
export default withTranslation;
