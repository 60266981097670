/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
DateRangeSummary Component
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       15th May 2021

*******************************************************************************************/

import React                    from 'react';
import {DateChip}               from './DateChip';
import { Typography }           from '@mui/material';
import { withTranslation }      from './hoc';

export const DateRangeSummary = withTranslation( ({
    t,
    children,
    prefix,
    start, 
    finish,
    ...props
}) => (
    <Typography component="div" {...props}>
        {prefix || t('components.dateRangeSummary.prefix')} <DateChip date={start} color="primary" size="small"/> &amp <DateChip date={finish} color="secondary" size="small"/>{children}
    </Typography>
));

export default DateRangeSummary;