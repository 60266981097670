/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Delivery Progress
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       19th February 2022

*******************************************************************************************/
import React                            from 'react';
import { useNetwork, useAlert }         from 'contexts';
import { 
    useCancelToken,
}                                       from 'hooks';

// Note: userFieldsBase must be [ {optionsQueryServer, processor, name}, ... ]

export const useUserFieldOptions = (userFieldsBase, productId) => {

    const {axios}                       = useNetwork();
    const {cancelToken, isCancel}       = useCancelToken();
    const {alert}                       = useAlert();
    const [userFields, setUserFields]   = React.useState(userFieldsBase);

    React.useEffect(() => {
        if(productId && Array.isArray(userFieldsBase)){
            const query = async () => {
                const promises = userFieldsBase.map(async field => {
                    if(field?.optionsQueryServer && field?.processor){
                        return new Promise((resolve) => {
                            axios.post(
                                '/api/product/processors/userField/options',
                                {
                                    productId   : productId, 
                                    fieldName   : field?.name, 
                                    processor   : field?.processor
                                },
                                {
                                    cancelToken
                                }
                            )
                            .then(({data:options}) => options)
                            .then(options => {
                                resolve({...field,options})
                            })
                            .catch((err) => {
                                if(!isCancel(err)) {
                                    
                                    console.error(err)
                                };
                                resolve(field)
                            })
                        })
                    }else{
                        return field;
                    }
                })

                try {
                    const newFields = await Promise.all(promises);
                    setUserFields(newFields);
                } catch (err) {
                    console.error(err);
                    err?.message && alert(err.message,'error');
                }
            };

            // Execute
            query();
        }else{
            console.log("No ProductId or Not Array");
            setUserFields(userFieldsBase);
        }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[axios, cancelToken, JSON.stringify(userFieldsBase), isCancel, productId])

    // Done
    return userFields;
}

export default useUserFieldOptions;