
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
use Cancel Token hook
********************************************************************************************
https://dev.to/mostafaomar98/comment/1lc0g

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       27th February 2022

Usage:      

    const {cancelToken, isCancel} = useCancelToken();               // NOTE USE
    const someQuery = () => new Promise((resolve, reject) => {
        axios.get(`<SOME URL>`, { cancelToken })                    // NOTE USE
            .then(resolve)
            .catch(err => {
                if (isCancel(err)) return resolve({});              // NOTE USE
                reject(err);
            })
    }

*******************************************************************************************/

import React        from 'react';
import {useAxios}   from './services';

export const useCancelToken = ({debug = false} = {}) => {

    const {axios}               = useAxios(); 
    const isCancel              = React.useMemo(() => axios.isCancel, [axios.isCancel]);

    // Seed the source
    const [source, setSource]   = React.useState(axios.CancelToken.source())

    // Cancel Function
    const cancel = React.useCallback( 
        (reason = 'Request Actively Cancelled') => {
            if(debug) console.log("Cancelling: " + reason)
            if(source){
                reason 
                    ? source.cancel(reason) 
                    : source.cancel()
            }else{
                console.log("No Source")
            }
            setSource( axios.CancelToken.source() );
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [axios.CancelToken]
    )
  
    // Cancel on Unmount or cancel function change
    React.useEffect( () => {

        // Cleanup
        return () => {
            cancel('Unmounting');
        }
        
    }, [cancel]);
  
    // Memoise Result
    const memoisedResult = React.useMemo(() => ({ 
        
        cancelToken : source?.token,
        isCancel,
        cancel

    }), [cancel, isCancel, source?.token]);

    // Done
    return memoisedResult;
};

export default useCancelToken;