/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Breadcrumbs
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       13th February 2021

*******************************************************************************************/
import React                        from 'react';
import startCase                    from 'lodash/startCase';
import { Typography, Box }          from '@mui/material';
import HomeIcon                     from '@mui/icons-material/Home';
import { 
    Link,
    useLocation,
    useHistory
}                                   from 'react-router-dom'
import {
    JSONViewer
}                                   from 'components'

const DEBUG = false;

const isMongoId         = str => str.match(/^[0-9a-fA-F]{24}$/);
const capitalize        = str => isMongoId(str || '') ? str : startCase(str);
const BreadCrumbEntry   = ({Icon,title,...props}) => (
    <Typography {...props} variant={'subtitle2'}>
        {
            Icon && 
            <Icon sx={{maxWidth:'1rem',fontSize:'1rem',transform : 'translatey(2px)'}}/>
        }{title}
    </Typography>
);

const BreadCrumbLink    = ({to, title, showLink, Icon, ...props}) => {
    if(!to || !showLink) 
        return (
            <BreadCrumbEntry title={title} Icon={Icon} {...props}/>
        )
    return (
        <Link to={to} style={{ textDecoration: 'none', color:'inherit'}}>
            <BreadCrumbEntry title={title} Icon={Icon} {...props}/>
        </Link>
    )
};

export const BreadCrumbs = ({ BreadCrumbsProps = {}, TypographyProps ={} }) => {
    
    const location              = useLocation();
    const history               = useHistory();
    const [paths, setPaths]     = React.useState([]);
    
    // When location changes, re-build the breadcrumbs
    React.useEffect(()=>{
        if(location.pathname){
            let sep = '/';
            let pathnameSplit = location.pathname.split(sep);
            let arr = pathnameSplit.reduce((acc, label, ix) => {
                const path = pathnameSplit.slice(0, ix + 1).join(sep); // Build vanilla pathname, from 0 .. n
                const record = ix === 0 
                    ? { label: undefined, path:sep, icon:HomeIcon}
                    : { label, path, icon:undefined}

                return [ ...acc, record]
            },[])
            if(location.hash) 
                arr.push({label: location.hash.replace('#',''), path : location.pathname + location.hash}); // Has Hash
            setPaths(arr);
        }else{
            setPaths([]);
        }
    },[location.pathname, location.hash])

    // No Breadcrumbs
    if(!Array.isArray(paths)) 
        return null;

    // Build the breadcrumbs
    return (
        <Box sx={{p:1,pl:2}}>
            <Box aria-label="breadcrumb" {...BreadCrumbsProps} display="flex">
                {
                    paths.map(({label, path, showLink, icon},ix) => {
                        label = label ? label.split(/[-_]+/).join(' ') : label;
                        let title = label ? capitalize(label) : label;
                        return (
                            <Box key={ix} display="flex" sx={{my:'auto'}}>
                                {
                                    ix > 0 && 
                                    <Box ml={1} mr={1}> / </Box>
                                }
                                <BreadCrumbLink 
                                    onClick     = {() => history.push(path)} 
                                    to          = {path} 
                                    title       = {title} 
                                    Icon        = {icon} 
                                    showLink    = {ix <= paths.length - 1} 
                                    {...TypographyProps}
                                />
                            </Box>
                        )
                    })}
            </Box>
            {
                DEBUG && 
                <JSONViewer src={paths}/>
            }
        </Box>
    )
}

export default BreadCrumbs;