/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Make Deposit
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       25th December 2020

*******************************************************************************************/
import React                            from 'react';
import {clone,isEmpty}                  from 'lodash';
import {useHistory}                     from 'react-router-dom';
import { 
    styled,
    Box, Button, Chip, Grid, 
    MenuItem, InputAdornment, 
    InputLabel, Typography 
}                                       from '@mui/material';
import PaymentIcon                      from '@mui/icons-material/Payment';
import CancelIcon                       from '@mui/icons-material/Cancel';
import CreditCardIcon                   from '@mui/icons-material/CreditCard';
import { FORM_ERROR }                   from 'hooks';
import { 
    TextField, 
    Select,
    //showErrorOnBlur     as showError,
    showErrorOnChange   as showError,
}                                       from 'mui-rff';
import {
    Form,
    FormAlert as Alert,
    Login,
    // StripeInfo,
    NoDefaultPaymentMethod,
    // Currency
}                                       from 'components';
import {
    UserSettingsLocation_TabPaymentMethods as PaymentMethods
}                                       from 'router/locations';
import {
    usePaymentMethod,
    useUser,
    useLocale,
    useTranslation
}                                       from 'contexts';
import PaymentMethod                    from 'components/modals/PaymentMethod';
import { RefreshIconButton }            from 'components/iconButtons';


// https://stripe.com/docs/currencies#minimum-and-maximum-charge-amounts
const MIN_DEPOSIT       =        50; // In Cents
const MAX_DEPOSIT       =  99999999; // In Cents

// Presets
const PRESETS           = [2000, 5000, 10000, 20000, 50000, 100000];

const DefaultChip = (props) => (
    <Chip 
        sx={{
            '&.MuiChip-root' : {
                height : 16
            }
        }} 
        color="primary" 
        label="Default" 
        {...props}
    />
)

const InputAdornmentSpaced = styled(InputAdornment)({
    "& .MuiButtonBase-root": {
        position    : "absolute",
        padding     : 0,
        right       : "20px",
        top         : "calc(50% - 12px)",
      },
})

const noop = () => {}
const noopOnSubmit = (values) => new Promise(resolve => resolve({[FORM_ERROR]:'Not Implemented', amount:'not implemented'}))
const obj = {}

const defaultFormData = {
    amount          : 0,
    currency        : undefined,
    paymentMethod   : undefined
}

const AddPaymentButton = ({disabled = false, onClick : handleClick = noop}) => {
    const {t} = useTranslation();
    return (
        <Button startIcon={<CreditCardIcon/>} disabled={disabled} onClick={handleClick} variant="contained" color="secondary">
            {t('components.forms.paymentForm.createNewMethod')}
        </Button>
    )
}

const ChangeDefaultPaymentButton = ({quantity = 0, disabled = false, onClick : handleClick = noop}) => {
    const {t} = useTranslation();
    if(quantity <= 0)
        return null
    return (
        <Button disabled={disabled} onClick={handleClick} variant="contained" color="secondary">
            {t('components.forms.paymentForm.changeDefaultMethod')}
        </Button>
    )
}

const Presets = ({disabled, presets : presetsIn, amountMinimum, values, onChange : handleChange = noop}) => {
    const {t}                   = useTranslation();
    const { 
        currency,
        currencyFactor,
        formatCurrency
    }                           = useLocale();
    const cncy                  = React.useCallback( (value) => formatCurrency(parseFloat(value) || 0, currency), [currency, formatCurrency]);
    const presets               = presetsIn.filter(p => p >= Math.max(MIN_DEPOSIT, amountMinimum))
    const isOther               = !presets.map(preset => Math.abs((values?.amount || 0) - preset/currencyFactor) < 0.01).some(Boolean);

    if(isEmpty(presets)) 
        return null;
        
    return (
        <Box>
            <InputLabel shrink={true}>
                {t('components.forms.paymentForm.paymentPresets')}
            </InputLabel>
            <Box pt={1}>
                {
                    presets.map((preset,ix) => {
                        const isPreset = Math.abs((values?.amount || 0) - preset/currencyFactor) <= 0.01
                        return (
                            <div key={ix} style={{padding:4, display:'inline-block'}}>
                                <Chip 
                                    disabled    = {disabled} size='small' color={isPreset ? 'secondary' : 'default'} 
                                    label       = {cncy(preset / currencyFactor)}
                                    onClick     = {() => handleChange((preset/currencyFactor).toFixed(2))}
                                />
                            </div>
                        )
                    })
                }
                <div style={{padding:4, display:'inline-block'}}>
                    <Chip 
                        disabled    = {disabled} 
                        size        = 'small' 
                        color       = {isOther ? 'secondary' : 'default'} 
                        label       = {t('components.forms.paymentForm.custom')}
                        onClick     = {() => handleChange(undefined)}
                    />
                </div>
            </Box>
        </Box>
    )
};

const CCLabel = ({paymentMethod, simple=false }) => {
    const {brand ='', last4, exp_month, exp_year} = paymentMethod?.data?.card || {};
    const ccSan = (last4) => simple ? `ending in ${last4}` : ` ${last4}`;
    const ccExp =  `, Exp ${exp_month}/${(exp_year || '').toString().slice(2,4)}`;
    return (
        <React.Fragment>
            {!simple && <strong>{brand.toUpperCase()} </strong>}{ccSan(last4)}{!simple && ccExp}
        </React.Fragment>
    )
}

export const PaymentForm = ({
    formData                = defaultFormData,
    amountMinimum           = 0,
    allowChangeDefault      = true,
    fixed                   = false,
    disabled                = false,
    submittingMessage       = undefined, 
    successMessage          = undefined,
    onSubmit                = noopOnSubmit,
    onCancel : handleCancel = noop,
    FormProps               = obj,
    presets                 = PRESETS
}) => {
    const {t}               = useTranslation();
    const history           = useHistory();
    const {
        currency,
        currencyFactor,
        formatCurrency,
        formatCurrencyNoSymbol
    }                       = useLocale();

    if(formData.currency !== currency)
        throw new Error( t('components.forms.paymentForm.currencyMustBe', { currency }) );

    const {isAuthenticated}                         = useUser();
    const {
        loading,
        refreshing,
        hasDefault, 
        createMethod, 
        quantity, 
        paymentMethods, 
        paymentMethodDefault,
        refresh
    }                                               = usePaymentMethod();
    const [submitting, setSubmitting]               = React.useState(false);
    const [openPaymentMethod, setOpenPaymentMethod] = React.useState(false);
    const handleOpenPaymentMethod                   = React.useCallback( (e) => {
        e.preventDefault();
        setOpenPaymentMethod(true);
    },[]);
    const handleClosePaymentMethod                  = React.useCallback( () => setOpenPaymentMethod(false), []);
    const validate                                  = React.useCallback( (values) => {
        let errors = {};

        // Required Values
        ['amount','paymentMethod'].forEach(key => {
            if(values[key] === undefined)
                errors[key] = errors[key] || t('components.forms.paymentForm.required')
        })

        // Check the amount is ok
        if(values.amount){
            const amount    = parseFloat(values.amount) * currencyFactor;
            const minimum   = Math.max(MIN_DEPOSIT, amountMinimum || 0.0);
            if(!Number.isFinite(amount) || isNaN(amount))
                errors.amount = errors.amount || t('components.forms.paymentForm.invalidValue')
            else if(amount < minimum)
                errors.amount = errors.amount || t('components.forms.paymentForm.minValue', { value : formatCurrency(minimum / currencyFactor) } );
            else if(amount > MAX_DEPOSIT)
                errors.amount = errors.amount || t('components.forms.paymentForm.minValue', { value : formatCurrency(MAX_DEPOSIT / currencyFactor) } );
        }

        // Check payment method is one of the users payment methods
        if(values.paymentMethod)
            if(!paymentMethods.find(x => x._id === values.paymentMethod))
                errors.paymentMethod = errors.paymentMethod || t('components.forms.paymentForm.invalidPaymentMethod');

        // Done
        return errors;
    }, [amountMinimum, currencyFactor, formatCurrency, paymentMethods, t]);

    const handleStartSubmitting = () => setSubmitting(true);
    const handleEndSubmitting   = () => setSubmitting(false);
    const handleSubmit = (values) => new Promise((resolve) => {
        const formData = clone(values);
        handleStartSubmitting();
        let result = {
            ...formData,
            paymentMethod   : paymentMethods.find(x => x._id === formData.paymentMethod),
            amount          : Math.round(formData.amount * currencyFactor),
            currency        : currency
        }
        Promise.resolve(result)
            .then(onSubmit)
            .then(resolve)
            .catch(resolve)
            .finally(handleEndSubmitting)
    })
    const initialValues = {
        ...formData,
        amount          : !isNaN(formData.amount) ? formatCurrencyNoSymbol(formData.amount / currencyFactor) : formData.amount,
        paymentMethod   : formData.paymentMethod || (paymentMethodDefault || {})._id
    };

    const handlePaymentMethodChange = React.useCallback((e) => {
        e.preventDefault();
        handleCancel();
        if(!window.location.href.includes(PaymentMethods.path))
            history.push(PaymentMethods.path)
    }, [handleCancel, history])

    const handleCreateMethod = React.useCallback( ({paymentMethod, isDefault}) => {
        handleClosePaymentMethod();
        createMethod({type : 'STRIPE', paymentMethod, isDefault});
    }, [createMethod, handleClosePaymentMethod]);

    const MethodOptions = React.useCallback( () => (
        <div onClick={(e)=>{e.preventDefault()}} style={{width : '100%',textAlign : 'right', paddingTop:10}}>
           <AddPaymentButton disabled={disabled || submitting} onClick={handleOpenPaymentMethod}/> { allowChangeDefault && <ChangeDefaultPaymentButton quantity={quantity} disabled={disabled || submitting} onClick={handlePaymentMethodChange}/>}
        </div>
    ), [allowChangeDefault, disabled, handleOpenPaymentMethod, handlePaymentMethodChange, quantity, submitting])

    // Formatting Function
    const cncy = React.useCallback( (value) => formatCurrency(parseFloat(value) || 0, currency), [currency, formatCurrency]);

    // Commentary
    const Notes = React.useCallback( ({paymentMethod, amount}) => {

        // const method = paymentMethods.find(ix => ix._id === paymentMethod);
        
        if(isNaN(amount)) 
            return null;

        /*
        const UsingMethod = () => {
            if(!method) return null;
            return (
                <>{method?.isDefault ? t('components.forms.paymentForm.usingDefaultCard') :  t('components.forms.paymentForm.usingCard')} <strong><CCLabel simple={true} paymentMethod={method}/></strong>,</>
            )
        }
        */

        if(loading || refreshing){
            return (
                <Alert severity="info">
                    {t('common.loading')}
                </Alert>
            )
        }

        return null;

        /*
        return (
            <Alert severity="info">
                To authorize payment of <strong><Currency value={amount}/></strong>, <UsingMethod/> please 
                click '{t('components.forms.paymentForm.submit')}'. 
                {
                    !method && 
                    <>
                        Note, you have not defined any payment methods yet.
                    </>
                }
            </Alert>
        )
        */
    }, [t, loading, refreshing]);

    // Not authenticated
    if(!isAuthenticated) 
        return (
            <Box width="100%">
                <Typography>
                    {t('components.forms.paymentForm.loginToMakePayment')}
                </Typography>
                <Box align="center" p={3}>
                    <Login force={true} variant="contained" color="primary" style={{minWidth:100}}>
                        {t('common.loginToAccount')}
                    </Login>
                </Box>
            </Box>
        )
    
    // No default payment
    if(allowChangeDefault && !hasDefault && !quantity){
        return (
            <React.Fragment>
                <NoDefaultPaymentMethod onClick = {handleOpenPaymentMethod}/>
                <PaymentMethod open={openPaymentMethod} onOk={handleCreateMethod} onCancel={handleClosePaymentMethod}/>
            </React.Fragment>
        )
    }

    // Default
    return (
        <>  
            <Form 
                debug                       = {false}
                disabled                    = {disabled || submitting}
                onSubmit                    = {handleSubmit}
                onCancel                    = {handleCancel}
                initialValues               = {initialValues}
                validate                    = {validate}
                submitText                  = {t('components.forms.paymentForm.submit')}
                confirmationRequired        = {true}
                confirmationRequiredText    = {(values) => t('components.forms.paymentForm.confirmMakePayment', { amount : cncy( values.amount ) }) }
                successMessage              = {successMessage || t('components.forms.paymentForm.successMessage')}
                submittingMessage           = {submittingMessage || t('components.forms.paymentForm.authorizing')}
                SubmitButtonProps           = {{startIcon: <PaymentIcon /> }}
                CancelButtonProps           = {{startIcon: <CancelIcon /> }}
                {...FormProps}
                render                      = { ({form, error, dirtySinceLastSubmit, submitFailed, submitting, submitSucceeded, errors, handleSubmit, values,...rest}) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate>
                            <Grid container>
                                {
                                    amountMinimum > MIN_DEPOSIT && values.amount < amountMinimum && 
                                    <Grid item xs={12}>
                                        <Alert severity="warning">
                                            {t('components.forms.paymentForm.minimumDeposit', { amount : cncy( amountMinimum / currencyFactor) }) }
                                        </Alert>    
                                    </Grid>   
                                }
                                {
                                    quantity > 1 && !hasDefault && !values.paymentMethod && 
                                    <Grid item xs={12}>
                                        <Typography variant="body2">
                                            {t('components.forms.paymentForm.multipleCardsOnFile', { quantity })}
                                        </Typography>
                                    </Grid>
                                }
                                {
                                    !isNaN(values.amount) && !submitSucceeded && !submitting && (values.amount * currencyFactor) >= Math.max(amountMinimum, MIN_DEPOSIT) &&
                                    <Grid item xs={12}>
                                        <Notes paymentMethod={values.paymentMethod} amount={values.amount * currencyFactor}/>
                                    </Grid>
                                }
                                
                                <Grid item xs={12} style={{paddingTop:0, paddingbottom:0}}>
                                    <MethodOptions />
                                </Grid>

                                {   
                                    !fixed && 
                                    <Grid item xs={12}>
                                        <Presets 
                                            disabled        = {disabled || submitting} 
                                            presets         = {presets} 
                                            values          = {values} 
                                            amountMinimum   = {amountMinimum}
                                            onChange        = {(value) => form.change('amount', value) }
                                        />
                                    </Grid>
                                }

                                <Grid item xs={8}>
                                    <Select 
                                        disabled    = {disabled || submitting}
                                        name        = 'paymentMethod' 
                                        label       =  {t('components.forms.paymentForm.method')}
                                        showError   = {showError}
                                        inputLabelProps = {{
                                            shrink : true
                                        }}
                                        SelectProps={{
                                            MenuProps: { disablePortal: true }
                                        }}
                                        endAdornment = {
                                            <InputAdornmentSpaced position="end">
                                                <RefreshIconButton 
                                                    disabled    = {loading || refreshing} 
                                                    IconProps   = {{loading : loading || refreshing}} 
                                                    size        = "small" 
                                                    onClick     = {refresh} 
                                                />
                                            </InputAdornmentSpaced>
                                        }
                                    >
                                        {
                                            paymentMethods
                                                .filter(pm => Boolean(pm._id))
                                                .map((pm,ix) => (
                                                    <MenuItem key={ix} value={pm._id}>
                                                        <Typography variant="body2" component='span'>
                                                            <CCLabel paymentMethod={pm}/>
                                                        </Typography> 
                                                        {
                                                            pm.isDefault && 
                                                            <DefaultChip style={{marginLeft:8}}/> 
                                                        } 
                                                    </MenuItem>
                                                )
                                            )
                                        }
                                    </Select>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField 
                                        label       =  {t('components.forms.paymentForm.amount')}
                                        name        = "amount" 
                                        type        = 'number' 
                                        disabled    = {disabled || submitting || fixed}
                                        inputProps  = {{
                                            style: { textAlign: 'right' },
                                        }}
                                        InputProps={{
                                            startAdornment : (
                                                <InputAdornment position="start">
                                                    {currency}
                                                </InputAdornment>)
                                        }}
                                        showError = {showError}
                                    />
                                </Grid>
                            </Grid>
                        </form>
                    )
                }}
            />
            <PaymentMethod open={openPaymentMethod} onOk={handleCreateMethod} onCancel={handleClosePaymentMethod}/>
        </>
    )
}

export default PaymentForm;