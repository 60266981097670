/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Stripe Input
********************************************************************************************
Based loosely off: 
  https://github.com/mui-org/material-ui/issues/16037
  https://codesandbox.io/s/stripe-0xez4?file=/src/App.tsx:252-330

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       28th December 2020

*******************************************************************************************/

import React                        from "react";
import { alpha, useTheme }          from "@mui/material/styles";
// import { InputBaseComponentProps }  from "@mui/material/InputBase";

/*
export const StripeInput = React.forwardRef((props,ref) => {
  const {
    component: Component,
    inputRef,
    "aria-invalid"      : ariaInvalid,
    "aria-describedby"  : ariaDescribeBy,
    defaultValue,
    required,
    onKeyDown,
    onKeyUp,
    readOnly,
    autoComplete,
    autoFocus,
    type,
    name,
    rows,
    options,
    ...other
  }                               = props;

  const theme                     = useTheme();
  const [mountNode, setMountNode] = React.useState(null);

  React.useImperativeHandle(
    inputRef,
    () => ({
      focus: () => mountNode.focus()
    }),
    [mountNode]
  );

  return (
    <Component
      onReady={setMountNode}
      options={{
        ...options,
        style: {
          base: {
            color       : theme.palette.text.primary,
            fontSize    : '1rem',
            fontFamily  : theme.typography.fontFamily,
            "::placeholder": {
              color: alpha(theme.palette.text.primary, 0.42)
            }
          },
          invalid: {
            color: theme.palette.text.primary
          }
        }
      }}
      {...other}
    />
  );
})
*/

// https://github.com/mui/material-ui/issues/16037
// https://codesandbox.io/s/stripe-0xez4?file=/src/StripeInput.tsx:88-154

export const StripeInput = React.forwardRef(

  function StripeInput(props, ref) {
    const { component: Component, disabled, options, ...other } = props;
    const theme                     = useTheme();
    const [mountNode, setMountNode] = React.useState(null);

    React.useImperativeHandle(
      ref,
      () => ({
        focus: () => mountNode.focus()
      }),
      [mountNode]
    );

    return (
      <Component
        onReady={setMountNode}
        options={{
          disabled,
          ...options,
          style: {
            base: {
              color       : theme.palette.text.primary,
              fontSize    : "16px",
              // lineHeight  : "1.4375em", // 23px
              fontFamily  : theme.typography.fontFamily,
              "::placeholder": {
                color: alpha(theme.palette.text.primary, 0.42)
              }
            },
            invalid: {
              color: theme.palette.text.primary
            }
          }
        }}
        {...other}
      />
    );
  }
);

export default StripeInput;