/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Address Modal
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       24th December 2020

*******************************************************************************************/

import React                from 'react';
import { Box }              from '@mui/material';
import { withTranslation }  from 'components/hoc';

const AddressForm       = React.lazy(() => import('components/forms/AddressForm'));
const DraggableDialog   = React.lazy(() => import('components/DraggableDialog'));

const defaultData =  {
    _id         : null,
    kind        : "BILLING",
    firstName   : '',
    middleName  : '',
    lastName    : '',

    countryCode : undefined,
    stateCode   : '',
    city        : '',
    address1    : '',
    address2    : '',
    postCode    : ''
};

const noop = () => {}
const noopSubmit = (values) => {}
const formProps = {showObjectId : false };

export const Address = withTranslation( ({
    t,
    children,
    disabled                    = false,
    title                       = undefined,
    formData                    = defaultData,
    open                        = false,
    onSubmit    : handleSubmit  = noopSubmit,
    onCancel    : handleCancel  = noop,
    onClose     : handleClose   = noop,
    ...props
}) => (
    <DraggableDialog 
        maxWidth    = {'xs'} 
        open        = {open} 
        onClose     = {handleClose} 
        title       = {title || t('components.modals.address.address')} 
        {...props} 
        showButtons = {false} 
        readOnly    = {disabled} 
    >
        <Box>
            {children}
        </Box>
        <Box>
            <AddressForm 
                disabled            = {disabled}
                formData            = {formData} 
                onSubmit            = {handleSubmit} 
                onCancel            = {handleCancel} 
                showSubmitButton    = {!disabled}
                cancelText          = {disabled ? t('components.modals.address.close') : undefined}
                FormProps           = {formProps}
            />
        </Box>
    </DraggableDialog>
))

export default Address;