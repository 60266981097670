/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Comments Title
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       12th May 2022

*******************************************************************************************/
import { Box, Typography }              from '@mui/material';   
import { useTranslation }               from 'contexts';

export const CommentsTitle = ({childCount = undefined}) => {
    const {t} = useTranslation();
    return (
        <Box sx={{m:'auto'}}>
            <Typography align="left" variant="h5" component="h3">
                {
                    childCount 
                        ? t('components.comments.commentsCount', {count : childCount}) 
                        : t('components.comments.comments') 
                }
            </Typography>
        </Box>
    )
}

export default CommentsTitle;