import React from 'react';
import { Loading } from './Loading';

export const Redirecting = ({children, title="Redirecting"}) => {
    return (
        <React.Fragment>
            {children}
            <Loading> 
                {title}
            </Loading> 
        </React.Fragment>
    )
}

export default Redirecting;