

/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Formatted Moment
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       29th December 2020

*******************************************************************************************/
import React                            from 'react';
import moment                           from 'moment-timezone';

export const FormattedMoment = ({
    time    = undefined,
    prefix  = undefined,
    tz      = undefined
}) => {
    let timestamp;
    try{
        timestamp = tz 
            ? moment.utc(time).tz(tz) 
            : moment(time).tz(moment.tz.guess());
        timestamp = timestamp.format("lll z")
    }catch(err){
        timestamp = undefined;
    }
    if(!timestamp) 
        return null;
    return (
        <React.Fragment>
            {prefix}{prefix ? " " : ""}{timestamp}
        </React.Fragment>
    )
};

export default FormattedMoment;

