/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Authentication Error Component
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       30th May 2022

*******************************************************************************************/

import React                from 'react';
import { Box, Typography }  from '@mui/material';
import { 
    Title,
    RootContainer
}                           from 'components';
import { useTranslation }   from 'contexts';

export const Authentication = ({
    children, 
    title       = undefined, 
    message     = undefined, 
    location,
    ...props
}) => {
    const {t} = useTranslation();
    return (
        <RootContainer {...props}>
            <Title>
                {location?.state?.title || title || t('components.errors.authentication.title')}
            </Title>
            <Typography>
                {location?.state?.message || message || t('components.errors.authentication.description')}
            </Typography>
            {
                children && 
                <Box>
                    {children}
                </Box>
            }
        </RootContainer>
    )
}

export default Authentication;