/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Fabs
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       30th May 2021

*******************************************************************************************/
import React                            from 'react';
import { Tooltip, Badge, Box}           from '@mui/material';
import {
    Add         as AddIcon,
    Edit        as EditIcon,
    Delete      as DeleteIcon,
    Storage     as StorageIcon,
    Payment     as PaymentIcon,
    DateRange   as DateRangeIcon,
    FilterList  as FilterListIcon,
    Sort        as SortIcon,
    ClearAll    as ClearAllIcon,
    Engineering as EngineeringIcon,
    Map         as MapIcon
}                                       from '@mui/icons-material';
import { RefreshIcon }                  from 'components';
import { PulseFab as Fab }              from 'components/pulse';
import { useTranslation }               from 'contexts';

const IconTooltip = ({children, title, ...props}) => {
    if(!title)
        return (
            <Box component="span" sx={{p:0,lineHeight:0}}>
                {children}
            </Box>
        )
    return (
        <Tooltip title={title} {...props}>
            <Box component="span" sx={{p:0,lineHeight:0}}>
                {children}
            </Box>
        </Tooltip>
    )
}

const IconFab = ({children, title, ...props}) => {
    return (
        <Fab pulse = {false} size="small" color = "primary" {...props} sx={{zIndex:0, mx:1.5, ...props.sx}}>
            {children}
        </Fab>
    )
}


export const FabCreate = ({tooltip : tt, ...props}) => {
    const {t}       = useTranslation();
    const tooltip   = React.useMemo(() => tt || t('components.fabs.create'),[tt,t])
    return (
        <IconFab color = "secondary" aria-label={tooltip} {...props}>
            <IconTooltip title={tooltip}>
                <StorageIcon />
            </IconTooltip>
        </IconFab> 
    )
}

export const FabData = ({tooltip : tt, loading = false, disabled = false, ...props}) => {
    const {t}       = useTranslation();
    const tooltip   = React.useMemo(() => tt || t('components.fabs.data'),[tt,t])
    return (
        <IconFab disabled = {Boolean(loading || disabled)} aria-label={tooltip} {...props} >
            <IconTooltip title={tooltip}>
                <StorageIcon />
            </IconTooltip>
        </IconFab> 
    )
}

export const FabDelete = ({tooltip : tt, ...props}) => {
    const {t}       = useTranslation();
    const tooltip   = React.useMemo(() => tt || t('components.fabs.delete'),[tt,t])
    return (
        <IconFab color = "secondary" aria-label={tooltip} {...props}>
            <IconTooltip title={tooltip}>
                <DeleteIcon />
            </IconTooltip>
        </IconFab>  
    )
}

export const FabEdit = ({tooltip : tt, ...props}) => {
    const {t}       = useTranslation();
    const tooltip   = React.useMemo(() => tt || t('components.fabs.edit'),[tt,t])
    return (
        <IconFab aria-label={tooltip} {...props}>
            <IconTooltip title={tooltip}>
                <EditIcon />
            </IconTooltip>
        </IconFab> 
    )
}

export const FabLookupMap = ({tooltip : tt, ...props}) => {
    const {t}       = useTranslation();
    const tooltip   = React.useMemo(() => tt || t('components.fabs.lookup'),[tt,t])
    return (
        <IconFab aria-label={tooltip} {...props}>
            <IconTooltip title={tooltip}>
                <MapIcon />
            </IconTooltip>
        </IconFab> 
    )
}


export const FabEngineering = ({tooltip : tt, ...props}) => {
    const {t}       = useTranslation();
    const tooltip   = React.useMemo(() => tt || t('components.fabs.engineering'),[tt,t])
    return (
        <IconFab aria-label={tooltip} {...props}>
            <IconTooltip title={tooltip}>
                <EngineeringIcon />
            </IconTooltip>
        </IconFab> 
    )
}

export const FabAdd = ({tooltip : tt, icon : Icon = AddIcon, ...props}) => {
    const {t}       = useTranslation();
    const tooltip   = React.useMemo(() => tt || t('components.fabs.add'),[tt,t])
    return (
        <IconFab aria-label={tooltip} {...props} >
            <IconTooltip title={tooltip}>
                <Icon />
            </IconTooltip>
        </IconFab> 
    )
}

export const FabPayment = ({tooltip : tt, ...props}) => {
    const {t}       = useTranslation();
    const tooltip   = React.useMemo(() => tt || t('components.fabs.payment'),[tt,t])
    return (
        <IconFab aria-label={tooltip} {...props}>
            <IconTooltip title={tooltip}>
                <PaymentIcon />
            </IconTooltip>
        </IconFab> 
    )
}

export const FabDateRange = ({tooltip : tt, ...props}) => {
    const {t}       = useTranslation();
    const tooltip   = React.useMemo(() => tt || t('components.fabs.dateRange'),[tt,t])
    return (
        <IconFab aria-label={tooltip} {...props}>
            <IconTooltip title={tooltip}>
                <DateRangeIcon />
            </IconTooltip>
        </IconFab> 
    )
}

export const FabRefresh = ({tooltip : tt, loading = false, disabled = false, ...props}) => {
    const {t}       = useTranslation();
    const tooltip   = React.useMemo(() => tt || t('components.fabs.refresh'),[tt,t])
    return (
        <IconFab disabled = {Boolean(loading || disabled)} aria-label={tooltip} {...props} >
            <IconTooltip title={tooltip}>
                <RefreshIcon loading={loading}/>
            </IconTooltip>
        </IconFab> 
    )
}

export const FabFilter = ({tooltip : tt, quantity = 0, showZero = false, ...props}) => {
    const {t}       = useTranslation();
    const tooltip   = React.useMemo(() => tt || t('components.fabs.filter'),[tt,t])
    return (
        <Badge overlap="rectangular" badgeContent={quantity} color="error" showZero={showZero} sx={{right:1.5}}>
            <IconFab aria-label={tooltip} {...props}>
                <IconTooltip title={tooltip}>
                    <FilterListIcon />
                </IconTooltip>
            </IconFab> 
        </Badge>
    )
}

export const FabSort = ({tooltip : tt, ...props}) => {
    const {t}       = useTranslation();
    const tooltip   = React.useMemo(() => tt || t('components.fabs.sort'),[tt,t])
    return (
        <IconFab aria-label={tooltip} {...props} >
            <IconTooltip title={tooltip}>
                <SortIcon />
            </IconTooltip>
        </IconFab> 
    )
}

export const FabClear = ({tooltip : tt, ...props}) => {
    const {t}       = useTranslation();
    const tooltip   = React.useMemo(() => tt || t('components.fabs.clear'),[tt,t])
    return (
        <IconFab aria-label={tooltip} {...props}>
            <IconTooltip title={tooltip}>
                <ClearAllIcon/>
            </IconTooltip>
        </IconFab> 
    )
}