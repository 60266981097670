/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
AstroVenusian Application -- Socket IO Context
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       20th November 2020

*******************************************************************************************/
import React                            from 'react';
import {clone}                          from 'lodash';
import moment                           from 'moment';
import io                               from "socket.io-client";
import { useCookies }                   from 'react-cookie';
import { useUser}                       from './UserContext';
import { useAPIHealth }                 from './APIHealthContext';
import { useIsWindowFocussed }          from '../hooks';
import config                           from '../config';

const { development : DEVELOPMENT } = config;

// The voltage context
const   SocketIOContext = React.createContext(undefined);
const   DEBUG               = false;
const   NAMESPACE_ROOT      = '/', 
        NAMESPACE_USERS     = '/users';

const   DEFAULT_STATE       = {
    [NAMESPACE_ROOT]    : undefined, 
    [NAMESPACE_USERS]   : undefined, 
};

// Voltage Provider
const SocketIOProvider = ({children}) => {

    // User state
    const {
        isAuthenticated, 
        /*isUserCurrent, */
        token
    }                                           = useUser();

    const [,setCookie,removeCookie]             = useCookies(['sockets', 'socketRoot', 'socketUser'])

    const isWindowFocussed                      = useIsWindowFocussed();

    // Is internet online
    const {isAPIHealthy,  checkAPIHealthy}      = useAPIHealth();

    // The sockets
    const [sockets,         setSockets]         = React.useState(clone(DEFAULT_STATE));

    // Keep track of when last seen
    const [lastSeen,        setLastSeen]        = React.useState(clone(DEFAULT_STATE));

    // Is ready to authenticate
    const isReadyToAuth                         = React.useMemo(() => (
        Boolean(isAPIHealthy && isAuthenticated /*&& isUserCurrent*/ )
    ), [isAuthenticated, isAPIHealthy/*, isUserCurrent*/])

    // Function to connect
    const connect = React.useCallback( ({requiresAuthentication = false, token = undefined, nsp = NAMESPACE_ROOT, forceNew = false}) => {

        // Esure namespace is valid
        if(!Object.keys(DEFAULT_STATE).includes(nsp))
            throw new Error('invalid namespace, must be one of: [' + Object.keys(DEFAULT_STATE).join(', ') + ']')
        
        // Set states for nsp 
        const setSocket = socket => setSockets( prev => ({...prev, [nsp]: socket}));  // Store socket for namespace
        const touch     = stamp  => setLastSeen(prev => ({...prev, [nsp]: stamp }));  // Set the socket timestamp     

        // Build the socket config args
        // https://stackoverflow.com/a/55157089/1834057
        // const transports = ['websocket'];
        const transports = DEVELOPMENT
            ? ['polling'] 
            : ['websocket']
        const argsCommon = {timeout: 5000, reconnectionDelayMax: 5000, forceNew,  withCredentials: true, upgrade: true, transports}

        // Establish the args depending on the token
        const args = Boolean(token)
            ? {...argsCommon, auth: cb => cb({token : `Bearer ${token}`})} 
            : {...argsCommon, auth: cb => cb({})};

        // Keep track of quantity of connection errors
        let connectionErrorCount = 0;

        // Instantiate the socket
        const socket = io(nsp, args);

        // Instruct Disconnect
        const disconnect = () => {
            if(socket.connected){
                if(DEBUG) console.log(`SocketID, NSP: '${nsp}', Socket '${socket.id}' Disconnecting`);
                socket.disconnect(); 
            }
        }

        // Handle Connect Error
        const handleConnectError = (err) => {
            if(DEBUG) console.log(`${++connectionErrorCount} connection attempts for ${nsp}, ` + err.message);
        }

        // Handle Connect
        const handleConnect = () => {
            if(DEBUG) console.log(`SocketID, NSP: '${nsp}', Socket '${socket.id}' has Connected`);
            setSocket(socket)
            
            connectionErrorCount = 0;

            // Check Healthy
            if(nsp === NAMESPACE_ROOT){
                checkAPIHealthy('SocketIOContext.handleConnect');
            }
        }

        // Handle Disconnect
        const handleDisconnect = () => {
            if(DEBUG) console.log(`SocketID, NSP: '${nsp}', Disconnected`);
            setSocket(undefined);
            if(nsp === NAMESPACE_ROOT){
                checkAPIHealthy('SocketIOContext.handleDisconnect');
            }
        }

        // Handle Unauthorized
        const handleUnauthorized = () => {
            if(requiresAuthentication){
                disconnect(); 
            }
        } // setSocket(undefined);

        // Handle Authorized
        const handleAuthorized = () => setSocket(socket);

        // Handle Heartbeat
        const handleHeartbeat = () => socket.emit('heartbeat'); // Bounce Back

        // Handle Any Event
        const handleAny = (event, ...args) => touch(moment().utc());

        // Handle Authentication Event
        const handleAuthentication = () => {
            if(DEBUG) console.log('Authentication Request')
        };

        // Main Events
        socket.on('authenticate',           handleAuthentication);      // Listen to authentication request event
        socket.on('connect',                handleConnect);             // Listen to connection event
        socket.on("connect_error",          handleConnectError);        // Listen to connect error event
        socket.on('disconnect',             handleDisconnect);          // Listen to disconnect event
        socket.on('unauthorized',           handleUnauthorized);        // Listen to unauthorized event
        socket.on('authorized',             handleAuthorized);          // Listen to authorized event
        socket.on('heartbeat',              handleHeartbeat);           // Listen to heartbeat event

        // Wildcard Event
        socket.onAny(handleAny);                                        // Listen to anything

        // Return Cleanup Function
        return () => {

            // Main Events
            socket.off('authenticate',           handleAuthentication);      // Listen to authentication request event
            socket.off('connect',                handleConnect);             // Listen to connection event
            socket.off("connect_error",          handleConnectError);        // Listen to connect error event
            socket.off('disconnect',             handleDisconnect);          // Listen to disconnect event
            socket.off('unauthorized',           handleUnauthorized);        // Listen to unauthorized event
            socket.off('authorized',             handleAuthorized);          // Listen to authorized event
            socket.off('heartbeat',              handleHeartbeat);           // Listen to heartbeat event

            // Wildcard Event
            socket.offAny(handleAny);

            // Disconnect
            disconnect();

            // Destroy
            setSocket(undefined);
            touch(undefined);
        }

    },[checkAPIHealthy]);

    // --------------------------------------------------------------------------------------------
    // Connect Root
    // --------------------------------------------------------------------------------------------
    React.useEffect(() => {
        if(!isAPIHealthy)
            return; 
        if(DEBUG) console.log("CONNECTING NAMESPACE ROOT")
        const cleanupFunction = connect({requiresAuthentication : false, nsp: NAMESPACE_ROOT});
        return () => {
            cleanupFunction();
        }
    },[connect, isAPIHealthy])
    
    // --------------------------------------------------------------------------------------------
    // Connect Users
    // --------------------------------------------------------------------------------------------
    React.useEffect(() => {
        if(!isReadyToAuth || !token)
            return;
        const cleanupFunction = connect({requiresAuthentication : true, token, nsp: NAMESPACE_USERS, attachToAxios:true});
        return () => {
            return cleanupFunction();
        }
    },[connect, isReadyToAuth, token]);

    React.useEffect(()=>{
        if(isWindowFocussed){
            let [s1,s2] = [sockets[NAMESPACE_ROOT], sockets[NAMESPACE_USERS]];
            setCookie('socketRoot', s1?.id, {path:'/'});
            setCookie('socketUser', s2?.id, {path:'/'});
            setCookie('sockets',    [s1?.id, s2?.id].filter(Boolean), {path:'/'});
            return () => {
                removeCookie('socketRoot');
                removeCookie('socketUser');
                removeCookie('sockets');
            }
        }
    },[setCookie, sockets, isWindowFocussed, removeCookie])

    // Force Check API healthy when anonymous socket connects
    /*
    React.useEffect(()=>{
        let socket = sockets[NAMESPACE_ROOT];
        if(socket){
            const handleConnect = () => {
                checkAPIHealthy();
                socket.on()
            }
            socket.on('connect', checkAPIHealthy);
            socket.on('disconnect', checkAPIHealthy);
            return () => {
                socket.off('connect', checkAPIHealthy);
                socket.off('disconnect', checkAPIHealthy);
            }
        }
    },[checkAPIHealthy, sockets, handleAuthentication]);
    */

    // Context values
    const value = {
        socketRoot      : sockets[NAMESPACE_ROOT],
        socketUsers     : sockets[NAMESPACE_USERS],
        lastSeen
    };

    return (
        <SocketIOContext.Provider value={value}>
            {children}
        </SocketIOContext.Provider>
    )
}

// Voltage Consumer
const SocketIOConsumer = ({children}) => {
    return (
        <SocketIOContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('SocketIOConsumer must be used within SocketIOProvider');
                }
                return children(context)
            }}
        </SocketIOContext.Consumer>
    )
}

// useVoltage Hook
const useSocketIO = () => {
    const context = React.useContext(SocketIOContext);
    if(context === undefined)
        throw new Error('useSocketIO must be used within SocketIOProvider');
    return context;
}

export {
    SocketIOProvider,
    SocketIOConsumer,
    useSocketIO
}
