/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Narrow Page
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       7th January 2024

*******************************************************************************************/
import React                    from 'react';
import { Box }                  from '@mui/material';
import { 
    ItemPaper,
    PageContainer,
    RootContainer
}                               from 'components';

const obj = {};

export const NarrowPage = ({children, pageContainerProps= obj, maxWidth = 900}) => {
    return (
        <RootContainer>
            <PageContainer {...pageContainerProps}>
                <Box sx={{width:'100%', maxWidth, mx:'auto', mb:4, '& > * + *' : {mt:2}}}>
                    <ItemPaper elevation={6} sx={{minHeight:'50vh', position:'relative', p:4, '& > * + *' : {mt:4}}}>
                        {children}
                    </ItemPaper>
                </Box>
            </PageContainer>
        </RootContainer>
    )
}

export default NarrowPage