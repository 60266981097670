/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Example
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       17th January 2023

*******************************************************************************************/
import React                    from 'react';
import moment                   from 'moment';
import pick                     from 'lodash/pick';
import startCase                from 'lodash/startCase';
import {titleCase}              from 'title-case';
import { useHistory}            from "react-router-dom";
import { useInView }            from 'react-intersection-observer';
import {
    alpha,
    useTheme,
    Box,
    Chip,
    Grid,
    Typography,
    Skeleton as SkeletonMUI
}                               from '@mui/material';
import AudiotrackIcon           from '@mui/icons-material/Audiotrack';
import TollIcon                 from '@mui/icons-material/Toll';
import CheckCircleOutlineIcon   from '@mui/icons-material/CheckCircleOutline';
import LoginIcon                from '@mui/icons-material/Login';
import useMediaQuery            from '@mui/material/useMediaQuery';
import { 
    JSONViewer,
    PageContainer,
    NotFound,
    Title,
    FeatureBox,
    Radix,
    NatalSummary,
    CommentsThread,
    LastQueried,
    ContainerWithFooter,
    RefreshIcon,
    Skeleton,
    SocialSharePage,
    Playlist,
    ProductCard,
    Sticky,
    Seo,
    Linkify,
    PublicBooksAdvertisement,
    ArchiveCrossSell,
    Like,
    LikeDisplay,
    FormAlert,
    NoticeBlock,
    ItemPaper,
    SkeletonTable,
    Login,
    Button,
    ContentfulRichText,
    TermsAndConditions,
    RoddenRatingDialogButton,
    RoddenRatingStamp,
    DraggableDialog,
    ConfirmationButton,
    SabianSymbolsSampler,
    CardMediaWithLoading
}                               from 'components';
import { withTranslation }      from './hoc';
import withFeatureTypography    from 'components/hoc/withFeatureTypography';
import SkeletonRadix            from 'components/skeletons/SkeletonRadix';
import { 
    ExamplesCollectionLocation,
    ExamplesLocation, 
    ShopLocation
}                               from 'router/locations/Locations';
import {
    useNetwork,
    useUser,
    CommentsThreadProvider,
    useContentful,
    useAccountCredits,
    // useAlert
}                               from 'contexts';
import {
    useSize,
    useCancelToken,
    useQueryHoroscopeData
}                               from 'hooks';     
import { 
    useQueryParam,
    withDefault, 
    BooleanParam
}                               from 'use-query-params';
import { 
    useImageCDN,
    useHoroscopeDescriptor
}                               from 'hooks';
import config                   from 'config';

const GRAYSCALE     = Boolean(config?.archive?.grayscale);
const SAMPLE_COUNT  = 7;

const SkeletonRadixLoading = withFeatureTypography(SkeletonRadix);

const stripSarasStupidAttributionChevrons = x => {
    if(!x || typeof x !== 'string') return x;
    const pattern = /<((?:https?:\/\/|www\.)[^\s<]+)>/g;
    return  x.replaceAll(pattern, '$1');
}

const SkeletonParagraph = ({lines = 5, lastWidth = "75%", ...props}) => {
    return (
        <Box>
            {  
                lines > 1 && 
                Array(lines - 1).fill(undefined).map((_,ix) => (
                    <SkeletonMUI variant="text" key={ix} {...props}/>
                ))
            }
            <SkeletonMUI variant="text" {...props} width={lastWidth}/>
        </Box>
    )
}

const SidebarContent = withTranslation( ({
    t, 
    data, 
    horoscopeData, 
    queried, 
    width, 
    working
}) => {
    const {isNetworkReady} = useNetwork();
    return (
        <>
            {
                isNetworkReady && queried &&
                <Title variant="h5" component="h3">
                    { t('common.natalData') }
                </Title>
            }
            <Box>
                {
                    (!queried || !isNetworkReady) && 
                    <Box style={{aspectRatio:'5'}}>
                        <Skeleton />
                    </Box>
                }
                {
                    queried && isNetworkReady &&
                    <NatalSummary approxAddress = {false} {...data} />
                }
            </Box>
            <Box position = "relative" width="100%">
                {
                    (!queried || working || !horoscopeData) &&
                    <SkeletonRadixLoading />
                }
                {
                    horoscopeData && 
                    <Radix 
                        id      = {'radix'}
                        width   = {width}
                        data    = {horoscopeData}
                    />
                }
                { 
                    isNetworkReady && queried && !horoscopeData && !working &&
                    <Typography align="center">
                        {t('common.noNatalData')}
                    </Typography>
                }           
            </Box>
        </>
    )
});


const toUrl = ({id,absolute=true} = {}) => {
    const {origin} = window.location;
    if(!id) return origin;
    const rel = ExamplesLocation.toUrl({slug : id})
    return absolute ? `${origin}${rel}` : rel;
}

const obj = {};

export const Example = withTranslation( ({
    t, 
    slug, 
    page                = true, 
    pageContainerProps  = obj, 
    force               = false, 
    adminMode           = false
}) => {
    const theme                                         = useTheme();
    const history                                       = useHistory();
    const {cancelToken, isCancel}                       = useCancelToken();
    const {axios, isNetworkReady}                       = useNetwork();
    const {isAdmin, isAuthenticated}                    = useUser();
    // const {alert}                                       = useAlert();
    const {
        balance,
        purchaseArchive,
        working:accountBalanceWorking,
        queried:accountBalanceQueried,
        CREDITS_NAME
    }                                                   = useAccountCredits();
    const [debug]                                       = useQueryParam('debug',        withDefault(BooleanParam, false));
    const [omitFiles]                                   = useQueryParam('omitFiles',    withDefault(BooleanParam, false));
    const [omitMeta]                                    = useQueryParam('omitMeta',     withDefault(BooleanParam, true));
    const readingRef                                    = React.useRef();
    const [commentsDetached, setCommmentsDetached]      = React.useState(false);
    const convert                                       = useImageCDN();

    const ref                                           = React.useRef();
    const {width}                                       = useSize(ref);

    const ref2                                          = React.useRef();
    const {width:width2}                                = useSize(ref2);

    const [working,         setWorking]                 = React.useState(false);
    const [workingP,        setWorkingP]                = React.useState(false);
    const [queried,         setQueried]                 = React.useState(undefined);
    const [data,            setData]                    = React.useState(undefined);

    const {
        horoscopeData, 
        working : horoscopeDataWorking,
        queried : horoscopeDataQueried
    }                                                   = useQueryHoroscopeData({...data});

    // Build Descriptor
    const {descriptor}                                  = useHoroscopeDescriptor(horoscopeData, Boolean(!!data?.unknownTime));


    // const [messageSuccess,  setMessageSuccess]          = useStateEphemeral(undefined)
    // const [messageError,    setMessageError]            = useStateEphemeral(undefined)
    const [status,          setStatus]                  = React.useState(undefined)

    const smDown                                        = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const mdUp                                          = useMediaQuery((theme) => theme.breakpoints.up('md'));

    const handleDetach = React.useCallback((value) => {
        setCommmentsDetached(value);
    },[])

    // Reset data and last queried time
    const clear             = React.useCallback(() => {
        setQueried(undefined);
        setData(undefined);
    }, []);

    // View
    React.useEffect( () => {
        if(isNetworkReady && data?.id){
            axios.post(`/api/examples/${data?.id}/view`, {cancelToken} ).catch()
        }
    },[axios, cancelToken, data?.id, isNetworkReady]);

    const {getArchive}                              = useContentful();
    const [queriedC, setQueriedC]                   = React.useState(undefined);
    const [loadingC, setLoadingC]                   = React.useState(false);
    const [dataC,    setDataC]                      = React.useState(undefined);

    React.useEffect(() => {
        if(data?.slug){
            setLoadingC(true);
            getArchive(data?.slug)
                .then(items => setDataC(items[0]))
                .finally(() => {
                    setLoadingC(false)
                    setQueriedC(moment())
                })
        }else{
            setData(undefined);
        }
    },[data?.slug, getArchive])

    // Query
    const query= React.useCallback((passive = false) => {
        if(isNetworkReady && slug){
            if(!passive) {
                clear();
                setWorking(true);
            }else{
                setWorkingP(true);
            }
            axios.get(`/api/examples/${slug}?auth=${isAuthenticated}&omitFiles=${omitFiles}&omitMeta=${omitMeta}&sampleCount=${SAMPLE_COUNT}&adminMode=${adminMode}`, {cancelToken})
                .then(({data,status}) => {
                    setData(data);
                    setStatus(status);
                })
                .catch(err => {
                    if(isCancel(err)) return;
                    setStatus(err.status);
                })
                .finally(() => {
                    setQueried(moment());
                    if(!passive)
                        setWorking(false);
                    else
                        setWorkingP(false);
                })
        }else{
            setData(undefined);
        }
    },[isNetworkReady, slug, axios, isAuthenticated, omitFiles, omitMeta, adminMode, cancelToken, clear, isCancel]);

    React.useEffect(query,[query]);

    // Extract the Data
    // Only update files when id changes, this stops current playlist from being interrupted
    const files                     = React.useMemo(() => (
        data?.files
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [data?.id, JSON.stringify(data?.files)]);

    const success                   = React.useMemo(() => (
        Boolean(data?.metadata?.result?.success || data?.success)
    ), [data?.metadata?.result?.success, data?.success]);
    
    const symbolsArgs                           = React.useMemo(() => (
        data 
            ? pick(data,['lat','lng','birthDateTime','unknownTime','localTime','houseSystem','zodiac'])
            : undefined
    ), [data])

    const [open, setOpen]           = React.useState(false);
    const handleOpen                = React.useCallback(() => setOpen(true),    [])
    const handleClose               = React.useCallback(() => setOpen(false),   [])

    const handleCloseAndRefresh     = React.useCallback(() => {
        handleClose();  // close modal
        query(true);    // passsively query
    }, [handleClose, query])

    // Purchase and refresh
    const handlePurchase = React.useCallback( () => {
        purchaseArchive(data?._id)
            .then(handleOpen)
            .catch(console.error)
    }, [data?._id, handleOpen, purchaseArchive]);

    const { ref : titleRef, inView }    = useInView({threshold:0});

    const isPurchased       = React.useMemo(() => data?.isPurchased,[data?.isPurchased]);

    // Admin Sees All
    const adminOverride     = React.useMemo(() => adminMode && isAuthenticated && isAdmin, [adminMode, isAdmin, isAuthenticated])
    
    // Free Duration
    const durationFree      = React.useMemo(() => {
        let cnt = 0;
        return (files || []).reduce((acc, {duration = 0, permission : {everyone:e, public:f, sample:s}}) => acc + ((e || f || (s && cnt++ < data?.sampleCount)) ? duration : 0), 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[JSON.stringify(files), data?.sampleCount]);
    
    // Paid Duration
    const durationPaid      = React.useMemo(() => {
        return (files || []).reduce((acc, {duration = 0, permission : {everyone:e, public:f, sample:s, purchased:p}}) => acc + ((e || f || s || p) ? duration : 0), 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[JSON.stringify(files)]);

    // Admin Override Duration
    const durationAdmin     = React.useMemo(() => {
        return (files || []).reduce((acc, {duration = 0 }) => acc + duration, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[JSON.stringify(files)]);

    // Net Duration
    const duration          = React.useMemo(() => {
        if(adminOverride) return durationAdmin;
        return isPurchased ? durationPaid : durationFree
    }, [adminOverride, durationAdmin, durationFree, durationPaid, isPurchased]);

    const hasFunds          = React.useMemo(() => balance >= data?.product?.price, [balance, data?.product?.price]);
    const analysisTitle     = React.useMemo(() => `Analysis${data?.name ? ` for ${data.name}` : ''}`, [data]);
    const viewedTimes       = React.useMemo(() => <Typography variant="body2" sx={{fontStyle:'italic',fontSize:10}}> Viewed {(data?.views || 0) + 1}x Times</Typography> , [data]);
    const photo             = React.useMemo(() => convert(data?.photo, {operation:'width', width:500, options:`grayscale:${Boolean(GRAYSCALE && !isPurchased)}`}), [convert, data?.photo, isPurchased]);
    const roddenRating      = React.useMemo(() => data?.roddenRating,[data?.roddenRating])

    const limit             = useMediaQuery(theme => theme.breakpoints.down('lg')) ? 3 : 5;
    const lgUp              = useMediaQuery(theme => theme.breakpoints.up('lg'));

    const readingEnabled    = React.useMemo(() => isNetworkReady && queried && (data?.enabled || force), [data?.enabled, force, isNetworkReady, queried])
    const readingHasFiles   = React.useMemo(() => success && files, [success, files])

    return (
        <CommentsThreadProvider>
            {
                page && 
                <Seo
                    title           = { data?.name || t('common.example') }
                    description     = { 
                        data?.name 
                            ? [ 
                                t('components.example.astrologyForName', { name : data?.name }),
                                descriptor
                            ].filter(Boolean).join(' - ')
                            : t('components.example.astrologyForPeopleOfInterest') 
                        }
                    keywords        = { 
                        [ t('components.example.birthChartAnalysis'), t('components.example.celebrityHoroscopes') , data?.name, ...(data?.tags || [])]
                            .filter(Boolean)
                            .map(x => startCase(x))
                            .join(", ")
                    }
                    canonical       = { toUrl({id : data?.slug || data?.id, absolute: true}) }
                    image           = { photo }
                />
            }
            <PageContainer loading={working} breadcrumbs={Boolean(page)} scrollToTop={Boolean(page)} id="pageContainer" {...pageContainerProps}>
                
                {
                    status && status >= 400 &&
                    <Box display="flex" flexGrow={1} flexDirection="column">
                        <Box flexGrow={1}/>
                        <Box>
                            <NotFound/>
                        </Box>
                        <Box flexGrow={1}/>
                    </Box>
                }

                {   (!status || status < 400) &&
                    <ContainerWithFooter
                        renderHeader = {()=>{
                            return null;
                            /*
                            return (
                                <PageContainerHeader 
                                    title                   = { data?.name || "Example"}
                                    messageSuccess          = { messageSuccess } 
                                    messageError            = { messageError } 
                                    alertOverwriteTitle     = { false }
                                    render                  = { () => (
                                        <React.Fragment>
                                        </React.Fragment>
                                    )}
                                />
                            ) 
                            */
                        }}
                        renderFooter = {()=>{
                            return (
                                <LastQueried quantity={data ? 1 : 0} timestamp={queried}/>
                            )
                        }}
                    >
                        <ItemPaper sx={{minHeight:'100%'}}>
                            {
                                isNetworkReady && isAdmin && queried && !data?.active && 
                                <Box mb={1}>
                                    <FormAlert severity="error">
                                        { t('components.example.adminError404Note') }
                                    </FormAlert>
                                </Box>
                            }

                            <Grid container>
                                {
                                    !smDown &&
                                    <Grid item xs={12} sm={4} md={3}>
                                        <Sticky sx={{'& > * + *' : {mt:2}, pt:0.5}} style={{top:theme.spacing(2)}}>

                                            <FeatureBox sx={{borderRadius:2,p:0,position:'relative'}}>

                                                {
                                                    queried && !inView &&
                                                    <Box 
                                                        sx={{
                                                            position    : 'absolute',
                                                            top         : 0,
                                                            left        : 0,
                                                            right       : 0,
                                                            bgcolor     : theme => alpha(theme.palette.background.paper,0.5),
                                                            p           : 1
                                                        }}
                                                    >
                                                        <Title component="h1" align="center">
                                                            {data?.name || t('common.ecample') }
                                                        </Title>
                                                    </Box>
                                                }

                                                {
                                                    (!queried || !photo) && 
                                                    <Box style={{aspectRatio:'1'}}>
                                                        <Skeleton />
                                                    </Box>
                                                }
                                                {
                                                    (queried && photo) &&
                                                    <Box>
                                                        <CardMediaWithLoading
                                                            component           = "img"
                                                            src                 = {photo}
                                                            alt                 = {data?.name || ""} 
                                                            loading             = "lazy"
                                                            skeletonAspectRatio = "1" 
                                                            sx                  = {{ display : 'flex' }}
                                                        />
                                                    </Box>
                                                }
                                                {
                                                    (queried && photo && data?.likeGroup) &&
                                                    <LikeDisplay 
                                                        likes           = {data?.likeGroup?.likeCount} 
                                                        dislikes        = {data?.likeGroup?.dislikeCount} 
                                                    />
                                                }
                                            </FeatureBox>

                                            

                                            {   
                                                (queried && data?.photoAttribution) && 
                                                <Box mt={0} ml={1}>
                                                    <Linkify>
                                                        <Typography sx={{fontSize:10,textAlign:'left'}}>
                                                            {t('components.example.attribution', { photoAttribution : stripSarasStupidAttributionChevrons(data?.photoAttribution) })}
                                                        </Typography>
                                                    </Linkify>
                                                </Box>
                                            }

                                           

                                            {   
                                                false && mdUp && data?.product && data?.enabled &&
                                                <Box mt={2}>
                                                    <Title variant="h5" component="h3">
                                                        { t('components.example.productUsed') }
                                                    </Title>
                                                    <FeatureBox sx={{borderRadius:2}}>
                                                        <Box sx={{m:-1}}>
                                                            <ProductCard showDescription={false} productId={data?.product} />
                                                        </Box>
                                                    </FeatureBox>
                                                </Box>
                                            }
                                        </Sticky>

                                    </Grid>
                                }

                                <Grid item xs={12} sm={8} md={5} lg={6}>
                                    {
                                        isNetworkReady && queried && smDown && photo &&
                                        <Box sx={{overflow:'hidden', mr:1, mt:1, float:'left', position:'relative'}}>
                                            <Box sx={{position:'relative'}}>
                                                <CardMediaWithLoading
                                                    component           = "img"
                                                    src                 = {photo}
                                                    alt                 = {data?.name}
                                                    loading             = "lazy"
                                                    skeletonAspectRatio = "1"
                                                    style               = {{maxWidth:150}}
                                                />
                                                {
                                                    data?.likeGroup && 
                                                    <LikeDisplay 
                                                        likes           = {data?.likeGroup?.likeCount} 
                                                        dislikes        = {data?.likeGroup?.dislikeCount} 
                                                    />
                                                }
                                            </Box>
                                        </Box>
                                    }

                                    {   
                                        isNetworkReady && queried && 
                                        <Box display="flex">
                                            <Box flexGrow={1}>
                                                <Title component="h1" gutterBottom>
                                                    {data?.name || t('common.example')}
                                                </Title>
                                            </Box>
                                            {
                                                readingEnabled && 
                                                <Box>
                                                    <Button startIcon={<AudiotrackIcon />} size="small" variant="contained" color="info" onClick={() => readingRef.current?.scrollIntoView({behavior:'smooth'}) } >
                                                        Listen
                                                    </Button>
                                                </Box>
                                            }
                                        </Box>
                                    }

                                    <Box ref={titleRef}/>

                                    
                                    
                                    {
                                        isNetworkReady && queried && 
                                        <>
                                            <Box display="flex">
                                                <Box flexGrow={1}>
                                                    <Title variant="h5" component="h3">
                                                        { t('common.biography') }
                                                    </Title>
                                                </Box>
                                                {
                                                    mdUp &&
                                                    <Box sx={{mt:'auto',ml:0.5}}>
                                                        { viewedTimes }
                                                    </Box>
                                                }
                                            </Box>
                                            {
                                                !mdUp &&
                                                <Box>
                                                    {viewedTimes}
                                                </Box>
                                            }
                                        </>
                                    }

                                    { 
                                        isNetworkReady && queried && 
                                        <Box>
                                            <Linkify disabled={false} sanitize={false}>
                                                {
                                                    (data?.biography || '')
                                                        .split('\n')
                                                        .filter(Boolean)
                                                        .map((paragraph,ix) => (    
                                                            <Typography key={ix} paragraph sx={{textAlign:'justify'}}>
                                                                {paragraph}
                                                            </Typography>
                                                        ))
                                                }
                                            </Linkify>
                                        </Box>
                                    }

                                    {
                                        isNetworkReady && !loadingC && queriedC && 
                                        <ContentfulRichText 
                                            content={dataC?.fields?.description} 
                                        />
                                    }

                                    {
                                        (!isNetworkReady || !queried) && 
                                        <Box sx={{'& > * + *' : {mt:2}}}>
                                            <SkeletonParagraph lines={3}    lastWidth="40%"/>
                                            <SkeletonParagraph lines={10}   lastWidth="75%"/>
                                            <SkeletonParagraph lines={5}    lastWidth="50%"/>
                                        </Box>
                                    }

                                    {
                                        isNetworkReady && queried && smDown && data?.photo && data?.photoAttribution && 
                                        <Box>
                                            <Linkify>
                                                <Typography>
                                                    {t('components.example.photoAttribution', { photoAttribution : stripSarasStupidAttributionChevrons(data?.photoAttribution) })}
                                                </Typography>
                                            </Linkify>
                                        </Box>
                                    }

                                    


                                    {
                                        isNetworkReady && queried && descriptor &&
                                        <Box mt={2}>
                                            <Box display="flex">
                                                <Title variant="h5" component="h3">
                                                    {t('common.summary')}
                                                </Title>
                                            </Box>
                                            <Typography component="div">
                                                {data?.name}{data?.name ? ` has` : ''} {descriptor}
                                            </Typography>
                                        </Box>
                                    }

                                    {   
                                        isNetworkReady && queried && roddenRating &&
                                        <Box mt={2} sx={{"& > * + *" : {mt:2}}}>
                                            <Box display="flex">
                                                <Box flexGrow={1}>
                                                    <Box display="flex">
                                                        <Title variant="h5" component="h3">
                                                            {t('common.roddenRating')}
                                                        </Title>
                                                        <Box ml={1}>
                                                            <RoddenRatingDialogButton />
                                                        </Box>
                                                    </Box>
                                                    <Typography component="div">
                                                        {t('components.example.accuracyRoddenRating', { roddenRating })}
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <RoddenRatingStamp code={roddenRating}/>
                                                </Box>
                                            </Box>

                                            {
                                                /x/i.test(roddenRating) &&
                                                <FormAlert severity="error">
                                                    {
                                                        data?.name 
                                                            ? t('components.example.roddenRatingWarningXName',{name : data?.name}) 
                                                            : t('components.example.roddenRatingWarningXNoName')
                                                    }
                                                </FormAlert>
                                            }

                                            {
                                                /dd/i.test(roddenRating) &&
                                                <FormAlert severity="warning">
                                                    {t('components.example.roddenRatingWarningDD', {roddenRating})}
                                                </FormAlert>
                                            }
                                            
                                        </Box>
                                    }

                                    {
                                        isNetworkReady && queried && 
                                        <Box mt={2}>
                                            <Box display={"flex"} width={'100%'}>
                                                <Box flexGrow={1}>
                                                    {
                                                        (data?.tags || []).map((tag,ix) => (
                                                            <Chip 
                                                                key         = {ix}
                                                                label       = {titleCase(tag)} 
                                                                sx          = {{m:0.15}} 
                                                                color       = "primary"
                                                                onClick     = {() => {
                                                                    history.push(`${ExamplesCollectionLocation.path}?tags=${tag}`)
                                                                }}
                                                            />
                                                        ))
                                                    }
                                                    {
                                                        data?.gender && 
                                                        <Chip 
                                                            label       = {titleCase(data.gender)} 
                                                            sx          = {{m:0.15}} 
                                                            color       = "default"
                                                            onClick     = {() => {
                                                                history.push(`${ExamplesCollectionLocation.path}?gender=${data.gender}`)
                                                            }}
                                                        />
                                                    }
                                                </Box>
                                                <Box ml={2}>
                                                    <Like reference={data?.id} size="small" onClick={() => query(true)}/>
                                                </Box>
                                                {
                                                    lgUp &&
                                                    <Box ml={2}> 
                                                        <SocialSharePage/>
                                                    </Box>
                                                }
                                            </Box>
                                            {
                                                !lgUp &&
                                                <Box align="right">
                                                    <SocialSharePage/>
                                                </Box>
                                            }
                                        </Box>
                                    }

                                    <Box>
                                        <Box ref = {ref2} width="100%">
                                            {
                                                !mdUp &&
                                                <Box mt={2}>
                                                    <SidebarContent 
                                                        data            = {data} 
                                                        horoscopeData   = {horoscopeData} 
                                                        width           = {width2}
                                                        queried         = {queried && horoscopeDataQueried} 
                                                        working         = {horoscopeDataWorking}
                                                    />
                                                </Box>
                                            }
                                        </Box>
                                    </Box>

                                    <Box ref={readingRef} />

                                    <React.Fragment>
                                        {
                                            readingEnabled && 
                                            <React.Fragment>
                                                {
                                                    isAuthenticated && readingHasFiles &&
                                                    <Box mt={2} sx={{position:'relative'}}>
                                                        <Box display="flex">
                                                            <Box flexGrow={1}>
                                                                <Title variant="h5" component="h3">
                                                                    {analysisTitle}
                                                                </Title>
                                                                <TermsAndConditions title={null} showCheckbox={false} action={t('components.example.listeningToThisAnalysis')}/>
                                                            </Box>
                                                            {
                                                                duration > 0 &&
                                                                <Box sx={{my:'auto',ml:2,mr:1}}>
                                                                    {moment.duration(duration,'seconds').format(undefined)}
                                                                </Box>
                                                            }
                                                        </Box>

                                                        {
                                                            isAdmin && force && !data.enabled &&
                                                            <Box mb={0.5}>
                                                                <FormAlert severity="error">
                                                                    {t('components.example.playlistShownAdministration' )}
                                                                </FormAlert>
                                                            </Box>
                                                        }

                                                        {
                                                            !adminMode && !isPurchased && accountBalanceQueried && 
                                                            <Box my={1}>
                                                                {
                                                                    hasFunds &&
                                                                    <FormAlert 
                                                                        severity    = "info" 
                                                                        action      = {
                                                                            <Box sx={{my:'auto'}}>
                                                                                <ConfirmationButton 
                                                                                    onClick             = {handlePurchase}
                                                                                    disabled            = {accountBalanceWorking || open} 
                                                                                    startIcon           = {<TollIcon />} 
                                                                                    color               = "info" 
                                                                                    size                = "small" 
                                                                                    variant             = "contained" 
                                                                                    sx                  = {{whiteSpace:'nowrap',minWidth:125}} 
                                                                                    confirmationText    = {t('common.confirm')}
                                                                                >
                                                                                    {t('components.example.useCC', {price : data?.product?.price})}
                                                                                </ConfirmationButton>
                                                                            </Box>
                                                                        }
                                                                    >
                                                                        {
                                                                            t('components.example.previewOpenedAdditionalContent', {
                                                                                duration    : moment.duration((durationPaid - durationFree),'seconds').format('h [hours] m [minutes]') ,
                                                                                price       : data?.product?.price,
                                                                                balance     : balance,
                                                                                creditsName : CREDITS_NAME
                                                                            })
                                                                        }
                                                                    </FormAlert>
                                                                }
                                                                <DraggableDialog
                                                                    open                = {open}
                                                                    title               = {t('common.success')}
                                                                    onClose             = {handleCloseAndRefresh}
                                                                    onOk                = {handleCloseAndRefresh}
                                                                    showButtonsCancel   = {false}
                                                                    maxWidth            = "xs"
                                                                    fullWidth           = {true}
                                                                >
                                                                    <Box display="flex">
                                                                        <Box mr={2}>
                                                                            <CheckCircleOutlineIcon color="success" sx={{fontSize:100}}/>
                                                                        </Box>
                                                                        <Box sx={{my:'auto'}}>
                                                                            <Typography variant="h5" gutterBottom>
                                                                                {t('common.congratulations')}
                                                                            </Typography>
                                                                            <Typography>
                                                                                {t('components.example.openedAdditionalContent',{duration : moment.duration((durationPaid - durationFree),'seconds').format('h [hours] m [minutes]') })}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Box>
                                                                </DraggableDialog>
                                                                {
                                                                    !hasFunds &&
                                                                    <FormAlert 
                                                                        severity    = "warning" 
                                                                        action      = {
                                                                            <Box sx={{my:'auto'}}>
                                                                                <Button disabled={accountBalanceWorking} startIcon={<TollIcon />} color="warning" size="small" variant="contained" sx={{whiteSpace:'nowrap'}} onClick={() => history.push(ShopLocation.path)} >
                                                                                    {t('components.example.buyCredits')}
                                                                                </Button>
                                                                            </Box>
                                                                        }
                                                                    >
                                                                        {t('components.example.previewInsufficientCredits')}
                                                                    </FormAlert>  
                                                                }
                                                            </Box>
                                                        }
                                                        
                                                        <Playlist 
                                                            identifyer          = {data?.id} 
                                                            data                = {files} 
                                                            loading             = {working}
                                                            autoscroll          = {true}
                                                            allowComments       = {true}
                                                            // force               = {!isPurchased}
                                                            crossSellQuantity   = {limit}
                                                            sticky              = {true /* page */}
                                                        />
                                                    </Box>
                                                }

                                                {
                                                    isAuthenticated && !readingHasFiles && 
                                                    <NoticeBlock title={analysisTitle} mt={2}>
                                                        <Typography paragraph>
                                                            {t('components.example.errorRetrievingAnalysis')}
                                                        </Typography>
                                                        <Button 
                                                            disabled    = {working || workingP} 
                                                            startIcon   = {<RefreshIcon loading={working || workingP}/>} 
                                                            onClick     = {() => {
                                                                setData(prev => ({...prev, files : undefined})); // kill files
                                                                query(true)
                                                            }}
                                                        >
                                                            {t('common.refreshData')}
                                                        </Button>
                                                    </NoticeBlock>
                                                }

                                                {
                                                    !isAuthenticated &&
                                                    <NoticeBlock title={analysisTitle} mt={2}>
                                                        <Login variant="contained" startIcon={<LoginIcon/>} size="large" popup={true}>
                                                            {t('common.loginToView')}
                                                        </Login>
                                                    </NoticeBlock>
                                                }
                                            </React.Fragment>
                                        }

                                        {
                                            isNetworkReady && queried && !data?.enabled && !force &&
                                            <NoticeBlock title={analysisTitle} mt={2}>
                                                {t('components.example.analysisComingSoon')}
                                            </NoticeBlock>
                                        }

                                        { 
                                            false && 
                                            <JSONViewer src={{queried,success,data,files}} /> 
                                        }

                                        {
                                            !queried &&
                                            <Box mt={2} sx={{position:'relative'}}>
                                                <SkeletonTable/>
                                            </Box>
                                        }

                                    </React.Fragment>

                                    <React.Fragment>
                                        {
                                            isNetworkReady && queried && data?.id &&
                                            <Box mt={2}>
                                                {
                                                    false &&
                                                    <Box sx={{textAlign:'right'}}>
                                                        {
                                                            commentsDetached 
                                                                ? t('common.detached')
                                                                : t('common.attached')
                                                        }
                                                    </Box>
                                                }
                                                {
                                                    data?.comments
                                                        ? (
                                                            <CommentsThread 
                                                                reference       = {data?.id} 
                                                                privateThread   = {false}
                                                                onDetach        = {handleDetach}
                                                            />
                                                        )
                                                        : (
                                                            <NoticeBlock title={t('common.comments')} mt={2}>
                                                                {t('components.example.commentsAreDisabled')}
                                                            </NoticeBlock>
                                                        )
                                                }
                                            </Box>
                                        }
                                    </React.Fragment>


                                    <React.Fragment>
                                        {
                                            queried && 
                                            <SabianSymbolsSampler data = { symbolsArgs } />
                                        }
                                    </React.Fragment>

                                    {
                                        isNetworkReady && queried && /*!data?.enabled && */
                                        <Box mt={2}>
                                            <PublicBooksAdvertisement quantity={limit}/>
                                        </Box>
                                    }

                                    {
                                        isNetworkReady && queried && data?.id &&
                                        <Box mt={2}>
                                            <ArchiveCrossSell id={data?.id} quantity={limit} />
                                        </Box>
                                    }

                                    {
                                        //{files,success} = data.metadata.result;
                                        isAdmin && debug && queried &&
                                        <Box mt={2}>
                                            <Title variant="h5" component="h3">
                                                {t('common.rawData')}
                                            </Title>
                                            <JSONViewer src={data}/>
                                        </Box>
                                    }


                                    {
                                        //{files,success} = data.metadata.result;
                                        isAdmin && debug && queried &&
                                        <Box mt={2}>
                                            <Title variant="h5" component="h3">
                                                {t('components.example.horoscopeData')}
                                            </Title>
                                            <JSONViewer src={horoscopeData}/>
                                        </Box>
                                    }

                                </Grid>


                                <Grid item xs={12} md={4} lg={3} ref = {ref}>
                                    {
                                        mdUp &&
                                        <Sticky style={{top:theme.spacing(2)}}>
                                            <SidebarContent 
                                                data            = {data} 
                                                horoscopeData   = {horoscopeData} 
                                                width           = {width}
                                                queried         = {queried && horoscopeDataQueried} 
                                                working         = {horoscopeDataWorking}
                                            />
                                        </Sticky>
                                    }
                                </Grid>

                            </Grid>
                        </ItemPaper>
                    </ContainerWithFooter>
                }
            </PageContainer>
        </CommentsThreadProvider>
    );
});

export default Example;