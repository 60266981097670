/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Order Progress Summary
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       9th August 2021

*******************************************************************************************/
import React                            from 'react';
import { 
    lighten, Stepper, Step, 
    StepLabel, Typography,
    useMediaQuery
}                                       from '@mui/material';
import { 
    RootContainer,
    Title,
    // ObjectId,
    ItemPaper
}                                       from 'components';
import { withTranslation }              from 'components/hoc';
import { useOrderViewer }               from 'contexts';

export const OrderProgressSummary = withTranslation( ({t}) => {

    const {
        data : order, 
        orderId
    }                        = useOrderViewer();

    // Number of Deliveries
    const deliveriesQuantity = React.useMemo(() => (
        order?.deliveries?.length || 0
    ), [order?.deliveries?.length]);

    // Should Process (ie has received full payment at some stage)
    const shouldProcess = React.useMemo(() => (
        Boolean(order?.shouldProcess)
    ), [order?.shouldProcess]);

    // Media Query, smDown
    const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'))

    // No Order
    if(!order || !orderId)
        return null;
    
    // Steps Aggregation
    let stepsMap = [
        {   
            step                : "CREATED",            
            relevant            : true, 
            completed           : deliveriesQuantity,
            relevantQuantity    : deliveriesQuantity,      
            current             : 0
        },
        {
            step                : "PAID", 
            relevant            : true,
            completed           : shouldProcess ? deliveriesQuantity : 0,
            relevantQuantity    : deliveriesQuantity,
            current             : shouldProcess ? 0 : deliveriesQuantity
        },
        {
            step                : "USER_INPUT",
            relevant            : false,
            completed           : 0,                          
            relevantQuantity    : 0,                       
            current             : 0
        },
        {
            step                : "USER_INPUT_PENDING",
            relevant            : false, 
            completed           : 0,                          
            relevantQuantity    : 0,                       
            current             : 0
        },
        {
            step                : "PROCESSING",   
            relevant            : false,         
            completed           : 0,                          
            relevantQuantity    : 0,                       
            current             : 0
        },
        {
            step                : "DISPATCHED",
            relevant            : false,          
            completed           : 0,                          
            relevantQuantity    : 0,                       
            current             : 0
        },
        {
            step                : "OTHER", 
            relevant            : false,                           
            completed           : 0,                        
            relevantQuantity    : 0,                       
            current             : 0
        },
        {   
            step                : "COMPLETE_PENDING",   
            relevant            : false,     
            completed           : 0,                        
            relevantQuantity    : 0,                       
            current             : 0
        },
        {
            step                : "COMPLETE",   
            relevant            : false,                   
            completed           : 0,                          
            relevantQuantity    : 0,                       
            current             : 0
        },
        {
            step                : "CANCELLED",         
            relevant            : false, 
            completed           : 0,                          
            relevantQuantity    : 0,                       
            current             : 0
        }
    ];

    // step names
    const steps = stepsMap.map(x => x.step);
    
    // Process
    (order?.deliveries || []).forEach( delivery => {
        const {
            status,
            statusOptions
        }               = delivery;
        const ixO       = steps.indexOf("OTHER");
        const ixS       = steps.indexOf(status);

        // Increment value
        const inc       = shouldProcess ? 1 : 0; 

        // Update Current Helper
        const updateCurrent = (ix) => {
            if(ix >= 0 && ix < stepsMap.length) 
                stepsMap[ix] = {...stepsMap[ix], current : stepsMap[ix].current + inc}; 
        }

        // Update Current Value
        updateCurrent(ixS >= 0 ? ixS : ixO)

        // Update Other Values Helper
        const updateOther = (ix,override) => {
            if(ix >= 0 && ix < stepsMap.length){
                const completed = (ix < ixS || override) ? 1 : 0;
                stepsMap[ix] = {
                    ...stepsMap[ix],  
                    completed           : stepsMap[ix].completed + completed, 
                    relevant            : true, 
                    relevantQuantity    : stepsMap[ix].relevantQuantity + 1 
                }
            } 
        }

        // Update Relevant Values
        statusOptions.forEach(sm => {
            const ixT               = steps.indexOf(sm); // Index of the status option
            const completeOverride  = (sm === status && status === "COMPLETE");
            // Update Other Values
            updateOther(
                ixT >= 0 ? ixT : ixO,
                completeOverride
            )
        })
    })
    
    // Filter non relevant entries and not cancelled
    stepsMap = stepsMap.filter(x => x.relevant && x.step !== "CANCELLED")

    // Work out which is complete or not
    for(let i = 1; i < stepsMap.length; i++){
        if(stepsMap[i].current === 0 && stepsMap[i-1].complete)
            stepsMap[i].complete = true;
    }

    // Determine the active step
    const activeStep = (
        stepsMap
            .map(x => x.completed === x.relevantQuantity)
            .indexOf(false)
    );

    // Render
    return (
        <RootContainer component={ItemPaper} id="orderProgressSummary">
            <Title variant="h5" gutterBottom>
                { t("components.order.orderProgressSummary.deliveryStatusSummary") }
            </Title>
            {
                order?.isCancelled &&
                <Typography color="error">
                    { t("components.order.orderProgressSummary.orderCancelled") }
                </Typography>
            }
            {
                order?.isExpired &&
                <Typography color="error">
                    { t("components.order.orderProgressSummary.orderExpired") }
                </Typography>
            }
            {
                !order.isCancelled && !order.isExpired &&
                <Stepper 
                    activeStep          = {activeStep} 
                    alternativeLabel    = {!smDown} 
                    orientation         = {smDown ? "vertical" : "horizontal"}
                    sx                  = {{
                        ...(smDown && {
                            mx      : "auto",
                            width   : "fit-content"
                        })
                    }}
                >
                    {
                        stepsMap.map(({ step, completed, current, relevantQuantity}, ix) => {
                            const stepProps         = {};
                            const labelProps        = {};
                            const complete          = completed === relevantQuantity && relevantQuantity > 0;
                            const partial           = current > 0 && current < relevantQuantity;
                            const isActive          = ix === activeStep;
                            const label             = t( `components.order.orderProgressSummary.stepsMap.${step}.${ix >= activeStep ? 'before' : 'after'}`)
                            return (
                                <Step key={ix} {...stepProps} completed={complete}>
                                    <StepLabel {...labelProps} 
                                        sx = {{
                                            ...(partial && {
                                                '.MuiStepIcon-root' : {
                                                    color : theme => lighten(theme.palette.primary.light, 0.5)
                                                }
                                            }),
                                            ...(isActive && {
                                                '.Mui-active' : {
                                                    fontWeight : 400
                                                }
                                            })
                                        }}
                                    >
                                        {label}
                                        {
                                            deliveriesQuantity > 1 && current > 0 && 
                                            <Typography 
                                                component   = "div" 
                                                variant     = "body2" 
                                                color       = "textSecondary" 
                                                sx          = {{
                                                    ...(isActive && {
                                                        fontWeight : 400
                                                    })
                                                }}
                                            >
                                                {current} / {relevantQuantity}
                                            </Typography>
                                        }
                                    </StepLabel>
                                </Step>
                            );
                        })}
                </Stepper>
            }
        </RootContainer>
    )
});

export default OrderProgressSummary;