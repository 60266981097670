/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Gravatar
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       4th December 2021

*******************************************************************************************/
import React                            from 'react';
import { 
    useTheme,
    lighten,
    Avatar 
}                                       from '@mui/material';
import { withTranslation }              from './hoc';

const DEFAULT_SIZE = 40;

export const Gravatar = withTranslation( ({
    t,
    emailMD5, 
    size : sizeIn   = DEFAULT_SIZE, 
    d               = 'mp',
    ...props
}) => {
    const {style,alt,...rest}   = props;
    const theme                 = useTheme();
    const size                  = (isNaN(sizeIn) || sizeIn < 1 || sizeIn > 2048) ? DEFAULT_SIZE : parseInt(sizeIn);
    return (
        <Avatar 
            component   = "div"
            alt         = {alt || t('components.gravatar.userGravatar')} 
            src         = {`https://www.gravatar.com/avatar/${emailMD5}?size=${size}&d=${d}`} 
            style       = {{
                background  : lighten(theme.palette.background.paper,0.1),
                border      : `1px solid ${theme.palette.divider}`,
                ...style,
                width       : size,
                height      : size,    
            }}
            {...rest}
        />
    )
});

export default Gravatar;