/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
useMoment Hook
********************************************************************************************
Keeps a current timestamp

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       13th June 2021

*******************************************************************************************/

import React                        from 'react';
import moment                       from 'moment';

export const useMoment = ({period = 1000} = {}) => {
    if(isNaN(period) || period <= 0)
        throw new Error('period must be positive numeric');

    const [now, setNow] = React.useState(moment());

    // Keep track of time
    React.useEffect(() => {
        let interval = setInterval(() => {
            setNow(moment());
        },period);
        return () => {
            clearInterval(interval);
        }
    },[period]);

    return now;
}

export default useMoment