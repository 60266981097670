/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
SelectHouseSystemForm
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       25th September 2022

*******************************************************************************************/
import React                            from 'react';
import { Form }                         from 'components';
import { useTranslation }               from 'contexts';
import { 
    showErrorOnChange   as showError,
}                                       from 'mui-rff';
import {
    HouseSystemField, 
    HOUSE_SYSTEM_DATA
}                                       from './fields/HouseSystemField';

const noop = () => {}
const obj = {};

const defaultFormData = {
    houseSystem : undefined
}

export const SelectHouseSystemForm = ({
    formData                    = defaultFormData,
    disabled                    = false,
    onSubmit : handleSubmit     = noop,
    onCancel : handleCancel     = noop,
    FormProps                   = obj
}) => {
    const {t}       = useTranslation();
    const validate  = React.useCallback( (values) => {
        let errors          = {};
        if(!values.houseSystem)
            errors.houseSystem = errors.houseSystem || t('components.forms.selectHouseSystemForm.required');
        if(!HOUSE_SYSTEM_DATA.map(x => x.value).includes(values.houseSystem))
            errors.houseSystem = errors.houseSystem || t('components.forms.selectHouseSystemForm.invalidSelection');
        return errors;
    }, [t]);

    // Initial Form Data
    const initialValues = React.useMemo(() => ({
        ...formData
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [JSON.stringify(formData)])

    return (
        <Form
            debug                   = {false}
            disabled                = {disabled}
            onSubmit                = {handleSubmit}
            onCancel                = {handleCancel}
            initialValues           = {initialValues}
            validate                = {validate}
            showObjectId            = {false}
            changeRequiredToSubmit  = {true}
            {...FormProps}
            render                  = {({disabled, form, error, dirtySinceLastSubmit, submitFailed, submitSucceeded, errors, handleSubmit, values, ...rest}) => {
                return (
                    <form onSubmit={handleSubmit} noValidate>
                        <HouseSystemField 
                            name        = "houseSystem" 
                            disable     = {disabled} 
                            showError   = {showError} 
                        />
                    </form>
                )
            }}
        />
    )
}

export default SelectHouseSystemForm;