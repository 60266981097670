/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Use network hook
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       7th March 2021

********************************************************************************************/
import React from 'react';

export const useInternet = () => {

    // Online State
    const [isOnline, setOnline] = React.useState(Boolean(window.navigator.onLine));
    
    // Listen upon render, remove when destroyed
    React.useEffect(() => {

        const updateNetwork = () => setOnline(Boolean(window.navigator.onLine));

        // The events
        const events = ['offline','online'];

        const addEventListener      = event => window.addEventListener(     event, updateNetwork, {passive: true});
        const removeEventListener   = event => window.removeEventListener(  event, updateNetwork)

        // Add listeners
        events.forEach(addEventListener);
    
        // Cleanup, Remove Listeners
        return () => {
            events.forEach(removeEventListener);
        }
    },[]);

    return {
        isOnline
    }
};

export default useInternet;