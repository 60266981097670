/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
CardMediaWithLoading 
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       3rd October 2022

*******************************************************************************************/
import React                                from 'react';
import { 
    styled,
    Box, 
    CardMedia
}                                           from '@mui/material';
import { Skeleton as SkeletonBase }         from 'components';
import withFeatureLoading                   from 'components/hoc/withFeatureLoading';
import { withTranslation }                  from './hoc';

const Skeleton = withFeatureLoading(SkeletonBase);

const SkeletonContainer = styled(Box,{
    shouldForwardProp : prop => prop !== 'aspectRatio' && prop !== 'minHeight'
})(({aspectRatio = '1', minHeight = 100}) => ({
    position        : 'relative',
    width           : '100%',
    /*
    paddingTop      : 2,
    paddingLeft     : 1,
    paddingRight    : 1,
    paddingBottom   : 0,
    */
    minHeight       : minHeight,
    aspectRatio     : aspectRatio
}))

const obj = {};

export const CardMediaWithLoading = withTranslation(({
    t,
    src, 
    alt                                 = undefined,
    skeletonAspectRatio                 = '1/1.591', 
    skeletonShowLoading                 = true, 
    skeletonMinHeight                   = 100, 
    skeletonContainerProps              = obj, 
    loadingComponent : LoadingComponent = undefined, 
    ...props
}) => {

    const [loading, setLoading] = React.useState(true);
    const [image,   setImage]   = React.useState(undefined);

    const handleLoaded          = React.useCallback(() => {
        setTimeout(() => setLoading(false), 500)
    },[]);
    const handleLoading         = React.useCallback(() => setLoading(true),[]);

    React.useEffect(() => {
        handleLoading();
        if(src){
            const image         = new Image();
            image.crossOrigin   = "Anonymous";
            image.onload        = handleLoaded;
            image.src           = src;
            setImage(src);
        }
    }, [src, handleLoading, handleLoaded]);
  
    return (
        <>
            {
                loading && !LoadingComponent && 
                <SkeletonContainer id="cardMedia" aspectRatio={skeletonAspectRatio} minHeight={skeletonMinHeight} {...skeletonContainerProps}>
                    <Skeleton loading={skeletonShowLoading} height="100%" width="100%"/>
                </SkeletonContainer>
            }
            {
                loading && LoadingComponent &&
                <LoadingComponent />
            }
            {
                !loading &&
                <CardMedia 
                    alt     = {alt || t('common.image')}
                    {...props} 
                    image   = {image}
                />
            }
        </>
    )
});

export default CardMediaWithLoading;