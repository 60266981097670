/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Icon Buttons
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       18th June 2021

*******************************************************************************************/
import React                        from 'react';

import Tooltip                      from '@mui/material/Tooltip';
import EditIcon                     from '@mui/icons-material/Edit';
import DeleteIcon                   from '@mui/icons-material/Delete';
import SearchIcon                   from '@mui/icons-material/Search';
import SettingsIcon                 from '@mui/icons-material/Settings';
import CancelIcon                   from '@mui/icons-material/Cancel';
import ReadIcon                     from '@mui/icons-material/Visibility';
import GetAppIcon                   from '@mui/icons-material/GetApp';
import PlayCircleFilledIcon         from '@mui/icons-material/PlayCircleFilled';
import CopyIcon                     from '@mui/icons-material/FileCopy';
import UpdateIcon                   from '@mui/icons-material/Update';
import StorefrontIcon               from '@mui/icons-material/Storefront';

import IconButton                   from 'components/IconButton';
import RefreshIcon                  from 'components/RefreshIcon';
import { useTranslation }           from 'contexts/TranslationContext';

export default IconButton;

const obj = {};

export const EditIconButton     = React.forwardRef(({Icon=EditIcon, IconProps = obj, TooltipProps = obj, ...props}, ref) => {
    const [t] = useTranslation();
    return (
        <IconButton ref={ref} {...props} >
            <Tooltip title={t('common.edit')} {...TooltipProps}>
                <Icon {...IconProps}/>
            </Tooltip>
        </IconButton>
    );
});

export const ReadIconButton     = React.forwardRef(({Icon=ReadIcon, IconProps = obj, TooltipProps = obj, ...props}, ref) => {
    const [t] = useTranslation();
    return (
        <IconButton ref={ref} {...props} >
            <Tooltip title={t('common.read')} {...TooltipProps}>
                <Icon {...IconProps}/>
            </Tooltip>
        </IconButton>
    );
});

export const DeleteIconButton   = React.forwardRef(({Icon=DeleteIcon, IconProps = obj,TooltipProps = obj, ...props}, ref) => {
    const [t] = useTranslation();
    return (
        <IconButton ref={ref} {...props} >
            <Tooltip title={t('common.delete')} {...TooltipProps}>
                <Icon {...IconProps}/>
            </Tooltip>
        </IconButton>
    );
});

export const CancelIconButton   = React.forwardRef(({Icon=CancelIcon, IconProps = obj, TooltipProps = obj, ...props}, ref) => {
    const [t] = useTranslation();
    return (
        <IconButton ref={ref} {...props} >
            <Tooltip title={t('common.cancel')} {...TooltipProps}>
                <Icon {...IconProps}/>
            </Tooltip>
        </IconButton>
    );
});

export const ViewIconButton = React.forwardRef(({Icon=SearchIcon,IconProps = obj, TooltipProps = obj, ...props}, ref) => {
    const [t] = useTranslation();
    return (
        <IconButton ref={ref} {...props} >
            <Tooltip title={t('common.view')} {...TooltipProps}>
                <Icon {...IconProps}/>
            </Tooltip>
        </IconButton>
    );
});

export const UpdateIconButton = React.forwardRef(({Icon=UpdateIcon,IconProps = obj, TooltipProps = obj, ...props}, ref) => {
    const [t] = useTranslation();
    return (
        <IconButton ref={ref} {...props} >
            <Tooltip title={t('common.update')} {...TooltipProps}>
                <Icon {...IconProps}/>
            </Tooltip>
        </IconButton>
    );
});

export const RefreshIconButton = React.forwardRef(({Icon=RefreshIcon, IconProps = obj, TooltipProps = obj, ...props}, ref) => {
    const [t] = useTranslation();
    return (
        <IconButton ref={ref} {...props} >
            <Tooltip title={t('common.refresh')} {...TooltipProps}>
                <Icon {...IconProps}/>
            </Tooltip>
        </IconButton>
    );
});

export const SettingsIconButton = React.forwardRef(({Icon=SettingsIcon, IconProps = obj, TooltipProps = obj, ...props}, ref) => {
    const [t] = useTranslation();
    return (
        <IconButton ref={ref} {...props} >
            <Tooltip title={t('common.settings')} {...TooltipProps}>
                <Icon {...IconProps}/>
            </Tooltip>
        </IconButton>
    );
});

export const DownloadIconButton =  React.forwardRef(({Icon=GetAppIcon,IconProps = obj, TooltipProps = obj, ...props}, ref) => {
    const [t] = useTranslation();
    return (
        <IconButton ref={ref} {...props} >
            <Tooltip title={t('common.download')} {...TooltipProps}>
                <Icon {...IconProps}/>
            </Tooltip>
        </IconButton>
    );
});

export const PlayIconButton =  React.forwardRef(({Icon=PlayCircleFilledIcon,IconProps = obj, TooltipProps = obj, ...props}, ref) => {
    const [t] = useTranslation();
    return (
        <IconButton ref={ref} {...props} >
            <Tooltip title={t('common.play')} {...TooltipProps}>
                <Icon {...IconProps}/>
            </Tooltip>
        </IconButton>
    );
});

export const CopyIconButton =  React.forwardRef(({Icon=CopyIcon,IconProps = obj, TooltipProps = obj, ...props}, ref) => {
    const [t] = useTranslation();
    return (
        <IconButton ref={ref} {...props} >
            <Tooltip title={t('common.copy')} {...TooltipProps}>
                <Icon {...IconProps}/>
            </Tooltip>
        </IconButton>
    );
});

export const OrderIconButton = React.forwardRef(({Icon=StorefrontIcon, IconProps = obj, TooltipProps = obj, ...props}, ref) => {
    const [t] = useTranslation();
    return (
        <IconButton ref={ref} {...props} >
            <Tooltip title={t('common.order')} {...TooltipProps}>
                <Icon {...IconProps}/>
            </Tooltip>
        </IconButton>
    );
});