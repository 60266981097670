/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
LibraryDetails Component
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       19th January 2022

*******************************************************************************************/
import React                            from 'react';
import {styled}                         from '@mui/material';
import TickIconMUI                      from '@mui/icons-material/CheckCircle';
import CrossIconMUI                     from '@mui/icons-material/Cancel';

const YesIconMUI    = styled(TickIconMUI)(({theme}) => ({
    color : theme.palette.success.main
}))

const NoIconMUI     = styled(CrossIconMUI)(({theme}) => ({
    color : theme.palette.error.main
}))

const obj           = {}

export const YesNoIcon = ({
    value=true, 
    yesIcon         : YesIcon   = YesIconMUI, 
    yesIconProps                = obj, 
    noIcon          : NoIcon    = NoIconMUI, 
    noIconProps                 = obj, 
    ...props
}) => (
    value 
        ? <YesIcon  {...props} {...yesIconProps}    /> 
        : <NoIcon   {...props} {...noIconProps}     />
)

export default YesNoIcon;