/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
User Menu
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       3rd July 2022

*******************************************************************************************/
import React                            from 'react';
import Menu                             from './Menu';
import FatMenu                          from './FatMenu';
import usePages                         from './usePages';
import { 
    useUser,
    useTranslation,
}                                       from 'contexts';
import { UserNavigationTitle }          from './Titles';

export const UserMenu = ({columns = 2, ...props}) => {
    
    const [t]                               = useTranslation();
    const {isAuthenticated, isSuspended}    = useUser();
    const {userPages}                       = usePages();
    const userValid                         = isAuthenticated && !isSuspended;
    if(!userValid) 
        return null;
    return (
        <Menu showArrow = {true} label = {t('common.user')} >
            <UserNavigationTitle />
            <FatMenu 
                pages   = {userPages} 
                columns = {columns} 
            />
        </Menu>
    )
}

export default UserMenu;
