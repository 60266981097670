/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Date Range Chips
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       28th April 2022

*******************************************************************************************/
import React                            from 'react';
import moment                           from 'moment';
import { 
    styled, useMediaQuery, 
    alpha, Box, Tooltip, 
    Typography 
}                                       from '@mui/material';
import DateRangeIcon                    from '@mui/icons-material/DateRange';
import { DateChip }                     from './DateChip';
import withDisabledOffline              from 'components/hoc/withDisabledOffline';
import { useLocale }                    from 'contexts';
import { withTranslation }              from './hoc';
import { useClock }                     from "hooks";

const OnlineDateChip = withDisabledOffline(DateChip);

const noop = () => {};

const Spacer = styled(Box,{
    shouldForwardProp : prop => prop !== 'enabled'
})(({theme,enabled = false})=>({
    flexGrow : 1,
    ...(enabled && {
        marginLeft      : theme.spacing(4),
        marginRight     : theme.spacing(4),
        marginBottom    : 'auto',
        marginTop       : 'auto',
        borderBottom    : `1px solid ${alpha(theme.palette.text.secondary,0.15)}`
    })
}))

const DurationContainer = styled(Box)(({theme})=>({
    textAlign       : 'center',
    lexShrink       : 1, 
    margin          : 'auto',
    transform       : 'translateY(-2px)',
    fontStyle       : 'italic'
}))

export const DateRangeChips = withTranslation( ({
    t,
    disabled                = false, 
    start                   = undefined, 
    startLabel              = undefined,
    finish                  = undefined, 
    finishLabel             = undefined,
    onClick : handleClick   = noop, 
}) => {
    const {formatDateTime}              = useLocale();
    const {now}                         = useClock();
    const [duration,    setDuration]    = React.useState(undefined);

    // Calculate Duration
    React.useEffect(() => {
        let [a,b] = [start,finish];
        if(moment.isMoment(a)){
            b = moment.isMoment(b) ? b : now;
            setDuration(moment.duration(b.diff(a)));
        }else{
            setDuration(undefined);
        }
    }, [start, finish, now]);

    const mdUp = useMediaQuery(theme => theme.breakpoints.up('md'));

    return (
        <Box display={'flex'} style={{position:'relative'}}>
            <Box flexShrink={1}>
                {
                    start && 
                    <Tooltip title={startLabel || t('components.dateRangeChips.startLabel')} placement="top">
                        <span>
                            {mdUp && 
                                <OnlineDateChip 
                                    disabled    = {disabled} 
                                    icon        = {<DateRangeIcon/>} 
                                    prefix      = {startLabel || t('components.dateRangeChips.startLabel')}
                                    date        = {start} 
                                    onClick     = {handleClick}
                                />
                            }
                            {!mdUp && 
                                <Typography variant="body2" sx={{fontWeight:400}}>
                                    {formatDateTime(start)}
                                </Typography>
                            }
                        </span>
                    </Tooltip>
                }
            </Box>
            {
                mdUp && 
                <>
                    <Spacer enabled={Boolean(duration)}/>
                    <DurationContainer>
                        {
                            duration && 
                            <Typography sx={{color:'text.primary'}}>
                                {t('components.dateRangeChips.durationInterval', {interval : duration.humanize()})}
                            </Typography>
                        }
                    </DurationContainer>
                </>
            }

            <Spacer enabled={Boolean(duration)}/>
            
            <Box flexShrink={1}>
                {
                    finish && 
                    <Tooltip title={finishLabel || t('components.dateRangeChips.finishLabel')} placement="top">
                        <span>
                            {
                                mdUp && 
                                <OnlineDateChip 
                                    disabled    = {disabled} 
                                    icon        = {<DateRangeIcon/>} 
                                    prefix      = {finishLabel || t('components.dateRangeChips.finishLabel') }
                                    date        = {finish} 
                                    onClick     = {handleClick}
                                />
                            }
                            {
                                !mdUp && 
                                <Typography variant="body2" sx={{fontWeight:400}}>
                                    {formatDateTime(finish)}
                                </Typography>
                            }
                        </span>
                    </Tooltip>
                }
            </Box>
        </Box>
    );
});

export default DateRangeChips;
