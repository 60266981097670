/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Cart Quantity Modifyer
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       14th April 2022

*******************************************************************************************/
import React                            from 'react';
import { QuantityController }           from 'components';
import { withTranslation }              from './hoc';
import { Confirmation }                 from 'components/modals';

const noopvalue = (value) => {}

// Component, to Modify Cart Quantity for a product
export const CartQuantityModifyer = withTranslation( ({
    t,
    disabled            = false,
    title               = undefined,
    readOnly            = false,
    quantity            = 1, 
    onClick             = noopvalue,
    confirmAtZero       = true,
    ...props
}) => {
    const [open, setOpen]               = React.useState(false);

    const handleOpen                    = React.useCallback(() => setOpen(true),[]);
    const handleCancel                  = React.useCallback(() => setOpen(false),[]);
    const handleOk                      = React.useCallback(() => {
        handleCancel();
        onClick(0);
    },[handleCancel, onClick]);

    const handleClick                   = React.useCallback( (event, delta) => {
        if(quantity + delta >=  1 || !confirmAtZero) 
            return onClick(delta)
        return handleOpen();
    }, [confirmAtZero, handleOpen, onClick, quantity])

    return (
        <React.Fragment>
            <QuantityController 
                title               = { title || t('components.cartQuantityModifyer.quantity') } 
                quantity            = {quantity} 
                onClick             = {handleClick}
                readOnly            = {readOnly}
                disabled            = {disabled}
                {...props}
            />
            <Confirmation 
                readOnly    = {readOnly} 
                title       = { t('components.cartQuantityModifyer.pleaseConfirm') }
                open        = {open}
                onOk        = {handleOk} 
                onCancel    = {handleCancel}
            >
                { t('components.cartQuantityModifyer.pleaseConfirmPrompt') }
            </Confirmation>
        </React.Fragment>
    )
})

export default CartQuantityModifyer;