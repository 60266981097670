/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Comments Actions
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       12th May 2022

*******************************************************************************************/
import React                            from 'react';
import { 
    styled,
    Button as ButtonMUI,
    Box,
}                                       from '@mui/material';
import ReplyIconMUI                     from '@mui/icons-material/Reply';
import EditIconMUI                      from '@mui/icons-material/Edit';
import DeleteIconMUI                    from '@mui/icons-material/Delete';
import ThumbUpIconMUI                   from '@mui/icons-material/ThumbUp';
import ThumbDownIconMUI                 from '@mui/icons-material/ThumbDown';
import { 
    ConfirmationButton,
    Like
    // Button
}                                       from 'components'
import { 
    useUser,
    useComment,
    useTranslation
}                                       from 'contexts';
import withTinyIcon                     from './hoc/withIcon';
import withActionButton                 from './hoc/withActionButton';
import { withDisabledOffline }          from 'components/hoc';

const ReplyIcon     = withTinyIcon(ReplyIconMUI);
const EditIcon      = withTinyIcon(EditIconMUI);
const DeleteIcon    = withTinyIcon(DeleteIconMUI);
const ThumbUpIcon   = withTinyIcon(ThumbUpIconMUI);
const ThumbDownIcon = withTinyIcon(ThumbDownIconMUI);

const ButtonBase    = withActionButton(ButtonMUI);
const Button        = withDisabledOffline(ButtonBase);

const noop          = () => {};

const RootContainer = styled(Box)(({theme}) => ({
    display         : 'flex',
    flexDirection   : 'row',
    marginTop       : theme.spacing(1),
    marginBottom    : theme.spacing(1),
    "& > * + *" : {
        marginLeft : theme.spacing(0.5)
    }
}))

const ButtonContainer = styled(Box)(({theme}) => ({
    color : theme.palette.primary.textContrast
}))

export const CommentsActions = ({
    data,
    loading         = false,
    deleting        = false,
    reply           = true, 
    edit            = undefined, 
    onClickReply    : handleClickReply  = noop, 
    onClickEdit     : handleClickEdit   = noop, 
    onClickDelete   : handleClickDelete = noop, 
    onDeleting      : handleDeleting    = noop,
}) => {
    const {t}                                               = useTranslation();
    const {canPost,remaining}                               = useComment();
    const {userId, isAuthenticated, loginWithPopup}         = useUser();
    const [confirmationRequired, setConfirmationRequired]   = React.useState(false);
    const handleConfirmationRequired                        = React.useCallback( (value) => {
        setConfirmationRequired(value);
        handleDeleting(value);
    }, [handleDeleting])

    const isOwner                                           = React.useMemo(() => (
        Boolean(data?.user?.id && data?.user?.id === userId)
    ), [data?.user?.id, userId])

    const userReplyBlocked                                  = React.useMemo(() => (
        [loading, !data.id, isOwner, confirmationRequired].some(Boolean)
    ), [confirmationRequired, data.id, isOwner, loading]);

    const disabled = React.useMemo(() => (
        [data?.moderated, data?.censored, data?.deleted].some(Boolean)
    ), [data]);

    if(edit || reply)            
        return null;

    return (
        <RootContainer id = "buttonsContainer">
            {
                data?.id && 
                <ButtonContainer>
                    <Like 
                        disabled    = {disabled}
                        reference   = {data?.id} 
                        likeIcon    = {ThumbUpIcon} 
                        dislikeIcon = {ThumbDownIcon} 
                        size        = "small"
                    /> 
                </ButtonContainer>
            }
            <ButtonContainer ml={2}>
                <Button
                    disabled    = {userReplyBlocked || !canPost || disabled} 
                    onClick     = {isAuthenticated ?  handleClickReply : loginWithPopup} 
                    color       = "inherit" 
                    size        = "small" 
                    startIcon   = { <ReplyIcon /> }
                    sx          = {{
                        minWidth : 0
                    }}
                >
                    {
                        !userReplyBlocked && remaining > 0
                            ? t('components.comments.replyInSeconds', { seconds : (remaining/1000 + 1).toFixed(0) } )
                            : t('components.comments.reply')
                    }
                </Button>
            </ButtonContainer>
            {
                isOwner &&
                <ButtonContainer>
                    <Button 
                        disabled    = {loading || confirmationRequired || !isAuthenticated || disabled} 
                        onClick     = {handleClickEdit} 
                        color       = "inherit" 
                        size        = "small" 
                        startIcon   = { <EditIcon /> }
                        sx          = {{
                            minWidth : 0
                        }}
                    >
                        {t('components.comments.edit')}
                    </Button>
                </ButtonContainer>
            }
            {
                isOwner && 
                <ButtonContainer>
                    <ConfirmationButton 
                        disabled                = {loading || deleting || !isAuthenticated || disabled} 
                        onClick                 = {handleClickDelete}
                        onConfirmationRequired  = {handleConfirmationRequired}
                        color                   = "error" 
                        size                    = "small" 
                        startIcon               = { <DeleteIcon /> } 
                        sx                      = {{
                            minWidth : 0, 
                            fontSize : 8, 
                            textTransform : 'capitalize'
                        }}
                    >
                        {t('components.comments.delete')}
                    </ConfirmationButton>    
                </ButtonContainer>
            }
            <Box flexGrow={1}/>
        </RootContainer>
    )
}

export default CommentsActions;