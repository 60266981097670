/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Payment Method Form
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       25th December 2020

*******************************************************************************************/
import React                            from 'react';
import { 
    CardNumberElement,
    useStripe, 
    useElements,
}                                       from '@stripe/react-stripe-js';
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Typography,
    DialogActions,
}                                       from '@mui/material';
import SubmitIcon                       from '@mui/icons-material/CheckCircleOutline';
import CancelIcon                       from '@mui/icons-material/Cancel';
import {
    StripeCreditCardDetails,
    StripeInfo,
    StripePaymentImages
}                                       from 'components';
import { useTranslation }               from 'contexts';
import {
    useStateEphemeral           
}                                       from 'hooks';
import {
    FORM_ERROR
}                                       from 'final-form';

const defaultSubmit = ({paymentMethod,isDefault}) => {}
const noop = () => {};

export const PaymentMethodForm = ({
    disabled    : disabledIn    = false, 
    onSubmit    : handleSubmit  = defaultSubmit,
    onCancel    : handleClose   = noop,
    ...rest
}) => {
    
    const {t}                                       = useTranslation();
    const stripe                                    = useStripe();
    const elements                                  = useElements(); 
   
    // Stripe Payment method and Is Default
    const [isDefault,       setDefault]             = React.useState(true);

    const [messageSuccess,    setMessageSuccess]    = useStateEphemeral(undefined);
    const [messageError,      setMessageError]      = useStateEphemeral(undefined, 5000);

    // Is Working of Not
    const [working, setWorking]                     = React.useState(false);   
    const [created, setCreated]                     = React.useState(false); 

    const startWorking                              = React.useCallback(() => setWorking(true), []);
    const finishWorking                             = React.useCallback(() => setWorking(false), []);

    // Default Checked
    const handleDefaultChange                       = React.useCallback((event,value) => setDefault(Boolean(value)), []);

    // Method to submit to stripe
    const handleSubmitStripe                        = React.useCallback((event) => new Promise(resolve => {

        event.preventDefault();

        const cardNum = elements.getElement(CardNumberElement);

        startWorking();
        stripe.createPaymentMethod({type : 'card', card : cardNum})
            .then(({error, paymentMethod}) => {
                if(error) throw error;
                return paymentMethod
            })
            .then(paymentMethod => handleSubmit({paymentMethod, isDefault}))
            .then(() => {
                setCreated(true);
                setMessageSuccess(t('components.forms.paymentMethodForm.successMessage'));
                resolve({});
            })
            .catch(error => {
                setMessageError(
                    (error?.errors || {})[FORM_ERROR] || error.message || t('components.forms.paymentMethodForm.errorMessage')
                );
                resolve(error);
            })
            .finally(finishWorking);

    }),[t, elements, startWorking, stripe, finishWorking, handleSubmit, isDefault, setMessageSuccess, setMessageError]);

    // Disabled or Not
    const disabled = React.useMemo(() => Boolean(working || disabledIn || created), [created, disabledIn, working]);

    return (
        <Box>
            <Box>
                <StripePaymentImages />
            </Box>

            <Typography component="div" sx={{fontSize:'0.75rem'}}>
                {t('components.forms.paymentMethodForm.paymentProcessedUsing')} <StripeInfo color="secondary" demoCards={true}/>
            </Typography>

            <StripeCreditCardDetails disabled={disabled} messageSuccess={messageSuccess} messageError={messageError}>
                <Box display="flex" flexDirection="row-reverse" pt={1} pr={1}>
                    <FormControlLabel
                        control = {
                            <Checkbox
                                disabled    = {disabled}
                                checked     = {isDefault}
                                name        = {'default'}
                                onChange    = {handleDefaultChange}
                            />
                        }
                        label = {
                            <Typography variant="body2">
                                {t('components.forms.paymentMethodForm.setAsDefault')}
                            </Typography>
                        }
                        sx = {{mr:0}}
                    />
                </Box>
            </StripeCreditCardDetails>

            <DialogActions>
                <Button 
                    startIcon   = {<CancelIcon/>} 
                    disabled    = {disabled} 
                    type        = "button"
                    variant     = "text" 
                    onClick     = {handleClose} 
                    sx          = {{
                        color : 'error.main'
                    }}
                >
                    {t('common.cancel')}
                </Button> 
                <Button 
                    startIcon   = {<SubmitIcon/>} 
                    disabled    = {disabled} 
                    type        = "button"
                    variant     = "contained"
                    color       = "secondary" 
                    onClick     = {handleSubmitStripe} 
                >
                    {t('common.submit')}
                </Button> 
            </DialogActions>
        </Box>
    )
}

export default PaymentMethodForm;