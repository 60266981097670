/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Support Tickets
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       9th December 2021

*******************************************************************************************/
import React                            from 'react';
import { Box, Typography}               from '@mui/material';
import {
    Title,
    TicketsTable,
    SkeletonTableWaitingForNetwork,
    DraggableDialog,
    TicketsThreadViewer,
    AdministratorModeChip,
    TicketsAdminAlert
}                                       from 'components';
import {
    TicketForm,
    FilterTicketForm
}                                       from 'components/forms';
import {
    useTicket,
    useNetwork,
    useLibrary,
    useUser
}                                       from 'contexts';
import {
    FabRefresh,
    FabAdd,
    FabFilter
}                                       from 'components/fabs';

const noop = () => {}

const defaultComponentProps = {}
const defaultFormProps      = {}
const defaultTitleProps     = {}

// Tickets Collection Component
export const Tickets = ({
    component : Component   = Box,
    componentProps          = defaultComponentProps, 
    id                      = undefined, 
    disabled : disabledIn   = false, 
    orderId     : oid       = undefined, 
    libraryId   : lid       = undefined, 
    adminMode               = false,
    showSkeleton            = false, 
    onChange : handleChange = noop, 
    FormProps               = defaultFormProps, 
    TitleProps              = defaultTitleProps,
    ...props
}) => {
    const { isNetworkReady }                    = useNetwork();
    const {isAdmin}                             = useUser();
    const {getLibraryById}                      = useLibrary();
    const {
        setEnabled,
        admin,
        setAdmin,
        working,
        queried,
        refresh,
        refreshTicket,
        tickets,
        submitTicket,
        setIncludeClosed,
        setIncludeOpen,
        setIncludeAssigned,
        setIncludeUnassigned,
        setIncludeRead,
        setIncludeUnread,

        ticketId,       setTicketId,
        libraryId,      setLibraryId,
        orderId,        setOrderId,
    }                                                           = useTicket();
    const [formData,                setFormData]                = React.useState({});
    const [openNewTicketModal,      setOpenNewTicketModal]      = React.useState(false);
    const [openViewTicketModal,     setOpenViewTicketModal]     = React.useState(false);
    const [openFilterTicketModal,   setOpenFilterTicketModal]   = React.useState(false);

    const [filter, setFilter]                                   = React.useState({assigned:[true,false], read:[true,false], open : [true]})

    // When OrderID, LibraryId Changes
    React.useEffect(() => setOrderId(oid),   [oid, setOrderId]);
    React.useEffect(() => setLibraryId(lid), [lid, setLibraryId]);

    // Reset
    React.useEffect(() => {

        // Destroy on leave
        return () => {
            setTicketId(undefined);
            setLibraryId(undefined);
            setOrderId(undefined);
            setFormData({});
        }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    // Enable
    React.useEffect(() => {
        setEnabled(true);
        return () => {
            setEnabled(false);
        }
    }, [setEnabled])

    // Form Data
    const resetFormData = React.useCallback(() => {

        if(orderId){
            
            setFormData({
                category : "ORDER", 
                inRelationTo : { 
                    order : orderId 
                }
            });

        } else if(libraryId){

            const setData = data => {
                setFormData({
                    category : "LIBRARY", 
                    inRelationTo : { 
                        library     : libraryId, 
                        delivery    : data?.delivery?.id, 
                        order       : data?.delivery?.order
                    }
                })
            };

            // if(libraryId === libraryViewerId && !libraryViewerLoading){
            //    setData(libraryViewerData)
            //}else{

            getLibraryById(libraryId, "Tickets.resetFormData")
                .then(setData)
                .catch(err => {
                    console.error(err);
                })

            //}
        }else {
            setFormData({});
        }

    },[orderId, libraryId, getLibraryById]);

    // Open/Close New Ticket Modal
    const handleOpenNewTicketModal      = React.useCallback( () => setOpenNewTicketModal(true), [])
    const handleCloseNewTicketModal     = React.useCallback( () => {
        setOpenNewTicketModal(false); 
        resetFormData();
    }, [resetFormData]);

    // Close View Ticket Modal
    const handleCloseViewTicketModal    = React.useCallback( () => {
        refreshTicket(ticketId);

        // Events
        handleChange(undefined);
        setTicketId(undefined);

    }, [handleChange, refreshTicket, setTicketId, ticketId])

    // Open/Close When ticketid changes
    React.useEffect(() => {
        setOpenViewTicketModal(Boolean(ticketId));
    }, [ticketId])

    // Reset when orderId changes
    React.useEffect(resetFormData,[resetFormData]);

    // Update admin state
    React.useEffect(() => {
        let newVal = Boolean(adminMode);
        if(newVal !== admin) 
            setAdmin(newVal)
    },[admin, adminMode, setAdmin])

    /*
    React.useEffect(() => {
        return () => {
            setFormData({});
        }
    }, [setLibraryId])
    */

    /*
    const handleChangeIncludeClosed             = e => setIncludeClosed(e.target.checked);
    const handleChangeIncludeOpen               = e => setIncludeOpen(e.target.checked);
    const handleChangeIncludeAssigned           = e => setIncludeAssigned(e.target.checked);
    const handleChangeIncludeUnassigned         = e => setIncludeUnassigned(e.target.checked);
    const handleChangeIncludeRead               = e => setIncludeRead(e.target.checked);
    const handleChangeIncludeUnread             = e => setIncludeUnread(e.target.checked);
    */

    const handleCloseFilterTicketModal          = React.useCallback( () => setOpenFilterTicketModal(false), []);
    const handleOpenFilterTicketModal           = React.useCallback( () => setOpenFilterTicketModal(true), []);
    

    // Destroy ticketid if it is no longer in the unassigned, or assigned tickets
    /*
    React.useEffect(()=>{
        if(queried && !working){
            let hasTickets = (tickets || []).length > 0;
            if(!hasTickets || (ticketId && !tickets.find(ticket => ticket.id === ticketId))){
                handleChange(undefined);
                setTicketId(undefined);
            }
        }
    },[ticketId, tickets, queried, working, handleChange, setTicketId]);
    */

    // Handle submit message. If no errors, disable reply form
    const handleSubmitTicket = React.useCallback( (formData) => new Promise(resolve => {
        setFormData(formData)
        submitTicket(formData)
            .then(async (result) => {
                await refresh();
                handleCloseNewTicketModal();
                return result;
            })
            .then(resolve)
            .catch(({errors}) => {
                resolve(errors);
            })
    }) , [handleCloseNewTicketModal, refresh, submitTicket])

    const handleFilterSubmit = React.useCallback( (formData) => {
        setFilter(formData);
        handleCloseFilterTicketModal();
    }, [handleCloseFilterTicketModal])

    React.useEffect(() => {
        let {assigned,read,open} = filter;
        setIncludeAssigned(     assigned.includes(true)     || !assigned.length);
        setIncludeUnassigned(   assigned.includes(false)    || !assigned.length);
        setIncludeRead(         read.includes(true)         || !read.length);
        setIncludeUnread(       read.includes(false)        || !read.length);
        setIncludeOpen(         open.includes(true)         || !open.length);
        setIncludeClosed(       open.includes(false)        || !open.length);
    },[filter, setIncludeAssigned, setIncludeClosed, setIncludeOpen, setIncludeRead, setIncludeUnassigned, setIncludeUnread])

    const disabled              = React.useMemo(() => (
        disabledIn || !isNetworkReady || openViewTicketModal || openNewTicketModal || openFilterTicketModal || working
    ), [disabledIn, isNetworkReady, openFilterTicketModal, openNewTicketModal, openViewTicketModal, working]);
    
    const filterQuantity        = React.useMemo(() => (
        Object.keys(filter).reduce((a,c) => a + (Array.isArray(filter[c]) ? filter[c] : []).length, 0)
    ), [filter]);

    const formProps = React.useMemo(() => ({
        resetOnSubmit       : false,
        disabledAfterSubmit : true,
        showCancelButton    : true,
    }),[])

    if(adminMode && !isAdmin)       return null; // Permission Mismatch
    if(oid !== orderId)             return null; // OrderID Mismatch
    if(lid !== libraryId)           return null; // LibraryID Mismatch

    if(!queried && showSkeleton){
        return ( 
            <SkeletonTableWaitingForNetwork />
        )
    }

    // Render
    return (
        <Box id={id} display="flex" flexGrow={1} flexDirection="column" style={{height:'100%'}}>
            <Box flexShrink={1}>
                <TicketsAdminAlert />
                <Box display="flex" style={{minHeight:50}} pb={1}>
                    <Box flexShrink={1} style={{margin:'auto'}}>
                        <Title variant="h5" {...TitleProps}>
                            {admin ? "Support Tickets" : "Customer Support and Help"}
                        </Title>
                    </Box>
                    {
                        admin && 
                        <Box flexShrink={1} style={{display:'inline-block'}} pl={1}>
                            <AdministratorModeChip />
                        </Box>
                    }
                    <Box flexGrow={1}/>
                    <Box flexShrink={1} style={{margin:'auto'}} pl={1} align={'right'}>
                        <FabRefresh 
                            loading     = {working} 
                            tooltip     = "Refresh" 
                            disabled    = {disabled}
                            onClick     = {refresh}
                        />
                        <FabFilter 
                            tooltip     = "Filter"
                            disabled    = {disabled}
                            color       = {filterQuantity > 0 ? "secondary" : "primary"}
                            quantity    = {filterQuantity}
                            onClick     = {handleOpenFilterTicketModal}
                        />
                        {
                            !adminMode && 
                            <FabAdd
                                tooltip     = "Create Ticket" 
                                disabled    = {disabled}
                                onClick     = {handleOpenNewTicketModal}
                            />
                        }
                    </Box>
                </Box>
                {
                    !admin && 
                    <Typography variant="body2" sx={{maxWidth:600}}>
                        Should you have any issues, feel free to create and lodge a ticket with our help desk, 
                        and we will get back to you as soon as possible.
                    </Typography>
                }
            </Box>

            <Box flexGrow={1} display="flex" flexDirection="column">
                <Component {...componentProps} style={{flexGrow:1, display:'flex',flexDirection:'column'}}>
                    {   queried && 
                        <TicketsTable 
                            disabled        = {disabled}
                            ticketId        = {ticketId} 
                            tickets         = {tickets} 
                            onClick         = {(tid) => {
                                handleChange(tid);
                                setTicketId(tid);
                            }} 
                            onClickCreate   = {handleOpenNewTicketModal}
                            adminMode       = {adminMode} />
                    }
                    {
                        !queried &&
                        <SkeletonTableWaitingForNetwork/>
                    }
                </Component>
            </Box>

            {
                openNewTicketModal && 
                <DraggableDialog 
                    title                       = "Create Ticket"
                    open                        = {openNewTicketModal}  
                    onClose                     = {handleCloseNewTicketModal}
                    onOk                        = {handleCloseNewTicketModal}
                    showButtons                 = {false}
                    disableBackdropClick        = {true}
                    fullScreen                  = {!true}
                    maxWidth                    = "md"
                    fullWidth
                >
                    <TicketForm
                        formData            = {formData}
                        allowEditDropdowns  = {!Boolean(orderId || libraryId)}
                        onCancel            = {handleCloseNewTicketModal}
                        onSubmit            = {handleSubmitTicket}
                        textRows            = {10}
                        responsive          = {true}
                        {...FormProps}
                        FormProps           = {formProps}
                    />
                </DraggableDialog>
            }

            {
                openViewTicketModal && 
                <DraggableDialog 
                    title                   = { <>Ticket Details {admin && <AdministratorModeChip/>}</> }
                    open                    = {openViewTicketModal}  
                    onClose                 = {handleCloseViewTicketModal}
                    onOk                    = {handleCloseViewTicketModal}
                    fullScreen              = {true}
                    showButtons             = {false}
                    disableBackdropClick    = {true}
                >
                    <TicketsThreadViewer 
                        ticketId    = {ticketId} 
                        onCancel    = {handleCloseViewTicketModal} 
                        onClose     = {handleCloseViewTicketModal} 
                        adminMode   = {adminMode} 
                        showTitle   = {false}
                    />
                </DraggableDialog>
            }

            {
                openFilterTicketModal &&
                <DraggableDialog 
                    title                   = {"Filter Tickets"}
                    open                    = {openFilterTicketModal}  
                    onClose                 = {handleCloseFilterTicketModal}
                    onOk                    = {handleCloseFilterTicketModal}
                    fullScreen              = {false}
                    showButtons             = {false}
                    maxWidth                = "xs"
                    fullWidth
                >
                    <FilterTicketForm formData={filter} onSubmit={handleFilterSubmit} onCancel={handleCloseFilterTicketModal} onClose={handleCloseFilterTicketModal} showTitle={false}/>
                </DraggableDialog>

            }
        </Box>
    )
}

export default Tickets;
