/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
useSize Hook
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       23rd July 2022

*******************************************************************************************/
import React                            from 'react';
import useResizeObserver                from '@react-hook/resize-observer';


const DEFAULT_DOM_RECT = {
    bottom  : 0,
    height  : 0,
    left    : 0,
    right   : 0,
    top     : 0,
    width   : 0,
    x       : 0,
    y       : 0
};


export const useSize = (target) => {

    const [size, setSize] = React.useState(DEFAULT_DOM_RECT)
  
    React.useLayoutEffect(() => {
        if(target.current){
            setSize(target.current.getBoundingClientRect());
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [target?.current])
  
    // Where the magic happens
    useResizeObserver(target, (entry) => setSize(entry.contentRect))
    return size
};

export default useSize;