/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Legal Component
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       21st April 2023

*******************************************************************************************/
import React                                from 'react';
import moment                               from 'moment';
import { 
    Grid, Box, CardMedia, Typography,
}                                           from '@mui/material';
import { 
    Title,
    Seo,
    ContentfulRichText,
    Skeleton,
    RootContainer,
    NotFound
}                                           from 'components';
import { 
    useContentful,
    useLocale
}                                           from 'contexts';
import { withTranslation }                  from './hoc';
import config                               from '../config';

export const LegalComponent = withTranslation( ({t, slug = undefined}) => {

    const {getLegal}                = useContentful();
    const {formatDateTime}          = useLocale();
    const [queried, setQueried]     = React.useState(undefined);
    const [loading, setLoading]     = React.useState(false);
    const [data,    setData]        = React.useState(undefined);

    React.useEffect(() => {
        if(slug){
            setLoading(true);
            getLegal(slug)
                .then(items => setData(items[0]))
                .finally(() => {
                    setLoading(false)
                    setQueried(moment())
                })
        }else{
            setData(undefined);
        }
    },[slug, getLegal])

    if(!slug || (queried && !data))
        return (
            <NotFound />
        )

    return (
        <RootContainer>
            {
                queried &&
                <Seo
                    title           = { data?.fields?.title }
                    description     = { [config?.company,data?.fields?.title].filter(Boolean).join(' ')}
                />
            }
            <Grid container spacing={2}>
                <Grid item xs={12} md={4} align="center" sx={{display:{xs:'none',md:'initial'}}}>
                    <Box style={{position:'sticky',top:0}}>
                        {
                            !loading && data?.fields?.featuredImage?.fields?.file?.url &&
                            <CardMedia
                                component   = "img"
                                src         = {`${data?.fields?.featuredImage?.fields?.file?.url}?w=500&h=500`}
                                alt         = {data?.fields?.featuredImage?.fields?.title}
                            />
                        }
                        {
                            (loading || !data?.fields?.featuredImage?.fields?.file?.url) &&
                            <Box sx={{width:'100%',aspectRatio:'1'}}>
                                <Skeleton width={'100%'} height={"100%"}/>
                            </Box>
                        }
                    </Box>
                </Grid>
                <Grid item xs={12} md={8} sx={{textAlign:'justify', '& > * + *' : { mt : 2 }}}>
                    {
                        loading && 
                        <Skeleton height={'50vh'} />
                    }
                    {   
                        !loading &&
                        <>
                            <Title variant="h3" component="h1" gutterBottom>
                                {data?.fields?.title}
                            </Title>
                            {
                                data?.sys?.updatedAt &&
                                <Typography sx={{fontWeight:400}}>
                                    { t('components.legalComponent.lastUpdated',{ timeStamp : formatDateTime(moment(data?.sys?.updatedAt)) }) }
                                </Typography>
                            }
                            <Box>
                                <ContentfulRichText content={data?.fields?.body}/>
                            </Box>
                        </>
                    }
                </Grid>
            </Grid>
        </RootContainer>
    );
});

export default LegalComponent;