/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Last queried
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       15th May 2021

*******************************************************************************************/

import React                    from 'react';
import moment                   from 'moment';
import { Box, Typography }      from '@mui/material';
import FormattedMoment          from 'components/FormattedMoment';
import { withTranslation }      from './hoc';

export const LastQueried = withTranslation( ({t, quantity = 0, timestamp = undefined, ...props }) => {
    
    if(!moment.isMoment(timestamp))
        return null;

    return (
        <Box align="right" sx={{py:2}}>
            <Typography variant="body2" {...props} sx={{color:'text.primary', ...props.sx}}> 
                {t('components.lastQueried.recordQuantity',{quantity:quantity || 0})}{". "}<FormattedMoment prefix={t('components.lastQueried.lastQueried')} time={timestamp}/>
            </Typography>
        </Box>
    )
});

export default LastQueried;