import lodashDebounce       from 'lodash/debounce';
import moment               from 'moment';
import config               from '../config';


const debounce = (func,wait=undefined,options={}) => (
    lodashDebounce(func, wait || config.debounce.wait, {...config.debounce.options,...options})
)

// Absolute value and left-pad Function
const absPad = x => Math.abs(x || 0).toString().padStart(2,'0');

// Formatter Function
const formatterFunction = ({prefix,days,hours,minutes,seconds}) => (
    `${prefix}${parseInt(days) ? absPad(days) + 'd' : ''}${parseInt(days) || parseInt(hours) ? absPad(hours) + 'h' : ''}${absPad(minutes)}m${absPad(seconds)}s`
)

// Function to format the duration
const formatDuration = (duration) => {

    // Not a duration
    if(!moment.isDuration(duration)) 
        return null;

    // Is duration in past?
    const isNeg = duration < 0;

    // Build the various elements
    const   d = moment.duration(duration).days(),
            h = moment.duration(duration).hours(),
            m = moment.duration(duration).minutes(),
            s = moment.duration(duration).seconds();

    // The Prefix
    const prefix = isNeg ? '-' : ''
    
    // Now build the formatted duration
    return formatterFunction({prefix,days:absPad(d),hours:absPad(h),minutes:absPad(m),seconds:absPad(s)});

}

export {
    debounce,
    formatDuration
}