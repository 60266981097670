/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Json Viewer
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       22nd December 2020

*******************************************************************************************/
import React            from 'react';
import isEqual          from 'lodash/isEqual';
import ReactJson        from 'react-json-view';
import { useTheme, Box} from '@mui/material';
import { usePrevious }  from 'hooks';

const REACT_JSON_OPTIONS = {
    displayObjectSize : true,
    collapsed         : 1
}

const obj = {};

export const JSONViewer =({
    src : srcIn     = obj,
    style           = obj,
    name            = undefined,
    ...rest
} = {}) => {

    const theme         = useTheme()
    const [src, setSrc] = React.useState(srcIn);
    const srcPrv        = usePrevious(src);

    // Update only if change by object comparison
    React.useEffect(() => {
        if(!isEqual(srcIn,srcPrv))
            setSrc(srcIn);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[srcIn])

    const isDark        = React.useMemo(() => theme.palette.mode === 'dark', [theme.palette.mode]);
    const rjvTheme      = React.useMemo(() => isDark ? "tomorrow" : 'rjv-default', [isDark]);
    const styleMemo     = React.useMemo(() => ({
        padding : theme.spacing(2), 
        fontSize:'0.75em', 
        ...style
    }),[style, theme])

    return (
        <Box width="100%">
            <ReactJson 
                src     = {src} 
                theme   = {rjvTheme} 
                {...REACT_JSON_OPTIONS} 
                name    = {undefined} 
                style   = {styleMemo}
                {...rest} 
            />
        </Box>
    )
}

export default JSONViewer;