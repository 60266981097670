/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
with Typography HOC
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       22nd July 2022

*******************************************************************************************/
import React                            from 'react';
import { styled, Box, Typography }      from '@mui/material';

const obj = {};

const FeatureTypographyContainer = styled(Box)(({theme}) => ({
    position    : 'absolute',
    top         : '50%',
    left        : '50%',
    transform   : `translate(-50%, -50%)`,
    width       : '100%',
    padding     : theme.spacing(2)
}))

const withStyledContainerComponent  = Component => styled(Component)(() => ({
    width       : '100%',
    height      : '100%',
    position    : "relative",
    overflow    : 'hidden'
}));

export const withFeatureTypography = (Component) => ({
    showFeature                                         = true,
    containerComponent  : ContainerComponent            = Box,
    typographyComponent : FeatureTypographyComponent    = Typography, 
    typographyProps                                     = obj, 
    componentProps                                      = obj,
    containerProps                                      = obj,
    ...props
}) => {
    const StyledContainerComponent  = React.useMemo(() => withStyledContainerComponent(ContainerComponent), [ContainerComponent]);
    return (
        <StyledContainerComponent {...containerProps}>
            <Component {...props} {...componentProps} />
            {   
                showFeature && FeatureTypographyComponent &&
                <FeatureTypographyContainer>
                    <FeatureTypographyComponent {...typographyProps}>
                        {typographyProps.children}
                    </FeatureTypographyComponent>
                </FeatureTypographyContainer>
            }
        </StyledContainerComponent>
    )
}

export default withFeatureTypography