/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Grapes Editor V2
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       14th July 2022

// see https://codesandbox.io/s/editor-urxqy?file=/src/App.js

*******************************************************************************************/
import React                            from "react";
import moment                           from "moment";
import { 
    styled,
    useTheme, 
    Box, 
    Typography 
}                                       from "@mui/material";
import grapesjs                         from "grapesjs";
import blocks                           from 'grapesjs-blocks-basic';
import rteExtensions                    from 'grapesjs-rte-extensions';
import grapesjsStyleBg                  from 'grapesjs-style-bg';
import * as customBlocks                from './blocks'
import { 
    useAlert,
    useNetwork, 
    useUser 
}                                       from "contexts";
import { 
    useCancelToken,
    useFileUploader
}                                       from 'hooks';

import "grapesjs/dist/css/grapes.min.css";
import "./styles.css";

const DEFAULT_HTML  = `<body></body>`;
const DEFAULT_CSS   = '';
const DEFAULT_JS    = '';
const BASE_API_URL  = '/api/admin/templates/'

const Root = styled(Box)({
    height  : "100%",
    border  : "5px solid #444"
});

const SideBars = styled(Box)({
    height      : "100%",
    position    : 'relative', 
    flexBasis   : 200, 
    maxWidth    : '40%',
    minWidth    : 150
});

const ManagersContainer = styled(Box)(({theme}) => ({
    height      : "100%",
    overflow    : 'scroll',
    '& > * + *' : {
        marginTop : theme.spacing(2)
    }
}));

const SectionTitle = styled(Typography)(({theme}) => ({
    textAlign   : 'left',
    color       : theme.palette.primary.contrastText,
    fontSize    : '1.1rem',
    fontWeight  : 100
}));

const obj           = {};
const noopchange    = (args) => {};

const SectionRemark = (props) => (
    <Typography component="div" align="center" variant="body2" {...props}/>
);

const stripBodyContents = (str) => {
    if(str.includes("<body") && str.includes("body>")){
        const reg = /<body[^>]*>([^]*)<\/body/m;
        return str.match(reg)[1];
    }
    return str;
}

export const GrapesEditor = ({html = DEFAULT_HTML, css = DEFAULT_CSS, js = DEFAULT_JS, onChange : handleChange = {noopchange}, containerProps = obj, ...props}) => {

    const theme                         = useTheme();
    const {axios}                       = useNetwork();
    const {cancelToken }                = useCancelToken();
    const {getSignedParams}             = useFileUploader();
    const {token}                       = useUser();
    const {alert}                       = useAlert();
    const [editor, setEditor]           = React.useState(undefined);
    // const [assets, setAssets]           = React.useState(undefined);
    const [hasTraits,   setHasTraits]   = React.useState(false);
    const [isSelected,  setIsSelected]  = React.useState(false);
    const [isStylable,  setIsStylable]  = React.useState(false);

    const components = React.useMemo(() => (
        `
            <body>
                ${stripBodyContents(html)}
            </body>
            <style>${css}</style>
            <script>${js}</script>
        `
    ), [css, js, html]);

    // upload a sing 'File' to the dropbox
    // https://bobbyhadz.com/blog/notes-s3-signed-url
    const uploadSingleFile  = React.useCallback( async (file, group) => {

        if(!file || !(file instanceof File))
            throw new Error('file is required and must be of type File')

        // Get Presigned URL
        const presignedPostUrl = await getSignedParams({fileName:file.name, group, contentType : file.type });

        // Buold the form data
        const formData = new FormData();
        Object.entries(presignedPostUrl.fields).forEach(([k, v]) => {
            formData.append(k, v);
        });
        formData.append('file', file);

        // Upload
        const response = await fetch( presignedPostUrl.url, { method : 'POST', body : formData });

        // Error
        if (!response.ok)
            return Promise.reject(new Error( 'Invalid file upload' ));
        
        // Done
        return presignedPostUrl.meta.fileUrl;

    }, [getSignedParams]);

    // helper to get dimensions of an image
    const imageDimensions = file => new Promise((resolve, reject) => {
        const img = new Image()
        // the following handler will fire after a successful loading of the image
        img.onload = () => {
            const { naturalWidth: width, naturalHeight: height } = img
            resolve({ width, height })
        }
        // and this handler will fire if there was an error with the image (like if it's not really an image or a corrupted one)
        img.onerror = () => {
            reject('There was some problem with the image.')
        }
        img.src = URL.createObjectURL(file)
    })

    React.useEffect(() => {
        
        var editor = grapesjs.init({
            components      : components,
            container       : "#gjs",
            height          : "100%",
            fromElement     : false,
            jsInHtml        : true,
            exportWrapper   : 0,
            wrapperIsBody   : 0,
            storageManager  : { type : 0 },
            plugins: [
                blocks, 
                grapesjsStyleBg,
                rteExtensions,
            ],
            pluginsOpts: {
            }, 
            deviceManager: {
                appendTo: "#device-container",
            },
            assetManager: {
                assets          : [],
                autoAdd         : 1,
                multiUpload     : !false,
                showUrlInput    : !true,
                uploadFile      : async function(e) {
                    const files             = Array.from(e.dataTransfer ? e.dataTransfer.files : e.target.files);
                    const group             = 'assetManager/' + moment.utc().format('hh-mm-ss');
                    for(let i = 0; i < files.length; i++){
                        let file = files[i];
                        try {
                            let dim = await imageDimensions(file);
                            let url = await uploadSingleFile(file, group);
                            let res = await axios.post( `${BASE_API_URL}/assets`, 
                                {
                                    name    : file.name,
                                    type    : 'image',
                                    src     : url,
                                    width   : dim.width,
                                    height  : dim.height,
                                    deleted : false
                                }, 
                                { cancelToken }
                            )
                            alert(`Asset Manager - File ${file.name} uploaded`);
                            editor.AssetManager.add(res.data);
                        }catch(err){
                            alert(`Asset Manager - File ${file.name} upload error: ${err.message}`,'error');
                        }
                        await new Promise(resolve => setTimeout(resolve,25));
                    }
                }
            },
            layerManager: {
                appendTo: "#layers-container"
            },
            blockManager: {
                appendTo: "#blocks",
                // custom : true
            },
            styleManager: {
                appendTo: "#style-manager-container",
                sectors: [
                    {
                        name: "General",
                        open: false,
                        buildProps: [
                            "float",
                            "display",
                            "position",
                            "top",
                            "right",
                            "left",
                            "bottom"
                        ]
                    },
                    {
                        name: "Dimension",
                        open: false,
                        buildProps: [
                            "width",
                            "height",
                            "max-width",
                            "min-height",
                            "margin",
                            "padding"
                        ]
                    },
                    {
                        name: "Typography",
                        open: false,
                        buildProps: [
                            "font-family",
                            "font-size",
                            "font-weight",
                            "letter-spacing",
                            "color",
                            "line-height",
                            "text-align",
                            "text-shadow"
                        ]
                    },
                    {
                        name: "Decorations",
                        open: false,
                        buildProps: [
                            "border-radius-c",
                            "background-color",
                            "border-radius",
                            "border",
                            "box-shadow",
                            "background"
                        ]
                    },
                    {
                        name: "Extra",
                        open: false,
                        buildProps: ["opacity", "transition", "perspective", "transform"],
                        properties: [
                        {
                            type: "slider",
                            property: "opacity",
                            defaults: 1,
                            step: 0.01,
                            max: 1,
                            min: 0
                        }
                        ]
                    }
                ]
            },
            selectorManager: {
                appendTo: "#selectors-container"
            },
            traitManager: {
                appendTo: "#traits-container"
            },
            panels: {
                defaults: [
                    {
                        id  : "layers",
                        el  : "#layers-manager",
                        resizable: {
                            tc          : 0,
                            cr          : 1,
                            cl          : 0,
                            bc          : 0,
                            keyWidth    : "flex-basis"
                        }
                    },
                    {
                        id  : "content",
                        el  : "#content-manager",
                        resizable: {
                            tc          : 0,
                            cl          : 1,
                            cr          : 1,
                            bc          : 0,
                            keyWidth    : "flex-basis"
                        }
                    },
                    {
                        id  : "styles",
                        el  : "#style-manager",
                        resizable: {
                            tc          : 0,
                            cr          : 0,
                            cl          : 1,
                            bc          : 0,
                            keyWidth    : "flex-basis"
                        }
                    }
                ]
            }
        });

        editor.getConfig().showDevices = 0;
        editor.Panels.addPanel({
            id          : 'devices', 
            appendTo    : "#device-container",
            buttons: [
                { 
                    id          : "set-device-desktop", 
                    command     : function (e) { 
                        return e.setDevice("Desktop") 
                    }, 
                    className   : "fa fa-desktop", 
                    active      : 1 
                },
                { 
                    id          : "set-device-tablet", 
                    command     : function (e) { 
                        return e.setDevice("Tablet") 
                    }, 
                    className   : "fa fa-tablet" 
                },
                {   id          : "set-device-mobile", 
                    command     : function (e) { 
                        return e.setDevice("Mobile portrait") 
                    }, 
                    className   : "fa fa-mobile" 
                }
            ]
        });
    
        
        editor.on("load", () => {

            const bm = editor.BlockManager;

            // Broadly group the categories
            const blocksForCategories = {
                Layouts     : ['column1', 'column2', 'column3', 'column3-7'],
                Typography  : [/*'text',*/ 'link'],
                Media       : ['image', 'video', 'map']
            }
            
            // Render the blocks
            bm.render(
                [
                    ...Object
                    .keys(blocksForCategories)
                    .map(cat => (
                        blocksForCategories[cat]
                            .map(x => (
                                bm.get(x).set( "category", cat)
                            ))
                    )).flat(),
                    ...Object.values(customBlocks).map(block => block(theme))
                ]
            );

            // Initialize each category as collapsed, and allow only one category to be not collapsed at one time
            // https://github.com/artf/grapesjs/issues/446#issuecomment-339311523
            const categories = bm.getCategories();
            categories.each(category => {
                category.set('open', false).on('change:open', opened => {
                    opened.get('open') && categories.each(category => {
                        category !== opened && category.set('open', false);
                    })
                })
            })
        });

        editor.runCommand("sw-visibility");

        // Store Editor
        setEditor(editor);

        // Destroy
        return () => {
            try {
                if(editor){
                    editor.Panels.removePanel({id:'devices'});
                    editor.destroy();
                }
            } catch (err) {
                // Handle
            }
            setEditor(undefined);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [html, css, theme, token]);

    // Set Wrapper Not Stylable
    React.useEffect(() => {
        if(editor){
            const handler = () => editor.getWrapper().set('stylable',false);
            editor.on('load', handler);
            return () => {
                editor.off('load', handler);
            }
        }
    }, [editor])

    // Set Wrapper Not Stylable
    React.useEffect(() => {
        if(editor){
            const handleDeviceSelect = model => {
                // alert('New Device Selected');
            }
            const handleComponentSelected = model => {
                // alert('Component Selected');
            };
            const handleComponentDeselected = model => {
                // alert('Component Deselected','error');
            };
            const handleComponentUpdate = model => {
                // alert('Component Updated') //, model.get('content'));
            }
            editor.on('device:select',              handleDeviceSelect);
            editor.on('component:selected',         handleComponentSelected); 
            editor.on('component:deselected',       handleComponentDeselected); 
            editor.on('component:update',           handleComponentUpdate);
            return () => {
                editor.off('device:select',                 handleDeviceSelect);
                editor.off('component:selected',            handleComponentSelected); 
                editor.off('component:deselected',          handleComponentDeselected); 
                editor.off('component:update',              handleComponentUpdate);
            }
        }
    }, [alert, editor])

    // Components Updated
    React.useEffect(() => {
        if(editor){
            const handler = () => {
                const html  = stripBodyContents(editor.getHtml({ cleanId: false }));
                const css   = editor.getCss();
                const js    = editor.getJs();
                handleChange({html,css,js});
            }
            editor.on('update', handler);
            return () => {
                editor.off('update', handler);
            }
        }
    }, [editor, handleChange])

    React.useEffect(() => {
        if(editor){
            const processSelected = () => {
                const component = editor.getSelected(); // Component selected in canvas
                if(component){                          // Should be always truthy
                    const traits    = component.get('traits');
                    const stylable  = component.get('stylable');
                    setHasTraits(Boolean(traits.length > 0));
                    setIsSelected(true);
                    setIsStylable(stylable);
                }else{
                    setHasTraits(false);
                    setIsSelected(false);
                    setIsStylable(false);
                }
            }
            editor.on('component:selected',     processSelected);
            editor.on('component:deselected',   processSelected);
            editor.on('load',                   processSelected);
            return () => {
                editor.off('component:selected',     processSelected);
                editor.off('component:deselected',   processSelected);
                editor.off('load',                   processSelected);
            }
        }
    }, [editor])


    // Remove Asset
    React.useEffect(() => {
        if(editor && axios && cancelToken){

            const handleRemove = async (asset) => {
                const {attributes : {id = undefined, name = undefined} = {}} = asset;
                if(id){
                    try { 
                        await axios.delete(`${BASE_API_URL}/assets/${id}`, {cancelToken})
                        alert(`Asset Manager - Asset (${name || id}) removed`,'error');

                    // Problem Deleting, Restore
                    }catch(err){
                        alert(`Asset Manager - Error removing asset`,'error');
                        editor.AssetManager.add(asset);
                    }
                }else{
                    console.log('Asset removed');
                    alert(`Asset Manager - Asset removed`,'error');
                }
            }

            const handleAdd = (asset) => {
                const {attributes : {id = undefined, name = undefined} = {}} = asset;
                if(id || name){
                    alert(`Asset Manager - Asset (${name || id}) added`);
                }else{
                    alert(`Asset Manager - Asset Added`);
                }
            }

            // Listen
            editor.on('asset:remove',   handleRemove);
            editor.on('asset:add',      handleAdd);

            // Turn Off
            return () => {
                editor.off('asset:remove',  handleRemove);
                editor.off('asset:add',     handleAdd);
            }
        }
    }, [editor, axios, cancelToken, alert])

    const addAssets = React.useCallback( async (data) => {
        if(!editor) return;
        let result = [];
        let am = editor.AssetManager;
        if(am){
            let existing = am.getAll();
            for(let i = 0; i < data.length; i++){
                let added = false, 
                    asset = data[i];
                for(let i = 0; i < existing.length; i++){
                    let ex = existing.at(i);
                    if(asset.id && ex?.attributes?.id === asset.id){
                        added = true;
                        break;
                    }
                }
                if(!added) result.push(asset);
            }
            for(let i = 0; i < result.length; i++){
                am.add(result[i]);
                await new Promise(resolve => setTimeout(resolve, 50));
            }
        }
        return result;
    }, [editor]);

    // Load assets from server
    React.useEffect(() => {
        if(editor && axios && cancelToken){
            axios.get( `${BASE_API_URL}/assets`, { cancelToken } )
                .then(({data}) => data)
                .then(addAssets)
                .catch(err => {
                    console.error(err);
                })
        }
    },[addAssets, axios, cancelToken, editor])

    return (
        <Root {...containerProps}>
            <Box display="flex" width="100%" height="100%">
                <SideBars id="layers-manager">
                    <ManagersContainer>
                        <Box>
                            <SectionTitle> Layers </SectionTitle>
                            <Box id="layers-container" />
                        </Box>
                        <Box>
                            <SectionTitle> Blocks </SectionTitle>
                            <Box id="blocks"/>
                        </Box>
                    </ManagersContainer>
                </SideBars>
                <Box id="content-manager" flexGrow={1} height="100%" style={{position:'unset'}}>
                    <Box id="gjs" style={{height:"100%",overflow:'scroll'}}/>
                </Box>
                <SideBars id="style-manager">
                    <ManagersContainer>
                        <Box>
                            <SectionTitle> Devices </SectionTitle>
                            <Box id="device-container"/>
                        </Box>
                        <Box>
                            <SectionTitle> Selectors </SectionTitle>
                            <Box mt={-2} id="selectors-container"/>
                        </Box>
                        <Box>
                            <SectionTitle> Traits </SectionTitle>
                            <Box id="traits-container" style={{display:isSelected ? 'initial' : 'none'}}/>
                            {isSelected && !hasTraits &&    <SectionRemark>No Traits</SectionRemark> }
                            {!isSelected &&                 <SectionRemark>No Component Selected</SectionRemark> }
                        </Box>
                        <Box>
                            <SectionTitle> Styling </SectionTitle>
                            <Box id="style-manager-container" style={{display:isStylable ? 'initial' : 'none'}}/>
                            {isSelected && !isStylable &&   <SectionRemark>Not Stylable</SectionRemark> }
                            {!isSelected &&                 <SectionRemark>No Component Selected</SectionRemark> }
                        </Box>
                    </ManagersContainer>
                </SideBars>
            </Box>      
        </Root>
    );
};

export default GrapesEditor;
