/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Edit Library Button
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       3rd February 2023

*******************************************************************************************/
import React                            from 'react';
import { useMasterLibrary }             from 'contexts';
import {useCanQuery}                    from 'hooks';
import { DraggableDialog, FormAlert}    from 'components';
import { EditButton }                   from 'components/buttons';
import { MasterLibraryForm }            from 'components/forms';
import { withTranslation }              from './hoc';

const obj   = {};

export const EditLibraryButton = withTranslation( ({
    t, 
    children, 
    libraryId, 
    disabled    = false, 
    ButtonProps = obj 
}) => {
    
    const { allowQueryForAdmin }                = useCanQuery();
    const { getById, postById, setEnabledTypes} = useMasterLibrary(); 
    const [ data, setData]                      = React.useState(undefined);
    const [ working, setWorking]                = React.useState(false);

    const handleOpen                            = React.useCallback( () => {
        if(allowQueryForAdmin && libraryId){
            setWorking(true);
            getById(libraryId,false)
                .then(setData)
                .catch(err => {
                    setData(undefined)
                })
                .finally(() => {
                    setWorking(false);
                })
        }
    }, [allowQueryForAdmin, getById, libraryId]);

    const handleClose                           = React.useCallback( () => {
        setData(undefined)
    },  [setData]);
    
    const handleEditSubmit                      = React.useCallback( ({ _id:id, ...data }) => {
        return new Promise( resolve => {
            setWorking(true);
            postById(id, data)
                .then(handleClose)
                .then(() => {
                    resolve({})
                })
                .catch(({errors = {}}) => {
                    resolve(errors);
                })
                .finally(() => {
                    setWorking(false);
                })
        })
    }, [handleClose, postById]);

    React.useEffect(() => {
        if(allowQueryForAdmin && data)
            setEnabledTypes(true)
    }, [allowQueryForAdmin, data, setEnabledTypes])

    // No Product
    if(!allowQueryForAdmin || !libraryId) 
        return null;

    return (
        <React.Fragment>
            <EditButton disabled = {working || disabled} onClick={handleOpen} {...ButtonProps}>
                {children || t('common.edit')}
            </EditButton>
            <DraggableDialog 
                showButtons     = {false}
                title           = {t('components.editLibraryButton.editLibrary')}
                open            = {Boolean(data)} 
                onClose         = {handleClose} 
                onCancel        = {handleClose} 
            >
                <FormAlert severity="warning">
                    {t('components.editLibraryButton.noteWillEditMasterLibrary"')}
                </FormAlert>
                <MasterLibraryForm  
                    formData    = {data} 
                    onSubmit    = {handleEditSubmit} 
                    onCancel    = {handleClose} 
                    isEditing   = {true} 
                    submitText  = {t('common.update')}
                />
            </DraggableDialog>
        </React.Fragment>
    )
});

export default EditLibraryButton;