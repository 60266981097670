/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Comments Component
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       1st May 2022

*******************************************************************************************/
import React                from 'react';
import { NoticeBlock }      from 'components';
import { useTranslation }   from 'contexts';

export const CommentsNotPublic = ({children, ...props}) => {
    const {t} = useTranslation();
    return (
        <NoticeBlock title = {t('components.comments.comments')} {...props}>
            {children ||  t('components.comments.commentsDisabled')}
        </NoticeBlock>
    )
}

export default CommentsNotPublic;