/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Skeleton
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       20th November 2020

*******************************************************************************************/

import React                            from 'react';
import { styled, Box }                  from '@mui/material';
import { Skeleton }                     from '@mui/material';
import { ContainerHorizontal }          from './components';

const Root = styled(Box)(({theme}) => ({
    textAlign       : 'center',
    width           : '100%',
    height          : '100%',
    position        : 'relative',
    display         : "flex",
    flexDirection   : "column",
    '& > * + *' : {
        marginTop : theme.spacing(1)
    }
}))

export const SkeletonFancy = ({showCircle = true, circleDiameter = '33%', ...props}) => {
    const args = React.useMemo(() => ({width : '100%', height:'100%'}), []);
    return (
        <Root {...props}>
            {
                showCircle && 
                <ContainerHorizontal flexShrink={1} display="flex">
                    <Box sx={{width:circleDiameter, maxWidth:100, aspectRatio:'1'}}>
                        <Skeleton variant="circular" {...args}/>
                    </Box>
                    <Box flexGrow={1} height="100%">
                        <Skeleton variant="rectangular"{...args}/>
                    </Box>
                </ContainerHorizontal>
            }
            <Box width="100%" flexGrow={1}>
                { 
                    props.children 
                }
                {   
                    !props.children &&
                    <Skeleton variant="rectangular" {...args}/>
                }
            </Box>
        </Root>
    );
};

export default SkeletonFancy;