/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Form FailBox
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       11th June 2021

*******************************************************************************************/
import React                            from 'react';
import { styled, Box }                  from '@mui/material';

const Root = styled(Box,{
    shouldForwardProp : prop => prop !== 'failure' && prop !== 'spacing'
})(({theme, failure = false, spacing = 0.5}) => ({
    width           : `calc(100% + ${theme.spacing(2 * spacing)})`,
    height          : '100%',
    padding         : theme.spacing(+spacing),
    margin          : theme.spacing(-spacing),
    '& > * + *' : {
        marginTop : theme.spacing(1)
    },
    ...(failure && {
        border          : `1px dashed red`,
        borderRadius    : theme.spacing(+spacing),
        marginTop       : theme.spacing(+spacing)
    })
}))

export const FormFailBox = ({failure = false, ...props}) => <Root failure={failure} {...props} />

export default FormFailBox;