/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Internationalization
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       14th August 2023

NB: Manage Translations using https://crowdin.com/

*******************************************************************************************/
// import React                    from 'react';
import i18nbase                 from 'i18next';
import { initReactI18next }     from 'react-i18next';
import LanguageDetector         from 'i18next-browser-languagedetector';
import en                       from './locales/en.json';
// import fr                       from './locales/fr.json';

const resources = {
    en,
    // fr
};

i18nbase
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        ns              : ['translation'],
        defaultNS       : 'translation', 
        resources       : resources,
        lng             : 'en', // Default language
        fallbackLng     : 'en', // Fallback language
        interpolation : {
            escapeValue: false, // Set to false when using React components
            /*
            format: (value, format, lng) => {
                if (React.isValidElement(value)) {
                    return value; // Return the React component as-is
                }
                // Handle other interpolation values if needed
                return value;
            },
            */
        },
    });

export const i18n = i18nbase;

export default i18n;