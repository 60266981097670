/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Price Component
********************************************************************************************
Includes Strikethrough if set as a discount

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       11th July 2021

*******************************************************************************************/
import React                from 'react';
import { 
    styled, 
    Box,
    Typography
}                           from '@mui/material';
import { Currency }         from 'components';

const Root = styled(Box)(({theme}) => ({
    paddingLeft : theme.spacing(0.5),
    display     : 'inline-block',
    position    : 'relative'
}));

const SaleContainer = styled(Box, {
    shouldForwardProp : prop => prop !== 'sale'
})(({sale = false}) => ({
    ...(sale && {
        fontSize : '0.75em'
    })
}));

const Sale = styled(Box, {
    shouldForwardProp : prop => prop !== 'sale'
})(({sale = false}) => ({
    ...(sale && {
        position        : 'absolute',
        background      : 'red', /*this is the color of the line*/
        opacity         : '.6',
        content         : 'none',
        width           : '110%',
        borderRadius    : '.1rem',
        whiteSpace      : 'nowrap',
        transformOrigin : 'center',
        transform       : 'translateX(-5%) rotate(-12.5deg)',
        height          : '15%', 
        top             : 'calc(50% - 10%)'
    })
}));

const MoneyContainer = styled(Box)({
    display         : 'inline-block',
    fontSize        : 'inherit'
});

const Money = styled(Box)({
    position        : 'relative',
    fontSize        : 'inherit',
});

const UnitContainer = styled(Box)({
    position    : 'absolute',
    top         : 0,
    right       : 0,
    transform   : 'translateY(-100%)',
    marginTop   : '0.1em'
});

const Unit = styled(Typography)({
    paddingLeft     : 2,
    fontSize        : '0.4em',
    color           : 'inherit'
});

export const Price = ({
    price : priceIn, 
    colorless       = false, 
    prefix          = undefined, 
    suffix          = undefined, 
    sale            = false, 
    shrinkOnSale    = true, 
    unit            = "ea"
}) => {

    const price     = React.useMemo(() => priceIn, [priceIn])
    const showSale  = React.useMemo(() => sale && shrinkOnSale, [sale, shrinkOnSale]);

    return (
        <Root>
            <SaleContainer sale = {showSale}>
                <MoneyContainer>
                    <Money>
                        <Currency value={price} colorless={colorless} prefix={prefix} suffix={suffix}/>
                        <Sale sale={sale}/>
                        <Box position='relative'>
                            <UnitContainer>
                                <Unit>
                                    {unit}
                                </Unit>
                            </UnitContainer>
                        </Box>
                    </Money>
                </MoneyContainer>
            </SaleContainer>
        </Root>
    )
}

export default Price;