
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Make Payment
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       15th May 2021

*******************************************************************************************/
import React                            from 'react';
import {Box}                            from '@mui/material';
import {
    MakePaymentButton, 
    DraggableDialog,
    NoDefaultPaymentMethod
}                                       from 'components';
import PaymentMethod                    from 'components/modals/PaymentMethod';
import {
    PaymentForm 
}                                       from 'components/forms';
import { 
    usePaymentMethod,
    usePayment,
    useLocale,
    useNetwork
}                                       from 'contexts';
import {FORM_ERROR}                     from 'hooks';
import { withTranslation }              from './hoc';

const noop = () => {}

export const MakePayment = withTranslation( ({
    t,
    children,
    disabled : disabledIn       = false,
    hideButton                  = false, 
    invoiceId                   = undefined,
    orderId                     = undefined,
    isTip                       = false,
    open                        = false,
    amount                      = '50.00',
    amountMinimum               = undefined,
    fixed                       = false,
    title                       = undefined,
    allowChangeDefault          = true,
    multipleAttempts            = true,
    currency    : currencyCode  = undefined,
    onClick     : handleClick   = noop,
    onClose     : handleClose   = noop,
    onCancel    : handleCancel  = noop,
    onSuccess   : handleSuccess = noop,
    onFailure   : handleFailure = noop,
    presets                     = undefined,
    ...props
}) => {

    // Locale Context
    const {currency} = useLocale();

    if(!currency)
        throw new Error(t('components.makePayment.currencyArgumentRequired'));
    if(currency !== currencyCode)
        throw new Error(t('components.makePayment.paymentMustBeCurrency',{currency}));

    // Payment Method Context
    const {hasDefault, loading, quantity, createMethod} = usePaymentMethod();

    // Network Context
    const {isNetworkReady}                              = useNetwork();

    // Payment Context
    const {chargeStripe, message}                       = usePayment();

    // State Variables
    const [submitting,          setSubmitting]          = React.useState(false);
    const [submitted,           setSubmitted]           = React.useState(false);
    const [openNoDefault,       setOpenNoDefault]       = React.useState(!hasDefault && open);
    const [openPayment,         setOpenPayment]         = React.useState(hasDefault && open);
    const [openAddPaymentMethod,setOpenAddPaymentMethod]= React.useState(false);

    // Open/Close not default modal
    const handleOpenNoDefaultModal                      = React.useCallback(() => setOpenNoDefault(true), []);
    const handleCloseNoDefaultModal                     = React.useCallback(() => setOpenNoDefault(false), []);

    // Handle Open add payment method
    const handleOpenPaymentMethod                       = React.useCallback( () => { 
        handleCloseNoDefaultModal(); 
        setOpenAddPaymentMethod(true); 
    }, [handleCloseNoDefaultModal])

    // Handle close add payment method
    const handleClosePaymentMethod                      = React.useCallback(() => {
        setOpenAddPaymentMethod(false);
    },[])

    // Handle Close Take Payment Modal
    const handleCloseMakePaymentModal                   = React.useCallback(() => {

        // Prevent closing when it is in the middle of submitting
        if(submitting) 
            return;

        // Close this modal
        setOpenPayment(false);

        // Trigger close callback
        handleClose(); 

    },[submitting, handleClose])

    // Handle Cancel Take Payment
    const handleCancelMakePaymentModal                  = React.useCallback(() => {
        if(submitting) 
            return;

        // Close this modal
        setOpenPayment(false);

        // Trigger cancel callback
        handleCancel(); 

    },[submitting, handleCancel])

    // Opent The payment modal
    const handleOpenMakePaymentModal                    = React.useCallback(() => setOpenPayment(true), []);
    
    // When component mounds, set submitting to false
    React.useEffect(() => setSubmitting(false), [])

    // Close no default modal if payment methods come into existence
    React.useEffect(()=>{
        if(quantity > 0)
            handleCloseNoDefaultModal();
    },[handleCloseNoDefaultModal, quantity])

    // Process Successful Payment
    const processSuccess = React.useCallback( () => { 
        setTimeout(()=>{
            handleCloseMakePaymentModal();
            handleSuccess();
        },1000); 
    }, [handleCloseMakePaymentModal, handleSuccess]);

    // Close modals if goes offline
    React.useEffect(()=>{
        if(!isNetworkReady){
            handleCloseNoDefaultModal();
            handleCloseMakePaymentModal();
        }
    },[handleCloseMakePaymentModal, handleCloseNoDefaultModal, isNetworkReady])

    // Process Failure
    const processFailure = React.useCallback( () => {
        if(!multipleAttempts)
            setTimeout(handleCloseMakePaymentModal, 1000)
        setTimeout(handleFailure,1000)
    }, [handleCloseMakePaymentModal, handleFailure, multipleAttempts]);

    // Start Payment Process
    const handleMakePayment = React.useCallback( (event) => {
        event.stopPropagation();
        hasDefault || quantity || !allowChangeDefault ? 
            handleOpenMakePaymentModal() : 
            handleOpenNoDefaultModal();
        handleClick();
    }, [allowChangeDefault, handleClick, handleOpenMakePaymentModal, handleOpenNoDefaultModal, hasDefault, quantity]);

    // Create new Method
    const handleCreateMethod = React.useCallback( ({paymentMethod, isDefault}) => {
        handleClosePaymentMethod()
        createMethod({type : 'STRIPE', paymentMethod, isDefault})
            .then(handleOpenMakePaymentModal)
            .catch(handleOpenNoDefaultModal)
    }, [createMethod, handleClosePaymentMethod, handleOpenMakePaymentModal, handleOpenNoDefaultModal]);

    // Take Payment
    const handleTakePayment = React.useCallback( ({paymentMethod, amount, currency}) => new Promise( resolve => {
        setSubmitting(true);
        chargeStripe({
            invoiceId, 
            paymentMethodId : paymentMethod._id, 
            amount, 
            currency,
            orderId,
            isTip
        })
            .then(processSuccess)
            .then(() => {
                setSubmitted(true);
                resolve({})
            })
            .catch(({message, errors}) => {
                processFailure();
                resolve({[FORM_ERROR] : message || t('components.makePayment.errorCapturingPayment'), ...errors});
            })
            .finally(()=>{
                setSubmitting(false);
            })
    }), [t, chargeStripe, invoiceId, orderId, processFailure, processSuccess, isTip]);

    // Should be disabled
    const disabled = React.useMemo(() => disabledIn || !isNetworkReady || open, [disabledIn, isNetworkReady, open]);

    // Form data
    const formData = React.useMemo(() => ({amount, currency}), [amount, currency]);

    // Checks References
    if(!invoiceId && (!(isTip && orderId)))
        return null;

    // Checks amount
    if(!isNaN(amount) && amount <= 0)
        return null;
    
    // Done, Render
    return (
        <React.Fragment>
            {
                !hideButton && 
                <Box>
                    {
                        props.render && 
                        props.render({...props, handleMakePayment, disabled})
                    }
                    {
                        !props.render && !open &&
                        <MakePaymentButton 
                            children    = {children}
                            size        = "small" 
                            {...props}
                            disabled    = {disabled} 
                            onClick     = {handleMakePayment} 
                            sx          = {{ 
                                width   : '100%', 
                                mt      : 1,
                                ...props?.sx
                            }}
                        />
                    }
                </Box>
            }
            {
                (!loading && quantity <= 0 && allowChangeDefault) && (openNoDefault || open) &&
                <DraggableDialog 
                    title       = {
                        quantity > 0 
                            ? t('components.makePayment.noDefaultPaymentMethod')
                            : t('components.makePayment.noMethod')
                    }
                    maxWidth    = {'xs'} 
                    open        = {(openNoDefault || open) && isNetworkReady} 
                    showButtons = {false} 
                    onOk        = {handleCloseNoDefaultModal} 
                    onCancel    = {handleCloseNoDefaultModal} 
                    onClose     = {handleCloseNoDefaultModal}
                >
                    <NoDefaultPaymentMethod 
                        onClick = {handleOpenPaymentMethod}
                    />
                </DraggableDialog>
            }

            {
                (!loading && (quantity > 0 || !allowChangeDefault)) && (openPayment || open) && 
                <DraggableDialog 
                    showButtons = {false} 
                    title       = {title || t('components.makePayment.makePayment')} 
                    maxWidth    = "sm" 
                    open        = {(open || openPayment) && isNetworkReady} 
                    onClose     = {handleCloseMakePaymentModal} 
                    fullWidth   = {true}
                >
                    <PaymentForm 
                        fixed               = {fixed}
                        disabled            = {submitting || submitted}
                        submittingMessage   = {message || t('components.makePayment.authorizing')}
                        formData            = {formData} 
                        allowChangeDefault  = {allowChangeDefault}
                        amountMinimum       = {amountMinimum}
                        onSubmit            = {handleTakePayment} 
                        onCancel            = {handleCancelMakePaymentModal}
                        presets             = {presets}
                    />
                </DraggableDialog>
            }

            {
                !loading && openAddPaymentMethod &&
                <PaymentMethod 
                    open        = {openAddPaymentMethod && isNetworkReady}
                    onOk        = {handleCreateMethod} 
                    onCancel    = {handleClosePaymentMethod}
                />
            }

        </React.Fragment>
    )
});

export default MakePayment;