/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Cart Header
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       9th July 2021

*******************************************************************************************/
import React                            from 'react';
import { Box }                          from '@mui/material';
import {
    Search as SearchIcon,
    Delete as DeleteIcon
}                                       from '@mui/icons-material';
import { useHistory }                   from 'react-router-dom';
import { ShopLocation }                 from 'router/locations/Locations';
import { 
    Title, 
    RemoveWithConfirmationButton as RemoveButton,
    Button
}                                       from 'components';
import { withTranslation }              from './hoc';
import {
    useCart,
    useNetwork
}                                       from 'contexts';

// Shopping Cart
export const CartHeader = withTranslation( ({
    t,
    title               = undefined,
    readOnly            = false,
    actions             = undefined,
    showClearCart       = true,
    showBrowse          = true
}) => {
    
    const history           = useHistory();
    const {isNetworkReady}  = useNetwork();
    const { 
        working,
        hasItems,
        clearCart, 
    }                       = useCart();
    const handleBrowse      = React.useCallback( () => history.push(ShopLocation.path), [history]);

    return (
        <Box display="flex" pb={1}>
            <Box flexGrow={1}>
                <Title>
                    {title || t('common.cart')}
                </Title>
            </Box>
            {   isNetworkReady && 
                <Box>
                    {actions}

                    {
                        hasItems && !readOnly &&
                        <React.Fragment>
                            {
                                showBrowse && 
                                <Button disabled={working} startIcon={<SearchIcon/>} onClick={handleBrowse} variant="contained" color="primary" style={{minWidth:140, marginLeft:8}}> 
                                    {t('common.browseShop')}
                                </Button>
                            }
                            {
                                showClearCart &&
                                <RemoveButton 
                                    disabled    = {working} 
                                    startIcon   = {<DeleteIcon/>} 
                                    onClick     = {clearCart} 
                                    variant     = "contained" 
                                    color       = "primary" 
                                    sx          = {{
                                        '&.MuiButton-root' : {
                                            color : theme => theme.palette.primary.contrastText
                                        }
                                    }}
                                    style       = {{minWidth:140, marginLeft:8}}
                                > 
                                    {t('common.clearCart')}
                                </RemoveButton>
                            } 
                        </React.Fragment>
                    }
                </Box>
            }
        </Box>
    )
});

export default CartHeader;