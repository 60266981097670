/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Pages - History
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       15th May 2021

*******************************************************************************************/

import React                    from 'react';
import { 
    styled,
    Box,
    Grid, 
    Typography 
}                               from '@mui/material';
import { withTranslation }      from './hoc';

const TextContainer = styled(Box)(({theme}) => ({
    paddingLeft     : theme.spacing(1),
    paddingRight    : theme.spacing(1)
}))

const GridItem = styled(Grid,{
    shouldForwardProp : prop => !['first','last'].includes(prop)
})(({first=false,last=false}) => ({
    ...(first && {
        textAlign :'left'
    }),
    ...(last && {
        textAlign : 'right'
    })
}))

export const AccountSummary = withTranslation( ({
    t,
    credits         = 0,
    debits          = 0,
    net             = 0,
    creditsQuantity = 0,
    debitsQuantity  = 0,
} = {}) => {
    
    const data = React.useMemo(() => ([
        { title : t('components.accountSummary.chargesQuantity', { quantity : creditsQuantity }),   value : credits },
        { title : t('components.accountSummary.refundsQuantity', { quantity : debitsQuantity }),    value : debits },
        { title : t('components.accountSummary.netCharges'),                                        value : net }
    ]), [t, credits, creditsQuantity, debits, debitsQuantity, net ]);

    return (    
        <Grid container spacing={0}>
            {
                data.map(({title, value}, ix) => (
                    <GridItem key={ix} item xs={4} sm={4} align="center" first={ix === 0} last={ix >= data.length - 1} >
                        <TextContainer id="textcontainer">
                            <Typography>              
                                {title} 
                            </Typography>
                            <Typography variant="h5"> 
                                {value} 
                            </Typography>
                        </TextContainer>
                    </GridItem>
                ))
            }
        </Grid>
    );
});

export default AccountSummary;