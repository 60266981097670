/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Cart Icon with Quantity Badge
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       24th July 2021

*******************************************************************************************/
import React                                from 'react';
import { Badge }                            from '@mui/material';
import { ShoppingCart as Icon }             from '@mui/icons-material';

const obj = {};

export const CartIconWithQuantity = ({ quantity = 0, iconProps = obj, ...props }) => (
    <Badge 
        overlap         = "rectangular" 
        invisible       = {quantity <= 0} 
        badgeContent    = {<span>{quantity}</span>} 
        anchorOrigin    = {{
            vertical    : 'bottom', 
            horizontal  : 'right'
        }}
        {...props}
    >
        <Icon {...iconProps}/>
    </Badge>
)

export default CartIconWithQuantity;