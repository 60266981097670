/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
WidgetStepper
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       1st June 2022

*******************************************************************************************/
import React                from 'react';
import { Title }            from 'components';
import {
    useMediaQuery, 
    Box,
    Typography,
    Stepper,
    Step,
    StepLabel
}                           from '@mui/material';
import SectionContainer     from '../components/SectionContainer';

export const WidgetStepper = ({data}) => {
    const vertical      = useMediaQuery(theme => theme.breakpoints.down('md'));
    const orientation   = React.useMemo(() => vertical ? 'vertical' : 'horizontal', [vertical]);
    return (
        <SectionContainer>
            <Title variant="h2" gutterBottom>
                {data?.fields?.title}
            </Title>
            <Box>
                {
                    !vertical &&
                    <Box p={2} pb={2}>
                        <Typography variant="h5">
                            <em>{data?.fields?.subtitle}</em>
                        </Typography>
                    </Box>
                }
                <Box align="center" pt={2} pb={2}>
                    <Stepper alternativeLabel activeStep={(data?.fields?.stepperEntries || []).length} orientation={orientation} style={{background:'none'}}>
                        {
                            (data?.fields?.stepperEntries || []).map(({fields:{title,subtitle}},ix) => (
                                <Step key={ix} style={{width:'100%'}}>
                                    <StepLabel optional={
                                        <Box align="center">
                                            <Typography align="center" style={{maxWidth:300}} gutterBottom>
                                                {subtitle}
                                            </Typography>
                                        </Box>
                                    }>
                                        <Title>
                                            {ix+1}. {title}
                                        </Title>
                                    </StepLabel>
                                </Step>
                            ))
                        }
                    </Stepper>
                </Box>
            </Box>
        </SectionContainer>
    );
}

export default WidgetStepper;