
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Comment Context
********************************************************************************************
Boilerplate context, consumer, provider and hook

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       13th July 2022

*******************************************************************************************/
import React                    from 'react';
import { useStateEphemeral }    from 'hooks';
import { Mutex, Semaphore }     from 'async-mutex';

// The Product Context 
const CommentContext = React.createContext(undefined);

// Comment Provider
const CommentProvider = ({children, maxQueries = 3}) => {

    const [remaining,   setRemaining]   = React.useState(0);
    const [canPost,     setCanPost]     = useStateEphemeral(true, 5000,  true, setRemaining);
    const markPosted                    = React.useCallback( () => setCanPost(false), [setCanPost]);
    const mutex                         = React.useMemo(() => new Mutex(), []);
    const semaphore                     = React.useMemo(() => new Semaphore(maxQueries), [maxQueries]);

    // Context values
    const value = React.useMemo(() => ({
        mutex,
        semaphore,
        canPost,    
        markPosted,
        remaining,
        maxQueries
    }),[mutex, semaphore, canPost, markPosted, remaining, maxQueries]);

    return (
        <CommentContext.Provider value={value}>
            {children}
        </CommentContext.Provider>
    )
}

// Comment Consumer
const CommentConsumer = ({children}) => {
    return (
        <CommentContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('CommentConsumer must be used within CommentProvider');
                }
                return children(context)
            }}
        </CommentContext.Consumer>
    )
}

// useComment Hook
const useComment = () => {
    const context = React.useContext(CommentContext);
    if(context === undefined)
        throw new Error('useComment must be used within CommentProvider');
    return context;
}

export {
    CommentProvider,
    CommentConsumer,
    useComment
}