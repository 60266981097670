/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Order
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com 
Date:       27th July 2021

*******************************************************************************************/
import React                            from 'react';
import { 
    styled, 
    Box, 
    Grid 
}                                       from '@mui/material';
import {
    RootContainer,
    Title,
    SkeletonFancyWaitingForNetwork,
}                                       from 'components';
import { withTranslation }              from 'components/hoc';
import NotFound                         from 'components/errors/NotFound';
import { 
    useOrderViewer,
    usePageHeight,
    TrackIdProvider,
    useTrackId
}                                       from 'contexts';
import {
    InvoicesProvider
}                                       from './context';        
import OrderTable                       from './OrderTable';
import OrderPaymentHistory              from './OrderPaymentHistory';
import OrderTransactionTiles            from './OrderTransactionTiles';
import OrderProgress                    from './OrderProgress';
import OrderProgressSummary             from './OrderProgressSummary';
import OrderUserInputAlert              from './OrderUserInputAlert';
import OrderTaxInvoice                  from './OrderTaxInvoice';
import OrderTips                        from './OrderTips';
import OrderTickets                     from './OrderTickets';
import OrderPaymentCallToAction         from './OrderPaymentCallToAction';

const withContent = Component => styled(Component, {
    shouldForwardProp : (prop) => prop !== 'sticky'
})(({theme, sticky = false}) => {
    const stylesSticky = sticky 
        ? { position : 'sticky', top : theme.spacing(2) } 
        : {}
    return {
        ...stylesSticky,
        '& > * + *' : {
            marginTop : theme.spacing(2)
        }
    }
});

const BoxContent = withContent(Box);

const OrderBase = withTranslation( ({
    t, 
    orderId : orderIdIn = undefined, 
    title               = false, 
}) => {
    const {heightInner}                                 = usePageHeight();
    const {trackId}                                     = useTrackId();
    const {
        queried,
        data : order,
        orderId,
        setOrderId
    }                                                   = useOrderViewer();
    const sideBarBox                                    = React.useRef();

    // Retrieve the Data from the orders context
    React.useEffect(() => {
        setOrderId(orderIdIn);
        return () => {
            setOrderId(undefined);
        }
    }, [orderIdIn, setOrderId])

    // No Order
    if(!orderId)
        return null;

    return (
        <RootContainer id="order">
            {
                !queried && 
                <Box style={{height:heightInner}}>
                    <SkeletonFancyWaitingForNetwork />
                </Box>
            }
            { 
                (queried && !order) &&
                <NotFound>
                    { t('components.order.order.error404') }
                </NotFound>
            }
            {
                (queried && order) &&
                <React.Fragment>
                    {   
                        title && 
                        <Title> 
                            { t('components.order.order.orderSheet') }
                        </Title>
                    }
                    <OrderUserInputAlert />
                    <OrderTransactionTiles />
                    <Box mt={2}>
                        <Grid container direction="row-reverse">
                            <Grid component={RootContainer} item xs={12} md={8}>
                                <OrderPaymentCallToAction />
                                <OrderProgressSummary orderId={orderId}/>
                                {
                                    order?.shouldProcess &&
                                    <OrderProgress orderId={orderId} quickLinks={true}/>
                                }
                                {
                                    order?.shouldProcess &&
                                    <OrderProgress trackId={trackId} orderId={orderId}/>
                                }
                                <OrderTable orderId={orderId}/>
                                <OrderTickets orderId={orderIdIn}/>
                            </Grid>
                            <Grid item xs={12} md={4} ref={sideBarBox}>
                                <BoxContent sticky={true} >
                                    <OrderTips />
                                    <OrderPaymentHistory />
                                    <OrderTaxInvoice />
                                </BoxContent>
                            </Grid>
                        </Grid>
                    </Box>
                </React.Fragment>
            }

        </RootContainer>
    )
});

export const Order = ({...props}) => (
    <TrackIdProvider>
        <InvoicesProvider>
            <OrderBase {...props}/>
        </InvoicesProvider>
    </TrackIdProvider>
)

export default Order;