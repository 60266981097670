/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Not Found Component
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       30th May 2022

*******************************************************************************************/
import React                    from 'react';
import { 
    alpha, 
    useTheme,
    Box, 
    Typography 
}                               from '@mui/material';
import {Title, RootContainer}   from 'components';
import { useTranslation }       from 'contexts';


const FeatureBox = ({children}) => {
    const theme = useTheme();
    const color = React.useMemo(() => theme.palette.mode === 'dark' ? theme.palette.error.main : theme.palette.primary.main, [theme])
    return (
        <Box sx={{minWidth:'30%', p:2, m:2, borderRadius : '0px 16px', border : `1px solid ${color}`, bgcolor : alpha(color,0.05)}}>
            {children}
        </Box>
    )
}

export const NotFound = ({children, ...props}) => {
    const [t] = useTranslation();
    return (
        <RootContainer id="error404" {...props} display="flex" flexDirection="column" sx={{height:'100%'}}>
            <Box flexBasis="25%"/>
            <Box display="flex">
                <Box flexGrow={1}/>
                <FeatureBox>
                    <Title style={{fontSize:'4rem'}}>
                        { t('components.errors.notFound.title') }
                    </Title>
                    <Typography gutterBottom sx={{fontWeight:800}}>
                        { t('components.errors.notFound.description') }
                    </Typography>
                    {children &&
                        <Typography component="div">
                            {children}
                        </Typography>
                    }
                </FeatureBox>
                <Box flexGrow={1}/>
            </Box>
            <Box flexBasis="75%"/>
        </RootContainer>
    )
}

export default NotFound;