import React from 'react';
import config from './config';

// Make sure to only include the library in development
if (config.development) {
    const whyDidYouRender = require("@welldone-software/why-did-you-render");
    whyDidYouRender(
        React, 
        { trackAllPureComponents: true }
    );
}
  