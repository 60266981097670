/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
useFileUploader Hook
********************************************************************************************

gets the presigned upload params for uploading a file of a give filename

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       30th July 2022

*******************************************************************************************/
import React                            from 'react';
import moment                           from 'moment';
import {useNetwork}                     from 'contexts';
import crypto                           from 'crypto-browserify'
import { useCancelToken }               from 'hooks';

export const useFileUploader = () => {

    const {axios}           = useNetwork();
    const {cancelToken}     = useCancelToken();
    const getRandomFilename = React.useCallback( () =>	crypto.randomBytes(16).toString("hex"), []);

    // specify upload params and url for your files
    const getSignedParams   = React.useCallback( ({fileName, group = undefined, contentType = undefined, maxSizeBytes = 10e6} = {}) => new Promise((resolve,reject) => {
        
        // if(!/^([0-9]|[A-Z]|[a-z]|[!\-_.*'()])+$/i.test(fileName))
        //     return reject(new Error('invalid filename, must contain alphanumeric only'));

        if(!fileName || typeof fileName !== 'string')
            return reject(new Error('fileName argument is required, and must be a string'));
        if(!contentType || typeof contentType !== 'string')
            return reject(new Error('contentType argument is required, and must be a string'));

        const todayString   = moment.utc().format('YYYY-MM-DD');
        const entropy       = getRandomFilename();
        const key           = `uploads/${todayString}/${group || entropy}/${fileName}`;
        axios.post('/api/dropbox', {key, contentType, maxSizeBytes}, {cancelToken})
            .then(({data}) => data)
            .then(({url, fileUrl, fields}) => {
                resolve({ 
                    fields, 
                    meta : { fileUrl }, // Upload URL 
                    url,
                }) 
            })
            .catch(err => {
                console.error(err);
                reject(err);
            });
    }), [axios, cancelToken, getRandomFilename]);

    return {
        getSignedParams
    }
}

export default useFileUploader;