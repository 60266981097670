/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Remove with Confirmation Button
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       29th June 2021

*******************************************************************************************/
import React                            from 'react';
import { Delete as DeleteIcon }         from '@mui/icons-material';
import { ConfirmationButton }           from 'components';

const noop = () => {}


// Component to remove item from cart
export const RemoveWithConfirmationButton = ({
    children,
    variant                                                 = "text",
    onClick                 : handleClick                   = noop,
    onConfirmationRequired  : handleConfirmationRequired    = noop,
    confirmationText                                        = undefined,
    ...props
}) => (
    <ConfirmationButton 
        decimals                = {0}
        updateFrequency         = {10}
        variant                 = {variant}
        startIcon               = {<DeleteIcon/>} 
        onClick                 = {handleClick} 
        onConfirmationRequired  = {handleConfirmationRequired}
        confirmationText        = {confirmationText}
        requireConfirmation     = {false}
        {...props}
    >
        {children || 'Remove'}
    </ConfirmationButton>
)

export default RemoveWithConfirmationButton;