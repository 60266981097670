/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Horoscope Component
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       17th April 2023

*******************************************************************************************/
import React                            from 'react';
import { useQueryHoroscopeData }        from 'hooks';

const noop = () => {}

export const HoroscopeComponent = ({birthDateTime, localTime, lat, lng, houseSystem = "auto", zodiac = "tropical", onChange : handleChange = noop, render = noop}) => {
    
    const { horoscopeData, working, queried } = useQueryHoroscopeData({birthDateTime, localTime, houseSystem, zodiac, lat, lng});

    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(() => handleChange(horoscopeData), [handleChange, JSON.stringify(horoscopeData) ])

    return (
        render({
            horoscopeData, 
            working, 
            queried
        })
    )
}

export default HoroscopeComponent;