
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Logo
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       22nd December 2020

*******************************************************************************************/
import React            from 'react';
import { Box }          from '@mui/system';  
import { HomeLocation } from 'router/locations';
import Logo             from './Logo';

export const LogoMonogram = ({to = HomeLocation.path, dark=true, ...props}) => (    
    <Box sx={{maxWidth:150, margin:'auto', ...props.sx}}>
        <Logo to={to} dark={dark} {...props}/>
    </Box>
);

export default LogoMonogram;