/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Sort Order Form
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       16th August 2022

*******************************************************************************************/
import React                            from 'react';
import { MenuItem, Grid }               from '@mui/material';
import { Form }                         from 'components';
import { useTranslation }               from 'contexts';
import { 
    showErrorOnChange   as showError,
    Select
}                                       from 'mui-rff';

const noop = () => {}

const optionsSortDefault = {
    createdAt   : {translationKey:"components.forms.sortOrderForm.createdAt"},
    updatedAt   : {translationKey:"components.forms.sortOrderForm.updatedAt"}
}
const optionsSortOrderDefault = {
    ascending   : {translationKey:"components.forms.sortOrderForm.ascending"},
    descending  : {translationKey:"components.forms.sortOrderForm.descending"}
}
const formDataDefault = {
    sort        : undefined,
    sortOrder   : undefined
}
const formPropsDefault = {}

export const SortOrderForm = ({
    formData                = formDataDefault,
    optionsSort             = optionsSortDefault,
    optionsSortOrder        = optionsSortOrderDefault,
    disabled                = false,
    onSubmit : handleSubmit = noop,
    onCancel : handleCancel = noop,
    onChange : handleChange = noop,
    FormProps               = formPropsDefault,
    ...props
}) => {
    const {t}           = useTranslation();
    const validate      = React.useCallback( (values) => {
        let errors = {};
        ['sort','sortOrder'].forEach((key) => {
            if(!values[key])
                errors[key] = errors[key] || t('components.forms.sortOrderForm.required');
        })
        // Check Sort is Valid
        if(!Object.keys(optionsSort).includes(values.sort))
            errors.sort = errors.sort || t('components.forms.sortOrderForm.invalidSelection');

        // Check Sort Order is Valid
        if(!Object.keys(optionsSortOrder).includes(values.sortOrder))
            errors.sortOrder = errors.sortOrder || t('components.forms.sortOrderForm.invalidSelection');

        // Done
        return errors;

    }, [t, optionsSort, optionsSortOrder]);

    const initialValues = React.useMemo(() => ({
        ...formData
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [JSON.stringify(formData)])

    return (
        <Form
            debug           = {false}
            disabled        = {disabled}
            onSubmit        = {handleSubmit}
            onCancel        = {handleCancel}
            onChange        = {handleChange}
            initialValues   = {initialValues}
            validate        = {validate}
            {...FormProps}
            render          = {({disabled, form, error, dirtySinceLastSubmit, submitFailed, submitSucceeded, errors, handleSubmit, values, ...rest}) => {
                return (
                    <form onSubmit={handleSubmit} noValidate>
                        <Grid container>
                            <Grid item xs={7}>
                                <Select 
                                    name        = "sort" 
                                    label       = { t('components.forms.sortOrderForm.sortBy') }
                                    disabled    = {disabled}
                                    showError   = {showError}
                                >
                                    {
                                        Object
                                            .entries(optionsSort)
                                            .map(([key,value], ix) => {
                                                const {translationKey, label} = value
                                                return (
                                                    <MenuItem key={ix} value={key}>
                                                        { ( translationKey ? t(translationKey) : label ) || value }
                                                    </MenuItem>
                                                )
                                            })
                                    }
                                </Select>
                            </Grid>
                            <Grid item xs={5}>
                                <Select 
                                    name        = "sortOrder" 
                                    label       = { t('components.forms.sortOrderForm.direction') }
                                    disabled    = {disabled}
                                    showError   = {showError}
                                >
                                    {
                                        Object
                                            .entries(optionsSortOrder)
                                            .map(([key,value], ix) => {
                                                const {translationKey, label} = value
                                                return (
                                                    <MenuItem key={ix} value={key}>
                                                        { ( translationKey ? t(translationKey) : label ) || value }
                                                    </MenuItem>
                                                )
                                            })
                                    }
                                </Select>
                            </Grid>
                        </Grid>
                    </form>
                )
            }}
        />
    )
}

export default SortOrderForm;