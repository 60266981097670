/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Cart Icon with Quantity Badge
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       24th July 2021

*******************************************************************************************/
import React                                from 'react';
import { 
    styled,
    Badge as BadgeMUI
}                                           from '@mui/material';
import Icon                                 from '@mui/icons-material/Notifications';

const Badge = styled(BadgeMUI)(({theme}) => ({
    '&.MuiBadge-badge' : {
        color : theme.palette.secondary.main
    }
}))

const obj = {};

export const NotificationsIconWithQuantity = ({ 
    quantity    = 0, 
    color       = 'inherit', 
    iconProps   = obj, 
    ...props 
}) => (
    <Badge 
        overlap         = "rectangular" 
        invisible       = {quantity <= 0} 
        badgeContent    = {
            <span>
                {quantity}
            </span>
        } 
        {...props}
        anchorOrigin    = {{
            vertical    : 'bottom', 
            horizontal  : 'right',
            ...props.anchorOrigin
        }}
    >
        <Icon color={color} {...iconProps}/>
    </Badge>
)

export default NotificationsIconWithQuantity;