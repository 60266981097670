/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Image Downloader
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       1st August 2023

*******************************************************************************************/
import React                        from 'react';
import isFunction                   from 'lodash/isFunction';
import CloudDownloadIcon            from '@mui/icons-material/CloudDownload';
import { Button, RefreshIcon}       from 'components';
import { useAlert }                 from 'contexts';
import { withTranslation }          from './hoc';

const noop = () => {};

export const ImageDownloader = withTranslation( ({
    t, 
    children, 
    imageUrl, 
    fileName    = undefined, 
    render      = noop, 
    ...props
}) => {
    const [downloading, setDownloading] = React.useState(false);
    const {alert}                       = useAlert();
    const download                      = React.useMemo(() => {
        const fallback = 'image.png';
        try {
            return fileName || imageUrl.split("/").pop().split("?")[0] || fallback;
        } catch (err) {
            // Silent
        }
        return fallback;
    },[imageUrl, fileName])

    const handleDownload = React.useCallback( async () => {
        setDownloading(true);
        try {
            const response  = await fetch(imageUrl);
            const blob      = await response.blob();
            const url       = URL.createObjectURL(blob);
            const link      = document.createElement('a');
            link.href       = url;
            link.download   = download;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            const msg = t('components.imageDownloader.errorDownloadingImage');
            console.error(msg, error);
            alert(msg, 'error');
        } finally {
            setDownloading(false);
        }
    }, [t, alert, download, imageUrl]);

    // Render Method
    if(isFunction(render) && render !== noop){
        return (
            render({
                downloading, 
                imageUrl, 
                fileName    : download, 
                handleDownload
            })
        )
    }
  
    // Default Componnent
    return (
        <Button
            variant     = "contained"
            color       = "primary"
            {...props}
            disabled    = {downloading}
            onClick     = {handleDownload}
            startIcon   = {
                downloading 
                    ? <RefreshIcon loading={true}/> 
                    : <CloudDownloadIcon/>
            } 
        >
            {
                downloading 
                    ? t('components.imageDownloader.downloading') 
                    : (children || t('components.imageDownloader.downloadImage'))
            }
        </Button>
    );
});

export default ImageDownloader;