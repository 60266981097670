/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Admin Menu
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       3rd July 2022

*******************************************************************************************/
import React                            from 'react';
import Menu                             from './Menu';
import usePages                         from './usePages';
import FatMenu                          from './FatMenu';
import { 
    useUser,
    useTranslation,
}                                       from 'contexts';
import { AdministrationTitle }          from './Titles';

export const AdminMenu = (props) => {
    
    const [t]                               = useTranslation();
    const {
        isAuthenticated, 
        isAdmin, 
        isSuspended
    }                                       = useUser();
    const {adminPages}                      = usePages();

    // Check User is Valid
    const userValid                         = React.useMemo(() => (
        isAuthenticated && isAdmin && !isSuspended
    ), [isAdmin, isAuthenticated, isSuspended]);

    if(!userValid) 
        return null;

    return (
        <Menu 
            showArrow   = {true} 
            label       = {t('common.admin')} 
        >
            <AdministrationTitle />
            <FatMenu 
                pages   = {adminPages.filter(x => !x?.index)}
                columns = {3} 
            />
        </Menu>
    )
}

export default AdminMenu;