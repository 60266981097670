/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Translation Provider
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       20th July 2021

*******************************************************************************************/
import React                                from 'react';
import {
    I18nextProvider, 
    I18nContext,
    useTranslation
}                                           from "react-i18next";

import i18                                  from "../i18n";

/*
import LanguageDetector                     from 'i18next-browser-languagedetector';
import i18                                  from "i18next";
import en                                   from "../translations/en/common.json";
import de                                   from "../translations/de/common.json";

const resources = {en,de};

i18
    .use(LanguageDetector)
    .init({
        interpolation   : { 
            escapeValue: false      // React already does escaping
        },   
        fallbackLng     : 'en',
        lng             : 'en',     // language to use
        resources
    });
*/

const TranslationProvider = ({children}) => {
    return (
        <I18nextProvider i18n={i18}>
            {children}
        </I18nextProvider>
    )
}

const TranslationConsumer =  ({children}) => {
    return (
        <I18nContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('TranslationConsumer must be used within TranslationProvider');
                }
                return children(context)
            }}
        </I18nContext.Consumer>
    )
}

export {
    TranslationProvider,
    TranslationConsumer,
    useTranslation
}