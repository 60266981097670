/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Expand Collapse Icons
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       28th July 2021

*******************************************************************************************/
import React                            from 'react';
import {Tooltip}                        from '@mui/material';
import ArrowRightIcon                   from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon                from '@mui/icons-material/ArrowDropDown';
import { withTranslation }              from './hoc';

const noop = () => {};

export const ExpandCollapse = withTranslation( ({
    t,
    onExpand            = noop,
    onCollapse          = noop,
    expand : expandIn   = false
}) => {
    const [expanded, setExpanded] = React.useState(expandIn);

    const handleExpand = React.useCallback( () => {
        setExpanded(true);
        onExpand();
    }, [onExpand]);

    const handleCollapse = React.useCallback( () => {
        setExpanded(false);
        onCollapse();
    }, [onCollapse]);

    React.useEffect(()=>{
        setExpanded(expandIn);
    },[expandIn])

    return (
        expanded 
            ? (
                <Tooltip title = {t("common.collapse") }>
                    <ArrowDropDownIcon onClick={handleCollapse}/> 
                </Tooltip>
            )
            : (
                <Tooltip title = { t("common.expand") }>
                    <ArrowRightIcon onClick={handleExpand}/>
                </Tooltip>
            )
    )
});

export default ExpandCollapse;