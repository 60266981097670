/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Fat Menu
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       1st August 2022

*******************************************************************************************/
import React                            from 'react';
import { Link }                         from 'react-router-dom';
import { 
    styled,
    Box, 
    Grid, 
    MenuItem,
    ListItemText    as ListItemTextMUI, 
    Badge           as BadgeMUI, 
    ListItemIcon    as ListItemIconMUI
}                                       from '@mui/material';
import { useTranslation }               from 'contexts';

const Badge = styled(BadgeMUI)(({theme}) => ({
    '&.MuiBadge-badge' : {
        right   : 0,
        top     : 0,
        border  : `2px solid ${theme.palette.background.paper}`,
        padding : '0 4px',
    }
}))

const ListItemIcon = styled(ListItemIconMUI,{
    shouldForwardProp : prop => prop !== 'feature'
})(({theme, feature = false}) => ({
    '&.MuiListItemIcon-root' : {
        minWidth : feature ? 80 : 40
    }
}))

const withIconComponent = Component => styled(Component,{
    shouldForwardProp : prop => prop !== 'feature' && prop !== 'iconSize'
})(({theme, feature = false, iconSize = 10}) => ({
    fontSize    : (feature ? 2 : 1) * iconSize,
    color       : theme.palette[theme.palette.mode === 'light' ? 'primary' : 'secondary'].main
}))

const MyMenuItem = ({link, Icon : IconIn, name, feature, iconSize, multicolumn, badge = 0}) => {
    const [t]   = useTranslation();
    const Icon  = React.useMemo(() => IconIn ? withIconComponent(IconIn) : null, [IconIn])

    return (
        <MenuItem color="inherit" component={Link} to={link}>
            {   
                Icon && 
                <ListItemIcon feature={feature}>
                    <Badge overlap="rectangular" color="error" badgeContent={badge}>
                        <Icon feature={feature} iconSize={iconSize} />
                    </Badge>
                </ListItemIcon>
            }
            <ListItemTextMUI 
                primary                     = { t(`${name}.title`)}
                secondary                   = { t(`${name}.description`)}
                primaryTypographyProps      = {{ 
                    noWrap      : false, // Enable wrapping for primary text
                    variant     : "h6", 
                    fontSize    : '0.9rem!important'
                }}
                secondaryTypographyProps    = {{ 
                    variant     : 'caption', 
                    ontSize     : '0.65rem', 
                    whiteSpace  : 'initial', 
                    textAlign   : 'justify'
                }}
            />
        </MenuItem>
    )
}

export const FatMenu = React.forwardRef(({pages, multicolumn=true, iconSize = 30, ...props}, ref) => (
    <Box>
        {
            multicolumn && 
            <Grid spacing={0} container ref={ref} sx={{maxWidth:600}}>
                {
                    pages
                        .filter(x => x.feature)
                        .map((item, ixRow) => (
                            <Grid key={ixRow} item xs={12} sm={multicolumn ? 6 : 12}>
                                <MyMenuItem {...item} feature={true} iconSize = {iconSize} multicolumn = {multicolumn}/>
                            </Grid>
                        ))
                }
            </Grid>
        }

        <Grid spacing={0} container ref={ref} sx={{maxWidth:multicolumn ? 800 : 400}}>
            {
                pages
                    .filter(x => !multicolumn || !x.feature)
                    .map((item,ixRow) => (
                        <Grid key={ixRow} item xs={12} sm={multicolumn ? 6 : 12} md={multicolumn ? 4 : 12}>
                            <MyMenuItem {...item} feature={false} iconSize = {iconSize} multicolumn = {multicolumn}/>
                        </Grid>
                    ))
            } 
        </Grid>
    </Box>
));

export default FatMenu;
