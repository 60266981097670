/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Media Card
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       12th June 2021

*******************************************************************************************/
import React                            from 'react';
import {useHistory, Link}               from 'react-router-dom';
import {isNil}                          from 'lodash';
import {
    styled,
    useTheme, 
    Box, 
    Button, 
    Typography, 
    DialogActions,
    Card            as CardMUI, 
    CardActionArea  as CardActionAreaMUI, 
    CardMedia       as CardMediaMUI, 
    CardContent     as CardContentMUI
}                                       from '@mui/material';
import {LoadingData}                    from 'components';
import {useTranslation}                 from 'contexts';

const obj = {};

const Root = styled(Box)({
    width           : '100%',
    height          : '100%'
});

const Card = styled(CardMUI)({
    display         : 'flex',
    flexDirection   : 'column',
    justifyContent  : 'space-between',
    height          : '100%',
    border          : `1px solid transparent`,
});

const CardActionArea = styled(CardActionAreaMUI)({
    display         : 'flex',
    flexDirection   : 'column',
    flexGrow        : 1,
});

const IconContainer = styled(Box,{
    shouldForwardProp : prop => prop !== 'imageHeight'
})(({imageHeight = 100}) => ({
    textAlign       : 'center',
    margin          : 'auto',
    width           : '100%', 
    height          : 0.6*imageHeight
}));

const CardMedia = styled(CardMediaMUI)({
    overflow        : 'hidden',
    transition      : 'all .2s ease-in-out',
    transform       : 'scale(1.00)',
    "&:hover"       : {
        transform: 'scale(1.10)'
    }
});

const CardContent = styled(CardContentMUI)(({theme}) => ({
    '&.MuiCardContent-root' : {
        background  : theme.palette.background.paper,
        position    :'relative'
    }
}));

const Title = styled(Typography)({
    whiteSpace      : 'nowrap',
    textOverflow    : 'ellipsis',
    width           : '100%'
});

const Description = styled(Typography)({
    fontSize : '0.75rem'
});

export const MediaCard = ({
    href            = undefined,
    image           = undefined,
    icon : Icon     = undefined,
    iconProps       = obj,
    title           = undefined,
    titleActions    = null,
    description     = undefined,
    subdescription  = undefined,
    buttonText      = undefined,
    actions         = undefined,
    showImage       = true,
    imageHeight     = 200,
    actionsHeight   = 0,
    loading         = false,
    onClick         = null,
    onMouseEnter    = null,
    onMouseLeave    = null
}) => {
    
    const theme         = useTheme();
    const [t]           = useTranslation();
    const history       = useHistory();

    const handleClick   = React.useCallback( () => {
        if(href) 
            history.push(href);
    },[history, href]);

    return (
        <Root>
            <Card onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                <CardActionArea onClick={onClick} style={{display:'flex',flexDirection:'column',flexGrow:1}}>
                    {
                        Icon && 
                        <IconContainer onClick = {handleClick} imageHeight={imageHeight}>
                            <Icon 
                                style = {{
                                    fontSize    : 0.6*imageHeight,
                                    padding     : theme.spacing(4)
                                }}
                                {...iconProps}
                            />
                        </IconContainer>
                    }
                    {
                        !Icon && showImage &&
                        <CardMedia
                            onClick     = {handleClick}
                            image       = {image}
                            title       = {typeof title === 'string' ? title : undefined}
                            actions     = {actions}
                            style       = {{...(!isNil(imageHeight) ? {height:imageHeight} : undefined), width:'100%'}}
                        />
                    }
                    {
                        (title || titleActions || description) && 
                        <CardContent onClick={handleClick} style={{width:'100%',flexGrow:1}}>
                            <Box display="flex" flexDirection={"column"}>
                                <Box flexGrow={1} display="flex">
                                    <Box flexGrow={1}>
                                        <Title gutterBottom variant="h5" component="h2">
                                            {title} {loading && <div style={{display:'inline-block'}}><LoadingData/></div>}
                                        </Title>
                                    </Box>
                                    {titleActions}
                                </Box>
                                <Description component={Box}>
                                    {description}
                                </Description>
                            </Box>
                        </CardContent>
                    }
                </CardActionArea>
                <DialogActions style={{minHeight:actionsHeight}}>
                    <Box display={"flex"} flexDirection="column" height="100%" align="right">
                        <Box flexGrow={1}/>
                        <Box>
                            {subdescription && 
                                <Typography component='div' gutterBottom>
                                    {subdescription}
                                </Typography>
                            }
                            {href &&
                                <Button disabled={loading} component={Link} to={href} size="small" color="primary" variant="contained">
                                    {buttonText || t('common.learnMore')}
                                </Button>
                            }
                            {actions}
                        </Box>
                    </Box>
                </DialogActions>
            </Card>
        </Root>
    );
}

export default MediaCard;