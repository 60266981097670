/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Debounced Button
********************************************************************************************
Button can be clicked and is then disabled for a 'period' amount of ms

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       16th December 2022

*******************************************************************************************/
import React            from 'react';
import { Button }       from 'components';
import Icon             from '@mui/icons-material/AccessTime';

const noop = () => {}

export const DebouncedButton = ({
    disabled    = false, 
    onClick     = noop, 
    period      = 5000, 
    startIcon   = undefined, 
    ...props
}) => {
   
    // Ready State
    const [ready, setReady] = React.useState(true);
    
    // Click Handler
    const handleClick = React.useCallback((e) => { 
        setReady(false); 
        onClick(e); 
    },[onClick])

    // Debounce
    React.useEffect(() => {
        if(!ready){
            const timeout = setTimeout(() => {
                setReady(true)
            }, period)
            return () => {
                clearTimeout(timeout);
            }
        }
    },[period, ready])

    // Done
    return (
        <Button 
            {...props} 
            disabled    = {!ready || disabled} 
            onClick     = {handleClick} 
            startIcon   = { startIcon || (!ready ? <Icon/> : null )}
        />
    )
}

export default DebouncedButton;