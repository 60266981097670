/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Skeleton Person
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       20th July 2022

*******************************************************************************************/
import React                            from 'react';
import { Box, Skeleton }                from '@mui/material';
import { ContainerHorizontal }          from './components';

export const SkeletonPerson = (props) => {

    const [size, setSize]   = React.useState({width:0,height:0});
    const ref               = React.useRef(null);
    
    React.useEffect(() => {
        if(ref.current){
            setSize({
                width   : ref.current.offsetHeight,
                height  : ref.current.offsetHeight
            })
        }
    }, [ref]);

    return (
        <ContainerHorizontal>
            <Box flexShrink={1} style={size}>
                <Skeleton variant = "circular" width = {'100%'} height = {'100%'} {...props}/>
            </Box>
            <Box ref={ref} flexGrow={1}>
                <Box display="flex" width="100%">
                    <Skeleton variant="text" width="30%"/>
                    <Box flexGrow={1}/>
                    <Skeleton variant="text" width="15%"/>
                </Box>
                <Skeleton variant="text" width="100%"/>
                <Skeleton variant="text" width="50%"/>
            </Box>
        </ContainerHorizontal>
    );
}

export default SkeletonPerson;