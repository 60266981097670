
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
AutoTheme Context
********************************************************************************************
Listens to changes to authentication, user and pushes update to server

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       3rd July 2021

*******************************************************************************************/
import React                            from 'react';
import {isNil}                          from 'lodash';
import { useDayTime }                   from 'hooks';
import lightTheme                       from 'themes/lightTheme';
import darkTheme                        from 'themes/darkTheme';

// The Product Context 
const AutoThemeContext = React.createContext(undefined);

// Voltage Provider
const AutoThemeProvider = ({children}) => {

    const {isDayTime}           = useDayTime();
    const [option, setOption]   = React.useState(localStorage.getItem('themeoption') || 'auto');
    const [theme, setTheme]     = React.useState(localStorage.getItem('theme') === 'dark' ? darkTheme : lightTheme);

    // Store
    React.useEffect(() => {
        localStorage.setItem('theme', theme.palette.mode);
    }, [theme])

    React.useEffect(()=>{
        localStorage.setItem('themeoption', option);
    },[option])

    // Handle Day Change when isDayChange Changes
    React.useEffect(() => {
        if((option === 'auto' || !option) && !isNil(isDayTime))
            setTheme(isDayTime ? lightTheme : darkTheme);
        else if(option)
            setTheme(option === 'dark' ? darkTheme : lightTheme);
    }, [isDayTime, option])

    // Change Theme Function
    const changeTheme = (opt) => {
        ['light','dark'].includes(opt) ? setOption(opt) : setOption('auto');
    }

    // Context values
    const value = React.useMemo(() => ({
        theme,
        selected : option,
        changeTheme
    }),[option, theme]);

    return (
        <AutoThemeContext.Provider value={value}>
            {children}
        </AutoThemeContext.Provider>
    )
}

// Voltage Consumer
const AutoThemeConsumer = ({children}) => {
    return (
        <AutoThemeContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('AutoThemeConsumer must be used within AutoThemeProvider');
                }
                return children(context)
            }}
        </AutoThemeContext.Consumer>
    )
}

// useVoltage Hook
const useAutoTheme = () => {
    const context = React.useContext(AutoThemeContext);
    if(context === undefined)
        throw new Error('useAutoTheme must be used within AutoThemeProvider');
    return context;
}

export {
    AutoThemeProvider,
    AutoThemeConsumer,
    useAutoTheme
}
