/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Address Form
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       4th July 2021

*******************************************************************************************/
import React                            from 'react';
import isNil                            from 'lodash/isNil';
import { useTheme, Grid }               from '@mui/material';
import formatcoords                     from 'formatcoords';
import {
    Form,
    FormAlert,
    JSONViewer,
    SkeletonAddress
}                                       from 'components';
import {
    useNetwork,
    useTranslation
}                                       from 'contexts';
import {
    useGeocode, 
    useStateEphemeral,
    useCancelToken
}                                       from 'hooks';
import { 
    postcodeValidator, 
    postcodeValidatorExistsForCountry 
}                                       from 'postcode-validator';
import { 
    TextField,
    Autocomplete,
    showErrorOnChange   as showError,
    // showErrorOnBlur,
    // showErrorOnBlur     as showError
}                                       from 'mui-rff';
import {
    FormSpy
}                                       from 'react-final-form';

const BASE_API_URL      = '/api/user/address/lookup'
const TOP_COUNTRY_CODES = ['AU','US','GB','JP','IN','FR','IT','CA','DE']

/*
const DropDownItem = (props) => {
    let {style,...rest} = props;
    return (
        <span style={{fontSize:'0.75rem',...style}} {...rest}/>
    )
}
*/

const SelectCountry = (props) => {
    const {t} = useTranslation();
    return (
        <Autocomplete
            options                 = {props.options}
            disabled                = {!props.options.length}
            isOptionEqualToValue    = {(option,value) => {
                // console.log("Option",   option);
                // console.log("Value",    value);
                return option.isoCode === value?.isoCode;
            }}
            getOptionValue          = {option => option.isoCode}
            getOptionLabel          = {option => option ? `${option.flag} ${option.name} (${option.isoCode})` : t('components.forms.addressForm.pleaseSelect')}
            {...props}
        />
    )
}

const DEFAULT_FORM_DATA = {
    _id         : null,
    kind        : "BILLING",
    firstName   : '',
    middleName  : '',
    lastName    : '',

    countryCode : undefined,
    stateCode   : '',
    city        : '',
    address1    : '',
    address2    : '',
    postCode    : ''
}

const obj = {};
const noop = () => {};
const noopvalues = (values) => {};
const stringIdentity = value => isNil(value) ? '' : value;

const useQueryCountries = () => {

    // Various Working Aspects
    const [workingCountries,    setWorkingCountries]    = React.useState(true); // Default True
    const [workingStateCodes,   setWorkingStateCodes]   = React.useState(false);
    const [workingCities,       setWorkingCities]       = React.useState(false);

    const {axios}                       = useNetwork();
    const {cancelToken, isCancel}       = useCancelToken();

    const getCountries = React.useCallback( () => new Promise((resolve,reject) => {
        setWorkingCountries(true);
        axios.get(`${BASE_API_URL}/countries`, {cancelToken})
            .then(({data = []}) => data)
            .then(resolve)
            .catch((err) => {
                if(isCancel(err)) return reject(err);
                reject(err);
            })
            .finally(() => {
                setWorkingCountries(false);
            })
    }),[axios, cancelToken, isCancel]);

    const getStates = React.useCallback( (countryCode) => new Promise((resolve,reject) => {
        if(countryCode){
            setWorkingStateCodes(true);
            axios.get(`${BASE_API_URL}/statesOfCountry/${countryCode}`, {cancelToken})
                .then(({data = []}) => data)
                .then(resolve)
                .catch((err) => {
                    if(isCancel(err)) return reject(err);
                    reject(err);
                })
                .finally(() => {
                    setWorkingStateCodes(false);
                })
        }else{
            resolve([])
        }
    }),[axios, cancelToken, isCancel])

    const getCities = React.useCallback( (countryCode, stateCode) => new Promise((resolve,reject) => {

        if(countryCode && stateCode){
            setWorkingCities(true);
            axios.get(`${BASE_API_URL}/citiesOfState/${countryCode}/${stateCode}`, {cancelToken})
                .then(({data = []}) => data)
                .then(resolve)
                .catch(err => {
                    if(isCancel(err)) return reject(err);
                    reject(err);
                })
                .finally(() => {
                    setWorkingCities(false);
                })
        }else if(countryCode){
            setWorkingCities(true);
            axios.get(`${BASE_API_URL}/citiesOfCountry/${countryCode}`, {cancelToken})
                .then(({data}) => data)
                .then(({cities = []}) => cities)
                .then(resolve)
                .catch(err => {
                    if(isCancel(err)) return reject(err);
                    reject(err);
                })
                .finally(() => {
                    setWorkingCities(false);
                })
        }else{
            resolve([])
        }

    }),[axios, cancelToken, isCancel])

    const getCountryByCode = React.useCallback( (countryCode) => new Promise((resolve,reject) => {
        if(countryCode){
            axios.get(`${BASE_API_URL}/countries/${countryCode}`, {cancelToken})
                .then(({data = []}) => data)
                .then(resolve)
                .catch(err => {
                    if(isCancel(err)) return reject(err)
                    reject(err)
                })
        }else{
            resolve([]);
        }
    }),[axios, cancelToken, isCancel]);

    const getStateByCodeAndCountry = React.useCallback( (stateCode, countryCode) => new Promise((resolve,reject) => {
        if(stateCode && countryCode){
            axios.get(`${BASE_API_URL}/stateByCodeAndCountry/${stateCode}/${countryCode}`, {cancelToken})
                .then(({data = []}) => data)
                .then(resolve)
                .catch((err) => {
                    if(isCancel(err)) return reject(err);
                    reject(err);
                })
        }else{
            resolve([])
        }

    }),[axios, cancelToken, isCancel])
    
    return {
        getCountries,
        getCountryByCode,
        getStates,
        getStateByCodeAndCountry,
        getCities,
        workingCountries,
        workingStateCodes,
        workingCities
    }
}

const FIELD_LENGTH_LIMITS = {
    "firstName"     : {min:1,max:50},
    "middleName"    : {min:0,max:50},
    "lastName"      : {min:2,max:50},
    "address1"      : {min:10,max:100}
};

export const AddressForm = ({
    formData                            = DEFAULT_FORM_DATA,
    includeName                         = true,
    disabled                            = false,
    onSubmit : handleSubmitMaster       = noopvalues,
    onCancel : handleCancel             = noop,
    FormProps                           = obj,
    ...rest
}) => {
    const {t}                           = useTranslation();
    const theme                         = useTheme();
    const {lookupAddress}               = useGeocode();
    const {
        workingCountries,
        workingStateCodes,
        workingCities,
        getCountries, 
        getCountryByCode, 
        getStates, 
        getStateByCodeAndCountry, 
        getCities
    }                                   = useQueryCountries();
    
    const [message,     setMessage]     = useStateEphemeral(undefined, 5000);

    // Countries
    const [countries,   setCountries]   = React.useState([]);
    const [countryCode, setCountryCode] = React.useState(formData.countryCode);

    // State Codes
    const [stateCodes,  setStateCodes]  = React.useState([]);
    const [stateCode,   setStateCode]   = React.useState(formData.stateCode);

    // Cities
    const [cities,      setCities]      = React.useState([]);
    const [city,        setCity]        = React.useState(formData.city);
   
    React.useEffect(()=>{
        getCountries()
            .then(c => {
                const   top     = c.filter(x => TOP_COUNTRY_CODES.includes(x.isoCode) === true),      // Top Countries
                        rest    = c.filter(x => TOP_COUNTRY_CODES.includes(x.isoCode) === false)      // Rest
                setCountries([...top, ...rest]);
            })
            .catch(err => {
                setCountries([]);
            })
    },[getCountries])

    // Change Cities option when countryCode or stateCode changes
    React.useEffect(() => {
        getCities(countryCode, stateCode)
            .then((v) => {
                setCities(Array.isArray(v) ? v : []);
            })
            .catch(err => {
                setCities([])
            })
    },[getCities, countryCode, stateCode])

    // Change States options when countryCode changes
    React.useEffect(() => {
        getStates(countryCode)
            .then((v) => {
                setStateCodes(Array.isArray(v) ? v : []);
            })
            .catch(err => {
                setStateCodes([])
            })
    },[getStates, countryCode])

    const SelectState = React.useCallback( ({options = [], ...props}) => (
        <Autocomplete
            key                     = {`states${countryCode}-${city}-${stateCodes.length}`}
            options                 = {options}
            disabled                = {!options?.length}
            getOptionValue          = {option => option.isoCode}
            getOptionLabel          = {option => `${option.name} (${option.isoCode})`}
            isOptionEqualToValue    = {(option,value) => option.isoCode === value?.isoCode }
            {...props}
        />
    ),[countryCode, city, stateCodes])
    
    const SelectCity = React.useCallback( ({options = [],...props}) => (
        <Autocomplete
            key                     = {`cities${countryCode}-${stateCode}-${cities.length}`}
            options                 = {options}
            disabled                = {!options?.length}
            getOptionValue          = {option => option?.name}
            getOptionLabel          = {option => option?.name}
            isOptionEqualToValue    = {(option,value) => option.name === value?.name }
            {...props}
        />     
    ),[countryCode, stateCode, cities])

    // The Required Fields
    const requiredFields = React.useMemo(() => ([
        'countryCode','address1','postCode', 
        includeName ? 'firstName'   : undefined,
        // includeName ? 'middleName'  : undefined,
        includeName ? 'lastName'    : undefined
    ].filter(Boolean)),[includeName]);

    const fieldLengthHelperText = React.useCallback( (field,value) => {
        let currentLength = (value || '').toString().length;
        let isRequired = requiredFields.includes(field);
        if(!isRequired) 
            return null;
        let {min: minLength = 0, max: maxLength = Infinity} = FIELD_LENGTH_LIMITS[field] || {};
        if(maxLength === Infinity)
            return currentLength < minLength 
                ? t('components.forms.addressForm.lengthMinChar', {minLength}) 
                : undefined;
        return currentLength < minLength || currentLength > maxLength 
            ? t('components.forms.addressForm.lengthRangeChar', {minLength,maxLength}) 
            : undefined;
    }, [requiredFields, t]);

    // Validation Function
    const validate = (values) => {
        let     errors      = {};
        const   REQUIRED    = t('components.forms.addressForm.required'),
                INVALID     = t('components.forms.addressForm.invalid')

        requiredFields.forEach(field => {
            if(!values[field])
                errors[field] = errors[field] || REQUIRED;
        })

        // Check Name Limits
        Object
            .entries(FIELD_LENGTH_LIMITS)
            .forEach( ([field,{min : minLength = 0, max : maxLength = Infinity}]) => { // key,value
                try {
                    let value = String(values[field] || '').trim();
                    if(value.length < minLength || value.length > maxLength)
                        errors[field] = errors[field] || fieldLengthHelperText(field,values[field])
                } catch (err) {
                    //
                }
            })

        // Variable to hold the key
        let key, hasCountry = false;

        // Check
        key = 'countryCode';
        if(values[key]){
            if(!countries.find(item => item.isoCode === values[key])){
                errors[key] = errors[key] || INVALID;
                hasCountry = false;
            }else{
                hasCountry = true;
            }
        }
        
        // Dynamic, Some Countries dont have States
        key = 'stateCode';
        if(hasCountry && stateCodes.length){
            if(!values[key])
                errors[key] = errors[key] || REQUIRED;
            if(!stateCodes.find(item => item.isoCode === values[key]))
                errors[key] = errors[key] || INVALID;
        }

        // Dynamic, Some Countries dont have States
        key = 'city';
        if(hasCountry && cities.length){
            if(!values[key])
                errors[key] = errors[key] || REQUIRED;
            if(!cities.find(item => item.name === values[key]))
                errors[key] = errors[key] || INVALID;
        }

        // Validate PostCode
        if(values.postCode && values.countryCode && postcodeValidatorExistsForCountry(values.countryCode)){
            if(!postcodeValidator(values.postCode, values.countryCode)){
                errors.postCode = errors.postCode || t('components.forms.addressForm.invalidValue');
            }
        }

        return errors;
    }

    // Initial Form Data
    const initialValues = {
        ...formData
    }

    const handleSubmit = (values) => new Promise(resolve => {

        Promise.all([
            getCountryByCode(values.countryCode),
            getStateByCodeAndCountry(values.stateCode, values.countryCode)
        ]).then(([country,state]) => {

            // Add country and state
            values.country  = country.name
            values.state    = state.name
    
            // Ensure null fields are replaced by empty string
            Object.keys(initialValues).forEach((key)=>{
                values[key] = stringIdentity(values[key]);
            })
            
            setMessage(t('components.forms.addressForm.geolocating'));
            
            // Construct the lookup term
            const street    = [values.address1, values.address2 ].filter(Boolean).join(' ')
            const region    = [values.city, values.stateCode, values.postCode].filter(Boolean).join(' ')
            const address   = [street, region, values.country].filter(Boolean).join(', ')
    
            lookupAddress(address)
                .then(({lat,lng,success}) => ({lat,lng,success}))
                .then(geodata => {
                    values.geolocation = geodata;
                    if(geodata.success) 
                        setMessage(t('components.forms.addressForm.geolocated', { geolocation : formatcoords(geodata.lat,geodata.lng).format()}));
                    resolve(
                        handleSubmitMaster(values)
                    );
                })

        }).catch(resolve)
    })

    const autoCompleteArgs = {
        autoComplete    : true,
        autoSelect      : true,
        autoHighlight   : true,
        // blurOnSelect    : false,
        showError       : showError,
        textFieldProps  : {...theme.components.MuiTextField.defaultProps}
    }

    /*
    const clearStateCode = (args, state, { setIn, changeValue }) => {
        // console.log(args, state, setIn, changeValue)
        const field = state.fields["stateCode"];
        field.change(undefined);
    };

    const clearCity = (args, state, { setIn, changeValue }) => {
        // console.log(args, state, setIn, changeValue)
        const field = state.fields["city"];
        field.change(undefined);
    };
    */
      
    /*
    const Spacer = () => <div style={{
        margin         : theme.spacing(1),
        // borderBottom    : '1px solid black',
        // background  : theme.palette.divider, 
        width       : '100%'}} 
    />
    */

    return (
        <>
            {
                // Bypass if working
                workingCountries && 
                <SkeletonAddress />
            }
            {
                // Present form if contry data loaded
                !workingCountries && 
                <Form
                    debug                   = {false}
                    disabled                = {disabled}
                    onSubmit                = {handleSubmit}
                    onCancel                = {handleCancel}
                    initialValues           = {initialValues}
                    validate                = {validate}
                    {...FormProps}
                    render          = {({disabled, form, error, dirtySinceLastSubmit, submitFailed, submitSucceeded, errors, handleSubmit, values, ...rest}) => {
                        return (
                            <React.Fragment>
                                {message && <FormAlert>{message}</FormAlert>}
                                <form onSubmit={handleSubmit} noValidate>
                                    <Grid container spacing={1}>
                                        {
                                            false &&
                                            <Grid item xs={12}>
                                                <JSONViewer src={errors} />
                                            </Grid>
                                        }
                                        <Grid item xs={9}>
                                            <SelectCountry
                                                // value       = {values?.countryCode || null}
                                                disabled    = {disabled || workingCountries}
                                                name        = "countryCode"          
                                                label       = { t('components.forms.addressForm.countryOrTerritoryCount',{count : countries.length}) }
                                                options     = {countries} 
                                                {...autoCompleteArgs}
                                                helperText  = {
                                                    workingCountries ? t('components.forms.addressForm.loading') : undefined
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField  
                                                disabled    = {true}
                                                name        = 'kind'    
                                                label       = {t('components.forms.addressForm.addressType')}
                                                showError   = {showError}
                                            />
                                        </Grid>
                                        {
                                            includeName &&
                                            <React.Fragment>
                                                <Grid item xs={6} sm={4}>
                                                    <TextField  
                                                        disabled    = {disabled}
                                                        name        = 'firstName'    
                                                        helperText  = {!values.firstName ? fieldLengthHelperText('firstName',values.firstName) : undefined} 
                                                        label       = {t('components.forms.addressForm.givenName')}
                                                        showError   = {showError}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} sm={4}>
                                                    <TextField  
                                                        disabled    = {disabled}
                                                        name        = 'middleName'   
                                                        helperText  = {!values.middleName ? fieldLengthHelperText('middleName',values.middleName) : undefined} 
                                                        label       = {t('components.forms.addressForm.middleName')}
                                                        showError   = {showError}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <TextField  
                                                        disabled    = {disabled}
                                                        name        = 'lastName'     
                                                        helperText  = {!values.lastName ? fieldLengthHelperText('lastName',values.lastName) : undefined}  
                                                        label       = {t('components.forms.addressForm.lastName')}
                                                        showError   = {showError}/>
                                                </Grid>
                                            </React.Fragment>
                                        }

                                        <Grid item xs={12}>
                                            <TextField      
                                                disabled    = {disabled}
                                                name        = 'address1'             
                                                helperText  = {!values.lastName ? fieldLengthHelperText('address1',values.address1) : undefined}  
                                                label       = {t('components.forms.addressForm.addressLine1')}
                                                showError   = {showError}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField      
                                                disabled    = {disabled}
                                                name        = 'address2'             
                                                label       = {t('components.forms.addressForm.addressLine2')}
                                                showError={showError}
                                            />
                                        </Grid>
                                        <Grid item xs={12} >
                                            {
                                                Boolean(true || stateCodes?.length) && 
                                                <SelectState   
                                                    disabled    = {disabled || workingCountries || workingStateCodes || !stateCodes?.length}
                                                    name        = "stateCode"            
                                                    helperText  = {
                                                        (workingCountries || workingStateCodes)
                                                            ?   t('components.forms.addressForm.loading')
                                                            :   !values.countryCode 
                                                                    ? t('components.forms.addressForm.pickCountryFirst')
                                                                    : !stateCodes.length 
                                                                        ? t('components.forms.addressForm.noStatesForCountry',{countryCode:values.countryCode})
                                                                        : !values.stateCode 
                                                                            ? t('components.forms.addressForm.pickStateOrProvince')
                                                                            : undefined
                                                    }
                                                    label       = { t('components.forms.addressForm.stateOrProvinceCount',{count:stateCodes.length}) }
                                                    options     = {stateCodes} 
                                                    {...autoCompleteArgs}
                                                />
                                            }
                                        </Grid>
                                        <Grid item xs={8} sm={9}>
                                            {
                                                Boolean(true || cities?.length) && 
                                                <SelectCity     
                                                    disabled    = {disabled || workingCountries || workingStateCodes || workingCities || !cities?.length}
                                                    name        = "city"                 
                                                    helperText  = {
                                                        (workingCountries || workingStateCodes || workingCities)
                                                            ?   t('components.forms.addressForm.loading')
                                                            :   !values.countryCode && !values.stateCode
                                                                    ? t('components.forms.addressForm.pickCountryAndStateFirst')
                                                                    : !cities.length 
                                                                        ?  (
                                                                                (values.countryCode || values.stateCode) 
                                                                                    ? t('components.forms.addressForm.noCitiesAtLocation',{location:values.stateCode || values.countryCode})
                                                                                    : t('components.forms.addressForm.noCities') 
                                                                            )
                                                                        : !values.city 
                                                                            ? t('components.forms.addressForm.pickCity')
                                                                            : undefined
                                                    }
                                                    label       = {t('components.forms.addressForm.cityCount',{count:cities.length})}
                                                    options     = {cities} 
                                                    {...autoCompleteArgs}
                                                />
                                            }
                                        </Grid>
                                        <Grid item xs={4} sm={3}>
                                            <TextField      
                                                disabled    = {disabled}
                                                name        = 'postCode'             
                                                label       = {t('components.forms.addressForm.postCode')}
                                                showError   = {showError}
                                            />
                                        </Grid>
                                    </Grid>
                                    <FormSpy subscription={{values: true}} 
                                        onChange = {({values})=>{
                                            if(values.countryCode !== countryCode){
                                                if(!values.countryCode || countries.find(item => item.isoCode === values.countryCode))
                                                    setCountryCode(values.countryCode);
                                                // Backprop.
                                                if(values.stateCode || !values.countryCode){
                                                    getStates(values.countryCode)
                                                        .then(sta => {
                                                            if(!sta.find(item => item?.isoCode === values.stateCode)){
                                                                setStateCode('');
                                                                form.change('stateCode', '');
                                                            }
                                                        })
                                                        .catch(err => {
                                                            setStateCode('');
                                                            form.change('stateCode', '');
                                                        })
                                                }
                                            }
                                            if(values.stateCode !== stateCode){
                                                if(!values.stateCode || stateCodes.find(item => item.isoCode === values.stateCode))
                                                    setStateCode(values.stateCode);
                                                // Backprop. This handles the case if city is specified before state, and then state is picked which includes the city
                                                if(values.city || !values.stateCode){
                                                    getCities(values.countryCode, values.stateCode)
                                                        .then(cit => {
                                                            if(!cit.find(item => item?.name === values.city)){
                                                                setCity('');
                                                                form.change('city', '');
                                                            }
                                                        })
                                                        .catch(err => {
                                                            setCity('');
                                                            form.change('city', '');
                                                        })
                                                }
                                            }
                                            if(values.city !== city ){
                                                if(!values.city || cities.find(item => item.name === values.city))
                                                    setCity(values.city);
                                                // Infer State from City
                                                if(values.city && !values.stateCode){
                                                    let cit = cities.filter(c => c.name === values.city)
                                                    if(cit.length === 1){
                                                        let {stateCode} = cit[0];
                                                        setStateCode(stateCode);
                                                        form.change('stateCode',stateCode);
                                                    }
                                                }
                                            }
                                        }}
                                    />
                                </form>
                            </React.Fragment>
                        )
                    }}
                />
            }
        </>
    )
}

export default AddressForm;