/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Video Component
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       1st June 2022

*******************************************************************************************/
import React from 'react';

const noop = () => {};
const obj = {};

export const Video = ({sources : sourcesIn = [], onPlay=noop, videoProps = obj, ...props}) => {
    
    if(!Array.isArray(sourcesIn))
        throw new Error('sources must be an array');
    
    const sources           = React.useMemo(() => sourcesIn, [sourcesIn]);

    const [index, setIndex] = React.useState(0);
    const src               = React.useMemo(() => sources[index], [sources,index]);
    const handlePlay        = React.useCallback(() => onPlay(src),[onPlay, src]);

    React.useEffect(() => {
        setIndex(Math.floor(Math.random() * sources.length))
    }, [sources]);

    if(sources.length <= 0)
        return null;

    return (
        <video 
            preload     = "none"
            loop        = {true} 
            autoPlay 
            muted 
            {...videoProps}
            onPlay      = {handlePlay}
            src         = {src} 
        />
    )
}

export default Video;