/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Image Gallery
********************************************************************************************
https://www.npmjs.com/package/react-image-gallery

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       25th July 2021

*******************************************************************************************/
import React                    from 'react';
import ReactImageGalleryBase    from 'react-image-gallery';
import { 
    styled,
    IconButton as IconButtonMUI, 
    alpha 
}                               from '@mui/material';
import ArrowBackIosIcon         from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon      from '@mui/icons-material/ArrowForwardIos';
import PlayCircleOutlineIcon    from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon   from '@mui/icons-material/PauseCircleOutline';
import FullscreenIcon           from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon       from '@mui/icons-material/FullscreenExit';

import "react-image-gallery/styles/css/image-gallery.css";
import { Helmet } from 'react-helmet';

/*
<div class="image-gallery-slides" style="max-height: calc(100vh - 125px);">
*/

const ReactImageGallery = styled(ReactImageGalleryBase)({
    "&.image-gallery-slides" : {
        maxHeight: 'calc(100vh - 125px)!important'
    }
});

const IconButton = styled(IconButtonMUI,{
    shouldForwardProp : prop => !['playpause','fullscreen','leftnav','rightnav'].includes(prop)
})(({theme, playpause = false, fullscreen = false, leftnav = false, rightnav = false}) => ({
    position    : 'absolute',
    padding     : theme.spacing(1),
    zIndex      : 2,
    background  : alpha(theme.palette.secondary.light,0.1),
    "&:hover" : {
        background : alpha(theme.palette.secondary.light,0.25),
        color      : theme.palette.secondary.dark
    },
    ...(playpause && {
        bottom      : 0,
        left        : '49%',
        transform   : 'translateX(-100%)',
    }),
    ...(fullscreen && {
        bottom      : 0,
        right       : '49%',
        transform   : 'translateX(+100%)',
    }),
    ...(leftnav && {
        bottom      : 0,
        left        : 0,
    }),
    ...(rightnav && {
        bottom      : 0,
        right       : 0,
    })
}))

const ICON_FONT_SIZE = '1.5rem';

export const ImageGallery = ({autoPlay=false, showThumbnails = true, ...props}) => {
    return (
        <>
            <Helmet>
                <style type="text/css">
                    {`
                        .image-gallery-slides, .image-gallery-slide .image-gallery-image { 
                            max-height: calc(100vh - 125px)!important; 
                        }
                        .image-gallery-thumbnail {
                            min-height: 50px;
                        }
                        .image-gallery-thumbnail-label {
                            background: rgba(0,0,0,0.40);
                            min-height: 50px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    `}
                </style>
            </Helmet>
            <ReactImageGallery 
                autoPlay                = {autoPlay}
                showThumbnails          = {showThumbnails}
                renderLeftNav           = {(onClick, disabled) => {
                    return (
                        <IconButton
                            variant     = "contained"
                            leftnav     = {true}
                            disabled    = {disabled}
                            onClick     = {onClick}
                            size        ="large"
                            sx          = {{ml:1}}
                        >
                            <ArrowBackIosIcon style={{fontSize : ICON_FONT_SIZE, transform:'translatex(12.5%)'}}/>
                        </IconButton>
                    );}
                }
                renderRightNav           = {(onClick, disabled) => {
                    return (
                        <IconButton
                            variant     = "contained"
                            rightnav    = {true}
                            disabled    = {disabled}
                            onClick     = {onClick}
                            size        = "large"
                            sx          = {{mr:1}}
                        >
                            <ArrowForwardIosIcon style={{fontSize : ICON_FONT_SIZE, transform:'translatex(12.5%)'}}/>
                        </IconButton>
                    );}
                }
                renderPlayPauseButton   = {(onClick, isPlaying) => {
                    const Icon = isPlaying ? PauseCircleOutlineIcon : PlayCircleOutlineIcon;
                    return (
                        <IconButton
                            variant     = "contained"
                            color       = {isPlaying ? "secondary" : "default"}
                            onClick     = {onClick}
                            size        = "large"
                            playpause   = {true}
                            sx          = {{m:1,mx:0}}
                        >
                            <Icon style={{fontSize : ICON_FONT_SIZE}}/>
                        </IconButton>
                    );
                }}
                renderFullscreenButton  = {(onClick, isFullscreen) => {
                    const Icon = isFullscreen ? FullscreenExitIcon : FullscreenIcon;
                    return (
                        <IconButton
                            variant     = "contained"
                            onClick     = {onClick}
                            size        = "large"
                            fullscreen  = {true}
                            sx          = {{m:1,mx:0}}
                        >
                            <Icon style={{fontSize : ICON_FONT_SIZE}}/>
                        </IconButton>
                    );
                }}
                {...props}
            />  
        </>
    );
}

export default ImageGallery;