/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Cart Continue Browsing
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       9th July 2021

*******************************************************************************************/
import React                            from 'react';
import { useHistory }                   from 'react-router-dom';
import { Box }                          from '@mui/material'
import { Search as SearchIcon }         from '@mui/icons-material';
import { useCart, useNetwork }          from 'contexts';
import { SpaceBox, NoDataPrompt }       from 'components';
import { withTranslation }              from './hoc';
import { ShopLocation }                 from 'router/locations/Locations';

export const CartContinueBrowsing = withTranslation( ({
    t,
    disabled = false,
    ...props
}) => {
    
    const history           = useHistory();
    const {isNetworkReady}  = useNetwork();
    const {
        queried,
        hasItems,
    } = useCart();
    const handleBrowse      = React.useCallback( () => history.push(ShopLocation.path), [history]);

    // Disabled, return nothing
    if(disabled) 
        return null;

    // Return the component
    return (
        <Box width="100%">
            {
                Boolean(isNetworkReady && !hasItems && queried) &&
                <SpaceBox>
                    <NoDataPrompt 
                        startIcon   = {<SearchIcon/>} 
                        onClick     = {isNetworkReady ? handleBrowse : undefined}
                        buttonText  = { t('components.cartContinueBrowsing.continueBrowsing') }
                        {...props}
                    >
                        { t('components.cartContinueBrowsing.noItemsInCart') }
                    </NoDataPrompt>
                </SpaceBox>
            }
        </Box>
    )
})

export default CartContinueBrowsing;