/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Titles
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       1st August 2022

*******************************************************************************************/
import React            from 'react';
import MenuTitle        from 'components/MenuTitle';
import {useTranslation} from 'contexts/TranslationContext';

const Title = ({translationKey = undefined, ...props}) => {
        const {t} = useTranslation();
        if(!translationKey) 
                return null;
        return (
                <MenuTitle {...props}> 
                        {t(translationKey)}
                </MenuTitle>
        )
}

export const LanguageTitle              = (props) => <Title translationKey={'common.language'}          {...props} /> 
export const UserNavigationTitle        = (props) => <Title translationKey={'common.userNavigation'}    {...props} /> 
export const AdministrationTitle        = (props) => <Title translationKey={'common.administration'}    {...props} /> 
export const SystemPagesTitle           = (props) => <Title translationKey={'common.systemPages'}       {...props} /> 
export const OtherPagesTitle            = (props) => <Title translationKey={'common.otherPages'}        {...props} /> 
export const ShoppingCartTitle          = (props) => <Title translationKey={'common.shoppingCart'}      {...props} /> 
export const NotificationsTitle         = (props) => <Title translationKey={'common.notifications'}     {...props} />