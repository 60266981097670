/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Hide on Scroll
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       22nd December 2020

*******************************************************************************************/
import React                            from 'react';
import Slide                            from '@mui/material/Slide';
import useScrollTrigger                 from '@mui/material/useScrollTrigger';   

const noop = () => {}


export const HideOnScroll = ({onHide = noop, onShow = noop, onChange = noop, ...props}) => {
    const { children }  = props;
    const trigger       = useScrollTrigger({threshold: 100, disableHysteresis: true});
    React.useEffect(() => {
        const show = !trigger;
        show ? onShow() : onHide();
        onChange(show);
    },[onChange, onHide, onShow, trigger])
    return (
        <Slide appear={false} direction="down" in={!trigger} timeout={100}>
            {children}
        </Slide>
    );
}

export default HideOnScroll;