/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Current Time Hook
********************************************************************************************
Hook that returns an updating time (moment) object held as a state variable

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       14th December 2022

*******************************************************************************************/
import React    from 'react';
import moment   from 'moment';

export const useClock = (period = 1000) => {

    // Check
    if(isNaN(period) || period < 0)
        throw new Error('period must be positive numeric')

    // Current Time State
    const [now,         setNow]         = React.useState(moment());
    const [running,     setRunning]     = React.useState(true);


    const start = React.useCallback(() => setRunning(true),[]);
    const stop  = React.useCallback(() => setRunning(false),[]);

    // Update the Current Time
    React.useEffect(()=>{
        if(running){
            const interval = setInterval(() => {
                const currentTime = moment();
                setNow(currentTime);
            }, Math.abs(period));
            return () => {
                clearInterval(interval);
            }
        }
    },[period,running]);
    
    // Return Current Time
    return {
        now,
        running,
        start,
        stop
    }
}

export default useClock;