/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Popover
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       7th May 2021

*******************************************************************************************/
import React                                from 'react';
import { Box, IconButton }                  from '@mui/material';
import { Menu }                             from './Menu';
import { NotificationsTitle }                    from './Titles'; 
import { 
    Notifications as Component,
    NotificationsIconWithQuantity
}                                           from 'components';
import { 
    useUser, 
    useNotification
}                                           from 'contexts';

export const DropDownNotifications = () => {
    const {quantityUnread}          = useNotification();
    const {isAuthenticated}         = useUser();
    return (
        <Menu 
            showArrow   = {true} 
            render      = {({handleClick}) => (
                <IconButton
                    disabled    = {!isAuthenticated}
                    onClick     = {handleClick}
                    size        = "large"
                    sx          = {{
                        color   : theme => theme.palette.mode ==='light' ? 'black' : 'white',
                    }}
                >
                    <NotificationsIconWithQuantity quantity={quantityUnread} iconProps={{sx:{fontSize:'2rem'}}} />
                </IconButton>
            )}
        >
            <NotificationsTitle />
            <Box>
                <Component />
            </Box>
        </Menu>
    )

    /*
    return (
        <ClickAwayListener onClickAway={handleClose}>
            <div className={classes.root}>
                <IconButton
                    disabled={!isAuthenticated}
                    className={classes.iconButton}
                    onClick = {handleToggleOpenClose}
                    size="large">
                    <NotificationsIconWithQuantity color={!isAuthenticated ? 'disabled' : 'secondary'} quantity={quantityUnread}/>
                </IconButton>
                <Dropdown title={title} anchorEl={anchorEl} open={open} onClose={handleClose}>
                    <Notifications />
                </Dropdown>
            </div>
        </ClickAwayListener>
    );
    */
}

export default DropDownNotifications;