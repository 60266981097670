/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
useStateEphemeral
********************************************************************************************
A state that resets to undefined after a period of time

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       9th March 2021

********************************************************************************************/
import React    from 'react';
import moment   from 'moment';

const noop = () => {};

export const useStateEphemeral = (initialState, duration = 1000, ephemeralState = undefined, onCountDown = noop) => {

    // Message and Set Message hook
    const [state, setState] = React.useState(undefined);

    // Method to erase the state
    const erase = React.useCallback( () => {
        setState(ephemeralState);
        onCountDown(0);
    }, [ephemeralState, onCountDown]);
    
    // Update message
    React.useEffect(() => setState(initialState), [initialState]);
    
    // Timer
    React.useEffect(() => {
        if(state !== ephemeralState){
            
            let timeout, interval, start;
            
            if(!isNaN(duration) && duration > 0){
                timeout     = setTimeout(erase, duration);
                start       = moment();
                interval    = setInterval(() => {
                    let elapsed     = moment().diff(start)
                    let remaining   = Math.max(duration - elapsed,0);
                    if(remaining <= 0)
                        clearInterval(interval);
                    onCountDown(remaining);
                },100)
            }

            // Cleanup
            return () => {
                clearTimeout(timeout);
                clearInterval(interval);
            }
        }
    },[duration, ephemeralState, erase, onCountDown, state])
    
    // Return the Message and SetMessage hook
    return [state, setState];
};

export default useStateEphemeral;