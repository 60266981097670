/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Title Container With Social Share
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       14th January 2024

*******************************************************************************************/
import React                    from 'react';
import {
    Grid,
}                               from '@mui/material';
import { 
    SocialSharePage,
}                               from 'components';

export const TitleContainerWithSocialShare = ({children, stripParms=true, stripParmsReason=undefined}) => {
    return (
        <Grid container>
            <Grid item md={7} sm={6} xs={12}>
                {children}
            </Grid>
            <Grid item md={5} sm={6} xs={12} align="right">
                <SocialSharePage stripParms={stripParms} stripParmsReason={stripParmsReason}/>
            </Grid>
        </Grid>
    )
}

export default TitleContainerWithSocialShare;