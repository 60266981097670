/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Feature Box
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       25th December 2020

*******************************************************************************************/
import React            from 'react';
import {
    styled,
    Card as CardMUI, 
    Box
}                       from '@mui/material';
import {CardHeading}    from 'components';

/*
const Root = styled(Box)(({theme}) => ({
    width: '100%',
    marginTop       : theme.spacing(0), //2
    '& > * + *': {
        marginTop   : theme.spacing(0), //2
    },
    '&:first-child' : {
        marginTop   : theme.spacing(0),
    }
}));
*/

const Card = styled(CardMUI)(({theme}) => ({
    // height      : '100%',
    padding     : theme.spacing(1),
    background  : theme.palette.background.paper //theme.palette.mode === 'dark' ? grey[800] : grey[100]
}));

export const FeatureBox = ({
    children,
    title,
    actions,
    ...rest
}) => (
    <Card variant="outlined" {...rest}>
        {
            (title || actions) && 
            <Box width = "100%">
                <Box display="flex" justifyContent="flex-end" p={0} pb={0} alignItems='stretch'> 
                    {
                        title && 
                        <Box flexGrow={1}>
                            <CardHeading>
                                {title}
                            </CardHeading>
                        </Box>
                    }
                    <Box>
                        {actions}
                    </Box>
                </Box>
            </Box>
        }
        {
            children
        }
    </Card>
)

export default FeatureBox;
