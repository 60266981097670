/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Is Window Focussed Hook
********************************************************************************************
https://stackoverflow.com/a/63582411/1834057

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       9th July 2021
*******************************************************************************************/

import React        from 'react';
import config       from '../config';
import {debounce}   from '../functions';

export const useIsWindowFocussed = () => {
    const [windowIsActive, setWindowIsActive] = React.useState(true)
    
    const handleActivityFunction = e => {
        if (e?.type === 'focus') {
            return setWindowIsActive(true)
        }
        if (e?.type === 'blur') {
            return setWindowIsActive(false)
        }
        if (e?.type === 'visibilitychange') {
            if (document.hidden) {
                return setWindowIsActive(false)
            } else {
                return setWindowIsActive(true)
            }
        }
    };

    const handleActivity = debounce( handleActivityFunction, config.debounce.wait, {leading: false, trailing: true});

    React.useEffect(() => {
        document.addEventListener('visibilitychange', handleActivity)
        document.addEventListener('blur', handleActivity)
        window.addEventListener('blur', handleActivity)
        window.addEventListener('focus', handleActivity)
        document.addEventListener('focus', handleActivity)

        return () => {
            window.removeEventListener('blur', handleActivity)
            document.removeEventListener('blur', handleActivity)
            window.removeEventListener('focus', handleActivity)
            document.removeEventListener('focus', handleActivity)
            document.removeEventListener('visibilitychange', handleActivity)
        }
    }, [handleActivity])

    return windowIsActive
}

export default useIsWindowFocussed;