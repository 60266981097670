/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Cache Loader
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       3rd January 2022

*******************************************************************************************/

import React                            from 'react';
import { 
  styled,
  useTheme, 
  Box, 
  Typography        as TypographyMUI,  
  CircularProgress  as CircularProgressMUI 
}                                       from '@mui/material';
import { withTranslation }              from './hoc';
import { Logo }                         from './Logo';

const RootContainer = styled(Box)(({theme}) => ({
  position    : 'absolute',
  zIndex      : 10000,
  width       : '100%',
  height      : '100%',
  background  : theme.palette.background.paper,
}));

const BodyContainer = styled(Box)(({theme}) => ({
  width       : '100%',
  height      : '100vh',
  display     : 'flex'
}));

const ContentContainer = styled(Box)(({theme}) => ({
  width     : 'fit-content',
  margin    : 'auto',
  textAlign : 'center'
}));

const CircularProgressContainer = styled(Box)(({theme}) => ({
  color       : theme.palette.text.primary,
  padding     : theme.spacing(2)
}));

const Typography = styled(TypographyMUI)(({theme}) => ({
  textAlign   : 'center',
  color       : theme.palette.text.primary,
  fontSize    : '1.1em', 
  whiteSpace  : 'nowrap'
}));

export const Loader = withTranslation( ({t,...props}) => {
  const theme   = useTheme();
  const isLight = React.useMemo(() => Boolean(theme.palette.mode === 'light'), [theme])
  return (
    <ContentContainer>
      <Logo to={false} dark={isLight} />
      <CircularProgressContainer>
        <CircularProgressMUI color="inherit" />
      </CircularProgressContainer>
      <Typography>
        {
          props.children || t('components.cacheLoader.loadingContent')
        } 
      </Typography>
    </ContentContainer>
  )
});

export const CacheLoader = (props) => (
  <RootContainer id = "loader">
    <BodyContainer>
      <Loader {...props} />
    </BodyContainer>
  </RootContainer>
)

export default CacheLoader;