/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
ServerBroadcasts
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       14th August 2022

*******************************************************************************************/
import React                            from 'react';
import { 
    styled, 
    useTheme, 
    Box, 
    Typography 
}                                       from '@mui/material';
import { Logo }                         from 'components';
import { useStateEphemeral }            from 'hooks';
import { useNetwork }                   from 'contexts';

const Root = styled(Box)(({theme}) => ({
    position        : 'fixed',
    right           : theme.spacing(1),
    bottom          : theme.spacing(1),
    width           : 400,
    maxWidth        : `calc(100vh - ${theme.spacing(2)})`,
    background      : theme.palette.background.paper,
    zIndex          : 10000,
    borderRadius    : theme.spacing(1),
    borderColor     : theme.palette.divider,
    border          : "1px solid"
}))

const Timer = styled(Typography)(({theme}) => ({
    color           : theme.palette.error.main,
    textAlign       : 'right',
    fontWeight      : 400,
    cursor          : 'pointer'
}))

export const ServerBroadcasts = (props) => {
    
    const [countDown, setCountDown]     = React.useState(0);
    const [message, setMessage]         = useStateEphemeral(undefined, 30000, undefined, setCountDown);
    const {socketUsers:socket}          = useNetwork();
    const theme                         = useTheme();

    const handleDismiss                 = React.useCallback(() => setMessage(undefined), [setMessage]);

    React.useEffect(() => {
        if(socket){
            socket.on('broadcast', setMessage);
            return () => {
                socket.off('broadcast', setMessage);
            }
        }
    },[setMessage, socket])

    if(!message)
        return null;

    return (
        <Root>
            <Box display="flex" p={1}>
                <Box style={{minWidth:125,width:125}}>
                    <Logo width="100%" minWidth={0} dark={theme.palette.mode !== 'dark'}/>
                </Box>
                <Box display="flex" flexDirection="column" flexGrow={1} p={0.5}>
                    <Box flexGrow={1} width="100%">
                        <Typography variant="body2" align="justify">
                            {message}
                        </Typography>
                    </Box>
                    <Box width="100%" align="right">
                        <Timer variant="body2" onClick={handleDismiss}> 
                            Closing in {((countDown || 0)/1000).toFixed(1)}s 
                        </Timer>
                    </Box>
                </Box>
            </Box>
        </Root>
    )
}

export default ServerBroadcasts;