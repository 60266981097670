/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Theme Switch Component
********************************************************************************************
Listens to changes to authentication, user and pushes update to server

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       3rd July 2021

*******************************************************************************************/
import React                            from 'react';
import {useAutoTheme}                   from 'contexts';
import {
    ButtonGroup,
    Button
}                                       from '@mui/material';
import IconLight                        from '@mui/icons-material/Image';
import IconAuto                         from '@mui/icons-material/PhotoLibrary';
import IconDark                         from '@mui/icons-material/CropOriginal';
import {useTranslation}                 from '../contexts';
import {
    FONT_FAMILY_FEATURE
}                                       from '../themes';

export const ThemeSwitch = ({condensed=false,...props}) => {
    const {changeTheme, selected}   = useAutoTheme();
    const [t]                       = useTranslation();

    const data = [
        {option: 'light',   Icon : IconLight, translationKey: 'common.light'},
        {option: 'auto',    Icon : IconAuto,  translationKey: 'common.auto'},
        {option: 'dark',    Icon : IconDark,  translationKey: 'common.dark'},
    ]

    return (
        <ButtonGroup variant="text" {...props} sx={{'& > *' : {mx : 1}}}>
            {
                data.map(({option,translationKey,Icon},ix) => (
                    <Button 
                        key     = {ix}
                        onClick = {() => changeTheme(option)} 
                        color   = {option === selected ? 'secondary' : 'inherit'}
                        style   = {{borderRight:'none', fontFamily : FONT_FAMILY_FEATURE, textTransform: 'lowercase'}}
                    >
                        <Icon/> {!condensed && t(translationKey)}
                    </Button>
                ))
            }
        </ButtonGroup>
    )
}

export default ThemeSwitch;