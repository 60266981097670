/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Comments Content
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       12th Maty 2022

*******************************************************************************************/
import React                            from 'react';
import { 
    styled, 
    useTheme, 
    Box, 
    Typography 
}                                       from '@mui/material';
import { Linkify }                      from 'components';

const noop      = () => {};

const Root = styled(Box,{
    shouldForwardProp : prop => prop !== 'canEdit'
})(({theme,canEdit = false}) => ({
    '& > * + *' : {
        marginTop : theme.spacing(1)
    },
    ...(canEdit && {
        cursor : 'pointer',
        '&:hover' : {
            background      : theme.palette.action.hover,
            margin          : theme.spacing(-0.5),
            padding         : theme.spacing(0.5),
            width           : `calc(100% + ${theme.spacing(1)})`,
            borderRadius    : theme.spacing(0.5),
        }
    })
}));

const Paragraph = styled(Typography,{
    shouldForwardProp : prop => !['newComment','deleting','replying'].includes(prop)
})((theme,newComment = false,deleting = false,replying = false) => ({
    ...(newComment && {
        fontWeight : 400
    }),
    ...(deleting && {
        color : theme.palette.mode === "dark" ? theme.palette.error.light : theme.palette.error.dark
    }),
    ...(replying && {
        color : theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark,
    }),
}))

const BoldTypography = styled(Typography)({
    fontWeight : 400
})

export const CommentsContent = ({
    comment         = undefined, 
    newComment      = false, 
    canEdit         = false, 
    deleting        = false,
    replying        = false, 
    deletingText    = null,
    replyingText    = null, 
    onClick         = noop, 
    onDoubleClick   = noop, 
    linkify         = false
}) => {
    
    const theme         = useTheme();
    const commentArr    = React.useMemo(() => comment ? comment.split('\n') : [], [comment]);

    if(!comment) 
        return null;

    return (
        <Root canEdit={canEdit} onClick = {onClick} onDoubleClick = {onDoubleClick}>
            
            {
                replying && replyingText && 
                <BoldTypography 
                    align       = "justify" 
                    variant     = "body2" 
                    style       = {{ color : theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.primary.dark }}
                >
                    {replyingText}
                </BoldTypography>
            }

            {
                deleting && !replying && deletingText &&
                <BoldTypography 
                    align       = "justify" 
                    variant     = "body2" 
                    style       = {{ color : theme.palette.mode === 'dark' ? theme.palette.error.light : theme.palette.error.dark }}
                >
                    {deletingText}
                </BoldTypography>
            }

            <Linkify disabled={!linkify} sanitize={!linkify}>
                {
                    commentArr.map((paragraph,ix) => (
                        <Paragraph 
                            key         = {ix} 
                            align       = "justify" 
                            variant     = "body2" 
                            newComment  = {newComment} 
                            deleting    = {deleting} 
                            replying    = {replying} 
                            paragraph   = {ix < commentArr.length - 1}
                        >
                            {paragraph}
                        </Paragraph>
                    ))
                }
            </Linkify>
        </Root> 
    )
}

export default CommentsContent;