/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
useHoroscopeDescriptor Hook
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       17th May 2023

*******************************************************************************************/
import React                    from 'react';
import ord                      from 'ordinal';

const ordinal = x => {
    try {
        return ord(x)
    } catch (err) {
        //
    }
    return undefined;
}

export const useHoroscopeDescriptor = (horoscopeData, unknownTime = false) => {

    const {
        sun         = undefined, 
        moon        = undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
    } = React.useMemo(() => horoscopeData?.CelestialBodies || {}, [JSON.stringify(horoscopeData?.CelestialBodies)] )

    const {
        ascendant   = undefined, 
        midheaven   = undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
    } = React.useMemo(() => horoscopeData?.Angles || {}, [JSON.stringify(horoscopeData?.Angles)] )

    const descriptorFun = React.useCallback(body => {
        if(!body) 
            return undefined;
        const short = [body?.label, body?.Sign?.label].filter(Boolean).join(' in ')
        if(unknownTime)
            return short
        const validHouse = body?.House?.id || body?.House?.label;
        if(!validHouse)
            return short
        return `${short} ${ordinal(body?.House?.id) || body?.House?.label} ${body?.House ? 'House' : ''}`
    },[unknownTime])

    // Function to extract relevant parts from a celesial body
    const processBody = React.useCallback((body) => ({
        bodyLabel        : body?.label,
        bodySign         : body?.Sign,
        bodySignLabel    : body?.Sign?.label,
        bodyHouse        : body?.House,
        bodyHouseLabel   : ordinal(body?.House?.id) || body?.House?.label
    }),[])

    // Function to extract relevant parts from a celesial body
    const processAngle = React.useCallback((angle) => ({
        angleLabel        : angle?.label,
        angleSign         : angle?.Sign,
        angleSignLabel    : angle?.Sign?.label,
    }),[])

    // SUN
    const {
        bodyLabel       : sunLabel, 
        bodySign        : sunSign, 
        bodySignLabel   : sunSignLabel, 
        bodyHouse       : sunHouse, 
        bodyHouseLabel  : sunHouseLabel

    // eslint-disable-next-line react-hooks/exhaustive-deps
    } = React.useMemo(() => processBody(sun),[processBody, JSON.stringify(sun)])

    // MOON
    const {
        bodyLabel       : moonLabel, 
        bodySign        : moonSign, 
        bodySignLabel   : moonSignLabel, 
        bodyHouse       : moonHouse, 
        bodyHouseLabel  : moonHouseLabel

    // eslint-disable-next-line react-hooks/exhaustive-deps
    } = React.useMemo(() => processBody(moon),[processBody, JSON.stringify(moon)])

    // ASCENDANT
    const {
        angleLabel       : ascendantLabel, 
        angleSign        : ascendantSign, 
        angleSignLabel   : ascendantSignLabel

    // eslint-disable-next-line react-hooks/exhaustive-deps
    } = React.useMemo(() => processAngle(ascendant),[processBody, JSON.stringify(ascendant)])

    // ASCENDANT
    const {
        angleLabel       : midheavenLabel, 
        angleSign        : midheavenSign, 
        angleSignLabel   : midheavenSignLabel

    // eslint-disable-next-line react-hooks/exhaustive-deps
    } = React.useMemo(() => processAngle(midheaven),[processBody, JSON.stringify(midheaven)])

    // Sun Descriptor
    const horoscopeSunDescriptor  = React.useMemo(() => {
        try {
            return descriptorFun(sun);
        } catch (err) {
            return undefined;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(sun),descriptorFun])

    // Moon Descriptor
    const horoscopeMoonDescriptor = React.useMemo(() => {
        try {
            return descriptorFun(moon);
        } catch (err) {
            return undefined; 
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(moon),descriptorFun])

    // Rising Descriptor
    const horoscopeRisingDescriptor = React.useMemo(() => {
        try {
            return ascendant 
                ? `${ascendant?.Sign?.label} Rising`
                : undefined
        } catch (err) {
            return undefined; 
        }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(ascendant)])

    const descriptor = React.useMemo(() => {
        const partA     = [ horoscopeSunDescriptor, horoscopeMoonDescriptor ].filter(Boolean).join(', ');
        const partB     = unknownTime ? undefined : horoscopeRisingDescriptor;
        const result    = [ partA, partB ].filter(Boolean).join(', with ')
        return result ? (result + '.') : undefined;
    }, [horoscopeMoonDescriptor, horoscopeRisingDescriptor, horoscopeSunDescriptor, unknownTime]);

    return {
        descriptor,
        components : {
            // Sun
            sun,
            sunLabel, 
            sunSign, 
            sunSignLabel, 
            sunHouse, 
            sunHouseLabel,
            // Moon
            moon,
            moonLabel, 
            moonSign, 
            moonSignLabel, 
            moonHouse, 
            moonHouseLabel,
            // Ascendant
            ascendant,
            ascendantLabel,
            ascendantSign,
            ascendantSignLabel,
            // Midheaven
            midheaven,
            midheavenLabel,
            midheavenSign,
            midheavenSignLabel
        }
    }
}

export default useHoroscopeDescriptor;