/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Password Reset Request
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       22nd December 2020

*******************************************************************************************/

import React                            from 'react';
import moment                           from 'moment';
import { 
    Tooltip, 
    Typography,
    useMediaQuery
}                                       from '@mui/material';
import LockOpenIcon                     from '@mui/icons-material/LockOpen';
import { useUser }                      from 'contexts';
import { IconButton, Button }           from 'components';
import { Confirmation }                 from 'components/modals';
import { CountDown }                    from 'components';
import { withTranslation }              from './hoc';

const DELAY_MILLISECONDS = 3000;

// Format function for digital clock
const formatter = ({prefix, days, hours, minutes, seconds}) => {
    if(seconds <= 0)
        return prefix;
    return `${prefix} in ${seconds} second${seconds > 1 ? 's' : ''}`;
}

const defaultButtonProps = {};

export const PasswordResetRequestButton = withTranslation( ({t, icon : Icon = LockOpenIcon, component : Component = Button, buttonProps = defaultButtonProps, ...props}) => {
    
    // User Hooks
    const {isAuthenticated, isSocial, sendEmailResetRequest, logoutWithDelay} = useUser();

    // Success Modal
    const [openSuccess,     setOpenSuccess]     = React.useState(false);
    const [openConfirm,     setOpenConfirm]     = React.useState(false);
    const [openTimestamp,   setOpenTimestamp]   = React.useState(undefined);
    const [openFailure,     setOpenFailure]     = React.useState(false);
    const [disabled,        setDisabled]        = React.useState(false);
    const [error,           setError]           = React.useState(undefined);

    const handleOpenSuccess                     = React.useCallback( () => {
        setOpenSuccess(true);
        const logoutFunction = logoutWithDelay(DELAY_MILLISECONDS);
        logoutFunction();
    }, [logoutWithDelay]);

    const handleCloseSuccess                    = React.useCallback( () => setOpenSuccess(false), []);
    const handleOpenFailure                     = React.useCallback( () => setOpenFailure(true), []);
    const handleCloseFailure                    = React.useCallback( () => setOpenFailure(false), []);
    const handleOpenConfirm                     = React.useCallback( () => setOpenConfirm(true), []);
    const handleCloseConfirm                    = React.useCallback( () => setOpenConfirm(false), []);
    
    // Set Disabled when either of the modals are open
    React.useEffect(()=>{
        setDisabled(openSuccess || openFailure);
    },[openSuccess,openFailure])

    // Reset the error message when the failure modal is closed
    React.useEffect(()=>{
        if(!openFailure)
            setError(undefined);
    },[openFailure])

    React.useEffect(() => {
        setOpenTimestamp(openSuccess ? moment() : undefined);
    },[openSuccess])

    // Query the server
    const handlePasswordReset = React.useCallback( () => {
        setDisabled(true);
        sendEmailResetRequest()
            .then(handleOpenSuccess)
            .catch(err => {
                setError(err.message);
                handleOpenFailure();
            })
    },[handleOpenFailure, handleOpenSuccess, sendEmailResetRequest])

    const handleConfirm = React.useCallback(() => {
        handleCloseConfirm();
        handlePasswordReset();
    }, [handleCloseConfirm, handlePasswordReset])

    const smUp = useMediaQuery(theme => theme.breakpoints.up('sm'))

    // Password cannot be set if the user is not authenticated
    if(!isAuthenticated) 
        return null;

    // Password cant be reset for social connections
    if(isSocial) 
        return null;

    // The main redner
    return (
        <React.Fragment>

            {   
                !smUp &&
                <Tooltip title={t('components.passwordResetRequestField.passwordReset')}>
                    <span>
                        <IconButton onClick={handleOpenConfirm} size="large">
                            <Icon/>
                        </IconButton>
                    </span>
                </Tooltip>
            }
            {   
                smUp &&
                <Tooltip title={t('components.passwordResetRequestField.passwordReset')}>
                    <span>
                        <Component startIcon={<Icon />} disabled={disabled} onClick={handleOpenConfirm} {...props} {...buttonProps}>
                            {t('common.reset')}
                        </Component>
                    </span>
                </Tooltip>
            }
            
            <Confirmation title={t('components.passwordResetRequestField.passwordReset')} oklabel="Yes" cancellabel="No" showButtonsCancel={true} open={openConfirm} onOk={handleConfirm} onCancel={handleCloseConfirm}>
                <Typography gutterBottom>
                    {t('components.passwordResetRequestField.confirmationRequired')}
                </Typography>
                <Typography gutterBottom align="right">
                    {t('common.areYouSure')}
                </Typography>
            </Confirmation>

            <Confirmation title="Success" oklabel="OK" showButtonsCancel={false} open={openSuccess} onOk={handleCloseSuccess} onCancel={handleCloseSuccess}>
                <Typography gutterBottom>
                    {t('passwordResetRequest.success')}
                </Typography>
                {
                    moment.isMoment(openTimestamp) && 
                    <Typography component="div" gutterBottom align="right">
                        <CountDown 
                            formatter   = {formatter} 
                            prefix      = {t('common.loggingOut')} 
                            to          = {openTimestamp.clone().add(DELAY_MILLISECONDS,'milliseconds')}
                        />
                    </Typography>
                }
            </Confirmation>

            <Confirmation title="Error" oklabel="OK" showButtonsCancel={false} open={openFailure} onOk={handleCloseFailure} onCancel={handleCloseFailure}>
                <Typography gutterBottom>
                    {t('passwordResetRequest.error')}
                </Typography>
                {
                    error && 
                    <Typography gutterBottom variant="body2">
                        {error}
                    </Typography>
                }
            </Confirmation>
        </React.Fragment>
    );
});

export default PasswordResetRequestButton;