/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Buttons
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       20th July 2021

*******************************************************************************************/

import React                        from 'react';

import EditIcon                     from '@mui/icons-material/Edit';
import SaveIcon                     from '@mui/icons-material/Save';
import UndoIcon                     from '@mui/icons-material/Undo';
import SearchIcon                   from '@mui/icons-material/Search';
import ShoppingCartIcon             from '@mui/icons-material/ShoppingCart';
import PaymentIcon                  from '@mui/icons-material/Payment';
import GetAppIcon                   from '@mui/icons-material/GetApp';

import { useTranslation }           from 'contexts/TranslationContext';
import Button                       from 'components/Button';
import PulseButton                  from 'components/pulse/PulseButton';

export default Button;

export const EditButton = React.forwardRef( ({children, ...props}, ref)  => {
    const [t] = useTranslation();
    return (
        <Button ref={ref} startIcon={<EditIcon/>} {...props}>
            {children || t('common.edit')}
        </Button>
    )
});

export const PayButton = React.forwardRef( ({children, pulse=false, ...props}, ref)  => {
    const [t] = useTranslation();
    return (
        <PulseButton ref={ref} pulse={pulse} startIcon={<PaymentIcon/>} {...props}>
            {children || t('common.pay')}
        </PulseButton>
    )
});

export const SaveButton = React.forwardRef( ({children, ...props}, ref)  => {
    const [t] = useTranslation();
    return (
        <Button ref={ref} startIcon={<SaveIcon/>} {...props}>
            {children || t('common.save')}
        </Button>
    )
});

export const UndoButton = React.forwardRef( ({children, ...props}, ref)  => {
    const [t] = useTranslation();
    return (
        <Button ref={ref} startIcon={<UndoIcon/>} {...props}>
            {children || t('common.undo')}
        </Button>
    )
});

export const BrowseButton = React.forwardRef(({children, ...props}, ref) => {
    const [t] = useTranslation();
    return (
        <Button ref={ref} startIcon={<SearchIcon/>} {...props}>
            {children || t('common.browse')}
        </Button>
    )
});

export const CheckoutButton = React.forwardRef(({children, ...props}, ref) => {
    const [t] = useTranslation();
    return (
        <Button ref={ref} startIcon={<ShoppingCartIcon/>} {...props}>
            {children || t('common.checkout')}
        </Button>
    )
});

export const DownloadButton = React.forwardRef(({children, ...props}, ref) => {
    const [t] = useTranslation();
    return (
        <Button ref={ref} startIcon={<GetAppIcon/>} {...props}>
            {children || t('common.download')}
        </Button>
    )
});
